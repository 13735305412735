import GridDuck from "gridduck";
import cookie from "react-cookies";
import {pdfjs} from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const configureGridduck = function () {
    const CLIENT_ID = "da0741d3-a163-4d32-bea7-93c177bdaf61";
    const CLIENT_SECRET = 'gridduck';
    const scopesWanted = GridDuck.getAvailableScopes()
	let baseUrl;
    // if (window.location.href.indexOf("beta.dashboard") > -1) {
    //     baseUrl = 'https://beta.api.gridduck.com/';
    // }
    // if (window.location.href.indexOf("staging.dashboard") > -1) {
    //     baseUrl = 'https://staging2.api.gridduck.com/';
    // }
	if (cookie.load('baseUrl')) {
		baseUrl = cookie.load('baseUrl');
	}
	let GDConfig = {
        apiKey: CLIENT_ID,
        apiSecret: CLIENT_SECRET,
        type: "password",
        scopes: scopesWanted,
        baseUrl: baseUrl ? baseUrl : 'https://v2.api.gridduck.com/'
    };
	GridDuck.config = GDConfig;
    GridDuck.on('resync', function () {
        window.location.reload();
    })
};

export default configureGridduck();