import React from "react";
import './AssetPage.scss';
import GridDuck from "gridduck";
import history from "../../meta/history";

class MainAssetPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		let self = this;
		let {assetId} = this.props.match.params;

		GridDuck.getAccount({id: GridDuck.userId})
			.then(function (account) {
				self.setState({
					account: account
				});
			});

		GridDuck.getAsset({
			id: assetId
		}).then(function (asset) {
			GridDuck.getSite({id: asset.groupIds[0]}).then((site) => {
				self.setState({
					asset: asset,
					site: site
				});
			});
		}).catch(function (err) {
			history.push('/404');
		});
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		let self = this;
		let oldAssetId = prevProps.match.params;
		let newAssetId = this.props.match.params;
		if (oldAssetId !== newAssetId) {
			let {assetId} = this.props.match.params;
			GridDuck.getAsset({
				id: assetId
			}).then(function (asset) {
				self.setState({
					asset: asset,
				});
			});
		}
	}
}

export default MainAssetPage;