import {Dropdown, Icon, Input, Toast} from "gd-react";
import React from "react";
import GenericLoader from "./GenericLoader";
import './TariffForm.scss'
import Rates from './Rates'

class TariffForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            utilityTypes: [
                {
                    id: 0,
                    title: 'Electricity',
                    value: 'DELIVERED_SPEND',
                    unit: 'kWh'
                },
                {
                    id: 4,
                    title: 'Generated Electricity',
                    value: 'GENERATED_SPEND',
                    unit: 'kWh'
                },
                {
                    id: 4,
                    title: 'Exported Electricity',
                    value: 'EXPORTED_SPEND',
                    unit: 'kWh'
                },
                {
                    id: 1,
                    title: 'Gas Volume',
                    value: 'DELIVERED_GAS_SPEND',
                    unit: 'm3/h'
                },
                {
                    id: 1,
                    title: 'Gas Energy',
                    value: 'GAS_ENERGY_DELIVERED_SPEND',
                    unit: 'kWh'
                },
                {
                    id: 2,
                    title: 'Water',
                    value: 'DELIVERED_WATER_SPEND',
                    unit: 'm3/h'
                },
                {
                    id: 3,
                    title: 'Hot Water',
                    value: 'DELIVERED_WATER_HOT_SPEND',
                    unit: 'm3/h'
                }
            ],
            currencies: [
                {
                    id: 0,
                    title: '$ (USD)',
                    value: 'USD',
                    lowestDenom: 'cents'
                },
                {
                    id: 1,
                    title: '€ (EUR)',
                    value: 'EUR',
                    lowestDenom: 'cents'
                },
                {
                    id: 2,
                    title: '£ (GBP)',
                    value: 'GBP',
                    lowestDenom: 'pence'
                }
            ],
            loaded: true,
            editing: '',
            errorMessages: []
        };
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleCostChange = this.handleCostChange.bind(this)
        this.isAllTheTime = this.isAllTheTime.bind(this)
        this.setAll = this.setAll.bind(this);
    }

    handleRateChange(direction) {
        let rates = this.props.formData.rates, self = this;

        if (direction === 'add') {
            //Look for gap in timeline
            let filledTimes = [], allTimes = [];
            for (let i = 1; i <= 24; i = i + 0.5) {
                let timeInside = rates.find((r) => {
                    let t = self.props.funcs.convertTimesToWholeNumbers(r);
                    console.log(t + 1, ' : t');
                    return (t.start + 1 < i && t.end + 1 > i);
                });
                if (timeInside) filledTimes.push(i);
                allTimes.push(i);
            }

            console.log(allTimes, ' : all times');
            console.log(filledTimes, ' : filled times');

            let gapTimes = allTimes.filter(t => !filledTimes.includes(t)).reduce((arr, val, i, a) => {
                if (!i || val !== a[i - 1] + 0.5) arr.push([]);
                arr[arr.length - 1].push(val);
                return arr;
            }, []);

            console.log(gapTimes);

            let firstRate = rates[0];
            let lastRate = rates[rates.length - 1];
            if (lastRate.endHour === firstRate.startHour && lastRate.endMinute === firstRate.endMinute) {
                window.alert("No space in timeline to add rate");
                return;
            }
            rates.push({
                startHour: lastRate.endHour,
                startMinute: lastRate.endMinute,
                endHour: firstRate.startHour,
                endMinute: firstRate.startMinute,
                rate: 0,
                id: Math.random()
            })
        } else if (direction === 'minus') {
            rates.pop();
        }

        if (this.props.onRatesChange) this.props.onRatesChange(rates);
        console.log(this.props.onUpdate, ' : on update');
    }

    isAllTheTime(index, rate) {
        if (!index) index = 0;
        let r = this.props.formData.rates[index];
        if (rate) r = rate;
        return (r.startHour === r.endHour && r.startMinute === r.endMinute);
    }

    setAll(r, forceEndHour) {
        let rate = r ? r : this.props.formData.rates[0];
        console.log(r, ' : r');
        console.log(this.props.formData.rates[0], ' : this.props.formData.rates[0]');
        rate.startMinute = 0
        rate.startHour = 0
        rate.endMinute = 0
        rate.endHour = (forceEndHour || forceEndHour === 0) ? forceEndHour : this.isAllTheTime() ? 1 : 0
        let rates = [rate]
        console.log(rates, ' : rates');
        if (this.props.onRatesChange) this.props.onRatesChange(rates);
    }

    handleTimeChange(val, timeName, rate) {
        rate[timeName] = val

        console.log(rate, ' : rate');

        if (this.isAllTheTime(null, rate)) {
            console.log('Is all the time');
            this.setAll(rate, 0);
        } else {
            if (this.props.onRatesChange) this.props.onRatesChange(this.props.formData.rates);
        }
    }

    handleCostChange(rate, newVal) {
        let rates = this.props.formData.rates;
        console.log(newVal, ' : handle rate change');
        rate.rate = newVal;
        if (this.props.onRatesChange) this.props.onRatesChange(rates);
    }

    render() {

        let errorMessages = [];

        let allTheTime = this.isAllTheTime();

        if (this.props.errorMessages && this.props.errorMessages.length) {
            console.log(' error messages exist, pushing')
            errorMessages = this.props.errorMessages.map((message, i) => {
                console.log(message, ' message being pushed')
                return <div key={i +'m'} className={'row'} style={{marginTop: '20px'}}>
                <span className={'error-text'}>
                {message}
                    </span>
                </div>
            })
        }

        console.log(this.props.triedToSave, ' : this.props.triedToSave');
        console.log(this.props.errors, ' : errors');

        return (
            this.state.loaded ? <div>
                    <div>
                        <div className={'top-container'}>
                            <div className={'row'} style={{alignItems: 'flex-end'}}>
                                <div style={{maxWidth: '300px'}}>
                                    <Input label={'Name'}
                                           disabled={this.props.disabled}
                                           error={this.props.errors['name'] && this.props.triedToSave}
                                           onChange={(e) => this.props.onNameChange(e.target.value)}
                                           value={this.props.formData.name}/>
                                </div>
                                <div style={{maxWidth: '150px', paddingLeft: '10px'}}>
                                    <Dropdown
                                        title={'Currency'}
                                        disabled={this.props.disabled}
                                        value={this.props.formData.currency}
                                        label={'Currency'}
                                        fixeditems={this.state.currencies}
                                        error={this.props.errors['currency'] && this.props.triedToSave}
                                        onChange={(e) => {
                                            console.log(e, ' e')
                                            this.props.onCurrencyChange(e.target.value);
                                        }}/>
                                </div>
                                <div style={{width: '200px', paddingLeft: '10px'}}>
                                    <Dropdown
                                        disabled={this.props.disabled}
                                        title={'Utility Type'}
                                        value={this.props.formData.utilityType}
                                        label={'Utility Type'}
                                        fixeditems={this.state.utilityTypes}
                                        error={this.props.errors['utilityType'] && this.props.triedToSave}
                                        onChange={(e) => {
                                            this.props.onUtilityTypeChange(e.target.value);
                                        }}/>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div>
                                    <div className={'row ' + (!this.props.disabled ? 'not-disabled' : null)}
                                         style={{alignItems: 'end'}}>

                                        <Input label={'Number of Rates'}
                                               type={'number'}
                                               disabled={true}
                                               inputProps={{step: 1, min: 1}}
                                               style={{maxWidth: '124px'}}
                                               onChange={(e) => this.handleRateChange(e)}
                                               value={this.props.formData.rates.length}/>


                                        {!this.props.disabled ? <div
                                            className={'plus-minus-button minus ' + (this.props.formData.rates.length === 1 ? 'disabled' : '')}
                                            onClick={() => this.handleRateChange('minus')}>
                                            <Icon size={'11'} icon={'FaMinus'}/>
                                        </div> : null}

                                        {!this.props.disabled ? <div className={'plus-minus-button'}
                                                                     onClick={() => this.handleRateChange('add')}>
                                            <Icon size={'11'} icon={'FaPlus'}/>
                                        </div> : null}

                                    </div>
                                </div>
                                <div style={{maxWidth: '350px', paddingLeft: '10px'}}>
                                    <Input
                                        label={'Daily Standing Charge in ' + this.state.currencies.find(c => c.value === this.props.formData.currency).lowestDenom + ' (exc. VAT)'}
                                        type={'number'}
                                        disabled={this.props.disabled}
                                        onBlur={(e) => {
                                            let value = e.target.value;
                                            if (!value) value = 0;
                                            this.props.onStandingChargeChange(value);
                                        }}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            if (value < 0) value = 0;
                                            if (!value && value !== 0) value = ''
                                            this.props.onStandingChargeChange(value);
                                        }}
                                        value={this.props.formData.standingCharge}/>
                                </div>
                            </div>
                        </div>
                        {errorMessages}
                        <Rates
                            all={allTheTime}
                            disabled={this.props.disabled}
                            setAll={this.setAll}
                            errors={this.props.errors}
                            triedToSave={this.props.triedToSave}
                            currency={this.state.currencies.find(c => c.value === this.props.formData.currency)}
                            unit={this.state.utilityTypes.find(c => c.value === this.props.formData.utilityType)}
                            handleTimeChange={this.handleTimeChange}
                            handleCostChange={this.handleCostChange}
                            rates={this.props.formData.rates}/>
                    </div>
                    <Toast onClose={() => this.setState({showToast: false})} message={this.state.showToast}
                           open={!!(this.state.showToast)}
                           severity="success"
                           anchorOrigin={{
                               vertical: 'bottom',
                               horizontal: 'left',
                           }}/>
                </div> :
                <div style={{maxHeight: '40px', marginTop: '-40px', marginBottom: '70px'}}><GenericLoader/></div>
        )
    }
}

export default TariffForm;