import React from 'react';
// import './DesktopLogin.scss';
import GridDuck from "gridduck";

class CreateOrganisation extends React.Component {

    constructor(props) {
        super(props);
        this.onEmailsChange = this.onEmailsChange.bind(this);
        this.submit = this.submit.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.clearForms = this.clearForms.bind(this);
        this.state = {
            emailsVerified: null,
            existingEmails: [],
            showPanel: 1,
            requestSent: false,
            newOrgName: '',
            newEmail: '',
            emailValid: true,
            free: 'true',
            currency: 'gbp',
            industry: null,
            goals: []
        };
    }

    clearForms() {
        this.setState({
            emailsVerified: null,
            existingEmails: [],
            showPanel: 1,
            requestSent: false,
            newOrgName: '',
            newEmail: '',
            emailValid: true,
            free: 'true',
            industry: null,
            goals: []
        })
    }

    validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    onEmailsChange(val) {
        let email = val.target.value;
        let validEmail = this.validateEmail(email);
        this.setState({newEmail: email, submitted: false});
    }

    submit() {
        let self = this;
        let validEmail = this.validateEmail(this.state.newEmail);
        this.setState({emailValid: validEmail, submitted: true});
        if (!validEmail) return Promise.reject();
        return GridDuck.createPubOrg({
            name: this.state.newOrgName,
            emailAddress: this.state.newEmail,
            free: !(this.state.free === 'true'),
            orgCurrency: this.state.currency,
            industry: this.state.industry,
            goals: this.state.goals ? this.state.goals.map(g => g.id) : null
        }).then(function (res) {
            self.setState({requestSent: true});
        })
    }
}

export default CreateOrganisation;
