import React from "react";
import {Button, Card, CardBody, CardHeader, Input, Toast} from "gd-react";
import SaveBar from "../../components/SaveBar/SaveBar";
import DeleteModal from "../../modals/Delete/DeleteModal";
import GridDuck from "gridduck";

class GroupSettings extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        this.onFormChange = this.onFormChange.bind(this);
        this.hasChanged = this.hasChanged.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.setGroupInfo = this.setGroupInfo.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.showDeleteModal = this.showDeleteModal.bind(this);
        this.errors = {};
        this.group = {};
        this.state = {
            group: {
                name: this.props.item.name
            },
			showDeleteModal: false,
            open: false
        };

        Promise.all([
            GridDuck.getAccount({id: GridDuck.userId})
        ])
            .then(function (results) {
                self.user = results[0];
                self.setState({user: self.user});
            });
    }

    saveChanges() {
        this.setState({triedToSave: true})
        let self = this;
        if (Object.keys(this.errors).length > 0) return new Promise(function (resolve, reject) {
            return reject(self.errors);
        }).catch(function () {
            return Promise.reject();
        });
        return this.props.item.set(this.state.group).then(function () {
            self.setState({open: true});
            self.setGroupInfo();
            return Promise.resolve();
        });
    }


    handleClose() {
        this.setState({open: false})
    }

    setGroupInfo() {
        let self = this;
        this.setState({
            group: {
                name: self.props.item.name
            }
        })
    }

    onFormChange(val) {
        this.state.group[val.target.name] = val.target.value;
        this.setState(this.state);
    }

    showDeleteModal() {
    	this.setState({showDeleteModal: true})
	}

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.item && this.props.item && prevProps.item.id !== this.props.item.id) {
            this.setGroupInfo();
        }
    }

    hasChanged() {
        let self = this;
        let hasChanged = false;
        if (self.props.item) {
            Object.entries(self.props.item).map(([key, value]) => {
                let updatedKey = key.replace(/^_+/i, '');
                if ((updatedKey in self.state.group) && self.state.group[updatedKey] !== value) {
                    hasChanged = true;
                }
            })
        }
        return hasChanged;
    }

    checkErrors() {
        let errors = {};
        if (!this.state.group.name.length) errors['name'] = true;
        this.errors = errors;
    }

    cancelChanges() {
        this.setGroupInfo();
    }

    render() {
        this.checkErrors();

        let headerBarComp = this.hasChanged() ?
            <div className={'detail-content'}><SaveBar onSaveClick={this.saveChanges}
                                                       onCancelClick={this.cancelChanges}/></div> : ''

        let generalCard;
    	let advancedCard;

        generalCard = (
            <Card className={'site-general-settings'}>
                <CardHeader title={'General'}/>
                <CardBody>
                    <Input top='true' name={"name"} label={'Name'} required
						   disabled={!(this.props.item._permission ==='edit' || this.props.item._permission ==='admin') || (this.state.user && window.$exampleAccount)}
                           error={this.errors['name'] && this.state.triedToSave} onKeyPress={(event) => {
                        if (event.key === 'Enter') this.saveChanges();
                    }} value={this.state.group.name} onChange={this.onFormChange}/>
                </CardBody>
            </Card>
        )

		advancedCard = (
			<Card>
				<CardHeader title={'Advanced'}/>
				<CardBody>
					{this.props.item._permission === 'admin' ? <Button label={'Delete Device Group'} color={'gd-red'} onClick={this.showDeleteModal}/> : ''}
				</CardBody>
			</Card>
		)

        return (
            <div className={"page grey narrow not-flex"}>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        {generalCard}
                    </div>
					<div className={'detail-content'}>
						{this.props.item._permission === 'admin' ? advancedCard : null}
					</div>
                    {headerBarComp}
                </div>
				<DeleteModal
					itemType={'group'}
					item={this.props.item}
					open={this.state.showDeleteModal}
					onClose={() => this.setState({showDeleteModal: false})} />
                <Toast onClose={this.handleClose} message={"Save Successful"}
                       anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                       open={this.state.open}/>
            </div>
        )
    }
}
export default GroupSettings;
