import React, {Component} from "react";
import '../../styles/_layout.scss';
import {
    Button,
    CardBody,
    Dropdown,
    Input,
    StateOptions,
    Tabs
} from "gd-react";
import GridDuck from "gridduck";
import Toggle from "../../components/Toggle/Toggle";
import GdModal from "../../components/GdModal/GdModal";
import DeleteModal from "../../modals/Delete/DeleteModal";
import './dataUpload.scss';
import UnitDetailsForm from "./UnitDetailsForm";

let unitTypes = [
    {
        id: 1,
        title: <span>Dimensionless <span
            style={{color: 'grey'}}>-Discrete objects or elements E.g. Item/Items...</span></span>,
        value: 'dimensionless'
    },
    {
        id: 2,
        instantaneousOnly: true,
        title: <span>Categorical <span
            style={{color: 'grey'}}>- E.g. Low/Medium/High, Red/Green/Blue...</span></span>,
        value: 'categorical'
    },
    {
        id: 3,
        title: <span>Metric <span
            style={{color: 'grey'}}>- E.g. meters (m), liters (L)...</span></span>,
        value: 'metric'
    },
    {
        id: 4,
        title: <span>Non-Metric <span style={{color: 'grey'}}>- E.g. inches (in), pounds
                                    (lbs)...</span></span>, value: 'nonMetric'
    },
    {
        id: 5,
        instantaneousOnly: true,
        title: <span>True/False <span
            style={{color: 'grey'}}>- E.g. Occupied/Unoccupied, On/Off...</span></span>,
        value: 'boolean'
    },
    // {
    //     id: 6,
    //     title: <span>Logarithmic <span
    //         style={{color: 'grey'}}>- E.g. decibels (dB), pH levels...</span></span>,
    //     value: 'logarithmic'
    // }
];

class CreateUnit extends Component {

    constructor(props) {
        super(props);
        let self = this;
        //Bind scope to functions
        ['closeModal', 'onFormChange', 'saveChanges', 'checkErrors', 'onTabClick', 'moveToTab', 'deleteUnit', 'updateUnitFormDetails'].forEach((func) => {
            self[func] = self[func].bind(self);
        });
        this.errors = {};
        this.unitFormErrors = {};
        this.changed = false;
        const item = this.props.item;
        let dataType = item ? item.dataType : 'aggregate';
        if (this.props.limitDataType) dataType = this.props.limitDataType;
        this.state = {
            wide: false,
            deleteConfirmation: '',
            update: 1,
            unit: {
                name: item ? item.name : '',
                dataType: dataType,
                unitType: item ? item.unitType : 'dimensionless',
                metaData: item ? item.metaData : {}
            },
            tabs: [[
                {
                    id: 1,
                    title: 'General',
                    selected: (this.props.openOnTab === 1 || !this.props.openOnTab),
                    onTabClick: this.onTabClick

                }
            ]]
        }
        if (this.props.item && this.props.item._permission !== 'view') {
            this.state.tabs[0][1] = {
                id: 2,
                title: 'Delete',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 3)
            }
        }

        this.modalClosed = false;
    }

    deleteUnit() {
        let self = this;
        return this.props.item.delete().then(function () {
            if (self.props.onDelete) self.props.onDelete();
            self.closeModal();
        })
    }

    onTabClick(ev, tab) {
        this.state.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    closeModal(createdUnit) {
        this.modalClosed = true;
        this.props.onClose(createdUnit);
    }

    onFormChange(val) {
        if (val.target.name === 'unitType' && (val.target.value === 'categorical' || val.target.value === 'boolean')) {
            this.state.unit['dataType'] = 'instantaneous';
        }
        this.state.unit[val.target.name] = val.target.value;
        this.setState({unit: this.state.unit, hasChanged: true});
    }

    async saveChanges() {
        let self = this, unit = this.state.unit, createdUnit;
        if (Object.values(this.errors).find(v => v) || Object.values(this.unitFormErrors).find(v => v)) return this.setState({
            update: this.state.update++,
            triedToSave: true
        })
        if (this.props.item) {
            try {
                await this.props.item.set(unit);
            } catch (e) {

            }
        } else {
            createdUnit = await GridDuck.createDataUnit(unit);
            createdUnit.rateUnit = this.props.rateUnit;
        }
        if (self.props.onSave) self.props.onSave();
        self.closeModal(createdUnit);
    }

    checkErrors() {
        let errors = this.errors;
        let unit = this.state.unit;
        if (!unit) return errors;
        errors['name'] = (!unit.name.length);
        //TODO Conditional check based on what type they've selected

        this.errors = errors;
    }

    onDeleteChange(val) {
        this.setState({deleteConfirmation: val.target.value});
    }

    moveToTab() {
        this.setState({triedToNext: true})
        if (Object.values(this.errors).find(v => v)) return;
        this.onTabClick(null, {id: 2});
    }

    updateUnitFormDetails(formData) {
        this.unitFormErrors = formData.errors;
        this.onFormChange({
            target: {
                name: 'metaData',
                value: formData.metaData
            }
        })
    }

    render() {
        this.checkErrors();
        let unitTypesToShow = unitTypes;
        const dt_dd_options = [{
            id: 1,
            title: <span>Instantaneous <span
                style={{color: 'grey'}}>- E.g. °C, W, Pa...</span></span>,
            value: 'instantaneous'
        }, {
            id: 2,
            title: <span>Aggregate <span
                style={{color: 'grey'}}>- E.g. Wh, items produced, total sales...</span></span>,
            value: 'aggregate'
        }]

        // if (this.state.tabs[0][0].selected) footer = <Button label={'Next'} onClick={() => this.moveToTab(1)}/>
        let dataTypes = [dt_dd_options[0]];
        if (this.state.unit.unitType !== 'categorical' && this.state.unit.unitType !== 'boolean') {
            dataTypes.unshift(dt_dd_options[1]);
        }
        if (this.props.limitDataType === 'instantaneous') {
            dataTypes = [dt_dd_options[0]];
        } else if (this.props.limitDataType === 'aggregate') {
            dataTypes = [dt_dd_options[1]];
            unitTypesToShow = unitTypes.filter(ut => !ut.instantaneousOnly);
        }
        let footer;
        if (!this.props.item || this.props.item._permission !== 'view') {
            footer = <Button progressRes
                             disabled={Object.values(this.errors).find(v => v) || Object.values(this.unitFormErrors).find(v => v)}
                             additionalclasses={'sm'}
                             label={this.props.item ? 'Save' : 'Create'}
                             color={'gd-green'}
                             onClick={this.saveChanges}/>
        }

        return (
            <GdModal
                title={(!this.props.item ? 'Create ' : '') + ' Unit System'}
                open={this.props.open}
                contentLabel={'Edit Unit'}
                footer={footer}
                onClose={this.closeModal}>
                {this.state.tabs ? <Tabs tabData={this.state.tabs}/> : null}
                {this.state.tabs[0][0].selected ?
                    [(<div className={'modal-info'}>
                        Rate (E.g. items/hour) and Ratio (Cost of Goods:Average Inventory) units are applied on the
                        Create Data Type page, by combining units created here.
                    </div>),
                        (<CardBody>
                            <div className={'data-upload'}>

                                <Input required
                                       onChange={this.onFormChange}
                                       name={'name'}
                                       error={this.state.triedToSave && this.errors['name']}
                                       value={this.state.unit.name}
                                       placeholder={'E.g. Joules, Length, Status...'}
                                       label={'Name'}/>

                                <div className={'row'} style={{marginTop: '15px'}}>
                                    <Dropdown disabled={this.props.item && this.props.item._permission === 'view'}
                                              style={{minWidth: '100px'}}
                                              required
                                              value={this.state.unit.dataType}
                                              label={'Data Type'} fixeditems={dataTypes}
                                              onChange={(val) => this.onFormChange({
                                                  target: {
                                                      name: 'dataType',
                                                      value: val.target.value
                                                  }
                                              })}/>
                                </div>
                                <div className={'row'} style={{marginTop: '15px'}}>
                                    <Dropdown disabled={this.props.item && this.props.item._permission === 'view'}
                                              style={{minWidth: '100px'}}
                                              required
                                              value={this.state.unit.unitType}
                                              label={'Unit Type'} fixeditems={unitTypesToShow}
                                              onChange={(val) => this.onFormChange({
                                                  target: {
                                                      name: 'unitType',
                                                      value: val.target.value
                                                  }
                                              })}/>
                                </div>
                                <UnitDetailsForm triedToSave={this.state.triedToSave} unit={this.state.unit}
                                                 onChange={this.updateUnitFormDetails}/>
                            </div>
                        </CardBody>)] : ''}
                {this.state.tabs[0][1] && this.state.tabs[0][1].selected ? <CardBody>
                    <DeleteModal itemType={'unit'}
                                 item={this.props.item}
                                 open={this.state.tabs[0][1].selected}
                                 deleteRes={this.closeModal}
                                 onClose={(e) => {
                                     this.onTabClick(e, {id: 1})
                                 }}/>
                </CardBody> : null}
            </GdModal>
        )
    }

}

export default CreateUnit;