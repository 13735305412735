import React from 'react';
import './LeftNav.scss';
import history from "../meta/history";
import {Dropdown, Icon, Tooltip, Loader, Button} from 'gd-react'
import GenericLoader from "../components/GenericLoader";

class LeftNavMiniListItem extends React.Component {

    constructor(props) {
        super(props);
    }

    goToPage(page) {
        console.log(history.location, ' : history.location')
        history.push("/" + page);
    }

    toTitleCase(str) {
        return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    }

    render() {
        let itemNameUrl = (this.props.url ? this.props.url.slice(0, -1) : this.props.name.slice(0, -1));
        let nameUrl = history.location.pathname.substring(1, this.props.url ? this.props.url.length : this.props.name.length);
        let titleName = this.props.name;
        if (!this.props.godOnly || this.props.account.god || (this.props.demo && this.props.account.username === 'example@gridduck.com')) {
            return this.props.loaded ? this.props.loaded && !this.props.account.noAssets ? <div
                className={'menu-item ' + (nameUrl === itemNameUrl ? 'selected' : '')}
                onClick={() => this.goToPage(this.props.url || this.props.name)}>
                <div className={'left-nav-icon'}>
                    {!this.props.open ? <Tooltip label={titleName} position={'right'}>
                        <Icon size={'12'} icon={this.props.icon}/>
                    </Tooltip> : <Icon size={'12'} icon={this.props.icon}/>}
                </div>
                <p>{titleName}</p>
            </div> : this.props.open ?
                <Tooltip label={'You need devices to create ' + this.props.name} position={'right'}>
                    <div style={{opacity: '0.6'}} className={'menu-item'}>
                        <div className={'left-nav-icon'}>
                            <Icon size={'12'} icon={this.props.icon}/>
                        </div>
                        <p>{titleName}</p>
                    </div>
                </Tooltip> : <div style={{opacity: '0.6'}} className={'menu-item'}>
                    <div className={'left-nav-icon'}>
                        <Tooltip label={'You need devices to create ' + this.props.name}
                                 position={'right'}>
                            <Icon size={'12'} icon={this.props.icon}/>
                        </Tooltip>
                    </div>
                    <p>{titleName}</p>
                </div> : '';
        } else {
            return null;
        }
    }

}

export default LeftNavMiniListItem;
