import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Button, Input, Pattern, Toast} from 'gd-react';
import GridDuck from 'gridduck';
import './DesktopLogin.scss';
import 'css-doodle'
import animatedLogo from '../images/animated-logo-once.svg'
import cookie from 'react-cookies'
import GenericLoader from "../components/GenericLoader";
import history from "../meta/history";
import PasswordChecker from "../components/PasswordChecker/PasswordChecker";
import {Icon} from "@material-ui/core";
import ConfirmInvite from "./ConfirmInvite";

class MobileConfirmInvite extends ConfirmInvite {
	render() {
		this.checkErrors();
		if (this.state.loggedIn === true) {
			return (<Redirect to='/siteGroup/all'/>)
		}
		return (
			<div className={'mobileLoginPage'}>
				<Link to="/" style={{
					'position': 'absolute',
					padding: '20px 30px',
					fontSize: '13px',
					color: 'grey',
					textDecoration: 'none'
				}}><Icon/>Back to login</Link>
				{this.state.loaded && !this.state.status ? <div style={{
					'display': 'flex',
					'flexDirection': 'column',
					'flex': 50,
					'justifyContent': 'start',
					'alignItems': 'center',
					overflow: 'auto'
				}}>
					<img alt={'img'} src={this.state.logoImageThumbnail ? this.state.logoImageThumbnail : '/images/duck-only.png'} className="org-logo"
						 style={{
							 marginBottom: '10px',
							 backgroundSize: 'contain',
							 backgroundPosition: 'center',
							 backgroundRepeat: 'no-repeat',
							 overflow: 'hidden',
							 width: '80px',
							 height: '80px',
							 minHeight: '80px',
							 marginTop: '100px',
							 pointerEvents: 'none !important'
						 }}/>
					<p className={'subHeader'} style={{marginBottom: '0'}}>Join {this.state.organisationName}</p>
					<p className={'header'} style={{marginBottom: '30px'}}>on {window._companyName}</p>
					<form onSubmit={this.handleSubmit} style={{'width': '80%'}}>
						<Input label={'Email'} disabled type="text"
							   value={this.state.emailAddress}/>
						<Input label={'Name'} required type="text"
							   error={this.state.triedToSave && this.errors['name']}
							   name={'name'}
							   onChange={this.onFormChange}
							   value={this.state.invite.name}/>
						<Input label={'Job Role'} required type="text"
							   error={this.state.triedToSave && this.errors['jobRole']}
							   name={'jobRole'}
							   onChange={this.onFormChange}
							   value={this.state.invite.jobRole}/>
						<PasswordChecker label={'Password'} onChange={this.pwChanged}/>
						<br/>
						<div style={{'display': 'flex', 'flexDirection': 'row-reverse'}}>
							<Button
								label="Submit"
								progressRes
								onClick={this.handleSubmit}
							/>
						</div>
						<br/>
					</form>
					<br/>
					<div>{this.state.loginError}</div>
				</div> : ''}
				{!this.state.loaded ? <div style={{
					'display': 'flex',
					'flexDirection': 'column',
					'flex': 50,
					'justifyContent': 'center',
					'alignItems': 'center'
				}}><GenericLoader  text={"Fetching"} textLineTwo={"Invite Details..."}/></div> : ''}
				{this.state.loaded && this.state.status ? <div style={{
					'display': 'flex',
					'flexDirection': 'column',
					'flex': 50,
					'justifyContent': 'center',
					'alignItems': 'center',
					overflow: 'auto'
				}}><p className={'subHeader'} style={{marginBottom: '0'}}>Whoops!</p>
					<p className={'header'} style={{marginBottom: '30px'}}>{this.state.status}</p></div> : ''}
			</div>
		);
	}
}

export default MobileConfirmInvite;
