import React from "react";
import GridDuck from 'gridduck';
import './IntegrationManagement.scss'
import PowerRadarImg from '../../images/power-radar-logo.png';
import PowerRadarModal from "./Modals/PowerRadarModal";
import {Grid} from "@material-ui/core";
import {Icon, Loader} from "gd-react";
import IntegrationStatus from "./IntegrationStatus";
import getFormat from "../../services/formatter";

let integrationOptions = [
    {
        name: 'PowerRadar',
        description: 'Stream data from PowerRadar into the GridDuck dashboard.\n\nThe sites and devices will appear as any other in your portfolio as data begins to come in.',
        id: 'power-radar',
        image: PowerRadarImg,
        modal: PowerRadarModal
    }
]

class IntegrationManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIntegration: null,
            integrationOptions: null
        };
        this.loadIntegrationData = this.loadIntegrationData.bind(this);

    }

    componentDidMount() {
      this.loadIntegrationData();
    }

    async loadIntegrationData() {
        console.log('Reload')
        let self = this;
        GridDuck.getThirdPartyIntegrations({getAll: true}).then((res) => {
            console.log(res, ' : Third Party Integrations');
            let integrations = res.list;
            let options = integrationOptions.map(io => {
                io.metadata = integrations.find(i => i.id === io.id).integrationMetaData;
                return io;
            });
            self.setState({integrationOptions: options}, () => {
                Promise.resolve();
            });
        })
    }

    render() {
        let IntegrationModalComponent = this.state.selectedIntegration ? this.state.selectedIntegration.modal : null;
        return (
            <div className={'page integration-management'}>
                <div className={'integration-options'}>
                    <Grid container spacing={2} key={'integrations'}>
                        {this.state.integrationOptions && this.state.integrationOptions.map((io) => {
                            return <Grid key={io.id} item xs={12}
                                         sm={12} md={4}
                                         lg={4} xl={3}>
                                <div className={'integration-option ' + (io.metadata.connected ? 'connected' : '')}>

                                    <div className={'inner-bordered-container'}>
                                        <img alt={io.name} src={io.image}/>
                                        <p className={'title'}>{io.name}</p>
                                        <p className={'description'}>{io.description}</p>
                                        {io.metadata.setup ? <div style={{marginTop: '5px', marginBottom: '15px'}}
                                                                  className={'divider'}/> : null}
                                        <div style={{marginBottom: '7px'}}>
                                            <IntegrationStatus inline item={io}/>
                                        </div>
                                        {io.metadata.setup && io.metadata.connected ?
                                            <p className={'status-list-item mini italic'}>
                                                <span>Last contacted:</span> {getFormat('lastContacted')(io.metadata.last_contacted)}
                                            </p> : null}
                                        <p className={'link'}
                                           onClick={() => this.setState({selectedIntegration: io})}>{io.metadata.setup ? 'Manage Connection' : 'Connect to ' + io.name}</p>

                                    </div>
                                </div>
                            </Grid>
                        })}
                    </Grid>
                </div>
                {this.state.selectedIntegration ?
                    <IntegrationModalComponent reloadData={this.loadIntegrationData} item={this.state.selectedIntegration}
                                               onClose={() => this.setState({selectedIntegration: null})}
                                               open={!!(this.state.selectedIntegration)}/> : null}
            </div>
        )
    }
}

export default IntegrationManagement;