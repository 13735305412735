import React from "react";
import GridDuck from "gridduck";
import history from "../../meta/history";
import cookie from "react-cookies";
import './sso.scss';

class InspiredLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let urlParams = new URLSearchParams(window.location.search);
        let token = urlParams.get('token');
        let self = this;
        GridDuck.createInspiredLogin({token: token})
            .then(function (loginAs) {
                let accessToken = {
                    expires: loginAs.expires,
                    token: loginAs.token,
                    mfaRequired: loginAs.mfaRequired,
                    scope: loginAs.scope,
                    tokenType: loginAs.tokenType,
                    user: {id: loginAs.user.id}
                };
                console.log("Set access token", accessToken);
                delete accessToken.scope;
                GridDuck.setAccessToken(
                    accessToken
                ).then(function () {
                    console.log("Checked token");
                    cookie.save('accessToken', accessToken, {path: '/'});
                    history.push('/');
                }).catch(function (err) {
                    GridDuck.config.accessToken = null;
                    cookie.remove('accessToken', {path: '/'});
                    self.setState({loggedOut: true});
                    history.push('/');
                    history.go(0);
                });
            }).catch(function (err) {
            GridDuck.config.accessToken = null;
            cookie.remove('accessToken', {path: '/'});
            self.setState({loggedOut: true});
            history.push('/');
            history.go(0);
        });
    }

    render() {
        return (
            <div className={"splash-screen-sso"}>
                <img src="https://gridduck-images.s3.eu-west-1.amazonaws.com/inspired.png"/>
                <span className="ouro ouro3">
                <span className="left">
                    <span className="anim"
                          style={{"background": "none repeat scroll 0 0 " + window._brandColor + ""}}></span></span>
                <span className="right">
                    <span className="anim"
                          style={{"background": "none repeat scroll 0 0 " + window._brandColor + ""}}></span></span>
            </span>
            </div>
        )
    }
}

export default InspiredLogin;
