import React from 'react';
import GridDuck from 'gridduck';
import {List, StandardListCell, StatusListCell} from "gd-react";
import NoItems from "../../../components/noItems";
import moment from "moment-timezone";
import getFormat from "../../../services/formatter";
import AuditModal from "../../../modals/AuditFile/AuditModal";
import history from "../../../meta/history";

class AuditHubsPage extends React.Component {
	constructor(props) {
		super(props);
		let self = this;
		this.getHubs = this.getHubs.bind(this)
		this.openHubModal = this.openHubModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.goToSite = this.goToSite.bind(this)
		this.state = {
			modalOpen: false,
			audit: null,
			hub: null,
			hubs: this.props.item ? this.props.item.hubs : null
		}

	}

	getHubs() {
		if (this.state.hubs) return this.state.hubs
		let {auditId} = this.props.match.params;
		return GridDuck.getAudit({
			id: auditId
		}).then((audit) => {
			let resList = {};
			resList.list = audit.gateways;
			this.setState({loaded: true, audit: audit})
			console.log(audit.gateways, ' audit.gateways')
			return resList
		})
	}

	openHubModal(hub) {
		let self = this;
		self.setState({modalOpen: true, hub: hub})
	}

	closeModal() {
		let self = this;
		self.setState({modalOpen: false, hub: null})
	}

	goToSite() {
		history.push('/site/' + this.state.audit.siteId + '/settings/hubs')
	}

	render() {

		let auditHeader;

		if (this.state.audit) auditHeader = (
			<div className={'audit-header'}>
				<p className={'audit-header-text'}>Information below fixed as
					of {getFormat('lastContacted')(this.state.audit.createdDate)}
				</p>
				<div
					className={'audit-site-link'}
					onClick={this.goToSite}><p className={'audit-header-text link'}>Go to current hubs</p></div>
			</div>
		)

		let modal;

		if (this.state.loaded) modal = <AuditModal audit={this.state.audit} itemType={"site"} itemId={this.state.audit.siteId} item={this.state.hub} previewType={'hub'} onClose={this.closeModal} open={this.state.modalOpen} />

		let permissions;

		permissions = (
			<List
				noitemelem={<NoItems text={'No Hubs Audited'}/>}
				columns={[
					{
						component: StandardListCell,
						sortable: true,
						title: 'Name',
						field: 'name',
					},
					{
						component: StatusListCell,
						sortable: false,
						title: 'Status',
						field: 'disconnected',
					},
					{
						component: StandardListCell,
						title: 'Power Source',
						field: 'powerSource',
					},
					{
						component: StandardListCell,
						title: 'Connection Type',
						field: 'connectionType',
						maxWidth: 'none'
					},
				]}
				getlist={this.getHubs}
				rowOnClick={this.openHubModal}
				filters={[]}
			/>
		)


		return (
			<div className='page'>
				{auditHeader}
				{permissions}
				{modal}
			</div>
		)
	}
}

export default AuditHubsPage;
