import React from "react";
import {Menu} from '@material-ui/core'
import {Icon, Dropdown, Button} from "gd-react";
import GridDuck from 'gridduck'
import GenericLoader from "./GenericLoader";
import getFormat from "../services/formatter";
import AddEditCustomFieldTypeModal from "../modals/AddEditCustomField/AddEditCustomFieldType";
import {isMobile} from "react-device-detect";

class CustomKpiMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            loading: true,
            comparativeKpi: Object.keys(this.props.kpiTotals)[0],
            showCustomFieldTypeModal: false
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        console.log(this.props.kpiTotals, ' : Kpi Totals');
    }

    handleClick(e) {
        let self = this;
        self.setState({
            anchorEl: e ? e.currentTarget : this.state.anchorEl,
            loading: true,
            comparativeKpi: Object.keys(this.props.kpiTotals)[0]
        });
        // Load Menu Items
        GridDuck.getCustomFieldTypes({getAll: true}).then((cfts) => {
            console.log(this.props.customKpis, ' : custom kpis');
            console.log(cfts.list, ' : cfts.list');
            let cftsNumbers = cfts.list.filter((c) => c.fieldType === 'number' && !this.props.customKpis.find(ckpi => ckpi.id === c.id));
            // this.props.customKpis
            console.log(cftsNumbers, ' : cftsNumbers');

            self.setState({customFieldTypes: cftsNumbers, loading: false, allCustomFieldTypes: cfts.list});
        });
    }

    handleClose() {
        this.setState({anchorEl: null});
    }

    render() {

        return (
            <div className={'filter-menu'}>
                <div style={{position: 'relative', overflow: 'hidden'}} onClick={this.handleClick}>
                    {this.props.children}
                </div>
                <Menu
                    id="filter-menu"
                    getContentAnchorEl={null}
                    anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                    transformOrigin={{vertical: "top", horizontal: "left"}}
                    anchorEl={this.state.anchorEl}
                    open={!!(this.state.anchorEl)}
                    disableEnforceFocus
                    onClose={this.handleClose}>
                    <div className={'filter-menu-container kpi-tooltip'}>
                        <div className={'menu-header'}>
                            Add KPI
                        </div>
                        {!this.state.loading && this.state.allCustomFieldTypes && this.state.allCustomFieldTypes.length ?
                            <div className={'minimal-dropdown'}>
                                <Dropdown style={{width: '100%'}} value={this.state.comparativeKpi}
                                          label={''} nolabel={'true'}
                                          fixeditems={Object.keys(this.props.kpiTotals).filter(k => k !== 'change').map((kpit) => {
                                              return {
                                                  id: kpit,
                                                  title: getFormat('kpiTitle')(kpit),
                                                  value: kpit
                                              }
                                          })}
                                          onChange={(val) => this.setState({comparativeKpi: val.target.value})}/>
                            </div> : null}
                        {!this.state.loading && this.state.allCustomFieldTypes && this.state.allCustomFieldTypes.length ?
                            <p style={{fontSize: '12px', margin: '10px 0'}}>by total...</p> : null}
                        <div className={'filter-items'}>
                            {!this.state.loading && this.state.customFieldTypes ? this.state.customFieldTypes.map(cft => {
                                return <div key={cft.id} className={'row icon-row'}
                                            onClick={() => {
                                                this.props.onCustomFieldSelected(cft, this.state.comparativeKpi);
                                                this.handleClose();
                                            }}>
                                    <Icon size={'14'} color={cft.colour} icon={cft.icon}/>
                                    <p>{cft.name}</p>
                                </div>
                            }) : null}
                            {!isMobile && !this.state.loading && this.state.allCustomFieldTypes && this.state.allCustomFieldTypes.length ?
                                <div key={'add-new'} className={'row icon-row'}
                                     onClick={this.props.showCustomFieldModal}>
                                    <Icon size={'14'} color={'#49b9c4'} icon={'FaPlus'}/>
                                    <p style={{color: '#49b9c4'}}>Create New</p>
                                </div> : null}
                            {!this.state.loading && (!this.state.customFieldTypes || !this.state.customFieldTypes.length) && (!this.state.allCustomFieldTypes || !this.state.allCustomFieldTypes.length) ?
                                <div style={{
                                    display: 'flex',
                                    'flexDirection': 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '10px'
                                }}><span
                                    style={{fontSize: '12px', marginBottom: '10px'}}>You have not yet created any Custom Fields</span><Button
                                    label={'Create Custom Field'}
                                    onClick={this.props.showCustomFieldModal}
                                    style={{
                                        height: '20px',
                                        fontSize: '11px',
                                        lineHeight: '20px',
                                        marginTop: '5px'
                                    }}/></div> : null}
                            {this.state.loading ? <GenericLoader/> : null}
                        </div>
                    </div>
                </Menu>
            </div>
        )
    }
}

export default CustomKpiMenu;