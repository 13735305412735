import React from "react";
import GridDuck from 'gridduck';
import getFormat from "../../services/formatter";
import {
    Button,
    Icon,
} from "gd-react";
import history from "../../meta/history";
import {CollapsibleTable} from "gd-react";
import '../../styles/_layout.scss'
import GenericLoader from "../../components/GenericLoader";
import NoItems from "../../components/noItems";

class OutstandingAlertsPage extends React.Component {
    constructor() {
        super();
        let self = this;
        this.state = {};
        this.processIncidents = this.processIncidents.bind(this);
        this.ignoreIncident = this.ignoreIncident.bind(this);
        this.ignoreAll = this.ignoreAll.bind(this);
        Promise.all([
                GridDuck.getIssues({
                    getAll: true,
                    items: 200
                }),
                GridDuck.getAccount({id: GridDuck.userId})
            ]
        )
            .then(function (results) {
                let incidents = results[0];
                let account = results[1];
                console.log(incidents, " : incidentsatsa")
                self.setState({
                    incidents: incidents,
                    ignoredAssets: account.ignoredAssets,
                    ignoredSites: account.ignoredSites,
                    loaded: true
                });
                incidents.on('updated', self.processIncidents);
                // account.on('updated', self.processIncidents);
                self.account = account;
                self.incidents = incidents;
                self.processIncidents();
            });
    }

    ignoreAll() {
        let ignoredAssets = this.account.ignoredAssets;
        this.state.incidents.list.forEach(function(incident) {
            if (ignoredAssets.indexOf(incident.objectId) === -1) {
                ignoredAssets.push(incident.objectId);
            }
        })
        return this.account.setIgnoredAssets(ignoredAssets);
    }

    componentWillUnmount() {
        let self = this;
        if (self.incidents) {
            self.incidents.off('updated', self.processIncidents);
        }
        if (self.account) {
            self.account.off('updated', self.processIncidents);
        }
    }

    ignoreIncident(objectId, event) {
        event.stopPropagation();
        event.preventDefault();
        let ignoredAssets = this.account.ignoredAssets;
        if (ignoredAssets.indexOf(objectId) === -1) {
            ignoredAssets.push(objectId);
        }
        return this.account.setIgnoredAssets(ignoredAssets);
    }

    processIncidents() {
        console.log("process incidents", this.account.ignoredAssets.length);
        let siteIncidents = [];

        if (this.state.incidents) {
            this.state.incidents.list
                .filter(i => !i.timeEnd)
                .filter(i => this.account.ignoredSites.indexOf(i.siteId) === -1 && this.account.ignoredAssets.indexOf(i.objectId) === -1)
                .forEach(function (incident) {
                    let thisSiteIncident = siteIncidents.find(i => i.siteId === incident.siteId);
                    if (!thisSiteIncident) {
                    	thisSiteIncident = {
							siteId: incident.siteId,
							siteName: incident.siteName,
							incidents: [incident]
						}
                        siteIncidents.push(thisSiteIncident);
                    } else {
                        thisSiteIncident.incidents.push(incident);
                    }
                    if (incident.inferredAssetIds) {
						incident.inferredAssetIds.forEach(function(inferredAssetId, inferredIndex) {
							thisSiteIncident.incidents.push({
								id: incident.id,
								objectId: inferredAssetId,
								objectName: incident.inferredAssetNames[inferredIndex],
								siteId: incident.siteId,
								siteName: incident.siteName,
								timeStart: incident.timeStart,
								type: "inferredAsset"
							});
						})
					}
                });
            if (!siteIncidents.length) {
				this.setState({siteIncidents: null});
				return;
            }
            console.log("got siteIncidents", siteIncidents);
            this.setState({siteIncidents: siteIncidents});
        }
    }

    render() {
        let self = this;
        let incidentCount = 0;
        if (this.state.incidents && this.state.incidents.list && self.account) {
            this.state.incidents.list
                .filter(i => !i.timeEnd)
                .filter(i => self.account.ignoredSites.indexOf(i.siteId) === -1 && self.account.ignoredAssets.indexOf(i.objectId) === -1)
                .forEach(function (incident) {
                    incidentCount++;
                    if (incident.inferredAssetIds) {
                        incidentCount += incident.inferredAssetIds.length;
                    }
                })
        }
        return (
            <div className={"page"}>
                <div style={{
                    marginTop: '10px',
                    marginRight: '20px',
                    marginBottom: '-30px',
                    flexDirection: 'row-reverse',
                    display: 'flex',
                    zIndex: 10
                }}>
                    <Button style={{'width': 'auto'}} progressRes label={"Ignore all " + incidentCount + " issues"} onClick={this.ignoreAll}/>
                </div>
                {this.state.loaded && this.state.siteIncidents ? <CollapsibleTable
                    columns={{
                    outerColumns: [{title: 'Site'}, {title: '# of Issues'}],
                    innerColumns: [{title: 'Type'}, {title: 'Device'}, {title: 'Status'}, {title: 'Date/Time'}, {title: 'Ignore'}]
                }} tableData={
                    this.state.siteIncidents.map(function (incidentsO) {
                        return {
                            id: incidentsO.siteId,
                            data: [
                                {
                                    value: <span className={'list-hover'}
                                                 onClick={() => incidentsO.siteId ? history.push('/site/' + incidentsO.siteId + '/devices') : history.push('/')}>{incidentsO.siteName}</span>
                                },
                                {value: incidentsO.incidents.length}
                            ],
                            innerData:
                                incidentsO.incidents.map(function (incidentsI) {
                                    return [
                                        {
                                            value: incidentsI.type === 'gateway' ? 'Hub' : incidentsI.type === 'inferredAsset' ? 'Device (Due to Disconnected Hub)' : 'Device'
                                        },
                                        {
                                            value:
                                                incidentsI.type === 'gateway' ?
                                                    (<span className={'list-hover'}
                                                           onClick={() => history.push('/hub/' + incidentsI.objectId)}>{incidentsI.objectName}</span>)
                                                    : (<span className={'list-hover'}
                                                             onClick={() => history.push('/device/' + incidentsI.objectId)}>{incidentsI.objectName}</span>)

                                        },
                                        {value: <span style={{color: 'red'}}>Disconnected</span>},
                                        {value: getFormat('lastContacted')(incidentsI.timeStart)},
                                        {
                                            value: incidentsI.type !== 'inferredAsset' ? (<Icon
                                                onIconClick={(e) => self.ignoreIncident(incidentsI.objectId, e)}
                                                color={'gd-red'} size={'20'} icon={"MdSnooze"}/>) : ''
                                            }]
                                })
                        }
                    })
                }/> : ''}
                {!this.state.loaded ? <GenericLoader text={"Fetching"} textLineTwo={"Outstanding Alerts..."}/> : ''}
                {this.state.loaded && !this.state.siteIncidents ?
                    <NoItems disabled text={'Good news! You have no outstanding issues.'}/> : ''}
            </div>
        );
    }
}

export default OutstandingAlertsPage;
