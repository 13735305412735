import React from "react";
import "./RuleList.scss";
import GridDuck from "gridduck";
import RuleCard from "../components/RuleCard/RuleCard";
import {StandardListCell, List, TickListCell, Icon} from "gd-react";
import history from "../meta/history";
import NoItems from "../components/noItems";
import AddAutomationModal from "../modals/Automation/AddAutomationModal";

class AutomationListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getList = this.getList.bind(this);
        this.goToRule = this.goToRule.bind(this);
    }

    goToRule(rule) {
        history.push("/automations/" + rule.id);
        this.setState({showAutomationModal: rule});
    }

    componentDidMount() {
        let self = this;
        let automationId = self.props.match.params.automationId;
        if (automationId) {
            GridDuck.getRule({id: automationId}).then((rule) => {
                self.setState({showAutomationModal: rule})
            })
        }
    }

    getList(retrievalParams) {
        return GridDuck.getRules(retrievalParams);
    }

    render() {
        return (
            <div className={"page white"}>
                <List
                    noitemelem={<NoItems text={'You haven\'t created any automations yet'} createtext={'Create Automation'}
                                         onclickfunc={() => this.setState({createAutomation: true})}/>}
                    columns={[
                        {
                            component: StandardListCell,
                            bold: true,
                            title: 'Name',
                            field: 'name',
                            sortable: true,
                            defaultSort: 'asc'
                        },
                        {
                            component: StandardListCell,
                            title: 'Type',
                            field: 'template',
                            maxWidth: '150px',
                            custom: function (v) {
                                let icon = 'FaCalculator';
                                let color = 'gd-grey'
                                let name = v.template === 'alert' ? 'Notification' : v.template ? v.template : 'Custom';

                                if (v.template === 'alert') {icon = 'FaBell'; color = 'orange';}
                                if (v.template === 'thermostat') {icon = 'FaThermometerThreeQuarters'; color = 'gd-red';}
                                if (v.template === 'timeswitch') {icon = 'FaHourglassHalf'; color = 'gd-blue';}
                                if (!v.template) {
                                    icon = 'GiMaterialsScience';
                                    color = 'gd-grey';
                                }
                                return <div><Icon size={'14'} icon={icon} color={color}/> <span style={{marginLeft: '5px'}}>{name}</span></div>

                            }
                        },
                        {component: StandardListCell, title: 'Description', field: 'description', maxWidth: 'none'},
                        // {component: TickListCell, title: 'Active', field: 'active', maxWidth: '100px'}
                    ]}
                    getlist={this.getList}
                    filters={[
                        {
                            field: 'automations',
                            value: true
                        }
                    ]}
                    rowOnClick={this.goToRule}/>
                {this.state.showAutomationModal || this.state.createAutomation ? <AddAutomationModal onClose={() => {
                    this.setState({showAutomationModal: null, createAutomation: null});
                    history.push('/automations');
                }} open={!!this.state.showAutomationModal || this.state.createAutomation} item={this.state.showAutomationModal}/> : ''}
            </div>
        )
    }
}

export default AutomationListPage;
