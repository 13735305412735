import React from "react";
import {Redirect} from "react-router-dom";
import history from "./../../meta/history";

class NotFoundPage extends React.Component {
	render() {
		history.notFound = true;
		return (
			<Redirect to='/siteGroup/all'/>
		)
	}
}

export default NotFoundPage;
