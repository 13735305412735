import React from 'react';
import './MobileTopNav.scss';
import Logout from "../components/Logout";
import BreadcrumbList from "../components/BreadcrumbList";
import {Menu, Icon, Input, GlobalSearch, Loader, GdAutocomplete} from 'gd-react';
import GridDuck from "gridduck";
import history from "../meta/history"
import {Tooltip} from "@material-ui/core";
import applyTheme from "../services/generate-theme";
import {Portal} from "react-portal";
import ThemePicker from "./ThemePicker";

const themes = {
    light: {
        icon: 'FaSun',
        title: 'Light'
    },
    dark: {
        icon: 'FaMoon',
        title: 'Dark'
    }
}
const toCamel = (s) => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
};

class MobileTopNav extends React.Component {

    constructor(props) {
        super(props);
        this.globalSearch = this.globalSearch.bind(this);
        this.onSelectFromSearch = this.onSelectFromSearch.bind(this);
        this.updateAccountInfo = this.updateAccountInfo.bind(this);
        this.updateModalState = this.updateModalState.bind(this);
        this.goToPage = this.goToPage.bind(this);
        // this.handleKeyPress = this.handleKeyPress.bind(this);
        let self = this;
        this.state = {
            open: false,
            darkLightMode: 'light',
            accentPalette: 'default',
            accentPalettes: null,
            showThemePicker: false
        };


        Promise.all([
            GridDuck.getAccount({id: GridDuck.userId})
        ])
            .then(function (results) {
                self.user = results[0];
                let darkLight = self.user.darkLight ? self.user.darkLight : 'light'
                let theme = self.user.theme ? self.user.theme : 'default'
                self.user.on('updated', self.updateAccountInfo);
                let customTheme;
                if (self.user.customTheme) {
                    customTheme = {
                        light: {},
                        dark: {}
                    }

                    // self.user.customTheme
                    for (let snake in self.user.customTheme.dark) {
                        customTheme.dark[toCamel(snake)] = self.user.customTheme.dark[snake];
                    }
                    for (let snake in self.user.customTheme.light) {
                        customTheme.light[toCamel(snake)] = self.user.customTheme.light[snake];
                    }
                }

                let defaultItem = self.user.themes.find(t => t.code === 'default');
                let defaultIndex = self.user.themes.findIndex(t => t.code === 'default');

                if (window._defaultThemeOverride && defaultItem) {
                    self.user.themes.splice(defaultIndex, 1)
                    self.user.themes.unshift(window._defaultThemeOverride);
                }


                self.setState({
                    user: self.user,
                    darkLightMode: darkLight,
                    accentPalette: theme,
                    accentPalettes: self.user.themes,
                    customTheme: customTheme
                }, () => {
                    self.loadTheme(darkLight, theme);
                });
            });
        // document.addEventListener("keyDown", this.handleKeyPress, false);
        // document.addEventListener("keyUp", this.handleKeyPress, false);
    }

    goToPage(page) {
        history.push("/" + page);
    }

    updateModalState(stateName, value) {
        this.state[stateName] = value;
        this.setState(this.state);
    }

    loadTheme(theme, accentPalette, customPalette) {
        let self = this;
        console.log(this.state.accentPalettes, ' : this.state.accentPalettes');
        if (this.state.accentPalettes && this.state.accentPalettes.length) {
            console.log(theme, ' : Load Theme');
            console.log(accentPalette, ' : accentPalette');
            console.log(this.state.accentPalettes.find(ap => ap.code === accentPalette), ' : accentPalettes[accentPalette]');
            let palette = accentPalette && this.state.accentPalettes.find(ap => ap.code === accentPalette) ? this.state.accentPalettes.find(ap => ap.code === accentPalette).palette : null;
            let cp = this.state.customTheme || customPalette || this.state.accentPalettes.find(ap => ap.code === 'default').palette;
            if (accentPalette === 'custom') {
                palette = cp;
            }
            applyTheme({
                theme: theme,
                accentPalette: accentPalette,
                accentPalettePalette: palette
            }, themes, this.state.accentPalettes, (t, ap) => {
                let userUpdateObject = {darkLight: t, theme: ap, customTheme: cp};
                console.log(userUpdateObject, ' : user update object');
                self.state.user.set(userUpdateObject);
                this.setState({darkLightMode: t, accentPalette: ap, customTheme: cp});
            });
        }
    }

    componentWillUnmount() {
        if (this.user && this.user.off) {
            this.user.off('updated', this.updateAccountInfo);
        }
    }

    updateAccountInfo() {
        this.setState({user: this.user});
    }

    onSelectFromSearch(ev, item) {
        this.setState({globalSearchValue: null});
        switch (item.icon) {
            case 'FaHome':
                history.push("/site/" + item.id);
                break;
            case 'FaPlug':
                history.push("/device/" + item.id);
                break;
            case 'MdRouter':
                history.push("/hub/" + item.id);
                break;
            case 'FaLayerGroup':
                history.push("/group/" + item.id);
                break;
            case 'FaClock':
                history.push("/timeswitch/" + item.id);
                break;
            case 'FaCalculator':
                history.push("/rule/" + item.id);
                break;
            default:
                console.log("Type not supported yet...");
        }
    }

    globalSearch(search) {
        console.log(search, ' : search text');
        let self = this;
        return GridDuck.getSearchs({
            filters: [
                {
                    field: 'query',
                    value: search
                }
            ]
        }).then(function (results) {

            self.setState({
                searchResults: results.list.map(function (item) {
                    let icon = 'FaHome';
                    switch (item.type) {
                        case 'site':
                            icon = 'FaHome';
                            break;
                        case 'asset':
                            icon = 'FaPlug';
                            break;
                        case 'gateway':
                            icon = 'MdRouter';
                            break;
                        case 'group':
                            icon = 'FaLayerGroup';
                            break;
                        case 'timeswitch':
                            icon = 'FaClock';
                            break;
                        case 'rule':
                            icon = 'FaCalculator';
                            break;
                    }

                    return ({
                        id: item.id,
                        type: item.type,
                        title: item.name,
                        subTitle: '',
                        icon: icon
                    });
                })
            });
        });
    }

    render() {
        let self = this;
        let matches, acronym, searchResults;
        if (this.state.user) {
            matches = this.state.user.name.match(/\b(\w)/g); // ['J','S','O','N']
            acronym = matches.join(''); // JSON
        }

        // icon: "FaHome"
        // id: "27f4758e-8df1-4e5b-93d9-bce6397d90ca"
        // subTitle: ""
        // title: "Chula Trial Site 2"

        if (this.state.searchResults && this.state.searchResults.length) {
            console.log(this.state.searchResults, ' : search results');
            searchResults = this.state.searchResults.map(function (item) {
                return item.type === 'site' || item.type === 'asset' ? <div onClick={(ev) => {
                    self.onSelectFromSearch(ev, item);
                    self.setState({open: false, searchResults: null})
                }} className={'mobile-search-list-item'}>
                    <Icon icon={item.icon}
                          color={'gd-grey'} size={'16'}/>
                    <p>{item.title}</p>
                </div> : ''
            });
        }


        return (
            <div id="top_nav">
                <BreadcrumbList {...this.props} isMobile={true}/>
                <div className={'globalSearchMobile ' + (this.state.open ? 'open' : '')}>
                    {!this.state.open ? <div className={'icon-wrapper'} onClick={() => this.setState({open: true})}>
                        <Icon icon={'FiSearch'}
                              color={'gd-grey'} size={'18'}/>
                    </div> : ''}

                    {this.state.open ? <div className={'search-body'}>
                        <div className={'search-header'}>
                            <div className={'icon search'}>
                                <Icon icon={'FiSearch'} color={'gd-grey'} size={'18'}/>
                            </div>
                            <Input onChange={(val) => this.globalSearch(val.target.value)} placeholder={'Global search'}
                                   autoFocus/>
                            <div className={'icon cancel'} onClick={() => this.setState({open: false})}>
                                <Icon icon={'FaTimes'}
                                      color={'gd-grey'} size={'14'}/>
                            </div>
                        </div>
                        <div className={'search-body-inner'}>
                            {searchResults}
                            <div className={'mobile-search-list-item'}
                                 style={{borderBottom: 'none', minHeight: '800px'}}/>
                        </div>
                    </div> : ''}
                </div>
                {/*{this.state.user ? <Tooltip*/}
                {/*    title={'Switch to ' + (this.state.darkLightMode === 'light' ? 'dark ' : 'light ') + 'mode'}>*/}
                {/*    <div className={'top-nav-icon on-mobile'}><Icon*/}
                {/*        onIconClick={() => this.loadTheme(this.state.darkLightMode === 'light' ? 'dark' : 'light', this.state.accentPalette)}*/}
                {/*        icon={this.state.darkLightMode === 'light' ? 'FaMoon' : 'FaSun'}*/}
                {/*        color={'gd-grey'} size={'16'}/></div>*/}
                {/*</Tooltip> : null}*/}
                {this.state ?
                    <Menu menuHeader={{
                        title: this.state.user ? this.state.user.name : <Loader/>,
                        subtitle: this.state.user ? this.state.user.username : <Loader/>
                    }} controlFromElement={<Tooltip title={this.state.user ? 'Account' : 'Loading account'}>
                        <div className={'profile-image'} style={{
                            backgroundImage: 'url("' + (this.state.user ? this.state.user.profilePictureThumbnail : '') + '")',
                            color: 'white',
                            fontWeight: '700',
                            fontSize: '12px',
                            textAlign: 'center',
                            lineHeight: '26px'
                        }}>{this.state.user ? this.state.user.profilePictureThumbnail ? '' : acronym :
                            <Loader type={'circular'} size={12}/>}</div>
                    </Tooltip>} menuItems={[
                        {
                            icon: {
                                color: 'gd-grey',
                                name: 'FaPaintRoller',
                                size: '12'
                            },
                            label: 'Theme',
                            onMenuItemClick: function () {
                                self.setState({showThemePicker: true});
                            }
                        },{
                            icon: {
                                color: 'gd-grey',
                                name: this.state.darkLightMode === 'light' ? 'FaMoon' : 'FaSun',
                                size: '12'
                            },
                            label: this.state.darkLightMode === 'light' ? 'Dark Mode' : 'Light Mode',
                            onMenuItemClick: () => this.loadTheme(this.state.darkLightMode === 'light' ? 'dark' : 'light', this.state.accentPalette)
                        }, {
                            icon: {
                                color: 'gd-grey',
                                name: 'FaPowerOff',
                                size: '12'
                            },
                            label: 'Logout',
                            onMenuItemClick: function () {
                                GridDuck.logout();
                            }
                        }
                    ]}/>
                    : <div className={'profile-image'} style={{
                        borderColor: 'grey'
                    }}/>}


                {this.state.showThemePicker && self.state.user ?
                    <Portal node={document && document.getElementById('decorated_page')}>
                        <ThemePicker userTheme={self.state.customTheme}
                                     mobile
                                     selectTheme={(t) => self.loadTheme(self.state.darkLightMode, t.code)}
                                     updateCustomTheme={(ct) => self.loadTheme(self.state.darkLightMode, 'custom', ct.palette)}
                                     theme={this.state.accentPalette} darkLight={this.state.darkLightMode}
                                     themes={this.state.accentPalettes}
                                     closePicker={() => this.setState({showThemePicker: false})}/>
                    </Portal> : null}
            </div>
        );
    }
}

export default MobileTopNav;
