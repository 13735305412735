import React from "react";
import {Button} from "gd-react";
import "./Appliances.scss";
import Appliance from "./Appliance";

class Appliances extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let self = this;
        return (
            <div className={'appliances'}>
                {this.props.appliances.map(function (appliance, i) {
                    return <Appliance key={appliance.id} removeOne={self.props.removeOne} onUpdate={self.props.onUpdate} i={i} appliances={self.props.appliances}
                                      applianceTypeData={self.props.applianceTypeData} appliance={appliance}/>
                })}
                {this.props.appliances.length < this.props.applianceTypeData.length ?
                    <Button disabled={!this.props.validForm} onClick={this.props.addAnother} label={'Add Another'}/> : null}
            </div>
        )
    }
}

export default Appliances;