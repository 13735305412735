import React from "react";
import {
    List, ProcessStateListCell, DataRequestDetailListCell, ButtonListCell, Button, StandardListCell
} from "gd-react";
import GridDuck from 'gridduck';
import './../dataDownload/DataArchive.scss'


class DownloadLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getList = this.getList.bind(this);
    }

    getList(retrievalParams) {
        retrievalParams.filters = retrievalParams.filters ? retrievalParams.filters : [];
        retrievalParams.filters.push(
            {
                field: 'god_get_all',
                value: true
            }
        );
        return GridDuck.getDataRequests(retrievalParams);
    }


    render() {
        let self = this, columns;

        columns = [
            {component: StandardListCell, title: 'Status', field: 'status'},
            {component: StandardListCell, title: 'Type', field: 'status', custom: function(obj) {
                    return (obj.startDate && obj.startDate.indexOf && obj.startDate.indexOf('-') !== -1) ? 'Invoice' : 'Data Download';
                }},
            {
                component: StandardListCell, title: 'Start Date', field: 'startDate', custom: function (obj) {
                    if (obj.startDate && obj.startDate.indexOf && obj.startDate.indexOf('-') !== -1) {
                        return new Date(obj.startDate).toLocaleString();
                    } else if (obj.startDate) {
                        return new Date(obj.startDate * 1000).toLocaleString();
                    }
                }
            },
            {
                component: StandardListCell, title: 'End Date', field: 'endDate', custom: function (obj) {
                    if (obj.endDate && obj.endDate.indexOf && obj.endDate.indexOf('-') !== -1) {
                        return new Date(obj.endDate).toLocaleString();
                    } else if (obj.endDate) {
                        return new Date(obj.endDate * 1000).toLocaleString();
                    }
                }
            }
        ]
        return (

            <div className={"page data-archive"}>
                <List
                    columns={columns}
                    getlist={this.getList}
                    filters={[]}/>
            </div>
        )
    }
}

export default DownloadLog;