import React from "react";
import TabbedPage from "../../components/TabbedPage/TabbedPage";
import CreateDataDownload from "./CreateDataDownload";
import DataArchive from "./DataArchive";


class DataDownloadMainPage extends React.Component {
    render() {
        return (
            <div className={'page white'}>
                <DataArchive dataDownloader dataLoaded={true} className={"download-page"} {...this.props}/>
            </div>
        )
    }
}

export default DataDownloadMainPage;

