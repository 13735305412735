import React from "react";
import "./Appliances.scss";
import {Dropdown, Icon, Input} from "gd-react";

class Appliance extends React.Component {

    constructor(props) {
        super(props);

        console.log(null, ' : typeId');
        this.state = {
            appliance: this.props.appliance.typeId || '',
            number: this.props.appliance.number || '1'
        };
        this.changeApplianceType = this.changeApplianceType.bind(this);
        this.changeNumber = this.changeNumber.bind(this);
    }

    changeApplianceType(a) {
        let typeId = a.target.value;
        this.setState({appliance: typeId});
        this.props.onUpdate({i: this.props.i, key: 'typeId', val: typeId});
    }

    changeNumber(a) {
        let number = a.target.value;
        if (a.target.value && a.target.value <= 0) number = 1;
        this.setState({number: number});
        this.props.onUpdate({i: this.props.i, key: 'number', val: number});
    }

    render() {
        let self = this;

        return (
            <div className={'row appliance'} style={{marginBottom: '15px'}}>
                <Dropdown value={this.state.appliance} nolabel='true' label={''} top='true'
                          placeholder={'Select Appliance'}
                          fixeditems={this.props.applianceTypeData.map((a) => {
                              return {title: a.name, value: a.typeId}
                          }).filter((a) => {
                              return (!self.props.appliances.find((oa) => oa.typeId === a.value) || self.props.appliance.typeId === a.value);
                          })}
                          onChange={this.changeApplianceType}/>
                <div className={'number-wrapper'}>
                    <Input nolabel='true' top='true' InputProps={{step: 1, min: 1}} type={'number'}
                           value={this.state.number}
                           onChange={this.changeNumber}/>
                </div>
                {this.props.appliances.length > 1 ?
                    <div className={'icon-wrapper'}><Icon color={'gd-red'} size={'14'} icon={'FaTrash'} onIconClick={() => this.props.removeOne(this.props.i)}/></div> : null}
            </div>
        )
    }
}

export default Appliance;