import React from "react";
import {Card, CardBody, Icon} from "gd-react";
import "./TemplateCard.scss";

class TemplateCard extends React.Component {
	render() {
		let icon, name, description, color;
		switch (this.props.type) {
			case 'timeswitch':
				icon = 'FaHourglassHalf';
				color = 'gd-blue'
				name = 'Timeswitch';
				description = 'Tell your devices what time and days they should turn on or off';
				break;
			case 'thermostat':
				icon = 'FaThermometerThreeQuarters';
				color = 'gd-red'
				name = 'Virtual Thermostat';
				description = 'Turn any ordinary electric heater into a thermostatically controlled one';
				break;
			case 'alert':
				icon = 'FaBell';
				color = 'orange'
				name = 'Email Notification';
				description = 'Send an email in response to a condition passing';
				break;
			case 'balancing':
				icon = 'FaBalanceScale';
				color = 'gd-blue'
				name = 'Balancing';
				description = 'Turn off devices when another turns on. Typically used for peak avoidance';
				break;
			default:
				icon = 'GiMaterialsScience';
				color = 'gd-blue'
				name = 'Custom';
				description = 'Chain together rules and actions to do almost anything';
		}
		return (
			<div className={"template-card"}  {...this.props}>
				{/*<CardBody>*/}
					<div className={"left-section"}>
						<Icon color={color} icon={icon} size={'20'}/>
					</div>
					<div className={"right-section"}>
						<h4>{name}</h4>
						<p>{description}</p>
					</div>
				{/*</CardBody>*/}
			</div>
		)
	}
}

export default TemplateCard;
