import React from "react";
import {Loader} from "gd-react";
import './GenericLoader.scss'

//TODO: put this in gd-react
class GenericLoader extends React.Component {
    render() {
        return (
            <div style={this.props.style} className={'loader-wrapper'}>
                <div className={'loader-inner'}>
                    <Loader color={this.props.color} size={this.props.size} type={'circular'}/>
                    {this.props.text ? <p className={'loader-text'} style={{fontWeight: '700'}}>{this.props.text}</p> : null}
                    {this.props.textLineTwo ? <p className={'loader-text'} style={{fontStyle: 'italic', fontSize: '12px', marginTop: '10px'}}>{this.props.textLineTwo}</p> : null}
                </div>
            </div>
        )
    }
}

export default GenericLoader;
