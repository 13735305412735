import pSBC from "./pSBC";

const generatePaletteFromBrandCol = function (col) {
    if (!col) {
        col = '#49b9c4';
    }
    return {
        "light": {
            "listItemActive": pSBC(0.7, col, '#ffffff'),
            "listItemHover": "#f6f6f6",
            "border": "#e8e8e8",
            "navBackground": "#ffffff",
            "primaryAccent": col
        },
        "dark": {
            "listItemActive": pSBC(0.7, col, '#000000'),
            "listItemHover": "#2e3a43",
            "border": "#424242",
            "navBackground": "#262e34",
            "primaryAccent": col
        }
    }
}

export default generatePaletteFromBrandCol;