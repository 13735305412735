import React, {Component} from "react";
import '../../styles/_layout.scss';
import './Gaia.scss'
import GridDuck from "gridduck";
import {
    Button,
    CardBody,
    Tabs,
    List,
    Icon,
    StandardListCell,
    EditableList,
    Tooltip
} from "gd-react";
import getFormat from "../../services/formatter";
import GenericLoader from "../GenericLoader";
import GaiaConversationRow from "./ConversationRowItem";

class GaiaConversation extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className={'conversations'}>
                <div className={'column'} style={{
                    overflowX: 'hidden',
                    position: 'relative'
                }}>
                    {this.props.loaded && this.props.conversations && this.props.conversations.length ? this.props.conversations.map(c => {
                        return <GaiaConversationRow conversation={c} key={c.id} onSelect={this.props.onSelect}/>
                    }) : null}
                    {!this.props.loaded ? <GenericLoader/> : null}
                    {this.props.loaded && !this.props.conversations.length && !this.props.loading ?
                        <div className={'no-chats-yet'}>
                            <div className={'row'}>
                                <Icon size={50} icon={'GiConversation'}/>
                            </div>
                            <div className={'row'}>
                                No Chats yet.<br/><br/> Enter text below to start a conversation.
                            </div>
                        </div> : null}
                    {this.props.loading ? <div className={'conversation'}>
                        <div className={'row'}>
                            <p className={'convo-title'}>Generating conversation...</p>
                        </div>
                    </div> : null}
                </div>
            </div>
        )
    }
}

export default GaiaConversation;