import React from "react";
import {Icon} from "gd-react";

class FileUploadDropper extends React.Component {
    constructor(props) {
        super(props);
        this.dropRef = React.createRef();
        this.handleDragIn = this.handleDragIn.bind(this);
        this.handleDragOut = this.handleDragOut.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.state = {
            dragging: false
        };
    }

    handleDragIn(e) {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({dragging: true})
        }
    }

    handleDragOut(e) {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--
        if (this.dragCounter > 0) return
        this.setState({dragging: false})
    }

    handleDrag(e) {
        console.log("drag");
        e.preventDefault();
        e.stopPropagation();
    }

    handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({dragging: false})
        console.log(e.dataTransfer.files, ' : e.dataTransfer.files');
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            if (this.props.downloadFiles) {
                this.props.downloadFiles(e.dataTransfer.files)
            }
            e.dataTransfer.clearData()
            this.dragCounter = 0
        }
    }

    componentDidMount() {
        this.dragCounter = 0;
        let div = this.dropRef.current
		if (!this.props.disabled) {
			div.addEventListener('dragenter', this.handleDragIn)
			div.addEventListener('dragleave', this.handleDragOut)
			div.addEventListener('dragover', this.handleDrag)
			div.addEventListener('drop', this.handleDrop)
		}
    }

    componentWillUnmount() {
        let div = this.dropRef.current
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }

    render() {
        let draggingOverlayClass = "dragging-overlay";
        if (this.state.dragging) {
            draggingOverlayClass += " active";
        }
        let prompt;
        if (this.props.showPrompt && !this.props.disabled) {
            prompt = (<div className={"prompt"}>
                <div className={'prompt-items'}>
                    <Icon size={'50'} color={"gd-lightgrey"} icon={'FaCloudUploadAlt'}/>
                    <div className={"image-text"}>
                        Drop your files here to upload
                    </div>
                </div>
            </div>);
        }
        //onClick={this.props.onClick}
        return (
            <div ref={this.dropRef} className={"file-dropper"}>
                <div className={draggingOverlayClass}>
                    {prompt}
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default FileUploadDropper;
