import React from "react";
import {Icon, Input, Dropdown, StateOptions, ScheduleTimeline, ScheduleSetter, Button} from "gd-react";
import "../Rules.scss";
import GridDuck from 'gridduck';
import history from "../../meta/history";
import DeleteModal from "../../modals/Delete/DeleteModal";
import moment from "moment";

class RulePageTimeswitch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assetNames: [],
            showDeleteModal: false
        };
        this.formatTime = this.formatTime.bind(this)

        let self = this;
        if (props.rule.alerts && props.rule.alerts[0] && props.rule.alerts[0].assets) {
            let promises = props.rule.alerts[0].assets.map(a => GridDuck.getAsset({id: a}));
            Promise.all(promises).then(function (results) {
                self.setState({
                    assetIds: props.rule.alerts[0].assets,
                    assetNames: results.map((a, i) => <span style={{marginLeft: (i !== 0) ? '8px' : '0'}}
                                                            className={'link'}
                                                            onClick={() => history.push('/device/' + a.id)}>{a.name}{(i !== results.length - 1) ? ',' : ''}</span>)
                });
            })
        }
        if (props.rule.actions && props.rule.actions.length > 2) {
            // todo fix me
            // let assets = props.rule.actions.find(a => a.assets.length).assets;
            // let promises = assets.map(a => GridDuck.getAsset({id: a}));
            let promises = []
            Promise.all(promises).then(function (results) {
                self.setState({
                    assetIds: props.rule.actions[1].assets,
                    assetNames: results.map((a, i) => <span style={{marginLeft: (i !== 0) ? '8px' : '0'}}
                                                            className={'link'}
                                                            onClick={() => history.push('/device/' + a.id)}>{a.name}{(i !== results.length - 1) ? ',' : ''}</span>)
                });
            })

        }
    }

    isDifferentAssets(oldAssets, newAssets) {
        if ((!oldAssets || !newAssets) || oldAssets.length !== newAssets.length) {
            return true;
        }
        return !oldAssets.every((value, index) => value === newAssets[index]);
    }

    componentDidMount() {
        this.setState({specificSchedule: (this.props.rule.actions && this.props.rule.actions.length > 2)})
    }

    formatTime(unix) {
        return moment(unix).format("YYYY-MM-DDTHH:mm")
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let self = this;

        if (this.props.rule.alerts && this.props.rule.alerts[0]) {
            if (this.isDifferentAssets(prevProps.rule.alerts[0].assets, this.props.rule.alerts[0].assets)) {
                let promises = self.props.rule.alerts[0].assets.map(a => GridDuck.getAsset({id: a}));
                Promise.all(promises).then(function (results) {
                    self.setState({
                        assetNames: results.map(a => a.name),
                        assetIds: results.map(a => a.id)
                    });
                })
            }
        } else if (this.props.rule.actions && this.props.rule.actions.length === 3) {
            let actionRule = this.props.rule.actions.find(a => a.id === 1);
            let actionRulePrev = prevProps.rule.actions.find(a => a.id === 1);
            if (this.isDifferentAssets(actionRulePrev.assets, actionRule.assets)) {
                if (actionRule.assets) {
                    let promises = actionRule.assets.map(a => GridDuck.getAsset({id: a}));
                    Promise.all(promises).then(function (results) {
                        self.setState({
                            assetNames: results.map(a => a.name),
                            assetIds: results.map(a => a.id)
                        });
                    })
                }
            }
        }
    }

    render() {
        let stateOptions = []
        StateOptions.forEach(function (stateOption) {
            stateOption.options.forEach(function (option) {
                if (!option.nonSelectable && !stateOptions.find(sO => sO.value === option.state)) {
                    stateOptions.push({
                        value: option.state,
                        title: option.label,
                        color: option.color
                    });
                }
            });
        });
        let dateDisplay;
        if (this.state.specificSchedule) {
            let timeActions = this.props.rule.actions.filter((s) => s.type === 'TIME')
            let rows = timeActions.map((s) => {
                let activeAction = this.props.rule.actions.find((a) => a.id === s.activeAction)
                let state = activeAction.stateType
                return <div
                    className={'row'}
                    style={{marginTop: '10px'}}
                >
                    <div className={'date-cell'}>
                        <label>Start</label>
                        <Input
                            type="datetime-local"
                            disabled
                            id="meeting-time"
                            name="meeting-time"
                            value={this.formatTime(s.startTime)}
                        />
                    </div>
                    <div className={'date-cell'}>
                        <label>End</label>
                        <Input
                            type="datetime-local"
                            disabled
                            error={this.state.triedToProgress && this.errors['time']}
                            id="meeting-time"
                            name="meeting-time"
                            value={this.formatTime(s.endTime)}
                        />
                    </div>
                    <div className={'date-cell'}>
                        <label>State</label>
                        <Dropdown
                            style={{marginTop: '15px'}}
                            nolabel='true'
                            disabled
                            value={state}
                            fixeditems={stateOptions}/>
                    </div>
                </div>
            })
            dateDisplay = <div>
                {rows}
            </div>
        } else {
            dateDisplay = <div
                style={{marginTop: '10px'}}
            >
                <ScheduleTimeline
                    assetType={this.props.rule.assetType ? this.props.rule.assetType : 'default'}
                    inactiveState={this.props.rule.inactiveState ? this.props.rule.inactiveState : 'OFF'}
                    rules={this.props.rule.triggers ? this.props.rule.triggers.map(t => t.details) : []}
                    activeState={this.props.rule.actions && this.props.rule.actions[2] ? this.props.rule.actions[2].stateType : this.props.rule.alerts[0].type}/>
                <ScheduleSetter
                    inactiveState={this.props.rule.inactiveState ? this.props.rule.inactiveState : 'OFF'}
                    assetType={this.props.rule.assetType ? this.props.rule.assetType : 'default'}
                    rules={this.props.rule.triggers ? this.props.rule.triggers.map(t => t.details) : []}
                />
            </div>
        }

        let link;
        if (this.props.rule && !this.props.isMobile) {
            link = (
                <div style={{display: 'inline-block', float: 'left'}}>
                    <Button label={"View Rule"} color={'gd-grey'}
                            onClick={() => history.push("/automations/" + this.props.rule.id)}/>
                </div>
            )
        }

        return (
            <div className={"details"}>
                <div className={"details-inner"}>
                    {/*<div className={"detail-card"}>*/}
                    {/*    <Icon color={'gd-blue'} size={!this.props.strippedback ? '50' : '20'} icon={'FaHourglassHalf'}/>*/}
                    {/*    <div className={"right-content"}>*/}
                    {/*        <h1>{this.props.rule.name}</h1>*/}
                    {this.props.rule.description ? <p>{this.props.rule.description}</p> : null}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {this.props.strippedback ? link : ''}
                    {!this.props.strippedback ? <div className={"timeswitch-preview"}>
                            {dateDisplay}
                        </div>
                        : ''}
                    {!this.props.strippedback && this.state.assetNames.length ?
                        <div className={"timeswitch-actions"}>
                            <p>Applied to:</p>
                            <div className={"action"}>
                                {this.state.assetNames}
                            </div>
                        </div>
                        : ''}
                </div>
            </div>
        )
            ;
    }
}

export default RulePageTimeswitch;
