import React, {Component} from "react";
import "./MenuItem.css";
import Menu from "./Menu";
import {Icon, Tooltip} from 'gd-react'

class MenuItem extends Component {

	constructor(props) {
		super(props);
		let color;
		if (props.color === 'gd-green') color = '#49c570';
		if (props.color === 'gd-red') color = '#e43536';
		if (props.color === 'gd-blue') color = '#03a9f4';
		if (props.color === 'gd-grey') color = '#595959';
		if (props.color === 'black') color = '#000000';
		if (props.color === 'white') color = '#ffffff';
		if (!color) {
			color = props.color;
		}
		this.state = {color: color, uid: Math.random()}
	}

	render() {
		let self = this;

		function menuClick(e) {
			if (self.props.closeMenu) self.props.closeMenu();
			if (self.props.subMenu) self.props.closeMenu();
			if (!self.props.subMenu && self.props.closeParent) self.props.closeParent();
			if (self.props.onMenuItemClick) self.props.onMenuItemClick(e);
		}

		if (!self.props.subMenu) {
			return !self.props.disabled ? <div className={'MenuItem'} onClick={menuClick.bind(self)}>
				<Icon icon={self.props.icon.name} color={self.props.icon.color} size={self.props.icon.size}/>
				<p style={{color: this.state.color}}>{self.props.label}</p>
			</div> : <Tooltip label={this.props.disabled.message} position={this.props.disabled.position}><div className={'MenuItem'} style={{opacity: '0.5'}}>
				<Icon icon={self.props.icon.name} color={self.props.icon.color} size={self.props.icon.size}/>
				<p style={{color: this.state.color}}>{self.props.label}</p>
			</div></Tooltip>
		} else {
			return <Menu lvl={self.props.subMenu.depth} createdByMenu={this.props.inMenu} createdByMenuItem={this}
						 closeParent={self.props.closeMenu} menuHeader={self.props.subMenu.header}
						 controlFromElement={<div className={'MenuItem ' + (this.state.selected ? 'selected' : '')}
												  onClick={menuClick.bind(self)}>
							 <Icon icon={self.props.icon.name} color={self.props.icon.color}
								   size={self.props.icon.size}/>
							 <p style={{color: this.state.color}}>{self.props.label}</p>
							 <span style={{'display': 'flex', flex: '1 1'}}/>
							 <Icon icon={'MdKeyboardArrowDown'} color={'gd-grey'}/>
						 </div>} menuItems={self.props.subMenu.items}/>
		}
	}
}

export default MenuItem;
