import React from "react";
import {Button, Icon, Input} from "gd-react";

class FileSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileName: '',
            fileDescription: ''
        }
        this.onClick = this.onClick.bind(this);
        this.hasChanged = this.hasChanged.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.fileUpdatedExternally = this.fileUpdatedExternally.bind(this);
    }

    fileUpdatedExternally(field, val) {
        switch (field) {
            case 'name':
                this.setState({'fileName': val});
                break;
            case 'description':
                this.setState({'fileDescription': val});
                break;
            default:
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps || this.props.selectedFiles !== prevProps.selectedFiles) {
            if (this.props.selectedFiles.length === 1) {
                let selectedFile = this.props.selectedFiles[0];
                if (selectedFile.on) {
                    selectedFile.on('updated', this.fileUpdatedExternally)
                }
                this.setState({
                    fileName: selectedFile.name ? selectedFile.name : '',
                    fileDescription: selectedFile.description ? selectedFile.description : ''
                })
            } else {
                this.setState({
                    fileName: '',
                    fileDescription: ''
                });
            }
        }
    }

    onClick(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    //FIXME: react filters?
    readableFileSize(input) {
        let i = -1;
        let byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            input = input / 1024;
            i++;
        } while (input > 1024);

        return Math.max(input, 0.1).toFixed(1) + byteUnits[i];
    };

    //FIXME: general place for this
    generalFileType(fileMime) {
        if (fileMime.indexOf('image') !== -1) {
            return 'image';
        } else if (fileMime.indexOf('pdf') !== -1) {
            return 'pdf';
        } else if (fileMime === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            return 'powerpoint';
        } else if (fileMime === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileMime === 'application/msword') {
            return 'word';
        } else if (fileMime.indexOf('video') !== -1) {
            return 'video';
        } else {
            return 'other'
        }
    };

    handleNameChange(newName) {
        newName.preventDefault();
        newName.stopPropagation();
        this.setState({
            fileName: newName.target.value
        });
    }

    handleDescriptionChange(newDescription) {
        newDescription.preventDefault();
        newDescription.stopPropagation();
        console.log("handle description change");
        this.setState({
            fileDescription: newDescription.target.value
        });
    }

    hasChanged() {
        let selectedFile = this.props.selectedFiles[0];
        return (this.state.fileName !== selectedFile.name) || ((this.state.fileDescription !== selectedFile.description) && this.state.fileDescription);

    }

    handleUpdate() {
        let selectedFile = this.props.selectedFiles[0];
        let promises = [];
        if (this.state.fileName !== selectedFile.name) {
            promises.push(selectedFile.setName(this.state.fileName));
        }
        if (this.state.fileDescription !== selectedFile.description) {
            promises.push(selectedFile.setDescription(this.state.fileDescription));
        }
        return Promise.all(promises);
    }

    render() {
        let heading;
        let preview;
        let size;
        let fileList;
        let fileDetails;
        let button;
        console.log(this.props.files.map(f => f.size));
        if (this.props.selectedFiles.length === 0) {
            heading = <p className={'file-heading-text'}>{this.props.files.length} files</p>;
            preview = (<Icon size={'35'} color={"gd-grey"} icon={'FaLayerGroup'}/>);
            let sizeVal = this.readableFileSize(this.props.files.reduce((o, f) => o + f.size, 0));
            size = (
                <p><span className={"size-heading"}>Size:</span><span className={"size-value"}>{sizeVal}</span>
                </p>);
            let files = this.props.files.map(f => (
                <div key={f.id} className="file-name" onClick={() => this.props.openFile(f)}>{f.name}</div>))
            fileList = (<div className={"file-name-list"}>{files}</div>)
        } else if (this.props.selectedFiles.length > 1) {
            heading = <p className={'file-heading-text'}>{this.props.selectedFiles.length} files selected</p>;
            preview = (<Icon size={'35'} color={"gd-grey"} icon={'FaLayerGroup'}/>);
            let sizeVal = this.readableFileSize(this.props.selectedFiles.reduce((o, f) => o + f.size, 0));
            size = (
                <p><span className={"size-heading"}>Size:</span><span className={"size-value"}>{sizeVal}</span>
                </p>);
            let files = this.props.selectedFiles.map(f => (
                <span key={f.id + '2'} className="file-name" onClick={() => this.props.openFile(f)}>{f.name}</span>))
            fileList = (<div className={"file-name-list"}>{files}</div>)
        } else {
            let selectedFile = this.props.selectedFiles[0];
            heading = (
                <Input inputRef={this.props.nameInputRef}
					   disabled={this.props.readOnly}
                       nolabel='true' label={''} value={this.state.fileName}
                       onChange={this.handleNameChange}/>)
            //TODO: file types
            let fileType = this.generalFileType(selectedFile.type);
            if (fileType === 'pdf') {
                preview = (<Icon size={'34'} color={'gd-red'} icon={'FaFilePdf'}/>)
            }
            if (fileType === 'image') {
                preview = (<div className={"image-preview"}
                                style={{backgroundImage: "url(" + selectedFile.thumbnail + ")"}}/>)
            }
            if (fileType === 'word') {
                preview = (<Icon size={'34'} color={'gd-blue'} icon={'FaFileWord'}/>)
            }
            if (fileType === 'powerpoint') {
                preview = (<Icon size={'34'} color={'gd-red'} icon={'FaFilePowerpoint'}/>)
            }
            if (fileType === 'video') {
                preview = (<Icon size={'34'} color={'gd-red'} icon={'FaFileVideo'}/>)
            }
            if (fileType === 'other') {
                preview = (<Icon size={'34'} color={'gd-red'} icon={'FaFile'}/>)
            }


            let sizeVal = this.readableFileSize(selectedFile.size);
            size = (<p><span className={"size-heading"}>Size:</span><span className={"value"}>{sizeVal}</span></p>);
            fileDetails = [
                (<div key={'type'} className={"file-details"}><p><span className={"file-heading"}>Type:</span><span
                    className={"value"}>{selectedFile.location.split('.').pop().split(/[#?]/)[0]}</span></p>
                </div>),
                (<div key={'details'} className={"file-details"}><p><span
                    className={"file-heading"}>Uploaded by:</span><span
                    className={"value"}>{selectedFile.uploadedByName}</span></p></div>),
                (<div key={'description'} className={"file-description"}><textarea
                    ref={this.props.descInputRef}
					disabled={this.props.readOnly}
                    placeholder={this.props.readOnly ? "" : "Enter a description or take some notes on the file here"}
                    onChange={this.handleDescriptionChange} value={this.state.fileDescription}/>
                </div>)
            ];
            if (this.hasChanged()) {
                button = (<Button
                    label="Save Changes"
                    progressRes
                    onClick={this.handleUpdate}
                />);
            }
        }

        return (
            <div className={"file-summary"} onClick={this.onClick}>
                <div className={"heading"}>{heading}</div>
                <div className={"preview"}>{preview}</div>
                <div className={"size"}>{size}</div>
                {fileDetails}
                <div className={'button-wrapper'}>
                    {button}
                </div>
                {fileList}
            </div>
        );
    }
}

export default FileSummary;
