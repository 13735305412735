import React from "react";
import './AssetPage.scss';
import TabbedPage from "../../components/TabbedPage/TabbedPage";
import AssetOverviewPage from "./AssetOverviewPage";
import GridDuck from "gridduck";
import AssetSettingsDetails from "./AssetSettingsDetails";
import AssetSettingsEventLog from "./AssetSettingsEventLog";
import {StateOptions} from "gd-react";
import DataPage from "../data/DataPage";
import history from "../../meta/history";
import GatewayAdmin from "../gateway/GatewayAdmin";
import AssetAdmin from "./AssetAdmin";
import MainAssetPage from "./MainAssetPage";
import MobileDataPage from "../data/MobileDataPage";
import MobileAssetOverviewPage from "./MobileAssetOverviewPage";
import DesktopDataPage from "../data/DesktopDataPage";

class MobileMainAssetPage extends MainAssetPage {
    render() {
        let dataTabs = [];
        let stateOptions;
        if (this.state.asset) {
            stateOptions = StateOptions.find(t => t.type === this.state.asset.sku);
            if (!stateOptions) {
                stateOptions = StateOptions.find(t => t.type === 'default');
            }
            console.log("sku", this.state.asset.sku);
        }
        if (stateOptions) {
            stateOptions.dataTypes.filter(dT => !dT.downloadOnly)
                .forEach(function (dataType) {
                    if (!dataTabs.find(dT => dT.title === dataType.category) && (dataType.type.toLowerCase().indexOf('spend') === -1)) {
                        dataTabs.push({
                            default: dataTabs.length === 0,
                            url: dataType.type.toLowerCase(),
                            title: dataType.category,
                            dataType: dataType,
                            component: MobileDataPage
                        })
                    }
                })
        }
        let tabs = [
            {
                default: true,
                url: 'data',
                title: 'Data',
                tabs: dataTabs
            }, {
                url: 'overview',
                title: 'Overview',
                component: MobileAssetOverviewPage,
                tabs: []
            }
        ];
        return (
            <TabbedPage
                filterType={'asset_id'}
                type={'asset'}
                filterId={this.state.asset ? this.state.asset.id : ''}
                isMobile={true} dataLoaded={this.state.asset} className={"asset-page"} tabs={tabs}
                        item={this.state.asset} dataItems={[this.state.asset]} {...this.props}/>
        )
    }

}

export default MobileMainAssetPage;

