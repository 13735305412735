import React from "react";
import {
    Button,
    List, StandardListCell
} from "gd-react";
import GridDuck from 'gridduck';
import history from "../../meta/history";
import NoItems from "../../components/noItems";
import CreateUnit from "./CreateUnit";
import formatValue from "../../services/formatterV2";
import {camelfy} from "../../services/CamelfyRequestData";
import GenerateExampleUnit from "../../services/GenerateExampleUnit";
import _ from "lodash";

const pageUrl = '/data-management/settings/custom-units'

class UnitList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUnit: null,
            createUnit: false,
            updateRef: 0
        };
        this.getList = this.getList.bind(this);
    }

    componentDidMount() {
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (user) {
                self.setState({user: user});
                let urlParams = new URLSearchParams(window.location.search);
                let unitId = urlParams.get('id');
                if (unitId) {
                    camelfy((...args) => GridDuck.getDataUnit(...args), {id: unitId}).then((model) => {
                        self.setState({selectedUnit: model});
                    });
                }
            });
    }

    async getList(params) {
        //FIXME tmp object cameler to account for betazoid sending object in snake
        return camelfy((...args) => GridDuck.getDataUnits(...args), params);
    }

    render() {
        return (
            this.state.user ? <div className={"page modbus-models"}>
                <div className={'widget-board-header'}>
                    <div className={'row reverse place-in-header'}>
                        {this.state.user.orgPermission !== 'view' ?
                            <Button color={'gd-green'} label={'+ Add New Unit System'}
                                    onClick={() => this.setState({
                                        createUnit: true
                                    })}/> : null}
                    </div>
                </div>
                <List
                    noitemelem={<NoItems text={'You have no Unit Systems'}/>}
                    updateRef={this.state.updateRef}
                    columns={[{
                        component: StandardListCell,
                        title: 'Name',
                        field: 'name',
                        maxWidth: '150px'
                    }, {
                        component: StandardListCell,
                        title: 'Type',
                        capitalize: true,
                        unCamel: true,
                        field: 'unitType',
                        maxWidth: '150px'
                    }, {
                        component: StandardListCell,
                        custom: function (item) {
                            return GenerateExampleUnit(item)
                        },
                        title: 'Example Vales',
                        field: 'metadata'
                    }, {
                        component: StandardListCell,
                        title: 'Data Type',
                        capitalize: true,
                        field: 'dataType',
                        maxWidth: '150px'
                    }]}
                    getlist={this.getList}
                    rowOnClick={(item) => {
                        this.setState({selectedUnit: item});
                        // history.push(pageUrl + '?id=' + item.id);
                    }}
                    filters={[
                        {field: 'customOnly', value: true}
                    ]}/>

                {this.state.selectedUnit || this.state.createUnit ?
                    <CreateUnit isGod={this.state.user.god}
                                orgPerm={this.state.user.orgPermission}
                                item={this.state.selectedUnit}
                                onClose={() => {
                                    this.setState({
                                        createUnit: false,
                                        selectedUnit: null,
                                        updateRef: Math.random()
                                    });
                                    // history.push(pageUrl);
                                }}
                                open={this.state.selectedUnit || this.state.createUnit}/> : null}
            </div> : null
        )
    }
}

export default UnitList;