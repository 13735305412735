import React from "react";
import {List, StandardListCell, EditableAccessListCell} from "gd-react";
import GridDuck from 'gridduck';
import ShareWithOrg from "../../components/ShareWithOrg";
import GenericLoader from "../../components/GenericLoader";


class SiteSettingsAccess extends React.Component {
    constructor(props) {
        super(props);
        this.getList = this.getList.bind(this);
        this.state = {}
        let self = this;
        this.updateAccessTo = this.updateAccessTo.bind(this);
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (account) {
                self.setState({user: account});
            });
    }

    getList() {
        let self = this;
        return GridDuck.getSitePermissions({
            filters: [
                {
                    field: 'siteId',
                    value: self.props.item.id
                }
            ]
        });
    }

    updateAccessTo(sitePermission, newAccess) {
        console.log("Update access to ", sitePermission, newAccess);
        if (newAccess === 'revoke') {
            sitePermission.delete();
        } else {
            sitePermission.setPermName(newAccess);
        }
    }

    render() {
        let permissions;
        if (this.props.item && this.state.user) {
            permissions = (
                <List
                    nohover
                    columns={[
                        {
                            component: StandardListCell,
                            bold: true,
                            title: 'Name',
                            field: 'orgName',
                            sortable: true
                        },
                        {
                            component: EditableAccessListCell,
                            title: 'Access level',
                            field: 'permName',
                            myPerm: this.props.item._permission,
                            myOrg: this.state.user.organisationId,
                            updateAccessTo: this.updateAccessTo,
                            maxWidth: 'none'
                        },
                    ]}
                    getlist={this.getList}
                    filters={[]}/>
            )
        }
        return (
            <div className='page'>
                <div className={"notice"} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                   <span style={{paddingRight: '30px'}}>This is where you can manage the access level that another organisation has to
                    "{this.props.item.name}"</span>
                    {this.props.item._permission === 'admin' ? (<ShareWithOrg site={this.props.item}/>) : ''}
                </div>
                {/*<div className={"edit-row"} style={{marginTop: '-94px'}}></div>*/}
                {this.props.loaded ? permissions : <GenericLoader/>}

            </div>
        );
    }
}

export default SiteSettingsAccess;
