import React, {Component} from "react";
import '../../styles/_layout.scss';
import './Gaia.scss'
import getFormat from "../../services/formatter";
import {Icon, Loader, Menu, Tooltip} from 'gd-react';
import history from "../../meta/history";

class GaiaConversationRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            updateCount: 0
        }
        this.updateConversation = this.updateConversation.bind(this);
        this.props.conversation.on('updated', this.updateConversation);
    }

    updateConversation() {
        console.log(this.props.conversation, ' : this.props.conversation');
        this.setState({updateCount: this.state.updateCount + 1});
    }

    render() {
        let c = this.props.conversation;
        return (
            <div className={'conversation'} onClick={() => this.props.onSelect(c)}>
                <div className={'column'}>
                    <div className={'row'}>
                        <p className={'convo-title'}>{c.title}</p>
                    </div>
                    <div className={'row'}>
                        <p className={'last-updated'}>{getFormat('lastContacted')(c.updatedAt)}</p>
                    </div>
                </div>
                <div className={'chat-menu-icon'}>
                    <Menu controlFromElement={<Icon icon={'FaEllipsisV'}/>} menuItems={[{
                        icon: {
                            color: 'gd-red',
                            name: 'FaTrash',
                            size: '12'
                        },
                        label: 'Delete',
                        onMenuItemClick: function () {
                            c.delete();
                        }
                    }]}/>
                </div>
            </div>
        )
    }
}

export default GaiaConversationRow;