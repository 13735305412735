import React from 'react';
import './Account.scss';
import GridDuck from 'gridduck';
import {Card, CardBody, CardHeader, Button, Icon, Tooltip} from "gd-react";
import GenericLoader from "../../components/GenericLoader";
import moment from "moment-timezone";

class AccountInvoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        let self = this;
        this.formatter = new Intl.NumberFormat('en-UK', {
            style: 'currency',
            currency: 'GBP',
        });
        console.log(this.props.item.invoices, ' : this.props.item.invoices');
        GridDuck.getGridduckInvoices({getAll: true}).then(function (invoices) {
            console.log(invoices, ' : subscriptions');
            self.setState({invoices: invoices.list, loaded: true});
        });
    }

    toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    render() {
        let self = this;
        return (
            <div className='page narrow Account not-flex grey'>
                <div className={'column top'}>
                    <div className={'detail-content'}  style={{maxWidth: '1000px'}}>
                        {(this.state.invoices && this.state.invoices.length) ? <Card>
                            <CardHeader>GridDuck Invoice History</CardHeader>
                            <table className={'invoices'}>
                                <tbody>
                                <tr>
                                    <th>Amount</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Number</th>
                                    <th>Created</th>
                                    <th/>
                                </tr>
                                {this.state.invoices && this.state.invoices.map(function (invoice) {
                                    if (invoice.total || (invoice.totalDiscountAmounts && invoice.totalDiscountAmounts.length)) {
                                        return <tr key={invoice.id} className={'invoice'}>
                                            <td>{self.formatter.format(invoice.total / 100)}</td>
                                            <td>{invoice.description || 'Subscription'}</td>
                                            <td>{self.toTitleCase(invoice.status)}</td>
                                            <td style={{minWidth: '100px'}}>{invoice.number}</td>
                                            <td>{moment.unix(invoice.created).format('DD/MM/YYYY')}</td>
                                            <td><Tooltip title={'Download PDF'} label={'Download PDF'} position={'top'}><Icon
                                                icon={'FaDownload'} color={'gd-blue'}
                                                onIconClick={() => window.open(invoice.hostedInvoiceUrl, '_blank')}/></Tooltip>
                                            </td>
                                        </tr>
                                    }
                                })}
                                </tbody>
                            </table>

                        </Card> : null}
                        {!this.state.loaded ? <GenericLoader text={"Fetching"} textLineTwo={"Invoices..."}/> : null}
                        {this.state.loaded && !this.state.invoices.length ? <Card>
                            <CardHeader>Invoice History</CardHeader>
                            <CardBody>
                                <p>You don't have any invoices yet</p>
                            </CardBody>
                        </Card> : null}
                    </div>
                </div>

            </div>
        );
    }
}

export default AccountInvoices;