import React from "react";
import GridDuck from "gridduck";
import {Button, CardBody, Input} from "gd-react";
import {Link} from "react-router-dom";
import {Icon} from "@material-ui/core";
import animatedLogo from "../images/animated-logo-once.svg";
import PasswordChecker from "../components/PasswordChecker/PasswordChecker";
import history from "../meta/history";
import PasswordReset from "./PasswordReset";

class DesktopPasswordReset extends PasswordReset {
    render() {
        let checking;
        let result;
        if (!this.state.checked) {
            checking = (<p>Checking your token</p>);
        } else {
            if (this.state.validated && !this.state.resetComplete) {
                result = (
                    <div style={{width: '400px'}}>
                        {/*<Input label={'New password'} value={this.state.password} onChange={this.handlePasswordChange}/>*/}
                        {/*       onChange={this.handleConfirmPasswordChange}/>*/}
                        <PasswordChecker onChange={this.handlePasswordChange}/>
                        <div style={{
                            'display': 'flex',
                            'flexDirection': 'row-reverse',
                            marginTop: '30px',
                            justifyContent: 'center'
                        }}>
                            <Button
                                label="Reset password"
                                progressRes
                                disabled={!this.state.passed}
                                onClick={this.reset}
                            />
                        </div>
                    </div>

                );
            } else if (this.state.checked && !this.state.validated && !this.state.resetComplete) {
                result = (<div> <p className={'subHeader'}>Whoops!</p>
                    <p className={'header'}>Invalid token</p>
                </div>);
            }else if (this.state.checked && this.state.validated && this.state.resetComplete) {
                result = (<div> <p className={'subHeader'}>Success</p>
                    <p className={'header'}>Your password has been reset</p>
                </div>);
            }
        }
        // <div className={"password-reset"}>
        //     Reset your password? {this.token}
        //     {checking}
        //     {result}
        // </div>
        return (
            <div className={'loginPage'}>
                <Link to="/" style={{
                    'position': 'absolute',
                    padding: '20px 30px',
                    fontSize: '13px',
                    color: 'grey',
                    textDecoration: 'none'
                }}><Icon/>Back to login</Link>
                {!this.state.resetPasswordSent ? <div style={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'flex': 50,
                    'justifyContent': 'center',
                    'alignItems': 'center'
                }}>
                    {result}

                </div> : <div style={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'flex': 50,
                    'justifyContent': 'center',
                    'alignItems': 'center'
                }}>
                    <p className={'subHeader'}>Request Sent!</p>
                    <p className={'header'}>Check your email for a link to reset your password</p>
                </div>}
                <div style={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'overflow': 'hidden',
                    'flex': 50,
                    justifyContent: 'center',
                    alignItems: 'center',
                    'backgroundColor': '#00141e'
                }}>

                    <img src={animatedLogo} className={'animated-logo'}/>

                    <css-doodle grid='20'>
                        {':doodle { grid-gap: 5px; width: 50vw; height: 100vh; position: relative; } border-radius: 100%; border: 0px solid rgb(44 71 82); background: #001e28; --scaledelay: calc(( (@abs(@abs(@row - @size-row/3) + @abs(@col - @size-col/4) - @size-col) / @size-col) - 1) * -4s) ; animation-name: scale; animation-duration: 5s; animation-iteration-count: infinite; animation-timing-function: ease; animation-delay: var(--scaledelay); transform: scale(0.2); opacity: 0.2; @keyframes scale { 0%{ transform: scale(0.2); opacity: 0.2; } 50%{ transform: scale(0.8); opacity: 0.7; } 100%{ transform: scale(0.2); opacity: 0.1 } }'}
                    </css-doodle>

                </div>
            </div>
        )
    }
}

export default DesktopPasswordReset;
