import React from "react";
import GridDuck from "gridduck";
import {Button, Card, CardBody, CardHeader, Input, Toast} from "gd-react";
import cookie from "react-cookies";
import history from "./../../meta/history";

class LoginAs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: ''
        };
        this.onFormChange = this.onFormChange.bind(this);
        this.loginAsUser = this.loginAsUser.bind(this);
    }

    loginAsUser() {
        return GridDuck.createLoginAs({username: this.state.emailAddress})
            .then(function (loginAs) {
                console.log("got loginAs", loginAs);
                let accessToken = {
                    expires: loginAs.expires,
                    token: loginAs.token,
                    mfaRequired: loginAs.mfaRequired,
                    scope: loginAs.scope,
                    tokenType: loginAs.tokenType,
                    user: {id: loginAs.user.id}
                };
                delete accessToken.scope;
                GridDuck.setAccessToken({
                    accessToken
                });
                cookie.save('accessToken', accessToken, {path: '/'});
                history.push("/")
                window.location.reload();
            });
    }

    onFormChange(val) {
        this.setState({emailAddress: val.target.value});
    }

    render() {
        return (
            <div className='page narrow Account not-flex grey'>

                <div className={'column top'}>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader>Login as another user</CardHeader>
                            <CardBody><Input value={this.state.emailAddress} onChange={this.onFormChange}
                                             label={'Email address'}/>
                                <br/>
                                <Button additionalclasses={'sm'} color={'gd-green'} onClick={this.loginAsUser}
                                        label={'Submit'}/>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginAs;
