import React, {Component} from "react";
import "./LocationModal.scss";
import '../styles/_layout.scss';
import {
    Button,
    CardBody,
    EditableList,
    Input,
    StandardListCell,
    Tabs
} from "gd-react";
import GridDuck from "gridduck";
import TariffForm from "../components/TariffForm";
import * as _ from 'underscore'
import GenericLoader from "../components/GenericLoader";
import GdModal from "../components/GdModal/GdModal";

class TariffModal extends Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.moveToTab = this.moveToTab.bind(this);
        this.saveSites = this.saveSites.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.saveDetails = this.saveDetails.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.deleteTariff = this.deleteTariff.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this)
        this.getActiveList = this.getActiveList.bind(this)
        this.sitesChanged = this.sitesChanged.bind(this)
        this.errors = {};
        this.changed = false;
        this.tariffFormRef = React.createRef();
        let item = {};
        if (this.props.item) item = this.props.item;
        let _rates = item.rates;
        //FIXME Why is this happening (Really not the fix we want)
        if (item.rates && typeof item.rates === 'string') {
            _rates = JSON.parse(item.rates);
        }

        this.aperm_levels = ['edit', 'admin'];
        this.perm_levels = ['edit', 'admin'];
        if (this.props.item && this.props.item._permission === 'view') this.perm_levels.unshift('view');
        console.log(this.props.sites, ' : sites from prop');
        this.state = {
            update: 0,
            formUpdated: false,
            wide: false,
            newSitesList: [],
            existingSitesList: [],
            deleteConfirmation: '',
            name: item.name || '',
            currency: item.currency || 'GBP',
            utilityType: item.utilityType || this.props.utilityType || 'DELIVERED_SPEND',
            triedToSave: false,
            rates: _rates ? _.map(_rates, _.clone).map((r, i) => {
                r.id = Math.random();
                return {
                    startHour: r.start_hour || r.startHour || 0,
                    startMinute: r.start_minute || r.startMinute || 0,
                    endHour: r.end_hour || r.endHour || 0,
                    endMinute: r.end_minute || r.endMinute || 0,
                    rate: r.rate,
                    id: Math.random(),
                    index: i
                };
            }) : [{
                startHour: 0,
                startMinute: 0,
                endHour: 0,
                endMinute: 0,
                rate: 0,
                id: Math.random()
            }],
            standingCharge: item.standingCharge || 0
        }

        this.tabs = [[
            {
                id: 1,
                title: 'Details',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 1 || !this.props.openOnTab)
            },
            {
                id: 2,
                title: 'Sites',
                onTabClick: this.onTabClick,
                disabled: !this.props.item,
                selected: (this.props.openOnTab === 2)
            }
        ]]

        if (this.props.item && this.props.item._permission !== 'view' && this.props.orgPerm !== 'view') {
            this.tabs[0].push({
                id: 3,
                title: 'Delete',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 3)
            })
        }

        this.modalClosed = false;
    }

    componentDidMount() {

        let site_promises = [GridDuck.getSites({
            filters: [{field: '_permission', value: this.perm_levels}],
            items: 1,
            offset: 0
        })];

        if (this.props.item && this.props.item) {
            site_promises.push(GridDuck.getSites({
                filters: [{field: '_permission', value: this.perm_levels}, {
                    field: 'tariffIds',
                    value: [this.props.item ? this.props.item.id : null]
                }],
                items: 1,
                offset: 0
            }));
        }

        Promise.all(site_promises).then((res) => {
            console.log(res[0], ' : res[0] get sites');
            this.setState({
                hasSites: res[0].list && res[0].list.length,
                hasNoActiveForPerm: this.props.item && !this.perm_levels.find(p => this.props.item._permission === p) && (!res[1].list || !res[1].list.length),
                loaded: true
            });
        })

    }

    async deleteTariff() {
        await this.props.item.delete();
        this.setState({showToast: 'Tariff ' + this.props.item.name + ' Deleted'})
        this.closeModal();
    }

    async saveSites() {
        console.log(this.state.newSitesList, ' prev sites list')
        let sites = this.state.newSitesList;
        console.log('sites list has changed, updating ')
        let siteIds = sites.map((s) => {
            return s.id;
        })
        console.log(siteIds, ' submitting site ids for tariff update')
        if (!siteIds) siteIds = [];
        let id = this.props.item ? this.props.item.id : this.state.createdTariffId
        console.log(id, ' : item id');
        let tariff;
        try {
            tariff = await GridDuck.getTariff({id: id})
        } catch (e) {
            console.log(e, ' : error');
        }
        if (tariff) {
            console.log(tariff, ' : got tariff');
            try {
                await tariff.set({siteIds: siteIds});
            } catch (e) {
                console.log(e, ' : error');
            }
        }
        console.log('update success')
        return Promise.resolve();

    }

    async saveChanges() {
        console.log('saving changes in form')
        let res, tariff = {
            name: this.state.name,
            currency: this.state.currency,
            rates: this.state.rates.map((r) => {
                return {
                    startHour: r.startHour,
                    startMinute: r.startMinute,
                    endHour: r.endHour,
                    endMinute: r.endMinute,
                    rate: parseFloat(r.rate)
                }
            }),
            utilityType: this.state.utilityType,
            standingCharge: this.state.standingCharge
        };
        console.log(tariff, ' : save tariff');
        let self = this;
        let item = self.props.item;
        if (item) {
            res = await item.set(tariff);
            this.setState({showToast: 'Tariff ' + tariff.name + ' Updated', formUpdated: false});
        } else {
            res = await GridDuck.createTariff(tariff);
            console.log('Created id: ', res);
            this.setState({showToast: 'Tariff ' + tariff.name + ' Created', createdTariffId: res.id});
        }
        return Promise.resolve(res)
    }

    async saveDetails() {
        console.log('Save details');
        let tariffErrors = this.checkErrors(true);
        console.log(tariffErrors, ' tariff errors back from form in next')
        if (Object.keys(tariffErrors).length > 0) return Promise.reject();
        await this.saveChanges();
        console.log('Saved Details');
        if (this.state.sitesUpdated) await this.saveSites();
        console.log('Added to sites');
        if (this.props.onSave) {
            console.log('On Save');
            this.props.onSave(this.state.showToast)
        }

        if (!this.props.item) {
            this.closeModal();
        }

        return Promise.resolve();
    }

    onTabClick(ev, tab) {
        this.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    sitesChanged(newSitesList) {
        console.log(newSitesList, ' new sites list on editable save')
        this.setState({newSitesList: newSitesList, formUpdated: true, sitesUpdated: true})
    }

    getAvailableList(params) {
        console.log('get available sites: ', params);
        if (!params.filters) params.filters = [];
        params.filters = params.filters.concat([{field: '_permission', value: this.perm_levels}]);
        return GridDuck.getSites(params)
    }

    async getActiveList(params) {
        let self = this

        if (!this.props.item || !this.props.item.id) return Promise.resolve({list: [], total: 0})

        if (!this.state.newSitesList.length) {
            params.filters = [
                {
                    field: 'tariffIds',
                    value: [this.props.item.id]
                },
                {field: '_permission', value: this.perm_levels}
            ]
            let actualList = await GridDuck.getSites(params);
            let list = actualList.list;
            self.setState({newSitesList: actualList});

            return Promise.resolve({list: list, total: list.length});
        } else return Promise.resolve({list: self.state.newSitesList, total: self.state.newSitesList.length});
    }

    moveToTab() {
        let errors = this.checkErrors(true);
        if (errors) errors = Object.keys(errors).length;
        if (errors) return Promise.reject();
        this.onTabClick(null, {id: 2});
    }

    convertTimesToWholeNumbers(rate) {
        return {
            start: parseInt(rate.startHour) + (parseInt(rate.startMinute) ? 0.5 : 0),
            end: parseInt(rate.endHour) + (parseInt(rate.endMinute) ? 0.5 : 0)
        }
    }

    checkErrors(saving) {
        let self = this;
        this.errors = {};
        if (!this.state.name) this.errors['name'] = true;
        if (!this.state.currency) this.errors['currency'] = true;
        if (!this.state.utilityType) this.errors['utilityType'] = true;
        let _rates = this.state.rates;
        let _conflicts = {}
        _rates.forEach((r, index) => {
            // console.log(r, ' : rate to check');
            if (!parseInt(r.rate)) this.errors['rate' + r.id] = true
            let conflicts = _rates.filter((r2) => {
                let r2_whole = self.convertTimesToWholeNumbers(r2),
                    r_whole = self.convertTimesToWholeNumbers(r);
                let conflict = false;
                if ((r_whole.start > r2_whole.start && r_whole.start < r2_whole.end) || (r_whole.end > r2_whole.start && r_whole.end < r2_whole.end) || (r_whole.start === r_whole.end) || (r2_whole.end === r2_whole.start)) {
                    conflict = true
                }
                return conflict;
            });
            conflicts.map((c, i) => {
                return c.index = i
            })
            let filteredConflicts = conflicts.filter((c) => {
                return c.index !== index
            })
            // console.log(filteredConflicts, ' filtered array of conflicts for rate ', index)
            if (filteredConflicts.length) _conflicts[index] = filteredConflicts;
        })
        let errorMessages;
        if (Object.keys(_conflicts).length > 0) {
            errorMessages = []
            for (const [key, value] of Object.entries(_conflicts)) {
                let error = 'Conflict between Rate ' + (value[0].index + 1) + ' and Rate ' + (parseInt(key) + 1)
                errorMessages.push(error)
            }
            console.log(errorMessages, ' : errorMessages');
            this.errors['conflict'] = true;
        }
        if (saving) this.setState({triedToSave: true, errorMessages: errorMessages})
        return this.errors;
    }

    render() {
        let footer;
        console.log('Render');
        let tariffErrors = this.checkErrors();
        if (tariffErrors) tariffErrors = Object.keys(tariffErrors).length;
        this.tabs[0][1].disabled = tariffErrors && !this.props.item;

        if (this.state.formUpdated && this.props.item) footer =
            <Button progressRes
                    additionalclasses={'sm'}
                    label={'Save Changes'}
                    color={'gd-brand'}
                    onClick={this.saveDetails}/>

        if (this.tabs[0][0] && this.tabs[0][0].selected && !this.props.item) footer =
            <Button additionalclasses={'sm'}
                    label={'Next'}
                    color={'gd-brand'}
                    onClick={this.moveToTab}/>

        if (this.tabs[0][1] && this.tabs[0][1].selected && !this.props.item) footer =
            <Button progressRes additionalclasses={'sm'}
                    label={'Create Tariff'} color={'gd-green'}
                    onClick={this.saveDetails}/>

        if (this.tabs[0][2] && this.tabs[0][2].selected) footer =
            <Button progressRes disabled={this.state.deleteConfirmation !== 'DELETE'} additionalclasses={'md'}
                    label={'Permanently Delete'} color={'gd-red'}
                    onClick={this.deleteTariff}/>
        let title = this.props.item ? this.props.item.name + ' Tariff' : 'Create Tariff'
        if (this.props.item && !this.aperm_levels.find(p => this.props.item._permission === p)) {
            title = this.props.item.name + ' Tariff';
        }

        let in_view = !!(this.props.item && !this.aperm_levels.find(p => this.props.item._permission === p) || this.props.orgPerm === 'view');

        console.log(this.props.item, ' : this.props.item');

        return (
            <GdModal
                title={title}
                open={this.props.open}
                contentLabel={'Tariff'}
                footer={footer}
                wide={this.tabs && this.tabs[0][1].selected}
                onClose={this.closeModal}>
                {this.tabs ? <Tabs tabData={this.tabs}/> : null}
                <CardBody additionalclasses={(this.tabs[0][1] && this.tabs[0][1].selected ? ' no-padding' : '')}>
                    {this.tabs[0][0] && this.tabs[0][0].selected ?
                        <TariffForm
                            disabled={in_view}
                            formData={{
                                name: this.state.name,
                                currency: this.state.currency,
                                utilityType: this.state.utilityType,
                                standingCharge: this.state.standingCharge,
                                rates: this.state.rates
                            }}
                            triedToSave={this.state.triedToSave}
                            errors={this.errors || {}}
                            funcs={{
                                convertTimesToWholeNumbers: this.convertTimesToWholeNumbers
                            }}
                            errorMessages={this.state.errorMessages}
                            onRatesChange={(rates) => this.setState({rates: rates, formUpdated: true})}
                            onCurrencyChange={(currency) => this.setState({currency: currency, formUpdated: true})}
                            onNameChange={(name) => this.setState({name: name, formUpdated: true})}
                            onUtilityTypeChange={(utilityType) => this.setState({
                                utilityType: utilityType,
                                formUpdated: true
                            })}
                            onStandingChargeChange={(standingCharge) => this.setState({
                                standingCharge: standingCharge,
                                formUpdated: true
                            })}
                            ref={this.tariffFormRef}>
                            {this.props}
                        </TariffForm>
                        : ''}
                    {this.tabs[0][1] && this.tabs[0][1].selected ?
                        <div style={{height: '100%'}}>
                            {this.state.loaded ? <div className={'row'} style={{height: '100%'}}>
                                {this.state.hasSites && !this.state.hasNoActiveForPerm ? <EditableList
                                    isEditing={!this.props.item || this.props.item._permission !== 'view'}
                                    disabled={in_view}
                                    columns={[
                                        {
                                            component: StandardListCell,
                                            bold: true,
                                            title: 'Name',
                                            field: 'name',
                                            sortable: true,
                                            defaultSort: 'asc'
                                        },
                                        {
                                            component: StandardListCell,
                                            title: 'Access level',
                                            field: '_permission',
                                            maxWidth: 'none'
                                        },
                                    ]}
                                    key={1}
                                    onChange={this.sitesChanged}
                                    noCancel={true}
                                    noSave
                                    getActiveList={this.getActiveList}
                                    getAvailableList={this.getAvailableList}
                                /> : null}
                                {!this.state.hasSites && !this.state.hasNoActiveForPerm ?
                                    <div>You have no sites of which you have either edit or admin access</div> : null}
                                {this.state.hasNoActiveForPerm ?
                                    <div>This tariff hasn't been added to any sites</div> : null}
                            </div> : <GenericLoader/>}
                        </div>: ''}
                    {this.tabs[0][2] && this.tabs[0][2].selected ? <div>
                        <div className={'row'}>
                            <Input required error={this.errors['delete'] && this.state.triedToDelete} top='true'
                                   onChange={(e) => this.setState({deleteConfirmation: e.target.value})}
                                   placeholder={'Type DELETE here'}
                                   name={'delete'} value={this.state.deleteConfirmation}
                                   label={'Enter DELETE (case sensitive) in the input and then click the button bellow'}/>
                        </div>
                    </div> : ''}
                </CardBody>
            </GdModal>
        )
    }
}

export default TariffModal;