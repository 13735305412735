import React from "react";
import GridDuck from "gridduck";
import "./MainSitePage.scss";
import history from "../../meta/history";
import {StateOptions} from "gd-react";

class MainSitePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getSiteData = this.getSiteData.bind(this);
        let self = this;
        this.gatewaysUpdated = this.gatewaysUpdated.bind(this);
        this.hubUpdated = this.hubUpdated.bind(this);
    }

    componentDidMount() {
        this.getSiteData();
    }

    componentWillUnmount() {
        let self = this;
        if (this.state.hubs && this.state.hubs.off) {
            this.state.hubs.off('updated', this.gatewaysUpdated);
            this.hubs.list.forEach(function (hub) {
                if (hub.off) {
                    hub.off('updated', self.hubUpdated);
                }
            });
        }
    }

    hubUpdated(key, value) {
        if (key === 'disconnected') {
            this.setState({hubs: this.hubs});
        }
    }

    gatewaysUpdated() {
        let self = this;
        this.setState({
            hubs: this.hubs
        });
        this.hubs.list.forEach(function (hub) {
            if (hub.off) {
                hub.off('updated', self.hubUpdated);
            }
            if (hub.on) {
                hub.on('updated', self.hubUpdated);
            }
        })
    }

    getSiteData(newSiteId) {
        //FIXME bit of duplicate API calls, getting all the devices and then it happens again in the list
        // this.setState({
        //     siteId: newSiteId
        // });
        let {siteId} = this.props.match.params;
        let self = this;
        GridDuck.getSite({
            id: siteId
        }).then(function (site) {
            // self.setState({
            //     site: site
            // });
            console.log(site, ' : site');
            let allDataTypes = [];
            let availableDataTypes = [];
            StateOptions.forEach(function (sO) {
                sO.dataTypes.forEach(function (sDT) {
                    if (!allDataTypes.find(dT => dT.type === sDT.type)) {
                        allDataTypes.push(sDT);
                    }
                });
            })
            site.dataTypes.forEach(function (dataTypeName) {
                let dataType = allDataTypes.find(dT => dT.type === dataTypeName)
                if (!availableDataTypes.find(dT => dT.type === dataType.type)) {
                    if (dataType.category !== 'Signal') {
                        availableDataTypes.push(dataType);
                    }
                }
            })
            Promise.all([
                GridDuck.getAssets({
                    filters: [
                        {
                            field: 'siteId',
                            value: site.id
                        }
                    ],
                    getAll: true
                }),
                GridDuck.getGateways({
                    filters: [
                        {
                            field: 'siteId',
                            value: site.id
                        }
                    ],
                    getAll: true
                }),
                GridDuck.getAccount({id: GridDuck.userId}).then(function (results) {
                    self.setState({user: results});
                }),
                Promise.resolve(availableDataTypes)
            ])
                .then(function (data) {
                    let hubsList = data[1];
                    self.loading = false;
                    console.log(hubsList, ' hubs list main site page')
                    self.hubs = hubsList;
                    self.hubs.list.forEach(function (hub) {
                        if (hub.on) {
                            hub.on('updated', self.hubUpdated);
                        }
                    });
                    hubsList.on('updated', self.gatewaysUpdated);
                    let list = data[0].list;
                    self.setState({
                        assets: list,
                        site: site,
                        hubs: hubsList,
                        availableDataTypes: data[3],
                        loading: false
                    });
                });
        }).catch(function (err) {
            console.log("Error opening site", err);
            history.push('/404');
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(prevProps, ' : prev props');
        console.log(prevState, ' : prev state');
        console.log(snapshot, ' : snapshot');
        let newSiteId = this.props.match.params.siteId;
        let oldSiteId = prevProps.match.params.siteId;
        if (newSiteId && oldSiteId && newSiteId !== oldSiteId) {
            this.loading = true;
            this.getSiteData(newSiteId);
        }
    }
}

export default MainSitePage;
