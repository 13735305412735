import React from 'react';
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';

const chartComponents = {
    line: Line,
    bar: Bar,
    pie: Pie,
    doughnut: Doughnut,
};

const FlexibleChart = ({ chartType = 'line', chartData, chartOptions }) => {
    const ChartComponent = chartComponents[chartType] || Line; // Default to Line chart if type is not provided

    return (
        <div style={{ width: '100%', height: '300px' }}>
            <ChartComponent data={chartData} options={chartOptions} height={300} />
        </div>
    );
};

export default FlexibleChart;