import React from "react";
import "./InteractiveTooltip.scss";
import history from "../meta/history";
import {Button} from "gd-react";

class SomeSitesWithout extends React.Component {
    render() {
        let sites_w_o = this.props.sitesWithout.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id && t.name === value.name
            )));
        console.log(sites_w_o, ' : sites_w_o');
        return (
            <React.Fragment>
                <div style={{fontSize: '12px'}}>This may not represent the true total as
                    the following sites within the group do not
                    have {this.props.title ? this.props.title.replace(/Spend/g, '').toLowerCase() : ''}:<br/>
                    <div style={{
                        display: 'flex',
                        marginBottom: '20px',
                        flexDirection: 'column',
                        maxHeight: '130px',
                        overflow: 'auto'
                    }}>{sites_w_o.map(swt =>
                        <span key={swt.id} style={{lineHeight: '30px'}}><span style={{width: '100%'}}
                                                                              className={'link'}
                                                                              onClick={() => history.push('/site/' + swt.id + '/' + this.props.urlItem)}>{swt.name}</span><br/></span>)}</div>
                    <div className={'row reverse'}>
                        <Button style={{height: '20px', fontSize: '11px', lineHeight: '20px', marginTop: '5px'}} label={this.props.buttonLabel}
                                onClick={this.props.buttonClick ? this.props.buttonClick : () => history.push('/' + this.props.urlItem + (this.props.itemId ? ('/' + this.props.itemId) : ''))}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SomeSitesWithout;
