import React from 'react';

const SubscriptionComponent = ({subscriptions, name}) => {
    const totalSubscriptionValue = subscriptions.reduce((total, subscription) => {
        const subscriptionTotal = subscription.items.data.reduce((subTotal, item) => {
            return subTotal + (item.price.unitAmount ? item.price.unitAmount * item.quantity : 0);
        }, 0);
        return total + subscriptionTotal;
    }, 0);
    return (
        <div className="subscriptions">
            <h4 className="total-subscription-value">
                {name} Subs
            </h4>
            <h4 className="total-subscription-value">
                Total of All Subs: {(totalSubscriptionValue / 100).toFixed(2)} GBP
            </h4>
            {subscriptions.map((subscription, i) => {
                const totalValue = subscription.items.data.reduce((total, item) => {
                    return total + (item.price.unitAmount ? item.price.unitAmount * item.quantity : 0);
                }, 0);

                return (
                    <div key={subscription.id} className="subscription">
                        <h4 className="subscription-id"><a target={"_blank"}
                            href={'https://dashboard.stripe.com/subscriptions/' + subscription.id}>Subscription {i + 1}</a>
                        </h4>
                        <p className="subscription-status">Status: {subscription.status}</p>
                        <p className="subscription-currency">Currency: {subscription.currency.toUpperCase()}</p>
                        <p className="subscription-method">Collection Method: {subscription.collectionMethod}</p>
                        <p className="subscription-period">
                            Current
                            Period: {new Date(subscription.currentPeriodStart * 1000).toLocaleDateString()} -{' '}
                            {new Date(subscription.currentPeriodEnd * 1000).toLocaleDateString()}
                        </p>
                        <h4 className="subscription-discount-title">Discount:</h4>
                        {subscription.discount ? (
                            <p className="subscription-discount">
                                {subscription.discount.coupon.name} ({subscription.discount.coupon.percentOff}%)
                            </p>
                        ) : (
                            <p className="subscription-no-discount">No discount applied</p>
                        )}
                        <h4 className="subscription-items-title">Items:</h4>
                        {subscription.items.data.map((item) => {
                            const itemTotalValue = item.price.unitAmount ? (item.price.unitAmount * item.quantity) / 100 : 0;
                            return (
                                <div key={item.id} className="subscription-item">
                                    <p className="item-product">Product: {item.plan.name}</p>
                                    <p className="item-unit-price">
                                        Unit
                                        Price: {item.price.unitAmount ? (item.price.unitAmount / 100).toFixed(2) : 'N/A'} {item.price.currency.toUpperCase()}
                                    </p>
                                    <p className="item-quantity">Quantity: {item.quantity}</p>
                                    <p className="item-total-value">
                                        Total Value: {itemTotalValue.toFixed(2)} {item.price.currency.toUpperCase()}
                                    </p>
                                </div>
                            );
                        })}
                        <h4 className="subscription-tax-rate-title">Tax Rate:</h4>
                        {subscription.defaultTaxRates.length > 0 ? (
                            <p className="subscription-tax-rate">
                                {subscription.defaultTaxRates[0].display_name} ({subscription.defaultTaxRates[0].percentage}%)
                            </p>
                        ) : (
                            <p className="subscription-no-tax-rate">No tax rate applied</p>
                        )}
                        <h4 className="subscription-total-value">
                            Total Subscription
                            Value: {(totalValue / 100).toFixed(2)} {subscription.currency.toUpperCase()}
                        </h4>
                    </div>
                );
            })}
        </div>
    );
};

export default SubscriptionComponent;