import React, {Component} from 'react';
import {Input, Dropdown, Button, GdAutocomplete} from "gd-react";
import GridDuck from 'gridduck';
import CreateSiteForm from "./CreateSiteForm";
import * as _ from "lodash";

class CreateDeviceForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceCategories: []
        };
        this.errors = {};
        ['updateField', 'checkErrors', 'getSites'].forEach(f => {
            this[f] = this[f].bind(this);
        });
    }

    async getDeviceCategory(searchTerm) {
        let filters = []

        let res = await GridDuck.getDeviceCategorys({
            filters: filters
        })
        let list = res.list.map(
            s => ({
                id: s.id,
                title: s.name,
                color: s.colour,
                icon: s.icon
            })
        )

        return Promise.resolve(list)
    }

    addNewItemSite() {
        let newItem = this.props.addNewItem({
            type: 'site', value: {
                name: '',
                locationDetails: null
            }
        });

        this.props.onChange({
            value: newItem.id,
            field: 'siteId',
            cb: this.props.onChange({value: true, field: 'newSite'})
        });
    }

    removeAndResetSite() {
        this.props.removeNewItem({type: 'site', id: this.props.item.siteId});
        this.props.onChange({
            value: null,
            field: 'siteId',
            cb: this.props.onChange({value: false, field: 'newSite'})
        });
    }

    async getSites(searchTerm, reset) {
        let icon = 'FaBuilding';
        let self = this
        if (reset) this.itemsOffset = 0
        let filters = []
        let limit = 25
        if (searchTerm) filters.push({
            field: 'search',
            value: searchTerm
        });
        let itemsRes = await GridDuck.getSites({filters: filters, offset: this.itemsOffset, items: limit});

        let items = itemsRes.list
            .filter(s => s._permission === 'admin')
            .map((s) => {
                return {
                    id: s.id,
                    title: s.name,
                    locationId: s.locationId,
                    subtitle: s.locationName || '',
                    icon: icon,
                }
            })
        let newOffset = self.itemsOffset + limit;
        self.itemsOffset = newOffset
        return Promise.resolve(items)
    }


    componentDidMount() {
        this.getDeviceCategory().then((res) => this.setState({deviceCategories: res}));
        if (this.props.onChange) this.props.onChange(this.state, [this.errors]);
    }

    checkErrors() {
        this.errors['name'] = !this.state.name || !this.state.name.length;
        this.errors['deviceCategory'] = !this.state.deviceCategory || !this.state.deviceCategory.length;
    }

    updateField(field, value) {
        if (this.props.onChange) this.props.onChange({value: value, field: field});
    }

    render() {
        this.checkErrors();
        return (
            <div>
                <div className={'row'}>
                    <Input
                        required
                        error={this.props.item?.errors['name'] && this.props.triedToSave}
                        top="true"
                        label={'Name'}
                        name={'name'}
                        onChange={(e) => this.updateField('name', e.target.value)}
                        value={this.props.item?.name}
                    />
                </div>
                <div className={'row'} style={{marginTop: '15px'}}>
                    <Dropdown
                        label={`Device Category`}
                        name={`deviceCategory`}
                        value={this.props.item?.deviceCategory}
                        fixeditems={this.state.deviceCategories}
                        placeholder={'e.g. Lighting'}
                        onChange={(e) => this.updateField('deviceCategory', e.target.value)}
                    />
                </div>
                <div className={'row'} style={{marginTop: '15px'}}>
                    <label style={{paddingLeft: '5px'}}>Site</label>
                </div>
                <div className={'inner-tabbed-page data-upload'} style={{padding: '10px'}}>
                    {!this.props.item?.newSite ? <div>
                        <div className={'row'}>
                            <GdAutocomplete
                                async
                                icon
                                subtitle
                                lazyload
                                getList={(searchTerm, reset) => this.getSites(searchTerm, reset)}
                                name={'siteId'}
                                getOptionSelected={function (option, value) {
                                    if (option && value) return option.id === value.id;
                                }}
                                fixedOptions={this.props.newSites.map(item => {
                                    return {
                                        id: item.id,
                                        title: item.name,
                                        type: 'Recent',
                                        icon: 'GiAnticlockwiseRotation'
                                    }
                                })}
                                noLabel
                                value={this.props.item?.siteId}
                                error={this.props.item?.errors['siteId'] && this.props.triedToSave}
                                placeholder={'Select a site'}
                                onChange={(e, value) => this.updateField('siteId', value)}
                            />
                        </div>
                    </div> : <div>
                        <CreateSiteForm triedToCreate={this.state.triedToCreate}
                                        triedToDone={this.props.triedToSave}
                                        triedToSave={this.props.triedToSave}
                                        dataItem={this.props.dataItem}
                                        item={this.props.newSites.find(item => item.id === this.props.item?.siteId)}
                                        onChange={(data) => this.props.updateNewItem({
                                            type: 'site',
                                            id: this.props.item?.siteId,
                                            value: data.value,
                                            field: data.field
                                        })}/>
                    </div>}
                    {[(<p key={'or-d'} className={'or-divider'}>or</p>), (
                        <Button
                            key={'cr-b'}
                            label={(this.props.item?.newSite ? 'Select existing' : '+ Create new') + ' site'}
                            additionalclasses={'md'}
                            onClick={() => {
                                let makeNewItem = !this.props.item?.newSite;
                                if (makeNewItem) {
                                    this.addNewItemSite();
                                } else {
                                    this.removeAndResetSite();
                                }
                            }}/>)]}
                </div>
            </div>
        );
    }
}

export default CreateDeviceForm;