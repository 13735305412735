import formatter from "./formatter";

function smartFixed(num) {
    return num.toFixed(2).replace(/\.00$/, '');
}

function formatMetric({value, unit, unitOnly, array}) {
    const {metaData} = unit;
    const scales = ['y', 'z', 'a', 'f', 'p', 'n', 'µ', 'm', '', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    let scaleIndex = 8; // Start from the base unit
    let scaledValue = value;

    while (scaledValue >= 1000 && scaleIndex < scales.length - 1) {
        scaledValue /= 1000;
        scaleIndex++;
    }

    while (scaledValue < 1 && scaleIndex > 0) {
        scaledValue *= 1000;
        scaleIndex--;
    }

    const label = metaData.symbol;
    if (!array) {
        if (unitOnly) return `${scales[scaleIndex]}${label}`;
        return `${smartFixed(scaledValue)}${scales[scaleIndex]}${label}`;
    } else {
        return scales.map((u, i) => {
            return {
                label: `${u}${label}`,
                value: Math.pow(1000, i - scaleIndex)
            }
        })
    }
}

function formatNonMetric({value, unit, ruleId, unitOnly, array}) {
    const {metaData} = unit;
    if (array) {
        let array_of_values = metaData?.rules.map(r => {
            return {
                label: `${r.symbol} (${r.name})`,
                value: r.scaleOfBase
            }
        });
        return array_of_values;
    }
    let clonedArray = [...metaData.rules];
    const rules = clonedArray.sort((a, b) => b.scaleOfBase - a.scaleOfBase);
    let result = '';

    if (ruleId) {
        const rule = rules.find(r => r.id === ruleId);
        if (rule) {
            const scaledValue = value / rule.scaleOfBase;
            result = `${smartFixed(scaledValue)}${rule.symbol}`;
            if (unitOnly) {
                result`${rule.symbol}`;
            }
            return result;
        }
    } else {
        for (const rule of rules) {
            if (value >= rule.scaleOfBase) {
                const scaledValue = value / rule.scaleOfBase;
                result = `${smartFixed(scaledValue)}${rule.symbol}`;
                if (unitOnly) {
                    result = `${rule.symbol}`;
                }
                break;
            }
        }
    }

    return result;
}

function formatBoolean({value, metaData}) {
    return value === 1 ? metaData.trueLabel : metaData.falseLabel;
}

function formatLogarithmic({value, scale}) {
    let unitIndex = 0; // Start with the base unit (Bytes)
    let convertedValue = value;

    // Find the largest unit to represent the value
    while (convertedValue >= scale.multiplier && unitIndex < scale.unitPrefixes.length - 1) {
        convertedValue /= scale.multiplier;
        unitIndex++;
    }

    // Format the value with the desired precision
    const formattedValue = smartFixed(convertedValue.toFixed(2));

    // Get the unit symbol for the final unit
    const unitSymbol = scale.unitPrefixes[unitIndex].symbol;

    return `${formattedValue}${unitSymbol}`;

}

function formatCategorical({value, unit}) {
    const {metaData} = unit;
    return metaData.categories[value] || "Unknown";
}

function formatDimensionless({value, unit, unitOnly}) {
    const {metaData} = unit;
    let _unit = (value === 1) ? ' ' + metaData.singleLabel : ' ' + metaData.pluralLabel;
    if (metaData.symbol) _unit = metaData.symbol;
    if (unitOnly) return _unit;
    return value + _unit;

}

function formatValue(props) {
    const {value, unit, currency, array} = props
    const {unitType} = unit;

    switch (unitType) {
        case 'metric':
            return formatMetric(props);
        case 'nonMetric':
            return formatNonMetric(props);
        case 'dimensionless':
            return formatDimensionless(props);
        case 'boolean':
            return formatCategorical(props);
        case 'logarithmic':
            return formatLogarithmic(props);
        case 'categorical':
            return formatCategorical(props);
        case 'percentage':
            return value + '%';
        case 'currency':
            return formatter('currency')(currency).format((value) / 100)
        default:
            return 'Invalid unit type';
    }
}

export default formatValue;