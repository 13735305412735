import _ from "lodash";

const toCamelCase = str => {
    const excludeList = ['dataService', 'params', 'events', 'isListening', 'API_FIELD'];
    if (excludeList.includes(str)) {
        return str;
    }

    let remStr = str;

    const leadingUnderscores = remStr.match(/^_+/) || [''];
    let camelCaseStr = leadingUnderscores[0] + remStr
        .substring(leadingUnderscores[0].length)
        .replace(/(?:^|_)(\w)/g, (match, p1, offset) => offset === 0 ? p1.toLowerCase() : p1.toUpperCase());

    return camelCaseStr;
};

function isObject(obj) {
    return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
}

function keysToCamelCase(obj, ignoredKeys = ["_events", "DataService"], cache = new WeakMap(), refs = new Set()) {
    if (Array.isArray(obj)) {
        if (cache.has(obj)) {
            return cache.get(obj);
        }
        let output = [];
        refs.add(obj);
        for (let i = 0; i < obj.length; i++) {
            output[i] = keysToCamelCase(obj[i], ignoredKeys, cache, refs);
        }
        cache.set(obj, output);
        refs.delete(obj);
        return output;
    } else if (isObject(obj)) {  // Object
        if (cache.has(obj)) {
            return cache.get(obj);
        }
        let output = Object.create(Object.getPrototypeOf(obj));
        refs.add(obj);
        Object.keys(obj).forEach((key) => {
            let camelCaseKey = typeof key === 'string' ? toCamelCase(key) : key;
            const descriptor = Object.getOwnPropertyDescriptor(obj, key);

            // Handle leading underscores
            if (key.startsWith('_')) {
                camelCaseKey = '_' + toCamelCase(key.substring(1));
            }
            if (descriptor && (descriptor.get || descriptor.set)) {
                Object.defineProperty(output, camelCaseKey, {
                    get: descriptor.get,
                    set: descriptor.set,
                    enumerable: descriptor.enumerable,
                    configurable: descriptor.configurable
                });
            } else if (ignoredKeys.includes(key)) {
                output[camelCaseKey] = obj[key];
            } else if (isObject(obj[key]) || Array.isArray(obj[key])) {
                output[camelCaseKey] = refs.has(obj[key])
                    ? obj[key]
                    : keysToCamelCase(obj[key], ignoredKeys, cache, refs);
            } else {
                output[camelCaseKey] = obj[key];
            }
        })
        cache.set(obj, output);
        refs.delete(obj);
        return output;
    }
    return obj;
}

async function camelfy(asyncFunc, ...args) {
    const data = await asyncFunc(...args);
    let camelCased = keysToCamelCase(data);
    return camelCased;
}

function camelfySync(data) {
    let camelCased = keysToCamelCase(data);
    return camelCased;
}

export {camelfy, camelfySync};