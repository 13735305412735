import React, {Component} from "react";
import '../../styles/_layout.scss';
import GridDuck from "gridduck";
import {
    Button,
    CardBody,
    Tabs,
    List,
    Icon,
    StandardListCell,
    EditableList,
    Tooltip
} from "gd-react";
import GaiaMessage from "./GaiaMessage";

class GaiaMessages extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div>
                {this.props.messages.map((message, index) => {
                    return <GaiaMessage message={message} key={message.id}/>;
                })}
            </div>
        )
    }
}


export default GaiaMessages;