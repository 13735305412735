import React from "react";
import "./Toggle.scss";

class Toggle extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div {...this.props} onClick={()=>this.props.onClick((this.props.active === 'false').toString())} className={'toggle-wrapper ' + (this.props.disabled ? 'no-pointer' : '')}>
                <div className={'Toggle ' + (this.props.active === 'true' ? 'active' : '')}>
                    <div className={'dot'}/>
                </div>
                <div className={'toggle-text'}>{this.props.active === 'true' ? this.props.activestatename : this.props.inactivestatename}</div>
            </div>
        )
    }

}

export default Toggle;