import React from "react";
import {Input, Icon, Loader} from "gd-react";
import GridDuck from "gridduck";
import TabbedPage from "../../../components/TabbedPage/TabbedPage";
import history from "../../../meta/history";
import "../../organisation/MainOrganisationPage.scss";
import GenericLoader from "../../../components/GenericLoader";
import AuditDevicesPage from "./AuditDevicesPage";
import AuditHubsPage from "./AuditHubsPage";
import AuditFilesPage from "./AuditFilesPage";
import moment from "moment-timezone";
import getFormat from "../../../services/formatter";

class MainAuditPage extends React.Component {
	constructor(props) {
		super(props);
		let self = this;
		this.state = {}
		let {auditId} = this.props.match.params;
		GridDuck.getAudit({
			id: auditId
		}).then(function (audit) {
			self.setState({
				audit: audit,
				loaded: true,
				site: audit.siteId
			});
		}).catch(function (err) {
			console.log(err, ' error ')
		});
	}

	render() {

		let audit = this.state.audit;

		let tabs = [
			{
				default: true,
				url: null,
				title: 'Devices',
				component: AuditDevicesPage
			},
			{
				url: 'hubs',
				title: 'Hubs',
				component: AuditHubsPage
			},
			{
				url: 'files',
				title: 'Files',
				component: AuditFilesPage
			},
		]

		let leftpanel;

		//&& (audit.devices[i].disconnected === false)

		if (this.state.loaded) {

			let severeIssueCount, severeIssueList, potentialIssueList, potentialIssueCount, minorIssueCount, minorIssueList, nullDeviceCount = 0;

			for (let i = 0; i < audit.devices.length; i++) {
				if (audit.devices[i].sku !== 'SEN-TEM-HUM' && (!audit.devices[i].disconnected) && (!audit.devices[i].hasOwnProperty('consumption'))) {
					nullDeviceCount++
				}
			}

			let devices = audit.devices

			console.log(devices, ' devices in audit')

			severeIssueCount = audit.disconnectedDevices + audit.disconnectedGateways + nullDeviceCount + (audit.devices.length === 0) + (audit.gateways.length === 0) ;

			severeIssueList = [];

			if (audit.gateways.length === 0) {
				severeIssueList.push(`No Hubs added`)
			}

			if (nullDeviceCount > 0) {
				severeIssueList.push(`${nullDeviceCount} Device${nullDeviceCount !== 1 ? 's' : ''} not sending data `)
			}

			if (audit.devices.length === 0) {
				severeIssueList.push(`No Devices added`)
			}

			if (audit.disconnectedDevices > 0) {
				severeIssueList.push(`${audit.disconnectedDevices} Device${audit.disconnectedDevices > 1 ? `s` : ``} disconnected`)
			}

			if (audit.disconnectedGateways > 0) {
				severeIssueList.push(`${audit.disconnectedGateways} Hub${audit.disconnectedGateways > 1 ? 's' : ''} disconnected`)
			}

			let severeIssues = severeIssueList.map(function (issue) {
				return <p className={'issue-text'}>{issue}</p>
			})

			potentialIssueCount = audit.zeroDemandDevices

			potentialIssueList = []

			if (audit.zeroDemandDevices > 0) {
				potentialIssueList.push(`${audit.zeroDemandDevices} Device${audit.zeroDemandDevices > 1 ? `s` : ``} reading 0`)
			}

			let potentialIssues = potentialIssueList.map(function (issue) {
				return <p className={'issue-text'}>{issue}</p>
			})

			minorIssueCount = audit.files.length === 0 ? 1 : 0

			minorIssueList = []

			if (audit.files.length === 0) {
				minorIssueList.push('No Files Added')
			}

			let minorIssues = minorIssueList.map(function (issue) {
				return <p className={'issue-text'}>{issue}</p>
			})

			leftpanel = (
				<div className={'left-panel audit'}>
					<div className={'audit-title-container'}>
						<p className={'title'}>{audit.siteName}</p>
						<p className={'subtitle'}>by <span className={'bold'}>{audit.installerName}</span> at <span className={'bold'}>{audit.installerOrgName}</span> on</p>
						<p className={'subtitle bold'}>{getFormat('lastContacted')(audit.createdDate)}</p>
					</div>
					<div className={'audit-summary'}>
						<p className={'summary-text'}>
							{audit.gateways.length - audit.disconnectedGateways + '/' + audit.gateways.length} Hub{audit.gateways.length !== 1 ? 's' : null} connected
						</p>
						<p className={'summary-text'}>
							{audit.devices.length - audit.disconnectedDevices + '/' + audit.devices.length} Device{audit.devices.length !== 1 ? 's' : null} connected
						</p>
						<p className={'summary-text'}>
							{audit.files.length > 0 ? audit.files.length : 'No'} File{audit.files.length !== 1 ? 's' : null} added
						</p>
					</div>
					<div className={'audit-issues'}>
						{severeIssueCount === 0 && potentialIssueCount === 0 && minorIssueCount === 0 ? <p className={'issue-text'}>No Issues Detected</p> : null}
						{severeIssueCount > 0 ? <p className={'severe'}>{severeIssueCount} Severe Issue{severeIssueCount !== 1 ? 's' : null}</p> : null}
						{severeIssues}
						{potentialIssueCount > 0 ? <p className={'moderate'}>{potentialIssueCount} Potential Issue{potentialIssueCount !== 1 ? 's' : null}</p> : null}
						{potentialIssues}
						{minorIssueCount > 0 ? <p className={'minor'}>{minorIssueCount} Minor Issue{minorIssueCount !== 1 ? 's' : null}</p> : null}
						{minorIssues}
					</div>
					<div className={'audit-note'}>
						<p className={'title'}>Note from {audit.installerName}</p>
						{audit.message !== undefined && audit.message !== '' ? <p className={'note'}>{audit.message}</p> : <p className={'note'}>No installer message submitted</p>}
					</div>


				</div>
			);
		} else {
			leftpanel = (
				<div className={'left-panel'}>
					<GenericLoader text={"Fetching"} textLineTwo={"Audits..."}/>
				</div>
			)
		}

		return (

			<TabbedPage
				dataLoaded={this.state.loaded}
				className={"organisation-page"}
				leftpanel={leftpanel}
				tabs={tabs}
				item={this.organisation}
				site={this.state.site}
				{...this.props}
			/>
		)
	}
}

export default MainAuditPage;
