import React from "react";
import FileManager from "../../components/FileManager/FileManager";
import GenericLoader from "../../components/GenericLoader";

class SiteFilesManager extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let {itemId} = this.props.match.params;
		return (this.props.loaded ? <FileManager itemType={"site"} itemId={itemId}/> : <GenericLoader/>);
	}
}

export default SiteFilesManager;