import {Dropdown, GdAutocomplete, Icon, Input, Toast, Tooltip} from "gd-react";
import React from "react";
import GridDuck from "gridduck";
import GenericLoader from "./GenericLoader";
import moment from "moment-timezone";
import './LocationForm.scss'
import DeleteModal from "../modals/Delete/DeleteModal";

class LocationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            existingLocation: null,
            newLocationName: '',
            newLocationTimezone: '',
            locationSearch: '',
            showDeleteModal: false,
            editing: '',
            timezones: moment.tz.names().map(function (name, index) {
                return {
                    id: index,
                    title: name
                };
            })
        };
        this.errors = {};
        this.fixedOptions = [
            {
                id: 'add_new',
                title: 'Create New Address',
                subtitle: 'Address',
                type: 'New',
                icon: 'FaPlus'
            }
        ]
        this.locationOffset = 0
        this.locationDropDownRef = React.createRef();
        this.searchLocation = this.searchLocation.bind(this);
        this.selectLocation = this.selectLocation.bind(this);
        this.getLocationShortcuts = this.getLocationShortcuts.bind(this)
        this.editLocation = this.editLocation.bind(this);
        this.selectExistingLocation = this.selectExistingLocation.bind(this)
        this.loadData = this.loadData.bind(this)
        this.deleteLocation = this.deleteLocation.bind(this);
        this.getLocations = this.getLocations.bind(this);
    }

    deleteLocation(location, e) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({showDeleteModal: location})
    }

    editLocation(locationOption, location, e) {

        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        let loc = locationOption || location;

        this.setState({
            editing: loc,
            loaded: true,
            newLocationName: loc.name,
            newLocationTimezone: loc.timezone,
            existingLocation: loc,
            locationSearch: {
                id: loc.metadata ? loc.metadata.place_id : null,
                title: loc.metadata ? loc.metadata.formatted_address : '',
                subTitle: 'Test',
                icon: 'FaBuilding'
            },
            extraLocationData: {
                businessDetails: loc.metadata
            }
        });
    }

    async getLocations(searchTerm, reset) {

        let self = this
        if (reset) self.locationOffset = 0
        let filters = []
        let limit = 25
        if (searchTerm) filters.push({
            field: 'search',
            value: searchTerm
        })
        let res = await GridDuck.getLocations({filters: filters, offset: this.locationOffset,})
        let locations = res.list
            .filter(l => l._permission === 'admin')
            .map((l) => {
                let location = {
                    id: l.id,
                    title: l.name,
                    subtitle: l.metadata?.formatted_address,
                    icon: 'FaMapMarkerAlt',
                }
                location.endContent =
                    <div
                        className={'row drop-down-row'}
                    >
                        <div className={'dd-icons-cont'}>
                            <Tooltip label={'Edit Address'}>
                                <Icon size={'12'} onIconClick={(e) => self.editLocation(location, l, e)}
                                      icon={'FaEdit'} color={'gd-grey'}/>
                            </Tooltip>
                            <Tooltip label={'Delete Address'}>
                                <Icon size={'10'} onIconClick={(e) => self.deleteLocation(location, l, e)}
                                      icon={'FaTrash'} color={'gd-red'}/>
                            </Tooltip>
                        </div>
                    </div>
                return location
            });
        self.locationOffset += limit
        return Promise.resolve(locations)
    }

    async loadData() {
        if (this.props.editingLocked) {
            this.editLocation(this.props.editingLocked);
        }
        if (typeof this.props.value == 'string') {
            let existingRes = await GridDuck.getLocations({filters: [{field: 'id', value: this.props.value}]})
            let r = existingRes.list[0]
            let existingObj = {
                id: r.id,
                title: r.name,
                subtitle: r.formatted_address,
                icon: 'FaBuilding'
            }
            this.setState({existingLocation: existingObj, loaded: true})
        } else {
            this.setState({loaded: true})
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value  !== this.props.value) {
            await this.loadData();
        }
    }

    async searchLocation(newText) {
        if (!newText.length) return;
        let res = await GridDuck.getPlacess({filters: [{field: 'input', value: newText}, {field: 'any', value: true}]});
        let locations = res.list[0].locations;
        this.setState({locationsFromSearch: locations});
        return locations.map(function (l) {
            let title = l.structuredFormatting.mainText;
            if (l.structuredFormatting.secondaryText) title += ' - ' + l.structuredFormatting.secondaryText
            return {
                id: l.placeId,
                title: title,
                subTitle: 'Test',
                icon: 'FaBuilding'
            }
        });

    }

    selectLocation(location, obj) {

        let self = this;
        this.setState({locationSearch: obj, loadingExtraLocationData: obj && obj.id}, () => {
            if (obj && obj.id) {
                GridDuck.getPlaces({id: obj.id}).then((res) => {
                    self.props.onNewLocationMetadataChange(res);
                    self.setState({
                        extraLocationData: {
                            businessDetails: res._businessDetails
                        }, loadingExtraLocationData: false
                    });
                });
            }
        });
    }

    async selectExistingLocation(loc, Obj) {

        if (this.props.onLocationIdChange) this.props.onLocationIdChange(Obj);

        if (Obj && Obj.id !== 'add_new') {
            let item = {
                itemType: 'location',
                itemId: Obj.id
            };
            await GridDuck.createSearchHistory(item)
        }

        this.setState({
            existingLocation: Obj,
            loaded: true,
            editing: false,
            newLocationName: '',
            newLocationTimezone: '',
            locationSearch: ''
        });
    }

    checkErrors(tryingToSave) {
        this.errors = {};
        if (!this.state.existingLocation) this.errors['existingLocation'] = true;
        if (this.state.existingLocation === 'add_new' && !this.state.newLocationName) this.errors['newLocationName'] = true;
        if (tryingToSave) this.setState({triedToSave: true});
        return this.errors;
    }

    async createNewLocation() {
        if ((this.state.existingLocation.id === 'add_new' || this.state.editing) && this.state.newLocationName) {
            let locationMetaData = this.state.extraLocationData ? this.state.extraLocationData.businessDetails : null,
                location = locationMetaData && locationMetaData.geometry ? locationMetaData.geometry.location : null,
                lat = location ? location.lat : null,
                lng = location ? location.lng : null,
                newLocation,
                loc_obj = {
                    name: this.state.newLocationName,
                    timezone: this.state.newLocationTimezone,
                    lat: lat,
                    lng: lng,
                    metadata: locationMetaData
                };
            if (!this.state.editing) {
                newLocation = await GridDuck.createLocation(loc_obj);
                this.setState({showToast: 'Address ' + loc_obj.name + ' Created'})
            } else {
                await this.state.editing.set(loc_obj);
                this.setState({showToast: 'Address ' + loc_obj.name + ' Updated'})
            }
            return this.state.editing ? this.state.existingLocation : {id: newLocation.id};
        } else {
            return this.state.existingLocation;
        }
    }

    async getLocationShortcuts() {

        let filters = [
            {
                field: 'itemType',
                value: 'location'
            }
        ]

        let res = await GridDuck.getSearchHistorys({filters: filters})
        let shortcuts = res.list.map((r) => {
            return {
                id: r.itemId,
                title: r.name,
                subtitle: r.metadata?.formatted_address,
                icon: 'GiAnticlockwiseRotation',
                type: 'Recent'
            }
        })
        this.setState({locationShortcuts: shortcuts})
    }

    render() {
        this.checkErrors();
        let self = this;
        return (
            this.state.loaded ? <div className={'location-form'}>
                {!this.props.editingLocked ? !this.state.editing ?
                    <GdAutocomplete
                        async
                        icon
                        subtitle
                        lazyload
                        style={{minWidth: '100px'}}
                        fixedOptions={this.fixedOptions}
                        getShortcuts={self.getLocationShortcuts}
                        shortcuts={self.state.locationShortcuts}
                        getList={self.getLocations}
                        name={'location'}
                        getOptionSelected={function (option, value) {
                            if (option && value) return option.id === value.id;
                        }}
                        disabled={this.props.disabled}
                        onChange={this.selectExistingLocation}
                        error={this.errors['existingLocation'] && this.state.triedToSave}
                        value={this.state.existingLocation}
                        placeholder={'Select an Address'}
                        label="Address"
                    />
                    : <div className={'fake-input'}>
                        <span>Editing "{this.state.editing.name}"</span>
                        <Tooltip label={'Cancel edit'}><Icon onIconClick={() => this.setState({editing: false})}
                                                             icon={'FaTimes'} color={'gd-red'}/></Tooltip>
                    </div> : null}

                {this.state.existingLocation?.id === 'add_new' || this.state.editing ?
                    <div className={'new-location-box ' + (this.props.editingLocked ? 'editing-locked' : '')}>
                        <div className={'row'}>
                            <Input disabled={this.props.disabled} required
                                   error={this.errors['newLocationName'] && this.state.triedToSave}
                                   onChange={(e) => {
                                       if (this.props.onNewLocationNameChange) this.props.onNewLocationNameChange(e.target.value)
                                       this.setState({newLocationName: e.target.value})
                                   }}
                                   name={'newLocationName'} value={this.state.newLocationName} label={'Nickname'}/>
                        </div>
                        <div className={'row'} style={{marginTop: '15px'}}>
                            <GdAutocomplete error={this.errors['timezone'] && this.state.triedToSave}
                                            name={'timezone'}
                                            value={this.state.newLocationTimezone}
                                            disabled={this.props.disabled}
                                            options={this.state.timezones}
                                            onChange={(e) => {
                                                if (this.props.onNewLocationTimezoneChange) this.props.onNewLocationTimezoneChange(e.target.value)
                                                this.setState({newLocationTimezone: e.target.value})
                                            }}
                                            label="Timezone"
                            />
                        </div>
                        {!this.props.disabled || (this.props.disabled && this.state.extraLocationData && this.state.extraLocationData.businessDetails) ?
                            <div className={'row'} style={{marginTop: '15px'}}>
                                <GdAutocomplete
                                    async
                                    icon
                                    disabled={this.props.disabled}
                                    name={'location'}
                                    label={'Postcode'}
                                    value={this.state.locationSearch}
                                    getOptionSelected={function (option, value) {
                                        if (option && value) return option.id === value.id;
                                    }}
                                    placeholder={'Search Postcode'}
                                    getList={this.searchLocation}
                                    onChange={this.selectLocation}/>
                            </div> : null}
                        {this.state.extraLocationData && this.state.extraLocationData.businessDetails && this.state.extraLocationData.businessDetails.place_id && this.state.locationSearch ?
                            <div className={'row'} style={{marginTop: '15px'}}>
                                <iframe width={'100%'}
                                        height={250}
                                        style={{border: 0}}
                                        loading="lazy"
                                        src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyDOam0AKv6wMGJODv8ItikOVHemQ-6CF38&q=place_id:" + this.state.extraLocationData.businessDetails.place_id}/>
                            </div> : null}
                    </div> : ''}
                {this.state.showDeleteModal ? <DeleteModal
                    itemType={'location'}
                    item={this.state.showDeleteModal}
                    deleteRes={this.getLocations}
                    open={this.state.showDeleteModal}
                    onClose={() => this.setState({showDeleteModal: false})}/> : null}
                <Toast onClose={() => this.setState({showToast: null})} message={this.state.showToast}
                       open={!!this.state.showToast}
                       severity="success"
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                       }}/>
            </div> : <div style={{maxHeight: '40px', marginTop: '-40px', marginBottom: '70px'}}><GenericLoader/></div>
        )
    }
}

export default LocationForm;
