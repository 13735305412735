import React from "react";
import {
    Button,
    List, StandardListCell
} from "gd-react";
import GridDuck from 'gridduck';
import './ModbusModelsListPage.scss'
import ModbusModelModal from "../../modals/ModbusModelModal/ModbusModelModal";
import history from "../../meta/history";
import NoItems from "../../components/noItems";

class ModbusModelsListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModbusModel: null,
            createModbusModel: false
        };
        this.getList = this.getList.bind(this);

    }

    componentDidMount() {
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (user) {
                console.log(user.orgPermission, ' : user org permission')
                console.log(user, ' : user');
                self.setState({user: user});
                let urlParams = new URLSearchParams(window.location.search);
                let modbusModelId = urlParams.get('id');
                if (modbusModelId) {
                    GridDuck.getModbusModel({id: modbusModelId}).then((model) => {
                        self.setState({selectedModbusModel: model});
                    });
                }
            });
    }

    async getList(params) {
        return GridDuck.getModbusModels(params);
    }

    render() {
        return (
            this.state.user ? <div className={"page modbus-models"}>
                <div className={'widget-board-header'}>
                    <div className={'row reverse place-in-header'}>
                        {this.state.user.orgPermission !== 'view' ?
                            <Button color={'gd-green'} label={'+ Add New Modbus Model'}
                                    onClick={() => this.setState({
                                        createModbusModel: true
                                    })}/> : null}
                    </div>
                </div>
                <List
                    noitemelem={<NoItems text={'You have no Modbus models'}/>}
                    columns={[{
                        component: StandardListCell,
                        title: 'Name',
                        field: 'displayName',
                        maxWidth: '150px'
                    },
                        {
                            component: StandardListCell,
                            title: 'Device Type',
                            field: 'modbusDeviceType',
                            maxWidth: '150px'
                        },
                        {
                            component: StandardListCell,
                            title: 'Manufacturer',
                            field: 'modbusManufacturerName',
                            maxWidth: '150px'
                        },
                        {
                            component: StandardListCell,
                            custom: function (item) {
                                return item.organisationId ? 'You' : window._companyName
                            },
                            title: 'Created by',
                            field: 'modbusManufacturerName',
                            maxWidth: '150px'
                        },
                        {
                            component: StandardListCell,
                            custom: function (item) {
                                return item._permission
                            },
                            title: 'Permission',
                            field: 'modbusManufacturerName',
                            maxWidth: 'none'
                        }]}
                    getlist={this.getList}
                    rowOnClick={(item) => {
                        this.setState({selectedModbusModel: item});
                        history.push('/settings/organisation/modbus-models?id=' + item.id);
                    }}
                    filters={[]}/>

                {this.state.selectedModbusModel || this.state.createModbusModel ?
                    <ModbusModelModal isGod={this.state.user.god}
                                      orgPerm={this.state.user.orgPermission}
                                      item={this.state.selectedModbusModel}
                                      onClose={() => {
                                          this.setState({
                                              createModbusModel: false,
                                              selectedModbusModel: null
                                          });
                                          history.push('/settings/organisation/modbus-models');
                                      }}
                                      open={this.state.selectedModbusModel || this.state.createModbusModel}/> : null}
            </div> : null
        )
    }
}

export default ModbusModelsListPage;