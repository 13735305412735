/**
 * Use the CSS tab above to style your Element's container.
 */
import React from 'react';
import {CardElement, IbanElement} from '@stripe/react-stripe-js';
// import './Styles.css'
const CARD_ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    placeholderCountry: 'GB',
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};
function CardSection() {
    return (
        <label>
            {/*<CardElement onReady={(e)=>e.focus()} options={CARD_ELEMENT_OPTIONS} />*/}
            <bacsElement onReady={(e)=>e.focus()} options={CARD_ELEMENT_OPTIONS} />
            <div className="mandate-acceptance">
                By providing your payment information and confirming this payment,
                you authorise (A) DUCKDUCK LTD and Stripe, our payment service
                provider, to send instructions to your bank to debit your account and
                (B) your bank to debit your account in accordance with those
                instructions. As part of your rights, you are entitled to a refund
                from your bank under the terms and conditions of your agreement with
                your bank. A refund must be claimed within 8 weeks starting from the
                date on which your account was debited. Your rights are explained in
                a statement that you can obtain from your bank. You agree to receive
                notifications for future debits up to 2 days before they occur.
            </div>
        </label>
    );
}
export default CardSection;