import React, {Component} from "react";
import "../components/Menu/Menu.css";
import ReactDOM from "react-dom";

class KeepComponentOnScreen extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		let pos = ReactDOM.findDOMNode(this).getBoundingClientRect();
		let width = pos.width;
		let height = pos.height;
		if (this.props.centered) {
			width = (width / 2);
			height = (height / 2);
		}
		let xDiff = (pos.x + width) - window.innerWidth;
		let yDiff = (pos.y + height) - window.innerHeight;

		let xx;
		let yy;

		if (xDiff > 0) xx = (pos.x - (xDiff + 15));
		if (yDiff > 0) yy = (pos.y - (yDiff + 15));

		let newPos = {
			x: xx,
			y: yy
		};

		this.props.changePos(newPos, pos)
	}

	render() {
		return (
			React.Children.only(this.props.children)
		)
	}
}

export default KeepComponentOnScreen;
