import {StateOptions} from "gd-react";
import * as _ from 'underscore'

const widgetTypes = [
    {
        label: 'header',
        name: 'Total'
    },
    {
        label: 'spend',
        name: 'Spend',
        hideOnAll: true
    },
    {
        label: 'historic',
        name: 'Historic',
        hideOnAll: true
    },
    {
        label: 'dow',
        name: 'Day of Week Average'
    },
    {
        label: 'hod',
        name: 'Hour of Day Average'
    },
    {
        label: 'group_breakdown',
        name: 'Site Breakdown',
        hideOnAll: true,
        hideIfObjectType: ['site', 'asset']
    },
    {
        label: 'device_breakdown',
        name: 'Device Breakdown',
        hideOnAll: true
    },
    {
        label: 'category_breakdown',
        name: 'Category Breakdown',
        hideOnAll: true
    }
]

const snakeCase = string => {
    return string.replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_');
};

const toTitleCase = string => {
    return string
        .split('_')
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

const generateWidgetObjectBase = ({id, aggregate, allDataTypes}) => {
    if (allDataTypes.length > 1) {
        id = id + '_' + aggregate.label.toLowerCase();
    }
    return {
        o_id: id,
        type: id,
        filter_type: id,
        data_type: aggregate.type,
        data_type_obj: aggregate,
        all_data_types: allDataTypes
    }
}

const generateWidgetObject = ({baseObj, label, name}) => {
    return {
        id: baseObj.o_id + '-' + label,
        name: name,
        ...baseObj
    }
}

const getTypes = function (isAll, checkOne, group, objectTypeName) {
    let s_o = StateOptions;
    let all_data_types = [];

    s_o.forEach((so) => {
        all_data_types = all_data_types
            .concat(so.dataTypes)
            .filter(d => !d.downloadOnly)
    });

    let data_types_grouped_by_category = _.groupBy(all_data_types, 'category');
    let possible_widgets = {};

    Object.entries(data_types_grouped_by_category).forEach((t) => {
        let id = snakeCase(t[0]);
        let aggregates = t[1].filter(dt => dt.graphType === 'bar').reduce((accumulator, current) => {
            if (!accumulator.some(item => item.type === current.type)) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);

        if (checkOne) {
            if (checkOne === id) possible_widgets = aggregates && aggregates.length > 0 ? aggregates[0] : null;
        } else {
            if (aggregates && aggregates.length > 0) {
                aggregates.forEach(aggregate => {
                    let widgetObject = generateWidgetObjectBase({
                        id: id,
                        aggregate: aggregate,
                        allDataTypes: aggregates
                    });
                    widgetTypes.forEach(d => {
                        if ((!d.hideOnAll || d.hideOnAll && !isAll) && (!d.hideIfObjectType || !d.hideIfObjectType.find(ot => ot === objectTypeName))) {
                            possible_widgets[widgetObject.o_id + '-' + d.label] = generateWidgetObject({
                                baseObj: widgetObject,
                                label: d.label,
                                name: d.name
                            });
                        }
                    });
                });
            } else {
                if (!isAll && id !== 'battery' && id !== 'signal') {
                    possible_widgets[id] = {
                        id: id,
                        name: toTitleCase(t[1][0].type),
                        type: id,
                        sensor: true,
                        filter_type: 'sensor',
                        data_type: t[1][0].type,
                        data_type_obj: t[1][0]
                    }
                }
            }
        }
    });

    console.log(possible_widgets, ' : possible widgets');

    return possible_widgets;
}

export default getTypes;