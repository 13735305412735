import React from 'react';
import {Input, Button, Dropdown, GdAutocomplete} from "gd-react";
import GridDuck from 'gridduck'

class CloneOrganisation extends React.Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
        this.getList = this.getList.bind(this)
        this.errors = {}
        this.state = {
            cloneToOrg: null,
            cloneFromOrg: null,
        };
    }

    onChange(val, name, object) {
        console.log(object, ' val on change')
        let state = this.state;
        state[name] = object
        this.setState(state)
    }

    async getList(search) {
        if (!search) return []
        let filters = []
        if (search) {
            filters.push(
                {
                    field: 'search',
                    value: search
                },
                {
                    field: 'include_internal',
                    value: true
                },
            );
        }
        let res = await GridDuck.getOrganisationManagements({
            filters: filters,
            items: 10,
        })
        let list = res.list.map((org) => {
            console.log(org, ' org being checked')
            return {
                id: org.id,
                title: org.name
            }
        })
        return list
    }

    async submit() {
        let body = {
            name: 'clone',
            cloneFromId: this.state.cloneFromOrg?.id,
            cloneToId: this.state.cloneToOrg?.id,
        }
        let res = GridDuck.createPubOrg(body)
        console.log(res, ' res from create pub org')
    }

    render() {
        return (
            <div className={'loginPage organisation'}>
                <div style={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'flex': 50,
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'overflow': 'hidden',
                    position: 'relative'
                }}>
                    <div style={{
                        overflow: 'auto',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{width: '400px'}}>
                            <div style={{padding: '100px 0'}}>
                                <p className={'subHeader'}>Clone Organisation</p>
                                <div style={{marginTop: '10px'}}>
                                    <GdAutocomplete
                                        async
                                        lazyload
                                        style={{minWidth: '100px'}}
                                        getList={this.getList}
                                        name={'cloneFromOrg'}
                                        onChange={(val, object) => this.onChange(val, 'cloneFromOrg', object)}
                                        error={this.errors['cloneFromOrg'] && this.state.triedToSave}
                                        value={this.state.cloneFromOrg}
                                        placeholder={'Select an Organisation'}
                                        label="Existing Org to clone from"
                                    />
                                </div>
                                <div style={{marginTop: '10px'}}>
                                    <GdAutocomplete async
                                                    lazyload
                                                    style={{minWidth: '100px'}}
                                                    getList={this.getList}
                                                    name={'cloneToOrg'}
                                                    onChange={(val, object) => this.onChange(val, 'cloneToOrg', object)}
                                                    error={this.errors['cloneToOrg'] && this.state.triedToSave}
                                                    value={this.state.cloneToOrg}
                                                    placeholder={'Select an Organisation'}
                                                    label="New Organisation to clone to"
                                    />
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'row'
                                }}>
                                    <Button progressRes
                                            label={"Submit"}
                                            onClick={this.submit}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default CloneOrganisation;
