import React, {Component} from 'react';
import {Button} from "gd-react";
import NonMetricRule from "./NonMetricRule";

class NonMetricForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rules: this.props.unit && this.props.unit.metaData.rules && this.props.unit.metaData.rules.length ? this.props.unit.metaData.rules : [{
                id: 'base',
                scaleOfBase: 1,
                name: '',
                symbol: ''
            }]
        };
        this.errors = {};
        let self = this;
        ['checkErrors', 'formUpdate', 'addRule', 'removeRule'].forEach((func) => {
            self[func] = self[func].bind(self);
        });
    }

    componentDidMount() {
        this.formUpdate({updateFunc: (cb) => cb()});
    }

    checkErrors() {
        //  Check Rules for errors
        this.errors = {};
        this.state.rules.forEach(r => {
            this.errors[r.id + 'scaleOfBase'] = !r.scaleOfBase || !!this.state.rules.find((_r) => _r.scaleOfBase === r.scaleOfBase && _r.id !== r.id);
            this.errors[r.id + 'name'] = !r.name || !r.name.length;
            this.errors[r.id + 'symbol'] = !r.symbol || !r.symbol.length;
        });
    }

    formUpdate(formData) {
        let self = this;
        formData.updateFunc(() => {
            self.checkErrors();
            self.props.onChange({metaData: self.state, errors: self.errors});
        });
    }

    addRule() {
        this.formUpdate({
            updateFunc: (cb) => {
                this.state.rules.push({
                    id: Math.random() + '',
                    scaleOfBase: '',
                    name: '',
                    symbol: ''
                });
                cb();
            }
        });
    }

    removeRule(itemToRemove) {
        this.formUpdate({
            updateFunc: (cb) => {
                this.state.rules.splice(this.state.rules.indexOf(itemToRemove), 1);
                cb();
            }
        });
    }

    render() {
        let rules = this.state.rules && this.state.rules.sort((a, b) => a.scaleOfBase - b.scaleOfBase).map((r, i) => {
            return <NonMetricRule unitType={this.props.unit.unitType} key={r.id} removeRule={this.removeRule} errors={this.errors} index={i}
                                  onChange={(res) => this.formUpdate({
                                      updateFunc: (cb) => {
                                          this.state.rules.map(r => {
                                              if (r.id === res.id) {
                                                  if (res.key === 'scaleOfBase') {
                                                      r[res.key] = parseFloat(res.value);
                                                  } else {
                                                      r[res.key] = res.value;
                                                  }
                                              }
                                          })
                                          cb();
                                      }
                                  })} rule={r} rules={this.state.rules}/>
        });
        return (
            <div>
                {rules}
                {this.props.unit.unitType !== 'dimensionless' ?
                    <div className={'row reverse center'} style={{marginTop: '10px'}}>
                        <Button onClick={this.addRule} label={'+ New Label'}/>
                    </div> : null}
            </div>
        );
    }
}

export default NonMetricForm;