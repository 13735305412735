import React from 'react';
import './DesktopLogin.scss';
import {Link} from "react-router-dom";
import {Input, Button} from "gd-react";
import GridDuck from "gridduck";
import animatedLogo from "../images/animated-logo-once.svg";
import {Icon} from "@material-ui/core";
import history from "../meta/history";
import Forgot from "./Forgot";

class MobileForgot extends Forgot {
	render() {
		return (
			<div className={'mobileLoginPage'}>
				<Link to="/" style={{
					'position': 'absolute',
					padding: '20px 30px',
					fontSize: '13px',
					color: 'grey',
					textDecoration: 'none'
				}}><Icon/>Back to login</Link>
				{!this.state.resetPasswordSent ? <div style={{
					'display': 'flex',
					'flexDirection': 'column',
					'flex': 50,
					'justifyContent': 'center',
					'alignItems': 'center'
				}}>
					{/*<p className={'header'}>Enter the email address of the account you want resetting</p>*/}
					<p className={'subHeader'}>Forgot Password</p>
					<div style={{width: '80%'}}>
						<Input disabled={this.state.submitting} label={"Email"} required onChange={this.changeEmail}
							   placeholder={'example@gridduck.com'}
							   error={this.state.triedToSubmit && !this.state.emailAddress.length}
							   autoFocus onKeyPress={(event) => {
							if (event.key === 'Enter') this.submit();
						}} value={this.state.emailAddress}/>
						<div style={{
							'display': 'flex',
							'flexDirection': 'row-reverse',
							marginTop: '30px',
							justifyContent: 'center'
						}}>
							<Button progressRes progressState={this.state.progressState} label={"Submit"}
									onClick={this.submit}/>
						</div>
						<p className={'error-text'}>{this.state.loginError}</p>
					</div>

				</div> : <div style={{
					'display': 'flex',
					'flexDirection': 'column',
					'flex': 50,
					'justifyContent': 'center',
					'alignItems': 'center'
				}}>
					<p className={'subHeader'}>Request Sent!</p>
					<p className={'header'}>Check your email for a link to reset your password</p>
				</div>}
			</div>
			// <div>
			// 	Forgot Password<br/>


			// </div>
		);
	}

}

export default MobileForgot;
