import React from "react";
import "../Rules.scss";
import GridDuck from "gridduck";
import EditRulePageAdvanced from "./EditRulePageAdvanced";
import EditRulePageTimeswitch from "./EditRulePageTimeswitch";
import EditRulePageAlert from "./EditRulePageAlert";
import history from "../../meta/history";
import EditRulePageThermostat from "./EditRulePageThermostat";

class EditRulePage extends React.Component {
	constructor(props) {
		super(props);
		let {ruleId} = this.props.match.params;
		let self = this;
		GridDuck.getRule({
			id: ruleId
		}).then(function (rule) {
			console.log("got rule", rule);
			self.setState({
				rule: rule
			});
		})
		this.state = {};
	}

	render() {
		let content;
		if (this.state.rule) {
			let Component = EditRulePageAdvanced;
			if (this.state.rule.template === 'timeswitch') {
				Component = EditRulePageTimeswitch;
			} else if (this.state.rule.template === 'alert') {
				Component = EditRulePageAlert;
			} else if (this.state.rule.template === 'thermostat') {
				Component = EditRulePageThermostat;
			}
			content = <Component rule={this.state.rule} history={history}/>
		}
		return (<div className={"rule"}>
			{content}
		</div>);
	}
}

export default EditRulePage;
