import React from "react";
import {Card, CardHeader, Icon, Loader} from "gd-react";
import {TSelectableItemProps, createSelectable} from './../../lib/react-selectable-fast/index'

class FileCard extends React.Component<TSelectableItemProps> {
    constructor(props) {
        super(props);
        this.getPreview = this.getPreview.bind(this);
        this.doubleClick = this.doubleClick.bind(this);
        this.getUploadProgress = this.getUploadProgress.bind(this);
        this.fileUpdatedExternally = this.fileUpdatedExternally.bind(this);
        this.state = {
            ref: 0
        };
        if (this.props.file && this.props.file.on) {
            console.log("File card prop exists so binding " + this.props.file.name);
            this.props.file.on('updated', this.fileUpdatedExternally);
        }
    }

    componentWillUnmount() {
        if (this.props.file && this.props.file.off) {
            this.props.file.off('updated', this.fileUpdatedExternally);
        }
    }

    doubleClick() {
        this.props.dblclick();
    }

    fileUpdatedExternally() {
        console.log("Card updated");
        this.setState({ref: this.state.ref++});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps && prevProps.file.id !== this.props.file.id) {
            if (prevProps.file && prevProps.file.off) {
                console.log(prevProps.file.name + " un binding");
                prevProps.file.off('updated', this.fileUpdatedExternally);
            }
        }
        if (this.props.file && this.props.file.id !== prevProps.file.id && !this.props.uploading && this.props.file && this.props.file.on) {
            console.log("File card prop changed and exists so binding " + this.props.file.name);
            this.props.file.on('updated', this.fileUpdatedExternally);
        }
    }

    generalFileType(fileMime) {
        if (fileMime.indexOf('image') !== -1) {
            return 'image';
        } else if (fileMime.indexOf('pdf') !== -1) {
            return 'pdf';
        } else if (fileMime === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            return 'powerpoint';
        } else if (fileMime === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileMime === 'application/msword') {
            return 'word';
        } else if (fileMime.indexOf('video') !== -1) {
            return 'video';
        } else {
            return 'other'
        }
    }
    ;

    getPreview() {
        let fileType = this.generalFileType(this.props.file.type);
        if (fileType === 'pdf') {
            return (<Icon size={'20'} color={'gd-red'} icon={'FaFilePdf'}/>)
        }
        if (fileType === 'word') {
            return (<Icon size={'20'} color={'gd-blue'} icon={'FaFileWord'}/>)
        }
        if (fileType === 'powerpoint') {
            return (<Icon size={'20'} color={'gd-red'} icon={'FaFilePowerpoint'}/>)
        }
        if (fileType === 'video') {
            return (<Icon size={'20'} color={'gd-red'} icon={'FaFileVideo'}/>)
        }
        if (fileType === 'image') {
            return (
                <div className={"image-background"}
                     style={{backgroundImage: "url(" + this.props.file.thumbnail + ")"}}/>)
        }
        return (<Icon size={'20'} color={'gd-red'} icon={'FaFile'}/>)
    }

    getUploadProgress() {
        if (this.props.file.status === 'UPLOADING') {
            if (this.props.file.uploadProgress >= 100) {
                return (<p>Processing</p>);
            } else {
                // return (<p>{this.props.file.uploadProgress}%</p>);
                return (<Loader type={'circular'} withprogress
                                value={!this.props.file.uploadProgress ? 5 : this.props.file.uploadProgress}/>);
            }
        } else if (this.props.file.status === 'QUEUED') {
            return (<p>Queued</p>);
        }
    }

    render() {
        const {selectableRef, isSelected, isSelecting} = this.props
        let preview;
        if (!this.props.uploading) {
            preview = this.getPreview()
        } else {
            preview = this.getUploadProgress();
        }
        let className = "file-card";
        if (this.props.selected) {
            className += " selected";
        }
        return (
            <div ref={selectableRef} className={className} onDoubleClick={this.props.openFile}>
                <Card>
                    <CardHeader title={this.props.file.name}/>
                    <div style={{'display': 'flex', flex: '1', justifyContent: 'center', alignItems: 'center'}}>
                        {preview}
                    </div>
                </Card>
            </div>
        )
    }
}

export default createSelectable(FileCard)