import React from "react";
import {Button, Card, CardBody, CardHeader, Dropdown, GdCheckbox, Input, Loader, Toast} from "gd-react";
import GridDuck from "gridduck";
import {confirmAlert} from "react-confirm-alert";
import history from "../../meta/history";

class GatewayZigbee extends React.Component {
	constructor(props) {
		super(props);
		let self = this;
		this.state = {
			updateRef: 0,
		};
		this.updatedExternally = this.updatedExternally.bind(this);
		this.refresh = this.refresh.bind(this);
		this.untrust = this.untrust.bind(this);
	}

	untrust(assetCode) {
		let self = this;
		confirmAlert({
			customUI: ({onClose}) => {
				return (
					<div className='dialog'>
						<div className={"top-content"}>
							<h2>Untrust {assetCode}</h2>
							<p>Untrusting {assetCode} will result in the device resetting and switching off. Are you sure?</p>
						</div>
						<div className={"bottom-menu"}>
							<Button label={"No"} color={'gd-grey'} onClick={onClose}/>
							<Button color={'gd-red'}
									onClick={() => {
										GridDuck.createGatewayTrustedDeviceUntrustRequest({
											assetCode: assetCode,
											gatewayId: self.props.item.id
										}).then(function() {
											onClose();
										});
									}}
									label={"Yes, un-trust"}
							/>
						</div>
					</div>
				);
			}
		});
	}


	refresh() {
		let self = this;
		self.trustedList = null;
		this.setState({
			updateRef: this.state.updateRef++
		});
		return this.props.item.set({
			getTrustedList: true
		}).then(function () {
			GridDuck.getGatewayTrustList({
				id: self.props.item.id
			}).then(function (trustedList) {
				self.trustedList = trustedList;
				self.trustedList.on('updated', self.updatedExternally);
			});
		});
	}

	componentDidMount() {
		let self = this;
		GridDuck.getAssets({
			filters: [
				{
					field: 'siteId',
					value: this.props.item.siteId
				}
			],
			getAll: true
		}).then(function (assetList) {
			self.assetList = assetList;
		})
	}

	updatedExternally(field) {
		let self = this;
		this.setState({
			updateRef: this.state.updateRef++,
		});
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		let self = this;
		if (prevProps !== this.props || this.props.item.id !== prevProps.item.id) {
			if (prevProps.item) {
				prevProps.item.off('updated', this.updatedExternally);
			}
			this.props.item.on('updated', this.updatedExternally);
			this.updatedExternally();
		}
	}

	componentWillUnmount() {
		if (this.props.item && this.props.item.off) {
			this.props.item.off('updated', this.updatedExternally);
		}
		if (this.trustedList) {
			this.trustedList.off('updated', this.updatedExternally)
		}
	}

	render() {
		let self = this;
		let trustItems = [];
		if (this.trustedList && this.trustedList.trustList) {
			this.trustedList.trustList.forEach(function (trustedItem) {
				if (self.assetList) {
					let thisAsset = self.assetList.list.find(a => a.code === trustedItem);
					if (thisAsset) {
						trustItems.push(
							<div style={{'display':'flex','marginBottom':'20px'}}><p style={{'marginTop':'10px','marginRight':'20px'}}>{thisAsset.name} ({trustedItem})</p><Button onClick={() => self.untrust(trustedItem)} additionalclasses={'sm'} color={'gd-red'}
																																												   label={'Un-Trust'}/></div>
						)
					} else {
						trustItems.push(
							<div style={{'display':'flex','marginBottom':'20px'}}><p style={{'marginTop':'10px','marginRight':'20px'}}>Unknown Appliance ({trustedItem})</p><Button onClick={() => self.untrust(trustedItem)} additionalclasses={'sm'} color={'gd-red'}
																																													label={'Un-Trust'}/></div>
						)
					}
				} else {
					trustItems.push(
						<div style={{'display':'flex','marginBottom':'20px'}}><p style={{'marginTop':'10px','marginRight':'20px'}}>Unknown Appliance ({trustedItem})</p><Button onClick={() => self.untrust(trustedItem)} additionalclasses={'sm'} color={'gd-red'}
																																												label={'Un-Trust'}/></div>
					)
				}
			})
		}
		return (
			<div className={"page grey narrow not-flex"}>
				<div className={'column top'}>
					<div className={'detail-content'}>
						<Card>
							<CardHeader title={'Zigbee Trust List'}/>
							<CardBody>
								{trustItems}
							</CardBody>
						</Card>
					</div>
					<div className={'detail-content'}>
						<Card>
							<CardBody>
								<Button onClick={self.refresh} progressRes additionalclasses={'sm'} color={'gd-blue'}
										label={'Refresh'}/>
							</CardBody>
						</Card>
					</div>
				</div>
			</div>
		)
	}

}

export default GatewayZigbee;
