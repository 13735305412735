import React, {Component} from "react";
import "./EditAddressModal.scss";
import '../../styles/_layout.scss';
import {Button, CardBody, Dropdown, GdAutocomplete, GdCheckbox, Input} from "gd-react";
import GridDuck from "gridduck";
import moment from "moment-timezone";
import history from "../../meta/history";
import GenericLoader from "../../components/GenericLoader";
import BreadcrumbService from "../../meta/breadcrumb-service";
import GdModal from "../../components/GdModal/GdModal";

class EditAddressModal extends Component {

    constructor(props) {
        super(props);
        this.saveChanges = this.saveChanges.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.addressFieldChanged = this.addressFieldChanged.bind(this);
        let self = this;
        this.state = {};
        GridDuck.getAccount({id: GridDuck.userId}).then(function (user) {
            self.account = user;
            let address = user.orgAddress || {};
            address.country = 'United Kingdom';
            if (!self.modalClosed) self.setState(address);
        });
        this.modalClosed = false;
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    saveChanges() {
        let self = this;
        this.setState({triedToSave: true})
        if (Object.keys(this.errors).length > 0) return new Promise(function (resolve, reject) {
            return reject();
        }).catch(function () {
            return Promise.reject();
        });
        return this.account.set({
            orgAddress: {
                line1: self.state.line1,
                line2: self.state.line2,
                city: self.state.city,
                postal_code: self.state.postal_code,
                country: self.state.country
            }
        }).then(function () {
            self.closeModal();
        })
    }

    addressFieldChanged(val) {
        console.log(val.target.name, ' : name');
        console.log(val.target.value, ' : value');
        this.state[val.target.name] = val.target.value;
        this.state.edited = true;
        this.setState(this.state);
    }

    checkErrors() {
        let errors = {};
        if (!this.state.line1 || !this.state.line1.length) errors['line1'] = true;
        if (!this.state.city || !this.state.city.length) errors['city'] = true;
        if (!this.state.postal_code || !this.state.postal_code.length) errors['postal_code'] = true;
        if (!this.state.country || !this.state.country.length) errors['country'] = true;
        this.errors = errors;
    }

    render() {
        if (Object.keys(this.state).length) {
            let footer;
            if (this.state.edited) {
                footer = <Button label={'Save Address'} additionalclasses={'sm'} color={'gd-green'}
                                 onClick={this.saveChanges}
                                 progressRes/>;
            }

            this.checkErrors();
            if (!this.props.inPage) {
                return (
                    <GdModal
                        open={this.props.open}
                        contentLabel={'Edit Address'}
                        title={'Edit Address'}
                        footer={footer}
                        onClose={this.closeModal}>
                        <CardBody>
                            <div style={{marginBottom: '5px'}}>
                                <Input error={this.errors['line1'] && this.state.triedToSave} top="true"
                                       label={'Address Line 1'} name={'line1'} onChange={this.addressFieldChanged}
                                       value={this.state.line1}/>
                            </div>
                            <div style={{marginBottom: '5px'}}>
                                <Input label={'Address Line 2'}
                                       name={'line2'} onChange={this.addressFieldChanged} value={this.state.line2}/>
                            </div>
                            <div style={{marginBottom: '5px'}}>
                                <Input error={this.errors['city'] && this.state.triedToSave} label={'City'}
                                       name={'city'}
                                       onChange={this.addressFieldChanged} value={this.state.city}/>
                            </div>
                            <div style={{marginBottom: '5px'}}>
                                <Input error={this.errors['postal_code'] && this.state.triedToSave} label={'Post Code'}
                                       name={'postal_code'} onChange={this.addressFieldChanged}
                                       value={this.state.postal_code}/>
                            </div>
                            <div style={{marginBottom: '5px'}}>
                                <Input label={'Country'} name={'country'} onChange={this.addressFieldChanged}
                                       value={this.state.country}/>
                            </div>
                        </CardBody>
                    </GdModal>
                )
            } else {
                return (<div style={{display: 'flex', flexDirection: 'column', flex: '1 1'}}>
                    <div style={{marginBottom: '5px'}}>
                        <Input error={this.errors['line1'] && this.state.triedToSave} top="true"
                               label={'Address Line 1'} name={'line1'} onChange={this.addressFieldChanged}
                               value={this.state.line1}/>
                    </div>
                    <div style={{marginBottom: '5px'}}>
                        <Input error={this.errors['city'] && this.state.triedToSave} label={'City'}
                               name={'city'}
                               onChange={this.addressFieldChanged} value={this.state.city}/>
                    </div>
                    <div style={{marginBottom: '5px'}}>
                        <Input error={this.errors['state'] && this.state.triedToSave} label={'County'}
                               name={'state'} onChange={this.addressFieldChanged} value={this.state.state}/>
                    </div>
                    <div style={{marginBottom: '5px'}}>
                        <Input error={this.errors['postal_code'] && this.state.triedToSave} label={'Post Code'}
                               name={'postal_code'} onChange={this.addressFieldChanged}
                               value={this.state.postal_code}/>
                    </div>
                    <div style={{marginBottom: '5px'}}>
                        <Input label={'Country'} name={'country'} disabled onChange={this.addressFieldChanged}
                               value={this.state.country}/>
                    </div>
                    <p style={{
                        fontSize: '12px',
                        padding: '5px 5px',
                        fontStyle: 'italic'
                    }}>We only ship within the United Kingdom</p>
                    {this.state.edited ? <div style={{
                        'display': 'flex',
                        'flexDirection': 'row-reverse',
                        padding: '20px 10px'
                    }}><Button label={'Save Address'} additionalclasses={'sm'} color={'gd-green'}
                               onClick={this.saveChanges}
                               progressRes/></div> : null}
                </div>)
            }
        } else {
            if (!this.props.inPage) {
                return <GdModal
                    open={this.props.open}
                    contentLabel={'Edit Address'}
                    title={'Edit Address'}
                    onClose={this.closeModal}>
                    <CardBody>
                        <GenericLoader text={'Fetching...'} textLineTwo={'Your Address'}/>
                    </CardBody>
                </GdModal>
            } else {
                return (<GenericLoader text={'Fetching...'} textLineTwo={'Your Address'}/>)
            }
        }
    }
}

export default EditAddressModal;