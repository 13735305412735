import React from 'react';
import './MainOrganisationPage.scss';
import GridDuck from 'gridduck';
import {
    AccountAdminListCell,
    ButtonListCell,
    AccessLevelListCell,
    TickListCell,
    LastActiveCell,
    EmailListCell,
    ProfileListCell,
    Button,
    DemandListCell,
    EditableList,
    List,
    SiteGroup,
    StandardListCell,
    StateListCell, Toast
} from "gd-react";
import BreadcrumbService from "../../meta/breadcrumb-service";
import BreadcrumbList from "../../components/BreadcrumbList";
import Modal from 'react-modal';
import history from "../../meta/history";
import AddSiteModal from "../../modals/AddSite/AddSiteModal";
import NoItems from "../../components/noItems";
import AddMemberModal from "../../modals/AddMember/AddMemberModal";
import {confirmAlert} from "react-confirm-alert";

class OrganisationInvites extends React.Component {
    constructor(props) {
        super(props);
        this.getList = this.getList.bind(this);
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId}).then(function (account) {
            if (account.orgPermission === 'admin' || account.orgPermission === 'owner') {
                self.setState({hasAdmin: true})
            }
            if (account.orgPermission === 'owner') {
                self.setState({hasOwner: true});
            }
        });
        this.state = {
            filters: [],
            showToast: false
        }
        GridDuck.getOrganisationInvites({
            offset: 5,
            items: 5
        }).then(function (res) {
            console.log(res, ' : res');
        });
    }

    async getList(retrievalParams) {
        return GridDuck.getOrganisationInvites(retrievalParams);
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({showToast: this.props.location.state.showToast})
        }
    }

    render() {
        let self = this;
        let columns = [
            {component: EmailListCell, bold: true, title: 'Email address', field: 'emailAddress'},
            {component: LastActiveCell, title: 'Sent', field: 'inviteSent'},
            {component: LastActiveCell, title: 'Opened', field: 'linkOpened', maxWidth: 'none'},
            {
                component: ButtonListCell,
                title: 'Resend',
                field: 'None',
                icon: 'FaRedoAlt',
                color: 'gd-blue',
                maxWidth: '80px',
                onClick: (object) => confirmAlert({
                    customUI: ({onClose}) => {
                        return (
                            <div className='dialog'>
                                <div className={"top-content"}>
                                    <h2>Resend invite to {object.emailAddress}</h2>
                                    <p>Are you sure you want to
                                        resend you invite to {object.emailAddress}?</p>
                                </div>
                                <div className={"bottom-menu"}>
                                    <Button label={"No"} color={'gd-grey'} onClick={onClose}/>
                                    <Button color={'gd-green'}
                                            onClick={() => {
                                                self.setState({showToast: 'Invite resent to ' + object.emailAddress})
                                                object.setInviteSent(new Date().getTime() / 1000);
                                                onClose();
                                            }}
                                            label={"Yes, resend"}
                                    />
                                </div>
                            </div>
                        );
                    }
                })
            },
            {
                component: ButtonListCell,
                title: 'Revoke',
                field: 'None',
                icon: 'AiFillDelete',
                color: 'gd-red',
                maxWidth: '80px',
                onClick: (object) => confirmAlert({
                    customUI: ({onClose}) => {
                        return (
                            <div className='dialog'>
                                <div className={"top-content"}>
                                    <h2>Delete invite to {object.emailAddress}</h2>
                                    <p>Are you sure you want to
                                        delete your invite to {object.emailAddress}?</p>
                                </div>
                                <div className={"bottom-menu"}>
                                    <Button label={"No"} color={'gd-grey'} onClick={onClose}/>
                                    <Button color={'gd-red'}
                                            onClick={() => {
                                                self.setState({showToast: 'Invite to ' + object.emailAddress + ' deleted'})
                                                object.delete();
                                                onClose();
                                            }}
                                            label={"Yes, delete"}
                                    />
                                </div>
                            </div>
                        );
                    }
                })
            },
        ];
        return (
            <div className='page'>
                <List nohover
                      noitemelem={<NoItems text={'You have no pending invites'} createtext={'Invite member/s'} modal={AddMemberModal}/>}
                      columns={columns}
                      getlist={this.getList}
                      filters={this.state.filters}
                />
                <Toast onClose={() => this.setState({showToast: null})} message={this.state.showToast}
                       open={!!(this.state.showToast)}
                       severity="success"
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                       }}
                />
            </div>
        );
    }
}

export default OrganisationInvites;
