import React, {Component} from 'react';
import {Input, GdAutocomplete} from "gd-react";
import GenericLoader from "../../components/GenericLoader";
import GridDuck from 'gridduck';
import {BiLoaderCircle, GiLoad} from "react-icons/all";
import LocationForm from "../../components/LocationForm";

class CreateSiteForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceCategories: []
        };
        this.errors = {};
        ['searchLocation', 'selectLocation', 'updateField', 'checkErrors'].forEach(f => {
            this[f] = this[f].bind(this);
        });
        this.locationFormRef = React.createRef();
    }

    updateField(field, value) {
        if (this.props.onChange) this.props.onChange({value: value, field: field});
    }

    checkErrors() {
        this.errors['name'] = !this.state.name || !this.state.name.length;
    }

    async searchLocation(newText) {
        if (!newText.length) return [];
        let res = await GridDuck.getPlacess({filters: [{field: 'input', value: newText}, {field: 'any', value: true}]});
        let locations = res.list[0].locations;
        this.setState({locationsFromSearch: locations});
        return locations.map(function (l) {
            let title = l.structuredFormatting.mainText;
            if (l.structuredFormatting.secondaryText) title += ' - ' + l.structuredFormatting.secondaryText
            return {
                id: l.placeId,
                title: title,
                subTitle: 'Test',
                icon: 'FaBuilding'
            }
        });

    }

    selectLocation(location, obj) {
        this.updateField('locationDetails', obj)
    }

    render() {
        this.checkErrors();
        return (
            <div>
                <div className={'row'}>
                    <Input
                        required
                        error={this.props.item?.errors['name'] && this.props.triedToSave}
                        top="true"
                        label={'Name'}
                        name={'name'}
                        onChange={(e) => this.updateField('name', e.target.value)}
                        value={this.props.item?.name}
                    />
                </div>
                <div className={'row'} style={{marginTop: '15px'}}>
                    <GdAutocomplete
                        async
                        icon
                        name={'locationDetails'}
                        label={'Address'}
                        value={this.props.item?.locationDetails}
                        error={this.props.item?.errors['locationDetails'] && this.props.triedToSave}
                        getOptionSelected={function (option, value) {
                            if (option && value) return option.id === value.id;
                        }}
                        placeholder={'Search address or business name'}
                        getList={this.searchLocation}
                        onChange={this.selectLocation}/>
                </div>
            </div>
        );
    }
}

export default CreateSiteForm;