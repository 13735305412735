import React, {Component} from 'react';
import {GdRadio, Button, GdAutocomplete, Dropdown} from 'gd-react';
import CreateSiteForm from "./CreateSiteForm";
import CreateDeviceForm from "./CreateDeviceForm";
import GridDuck from 'gridduck';
import * as _ from "lodash";

const itemTypes = [
    {
        label: <span>Device <span
            style={{color: 'grey', fontWeight: '500'}}><br/>E.g. Electricity Meter...</span></span>,
        name: 'device',
        value: 'device',
        title: 'A Device'
    },
    {
        label: <span>Site <span
            style={{color: 'grey', fontWeight: '500'}}><br/>E.g. Footfall...</span></span>,
        name: 'site',
        value: 'site',
        title: 'A Site'
    },
    {
        label: <span>Your Organisation <span
            style={{color: 'grey', fontWeight: '500'}}><br/>E.g. Total Revenue...</span></span>,
        name: 'organisation',
        value: 'organisation',
        title: 'Your Organisation'
    }
]

const createForms = {
    'site': CreateSiteForm,
    'device': CreateDeviceForm
}

class DataItemDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataItem: this.props.dataItem?.dataItem
        };
        ['addNewItem', 'removeAndResetItem', 'generateItemCard', 'getItems'].forEach(f => this[f] = this[f].bind(this));
    }

    async getItems(searchTerm, reset, itemType) {
        let icon;
        if (itemType === 'device') {
            icon = 'FaPlug';
        } else if (itemType === 'site') {
            icon = 'FaBuilding';
        }
        let self = this
        if (reset) this.itemsOffset = 0
        let filters = []
        let limit = 25
        if (searchTerm) filters.push({
            field: 'search',
            value: searchTerm
        });
        let itemsRes;
        if (itemType === 'device') {
            itemsRes = await GridDuck.getAssets({filters: filters, offset: this.itemsOffset, items: limit});
        } else if (itemType === 'site') {
            itemsRes = await GridDuck.getSites({filters: filters, offset: this.itemsOffset, items: limit});
        }
        let items = itemsRes.list
            .filter(s => s._permission === 'admin')
            .map((s) => {
                return {
                    id: s.id,
                    title: s.name,
                    locationId: s.locationId,
                    subtitle: s.locationName || s.siteName || '',
                    icon: icon,
                }
            })
        let newOffset = self.itemsOffset + limit;
        self.itemsOffset = newOffset
        return Promise.resolve(items)
    }

    generateItemCard(itemType) {
        let arrayKey = 'new' + _.capitalize(this.props.dataItem.itemType) + 's';
        const newItem = this.props.dataItem.newItem;
        let newItemObject;
        if (newItem) {
            newItemObject = this.props[arrayKey].find(item => item.id === this.props.dataItem.itemId);
        }
        const CreateForm = createForms[itemType];
        return <div>
            {!newItem ? <div>
                <div className={'row'}>
                    <GdAutocomplete
                        async
                        icon
                        subtitle
                        lazyload
                        getShortcuts={this.getShortcuts}
                        shortcuts={this.state.shortcuts}
                        fixedOptions={this.props[arrayKey].map(item => {
                            return {
                                id: item.id,
                                title: item.name,
                                type: 'Recent',
                                icon: 'GiAnticlockwiseRotation'
                            }
                        })}
                        getList={(searchTerm, reset) => this.getItems(searchTerm, reset, itemType)}
                        name={itemType}
                        getOptionSelected={function (option, value) {
                            if (option && value) return option.id === value.id;
                        }}
                        noLabel
                        value={this.props.dataItem.itemId}
                        error={this.props.triedToSave && this.props.dataItem.errors['itemId']}
                        placeholder={'Select a ' + itemType}
                        onChange={(e, value) => this.props.onChange({
                            di: this.props.dataItem,
                            label: 'itemId',
                            value: value
                        })
                        }/>
                </div>
            </div> : <div>
                <CreateForm triedToCreate={this.state.triedToCreate} triedToDone={this.state[itemType + 'triedToDone']}
                            dataItem={this.props.dataItem}
                            triedToSave={this.props.triedToSave}
                            item={newItemObject}
                            addNewItem={this.props.addNewItem}
                            updateNewItem={this.props.updateNewItem}
                            removeNewItem={this.props.removeNewItem}
                            newSites={this.props.newSites}
                            newDevices={this.props.newDevices}
                            onChange={(data) => this.props.updateNewItem({
                                type: itemType,
                                id: newItemObject?.id,
                                value: data?.value,
                                field: data?.field
                            })}/>
            </div>
            }

            {[(<p key={this.props.dataItem.id + 'o-d'} className={'or-divider'}>or</p>), (
                <Button
                    label={(newItem ? 'Select existing' : '+ Create new') + ' ' + itemType}
                    additionalclasses={'md'}
                    key={this.props.dataItem.id + 'a-e-b'}
                    onClick={() => {
                        let makeNewItem = !this.props.dataItem.newItem;
                        if (makeNewItem) {
                            this.addNewItem();
                        } else {
                            this.removeAndResetItem();
                        }
                    }}/>)]}
        </div>
    }

    addNewItem(noName) {
        let itemType = this.props.dataItem.itemType;
        let name = !noName ? this.props.dataItem.name : '';
        let obj;
        let siteObj = {
            name: name,
            locationDetails: null
        };
        let deviceObj = {
            name: name,
            deviceCategory: '',
            siteId: ''
        };
        if (itemType === 'device') obj = deviceObj;
        if (itemType === 'site') obj = siteObj;
        let newItem = this.props.addNewItem({
            type: itemType, value: obj
        });
        this.props.onChange({
            di: this.props.dataItem,
            label: 'itemId',
            value: newItem.id,
            cb: () => {
                this.props.onChange({
                    di: this.props.dataItem,
                    label: 'newItem',
                    value: true
                })
            }
        })
    }

    removeAndResetItem() {
        let itemType = this.props.dataItem.itemType;
        this.props.removeNewItem({type: itemType, id: this.props.dataItem[itemType + 'Id']});
        this.props.onChange({
            di: this.props.dataItem,
            label: 'itemId',
            value: null,
            cb: () => {
                this.props.onChange({
                    di: this.props.dataItem,
                    label: 'newItem',
                    value: false
                })
            }
        })
    }

    render() {
        let firstStepComplete = (this.props.dataItemsLayout === 'item_name_column' && this.props.dataItem.dataTypes && this.props.dataItem.dataTypes.length) || (this.props.dataItemsLayout === 'device_per_column' && this.props.dataItem.valueColumn);
        let dataTypeValueColumns = [];
        this.props.dataTypes.forEach(dt => {
            dataTypeValueColumns = dataTypeValueColumns.concat(dt.valueColumns.map(c => {
                return {
                    value: c,
                    title: c
                }
            }))
        });
        if (this.props.dataItemsLayout === 'device_per_column' && !this.props.dataItem.valueColumn && dataTypeValueColumns.length === 1) {
            this.props.onChange({
                di: this.props.dataItem,
                label: 'valueColumn',
                value: dataTypeValueColumns[0].value,
                cb: this.props.onChange({
                    di: this.props.dataItem,
                    label: 'name',
                    value: dataTypeValueColumns[0].value
                })
            })
        }

        return (<div>
            {this.props.dataItemsLayout === 'item_name_column' ? <div className={'row'} style={{padding: '5px'}}>
                {this.props.dataTypeAutocomplete(
                    {
                        id: this.props.dataItem.id + '_data_type',
                        value: this.props.dataItem.dataTypes,
                        label: 'Data Type/s',
                        multiple: true,
                        error: this.props.triedToSave && this.props.dataItem.errors['dataTypes'],
                        onChange: (value) => {
                            this.props.onChange({
                                di: this.props.dataItem,
                                label: 'dataTypes',
                                value: value
                            })
                        }
                    }
                )}
            </div> : null}
            {this.props.dataItemsLayout === 'device_per_column' ?
                <div className={'row'} style={{padding: '5px'}}>
                    <Dropdown label={'Item Data Column'}
                              onChange={(e) => this.props.onChange({
                                  di: this.props.dataItem,
                                  label: 'valueColumn',
                                  value: e.target.value,
                                  cb: this.props.onChange({
                                      di: this.props.dataItem,
                                      label: 'name',
                                      value: e.target.value
                                  })
                              })}
                              placeholder={'Select Column'}
                              error={this.props.triedToSave && this.props.dataItem.errors['valueColumn']}
                              fixeditems={dataTypeValueColumns} value={this.props.dataItem.valueColumn}/>
                </div> : null}
            {firstStepComplete ? <div style={{marginTop: '10px'}}>
                <label style={{paddingLeft: '15px'}}>Item Type</label>
                <div className={'dot-at-top'} style={{marginTop: '15px'}}>
                    <GdRadio
                        error={this.props.triedToSave && this.props.dataItem.errors['itemType']}
                        onChange={(e) => this.props.onChange({
                            di: this.props.dataItem,
                            label: 'itemType',
                            value: e.target.value,
                            cb: () => {
                                this.removeAndResetItem();
                            }
                        })}
                        value={this.props.dataItem.itemType} row options={itemTypes}/>
                </div>
            </div> : null}
            {this.props.dataItem.itemType && this.props.dataItem.itemType !== 'organisation' && firstStepComplete ?
                <div style={{marginTop: '10px', padding: '5px'}}>
                    <div className={'row'}>
                        <label style={{paddingLeft: '5px'}}>{_.capitalize(this.props.dataItem.itemType)} Details</label>
                    </div>
                    <div className={'inner-tabbed-page data-upload'} style={{padding: '10px'}}>
                        {this.generateItemCard(this.props.dataItem.itemType)}
                    </div>
                </div> : null}
        </div>);
    }
}

export default DataItemDetails;