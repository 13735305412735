import React from "react";
import "./AnomalyDetection.scss";
import TabbedPage from "../../components/TabbedPage/TabbedPage";
import Anomalies from "./Anomalies";
import AnomalyMonitoredDevices from "./MonitoredDevices";

class AnomalyDetectionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        let tabs = [
            {
                default: true,
                url: 'anomalies',
                title: 'Anomalies',
                component: Anomalies,
            },
            {
                url: 'monitored-devices',
                title: 'Monitored Devices',
                component: AnomalyMonitoredDevices
            },
        ];
        console.log("data tabs",tabs)
        return (
            <TabbedPage dataLoaded={true} className={"download-page"} tabs={tabs} {...this.props}/>
        )
    }

}

export default AnomalyDetectionPage;