import React from 'react';
import './MobileSiteGroupSites.scss';
import {Button, DemandListCell, EditableList, List, SiteGroup, StandardListCell} from "gd-react";
import SiteGroupSites from "./SiteGroupSites";

class MobileSiteGroupSites extends SiteGroupSites {
	render() {
		let self = this;
		let sites;
		let {itemId} = this.props.match.params;
		let siteGroupId = itemId;

		if (this.props.listFilters) {
			if (siteGroupId.substr(0, 3) === 'all') {
				sites = (
					<List
						columns={[
							{
								component: StandardListCell,
								bold: true,
								title: 'Name',
								field: 'name',
								sortable: true,
								defaultSort: 'asc'
							},
							{
								component: StandardListCell,
								title: 'Issues',
								field: 'issues',
								custom: function (object) {
									let activeIssues = object.issueIds.filter(i => i && self.account ? (self.account.ignoredAssets.indexOf(i.id) === -1 && self.account.ignoredSites.indexOf(i.id) === -1) : true);
									let activeIssuesCount = 0;
									activeIssues.forEach(function (issue) {
										activeIssuesCount += 1 + (issue ? issue.inferredAssetIds ? issue.inferredAssetIds.length : 0 : 0)
									})
									return activeIssuesCount;
								}
							}
						]}
						getlist={this.getAvailableList}
						rowOnClick={this.goToSite}/>
				)
			} else {
				sites = (
					<EditableList
						columns={[
							{
								component: StandardListCell,
								bold: true,
								title: 'Name',
								field: 'name',
								sortable: true,
								defaultSort: 'asc'
							},
							{
								component: StandardListCell,
								title: 'Issues',
								field: 'issues',
								custom: function (object) {
									let activeIssues = object.issueIds.filter(i => i && self.account ? (self.account.ignoredAssets.indexOf(i.id) === -1 && self.account.ignoredSites.indexOf(i.id) === -1) : true);
									let activeIssuesCount = 0;
									activeIssues.forEach(function (issue) {
										activeIssuesCount += 1 + (issue ? issue.inferredAssetIds ? issue.inferredAssetIds.length : 0 : 0)
									})
									return activeIssuesCount;
								}
							}
						]}
						getActiveList={this.getList}
						getAvailableList={this.getAvailableList}
						rowOnClick={this.goToSite}
						save={this.save}
						disabled={siteGroupId.substr(0, 3) === 'all' || !this.props.editable}
						filters={this.props.listFilters}/>
				)
			}
		}
		return (
			<div className='mobile-site-group-sites'>
				{sites}
			</div>
		);
	}
}

export default MobileSiteGroupSites;
