import React from 'react';
import './Account.scss';
import GridDuck from 'gridduck';
import {Card, CardBody, CardHeader, Button, Icon, Tooltip, Loader, Input, Toast} from "gd-react";
import AddPaymentCardModal from "../../modals/AddPaymentCard/AddPaymentCardModal";
import GenericLoader from "../../components/GenericLoader";
import EditAddressModal from "../../modals/EditAddress/EditAddressModal";
import PaymentMethods from "../../components/PaymentMethods";
import SaveBar from "../../components/SaveBar/SaveBar";

class AccountBilling extends React.Component {
    constructor(props) {
        super(props);
        this.loadCards = this.loadCards.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.createPaymentInfoSession = this.createPaymentInfoSession.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.hasChanged = this.hasChanged.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.setUserInfo = this.setUserInfo.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            openAddPaymentCardModal: false,
            userDetails: {
                billingEmail: this.props.item.billingEmail || ''
            }
        };
    }

    handleClose() {
        this.setState({open: false})
    }


    onFormChange(val) {
        this.state.userDetails[val.target.name] = val.target.value;
        this.setState({userDetails: this.state.userDetails});
    }

    createPaymentInfoSession() {
        GridDuck.createStripeSession({url: ''}).then(function (session) {
            window.location.replace(session.url);
        })
    }

    loadCards() {
        let self = this;
        self.setState({loaded: false});
        GridDuck.getPaymentCards({getAll: true, filters: []}).then(function (res) {
            self.setState({cards: res.list, loaded: true});
        })
    }

    loadAddress() {
        let self = this;
        GridDuck.getOrganisation({id: this.props.item.organisationId}).then(function (org) {
            self.setState({account: self.props.item, org: org});
        })
    }

    componentDidMount() {
        this.loadCards();
        this.loadAddress();
    }

    updateAddress() {
        this.setState({openAddressModal: true});
    }

    toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    setUserInfo() {
        let self = this;

        self.setState({
            userDetails: {
                billingEmail: this.props.item.billingEmail
            }
        });

    }

    saveChanges() {
        let self = this;
        return this.props.item.set(this.state.userDetails).then(function () {
            self.setUserInfo();
            self.setState({open: true})
        });
    };

    cancelChanges() {
        this.setUserInfo();
    };

    hasChanged() {
        let self = this;
        let hasChanged = false;
        if (self.props.item) {
            Object.entries(self.props.item).map(([key, value]) => {
                let updatedKey = key.replace(/^_+/i, '');
                if ((updatedKey in self.state.userDetails) && self.state.userDetails[updatedKey] !== value) {
                    hasChanged = true;
                }
            })
        }
        return hasChanged;
    }

    render() {

        let footerSaveBar = this.hasChanged() ?
            <SaveBar onSaveClick={this.saveChanges} onCancelClick={this.cancelChanges}/> : '';

        let self = this;
        return (
            <div className='page narrow Account not-flex grey'>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        {footerSaveBar}
                    </div>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader>Details</CardHeader>
                            <CardBody>
                                <div>
                                    <div className={'row'}>
                                        <Input top={'true'} name={'billingEmail'} onChange={this.onFormChange}
                                               value={this.state.userDetails.billingEmail}
                                               label={'Billing Email Address'}/>
                                    </div>

                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    <div className={'detail-content'}>
                        {this.state.account && <Card>
                            <div className={'CardHeader'} style={{
                                paddingRight: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>Address{this.state.account.orgPermission === 'owner' ?
                                <Button color={'gd-grey'} onClick={this.updateAddress} label={'Edit'}/> : ''}
                            </div>
                            {this.state.account.orgAddress && <CardBody>
                                <p>{this.state.account.orgAddress.line1}</p>
                                <p>{this.state.account.orgAddress.line2}</p>
                                <p>{this.state.account.orgAddress.city}</p>
                                <p>{this.state.account.orgAddress.state}</p>
                                <p>{this.state.account.orgAddress.postal_code}</p>
                                <p>{this.state.account.orgAddress.country}</p>
                            </CardBody>}
                        </Card>}
                    </div>
                    {this.state.org && this.state.account.orgPermission === 'owner' && !this.state.org.free ?
                        <div className={'detail-content'}>
                            <Card>
                                <div className={'CardHeader'} style={{
                                    paddingRight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>Payment Methods
                                </div>

                                {this.state.loaded && this.state.cards ?
                                    <PaymentMethods editable methods={this.state.cards}
                                                    loadMethods={this.loadCards}/> : null}

                                {!this.state.loaded &&
                                <GenericLoader text={'Fetching...'} textLineTwo={'Payment Methods'}/>
                                }

                                {this.state.loaded &&
                                <div style={{'display': 'flex', 'flexDirection': 'row-reverse', padding: '20px 10px'}}>
                                    <Button additionalclasses={'md'} progressRes label={'Add Payment Method'}
                                            onClick={self.createPaymentInfoSession}/>
                                </div>}

                            </Card>
                        </div> : null}

                </div>
                {this.state.openAddPaymentCardModal ?
                    <AddPaymentCardModal item={this.props.item} open={this.state.openAddPaymentCardModal}
                                         onClose={(reload) => {
                                             this.setState({openAddPaymentCardModal: false})
                                             if (reload) this.loadCards();
                                         }}/> : ''}
                {this.state.openAddressModal ?
                    <EditAddressModal item={this.props.item} open={this.state.openAddressModal}
                                      onClose={(reload) => {
                                          this.setState({openAddressModal: false})
                                      }}/> : ''}
                <Toast onClose={this.handleClose} message={"Save Successful"}
                       anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                       open={this.state.open}/>
            </div>
        );
    }
}

export default AccountBilling;