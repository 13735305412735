import React, {Component} from 'react';
import {Dropdown, Button, Icon} from "gd-react";

// moment dates


class GenerateSingleDateString extends Component {

    extractFormatParts(dateFormat, formatParts) {
        let formatElements = [];

        while (dateFormat.length > 0 && formatParts) {
            const matchingPart = formatParts.find(part =>
                part.value && dateFormat.startsWith(part.value)
            );

            if (matchingPart) {
                formatElements.push(matchingPart.value);
                dateFormat = dateFormat.slice(matchingPart.value.length);
            } else {
                formatElements.push(dateFormat[0]);
                dateFormat = dateFormat.slice(1);
            }
        }

        return formatElements;
    }

    constructor(props) {
        super(props);
        this.state = {};
        this.FormatOptions = this.props.dateFormatOptions();
        if (this.props.detectedFormat && (!this.props.dateFormat || !this.props.dateFormat.length)) {
            let separatedDate = this.extractFormatParts(this.props.detectedFormat, this.FormatOptions);
            this.props.onChange(separatedDate);
        }

        // this.props.onChange(['']);
        // if (!this.props.dateFormat || typeof this.props.dateFormat !== 'object') {
        // }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.detectedFormat !== this.props.detectedFormat) {
            let separatedDate = this.extractFormatParts(this.props.detectedFormat, this.FormatOptions);
            this.props.onChange(separatedDate);
        }
    }

    render() {
        return (
            <div className={'row date-formats '  + (this.props.error ? 'error' : '')} style={{flexWrap: 'wrap'}}>
                {/*TODO Create tool that displays the date format option strings by splitting them by separator and asking what's in betweek*/}

                {(this.props.dateFormat || typeof this.props.dateFormat === 'object') && this.props.dateFormat.map((dfo, i) =>
                    <div key={dfo + i} className={'row'} style={{flex: 0, alignItems: 'center'}}><Dropdown
                        key={"unix-timestamp"}
                        noLabel
                        placeholder={'Select date component'}
                        onChange={(val) => {
                            let updateArray = Object.assign([], this.props.dateFormat);
                            updateArray[i] = val.target.value;
                            this.props.onChange(updateArray);
                        }}
                        fixeditems={this.FormatOptions}
                        value={dfo}/>
                        <div className={'trash-icon clickable'} onClick={(val) => {
                            let updateArray = Object.assign([], this.props.dateFormat);
                            updateArray.splice(i, 1);
                            this.props.onChange(updateArray);
                        }}><Icon icon={'FaTrash'} color={'gd-red'} size={'11'}/></div>
                    </div>)}
                <Button label={'+'} onClick={(val) => {
                    let updateArray = Object.assign([], this.props.dateFormat);
                    updateArray.push('');
                    this.props.onChange(updateArray);
                }}/>
            </div>
        );
    }
}

export default GenerateSingleDateString;