import React, {Component} from "react";
import "./OptionField.scss";
import '../../styles/_layout.scss';
import {Icon} from 'gd-react'

class AddInvoiceModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showing: !!this.props.showing
        }

    }


    render() {

        return (<div className={'optional-field row'}>
            {!this.props.showing ? <div className={'add-remove-button add'} onClick={()=>this.props.onAddRemove(true)}><Icon icon={'FaPlus'} color={'gd-brand'}/>Include {this.props.label}</div> :
                [(this.props.children), (<div className={'add-remove-button remove'} onClick={()=>this.props.onAddRemove(false)}>Remove</div>)]}
        </div>)
    }
}

export default AddInvoiceModal;