import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Input,
    GdAutocomplete,
} from "gd-react";
import "./reset.scss";
import GridDuck from "gridduck";
import getFormat from "../../services/formatter";

class CreateReset extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        this.state = {};
        this.getList = this.getList.bind(this);
        this.create = this.create.bind(this);
    }

    create() {
        let self = this;
        return GridDuck.createAssetReset({
            assetId: this.state.deviceId,
            timestamp: this.state.timestamp,
            value: this.state.value
        }).then(function () {
            self.setState({timestamp: null, value: null});
            return Promise.resolve();
        });
    }

    getList(searchTerm) {
        return GridDuck.getAssetGods({
            filters: [{
                field: 'search',
                value: searchTerm
            }]
        }).then(function (siteList) {
            return Promise.resolve(
                siteList.list.map(
                    s => ({
                        id: s.id,
                        title: s.name + ' (' + s.siteName + ')'
                    })
                )
            );
        })
    }

    render() {
        let self = this;

        return (
            <div className={"page narrow not-flex grey reset-page"}>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        <Card className={'asset-general-settings'}>
                            <CardHeader title={'General'}/>
                            <CardBody>
                                <GdAutocomplete
                                    async
                                    placeholder={'Device'}
                                    value={this.state.deviceId}
                                    onChange={(ev, item) => {
                                        if (item) {
                                            this.setState({deviceId: item.id});
                                        }
                                    }}
                                    getList={(searchTerm) => self.getList(searchTerm)}
                                    label={"Device search"}
                                />
                                <br/>
                                <Input label={'Timestamp (ms)'} type={'number'} name={'timestamp'} value={this.state.timestamp} onChange={(e) => this.setState({timestamp: e.target.value})}/>
                                <br/>
                                {this.state.timestamp ? getFormat('date')(this.state.timestamp/1000) : ' '}
                                <br/>
                                <Input label={'Value'} type={'number'} name={'value'} value={this.state.value} onChange={(e) => this.setState({value: e.target.value})}/>
                                <br/>
                                {this.state.value >= 0 ? 'Positive to correct a negative data spike (Reset)' : 'Negative to correct a positive data spike (Extra data)'}
                                <br/>
                                <br/>
                                <Button label={'Create'} onClick={this.create} progressRes/>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateReset;
