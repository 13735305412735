import React, {Component} from "react";
import "./dataUpload.scss";
import '../../styles/_layout.scss';
import {
    Button,
    CardBody,
    GdAutocomplete,
    Loader,
    Dropdown,
    GdCheckbox,
    Input,
    GdRadio
} from "gd-react";
import GdModal from "../../components/GdModal/GdModal";
import GridDuck from 'gridduck';
import InfoCard from "./InfoCard";
import CreateSiteForm from "./CreateSiteForm";
import CreateDeviceForm from "./CreateDeviceForm";
import CreateDataCategory from "./CreateDataCategory";
import CreateDataType from "./CreateDataType";
import FileUploadDropper from "../../components/FileManager/FileUploadDropper";
import Icon from "../../components/Icon/Icon";
import FileUploadQueue from "../../components/FileManager/FileUploadQueue";
import DataTypesFromColumns from "./DataTypesFromColumns";
import DataItemTabs from "./DataItemTabs";
import * as _ from 'lodash';
import PreviewGraph from "./PreviewGraph";
import ErrorPreview from "./ErrorPreview";
import {camelfySync} from "../../services/CamelfyRequestData";

class CreateDataUpload extends Component {

    constructor(props) {
        super(props);
        ['dataUploadUpdated', 'addDataItem', 'removeNewItem', 'updateNewItem', 'addNewItem', 'dataTypeAutocomplete', 'generateDataItemsFromColumn', 'getDataItemObject', 'unselectDataTypes', 'selectDataType', 'unselectDataItems', 'selectDataItem', 'filesSelected', 'removeDataType', 'removeDataItem', 'addDataType', 'closeModal', 'uploadData', 'onTabClick', 'checkErrors', 'getShortcuts', 'getDataCategories', 'getDataTypes', 'columnsDropDown', 'validateDataTypes', 'validateDataItems', 'generateDataTypesArray'].forEach(f => {
            this[f] = this[f].bind(this);
        });
        this.changed = false;
        this.errors = {};
        let clonedItem = camelfySync(_.cloneDeep(this.props.item));
        this.state = {
            stage: 0,
            id: clonedItem?.id,
            csvFileNames: clonedItem?.csvFileNames,
            columnData: clonedItem?.columnData,
            dataTypes: clonedItem?.dataTypes && clonedItem?.dataTypes.length ? clonedItem?.dataTypes : this.generateDataTypesArray(),
            newSites: clonedItem?.newSites || [],
            newDevices: clonedItem?.newDevices || [],
            noHeaders: clonedItem?.noHeaders,
            dataItems: clonedItem?.dataItems || [],
            groupName: clonedItem?.groupName,
            dataItemsColumn: clonedItem?.dataItemsColumn,
            dataItemsLayout: clonedItem?.dataItemsLayout || 'item_name_column',
            previewSampleData: clonedItem?.previewSampleData,
            processedErrors: clonedItem?.processedErrors,
            shortcuts: [],
            hasChanged: false,
            anchorEl: null,
            showDataCategoryModal: false,
            uploadProgress: 0,
            tabs: [[
                {
                    id: 1,
                    title: 'Data Info',
                    selected: true,
                    onTabClick: this.onTabClick
                },
                {
                    id: 2,
                    title: 'CSV Data',
                    onTabClick: this.onTabClick
                }
            ]]
        }
        this.modalClosed = false;
        this.fileUploadQueue = new FileUploadQueue(this);
        this.validateDataItems();
        this.validateDataTypes();
        if (this.props.item?.id) {
            this.props.item.on('updated', this.dataUploadUpdated);
        }
        this.dataTypesCardRef = React.createRef();
        this.dataItemsCardRef = React.createRef();
    }

    removeNewItem({type, id}) {
        if (type === 'organisation') return;
        let arrayKey = 'new' + _.capitalize(type) + 's';
        this.state[arrayKey] = this.state[arrayKey].filter(item => item.id !== id);
        this.setState(this.state);
    }

    updateNewItem({type, id, field, value}) {
        if (type === 'organisation') return;
        let arrayKey = 'new' + _.capitalize(type) + 's';
        this.state[arrayKey] = this.state[arrayKey].map(item => {
            if (item.id === id) {
                item[field] = value;
            }
            return item;
        });
        this.setState(this.state);
    }

    addNewItem({type, value}) {
        if (type === 'organisation') return;
        let arrayKey = 'new' + _.capitalize(type) + 's';
        let newItemId = Math.random().toString(12).slice(2);
        let newItem = {id: newItemId, ...value};
        this.state[arrayKey].push(newItem);
        this.setState(this.state);
        return newItem;
    }

    getDataItemObject(id, index, newItem, selected) {
        if (!id) id = Math.random().toString(12).slice(2);
        return {
            id: id,
            name: !newItem ? id : '',
            errors: {},
            itemType: 'device',
            selected: selected || index === 0,
            dataTypes: [],
            newItem: false,
            itemId: ''
        }
    }

    generateDataItemsFromColumn(column) {
        let _column = this.state.columnData.find(c => c.id === column);
        this.setState({dataItems: _column.options.map((o, i) => this.getDataItemObject(o, i))})
    }

    filesSelected(files) {
        if (!this.state.csvData) this.state.csvData = [];
        this.setState({
            csvData: files.filter(f => !this.state.csvData.find(_f => _f.name === f.name) && f.type === 'text/csv').concat(this.state.csvData),
            hasCSVs: true,
            triedToSaveTemplate: false
        });
    }

    dataUploadUpdated() {
        if (this.updating) return;
        this.updating = true;
        let clonedItem = camelfySync(_.cloneDeep(this.props.item));
        this.setState({
            id: clonedItem.id,
            itemType: clonedItem.itemType,
            status: clonedItem.status,
            processingStatusDetails: clonedItem.processingStatusDetails,
            csvFileNames: clonedItem.csvFileNames,
            columnData: clonedItem.columnData,
            dataTypes: clonedItem.dataTypes && this.props.item?.dataTypes.length ? clonedItem.dataTypes : this.generateDataTypesArray(),
            newSites: clonedItem.newSites || [],
            newDevices: clonedItem.newDevices || [],
            noHeaders: clonedItem.noHeaders,
            dataItems: clonedItem.dataItems || [],
            groupName: clonedItem.groupName,
            dataItemsColumn: clonedItem.dataItemsColumn,
            dataItemsLayout: clonedItem.dataItemsLayout || 'item_name_column',
            previewSampleData: clonedItem.previewSampleData,
            processedErrors: clonedItem.processedErrors
        }, () => {
            this.updating = false;
        })
    }

    componentWillUnmount() {
        if (this.props.item?.id) this.props.item.off('updated', this.dataUploadUpdated);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps?.item?.id !== this.props?.item?.id) {
            if (this.props.item?.id) this.props.item.on('updated', this.dataUploadUpdated);
            this.dataUploadUpdated();
        }
    }

    onTabClick(ev, tab) {
        this.state.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    async getShortcuts() {
        let filters = [
            {
                field: 'itemType',
                value: this.state.itemType
            }
        ]

        let res = await GridDuck.getSearchHistorys({filters: filters})
        let shortcuts = res.list.map((r) => {
            return {
                id: r.itemId,
                title: r.name,
                subtitle: r.metadata?.locationName || r.metadata?.siteName,
                locationId: r.metadata.locationId,
                icon: 'GiAnticlockwiseRotation',
                type: 'Recent'
            }
        })
        this.setState({shortcuts: shortcuts})
    }

    async getDataTypes(searchTerm, reset) {
        let filters = [{
            field: 'categoryId',
            value: this.state.dataCategory?.id
        }];
        if (this.dataTypes && this.dataTypeCount && this.dataTypes.length === this.dataTypeCount && !reset) {
            return Promise.resolve([]);
        }
        if (searchTerm) filters.push({
            field: 'search',
            value: searchTerm
        });
        let itemsRes = await GridDuck.getDataTypes({getAll: true, filters: filters});
        this.dataTypeCount = itemsRes.total;
        let items = itemsRes.list
            .map((s) => {
                return {
                    id: s.id,
                    title: s.name,
                    icon: s.dataType === 'instantaneous' ? 'FaChartLine' : 'FaChartBar',
                    subtitle: s.unitDetails?.name + (s.rateUnitDetails?.name ? (' / ' + s.rateUnitDetails?.name) : '')
                }
            }).filter(u => searchTerm ? (u.title.toLowerCase().startsWith(searchTerm.toLowerCase())) : true)
        this.dataTypes = items;

        return Promise.resolve(items);
    }

    async getDataCategories(searchTerm, reset) {
        let filters = [];
        if (this.categories && this.categoryCount && this.categories.length === this.categoryCount && !reset) {
            return Promise.resolve([]);
        }
        if (searchTerm) filters.push({
            field: 'search',
            value: searchTerm
        });
        let itemsRes = await GridDuck.getDataCategorys({getAll: true, filters: filters});
        this.categoryCount = itemsRes.total;
        let items = itemsRes.list
            .map((s) => {
                return {
                    id: s.id,
                    title: s.name,
                    icon: s.icon,
                    color: s.color
                }
            }).filter(u => searchTerm ? (u.title.toLowerCase().startsWith(searchTerm.toLowerCase())) : true)
        this.categories = items;

        return Promise.resolve(items);
    }

    async uploadData(e) {
        let self = this;
        this.setState({triedToSave: true})
        if (Object.keys(this.errors).length > 0) {
            return Promise.reject()
        }
    }

    checkErrors() {
        let errors = {};
        this.errors = errors;
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    async uploadFiles(csvData, error) {
        let self = this;
        return new Promise((resolve, reject) => {
            this.setState({uploadingFiles: true, uploadProgress: 1}, async () => {
                let account = await GridDuck.getAccount({id: GridDuck.userId});

                let uploadData = await GridDuck.createDataUpload({
                    file: csvData,
                    itemType: 'data',
                    itemId: account.organisationId,
                    groupName: self.state.groupName,
                    noHeaders: csvData.map(csv => {
                        if (csv.noHeaders) {
                            return csv.name;
                        } else return null;
                    }).filter(c => c),
                    type: 'csv'
                }, function uploadProgress(progressEvt) {
                    let loadedPercentage = Math.round((progressEvt.loaded / progressEvt.total) * 100),
                        processing;
                    if (loadedPercentage >= 100) {
                        processing = true;
                    }
                    self.setState({uploadProgress: loadedPercentage, processing: processing});
                });
                if (this.props.reloadItem) this.props.reloadItem(uploadData.id);
                this.setState({processedFiles: true}, () => {
                    return resolve({continue: true});
                });
            });
        })
    }

    dataTypeAutocomplete({label, onChange, error, value, id}) {
        let dataTypeDropDownItems = this.state.dataTypes.map(dt => {
            return {
                id: dt.id,
                title: dt.dataType.title
            }
        });

        return <GdAutocomplete
            key={"column-date-" + id}
            error={error}
            options={dataTypeDropDownItems}
            name={value}
            getOptionSelected={function (option, value) {
                if (option && value) return option.id === value.id;
            }}
            filterSelectedOptions
            value={value}
            multiple
            label={label}
            placeholder={'E.g. Electricity Quantity, Electricity Rate, Items Produced...'}
            onChange={(e, val) => {
                onChange(val)
            }}/>
    }

    columnsDropDown(label, value, id, cb, dt, params) {
        if (!this.props.item?.columnData) return [];
        let columns = [].concat(this.props.item?.columnData.map(cd => {
            return {
                title: <span>{cd.name}<span
                    style={{color: 'grey'}}>- {cd.possibleDate && cd.dateFormatOptions ? cd.dateFormatOptions.map((o, i) => {
                    let val;
                    if (i < 3) {
                        val = o.title;
                        if (i < 2 && (i !== cd.dateFormatOptions.length - 1)) {
                            val += ', '
                        }
                    }
                    return val;
                }) : cd.options.map((o, i) => {
                    let val;
                    if (i < 3) {
                        val = o;
                        if (i < 2 && (i !== cd.options.length - 1)) {
                            val += ', '
                        }
                    }
                    return val;
                })}{cd.options.length > 3 ? '...' : ''}</span></span>,
                value: cd.id,
                ...cd
            }
        }));

        if (dt && dt.unitDetails?.unitType !== 'categorical' && dt.unitDetails?.unitType !== 'boolean') {
            columns = columns.filter(c => c.containsNumeric);
        }

        if (params?.filterOutSelectedAlready) {
            columns = columns.filter(c => !this.state.dataTypes.find(_dt => _dt.valueColumns.find(vc => vc === c.id)));
            if (params?.hideAllUsed) {
                columns = columns.filter(c => !this.state.dataTypes.find(_dt => Object.values(_dt).find(v => v === c.id)));
            }
        }

        if (params?.dateOnly) {
            let dateColumns = Object.assign([], columns).filter(c => c.possibleDate);
            dateColumns.unshift({divider: true, title: 'Detected Date Column'});
            dateColumns.push({divider: true, title: 'Other Columns'});
            dateColumns = dateColumns.concat(columns.filter(c => !c.possibleDate));
            columns = dateColumns;
        } else {
            if (!params.noNone) {
                columns.unshift({
                    title: "None",
                    value: null
                });
            }
        }

        if (params?.dataOnly) {
            return columns;
        }

        return <Dropdown key={"column-date-" + id} label={label}
                         placeholder={'None'}
                         error={params?.error}
                         onChange={(e) => cb(e.target.value)}
                         fixeditems={columns} value={value}/>
    }

    validateDataItems() {
        let layoutError;
        // item_name_column
        // device_per_column
        if (this.state.dataItemsLayout === 'item_name_column') {
            layoutError = !this.state.dataItemsColumn;
        }
        this.state.dataItems.forEach(di => {
            di.errors = {};
            if (this.state.dataItemsLayout === 'item_name_column') {
                if (!di.dataTypes || !di.dataTypes.length) di.errors['dataTypes'] = true;
            }
            if (this.state.dataItemsLayout === 'device_per_column') {
                if (!di.valueColumn) di.errors['valueColumn'] = true;
            }
            if (!di.itemType) di.errors['itemType'] = true;
            if (!di.itemId) di.errors['itemId'] = true;
            if (di.newItem && di.itemId) {
                if (di.itemType === 'site') {
                    let newSite = this.state.newSites.find(s => s.id === di.itemId);
                    if (newSite) {
                        newSite.errors = {};
                        if (!newSite.name) newSite.errors['name'] = true;
                        if (!newSite.locationDetails) newSite.errors['locationDetails'] = true;
                        if (Object.values(newSite.errors).find(e => e)) {
                            di.errors['newItem'] = true;
                        }
                    }
                }
                if (di.itemType === 'device') {
                    let newDevice = this.state.newDevices.find(d => d.id === di.itemId);
                    if (newDevice) {
                        let newDeviceNewSite = {errors: {}};
                        newDevice.errors = {};
                        if (!newDevice.name) newDevice.errors['name'] = true;
                        if (!newDevice.siteId) newDevice.errors['siteId'] = true;
                        if (newDevice.newSite && newDevice.siteId) {
                            newDeviceNewSite = this.state.newSites.find(d => d.id === newDevice.siteId);
                            newDeviceNewSite.errors = {};
                            if (!newDeviceNewSite.name) newDeviceNewSite.errors['name'] = true;
                            if (!newDeviceNewSite.locationDetails) newDeviceNewSite.errors['locationDetails'] = true;
                        }

                        if (Object.values(newDevice.errors).find(e => e) || Object.values(newDeviceNewSite.errors).find(e => e)) {
                            di.errors['newItem'] = true;
                        }
                    }
                }
            }

        });
        return !this.state.dataItems.find(dt => Object.values(dt.errors).find(e => e)) && !layoutError;
    }

    validateDataTypes() {
        this.state.dataTypes.forEach(dt => {
            dt.errors = {};
            if (!dt.dataType) dt.errors['dataType'] = true;
            if (!dt.valueColumns || !dt.valueColumns.length) dt.errors['valueColumns'] = true;
            if (dt.dateMultipleColumns) {
                if (!dt.dayColumn && !dt.monthColumn && !dt.yearColumn) dt.errors['dateMultipleColumns'] = true;
            } else {
                if (!dt.dateColumn) dt.errors['dateColumn'] = true;
                let formatOptions = dt?.dateFormatOptions;
                let unitMustBeUnix = (dt.dateColumn && this.state.columnData.find(cd => cd.id === dt.dateColumn) && !this.state.columnData.find(cd => cd.id === dt.dateColumn).containsString && this.state.columnData.find(cd => cd.id === dt.dateColumn).containsNumeric);
                let unitMustBeDateString = (dt.dateColumn && this.state.columnData.find(cd => cd.id === dt.dateColumn) && !this.state.columnData.find(cd => cd.id === dt.dateColumn).containsNumeric && this.state.columnData.find(cd => cd.id === dt.dateColumn).containsString);
                if (!dt.dateFormatExample && formatOptions && formatOptions.length) dt.errors['dateFormatExample'] = true;
                if (!dt.dateFormat || !dt.dateFormat.length && unitMustBeDateString) dt.errors['dateFormat'] = true;
                if (!dt.unixFormat && unitMustBeUnix) dt.errors['unixFormat'] = true;
            }
        });
        return !this.state.dataTypes.find(dt => Object.values(dt.errors).find(e => e));
    }

    getDataTypeObject() {
        return {
            id: Math.random().toString(12).slice(2),
            dateFormat: [],
            valueColumns: [],
            selected: true,
            dataType: null,
            errors: {},
            dateMultipleColumns: false,
            dateColumn: '',
            secondColumn: '',
            secondColumnFormat: '',
            minuteColumn: '',
            minuteColumnFormat: '',
            hourColumn: '',
            hourColumnFormat: '',
            dayColumn: '',
            dayColumnFormat: '',
            monthColumn: '',
            monthColumnFormat: '',
            yearColumn: '',
            yearColumnFormat: '',
            unixFormat: '',
            multipleOfBase: 1,
            rateMultipleOfBase: 1,
            dateFormatExample: '',
            valueTotalType: 'periodic'
        }
    }

    generateDataTypesArray(dataTypeColumns) {
        if (!dataTypeColumns) {
            dataTypeColumns = [{}];
        }
        return dataTypeColumns.map(dtc => {
            return this.getDataTypeObject();
        });
    }

    unselectDataTypes() {
        this.state.dataTypes.forEach(dt => {
            dt.selected = false;
        })
    }

    selectDataType(dt) {
        this.unselectDataTypes();
        this.state.dataTypes.find(_dt => _dt.id === dt.id).selected = true;
        this.setState({dataTypes: this.state.dataTypes});
    }

    unselectDataItems() {
        this.state.dataItems.forEach(dt => {
            dt.selected = false;
        })
    }

    selectDataItem(di) {
        this.unselectDataItems();
        this.state.dataItems.find(_di => _di.id === di.id).selected = true;
        this.setState({dataItems: this.state.dataItems});
    }

    removeDataType(dt, cb) {
        this.state.dataTypes = this.state.dataTypes.filter(_dt => _dt.id !== dt.id);
        if (dt.selected) {
            this.selectDataType(this.state.dataTypes[0]);
        } else {
            this.setState({dataTypes: this.state.dataTypes}, cb)
        }
    }

    removeDataItem(di, cb) {
        this.state.dataItems = this.state.dataItems.filter(_di => _di.id !== di.id);
        if (di.selected) {
            this.selectDataItem(this.state.dataItems[0]);
        } else {
            this.setState({dataItems: this.state.dataItems}, cb)
        }
    }

    addDataType() {
        this.unselectDataTypes();
        this.state.dataTypes.push(this.getDataTypeObject());
        this.setState({
            dataTypes: this.state.dataTypes
        })
    }

    addDataItem() {
        this.unselectDataItems();
        this.state.dataItems.push(this.getDataItemObject(null, null, true, true));
        this.setState({
            dataItems: this.state.dataItems
        })
    }

    render() {
        this.checkErrors();
        // let footer =
        //     <div><Button label={'Next'}
        //                  progressRes
        //                  additionalclasses={'sm'}
        //                  onClick={this.uploadData}/></div>
        let itemType = this.state.itemType;
        let csvSubHeading;
        let csvs;
        if (this.props.item) {
            csvSubHeading = this.state.csvFileNames?.length + ' CSV' + (this.state.csvFileNames?.length > 1 ? 's' : '');
            csvs = <div className={'csv-file-names'}>{this.state.csvFileNames?.map(csv => <div
                className={'row csv-file-name'}>
                <div className={'row'}><Icon icon={'FaFile'}/> <p>{csv}</p></div>
            </div>)}</div>;
        }

        let showPreviewCard = (this.state.processingStatusDetails || this.props.item?.status === 'validated' || this.state.previewSampleData) && this.props.item?.status !== 'incomplete';
        let chooseType = this.props.item?.csvFileNames
        let filesExist = (this.state.csvData && this.state.groupName) || (this.props.item && this.props.item !== true);
        let readyToValidate = filesExist && this.validateDataTypes() && this.validateDataItems();
        let footer;
        if (readyToValidate && this.state.status !== 'validated' && this.state.dataItems?.length) {
            footer = <Button additionalclasses={'md'} progressRes label={'Confirm and Review'}
                             onClick={async () => {
                                 return new Promise(async (resolve, reject) => {
                                     // TODO Save and close all InfoCards
                                     if (this.dataTypesCardRef?.current) await this.dataTypesCardRef.current.triggerSave();
                                     if (this.dataItemsCardRef?.current) await this.dataItemsCardRef.current.triggerSave();
                                     await this.props.item.set({status: 'validateData'});
                                     return resolve();
                                 })
                             }}/>
        }
        if (this.props.item?.status === 'validated') {
            footer = <Button label={'Upload Data'}
                             onClick={() => this.props.item.set({status: 'uploadData'})}/>
        }

        return (
            <GdModal
                title={`Upload Data`}
                open={this.props.open}
                footer={footer}
                wide
                onClose={this.closeModal}>
                {this.state.tabs[0][0].selected ?
                    <CardBody>
                        <div>
                            <InfoCard noEdit buttonText={'Continue'}
                                      key={'data-csv'}
                                      show={this.state.groupName || this.props.item}
                                      onDone={async () => {
                                      }}
                                      disabled={this.state.uploadingFiles}
                                      onNext={async () => {
                                          this.setState({triedToSaveTemplate: true});
                                          if (!this.state.csvData || !this.state.groupName) return {continue: true};
                                          await this.uploadFiles(this.state.csvData);
                                          return {continue: true};
                                      }}
                                      startClosed={this.state.columnData}
                                      status={filesExist ? 'completed' : 'error'}
                                      heading={'Data CSV/s'}
                                      subheading={csvSubHeading}
                                      belowSubheading={this.props.item?.id ? csvs : null}>
                                <div className={'row'} style={{marginTop: '15px'}}>
                                    <GdAutocomplete filterSelectedOptions
                                                    async
                                                    label={'Settings Template'}
                                                    freeSolo
                                                    disabled={this.state.processing}
                                                    value={this.state.groupName}
                                                    name={'siteGroups'}
                                                    openonfocus='true'
                                                    lazyload
                                                    error={this.state.triedToSaveTemplate && !this.state.groupName}
                                                    placeholder={'Search or enter new name'}
                                                    getOptionSelected={function (option, value) {
                                                        if (option && value) return option.id === value.id;
                                                    }}
                                                    clearOnBlur
                                                    filterOptions={(options, params) => {
                                                        // Suggest the creation of a new value
                                                        if (params.inputValue !== '' && !options.find(o => params.inputValue === o.title)) {
                                                            options.unshift({
                                                                inputValue: params.inputValue,
                                                                title: params.inputValue,
                                                                type: 'new'
                                                            });
                                                        }

                                                        return options;
                                                    }}
                                                    getList={async (searchTerm) => {
                                                        // if (searchTerm && searchTerm.length) {
                                                        let filters = [{
                                                            field: 'groupByGroupName',
                                                            value: true
                                                        }];
                                                        if (searchTerm && searchTerm.length) {
                                                            filters.push({
                                                                field: 'search',
                                                                value: searchTerm
                                                            })
                                                        }
                                                        let site_gws = await GridDuck.getDataUploads({
                                                            filters: filters
                                                        });
                                                        return site_gws.list
                                                            .map(
                                                                s => ({
                                                                    id: s.groupName,
                                                                    title: s.groupName
                                                                })
                                                            )
                                                        // } else return []
                                                    }}
                                                    onChange={(ev, newValue) => {
                                                        if (newValue) {
                                                            this.setState({groupName: newValue.inputValue || newValue.title || newValue});
                                                        }
                                                    }}
                                                    nolabel/>
                                </div>
                                <p className={'info-text'}>Either select an existing template to populate this
                                    CSV layout configuration or create a new one. It can all be edited before final
                                    submission it's just to create a starting point from.</p>
                                {(!this.state.csvData?.length && !this.state.loaded && !this.state.uploadProgress && !this.state.uploadingFiles && !this.state.processedFiles) || (this.state.csvData?.length && !this.state.uploadingFiles && !this.state.processedFiles) ?
                                    <FileUploadDropper error={!this.state.csvData && this.state.triedToSaveTemplate}
                                                       downloadFiles={(csvData, error) => {
                                                           this.filesSelected(csvData);
                                                       }} onlyAllow={['text/csv']}>
                                        {(!this.state.csvData?.length && !this.state.loaded && !this.state.uploadProgress && !this.state.uploadingFiles && !this.state.processedFiles) ?

                                            <div className={'file-upload'}>
                                                <Icon icon={'FaUpload'} size={'40'} color={'gd-brand'}/>
                                                <p style={{marginTop: '10px', fontWeight: 600}}>Drop CSV/s here</p>
                                                or
                                                <br/>
                                                <br/>
                                                <Button label={[(<Input type={'file'}
                                                                        inputProps={{
                                                                            accept: 'text/csv',
                                                                            multiple: true
                                                                        }}
                                                                        onChange={(e) => {
                                                                            this.filesSelected(e.target.files);
                                                                        }}
                                                                        label={'Select File'}/>), (
                                                    <p>Select CSV/s</p>)]}/>

                                            </div> : null}
                                        {(this.state.csvData?.length && !this.state.uploadingFiles && !this.state.processedFiles) ?
                                            <div className={'csv-file-names'}>
                                                {this.state.csvData.map((csv, i) => <div key={csv.name}
                                                                                         className={'csv-file-name'}>
                                                    <div className={'row space-between'}>
                                                        <div className={'row'}><Icon
                                                            icon={'FaFile'}/> {csv.name}</div>
                                                        <Icon onIconClick={() => {
                                                            let updateArrary = [...this.state.csvData];
                                                            updateArrary.splice(i, 1);  // remove element
                                                            this.setState({
                                                                csvData: updateArrary,
                                                                hasCSVs: true,
                                                                triedToSaveTemplate: false
                                                            })
                                                        }} icon={'FaTimes'} color={'gd-red'}/>
                                                    </div>
                                                    <div className={'row'}>
                                                        <GdCheckbox checked={csv.noHeaders} name={'noHeaders'}
                                                                    onChange={(val) => {
                                                                        csv.noHeaders = val.target.checked;
                                                                        // this.setState({noHeaders: val.target.checked})
                                                                    }} label={'Has no headers'}/>
                                                    </div>
                                                </div>)}
                                            </div> : null}
                                    </FileUploadDropper> : null}
                                {(this.state.uploadingFiles && this.state.processing && !this.state.processedFiles) ?
                                    <div className={'column processing-data'}>
                                        <Loader type={'circular'}/>
                                        <p>Processing data...</p>
                                    </div> : null}

                                {(this.state.uploadProgress && this.state.uploadProgress < 100 && this.state.uploadProgress > 0 && !this.state.processedFiles) ?
                                    <div className={'column processing-data'}>
                                        <Loader type={'circular'} withprogress size={50}
                                                value={this.state.uploadProgress}/>
                                        <p>Uploading data...</p>
                                    </div> : null}
                            </InfoCard>
                            <InfoCard buttonText={this.props.item.dataTypes ? 'Save' : 'Continue'}
                                      show={chooseType}
                                      key={'data-types'}
                                      onCancel={() => {
                                          if (this.props.reloadItem && this.props.item?.id) this.props.reloadItem(this.props.item.id, () => {
                                              this.dataUploadUpdated();
                                          });
                                      }}
                                      onEdit={() => this.props.item?.set({status: 'incomplete'})}
                                      startClosed={this.props.item.dataTypes && this.props.item.dataTypes.length && this.validateDataTypes()}
                                      onDone={async () => {
                                          return new Promise((resolve, reject) => {
                                              this.setState({triedToSaveDataTypes: true}, async () => {
                                                  if (this.state.dataTypes && this.validateDataTypes()) {
                                                      await this.props.item.set({
                                                          dataTypes: this.state.dataTypes,
                                                          dataValuesColumns: this.state.dataValuesColumns,
                                                          // status: readyToValidate ? 'validateData' : 'incomplete'
                                                      });
                                                      return resolve(true);
                                                      // if (this.props.item?.id) this.props.reloadItem(this.props.item?.id);
                                                  }
                                              });
                                          });
                                      }}
                                      status={this.validateDataTypes() ? 'completed' : 'error'}
                                      heading={'Data Types'}
                                      ref={this.dataTypesCardRef}
                                      subheading={this.state.dataTypes && this.state.dataTypes.length ? (this.state.dataTypes.map(dt => dt?.dataType?.title).join(", ")) : ''}>
                                <div className={'row'} style={{marginTop: '15px'}}>
                                    <Button label={'+ Add data type'} onClick={this.addDataType}/>
                                </div>
                                {this.state.dataTypes ? <div className={'column'} style={{marginTop: '15px'}}>
                                    <label style={{paddingLeft: '5px'}}>Data Details</label>
                                    <DataTypesFromColumns dataTypes={this.state.dataTypes}
                                                          selectDataType={this.selectDataType}
                                                          removeTab={this.removeDataType}
                                                          columnsDropDown={this.columnsDropDown}
                                                          onChange={({dt, label, value, cb}) => {
                                                              this.validateDataTypes();
                                                              this.state.dataTypes = this.state.dataTypes.map(_dt => {
                                                                  if (_dt.id === dt.id) {
                                                                      _dt[label] = value;
                                                                  }
                                                                  return _dt;
                                                              })
                                                              this.setState({
                                                                  dataTypes: this.state.dataTypes
                                                              }, cb);
                                                          }}
                                                          columnData={this.props.item?.columnData}
                                                          dataValuesColumns={this.state.dataValuesColumns}
                                                          triedToSave={this.state.triedToSaveDataTypes}/>
                                </div> : null}
                            </InfoCard>
                            <InfoCard buttonText={this.props.item.dataItems ? 'Save' : 'Continue'}
                                      onCancel={this.dataUploadUpdated}
                                      key={'data-items'}
                                      onEdit={() => this.props.item?.set({status: 'incomplete'})}
                                      show={this.props.item.dataTypes && this.props.item.dataTypes.length && this.validateDataTypes()}
                                      startClosed={this.props.item.dataItems && this.props.item.dataItems.length && this.validateDataItems()}
                                      onDone={async () => {
                                          return new Promise((resolve, reject) => {
                                              this.validateDataItems();
                                              this.setState({triedToSaveDataItems: true}, async () => {
                                                  if (this.state.dataItems && this.validateDataItems()) {
                                                      await this.props.item.set({
                                                          dataItems: this.state.dataItems,
                                                          dataItemsColumn: this.state.dataItemsColumn,
                                                          dataItemsLayout: this.state.dataItemsLayout,
                                                          newSites: this.state.newSites,
                                                          newDevices: this.state.newDevices,
                                                          // status: readyToValidate ? 'validateData' : 'incomplete'
                                                      });
                                                      return resolve(true);
                                                      // if (this.props.item?.id) this.props.reloadItem(this.props.item?.id);
                                                  }
                                              })
                                          });
                                      }}
                                      status={this.validateDataItems() ? 'completed' : 'error'}
                                      ref={this.dataItemsCardRef}
                                      heading={'Items'}
                                      subheading={this.state.dataItems && this.state.dataItems.length ? (this.state.dataItems.map(di => di.name).join(", ")) : ''}>
                                <div className={'column'} style={{marginTop: '15px'}}>
                                    <label style={{paddingLeft: '15px'}}>How is the data laid out?</label>
                                    <div className={'data-upload'}>
                                        <div className={'row with-body-item'}>
                                            <GdRadio
                                                onChange={(e) => {
                                                    let dataItems = [];
                                                    if (e.target.value === 'device_per_column') {
                                                        dataItems.push(this.getDataItemObject(null, 0, true));
                                                    }
                                                    this.setState({
                                                        dataItemsLayout: e.target.value,
                                                        dataItems: dataItems,
                                                        dataItemsColumn: null
                                                    });
                                                }}
                                                value={this.state.dataItemsLayout} row options={[
                                                {
                                                    label: <div className={'column'}>
                                                        <label>Item name or ID Column</label>
                                                        <table border={'1'} className={'rows'}>
                                                            <tbody>
                                                            <tr>
                                                                <td><strong>ID</strong></td>
                                                                <td><strong>Name</strong></td>
                                                                <td><strong>Data</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>Item 1</td>
                                                                <td>100</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>Item 2</td>
                                                                <td>50</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>,
                                                    name: 'item_name_column',
                                                    value: 'item_name_column'
                                                },
                                                {
                                                    label:
                                                        <div className={'column'}>
                                                            <label>Data Column per Item</label>
                                                            <table border={'1'} className={'columns'}>
                                                                <tbody>
                                                                <tr>
                                                                    <td><strong>Item 1</strong></td>
                                                                    <td><strong>Item 2</strong></td>
                                                                    <td><strong>Item 3</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>100</td>
                                                                    <td>84</td>
                                                                    <td>140</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>212</td>
                                                                    <td>513</td>
                                                                    <td>214</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>,
                                                    name: 'device_per_column',
                                                    value: 'device_per_column'
                                                }
                                            ]}/>
                                        </div>
                                        {this.state.dataItemsLayout === 'item_name_column' ?
                                            <div className={'row'} style={{marginTop: '15px'}}>
                                                {this.columnsDropDown('Item name or ID column', this.state.dataItemsColumn, 'data_items_column', (val) => {
                                                    this.generateDataItemsFromColumn(val);
                                                    this.setState({
                                                        dataItemsColumn: val
                                                    });
                                                }, null, {
                                                    filterOutSelectedAlready: true,
                                                    hideAllUsed: true,
                                                    noNone: true,
                                                    error: !this.state.dataItemsColumn && this.state.triedToSaveDataItems
                                                })}
                                            </div> : null}
                                        {this.state.dataItemsLayout === 'device_per_column' ?
                                            <div className={'row'} style={{marginTop: '15px'}}>
                                                <Button label={'+ Add item'} onClick={this.addDataItem}/>
                                            </div> : null}
                                        {this.state.dataItems && this.state.dataItems.length ?
                                            <div className={'column'} style={{marginTop: '15px'}}>
                                                <label style={{paddingLeft: '5px'}}>Item Details</label>
                                                <DataItemTabs dataItems={this.state.dataItems}
                                                              selectDataItem={this.selectDataItem}
                                                              noEdit={this.state.dataItemsColumn}
                                                              dataItemsLayout={this.state.dataItemsLayout}
                                                              dataTypes={this.state.dataTypes}
                                                              removeTab={this.removeDataItem}
                                                              columnsDropDown={this.columnsDropDown}
                                                              dataTypeAutocomplete={this.dataTypeAutocomplete}
                                                              addNewItem={this.addNewItem}
                                                              updateNewItem={this.updateNewItem}
                                                              removeNewItem={this.removeNewItem}
                                                              newSites={this.state.newSites}
                                                              newDevices={this.state.newDevices}
                                                              onChange={({di, label, value, cb}) => {
                                                                  // this.validateDataTypes();
                                                                  this.state.dataItems = this.state.dataItems.map(_dt => {
                                                                      if (_dt.id === di.id) {
                                                                          _dt[label] = value;
                                                                      }
                                                                      return _dt;
                                                                  })
                                                                  this.setState({
                                                                      dataItems: this.state.dataItems
                                                                  }, cb);
                                                              }}
                                                              columnData={this.props.item?.columnData}
                                                              dataValuesColumns={this.state.dataValuesColumns}
                                                              triedToSave={this.state.triedToSaveDataItems}/>
                                            </div> : null}
                                    </div>
                                </div>
                            </InfoCard>
                            {showPreviewCard ? <InfoCard noButton
                                                         key={'preview-data'}
                                                         show={showPreviewCard}
                                                         status={readyToValidate ? 'completed' : 'error'}
                                                         heading={'Review and Approve'}>
                                {this.state.processingStatusDetails && this.state.processingStatusDetails.percentage < 100 ?
                                    <div className={'column processing-data'}>
                                        <Loader type={'circular'} withprogress size={50}
                                                value={this.state.processingStatusDetails.percentage}/>
                                        <p>Processing rows
                                            ({this.state.processingStatusDetails.numberProcessed}/{this.state.processingStatusDetails.totalRows})...</p>
                                        {this.state.processingStatusDetails.invalidDates ?
                                            <p>{this.state.processingStatusDetails.invalidDates} Invalid
                                                Dates</p> : null}
                                        {this.state.processingStatusDetails.invalidValues ?
                                            <p>{this.state.processingStatusDetails.invalidValues} Invalid
                                                Values</p> : null}
                                    </div> : null}
                                {(!this.state.processingStatusDetails || this.state.processingStatusDetails.percentage >= 100) && this.state.processedErrors && Object.values(this.state.processedErrors).length ?
                                    <div>
                                        <p className={'invalid-title'}>Invalid Data</p>
                                        {Object.entries(this.state.processedErrors).map((e, i) =>
                                            <ErrorPreview dataTypes={this.state.dataTypes}
                                                          key={i + 'errorPreview'}
                                                          dataItems={this.state.dataItems} errorDetails={e}/>
                                        )}
                                    </div> : null}
                                {(!this.state.processingStatusDetails || this.state.processingStatusDetails.percentage >= 100) && this.state.previewSampleData ?
                                    Object.entries(this.state.previewSampleData).map((obj) =>
                                        obj[0] && obj[1] && obj[1].length ?
                                            <PreviewGraph key={obj[0]} dataTypes={this.state.dataTypes}
                                                          dataItems={this.state.dataItems}
                                                          data={obj}/> : null) : null}
                            </InfoCard> : null}
                        </div>
                    </CardBody> : null}
                {this.state.showDataCategoryModal ?
                    <CreateDataCategory createCategoryOnly
                                        reloadData={async () => {
                                        }}
                                        onClose={(item) => {
                                            let updateObj = {
                                                showDataCategoryModal: null
                                            };
                                            if (item) {
                                                updateObj.dataCategory = {
                                                    id: item.id,
                                                    title: item.name,
                                                    icon: item.icon,
                                                    color: item.color
                                                };
                                            }
                                            this.setState(updateObj);
                                        }}
                                        open={this.state.showDataCategoryModal}/> : null}
                {this.state.showDataTypeModal ?
                    <CreateDataType categoryId={this.state.dataCategory?.id}
                                    onClose={(item) => {
                                        let updateObj = {
                                            showDataTypeModal: null
                                        };
                                        if (item) {
                                            updateObj.dataType = {
                                                id: item.id,
                                                title: item.name,
                                                icon: item.dataType === 'instantaneous' ? 'FaChartLine' : 'FaChartBar',
                                            };
                                        }

                                        this.setState(updateObj);
                                    }}
                                    open={this.state.showDataTypeModal}/> : null}
            </GdModal>
        )
    }
}

export default CreateDataUpload