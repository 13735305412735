import React, {Component} from "react";
import '../../pages/organisation/MainOrganisationPage.scss'
import '../../styles/_layout.scss';
import GridDuck from "gridduck";
import {
    Button,
    CardBody,
    Tabs,
    List,
    Icon,
    StandardListCell,
    EditableList,
    GdCheckbox,
    Dropdown,
    LastActiveCell,
    AccountAdminListCell
} from "gd-react";
import GenericLoader from "../../components/GenericLoader";
import GdModal from "../../components/GdModal/GdModal";
import NoItems from "../noItems";
import history from "../../meta/history";
import DeleteModal from "../../modals/Delete/DeleteModal";

class UserDrawer extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.onTabClick = this.onTabClick.bind(this)
        this.onCheckboxChange = this.onCheckboxChange.bind(this)
        this.saveUser = this.saveUser.bind(this)
        this.checkChanged = this.checkChanged.bind(this)
        this.sitesChanged = this.sitesChanged.bind(this)
        this.getAllSites = this.getAllSites.bind(this)
        this.changePerm = this.changePerm.bind(this)
        this.changeGeneralPerm = this.changeGeneralPerm.bind(this)
        this.errors = {};
        this.state = {
            loaded: false,
            sitesLimitedTo: [],
            orgOverridePerms: null,
            permission: null
        }
        this.modalClosed = false;
        this.tabs = [[{
            id: 1,
            title: 'Details',
            onTabClick: this.onTabClick,
            selected: (this.props.openOnTab === 1 || !this.props.openOnTab)
        }]]
        console.log(GridDuck, ' : GridDuck');
        if (this.props.item.id !== GridDuck.userId && (this.props.hasAdmin || this.props.hasOwner) && this.props.item._permission !== 'view') {
            this.tabs[0].push({
                id: 2, title: 'Access', onTabClick: this.onTabClick, selected: (this.props.openOnTab === 2)
            })
        }
        // if orgPermName < this.perm.rank, filter out this perm
        this.perms = [
            {
                id: 'owner', title: 'Owner', value: 'owner', rank: 0
            }, {
                id: 'admin', title: 'Admin', value: 'admin', rank: 1
            }, {
                id: 'edit', title: 'Edit', value: 'edit', rank: 2
            }, {
                id: 'boost', title: 'Boost / Snooze', value: 'boost', rank: 3
            }, {
                id: 'view', title: 'View', value: 'view', rank: 4
            }]
    }


    async componentDidMount() {
        console.log('user in prop', this.props.item)
        let sitesLimitedTo;
        if (this.props.item && this.props.item.sitesLimitedTo) {
            sitesLimitedTo = this.props.item.sitesLimitedTo.map((s) => {
                if (!s.userPermName) {
                    s.userPermName = s.orgPermName;
                }
                if (!s.id) {
                    s.id = s.siteId;
                }
                return s
            });
        }
        this.setState({
            sitesLimitedTo: sitesLimitedTo || [],
            orgOverridePerms: this.props.item.orgOverridePerms,
            permission: this.props.item.orgPermission,
            loaded: true,
        })
    }

    onTabClick(ev, tab) {
        this.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    checkChanged() {
        let hasChanged = false;
        if ((this.state.orgOverridePerms !== this.props.item.orgOverridePerms) || this.state.sitesChanged || (this.state.permission !== this.props.item.orgPermission)) {
            hasChanged = true
        }
        this.hasChanged = hasChanged
    }

    async saveUser() {
        let orgMember = this.props.item;
        let memberObj = {
            orgOverridePerms: this.state.orgOverridePerms,
            sitesLimitedTo: this.state.sitesLimitedTo,
            orgPermission: this.state.permission
        }
        return await orgMember.set(memberObj).then(() => {
            window.setTimeout(() => {
                this.setState({sitesChanged: false});
            }, 500);
        });
    }

    closeModal() {
        this.setState({
            loaded: false,
            sitesLimitedTo: [],
            orgOverridePerms: null,
            permission: null
        })
        this.modalClosed = true;
        this.props.onClose();
    }

    async getAllSites(params) {
        if (!params.filters) params.filters = [];
        let sitesRes = await GridDuck.getSites(params);
        let sites = sitesRes.list.map((s) => {
            return {
                siteId: s.id,
                id: s.id,
                name: s.name,
                orgPermName: s._permission,
                userPermName: s._permission,
                orgPermRank: this.perms.find((p) => p.id === s._permission).rank,
            }
        })
        let res = {
            list: sites
        }
        return Promise.resolve(res)
    }

    async getUserSites(params) {
        let self = this

        if (!this.props.item || !this.props.item.id || !this.props.item.orgOverridePerms) return Promise.resolve({
            list: [], total: 0
        })

        if (!this.state.sitesLimitedTo.length) {

            let filters = [{
                "field": "id", "value": this.props.item.id
            }]

            let userRes = await GridDuck.getOrganisationMembers({filters: filters});

            let list = userRes.list[0].sitesLimitedTo;
            self.setState({sitesLimitedTo: list});
            return Promise.resolve({list: list, total: list.length});
        } else return Promise.resolve({list: self.state.sitesLimitedTo, total: self.state.sitesLimitedTo.length});

    }

    sitesChanged(newSitesList) {
        this.setState({sitesLimitedTo: newSitesList, formUpdated: true, sitesChanged: true})
    }

    onCheckboxChange(val) {
        this.state[val.target.name] = val.target.checked
        this.setState(this.state);
    }

    changePerm(val, item) {
        let element = this.state.sitesLimitedTo.find((s) => s.siteId === item.siteId)
        element.userPermName = val.target.value;
        this.state.sitesChanged = true;
        this.setState(this.state)
    }

    changeGeneralPerm(val) {
        this.setState({permission: val.target.value})
    }

    render() {
        this.checkChanged()
        let item = this.props.item;
        let notAllowedToChangePerms = ((!this.props.hasAdmin && !this.props.hasOwner) || GridDuck.userId === item.id) || item._permission === 'view';
        let self = this;
        console.log(item, ' : item user');
        let footer;
        if (this.tabs[0][1] && this.tabs[0][1].selected && this.hasChanged) {
            footer = <Button additionalclasses={'md'}
                             progressRes onClick={this.saveUser}
                             color={'gd-brand'}
                             label={'Save'}/>;
        }
        let imageStyle = {
            backgroundImage: 'url(' + item.profilePictureThumbnail + ')'
        }
        let matches = item.name.match(/\b(\w)/g);
        let acronym = matches.join('');
        let profileImage = <div
            className={'profile-image'}
            style={imageStyle} onClick={() => this.setState({showProfilePicModal: true})}>
            {!item.profilePictureThumbnail ? acronym : ''}
        </div>
        return (<GdModal
            title={item.name}
            footer={footer}
            open={this.props.open}
            wide={this.tabs[0][1] && this.tabs[0][1].selected && !this.state.hideIgnored}
            contentLabel={'user'}
            onClose={this.closeModal}>
            <CardBody additionalclasses={'no-padding rule'}>
                {this.state.loaded ? <div className={'user-drawer inner-rule'}>
                    <div className={"details"}>
                        <div className={'details-inner'} style={{overflow: 'hidden'}}>
                            <div className={'inner-header no-padding'}>
                                <Tabs tabData={this.tabs}/>
                            </div>
                            {this.tabs[0][0] && this.tabs[0][0].selected ? <div className={'column user-wrapper'}>
                                {profileImage}
                                <div className={'user-details'}>
                                    <p className={'header'}>{item.name}</p>
                                    <p className={'subHeader'}>{item.jobRole}</p>
                                    <p className={'subHeader row'}
                                       style={{marginTop: '10px'}}>{!item.currentlyConnected ?
                                        <span style={{marginRight: '10px'}}>Last active:</span> : ''} <LastActiveCell
                                        value={item.lastActive} comparisonOnly onlineStatus object={item}/></p>
                                </div>
                                <div className={'user-details'}>
                                    <p className={'subHeader with-icon'} style={{fontWeight: '600'}}><Icon
                                        icon={'MdEmail'}/><a
                                        href={'mailto:' + item.username}>{item.username}</a></p>
                                    <div className={'subHeader with-icon'} style={{fontWeight: '400'}}><Icon
                                        icon={'FaKey'}/>{item.mfa_enabled ? 'MFA Enabled' : 'MFA Not Enabled'}</div>
                                    <div className={'subHeader with-icon'}><Icon size={'8px'} icon={'FaLock'}/><span
                                        style={{fontWeight: '600'}}><a
                                        className={notAllowedToChangePerms ? 'no-click' : ''}
                                        onClick={() => this.onTabClick(null, {id: 2})}>{item.orgPermission}</a></span> level
                                        organisation
                                        access
                                    </div>
                                    {!item.orgOverridePerms ? <div className={'subHeader with-icon'}><Icon size={'8px'}
                                                                                                           icon={'FaBuilding'}/><span
                                            style={{fontWeight: '600'}}><a
                                            className={notAllowedToChangePerms ? 'no-click' : ''}
                                            onClick={() => this.onTabClick(null, {id: 2})}>{item.orgPermission}</a></span> level
                                            access
                                            to all
                                            sites</div> :
                                        <div className={'subHeader with-icon'}><Icon size={'8px'}
                                                                                     icon={'FaBuilding'}/>site access
                                            limited to <span
                                                style={{fontWeight: '600'}}><a
                                                className={notAllowedToChangePerms ? 'no-click' : ''}
                                                onClick={() => this.onTabClick(null, {id: 2})}>selected sites</a></span>
                                        </div>}
                                </div>
                                {(this.props.hasOwner && item._permission !== 'view') ? <div className={'user-details'} style={{display: 'flex'}}>
                                    {/*<AccountAdminListCell myId={GridDuck.userId} hasOwner={this.props.hasOwner} hasAdmin={this.props.hasAdmin} object={item}/>*/}
                                    <div style={{marginRight: '10px'}}>
                                        <Button onClick={() => item.createLogout()} color={'gd-grey'}
                                                label={<div><Icon icon={'FiLogOut'}/>Logout User</div>}/>
                                    </div>
                                    <Button onClick={() => this.setState({showDeleteModal: true})} color={'gd-red'}
                                            label={<div><Icon icon={'AiFillDelete'}/>Delete User</div>}/>
                                </div> : null}
                            </div> : null}
                            {this.tabs[0][1] && this.tabs[0][1].selected && !this.state.hideIgnored ?
                                <div className={'user-list-wrapper'}>
                                    <div className={'permission-row'} style={{marginTop: '10px'}}>
                                        <Dropdown onChange={(val) => self.changeGeneralPerm(val)}
                                                  value={this.state.permission}
                                                  label={'Organisation Permission Level'}
                                                  fixeditems={self.perms}/>

                                    </div>
                                    <div className={'override-row'}>
                                        <GdCheckbox checked={this.state.orgOverridePerms}
                                                    name={'orgOverridePerms'}
                                                    onChange={this.onCheckboxChange}
                                                    label={'Limit Access to Only Selected Sites'}/>

                                    </div>
                                    {this.state.orgOverridePerms ? <EditableList
                                        key={1}
                                        noCancel
                                        activeRowHeight={'60px'}
                                        noSave
                                        isEditing
                                        activeColumns={[{
                                            component: StandardListCell,
                                            maxWidth: '200px',
                                            title: 'Name',
                                            custom: function (item) {
                                                return <div>
                                                    <p className={'name'}>{item.name}</p>
                                                </div>
                                            }
                                        }, {
                                            component: StandardListCell,
                                            maxWidth: 'none',
                                            title: 'Access',
                                            custom: function (item) {
                                                return <div>
                                                    <Dropdown onChange={(val) => self.changePerm(val, item)}
                                                              value={item.userPermName}

                                                              fixeditems={self.perms.filter((p) => !(p.rank < item.orgPermRank))}/>
                                                </div>
                                            }
                                        },]}
                                        getActiveList={(params) => this.getUserSites({})}
                                        getAvailableList={this.getAllSites}
                                        onChange={this.sitesChanged}
                                    /> : null}
                                </div> : null}
                        </div>
                    </div>
                    <DeleteModal
                        itemType={'user'}
                        item={item}
                        open={this.state.showDeleteModal}
                        onClose={() => this.setState({showDeleteModal: false})}/>
                </div> : <GenericLoader/>}
            </CardBody>
        </GdModal>)
    }
}


export default UserDrawer;