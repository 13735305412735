import React from 'react';
import GridDuck from 'gridduck';
import {DemandListCell, List, StandardListCell, StateListCell, DummyListCell, SensorDataListCell} from "gd-react";
import history from "../../meta/history";
import AddGroupModal from "../../modals/AddGroup/AddGroupModal";
import NoItems from "../../components/noItems";
import AddHubModal from "../../modals/AddHub/AddHubModal";
import SiteAssetListPage from "./SiteAssetListPage";
import AddManualDeviceModal from "../../modals/AddDevice/AddManualDeviceModal";
import './MobileSiteAssetListPage.scss';

class MobileSiteAssetListPage extends SiteAssetListPage {
    render() {
        let assets;
        let filters;

        console.log(this.props, ' : site asset list props');
        if (this.props.filterType === 'site_id') {
            filters = [
                {
                    field: 'siteId',
                    value: this.props.item.id
                },
                {
                    field: 'locationId',
                    value: this.props.item.locationId
                },
                {
                    field: 'parentAsset',
                    value: null
                },
            ];
        } else if (this.props.filterType === 'site_group_id') {
            filters = [
                {
                    field: 'siteGroupId',
                    value: this.props.item.id
                }
            ];
            console.log('Site group filters');
        } else if (this.props.filterType === 'org_partner_id') {
            filters = [
                {
                    field: 'orgPartnerId',
                    value: this.props.item.id
                }
            ];
            console.log('Site group filters');
        } else if (this.props.filterType === 'asset_id' && this.props.type === 'hub') {
            console.log('Apply gateway id filter: ', this.props.item.id);
            filters = [
                {
                    field: 'gatewayId',
                    value: this.props.item.id
                }
            ]
        }
        let noDevicePage;
        if (this.props.hubs && (!this.props.hubs.list || !this.props.hubs.list.length)) {
            noDevicePage =
                <NoItems text={'"' + this.props.item.name + '" has no Hubs'} createtext={'Add Hub'} modal={AddHubModal}
                         disabled={this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'owner'}/>
        }
        // if (this.props.hubs && (this.props.hubs.list.length && (!this.props.dataItems.list || !this.props.dataItems.list.length))) {
        //     noDevicePage = <NoItems text={'"' + this.props.item.name + '" has no Devices'} createtext={'Add Device'}
        //                             modal={AddManualDeviceModal}
        //                             disabled={this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'owner'}/>
        // }
        if (this.props.item) {
            assets = (
                <List
                    noitemelem={noDevicePage}
                    itemsPerPage={25}
                    columns={[
                        {component: StandardListCell, bold: true, sortable: true, title: 'Name', field: 'name'},
                        {
                            component: StateListCell,
                            disableRightClick: true,
                            sortable: false,
                            title: 'Status',
                            field: 'currentState',
                            compact: true,
                            showLabel: true,
                            permission: this.state.orgPermission,
                            minWidth: '90px',
                            maxWidth: '300px'
                        },
                        {component: SensorDataListCell, title: 'Data', field: 'temperature', maxWidth: 'none'},
                    ]}
                    rowOnClick={this.goToAsset}
                    getlist={this.getList}
                    childrenField={"children"}
                    filters={filters}
                />
            )
        }
        return (
            <div className='mobile-site-group-sites'>
                {assets}
            </div>
        );
    }
}

export default MobileSiteAssetListPage;
