import React from "react";
import "./Reports.scss";
import getFormat from "../../services/formatter";
import {Icon, Tooltip} from "gd-react";

class PdfListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            updated: 1
        }
    }

    convertFileNameToTitle(fn) {
        console.log(fn, " : fn")
        return fn.replaceAll('_', ' ').replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        ).replaceAll('.pdf', '');
    }

    render() {
        let self = this;
        let selected = self.props.selectedPdfToShow && (self.props.selectedPdfToShow.id === this.props.pdf.id);
        return (
            <div
                key={this.props.pdf.id + 'pdf2'}
                className={'report-list-item with-icons ' + (selected ? 'selected' : '')}
                onClick={()=>this.props.onClick(this.props.pdf)}>
                <p>{self.convertFileNameToTitle(this.props.pdf.type !== 'device' ? this.props.pdf.type : this.props.pdf.name)}</p>
                <div className={'list-row-icons'}>
                    <Tooltip label={'Share PDF'} position={'bottom'}>
                        <div className={'left-arrow thin'}
                             onClick={()=>this.props.sharePdf(this.props.pdf)}>
                            <Icon color={'grey'} size={'15'} icon={'FaShare'}/>
                        </div>
                    </Tooltip>
                    <Tooltip label={'Download PDF'} position={'bottom'}>
                        <div className={'left-arrow thin'}
                             onClick={()=>this.props.downloadPdf(this.props.pdf)}>
                            <Icon color={'grey'} size={'15'} icon={'FaDownload'}/>
                        </div>
                    </Tooltip>
                </div>
            </div>
        )
    }
}

export default PdfListItem;