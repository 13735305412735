import React, {Component} from "react";
import {GdCheckbox, Button, CardBody, Input} from "gd-react";
import './AddApplicationModal.scss'
import '../../styles/_layout.scss'
import GridDuck from 'gridduck';
import GdModal from "../../components/GdModal/GdModal";

class AddApplicationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scopes: [],
            allowedGrants: ["client_credentials"]
        }
        this.create = this.create.bind(this);
        this.updateScope = this.updateScope.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    create() {
        console.log("Create application", this.state);
        return GridDuck.createApplication({
            clientName: this.state.name,
            scopes: this.state.scopes,
            allowedGrants: this.state.allowedGrants
        });
    }

    updateScope(e) {
        let scope = e.target.name;
        let checked = e.target.checked;
        let scopes = this.state.scopes;
        if (checked && !~scopes.indexOf(scope)) {
            scopes.push(scope);
            this.setState({scopes: scopes});
        }
        if (!checked && ~scopes.indexOf(scope)) {
            scopes.splice(scopes.indexOf(scope), 1);
            this.setState({scopes: scopes});
        }
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    render() {
        const availableScopes = GridDuck.getPublicAvailableScopes().filter(s => s !== 'god');
        const checkboxes = availableScopes.map(a => (
            <GdCheckbox id={a} label={a} onChange={this.updateScope} name={a} checked={~this.state.scopes.indexOf(a)}/>
        ))
        return (
            <GdModal
                open={this.props.open}
                contentLabel={"Create Application"}
                title={"Create Application"}
                footer={
                    <Button additionalclasses={'md'} label={'Create Application'} onClick={this.create}
                            color={'gd-green'}
                            disabled={!this.state.name} progressRes onComplete={this.closeModal}/>
                }
                onClose={this.closeModal}>
                <CardBody>

                    <div className={"row"}>
                        <Input top label={"Application name*"} placeholder={"Application name"}
                               onChange={e => this.setState({name: e.target.value})} value={this.state.name}/>
                    </div>

                    <div className={"row"}>
                        <p className={'fakeLabel'}>Select authorised scopes</p>
                    </div>

                    <div>
                        {checkboxes}
                    </div>

                    <div className={"row"}>
                        <p className={'fakeLabel'}>Select permitted OAuth grant flows</p>
                    </div>

                    <div>
                        <GdCheckbox label={"client_credentials"} checked={true} disabled/>
                    </div>

                </CardBody>
            </GdModal>
        )
    }
}

export default AddApplicationModal;
