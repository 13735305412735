import moment from "moment";
import {
    Icon
} from "gd-react";

const formatDemand = function (input) {
    let isNegative = false;
    if (input < 0) {
        isNegative = true;
        input = -1 * input;
    }
    if (input >= 1000) {
        let i = -1;
        let powerUnits = ['kW', 'MW', 'GW', 'TW', 'PW', 'EW', 'ZW', 'YW'];
        do {
            input = input / 1000;
            i++;
        } while (input > 1000);

        return (isNegative ? '-' : '') + Math.max(input, 0.01).toFixed(2) + powerUnits[i];
    } else {
        return (isNegative ? '-' : '') + input + 'W';
    }
};

const formatCurrent = function (input) {
    if (input >= 1000) {
        let i = -1;
        let powerUnits = ['A', 'kA', 'MA', 'GA', 'TA', 'PA', 'EA', 'ZA', 'YA'];
        do {
            input = input / 1000;
            i++;
        } while (input > 1000);

        return Math.max(input, 0.01).toFixed(2) + powerUnits[i];
    } else {
        return input + 'mA';
    }
};

const formatCurrency = function (currency) {
    console.log(currency, ' : currency');
    return new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: currency,
    });
}

const hexToRgba = function (hex, a, asString) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    let rgba = {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: a
    };
    if (asString) {
        return 'rgba(' + rgba.r + ',' + rgba.g + ',' + rgba.b + ',' + rgba.a + ')'
    } else return rgba;
}

const formatGas = function (input) {
    if (!input) {
        return "0mᶟ/h";
    }
    if (Math.abs(input) >= 1000) {
        var i = -1;
        var powerUnits = ['mᶟ/h', 'kmᶟ/h', 'Mmᶟ/h', 'Gmᶟ/h', 'Tmᶟ/h', 'Pmᶟ/h', 'Emᶟ/h', 'Zmᶟ/h', 'Ymᶟ/h'];
        do {
            input = input / 1000;
            i++;
        } while (Math.abs(input) > 1000);

        return input.toFixed(2) + powerUnits[i];
    } else {
        return (input / 1000).toFixed(2) + 'mᶟ/h';
    }
};

const formatWater = function (input) {
    if (!input) {
        return "0mᶟ/h";
    }
    if (Math.abs(input) >= 1000) {
        var i = -1;
        var powerUnits = ['mᶟ/h', 'kmᶟ/h', 'Mmᶟ/h', 'Gmᶟ/h', 'Tmᶟ/h', 'Pmᶟ/h', 'Emᶟ/h', 'Zmᶟ/h', 'Ymᶟ/h'];
        do {
            input = input / 1000;
            i++;
        } while (Math.abs(input) > 1000);

        return input.toFixed(2) + powerUnits[i];
    } else {
        return (input / 1000).toFixed(2) + 'mᶟ/h';
    }
};

const formatConsumption = function (input) {
    if (input >= 1000) {
        let i = -1;
        let powerUnits = ['kWh', 'MWh', 'GWh', 'TWh', 'PWh', 'EWh', 'ZWh', 'YWh'];
        do {
            input = input / 1000;
            i++;
        } while (input > 1000);

        return Math.max(input, 0.01).toFixed(2) + powerUnits[i];
    } else {
        return input + 'Wh';
    }
};

const numberWithCommas = function (x) {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
}

const formatTemperature = function (input, noUnit) {
    return input / 100 + (noUnit ? "" : "°C");
}

const formatHumidity = function (input, noUnit) {
    return input / 100 + (noUnit ? "" : "%");
}

const formatVoc = function (input, noUnit) {
    return input + (noUnit ? "" : " IAQ");
}

const formatLight = function (input) {
    return input + " Lux";
}

const formatOccupancy = function (input) {
    if (input) {
        return "Occupied";
    } else {
        return "Unoccupied";
    }
}

const formatOpen = function (input) {
    if (input) {
        return "Open";
    } else {
        return "Closed";
    }
}

//Zigbee
const formatSignal = function (input) {
    let summary = "N/A";
    if (input <= -100) {
        return `Terrible (${input}dBm)`;
    } else if (input <= -90) {
        return `Bad (${input}dBm)`;
    } else if (input <= -75) {
        return `Okay (${input}dBm)`;
    } else {
        return `Excellent (${input}dBm)`;
    }
}

const formatSignalNBIoT = function (input) {
    let summary = "N/A";
    if (input <= -110) {
        return `Terrible (${input}dBm)`;
    } else if (input <= -100) {
        return `Bad (${input}dBm)`;
    } else if (input <= -85) {
        return `Okay (${input}dBm)`;
    } else {
        return `Excellent (${input}dBm)`;
    }
}

const formatSignalMobile = function (input) {
    let summary = "N/A";
    if (input <= -100) {
        return `Terrible (${input}dBm)`;
    } else if (input <= -95) {
        return `Bad (${input}dBm)`;
    } else if (input <= -75) {
        return `Okay (${input}dBm)`;
    } else {
        return `Excellent (${input}dBm)`;
    }
}

const formatSignalBluetooth = function (input) {
    let summary = "N/A";
    if (input <= -90) {
        return `Terrible (${input}dBm)`;
    } else if (input <= -80) {
        return `Bad (${input}dBm)`;
    } else if (input <= -60) {
        return `Okay (${input}dBm)`;
    } else {
        return `Excellent (${input}dBm)`;
    }
}

const formatSignalEnocean = function (input) {
    let summary = "N/A";
    if (input <= -95) {
        return `Terrible (${input}dBm)`;
    } else if (input <= -85) {
        return `Bad (${input}dBm)`;
    } else if (input <= -70) {
        return `Okay (${input}dBm)`;
    } else {
        return `Excellent (${input}dBm)`;
    }
}

const formatSignalWiFi = function (input) {
    let summary = "N/A";
    if (input <= -85) {
        return `Terrible (${input}dBm)`;
    } else if (input <= -75) {
        return `Bad (${input}dBm)`;
    } else if (input <= -65) {
        return `Okay (${input}dBm)`;
    } else {
        return `Excellent (${input}dBm)`;
    }
}

const formatSignalSummary = function (input) {
    let summary = "N/A";
    if (input <= -90) {
        summary = 'Terrible';
    } else if (input <= -80) {
        summary = 'Bad';
    } else if (input <= -65) {
        summary = 'Okay';
    } else {
        summary = 'Excellent';
    }
    return summary;
}

const format = function (input) {
    return input;
}

const formatBatteryPercent = function (input) {
    if (!input) {
        input = 0;
    }
    return Math.round(input) + "%";
}

const formatPercent = function (input) {
    if (!input) {
        input = 0;
    }
    return Math.round(input) + "%";
}

const formatBatteryVoltage = function (input) {
    return input / 10 + "V";
}

const formatBatteryAlarm = function (input) {
    if (input) {
        return "Yes";
    } else {
        return "No";
    }
}



const formatLastContacted = function (input) {
    if (!input) {
        return "Never Contacted";
    } else {
        return moment.unix(input).local().format('DD-MMM-YYYY HH:mm:ss') + " (" + moment.unix(input).local().fromNow(true) + " ago)";
    }
}

const formatDate = function (input) {
    if (!input) {
        return "N/A";
    } else {
        return moment.unix(input).local().format('DD-MMM-YYYY HH:mm:ss');
    }
}

const formatDateUtc = function (input) {
    if (!input) {
        return "N/A";
    } else {
        return moment(input * 1000).utc().format('DD-MMM-YYYY HH:mm:ss');
    }
}

const formatLastContactedMobile = function (input) {
    if (!input) {
        return "Never Contacted";
    } else {
        return moment.unix(input).local().format('DD-MMM-YYYY HH:mm:ss');
    }
}

const formatFileSize = function (input) {
    let i = -1;
    let byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        input = input / 1024;
        i++;
    } while (input > 1024);

    return Math.max(input, 0.1).toFixed(1) + byteUnits[i];
};

const ordinalSuffixOf = function (i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

const titleCase = function (i) {
    return i.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

const weekDay = function (i) {
    switch (i) {
        case 1:
            return 'Monday'
            break;
        case 2:
            return 'Tuesday'
            break;
        case 3:
            return 'Wednesday'
            break;
        case 4:
            return 'Thursday'
            break;
        case 5:
            return 'Friday'
            break;
        case 6:
            return 'Saturday'
            break;
        case 7:
            return 'Sunday'
            break;
    }

}

const generateTitle = function (type) {
    if (type === 'total') return 'Total Usage';
    if (type === 'average' || type.indexOf('Avg') !== -1) return 'Average Usage';
    if (type === 'max') return 'Max Usage';
    if (type === 'min') return 'Min Usage';
    if (type === 'totalDemand') return 'Total Demand';
    if (type === 'change') return 'Change';
    if (type === 'temperatureAvg') return 'Average Temperature';
    if (type === 'temperatureMax') return 'Max Temperature';
    if (type === 'temperatureMin') return 'Min Temperature';
    if (type === 'humidityAvg') return 'Average Humidity';
    if (type === 'humidityMax') return 'Max Humidity';
    if (type === 'humidityMin') return 'Min Humidity';
    if (type === 'lightAvg') return 'Average Light';
    if (type === 'lightMax') return 'Max Light';
    if (type === 'lightMin') return 'Min Light';
    if (type === 'consumptionAvg') return 'Average Demand';
    if (type === 'consumptionMax') return 'Max Demand';
    if (type === 'consumptionMin') return 'Min Demand';
    if (type === 'currentAvg') return 'Average Current';
    if (type === 'currentMax') return 'Max Current';
    if (type === 'currentMin') return 'Min Current';
    if (type === 'asset_signal_strengthAvg') return 'Average Signal Strength';
    if (type === 'asset_signal_strengthMax') return 'Max Signal Strength';
    if (type === 'asset_signal_strengthMin') return 'Min Signal Strength';
    if (type === 'asset_battery_voltageAvg') return 'Average Battery Voltage';
    if (type === 'asset_battery_voltageMax') return 'Max Battery Voltage';
    if (type === 'asset_battery_voltageMin') return 'Min Battery Voltage';
    if (type === 'gateway_wifi_strengthAvg') return 'Average WiFi Signal Strength';
    if (type === 'gateway_wifi_strengthMax') return 'Max WiFi Signal Strength';
    if (type === 'gateway_wifi_strengthMin') return 'Min WiFi Signal Strength';
    if (type === 'spend') return 'Spend';
}

const getFormat = function (dataType, isMobile, connectionType) {
    switch (dataType) {
        case 'delivered':
            return formatConsumption;
        case 'signal':
            switch(connectionType) {
                case 'NB-IoT':
                    return formatSignalNBIoT;
                case 'Bluetooth':
                    return formatSignalBluetooth;
                case 'Pressac':
                    return formatSignalEnocean;
                case 'Wifi':
                    return formatSignalWiFi;
                case 'Mobile':
                    return formatSignalMobile;
            }
            //default Zigbee
            return formatSignal;
        case 'assetSignalStrength':
            return formatSignal;
        case 'assetSignalStrengthSummary':
            return formatSignalSummary;
        case 'consumption':
            return formatDemand;
        case 'current':
            return formatCurrent;
        case 'consumptionPulse':
            return formatDemand;
        case 'gasPulse':
            return formatGas;
        case 'gas':
            return formatGas;
        case 'waterPulse':
            return formatWater;
        case 'water':
            return formatWater;
        case 'waterPulseHot':
            return formatWater;
        case 'temperature':
            return formatTemperature;
        case 'titleCase':
            return titleCase;
        case 'humidity':
            return formatHumidity;
        case 'voc':
            return formatVoc;
        case 'open':
            return formatOpen;
        case 'light':
            return formatLight;
        case 'occupancy':
            return formatOccupancy;
        case 'lastContacted':
            if (isMobile) {
                return formatLastContactedMobile;
            } else {
                return formatLastContacted;
            }
        case 'date':
            return formatDate;
        case 'dateUtc':
            return formatDateUtc;
        case 'batteryPercent':
            return formatBatteryPercent;
        case 'mobileSignalStrength':
            return formatSignalMobile;
        case 'batteryAlarm':
            return formatBatteryAlarm;
        case 'batteryVoltage':
            return formatBatteryVoltage;
        case 'fileSize':
            return formatFileSize;
        case 'assetBatteryVoltage':
            return formatBatteryVoltage;
        case 'assetBatteryPercent':
            return formatBatteryPercent;
        case 'assetBatteryAlarm':
            return formatBatteryAlarm;
        case 'ordinalSuffix':
            return ordinalSuffixOf;
        case 'weekDay':
            return weekDay;
        case 'comma':
            return numberWithCommas;
        case 'hexToRGB':
            return hexToRgba;
        case 'kpiTitle':
            return generateTitle;
        case 'currency':
            return formatCurrency;
        case 'percentage':
            return formatPercent;
        default:
            return format;
    }
}

export default getFormat;
