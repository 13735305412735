import React from 'react';
import './MainOrganisationPage.scss';
import {
	Card,
	CardBody,
	CardHeader,
	Button,
} from "gd-react";

class OrganisationSecurity extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.disableMFAEnforcement = this.disableMFAEnforcement.bind(this);
		this.enableMFAEnforcement = this.enableMFAEnforcement.bind(this);
	}


	enableMFAEnforcement() {
		return this.props.org.setMfaEnforced(true);
	}

	disableMFAEnforcement() {
		return this.props.org.setMfaEnforced(false);
	}


	render() {
		let mfaContent = [];
		if (this.props.item) {
			if (this.props.org.mfaEnforced) {
				mfaContent = [
					(<p key={'p1'} className={'notice'}>Multi Factor Authentication is currently enforced for this organisation</p>),
					(<Button key={'button1'} label={'Disable MFA Enforcement'} onClick={() => this.disableMFAEnforcement()} progressRes={true}/>)
				]
			} else {
				mfaContent = [
					(<p key={'p2'} className={'notice'}>Multi Factor Authentication is not currently enforced for this organisation</p>),
					(<Button key={'button2'} additionalclasses={'md'} label={'Enable MFA Enforcement'} onClick={() => this.enableMFAEnforcement()} progressRes={true}/>)
				]
			}
		}
		return (
			<div className='page org-security'>
				<Card>
					<CardHeader title={'Multi Factor Authentication'}/>
					<CardBody>
						{mfaContent}
					</CardBody>
				</Card>
			</div>
		);
	}
}

export default OrganisationSecurity;
