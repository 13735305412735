import React from "react";
import GridDuck from "gridduck";
import TabbedPage from "../../components/TabbedPage/TabbedPage";
import LoginAs from './LoginAs'
import ManageHubs from "./ManageHubs";
import GlobalInvites from "./GlobalInvites";
import ReportsTest from "./ReportsTest";
import DevOps from "./DevOps";
import DuckCharge from "./DuckCharge";
import OrganisationManagement from "./OrganisationManagement";
import MainProductListPage from "../product/MainProductListPage";
import DesktopCreateOrganisation from "../DesktopCreateOrganisation";
import HubFirmware from "./HubFirmware";
import Fleet from "./Fleet";
import TestIcons from "./TestIcons";
import CloneSite from './CloneSite';
import Devices from "./Devices";
import RemoveData from "./RemoveData";
import AggregateData from "./AggregateData";
import ExistingResets from "./ExistingResets";
import ScanReset from "./ScanReset";
import TelemetryOperationsLog from "./TelemetryOperationsLog";
import DownloadLog from "./DownloadLog";
import CreateReset from "./CreateReset";
import CloneOrganisation from "./CloneOrganisation";

class AdminConsole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId}).then(function (user) {
            self.setState({user: user});
        });
    }

    render() {
        let tabs = [
            {
                default: true,
                icon: 'FaUserFriends',
                url: 'org-management',
                title: 'Organisation Management',
                tabs: [
                    {
                        url: 'existing',
                        icon: 'FaUserAstronaut',
                        title: 'Existing Organisations',
                        component: OrganisationManagement,
                        default: true
                    },{
                        url: 'loginAs',
                        icon: 'FaArrowAltCircleRight',
                        title: 'Login As User',
                        component: LoginAs
                    },
                    {
                        url: 'create-org',
                        title: 'Create Organisation',
                        icon: 'FaBriefcaseMedical',
                        component: DesktopCreateOrganisation
                    },
                    {
                        url: 'clone-org',
                        title: 'Clone Organisation',
                        icon: 'FaBriefcaseMedical',
                        component: CloneOrganisation
                    },
                    {
                        url: 'invites',
                        icon: 'FaPaperPlane',
                        title: 'Invites',
                        component: GlobalInvites
                    },
                    {
                        url: 'search-icons',
                        icon: 'FaSearch',
                        title: 'Search Icons',
                        component: TestIcons
                    }
                ]
            },{
                url: 'hardware',
                title: 'Hardware Fleet',
                icon: 'FaScrewdriver',
                tabs: [
                    {
                        url: 'hubs',
                        title: 'Hubs',
                        component: Fleet,
                        default: true,
                    },
                    {
                        url: 'devices',
                        title: 'Devices',
                        component: Devices,
                        default: true,
                    },
                    {
                        url: 'ownership',
                        title: 'Manage Hub Ownership',
                        component: ManageHubs,
                        icon: 'IoIosCheckbox'
                    },
                    {
                        url: 'firmware',
                        title: 'Hub Firmware',
                        component: HubFirmware,
                        icon: 'FaMicrochip'
                    }
                ],
            },
            // {
            //     url: 'create-order',
            //     title: 'Create Order',
            //     component: MainProductListPage
            // },
            {
                url: 'test-reports',
                title: 'Test Reports',
                icon: 'FaChartArea',
                component: ReportsTest
            },
            {
                url: 'clone-site',
                title: 'Clone Site',
                icon: 'FaClone',
                component: CloneSite
            },
            {
                url: 'dev-ops',
                title: 'Dev Ops',
                icon: 'FaUserCog',
                component: DevOps
            },
            {
                url: 'admin-telemetry',
                title: 'Telemetry Management',
                icon: 'AiOutlineFieldBinary',
                tabs: [
                    {
                        url: 'operations',
                        title: 'Telemetry Operations Log',
                        icon: 'FaListOl',
                        component: TelemetryOperationsLog,
                        default: true
                        //a log operations on telemetry with status, date/time etc and result
                    },
                    {
                        title: 'Telemetry Download & Invoice Log',
                        icon: 'FaFileDownload',
                        url: 'telemetry-download-log',
                        component: DownloadLog
                        //show everything from the data-request table
                    },
                    {
                        url: 'remove-telemetry',
                        title: 'Remove Telemetry',
                        icon: 'FaFileExcel',
                        component: RemoveData,
                        //create a remove telemetry operation
                    },
                    {
                        title: 'Aggregate Telemetry Groups',
                        url: 'aggregate-telemetry',
                        icon: 'GoFileBinary',
                        component: AggregateData,
                        //run a manual aggregate telemetry operation
                    },
                    {
                        title: 'Telemetry Resets',
                        icon: 'FaSyncAlt',
                        url: 'telemetry-resets',
                        //show and create data resets
                        tabs: [
                            {
                                title: 'Scan',
                                url: 'scan',
                                component: ScanReset,
                                default: true
                            },
                            {
                                title: 'Existing',
                                url: 'existing',
                                component: ExistingResets,
                            },
                            {
                                title: 'Create New',
                                url: 'create',
                                icon: 'MdCreateNewFolder',
                                component: CreateReset,
                            }
                        ]
                    },
                    {
                        title: 'TODO: Raw Telemetry Management',
                        url: 'raw-telemetry',
                        component: RemoveData
                        //show all the raw data for a specific device and allow deletion
                    },
                    {
                        title: 'TODO: Pythia Request',
                        url: 'pythia-request',
                        component: RemoveData
                        //Make a general pythia request and get the returned json
                    }
                ]
            },
            {
                url: 'grant-projects',
                title: 'Grant Projects',
                tabs: [
                    {
                        url: 'duck-charge',
                        title: 'DuckCharge',
                        component: DuckCharge,
                        default: true
                    }
                ]
            }
        ];

        return (
            <TabbedPage dataLoaded={this.state.user} tabs={tabs}
                        item={this.state.user} {...this.props}/>
        )
    }
}

export default AdminConsole;
