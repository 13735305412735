import React from "react";
import "./KPI.scss";
import {Icon, Dropdown, Button} from 'gd-react';
import GridDuck from 'gridduck';
import getFormat from '../services/formatter.js'
import {Loader} from "gd-react";
import {Tooltip} from "@material-ui/core";
import InteractiveTooltip from "./InteractiveTooltip";
import TariffBreakdown from "./TariffBreakdown";
import formatter from "../services/formatter.js";
import SomeSitesWithoutIconWithTooltip from "./SomeSitesWithoutIconWithTooltip";
import AddEditCustomFieldTypeModal from "../modals/AddEditCustomField/AddEditCustomFieldType";
import {isMobile} from "react-device-detect";

class customKPI extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            customFieldType: null,
            loaded: false,
            comparative: this.props.initialComparative || Object.keys(this.props.kpiTotals)[0],
            customFieldValue: null,
            showCustomFieldTypeModal: null
        };
        this.getData = this.getData.bind(this);
        this.generateIcon = this.generateIcon.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let comparativeDatum = this.props.kpiTotals[this.state.comparative];
        if (!comparativeDatum && this.props.kpiTotals) {
            this.setState({comparative: Object.keys(this.props.kpiTotals)[0]})
        }
    }

    async getData() {
        let self = this;
        this.setState({loaded: false});
        let type = this.props.type, itemId = this.props.item.id, field;
        if (type === 'SiteGroup') field = 'siteGroupId';
        if (type === 'client') field = 'orgPartnerId';
        else if (type === 'site') field = 'siteId';
        else if (type === 'deviceGroup') field = 'deviceGroupId';
        else if (type === 'asset') field = 'assetId';

        let cft = await GridDuck.getCustomFieldTypes({filters: [{field: 'id', value: this.props.customFieldId}]})

        let data = await GridDuck.getCustomFieldDatas({
            filters: [{field: field, value: itemId}, {
                field: 'customFieldTypeId',
                value: this.props.customFieldId
            }]
        });

        if (!cft.list[0]) {
            return this.props.removeKpi();
        }

        // total: total,
        // no_sites_have_field_type: noSitesHaveFieldType,
        // sites_without_field_type: sitesWithoutFieldType,

        let total = data.list[0].total;
        let noSitesHaveFieldType = data.list[0].noSitesHaveFieldType;
        let sitesWithoutFieldType = data.list[0].sitesWithoutFieldType;

        self.setState({
            customFieldType: cft.list[0],
            customFieldValue: total,
            loaded: true,
            noSitesWithCustomField: noSitesHaveFieldType,
            sitesWithoutFieldType: sitesWithoutFieldType
        }, () => {
            self.props.onLoaded();
        });
    }

    spend(comparativeDatum, dividedBy, bold) {
        let spend = !comparativeDatum.noTariff && comparativeDatum.totalValues && comparativeDatum.totalValues.length ? comparativeDatum.totalValues.map((tv, i) => {
            return <InteractiveTooltip key={'data-tt'}
                                       title={<TariffBreakdown
                                           dividedBy={dividedBy}
                                           value={tv}/>}>
                <div key={i + 'kpi-v'} className={"kpi-value " + (bold ? 'total' : '')}>{i > 0 ?
                    <span>+</span> : ''}{formatter('currency')(tv.currency).format(((tv.value + tv.standingChargeValue) / 100) / (dividedBy || 1))}</div>
            </InteractiveTooltip>
        }) : '';
        if (comparativeDatum.noTariff) {
            spend = <div className={"kpi-value"}>No Tariff</div>
        }
        return spend;
    }

    generateIcon(size) {
        return <div style={{margin: '0 3px'}}><Icon size={size} color={this.state.customFieldType.colour}
                                                    icon={this.state.customFieldType.icon}/></div>
    }

    render() {
        let self = this;
        let comparativeDatum = this.props.kpiTotals[this.state.comparative];
        let comparativeDropDown = <div className={'minimal-dropdown ultra-minimal swiper-no-swiping'}>
            <Dropdown value={this.state.comparative}
                      label={''} nolabel={'true'}
                      fixeditems={Object.keys(this.props.kpiTotals).filter(k => k !== 'change').map((kpit) => {
                          return {
                              id: kpit,
                              title: getFormat('kpiTitle')(kpit),
                              value: kpit
                          }
                      })}
                      onChange={(val) => {
                          this.setState({comparative: val.target.value});
                          self.props.onComparativeChange(self.state.customFieldType, val.target.value);
                      }}/>
        </div>;

        let dataType = 'delivered';
        if (comparativeDatum && comparativeDatum.dataType) {
            if (comparativeDatum.dataType.indexOf('gas') !== -1) dataType = 'gas';
            if (comparativeDatum.dataType.indexOf('water') !== -1) dataType = 'water';
        }

        return [(
            this.state.loaded && comparativeDatum && this.state.customFieldValue ?
                <div key={this.props.customFieldId} className={"kpi-datum custom"}>
                    <Tooltip placement={'top'} title={'Remove KPI'}>
                        <div className={'cancel-button'}><Icon color={'gd-red'} icon={'MdCancel'}
                                                               onIconClick={this.props.removeKpi}/></div>
                    </Tooltip>
                    <div
                        className={"kpi-title row min-dd"}>
                        <div style={{overflow: 'hidden', position: 'relative'}}>{comparativeDropDown}
                            {/*<span*/}
                            {/*className={'icon-font'}*/}
                            {/*style={{*/}
                            {/*    margin: '0 5px',*/}
                            {/*    position: 'absolute',*/}
                            {/*    right: 0,*/}
                            {/*    top: 0,*/}
                            {/*    pointerEvents: 'none'*/}
                            {/*}}><i className="fa-solid fa-chevron-down"></i></span>*/}
                        </div>
                        / <div className={'row clickable'}
                               onClick={isMobile ? () => {
                               } : () => this.setState({showCustomFieldTypeModal: this.state.customFieldType})}>
                        {this.generateIcon('inherit')}{this.state.customFieldType.name}
                    </div>
                    </div>
                    <div className={'row'} style={{flex: 'none'}}>
                        {comparativeDatum.noTariff ? <div className={"kpi-value"}>No Tariff</div> : ''}
                        {comparativeDatum.totalValues && !comparativeDatum.noTariff ?
                            <div className={'row'} style={{flex: '0 0'}}>
                                {this.spend(comparativeDatum, self.state.customFieldValue)}
                            </div> : ''}

                        {!comparativeDatum.totalValues ?
                            <div
                                className={"kpi-value"}>{formatter(dataType)((comparativeDatum.rawValue / this.state.customFieldValue).toFixed(2))}
                            </div> : null}
                        <InteractiveTooltip key={'nfc-tt'}
                                            title={
                                                <div className={'KPI-Breakdown'}>
                                                    <p className={'title'}>Breakdown</p>
                                                    <p className={'kpi-label'}>{getFormat('kpiTitle')(this.state.comparative)}</p>
                                                    {!comparativeDatum.totalValues ?
                                                        <p className={'kpi-value'}>{formatter(dataType)(comparativeDatum.rawValue.toFixed(2))}</p> : null}
                                                    {this.spend(comparativeDatum)}
                                                    <div
                                                        className={'kpi-label'}>{this.generateIcon('inherit')}{this.state.customFieldType.name}</div>
                                                    <p className={'kpi-value'}>{formatter('comma')(this.state.customFieldValue)}</p>
                                                    <p className={'dividing-text'}>
                                                        {getFormat('kpiTitle')(this.state.comparative)} <span>divided
                                                        by</span> {this.state.customFieldType.name}</p>
                                                    {!comparativeDatum.totalValues ?
                                                        <p className={'kpi-value total'}>{formatter(dataType)((comparativeDatum.rawValue / this.state.customFieldValue).toFixed(2))}</p> : null}
                                                    {this.spend(comparativeDatum, self.state.customFieldValue, true)}
                                                </div>
                                            }>
                            <span className={'n-f-c-icon'}>
                                <Icon size={'20'} icon={'IoMdInformationCircle'}
                                      color={'#bababa'}/>
                        </span></InteractiveTooltip>
                        {this.state.sitesWithoutFieldType && this.state.sitesWithoutFieldType.length && !this.state.showCustomFieldTypeModal ?
                            <SomeSitesWithoutIconWithTooltip size={'20'} urlItem={'custom-fields'}
                                                             buttonLabel={'Manage ' + this.state.customFieldType.name}
                                                             buttonClick={() => this.setState({showCustomFieldTypeModal: this.state.customFieldType})}
                                                             sitesWithout={this.state.sitesWithoutFieldType}
                                                             itemId={this.state.customFieldType.id}
                                                             title={this.state.customFieldType.name}/> : null}
                    </div>
                </div> : null),
            (!this.state.loaded ? <div key={this.props.customFieldId + 'loader'} className={"kpi-datum custom"}>
                <div
                    className={"kpi-title row min-dd"}>
                    <div style={{overflow: 'hidden', position: 'relative'}}>Loading Data...</div>
                </div>
                <div className={"kpi-value"} style={{width: '100px'}}><Loader size={18} type={'circular'}/></div>
            </div> : null),
            (this.state.loaded && (!comparativeDatum || !this.state.customFieldValue) ?
                <div className={"kpi-datum custom"} key={this.props.customFieldId + 'cancel'}>
                    <Tooltip placement={'top'} title={'Remove KPI'}>
                        <div className={'cancel-button'}><Icon color={'gd-red'} icon={'MdCancel'}
                                                               onIconClick={this.props.removeKpi}/></div>
                    </Tooltip>
                    <div
                        className={"kpi-title row min-dd"}>
                        <div style={{overflow: 'hidden', position: 'relative'}}>No sites
                            have {this.state.customFieldType.name}</div>
                    </div>
                    {!isMobile ? <Button label={'Manage'}
                                         onClick={() => this.setState({showCustomFieldTypeModal: this.state.customFieldType})}
                                         style={{
                                             height: '15px',
                                             fontSize: '10px',
                                             lineHeight: '15px',
                                             marginTop: '5px'
                                         }}/> : null}
                </div> : null),
            (this.state.showCustomFieldTypeModal ?
                <AddEditCustomFieldTypeModal key={this.props.customFieldId + 'add-edit-modal'} onDelete={() => {
                    this.setState({showCustomFieldTypeModal: null}, () => {
                        this.props.removeKpi();
                    });
                }} onClose={(e, reload) => {
                    this.setState({showCustomFieldTypeModal: null});
                    this.getData();
                    // TODO RELOAD DATA
                }} open={!!this.state.showCustomFieldTypeModal} item={this.state.showCustomFieldTypeModal}/> : null)]

    }
}

export default customKPI;
