import React, {Component} from 'react';
import DataItemDetails from "./DataItemDetails";
import {Tabs, Icon} from 'gd-react';

class DataItemTabs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'inner-tabbed-page data-upload'}>
                {this.props.dataItems ?
                    <Tabs tabData={[this.props.dataItems.map((di, i) => {
                        return {
                            id: di.id,
                            title: <div className={'row'} style={{color: (this.props.triedToSave && Object.values(di.errors).find(e => e) ? 'red' : 'inherit')}}>{di.name ? di.name : 'Item ' + (i + 1)} {!this.props.noEdit && this.props.dataItems.length > 1 ?
                                <div style={{marginLeft: '5px'}}><Icon color={'gd-red'} onIconClick={(ev) => {
                                    ev.preventDefault();
                                    ev.stopPropagation();
                                    this.props.removeTab(di);
                                }} icon={'FaTrash'}/></div> : ''}</div>,
                            onTabClick: () => this.props.selectDataItem(di),
                            selected: di.selected
                        }
                    }).filter(t => t)]}/> : null}
                <div className={'contents'}>
                    {this.props.dataItems.map((di, i) => {
                        if (di.selected) {
                            return <DataItemDetails columnData={this.props.columnData}
                                                    key={di.id}
                                                    dataItemsLayout={this.props.dataItemsLayout}
                                                    addNewItem={this.props.addNewItem}
                                                    updateNewItem={this.props.updateNewItem}
                                                    newSites={this.props.newSites}
                                                    newDevices={this.props.newDevices}
                                                    dataTypes={this.props.dataTypes}
                                                    removeNewItem={this.props.removeNewItem}
                                                    triedToSave={this.props.triedToSave}
                                                    dataTypeAutocomplete={this.props.dataTypeAutocomplete}
                                                    columnsDropDown={this.props.columnsDropDown}
                                                    onChange={this.props.onChange} dataItem={di}/>
                        } else return null;
                    })}
                </div>
            </div>
        );
    }
}

export default DataItemTabs;