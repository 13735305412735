import React, {Component} from 'react';
import DataTypeDetails from "./DataTypeDetails";
import {Tabs, Icon} from 'gd-react';

class DataTypesFromColumns extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'inner-tabbed-page data-upload'}>
                {this.props.dataTypes ?
                    <Tabs tabData={[this.props.dataTypes.map((dt, i) => {
                        return {
                            id: dt.id,
                            title: <div className={'row'} style={{color: (this.props.triedToSave && Object.values(dt.errors).find(e => e) ? 'red' : 'inherit')}}>{dt.dataType?.title ? dt.dataType.title : 'Data Type ' + (i + 1)} {this.props.dataTypes.length > 1 ?
                                    <div style={{marginLeft: '5px'}}><Icon color={'gd-red'} onIconClick={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        this.props.removeTab(dt);
                                    }} icon={'FaTrash'}/></div> : ''}</div>,
                            onTabClick: () => this.props.selectDataType(dt),
                            selected: dt.selected
                        }
                    })]}/> : null}
                <div className={'contents'}>
                    {this.props.dataTypes.map((dt, i) => {
                        if (dt.selected) {
                            return <DataTypeDetails columnData={this.props.columnData}
                                                    key={dt.id}
                                                    triedToSave={this.props.triedToSave}
                                                    columnsDropDown={this.props.columnsDropDown}
                                                    onChange={this.props.onChange} dataType={dt}/>
                        } else return null;
                    })}
                </div>
            </div>
        );
    }
}

export default DataTypesFromColumns;