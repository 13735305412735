import React, {Component} from "react";
import "./Disconnected.scss";
import '../../styles/_layout.scss';
import GridDuck from "gridduck";
import {
    Button,
    CardBody,
    Tabs,
    List,
    Icon,
    StandardListCell,
    EditableList,
    Tooltip
} from "gd-react";
import GenericLoader from "../../components/GenericLoader";
import NoItems from "../noItems";
import history from "../../meta/history";
import SideDrawer from "../SideDrawer";
import {deleteCookie} from "../../services/cookieManager";
import DeviceHubIssueOverviewIcons from "../DeviceHubIssueOverviewIcons/DeviceHubIssueOverviewIcons";
import NotificationsFilter from "../Notifications/NotificationsFilter";

class DisconnectedDrawer extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.getList = this.getList.bind(this)
        this.onTabClick = this.onTabClick.bind(this)
        this.navigateToClickedItem = this.navigateToClickedItem.bind(this)
        this.ignoreIncident = this.ignoreIncident.bind(this)
        this.ignoreAll = this.ignoreAll.bind(this)
        this.getDevicesHubs = this.getDevicesHubs.bind(this)
        this.updateTabs = this.updateTabs.bind(this)
        this.unIgnoreAll = this.unIgnoreAll.bind(this)
        this.setIgnoredDevices = this.setIgnoredDevices.bind(this)
        this.disconnectedDevicesListDOM = React.createRef();
        this.ignoredDevicesListDOM = React.createRef();
        this.errors = {};
        this.state = {
            loaded: false,
            account: null,
            showingIssues: ['disconnected', 'intermittent', 'low_signal', 'fault', 'anomaly', 'low_battery'],
            onlyIs: false
            // filterId: this.props.filterId,
            // filterType: this.state.,
            // filterItem: this.props.filterItem
        }
        this.modalClosed = false;
        this.updateTabs();
        this.issueListRef = React.createRef();
        console.log(this.props.ignoredDevices, ' : ignored devices');
    }

    async setIgnoredDevices({item, adding}) {
        if (adding) {
            return this.state.account.setIgnoreAssets([item.id]);
        } else {
            return this.state.account.setUnignoreAssets([item.id]);
        }
    }

    async componentDidMount() {
        let account = await GridDuck.getAccount({id: GridDuck.userId});
        this.setState({loaded: true, account: account});
        console.log(this.props.filterId, ' : filter ID');
        console.log(this.props.filterType, ' : filter type');
    }

    async unIgnoreAll() {
        this.setState({hideIgnored: true});
        // await this.state.account.setIgnoredAssets([]);
        this.setState({hideIgnored: false});
        return this.state.account.setUnignoreAll(true);
    }

    ignoreAll() {
        console.log(this.state.searchText, ' : this.state.searchText');
        return this.state.account.setIgnoreAssetsWithFilter({
            unignored: true,
            filter_id: this.props.filterId,
            filter_type: this.props.filterType,
            issue_types: this.state.showingIssues,
            only_has: this.state.onlyIs,
            search: this.state.searchText ? this.state.searchText.value : null
        });
    }

    updateTabs() {
        // issuesCount
        // ignoredDevicesCount
        if (!this.tabs) {
            this.tabs = [[
                {
                    id: 1,
                    title: 'Devices with Issues',
                    icon: 'FaExclamationCircle',
                    color: '#e43535',
                    count: this.state.issueCount,
                    onTabClick: this.onTabClick,
                    selected: true
                },
                // {
                //     id: 3,
                //     title: 'Warnings',
                //     icon: 'FaExclamationCircle',
                //     color: 'orange',
                //     count: 163,
                //     onTabClick: this.onTabClick,
                //     selected: (this.props.openOnTab === 3)
                // },
                {
                    id: 2,
                    icon: 'FaEyeSlash',
                    title: 'Ignored Devices',
                    count: this.state.ignoredDevicesCount,
                    onTabClick: this.onTabClick,
                    selected: (this.props.openOnTab === 2)
                }
                // {
                //     id: 3,
                //     title: 'Disconnection Events',
                //     onTabClick: this.onTabClick,
                //     selected: (this.props.openOnTab === 3)
                // },
            ]]
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('Update Tabs');
        // if (this.props.filterId !== this.props.filterId) {
        //     console.log(this.props.filterId, ' : filter ID');
        //     console.log(this.props.filterType, ' : filter type');
        //     this.setState({filterId: this.props.filterId, filterType: this.props.filterType, filterItem: this.props.filterItem});
        // }
        // if (this.state.issueCount !== prevState.issueCount) {
        //     if (this.disconnectedDevicesListDOM.current) {
        //         console.log(this.disconnectedDevicesListDOM.current, ' : this.disconnectedDevicesListDOM.current');
        //         this.disconnectedDevicesListDOM.current.getItems();
        //     }
        //     if (this.ignoredDevicesListDOM.current) {
        //         this.ignoredDevicesListDOM.current.loadData();
        //     }
        //     console.log('Update Tabs');
        // }
        // this.updateTabs();
    }

    async ignoreIncident(objectId, event) {
        // Rewrite to be a server side update on ignored device
        console.log('Ignore device: ', objectId);
        event.stopPropagation();
        event.preventDefault();

        // let ignoredAssets = this.state.account.ignoredAssets;
        // if (ignoredAssets.indexOf(objectId) === -1) {
        //     ignoredAssets.push(objectId);
        // }
        // let res = await this.state.account.setIgnoredAssets(ignoredAssets);

        // return Promise.resolve(res);
        return this.state.account.setIgnoreAssets([objectId]);
    }

    async getList(e, o_list, sort_by) {
        console.log(e, ' : e.params.filters');
        console.log(o_list, ' : o_list');
        let _list = o_list;
        let filteredList = o_list;
        let searchFilter = e.filters ? e.filters.find(p => p.field === 'search') : null;
        if (searchFilter) {
            filteredList = o_list.filter(li => (li.objectName.toLowerCase().indexOf(searchFilter.value.toLowerCase()) !== -1) || (li.siteName.toLowerCase().indexOf(searchFilter.value.toLowerCase()) !== -1));
        }
        if (e.items) {
            _list = filteredList.sort((a, b) => b[sort_by] - a[sort_by]).filter((incident, i) => i >= e.offset && i < (e.offset + e.items));
        }

        let list = {
            list: _list,
            total: filteredList.length
        }

        this.setState({issueCount: filteredList.length});

        console.log(list, ' : final list here');

        return Promise.resolve(list)
    }

    navigateToClickedItem(item) {
        console.log(item, ' item clicked');
        // this.closeModal();

        history.push('/device/' + item.id + '/details')

    }

    onTabClick(ev, tab) {
        this.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    async getDevicesHubs(params) {
        return GridDuck.getIgnoredDevices(params);
    }

    async getIgnoredDevices(params) {
        return GridDuck.getIgnoredDevices(params);
    }

    closeModal() {
        this.modalClosed = true;
        deleteCookie('disconnectionDrawerOpen', 'true');
        this.props.onClose();
    }

    render() {
        let self = this;
        let footer;
        console.log(self.issueCount, ' : this.state.issueCount in render');
        if (this.tabs) {
            this.tabs[0][0].count = self.state.issueCount;
            this.tabs[0][1].count = self.ignoredDevicesCount;
        }
        if (this.tabs[0][0] && this.tabs[0][0].selected && this.state.issueCount) {
            footer = <Button additionalclasses={'md'} progressRes onClick={this.ignoreAll} color={'gd-grey'}
                             label={'Ignore all ' + this.state.issueCount + ' issues'}/>;
        }
        if (this.tabs[0][1] && this.tabs[0][1].selected) {
            footer = <Button additionalclasses={'md'} progressRes onClick={this.unIgnoreAll} color={'gd-grey'}
                             label={'Stop ignoring all devices'}/>;
        }
        console.log(this.props.openOnTab, ' open on tab in disconnected drawer')
        console.log(this.tabs, ' tabs in disconnected drawer')
        return (
            <SideDrawer className={'manage-disconnections'}
                        title={<div className={'row space-between'}>
                            <div className={'column'}>
                                Device Issues
                                {this.props.filterItem ? <div className={'link in-drawer-header'}><span
                                    style={{paddingRight: '5px'}}>in </span>
                                    <div className={'site-filter-title'}>{this.props.filterItem.name}</div>
                                    <div><Icon icon={'FaTimes'} color={'gd-red'} onIconClick={() => {
                                        this.props.updateFunctions.updateDisconnectedDrawer({
                                            filterItem: null,
                                            filterId: null,
                                            filterType: null,
                                            openDisconnectedDrawer: true
                                        });
                                    }}/></div>
                                </div> : null}
                            </div>
                            <NotificationsFilter onlyIs={this.state.onlyIs} onlyIsDropDown label={'Issues'}
                                                 potentialOptions={['disconnected', 'intermittent', 'low_signal', 'low_battery', 'fault', 'anomaly']}
                                                 type={'issues'} onUpdate={(val, onlyIs) => {
                                console.log(val, ' : update types filter to');
                                this.setState({showingIssues: val, onlyIs: onlyIs}, () => {
                                    if (this.issueListRef && this.issueListRef.current) this.issueListRef.current.getItems(null, true, null, true)
                                });
                            }} notificationTypes={this.state.showingIssues}/>
                        </div>}
                        footer={footer}
                        open={this.props.open}
                        contentLabel={'disconnected'}
                        onClose={this.closeModal}>
                <CardBody additionalclasses={'no-padding rule'}>
                    {this.state.loaded ? <div className={'disconnected-drawer inner-rule'}>
                        <div className={"details"}>
                            <div className={'details-inner'} style={{overflow: 'hidden'}}>
                                <div className={'inner-header no-padding'}>

                                    <p className={'info-t'}>Manage Disconnection Notifications <span className={'link'}
                                                                                                     onClick={() => {
                                                                                                         history.push('/settings');
                                                                                                     }}>here</span></p>
                                    <Tabs tabData={this.tabs}/>
                                </div>
                                {this.tabs[0][0] && this.tabs[0][0].selected ?
                                    <List
                                        removeHeaders
                                        searchable
                                        nohover
                                        rowHeight={'65px'}
                                        ref={this.issueListRef}
                                        noitemelem={<NoItems text={<span
                                            style={{marginTop: '30px', fontSize: 'inherit', fontStyle: 'inherit'}}>You have no disconnected devices</span>}
                                        />}
                                        columns={[
                                            {
                                                component: StandardListCell,
                                                maxWidth: 'none',
                                                custom: function (item) {
                                                    return <div className={'column'}>
                                                        <div className={'row'}>
                                                            <p className={'disconnected-name'}><span
                                                                className={'clickable'} onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                history.push('/device/' + item.id + '/details')
                                                            }}>{item.assetName}</span>
                                                                {item.siteName ?
                                                                    <span onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        history.push('/site/' + item.siteId + '/devices')
                                                                    }}
                                                                          className={'disconnected-subtitle clickable'}>{' • ' + item.siteName}</span> : null}
                                                            </p>
                                                        </div>
                                                        <DeviceHubIssueOverviewIcons item={item}/>
                                                    </div>
                                                }
                                            },
                                            {
                                                component: StandardListCell,
                                                maxWidth: '30px',
                                                custom: function (item) {
                                                    return <Tooltip
                                                        label={'Ignore device'}
                                                        position={'top'}><Icon onIconClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        self.ignoreIncident(item.id, e);
                                                    }} size={'14'} icon={'FaEyeSlash'}
                                                                               color={'grey'}/></Tooltip>
                                                }
                                            },
                                            {
                                                component: StandardListCell,
                                                maxWidth: '30px',
                                                custom: function (item) {
                                                    return <Tooltip
                                                        label={'View device'}
                                                        position={'top'}><Icon size={'14'}
                                                                               onIconClick={() => self.navigateToClickedItem(item)}
                                                                               icon={'FaArrowRight'}
                                                                               color={'grey'}/>
                                                    </Tooltip>
                                                }
                                            },
                                        ]}
                                        getlist={async (params) => {
                                            console.log(params, ' : params on get list');
                                            let issues = await GridDuck.getIssues(params);
                                            if (issues.total !== this.state.issueCount) {
                                                this.setState({
                                                    issueCount: issues.total,
                                                    searchText: params.filters.find(f => f.field === 'search')
                                                });
                                            }
                                            return issues;
                                        }}
                                        filters={[
                                            {
                                                field: 'unignored',
                                                value: true
                                            },
                                            {
                                                field: 'filterId',
                                                value: this.props.filterId
                                            },
                                            {
                                                field: 'filterType',
                                                value: this.props.filterType
                                            },
                                            {
                                                field: 'issueTypes',
                                                value: this.state.showingIssues
                                            },
                                            {
                                                field: 'onlyHas',
                                                value: this.state.onlyIs
                                            }
                                        ]}
                                    />
                                    : null}
                                {this.tabs[0][2] && this.tabs[0][2].selected ?
                                    <List
                                        removeHeaders
                                        lazyload
                                        noitemelem={<NoItems text={'No new disconnection events to show'}/>}
                                        columns={[
                                            {
                                                component: StandardListCell,
                                                maxWidth: '20px',
                                                custom: function (item) {
                                                    console.log(item, ' item being processed')
                                                    return <div><Icon size={'14'} icon={'FaPlug'}
                                                                      color={'red'}/>
                                                    </div>
                                                }
                                            },
                                            {
                                                component: StandardListCell,
                                                maxWidth: '200px',
                                                custom: function (item) {
                                                    return <div className={'column'}>
                                                        <div className={'row'}>
                                                            <p className={'disconnected-name'}>{item.objectName}
                                                                {item.objectType ?
                                                                    <span
                                                                        className={'disconnected-subtitle'}>{' • ' + item.objectType}</span> : null}
                                                            </p>
                                                        </div>
                                                        <div className={'disconnected-date'}>some date
                                                            here{item.date}</div>
                                                    </div>
                                                }
                                            },
                                            {
                                                component: StandardListCell,
                                                title: 'Description',
                                                field: 'description',
                                                maxWidth: 'none',
                                                custom: function (item) {
                                                    return <div className={'disconnected-description'}>
                                                        <p>
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                }
                                            },
                                            {
                                                component: StandardListCell,
                                                maxWidth: '30px',
                                                custom: function (item) {
                                                    return <div onClick={() => self.navigateToClickedItem(item)}><Icon
                                                        size={'14'} icon={'FaArrowRight'}
                                                        color={'grey'}/>
                                                    </div>
                                                }
                                            },
                                        ]}
                                        getlist={(params) => this.getList(params, this.props.incidents, 'timeStart')}/>
                                    : null}
                                {this.tabs[0][1] && this.tabs[0][1].selected && !this.state.hideIgnored ?
                                    <EditableList
                                        key={1}
                                        noCancel
                                        noSave
                                        ref={this.ignoredDevicesListDOM}
                                        isEditing
                                        editingColumns={[
                                            {component: StandardListCell, title: 'Site', field: 'siteName'},
                                            {
                                                component: StandardListCell,
                                                maxWidth: '15px',
                                                title: ' ',
                                                custom: function (item) {
                                                    return <div><Icon size={item.type === 'asset' ? '14' : '16'}
                                                                      icon={item.type === 'asset' ? 'FaPlug' : 'MdRouter'}
                                                                      color={'gd-grey'}/>
                                                    </div>
                                                }
                                            }
                                        ]}
                                        asyncActive
                                        getActiveList={(params) => this.getIgnoredDevices(params)}
                                        getAvailableList={this.getDevicesHubs}
                                        onChange={this.setIgnoredDevices}
                                        availableFilters={[{
                                            field: 'unignored',
                                            value: true
                                        }]}
                                    />
                                    : null}
                            </div>
                        </div>
                    </div> : <GenericLoader/>}

                </CardBody>
            </SideDrawer>
        )
    }
}


export default DisconnectedDrawer;