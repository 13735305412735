import React from 'react';
// import './DesktopLogin.scss';
import {Link} from "react-router-dom";
import {Input, Button, Dropdown, GdAutocomplete} from "gd-react";
import animatedLogo from "../images/animated-logo-once.svg";
import {Icon} from "@material-ui/core";
import Forgot from "./Forgot";
import CreateOrganisation from "./CreateOrganisation";
import PasswordChecker from "../components/PasswordChecker/PasswordChecker";
import Toggle from "../components/Toggle/Toggle";

class DesktopCreateOrganisation extends CreateOrganisation {

    render() {
        return (
            <div className={'loginPage organisation'}>
                {/*<Link to="/" style={{*/}
                {/*    'position': 'absolute',*/}
                {/*    padding: '20px 30px',*/}
                {/*    fontSize: '13px',*/}
                {/*    color: 'grey',*/}
                {/*    textDecoration: 'none'*/}
                {/*}}><Icon/>Back to login</Link>*/}
                {!this.state.requestSent ?
                    <div style={{
                        'display': 'flex',
                        'flexDirection': 'column',
                        'flex': 50,
                        'justifyContent': 'center',
                        'alignItems': 'center',
                        'overflow': 'hidden',
                        position: 'relative'
                    }}>
                        <div style={{
                            overflow: 'auto',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <div style={{width: '400px'}}>
                                <div style={{padding: '100px 0'}}>
                                    {/*<p className={'header'}>Welcome to GridDuck</p>*/}
                                    <p className={'subHeader'}>Create Organisation</p>
                                    <Input required
                                           onChange={(val) => this.setState({newOrgName: val.target.value})}
                                           top={'true'}
                                           name={'orgName'} value={this.state.newOrgName}
                                           label={'Organisation Name'}/>
                                    <Input required
                                           style={{marginBottom: '10px'}}
                                           error={!this.state.emailValid && this.state.submitted}
                                           onChange={this.onEmailsChange}
                                           name={'orgEmail'} value={this.state.newEmail}
                                           label={"Email Address"}/>

                                    <Dropdown style={{minWidth: '100px'}} value={this.state.currency}
                                              label={'Currency'} fixeditems={[{
                                        id: 1,
                                        title: '£',
                                        value: 'gbp'
                                    }, {id: 2, title: '€', value: 'eur'}, {id: 2, title: '$', value: 'usd'}]}
                                              onChange={(val) => this.setState({currency: val.target.value})}/>

                                    <div style={{marginTop: '10px'}}>
                                        <Dropdown placeholder={'n/a'} style={{minWidth: '100px'}}
                                                  value={this.state.industry}
                                                  label={'Industry'} fixeditems={[
                                                      {
                                            id: 0,
                                            title: 'n/a',
                                            value: null
                                        },
                                            {
                                            id: 1,
                                            title: 'Manufacturing',
                                            value: 1
                                        }, {
                                            id: 2,
                                            title: 'Ghost Kitchens',
                                            value: 2
                                        }, {
                                            id: 3,
                                            title: 'Hospitality',
                                            value: 3
                                        }, {
                                            id: 4,
                                            title: 'Offices',
                                            value: 4
                                        }]}
                                                  onChange={(val) => this.setState({industry: val.target.value})}/>
                                    </div>
                                    <div style={{marginTop: '10px'}}>
                                        <GdAutocomplete name={'goals'}
                                                        value={this.state.goals}
                                                        options={[
                                                            {
                                                                id: 1,
                                                                title: 'Reduce Energy Usage'
                                                            },
                                                            {
                                                                id: 2,
                                                                title: 'Reduce Carbon Footprint'
                                                            },
                                                            {
                                                                id: 3,
                                                                title: 'Reduce Energy Spend'
                                                            },
                                                            {
                                                                id: 4,
                                                                title: 'Tenant Billing'
                                                            },
                                                            {
                                                                id: 5,
                                                                title: 'Empower / Monitor Renewables'
                                                            }
                                                        ]}
                                                        onChange={(e, newVal) => {
                                                            this.setState({goals: newVal})
                                                        }}
                                                        label="Goals"
                                                        getOptionSelected={function (option, value) {
                                                            if (option && value) return option.id === value.id;
                                                        }}
                                                        filterSelectedOptions
                                                        multiple
                                        />
                                    </div>

                                    <div style={{marginTop: '20px'}}>
                                        <Toggle onClick={(val) => this.setState({free: val})}
                                                inactivestatename={'Free account'}
                                                activestatename={'Paying customer'}
                                                active={this.state.free}/>
                                    </div>
                                    <div className={'email-error'}>
                                        {this.state.existingEmails.length > 0 ?
                                            <p>{window._companyName} account already registered to an existing user </p> : null}
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'row'
                                    }}>
                                        <Button progressRes progressState={this.state.progressState} label={"Submit"}
                                                onClick={this.submit}/>
                                    </div>
                                </div>
                                <p className={'error-text'}>{this.state.loginError}</p>
                            </div>
                        </div>
                    </div>
                    : <div style={{
                        'display': 'flex',
                        'flexDirection': 'column',
                        'flex': 50,
                        'justifyContent': 'center',
                        'alignItems': 'center'
                    }}>
                        <p className={'subHeader'}>Request Sent!</p>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row'
                        }}>
                            <Button label={"Create Another"}
                                    onClick={this.clearForms}/>
                        </div>
                        {/*<p className={'header'}>Check your email for an invite</p>*/}
                    </div>}
                {/*<div style={{*/}
                {/*    'display': 'flex',*/}
                {/*    'flexDirection': 'column',*/}
                {/*    'overflow': 'hidden',*/}
                {/*    'flex': 50,*/}
                {/*    justifyContent: 'center',*/}
                {/*    alignItems: 'center',*/}
                {/*    'backgroundColor': '#00141e'*/}
                {/*}}>*/}

                {/*    <img alt={'img'} src={animatedLogo} className={'animated-logo'}/>*/}

                {/*    <css-doodle grid='20'>*/}
                {/*        {':doodle { grid-gap: 5px; width: 50vw; height: 100vh; position: relative; } border-radius: 100%; border: 0px solid rgb(44 71 82); background: #001e28; --scaledelay: calc(( (@abs(@abs(@row - @size-row/3) + @abs(@col - @size-col/4) - @size-col) / @size-col) - 1) * -4s) ; animation-name: scale; animation-duration: 5s; animation-iteration-count: infinite; animation-timing-function: ease; animation-delay: var(--scaledelay); transform: scale(0.2); opacity: 0.2; @keyframes scale { 0%{ transform: scale(0.2); opacity: 0.2; } 50%{ transform: scale(0.8); opacity: 0.7; } 100%{ transform: scale(0.2); opacity: 0.1 } }'}*/}
                {/*    </css-doodle>*/}

                {/*</div>*/}
            </div>
            // <div>
            // 	Forgot Password<br/>


            // </div>
        );
    }

}

export default DesktopCreateOrganisation;
