import React, {Component} from 'react';
import GDGraphs from 'gd-graphs';
import WidgetGraph from "../Summary/Widgets/WidgetGraph";
import WidgetHeader from "../Summary/Widgets/WidgetHeader";

class ErrorPreview extends Component {
    constructor(props) {
        super(props);
        // Initialize state here
        this.state = {};
        this.dataType = this.props.dataTypes.find(dt => this.props.errorDetails[0].indexOf(dt.dataType.id) !== -1);
        this.dataItem = this.props.dataItems.find(di => this.props.errorDetails[0].indexOf(di.itemId?.id || di.itemId) !== -1);
    }

    render() {
        return (<div>
            <p style={{fontWeight: '600'}}>{`${this.dataItem?.name} - ${this.dataType?.dataType?.title}`}</p>
            {this.props.errorDetails[1].invalidDate ? <p>Invalid Dates: <span className={'error'}>{this.props.errorDetails[1].invalidDate?.value}</span></p> : null}
            {this.props.errorDetails[1].invalidValue ? <p>Invalid Values: <span className={'error'}>{this.props.errorDetails[1].invalidValue?.value}</span></p> : null}
        </div>)
    }
}

export default ErrorPreview;