import React from "react";
import {Menu} from '@material-ui/core'
import {Icon, Dropdown, Button} from "gd-react";
import GridDuck from 'gridduck'
import GenericLoader from "./GenericLoader";
import history from "../meta/history";

class OverlayMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            updateRef: 0,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    async handleClick(e) {
        let self = this;
        let item = this.props.item;
        self.setState({
            anchorEl: e ? e.currentTarget : this.state.anchorEl,
        });
    }

    handleClose() {
        this.setState({anchorEl: null, device: null});
    }


    render() {
        console.log("OverlayMenu", this.props);
        return (
            <div className={'legend-menu'}>
                <div style={{position: 'relative', overflow: 'hidden'}}
                     onClick={this.handleClick}>
                    {this.props.children}
                </div>
                <Menu
                    // id="legend-menu"
                    id="filter-menu"
                    getContentAnchorEl={null}
                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                    transformOrigin={{vertical: "top", horizontal: "right"}}
                    anchorEl={this.state.anchorEl}
                    open={!!(this.state.anchorEl)}
                    disableEnforceFocus
                    onClose={this.handleClose}
                    PaperProps={{
                        style: {
                            transform: 'translateX(-5%)',
                        }
                    }}
                >
                    <div
                        className={'legend-menu-container kpi-tooltip'}
                    >
                        <div className={'legend-header'}>
                            Overlays
                        </div>
                        <div
                            onClick={() => {
                                let overlays = this.props.overlays;
                                if (overlays.indexOf('TEMPERATURE') === -1) {
                                    overlays.push('TEMPERATURE');
                                } else {
                                    overlays = [];
                                }
                                this.props.setOverlays(overlays);
                                this.setState({updateRef: ++this.state.updateRef});
                            }}
                            className={'row'}>
                            <div className={'column'}>
                                <div className={'legend-title'}>Temperature</div>
                                <div className={'legend-subtitle'}>MetOffice temperature data</div>
                            </div>
                            <div className={'legend-menu-icon-container'}>
                                <Icon
                                    size={'14'}
                                    icon={this.props.overlays.indexOf('TEMPERATURE') !== -1 ? 'FaCheck' : 'FaSquare'}/>
                            </div>
                        </div>
                    </div>
                </Menu>
            </div>
        )
    }
}

export default OverlayMenu
