import React from "react";
import {Button, Card, CardBody, CardHeader, DateTimeRangePicker, Dropdown, Input, Loader, Toast} from "gd-react";
import SaveBar from "../../components/SaveBar/SaveBar";
import GridDuck from "gridduck";
import {confirmAlert} from "react-confirm-alert";
import history from "../../meta/history";
import getFormat from "../../services/formatter";

class AssetAdmin extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        this.state = {
            updateRef: 0,
            removeDateStart: new Date().getTime() / 1000,
            removeDateEnd: new Date().getTime() / 1000
        };
        this.updatedExternally = this.updatedExternally.bind(this);
        this.transmitUpdate = this.transmitUpdate.bind(this);
        this.reconfigureReporting = this.reconfigureReporting.bind(this);
        this.removeDataUpdated = this.removeDataUpdated.bind(this);
        this.removeData = this.removeData.bind(this);
        this.aggregateData = this.aggregateData.bind(this);
        this.aggregateUpdated = this.aggregateUpdated.bind(this);
        if (props.item) {
            props.item.on('updated', this.updatedExternally);
            this.updatedExternally();
        }
        GridDuck.getZigbeeFirmwares({
            getAll: true
        }).then(function (versionList) {
            self.setState({versionList: versionList.list.filter(f => f.sku === self.props.item.sku)})
        })
    }


    aggregateUpdated(dates) {
        let startUnix = new Date(dates.start).getTime() / 1000;
        let endUnix = new Date(dates.end).getTime() / 1000;
        this.setState({
            aggregateStart: startUnix,
            aggregateEnd: endUnix
        });
    }

    aggregateData() {
        let self = this;
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='dialog'>
                        <div className={"top-content"}>
                            <h2>Re-aggregate Data for {this.props.item.name}</h2>
                            <p>Are you sure you want to
                                re-aggregate the data for {this.props.item.name}?
                                between {getFormat('date')(this.state.aggregateStart)} and {getFormat('date')(this.state.aggregateEnd)}?</p>
                        </div>
                        <div className={"bottom-menu"}>
                            <Button label={"No"} color={'gd-grey'} onClick={onClose}/>
                            <Button color={'gd-red'}
                                    onClick={() => {
                                        GridDuck.createAssetAggregateDataRequest({
                                            assetId: this.props.item.id,
                                            start: this.state.aggregateStart,
                                            end: this.state.aggregateEnd
                                        }).then(function (res) {
                                            self.setState({assetAggregateDataRequest: res});
                                            res.on('updated', self.updatedExternally)
                                            onClose();
                                        }).catch(function (err) {
                                            console.error("Failed to remove data");
                                            onClose();
                                        })
                                    }}
                                    label={"Yes, re-aggregate"}
                            />
                        </div>
                    </div>
                );
            }
        });
    }

    removeDataUpdated(dates) {
        let startUnix = new Date(dates.start).getTime() / 1000;
        let endUnix = new Date(dates.end).getTime() / 1000;
        this.setState({
            removeDateStart: startUnix,
            removeDataEnd: endUnix
        });
    }

    removeData() {
        let self = this;
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='dialog'>
                        <div className={"top-content"}>
                            <h2>Delete Data for {this.props.item.name}</h2>
                            <p>Are you sure you want to
                                delete data for {this.props.item.name}?
                                between {getFormat('date')(this.state.removeDateStart)} and {getFormat('date')(this.state.removeDataEnd)}?</p>
                        </div>
                        <div className={"bottom-menu"}>
                            <Button label={"No"} color={'gd-grey'} onClick={onClose}/>
                            <Button color={'gd-red'}
                                    onClick={() => {
                                        GridDuck.createAssetRemoveDataRequest({
                                            assetId: this.props.item.id,
                                            start: this.state.removeDateStart,
                                            end: this.state.removeDataEnd
                                        }).then(function (res) {
                                            self.setState({assetRemoveDataRequest: res});
                                            res.on('updated', self.updatedExternally)
                                            onClose();
                                        }).catch(function (err) {
                                            console.error("Failed to remove data");
                                            onClose();
                                        })
                                    }}
                                    label={"Yes, delete"}
                            />
                        </div>
                    </div>
                );
            }
        });
    }

    updatedExternally(field) {
        console.log("Updated externally", field);
        let self = this;
        this.setState({updateRef: this.state.updateRef++});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let self = this;
        if (prevProps !== this.props || this.props.item.id !== prevProps.item.id) {
            if (prevProps.item) {
                prevProps.item.off('updated', this.updatedExternally);
            }
            this.props.item.on('updated', this.updatedExternally);
            this.updatedExternally();
        }
    }

    componentWillUnmount() {
        if (this.props.item && this.props.item.off) {
            this.props.item.off('updated', this.updatedExternally);
        }
        if (this.state.assetAggregateDataRequest && this.state.assetAggregateDataRequest.off) {
            this.state.assetAggregateDataRequest.off('updated', this.updatedExternally);
        }
        if (this.state.assetRemoveDataRequest && this.state.assetRemoveDataRequest.off) {
            this.state.assetRemoveDataRequest.off('updated', this.updatedExternally);
        }
    }

    reconfigureReporting() {
        return this.props.item.set({
            doReconfigure: true
        });
    }

    transmitUpdate() {
        return this.props.item.set({
            firmwareTarget: this.state.targetVersion
        });
    }

    render() {
        let self = this;
        let currentDetails;
        let updateStatus;
        let otaPreview;
        if (this.props.item.assetFirmwareVersion) {
            currentDetails = this.props.item.assetFirmwareVersion;
        } else {
            currentDetails = "No Reported Firmware Version";
        }

        if (this.state.targetVersion) {
            let version = this.state.versionList.find(v => v.id === this.state.targetVersion);
            if (this.state.targetVersion !== this.props.item.firmwareTarget) {
                otaPreview = (
                    <CardBody>
                        <h4>{version.version} - {this.state.targetVersion}</h4>
                        <Button onClick={self.transmitUpdate} progressRes additionalclasses={'sm'}
                                onComplete={() => self.setState({targetVersion: null})} color={'gd-green'}
                                label={'Update'}/>
                        <br/>
                        <Button onClick={() => self.setState({targetVersion: null})} additionalclasses={'sm'}
                                color={'gd-red'} label={'Cancel'}/>
                    </CardBody>
                )
            }
        }

        if (this.props.item.assetFirmwareProgress && this.props.item.assetFirmwareProgress !== 'running_normally') {
            let updateSummary = [];
            if (this.state.versionList) {
                let updateItem = this.state.versionList.find(v => v.id === this.props.item.firmwareTarget);
                if (updateItem) {
                    updateSummary = [(<p>Updating to {updateItem.version}</p>)];
                }
            }
            let loader;
            if (this.props.item.assetFirmwareProgress.substr(0, 11) === 'downloading') {
                loader = (<Loader type={'circular'} withprogress size={50}
                                  value={this.props.item.assetFirmwareProgress.substr(12)}/>)
                updateSummary.push(
                    (<p> - Downloading</p>)
                )
            } else {
                loader = (<Loader type={'circular'} size={50}/>);
                let state = this.props.item.assetFirmwareProgress;
                updateSummary.push(
                    (<p> - {state.charAt(0).toUpperCase() + state.slice(1)}</p>)
                )
            }
            updateStatus = [
                (<CardHeader title={'Updating Status'}/>),
                (<CardBody>
                    <div className={'row'} style={{marginTop: '20px'}}>
                        {loader}
                        {updateSummary}
                    </div>
                </CardBody>)
            ]
        } else if (this.props.item.firmwareTarget) {
            if (this.state.versionList) {
                let target = this.state.versionList.find(v => v.id === this.props.item.firmwareTarget);
                if (parseInt(target.version) !== this.props.item.assetFirmwareVersion) {
                    updateStatus = [
                        (<CardHeader title={'Updating Status'}/>),
                        (<CardBody>
                            <div className={'row'} style={{marginTop: '20px'}}>
                                Target set to {target ? target.version : 'Unknown'}
                            </div>
                        </CardBody>)
                    ]
                }
            }
        }

        return (
            <div className={"page grey narrow not-flex"}>
                <div className={'column top'}>
                    <div className={'detail-content column'}>
                        <Card>
                            <CardHeader title={'Ops'}/>
                            <CardBody>
                                <Button additionalclasses={'sm'} onClick={self.reconfigureReporting} color={'gd-green'}
                                        label={'Reset Reporting'} progressRes={true}/>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader title={'Remove Data'}/>
                            <CardBody>
                                <p><strong>Permanently</strong> remove all energy data for this device between the
                                    selected dates.</p>
                                <DateTimeRangePicker pastOnly
                                                     cleanable={false} key={'selector'} placement={'auto'}
                                                     nolabel='true' onChange={this.removeDataUpdated}
                                                     value={[new Date(this.state.removeDateStart * 1000), new Date(this.state.removeDateEnd * 1000)]}/>
                                <br/>
                                {this.state.removeDataEnd && this.state.removeDateStart ? (
                                    <Button additionalclasses={'sm'} onClick={self.removeData} color={'gd-red'}
                                            label={'Remove Data'}/>) : ''}
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader title={'Re-aggregate Data'}/>
                            <CardBody>
                                <p><strong>Permanently</strong> re-aggregate all delivered (aggregate consumption) data
                                    for this device between the selected dates, by averaging and aggregating from the
                                    instantaneous demand data.</p>
                                <DateTimeRangePicker nolabel='true' onChange={this.aggregateUpdated}/>
                                <br/>
                                {this.state.aggregateStart && this.state.aggregateEnd ? (
                                    <Button additionalclasses={'sm'} onClick={self.aggregateData} color={'gd-red'}
                                            label={'Re-aggregate Data'}/>) : ''}
                            </CardBody>
                        </Card>
                        {this.state.assetAggregateDataRequest ? (
                            <Card>
                                <CardHeader title={'Re-aggregate Data Request Progress'}/>
                                <CardBody>
                                    <p>Status: {this.state.assetAggregateDataRequest.status}%</p>
                                </CardBody>
                            </Card>
                        ) : ''}
                        {this.state.assetRemoveDataRequest ? (
                            <Card>
                                <CardHeader title={'Remove Data Request Progress'}/>
                                <CardBody>
                                    <p>Status: {this.state.assetRemoveDataRequest.status}%</p>
                                </CardBody>
                            </Card>
                        ) : ''}
                        <Card>
                            <CardHeader title={'Current Firmware Version'}/>
                            <CardBody>
                                {currentDetails}
                            </CardBody>
                            {updateStatus}
                            <CardHeader title={'Send OTA Update'}/>
                            <CardBody>
                                <Dropdown
                                    value={this.state.targetVersion}
                                    style={{"padding": "10px"}}
                                    onChange={function (e) {
                                        self.setState({targetVersion: e.target.value});
                                    }}
                                    fixeditems={this.state.versionList ? this.state.versionList.map(v => (
                                        {
                                            title: v.version,
                                            value: v.id
                                        })) : []}
                                />
                            </CardBody>
                            {otaPreview}
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default AssetAdmin;
