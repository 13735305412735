import React from "react";
import "./FilterMenuV2.scss";
import * as _ from 'underscore'
import {FormControlLabel, Checkbox, Menu} from '@material-ui/core'

class FilterMenuV2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick(e) {
        this.setState({anchorEl: e.currentTarget});
    }

    handleClose() {
        this.setState({anchorEl: null});
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    childrenDiffer(children, returnValIf) {
        let groupedChildren = Object.values(_.groupBy(children, 'checked'));
        if (!returnValIf) {
            return groupedChildren.length > 1;
        } else {
            console.log(groupedChildren, ' : groupedChildren');
            return (groupedChildren.length === 1 && groupedChildren['0'][0].checked)
        }
    }

    render() {

        return (

            <div className={'filter-menu clickable'}>
                <div style={{position: 'relative', overflow: 'hidden'}} onClick={this.handleClick}>
                    {this.props.children}
                </div>
                <Menu
                    id="filter-menu"
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    anchorEl={this.state.anchorEl}
                    open={!!(this.state.anchorEl)}
                    onClose={this.handleClose}>
                    <div className={'filter-menu-container'}>
                        {this.props.label ? <div className={'menu-header'}>
                            <p>{this.props.label}</p>
                        </div> : null}
                        <div className={'filter-items'}>
                            {this.props.filterItems.map((fi) => {
                                return <div key={fi.id} className={'checkbox-group'}>
                                    {!fi.justHeader ? <FormControlLabel
                                        label={fi.name}
                                        className={'parent-checkbox'}
                                        control={<Checkbox indeterminate={this.childrenDiffer(fi.children)} disabled={fi.disabled || this.props.disabled} checked={this.childrenDiffer(fi.children, true)}
                                                           onChange={(e) => this.props.onUpdate ? this.props.onUpdate(e.target.checked, fi, e) : null}/>}/> : <label>{fi.name}</label>}
                                    {fi.children ?
                                        <div className={'checkbox-children ' + (fi.justHeader ? 'no-padding' : '')}>
                                            {fi.children.map((fic) => {
                                                return <FormControlLabel
                                                    label={fic.name}
                                                    key={fic.id}
                                                    control={<Checkbox disabled={fic.disabled || fi.disabled || this.props.disabled} checked={fic.checked}
                                                                       onChange={(e) => this.props.onUpdate ? this.props.onUpdate(e.target.checked, fic, e) : null}/>}/>
                                            })}
                                        </div> : null}
                                </div>
                            })}</div>
                    </div>
                </Menu>
            </div>

        )
    }
}


export default FilterMenuV2;