import React, {useRef} from "react";
import GDGraphs from "gd-graphs";
import GridDuck from "gridduck";
import "./DataPage.scss";
import processedUrlParams from "../../services/processDataPageUrlParams";
import getSpend from "../../services/getSpend";
import getDataType from "../../services/getDataTypeForGraphs";
import typesFromStateOptionsService from "../../services/typesFromStateOptionsService";
import moment from "moment-timezone";
import history from "./../../meta/history";

let colorByType = {
    Electricity: "#FFCA00",
    Gas: "#D768F2",
    Water: "#00C3EE",
    "Hot Water": "#f52ec0",
    Generation: "#3ad56e"
};

class DataPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        GDGraphs.config({
            gridduckJS: GridDuck
        });
        let graph = this.props.tab.tab.dataType.dataType === 'aggregate' ? 'bar' : 'line';
        let url = window._current_params || window.location.search
        let urlParams = new URLSearchParams(url);
        if (!window._current_params && window.location.search.length) window._current_params = window.location.search;
        let timezone = this.props.item.timezone || this.props.item.siteTimezone || 'Europe/London';
        let isAll = this.props.item && this.props.item.id.indexOf('all') !== -1;
        let processedParams = processedUrlParams(urlParams, timezone, isAll);
        let start = processedParams.start, end = processedParams.end, compareStart = processedParams.compareStart,
            compareEnd = processedParams.compareEnd;
        this.granularity = processedParams.granularity;
        this.dRString = processedParams.dRString;
        this.cRString = processedParams.cRString;

        if (compareStart && compareEnd) {
            this.compareTo = {
                show: true,
                start: compareStart,
                end: compareEnd
            }
        }
        this.state = {
            type: this.checkIfValidType(urlParams.get('ty') ? urlParams.get('ty') : 'historic'),
            // graph: graph === 'line' ? 'line' : processedParams.graph && processedParams.graph !== 'null' ? processedParams.graph : 'bar',
            graph: graph,
            category: this.props.tab.tab.dataType.category,
            data: null,
            time: 'historic',
            average: urlParams.get('avg') ? urlParams.get('avg') : 'average',
            combined: !!urlParams.get('combined') || isAll,
            highlighted: [],
            hasntSeenZoomTip: false,
            zooming: false,
            overlays: [],
            m_x: 0,
            m_y: 0
        };
        let self = this;
        this.start = start;
        this.end = end;
        this.graphKPIRef = React.createRef();
        this.defaultSelected = self.assetData;
        this.state.assetData = self.assetData;
        this.set = this.set.bind(this);
        this.onHighlight = this.onHighlight.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onGranularitySelect = this.onGranularitySelect.bind(this);
        this.createGraph = this.createGraph.bind(this);
        this.setGraphType = this.setGraphType.bind(this);
        this.resetZoom = this.resetZoom.bind(this);
        this.graphTypeChange = this.graphTypeChange.bind(this);
        this.setSeenZoom = this.setSeenZoom.bind(this)
        this.onChange = this.onChange.bind(this);
        this.overrideBack = this.overrideBack.bind(this);
        this.setOverlays = this.setOverlays.bind(this);
        this.checkIfValidType = this.checkIfValidType.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        this.parentRef = React.createRef();
        this.tooltipRef = React.createRef();
    }

    handleMouseOut(e) {
        this.setState({showDetached: false});
    }

    handleMouseMove(e) {
        let tooltipLineElement;
        if (this.state.type === 'heatmap') {
            tooltipLineElement = document.querySelector('#selectedHeatCell');
        } else {
            tooltipLineElement = document.querySelector('.tooltip-line');
        }
        const tooltipElement = this.tooltipRef.current;

        if (tooltipLineElement && this.parentRef.current && tooltipElement) {
            const rect = tooltipLineElement ? tooltipLineElement.getBoundingClientRect() : null;
            const parentRect = this.parentRef.current.getBoundingClientRect();
            const tooltipRect = tooltipElement.getBoundingClientRect();
            //
            // // Check if tooltip should be visible
            let opacity , x, y;
            if (this.state.type === 'heatmap') {
                opacity = '1';
                x = rect.left + 10;
                if (e.clientY > parentRect.y + (parentRect.height / 2)) {
                    y = rect.top - tooltipRect.height - 10
                } else {
                    y = rect.bottom + 10
                }
            } else {
                x = rect.left + 10;
                y = e.clientY;
                opacity = window.getComputedStyle(tooltipLineElement).getPropertyValue('opacity');
            }

            // Cap x position
            if (x < parentRect.left) {
                x = parentRect.left;
            }
            if (x > parentRect.right - tooltipRect.width - 60) {
                x = parentRect.right - tooltipRect.width - 60;
            }

            // Cap y position
            if (y < parentRect.top + 50) y = parentRect.top + 50;
            if (y + tooltipRect.height > parentRect.bottom) y = parentRect.bottom - tooltipRect.height;

            this.setState({m_x: x, m_y: y, showDetached: opacity === '1'});
        } else {
            if (this.state.showDetached) {
                this.setState({m_x: 0, m_y: 0, showDetached: false});
            }
        }

    };

    setOverlays(overlays) {
        let self = this;
        this.setState({ready: false, overlays: overlays}, function () {
            if (self.graph) {
                self.graph.set({
                    overlays: overlays
                });
            }
        });
    }

    onChange(data) {
        if (data.action === 'close') {
            this.setSeenZoom()
        }
    }

    async setSeenZoom() {
        let tour = this.user.tour || {}
        tour.seen_zoom = true
        await this.user.set({tour: tour})
        return this.setState({hasntSeenZoomTip: false}, function () {
            return true
        })
    }

    overrideBack() {
        // this.override = false;
    }

    async componentDidMount() {
        window.addEventListener('popstate', this.overrideBack);
        let self = this
        let user = await GridDuck.getAccount({id: GridDuck.userId})
        self.user = user
        if (this.state.graph === 'line' && user && user.tour && !user.tour.seenZoom && !user.tour.seen_zoom) {
            self.setState({hasntSeenZoomTip: true})
        }
        this.createGraph();
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.overrideBack);
    }

    checkIfValidType(type) {
        if ((this.props.type === 'asset' && (type === 'breakdown' || type === 'site_breakdown' || type === 'category_breakdown')) || (this.props.type === 'site' && type === 'site_breakdown') || (this.props.tab.tab.dataType.dataType === 'instantaneous' && type !== 'historic')) {
            type = 'historic';
        }
        return type;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let isAll = this.props.item && this.props.item.id.indexOf('all') !== -1;
        let graph = this.props.tab.tab.dataType.dataType === 'instantaneous' ? 'line' : 'bar'
        let combined = false;
        let type = 'historic';
        let average = 'average';
        let category = this.props.tab.tab.dataType.category;
        let _start;
        let _end;
        let _granularity;
        let _dRString;
        let _cRString;
        if (window._current_params) {
            let urlParams = new URLSearchParams(window._current_params);
            let timezone = this.props.item.timezone || this.props.item.siteTimezone || 'Europe/London';
            let isAll = this.props.item && this.props.item.id.indexOf('all') !== -1;
            let processedParams = processedUrlParams(urlParams, timezone, isAll);
            _start = processedParams.start;
            _end = processedParams.end;
            _granularity = processedParams.granularity;
            _dRString = processedParams.dRString;
            _cRString = processedParams.cRString;
            type = this.checkIfValidType(processedParams.type);
            combined = !!processedParams.combined || isAll;
            average = processedParams.average;


            if (processedParams.compareStart && processedParams.compareEnd) {
                this.compareTo = {
                    show: true,
                    start: processedParams.compareStart,
                    end: processedParams.compareEnd
                }
            } else {
                this.compareTo = {
                    show: false,
                    start: null,
                    end: null
                }
            }
        }
        if ((this.props.item && prevProps.item && this.props.item.id !== prevProps.item.id) || (this.props.filterId && prevProps.filterId && this.props.filterId !== prevProps.filterId) || (prevProps && (this.props.tab.tab.dataType.type !== prevProps.tab.tab.dataType.type || this.props.tab.tab.dataType.graphType !== prevProps.tab.tab.dataType.graphType))) {
            if (_start) {
                this.start = _start;
                this.end = _end;
                this.granularity = _granularity;
                this.dRString = _dRString;
                this.cRString = _cRString;
            }
            let stateUpdateObj = {
                kpiData: null,
                type: type,
                combined: combined || isAll,
                overlays: [],
                average: average,
                visibleItems: [],
                graph: graph,
                category: category
            };
            this.setState(stateUpdateObj, () => this.createGraph());
        }

        let urlParams = new URLSearchParams(window.location.search);
        if (this.ab && urlParams.get('ty') === 'heatmap') {
            this.ab = false;
            let timezone = this.props.item.timezone || this.props.item.siteTimezone || 'Europe/London';
            let isAll = this.props.item && this.props.item.id.indexOf('all') !== -1;
            let processedParams = processedUrlParams(urlParams, timezone, isAll);
            let start = processedParams.start, end = processedParams.end,
                compareStart = processedParams.compareStart,
                compareEnd = processedParams.compareEnd;
            this.granularity = processedParams.granularity;
            this.dRString = processedParams.dRString;
            this.cRString = processedParams.cRString;

            let _type = urlParams.get('ty') ? urlParams.get('ty') : 'historic';

            if (compareStart && compareEnd) {
                this.compareTo = {
                    show: true,
                    start: compareStart,
                    end: compareEnd
                }
            }
            this.start = start;
            this.end = end;
            this.setState({
                type: _type,
                graph: 'bar',
                category: this.props.tab.tab.dataType.category,
                data: null,
                time: 'historic',
                overlays: [],
                visibleItems: [],
                average: urlParams.get('avg') ? urlParams.get('avg') : 'average',
                combined: !!urlParams.get('combined'),
                highlighted: [],
                hasntSeenZoomTip: false,
                zooming: false
            }, () => this.createGraph());
            let self = this;

        }
        if (window.history.pushState && !this.ab) {
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
            let params = '';

            params += '?dR=' + (this.dRString ? this.dRString : isAll ? 'week_so_far' : 'week_so_far');
            params += '&ty=' + this.state.type;
            if (this.state.type === 'historic') {
                params += '&g=' + graph;
            }
            if (this.dRString === 'custom') {
                params += '&s=' + this.start;
                params += '&e=' + this.end;
            }
            if (this.cRString) {
                params += '&cR=' + (this.cRString ? this.cRString : 'previous_day');
            }
            if (this.cRString === 'custom' && this.compareTo) {
                params += '&cS=' + this.compareTo.start;
                params += '&cE=' + this.compareTo.end;
            }
            if ((this.state.graph === 'bar' || this.state.graph === 'line') && this.state.type === 'historic') {
                params += '&gr=' + (this.granularity ? this.granularity : 3600);
            }
            if (this.state.combined || isAll) {
                params += '&combined=true';
            }
            if (this.state.average) {
                params += '&avg=' + this.state.average;
            }
            newurl += params
            if (newurl !== window.location.href) {
                window._current_params = params;
                window.history.replaceState({path: newurl}, '', newurl);
            }
        }
    }

    findItemType() {
        let options = [
            'site', 'siteGroup', 'device', 'hub', 'deviceGroup', 'client'
        ]
        let types = options.filter((o) => window.location.pathname.indexOf(o) > -1)
        let type;
        if (types.length > 1) {
            type = types[types.length - 1];
        } else {
            type = types[0];
        }
        return type;
    }

    createGraph(col) {
        let self = this;
        if (!this.props.loaded) return;
        this.resetZoom();
        let graphFn = GDGraphs.lineGraph;
        this.typeCol = colorByType[this.props.tab.tab.dataType.category];

        if (self.state.graph === 'bar' || self.state.type === 'hour_of_day' || self.state.type === 'day_of_week' || self.state.type === 'day_of_month') {
            graphFn = GDGraphs.barChart;
        }
        if (self.state.type === 'breakdown' || self.state.type === 'site_breakdown' || self.state.type === 'category_breakdown') {
            graphFn = GDGraphs.assetBreakdown;
        }
        if (self.state.type === 'heatmap') {
            graphFn = GDGraphs.heatmap;
        }

        let timezone = self.props.item.timezone || self.props.item.siteTimezone || 'Europe/London';
        let color;
        if ((this.state.combined || (self.props.className !== 'group-page' && self.props.className !== 'site-page' && self.props.className !== 'site-group-page' && self.props.className !== 'client-page')) && this.state.graph === 'line') {
            color = '#49b9c4';
            let colors = {
                "Electricity": "#FFCA00",
                "Gas": "#D768F2",
                "Water": "#00C3EE",
                "Hot Water": "#f52ec0",
                "Generation": "#3ad56e",
            };
            if (colors[self.props.tab.tab.dataType.category]) {
                color = colors[self.props.tab.tab.dataType.category];
            }
        }
        let graphParams = {
            element: '#graph',
            timezone: timezone,
            averageType: this.state.average,
            combined: this.state.combined,
            overlays: this.state.overlays,
            realTime: this.props.tab.tab.realTime,
            dataTypes: [self.props.tab.tab.dataType.type],
            error: function (e) {
                console.log("Graph Error", e);
                self.setState({error: true, ready: true})
            },
            onHighlight: function (highlighted) {
                if (highlighted) {
                    self.setState({highlighted: [highlighted]});
                } else {
                    self.setState({highlighted: []});
                }
            },
            onNoTempData: function () {
                self.state.overlays.splice(self.state.overlays.indexOf('TEMPERATURE'), 1);
                self.setState({showNoTempData: true, overlays: self.state.overlays});
            },
            start: this.start,
            end: this.end,
            granularity: this.granularity ? this.granularity : 60 * 30,
            hidden: self.hiddenAssets ? self.hiddenAssets : [],
            compareTo: this.compareTo,
            showTooltip: true,
            onVisibleItems: function (visibleItems) {
                self.setState({visibleItems: visibleItems, noData: visibleItems.length === 0, zooming: false});
            },
            onReady: function () {
                self.setState({ready: true, error: false});
            },
            onClick: function (clickData) {
                const currentURL = new URL(window.location.href);
                const newPathname = currentURL.pathname;
                currentURL.searchParams.set('s', clickData.timestamp);
                currentURL.searchParams.set('e', clickData.timestamp + 3600);
                currentURL.searchParams.set('gr', 300);
                currentURL.searchParams.set('dR', 'custom');
                currentURL.searchParams.set('ty', 'historic');
                currentURL.searchParams.set('g', 'line');
                currentURL.searchParams.set('combined', 'true');
                currentURL.searchParams.set('avg', 'average');
                self.ab = true;
                const newURL = newPathname + currentURL.search;
                history.push(newURL);
                self.start = clickData.timestamp;
                self.granularity = 300;
                self.end = clickData.timestamp + 3600;
                self.dRString = 'custom';
                self.setState({
                    graph: 'line',
                    type: 'historic',
                    average: 'average',
                    combined: false,
                    zoomData: null,
                    dRString: 'custom',
                    start: self.start,
                    granularity: 300,
                    end: self.end,
                    ready: false
                }, function () {
                    self.createGraph();
                });
            },
            onKPIData: function (kpiData) {
                let dataType = getDataType(self.props.tab.tab.dataType.type).spendDataType;
                let isGeneration = typesFromStateOptionsService(null, self.props.tab.tab.dataType.type.toLowerCase());
                let hideCustomKpis = (self.props.type === 'asset' || self.props.type === 'deviceGroup' || !isGeneration);
                if (self.state.graph === 'bar' && self.state.type === 'historic' && self.props.item.id.indexOf('all') === -1) {
                    getSpend(null, {
                        start: self.start,
                        end: self.end,
                        compare_start: self.compareTo ? self.compareTo.start : null,
                        compare_end: self.compareTo ? self.compareTo.end : null,
                    }, dataType, self.props.filterType, self.props.filterId).then((spendData) => {
                        kpiData.spend = spendData;
                        self.setState({kpiData: kpiData, hideCustomKpis: hideCustomKpis});
                    });
                } else {
                    self.setState({kpiData: kpiData, hideCustomKpis: hideCustomKpis, zooming: false})
                }
            },
            onDefaultSelected: function (selectedItems) {
                self.setState({defaultSelected: selectedItems.map(a => a.id)})
            },
            tooltipOverride: function (tooltipData) {
                self.setState({data: tooltipData})
            },
            zoomCallback: function (zoomData) {
                self.setState({zoomData: zoomData, zooming: true}, self.setSeenZoom)
            },
        };
        let itemType = this.findItemType();
        if (self.props.tab.tab.dataType.nonAdditive) {
            graphParams.combined = false;
            graphParams.average = true;
        }
        if (itemType === 'site' || itemType === 'device') {
            let siteId;
            if (itemType === 'site') {
                siteId = this.props.filterId;
            } else if (itemType === 'device') {
                siteId = this.props.item.siteId;
            }
            graphParams.siteId = siteId;
        }
        if (this.props.filterType && this.props.filterType !== 'asset_id') {
            graphParams.filterType = this.props.filterType;
            graphParams.filterId = this.props.filterId;
        }
        if (this.props.filterType === 'asset_id' && this.props.dataItems) {
            graphParams.assets = this.props.dataItems;
        }
        if (color && self.state.type) {
            graphParams.color = color;
        }
        if (self.state.type === 'site_breakdown') {
            graphParams.groupBy = {
                id: 'siteId',
                title: 'siteName'
            };
        }

        if (this.typeCol && self.state.type !== 'breakdown' && self.state.graph !== 'line' && self.state.type !== 'site_breakdown') {
            graphParams.color = this.typeCol;
        }

        if (self.state.type === 'category_breakdown') {
            graphParams.groupBy = {
                id: 'deviceCategoryId',
                title: 'deviceCategory'
            };
            graphParams.color = null;
        }
        let aggregateType;

        if (self.props.tab.tab.dataType.dataType === 'aggregate') {
            aggregateType = self.props.tab.tab.dataType.type;
        } else {
            aggregateType = self.props.tab.tab.dataType.dataTypeSibling;
        }
        let subMenu = false;
        if (self.state.type === 'heatmap' || self.state.graph === 'bar' || self.state.type === 'breakdown' || self.state.type === 'site_breakdown') {
            if (self.state.time === 'realtime' && self.state.type === 'breakdown') {
                graphParams.realTime = true;
            } else {
                graphParams.dataTypes = [aggregateType]
            }
        }
        if ((self.props.className === 'group-page' || self.props.className === 'site-page' || self.props.className === 'site-group-page' || self.props.className === 'client-page') && (this.props.tab.tab.dataType.category === 'Electricity' || this.props.tab.tab.dataType.category === 'Hot Water' || this.props.tab.tab.dataType.category === 'Water' || this.props.tab.tab.dataType.category === 'Gas' || this.props.tab.tab.dataType.category === 'Generation') && (self.state.graph === 'bar' || self.state.type === 'hour_of_day' || self.state.type === 'day_of_week' || self.state.type === 'day_of_month')) {
            graphParams.combined = true;
        }
        if (self.state.type === 'hour_of_day') {
            graphParams.aggregateBy = 'hourOfDay';
            graphParams.granularity = 60 * 60;
            graphParams.dataTypes = [aggregateType]
        }
        if (self.state.type === 'day_of_week') {
            graphParams.aggregateBy = 'dayOfWeek';
            graphParams.granularity = 24 * 60 * 60;
            graphParams.dataTypes = [aggregateType]
        }
        if (self.state.type === 'day_of_month') {
            graphParams.aggregateBy = 'dayOfMonth';
        }
        if (self.graph && self.graph.remove) {
            self.graph.remove();
        }
        self.setState({ready: false});
        self.graph = graphFn(graphParams);
    }

    graphTypeChange(graphTypeData) {
        if (graphTypeData.type === 'heatmap' && this.end - this.start < 24 * 60 * 60) {
            this.dRString = 'last_month';
            this.start = moment().tz(this.props.item.timezone || this.props.item.siteTimezone || 'Europe/London').subtract(1, 'month').startOf('month').unix();
            this.end = moment().tz(this.props.item.timezone || this.props.item.siteTimezone || 'Europe/London').startOf('month').unix();
        }
        this.setState({
            start: this.start,
            end: this.end,
            dRString: this.dRString,
            visibleItems: [],
            graph: graphTypeData.graph,
            type: graphTypeData.type,
            time: graphTypeData.time,
            average: graphTypeData.average,
            combined: graphTypeData.combined,
            zoomData: null,
        }, () => {
            if (graphTypeData.switchToType) {
                this.props.changeTab(['data', graphTypeData.switchToType])();
            } else {
                this.createGraph();
            }
        });
    }

    onGranularitySelect(granularity) {
        this.setState({ready: false});
        this.graph.set({
            granularity: granularity
        });
    }

    set(start, end, compareTo, granularity, meta) {
        let self = this;
        self.start = start;
        self.end = end;
        self.granularity = granularity;
        self.compareTo = compareTo;
        if (meta) {
            self.dRString = meta.dRString;
            self.cRString = meta.cRString;
        }
        this.setState({ready: false, zoomData: null, kpiData: null}, function () {

            if (self.graph) {
                self.graph.set({
                    start: start,
                    end: end,
                    compareTo: compareTo,
                    granularity: granularity,
                    hidden: this.hiddenAssets
                });
            }
        });
    }

    onHighlight(highlightedAssets) {
        this.setState({highlighted: highlightedAssets});
        this.graph.set({
            highlighted: highlightedAssets
        });
    }

    onHide(hiddenAssets) {
        this.hiddenAssets = hiddenAssets;
        this.graph.set({
            hidden: hiddenAssets
        });
    }

    resetZoom() {
        if (this.graph && this.graph.resetZoom) {
            this.graph.resetZoom();
            this.setState({zooming: false});
        }
    }

    setGraphType(e) {
        this.setState({graphType: e.target.value, compareTo: null});
    }
}

export default DataPage;
