import React from "react";
import "./DataPage.scss";
import {Icon} from "gd-react";
import Legend from "../../components/Legend";
import KPI from "../../components/KPI";
import GenericLoader from "../../components/GenericLoader";
import DataPage from "./DataPage";
import GraphTypePicker from "../../components/GraphTypePicker";
import MobileLegend from "../../components/MobileLegend";

let palette = [
    '#003f5c',
    '#2f4b7c',
    '#665191',
    '#a05195',
    '#d45087',
    '#f95d6a',
    '#ff7c43',
    '#ffa600'
];

class MobileDataPage extends DataPage {

    render() {
        let self = this;
        let loader;
        let legend;
        let timezone = this.props.item.timezone || this.props.item.siteTimezone || 'Europe/London';
        let isAll = this.props.item.id.indexOf('all') !== -1;
        legend = (<MobileLegend
            disabled={!this.state.ready}
            onHover={this.onHighlight}
            onHide={this.onHide}
            picker={<GraphTypePicker onChange={this.graphTypeChange}
                                     type={this.state.type}
                                     key={'gtp'}
                                     dRString={this.dRString}
                                     combined={this.state.combined}
                                     graph={this.state.graph}
                                     isAll={isAll}
                                     time={this.state.time}
                                     filterType={this.props.filterType}
                                     filterId={this.props.filterId}
                                     average={this.state.average}
                                     category={this.state.category}/>}
            resetZoom={this.resetZoom}
            zoomData={this.state.zoomData}
            items={this.state.visibleItems}
            category={self.props.tab.tab.dataType.category}
            data={this.state.data}
            combined={this.state.combined}
            highlighted={this.state.highlighted}
            defaultSelected={this.state.defaultSelected}
            locked={this.state.data ? this.state.data.locked : false}
            graph={this.state.graph}
            dRString={this.dRString}
            cRString={this.cRString}
            granularity={this.granularity}
            graphType={this.state.type}
            start={this.start}
            timezone={timezone}
            isAll={isAll}
            end={this.end}
            set={this.set}
            compareStart={this.compareTo ? this.compareTo.start : null}
            compareEnd={this.compareTo ? this.compareTo.end : null}
        />);
        let graphClass = 'graph-holder mobile';
        if (!this.state.ready) {
            loader = (<GenericLoader text={'Fetching Data...'} textLineTwo={'This may take a while'}/>);
            graphClass += ' loading';
        }
        let noData;
        if (this.state.noData) {
            noData = (<div className={"no-data"}>There is no available data</div>)
        }
        let granularityOptions = ['today_so_far', 'yesterday', 'last_7_days', 'last_30_days', 'this_month', 'last_month', 'this_year', 'last_year'];
        if (this.props.tab.tab.dataType.type === 'CONSUMPTION') {
            granularityOptions = [
                '15_mins', 'last_hour', 'last_24_hours', 'today_so_far', 'yesterday', 'last_7_days', 'last_30_days', 'this_month', 'last_month', 'this_year', 'last_year'];
        }

        return (
            <div className={"data-graphs"}>

                <div className={'graph-kpi-legend-wrapper'}>

                    <div className={'graph-kpi-wrapper'}>
                        {!this.state.noData && this.state.type ? <div className={'graph-top-wrapper mobile-h'}>
                            <KPI dataType={this.props.tab.tab.dataType} type={this.props.type} hideCustomKpis={this.state.hideCustomKpis} item={this.props.item} data={this.state.ready && this.state.kpiData ? this.state.kpiData : null}/>
                        </div> : null}
                        <div className={'graph-top-wrapper mobile-h scrollable-y'}>
                            {legend}
                        </div>
                        {this.state.ready ? noData : ''}
                        <div className={graphClass}>
                            {loader}
                            <div className={'graph ' + (this.state.ready ? '' : ' inactive')} id={"graph"}/>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default MobileDataPage;