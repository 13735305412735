import GridDuck from "gridduck";
import React from "react";
import "./LoggedInAsBar.scss"
import {Button} from "gd-react";
import cookie from "react-cookies";
import history from "./meta/history";

class LoggedInAsBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (account) {
                self.setState({account: account});
            });
    }

    revertToNonPartnerLogin() {
        let existingAccessToken = cookie.load('existingAccessToken');
        console.log("Existing access token", existingAccessToken);
        let accessToken = {
            expires: existingAccessToken.expires,
            token: existingAccessToken.token,
            mfaRequired: existingAccessToken.mfaRequired,
            partnerOverride: existingAccessToken.partnerOverride,
            scope: existingAccessToken.scope,
            tokenType: existingAccessToken.tokenType,
            user: {id: existingAccessToken.user.id}
        };
        delete accessToken.scope;
        cookie.remove('existingAccessToken');
        cookie.save('accessToken', accessToken, {path: '/'});
        GridDuck.setAccessToken({
            accessToken
        });
        history.push("/")
        window.location.reload();
    }

    render() {
        return (<div className={'LoggedInAsBar'}>
            <div>Viewing as client "{this.state.account?.organisationName}"</div>
            <Button onClick={this.revertToNonPartnerLogin} additionalclasses={'white-outline'} outline label={'Exit Client View'}/>
        </div>)
    }

}

export default LoggedInAsBar;