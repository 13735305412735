import React from "react";
import "./Reports.scss";
import getFormat from "../../services/formatter";
import {Icon} from "gd-react";

class ReportListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            updated: 1
        }
        this.updatedFromAbove = this.updatedFromAbove.bind(this);

        this.props.item.on('updated', this.updatedFromAbove);
    }

    updatedFromAbove() {
        this.state.updated++;
        this.setState(this.state);
    }

    componentWillUnmount() {
        if (this.props.item && this.props.item.off) {
            this.props.item.off('updated', this.updatedFromAbove);
        }
    }

    render() {
        let self = this;
        return (
            <div key={this.props.item.id + 'r'} className={'report-list-item'}
                 onClick={() => self.props.onClick(this.props.item)}>
                <div>
                    <p className={'name'}>{this.props.item.name}</p>
                    <p className={'state'}><span
                        style={{
                            color: this.props.item.active ? '#6ec455' : '#e45457',
                            fontWeight: '700'
                        }}>{this.props.item.active ? 'Active' : 'Inactive'}</span> - <span>{getFormat('titleCase')(this.props.item.frequency)}</span>
                    </p>
                </div>
                <div className={'right-arrow'}>
                    <Icon color={'grey'} size={'13'} icon={'FaChevronRight'}/></div>
            </div>
        )
    }
}

export default ReportListItem;
