import React from "react";
import {
    Button,
    List,
    StandardListCell,
    Icon
} from "gd-react";
import GridDuck from 'gridduck';
import './dataUpload.scss'
import history from "../../meta/history";
import CreateDataCategory from "./CreateDataCategory";

let pagePath;

class DataCategoryListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDataCategoryModal: null
        };
        this.getList = this.getList.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        pagePath = window.location.pathname + window.location.search;
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (user) {
                self.setState({user: user});
            });
        let urlParams = new URLSearchParams(window.location.search);
        let id = urlParams.get('id');
        if (id) {
            await this.loadData(id);
        }
    }

    async loadData(id) {
        let filters = [{
            field: 'id',
            value: id
        }]
        let res = await GridDuck.getDataCategorys({filters: filters})
        let item = res.list[0];
        this.setState({showDataCategoryModal: item});
        // let newUrl = pagePath.replace(/([&?])id=[^&]*&?/, '$1').replace(/&$/, '').replace(/\?$/, '');
        // history.push(newUrl + '?id=' + item.id);
    }

    async getList(params) {
        return GridDuck.getDataCategorys(params);
    }

    render() {
        return (
            this.state.user ? <div className={"page device-category"}>
                <div className={'widget-board-header'}>
                    <div className={'row reverse place-in-header'}>
                        {this.state.user.orgPermission !== 'view' ?
                            <Button color={'gd-green'} label={'+ Add New Data Category'}
                                    onClick={() => this.setState({
                                        showDataCategoryModal: true
                                    })}/> : null}
                    </div>
                </div>
                <List
                    noitemelem={<p>No Data Uploads</p>}
                    columns={[
                        {
                            component: StandardListCell,
                            title: 'Icon',
                            field: 'icon',
                            maxWidth: '60px',
                            custom: function (item) {
                                return <div><Icon size={'14'} icon={item.icon || 'FaCog'} color={item.color}/>
                                </div>
                            }
                        },
                        {
                            component: StandardListCell,
                            title: 'Name',
                            field: 'name',
                            maxWidth: '250px'
                        },
                        {
                            component: StandardListCell,
                            custom: function (item) {
                                return item._permission
                            },
                            title: 'Permission',
                            maxWidth: 'none'
                        }]}
                    getlist={this.getList}
                    rowOnClick={(item) => {
                        this.setState({showDataCategoryModal: item})
                        // let newUrl = pagePath.replace(/([&?])id=[^&]*&?/, '$1').replace(/&$/, '').replace(/\?$/, '');
                        // history.push(newUrl + '?id=' + item.id);
                    }}
                    filters={[]}/>

                {this.state.showDataCategoryModal ?
                    <CreateDataCategory isGod={this.state.user.god}
                                        orgPerm={this.state.user.orgPermission}
                                        item={this.state.showDataCategoryModal}
                                        reloadData={this.loadData}
                                        onClose={() => {
                                            this.setState({
                                                showDataCategoryModal: null
                                            })
                                            // let newUrl = pagePath.replace(/([&?])id=[^&]*&?/, '$1').replace(/&$/, '').replace(/\?$/, '');
                                            // newUrl = newUrl.replace(/([&?])datatypeid=[^&]*&?/, '$1').replace(/&$/, '').replace(/\?$/, '');
                                            // history.push(newUrl);
                                        }}
                                        open={this.state.showDataCategoryModal}/> : null}
            </div> : null
        )
    }
}

export default DataCategoryListPage;