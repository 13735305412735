import GenerateExampleUnit from "../../services/GenerateExampleUnit";
import React, {Component} from "react";
import "./dataUpload.scss";
import '../../styles/_layout.scss';
import {
    Button,
    CardBody,
    Input,
    Tabs,
    Dropdown,
    GdAutocomplete
} from "gd-react";
import GridDuck from "gridduck";
import GdModal from "../../components/GdModal/GdModal";
import DeleteModal from "../../modals/Delete/DeleteModal";
import CreateUnit from "./CreateUnit";
import {camelfy} from "../../services/CamelfyRequestData";
import GenericLoader from "../../components/GenericLoader";
import CreateDataCategory from "./CreateDataCategory";

const dataTypes = [{
    id: 1,
    title: <span>Aggregate <span style={{color: 'grey'}}>- E.g. Wh, items produced, total sales...</span></span>,
    value: 'aggregate'
}, {
    id: 2,
    title: <span>Instantaneous <span
        style={{color: 'grey'}}>- E.g. °C, W, Pa...</span></span>,
    value: 'instantaneous'
}, {
    id: 2,
    title: <span>Rate <span
        style={{color: 'grey'}}>- E.g. miles/hour, cost/item produced, m3/time...</span></span>,
    value: 'rate'
}];

class CreateDataType extends Component {

    constructor(props) {
        super(props);
        this.errors = {};
        this.changed = false;
        ['onFormChange', 'getDataUnits', 'closeModal', 'save', 'delete', 'onTabClick', 'checkErrors', 'getDataCategories'].forEach(func => this[func] = this[func].bind(this));
        let dt = this.props.item?.dataType, _dt = dt;
        if (this.props.item?.applyRate) _dt = 'rate';
        this.state = {
            name: this.props.item?.name || '',
            _dataType: _dt || 'aggregate',
            dataType: dt || 'aggregate',
            unitId: this.props.item?.unitId || '',
            unit: null,
            applyRate: this.props.item?.applyRate || false,
            rateUnitId: this.props.item?.rateUnitId || '',
            dataCategory: null,
            categoryId: this.props.categoryId,
            rateUnit: null,
            loaded: false,
            hasChanged: false,
            defaultRateMultiple: this.props.item?.defaultRateMultiple || 1,
            defaultMultiple: this.props.item?.defaultMultiple || 1,
            datasChanged: false,
            anchorEl: null,
            tabs: [[
                {
                    id: 1,
                    title: 'General',
                    selected: (this.props.openOnTab === 1 || !this.props.openOnTab),
                    onTabClick: this.onTabClick
                }
            ]]
        }

        if (this.props.item?._permission && this.props.item._permission !== 'view') {
            this.state.tabs[0][1] = {
                id: 2,
                title: 'Delete',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 2)
            }
        }
        let self = this;
        let existingUnitPromises = [];
        this.modalClosed = false;
        if (this.props.item?.unitId) {
            existingUnitPromises.push(camelfy((...args) => GridDuck.getDataUnit(...args), {id: self.props.item?.unitId}));
        }
        if (this.props.item?.rateUnitId) {
            existingUnitPromises.push(camelfy((...args) => GridDuck.getDataUnit(...args), {id: self.props.item?.rateUnitId}));
        }

        if (existingUnitPromises.length) {
            Promise.all(existingUnitPromises).then((res) => {
                let unit = res[0];
                let rateUnit = res[1];
                self.setState({
                    unit: unit ? {
                        id: unit.id,
                        unitCategory: unit.unitCategory || 'Custom',
                        dataType: unit.dataType,
                        unitType: unit.unitType,
                        metaData: unit.metaData,
                        title: unit.name,
                        subtitle: GenerateExampleUnit(unit)
                    } : null,
                    rateUnit: rateUnit ? {
                        id: rateUnit.id,
                        unitCategory: rateUnit.unitCategory || 'Custom',
                        dataType: rateUnit.dataType,
                        metaData: rateUnit.metaData,
                        unitType: rateUnit.unitType,
                        title: rateUnit.name,
                        subtitle: GenerateExampleUnit(rateUnit)
                    } : null,
                    loaded: true
                });
            })
        } else {
            this.state.loaded = true;
        }

    }

    onFormChange(val) {
        this.state[val.target.name] = val.target.value;
        // Scenarios in which to clear the unit data type
        let scenario_one = this.state._dataType === 'rate' && this.state.unit?.dataType === 'instantaneous';
        let scenario_two = this.state._dataType === 'instantaneous' && this.state.unit?.dataType === 'aggregate';
        let scenario_three = this.state._dataType === 'aggregate' && this.state.unit?.dataType === 'instantaneous';
        if (scenario_one || scenario_two || scenario_three) {
            this.state.unit = null;
        }
        if (this.state._dataType === 'rate') {
            this.state.dataType = 'instantaneous';
            this.state.applyRate = true;
        } else {
            this.state.dataType = this.state._dataType;
            this.state.applyRate = false;
        }

        this.state.hasChanged = true;
        this.setState({...this.state});
    }

    delete() {
        let self = this;
        return this.props.item.delete().then(function () {
            if (self.props.onDelete) self.props.onDelete();
        })
    }

    onTabClick(ev, tab) {
        this.state.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    async save() {
        this.setState({triedToSave: true});
        if (Object.keys(this.errors).length > 0) {
            return Promise.reject()
        }

        let dataType = {
            name: this.state.name,
            dataType: this.state.dataType,
            unitId: this.state.unitId,
            applyRate: this.state.applyRate,
            defaultRateMultiple: this.state.defaultRateMultiple,
            rateUnitId: this.state.applyRate ? this.state.rateUnitId : null,
            categoryId: this.props.categoryId || this.state.categoryId
        }, res;

        if (this.props.item?.id) {
            res = this.props.item.set(dataType)
        } else {
            res = GridDuck.createDataType(dataType)
        }
        await res.then((res) => {
            if (res?.name && this.props.setNewDataType) {
                this.props.setNewDataType(res.name)
            }
            setTimeout(() => this.closeModal(res), 1000)
        })
    }

    checkErrors() {
        let errors = {};
        if (!this.state.name) errors['name'] = true;
        if (!this.state.dataType) errors['dataType'] = true;
        if (!this.state.unitId) errors['unitId'] = true;
        if (!this.state.categoryId) errors['categoryId'] = true;
        if (this.state.applyRate && !this.state.rateUnitId) errors['rateUnitId'] = true;
        this.errors = errors;
    }

    closeModal(item) {
        this.modalClosed = true;
        this.props.onClose(item);
    }

    async getDataUnits(searchTerm, reset) {
        let self = this

        if (self.total && self.total <= self.units?.length && !reset) {
            return Promise.resolve([]);
        }

        let unitsRes = await camelfy((...args) => GridDuck.getDataUnits(...args), {});

        let units = unitsRes.list
            .map((s) => {
                return {
                    id: s.id,
                    unitCategory: s.unitCategory,
                    dataType: s.dataType,
                    metaData: s.metaData,
                    unitType: s.unitType,
                    title: s.name,
                    subtitle: GenerateExampleUnit(s)
                }
            }).filter(u => searchTerm ? (u.title.toLowerCase().startsWith(searchTerm.toLowerCase()) || u.subtitle.toLowerCase().includes(searchTerm.toLowerCase())) : true)
            .filter(u => (!this.state.applyRate && u.dataType === self.state.dataType) || (this.state.applyRate && u.dataType === 'aggregate'))

        self.total = units.length;
        self.units = units;
        return Promise.resolve(units)
    }

    async getDataCategories(searchTerm, reset) {
        let filters = [];
        if (this.categories && this.categoryCount && this.categories.length === this.categoryCount && !reset) {
            return Promise.resolve([]);
        }
        if (searchTerm) filters.push({
            field: 'search',
            value: searchTerm
        });
        let itemsRes = await GridDuck.getDataCategorys({getAll: true, filters: filters});
        this.categoryCount = itemsRes.total;
        let items = itemsRes.list
            .map((s) => {
                return {
                    id: s.id,
                    title: s.name,
                    icon: s.icon,
                    color: s.color
                }
            }).filter(u => searchTerm ? (u.title.toLowerCase().startsWith(searchTerm.toLowerCase())) : true)
        this.categories = items;

        return Promise.resolve(items);
    }

    render() {
        let self = this, footer, existingCategory = this.props.item?.id, viewOnly = false;
        let autoCompletes = [
            {
                label: "Unit System",
                name: 'unitId',
                value: 'unit',
                onChange: (unit, unitObj) => {
                    this.setState({
                        unit: unitObj,
                        unitId: unitObj?.id,
                        hasChanged: true,
                        loaded: true
                    });
                },
                onAddStart: () => {
                    this.setState({createUnit: true, unit: null, unitId: null});
                }
            },
            {
                label: "Rate Unit System",
                name: 'rateUnitId',
                value: 'rateUnit',
                onChange: (unit, unitObj) => {
                    this.setState({
                        rateUnit: unitObj,
                        rateUnitId: unitObj?.id,
                        defaultRateMultiple: 1,
                        hasChanged: true,
                        loaded: true
                    });
                },
                onAddStart: () => {
                    this.setState({createRateUnit: true, rateUnit: null, rateUnitId: null});
                }
            }
        ]
        if (existingCategory && this.props.item._permission === 'view') {
            viewOnly = true
        }
        let UnitAutoCompletes = autoCompletes.map(ac => {
            return <GdAutocomplete
                async
                lazyload
                icon
                disabled={viewOnly}
                subtitle
                groupBy={(i) => i.unitCategory}
                showGroupHeading
                fixedOptions={[
                    {
                        id: 'add_new',
                        title: 'Create Unit System',
                        subtitle: 'E.g. m², kWh, %',
                        type: 'New',
                        icon: 'FaPlus',
                        customFunction: ac.onAddStart
                    }
                ]}
                getList={self.getDataUnits}
                name={ac.name}
                getOptionSelected={function (option, value) {
                    if (option && value) return option.id === value.id;
                }}
                value={this.state[ac.value]}
                onChange={ac.onChange}
                placeholder={"E.g. m², kWh, %"}
                label={ac.label}
            />
        })
        this.checkErrors();

        if (this.state.hasChanged || !existingCategory) footer =
            <div><Button label={existingCategory ? 'Save' : 'Create'}
                         progressRes
                         additionalclasses={'sm'}
                         onClick={this.save}/></div>

        let defaultRateUnits;
        if (this.state.rateUnit) {
            let exampleArray = GenerateExampleUnit(this.state.rateUnit, {array: true});
            if (exampleArray && exampleArray.length && exampleArray[0].label && exampleArray[0].value) {
                defaultRateUnits =
                    exampleArray.map(dru => {
                        return {
                            id: dru.label,
                            title: dru.label,
                            value: dru.value
                        }
                    });
            }
        }
        let defaultUnits;
        if (this.state.rateUnit) {
            let UnitExampleArray = GenerateExampleUnit(this.state.unit, {array: true});
            if (UnitExampleArray && UnitExampleArray.length && UnitExampleArray[0].label && UnitExampleArray[0].value) {
                defaultUnits =
                    UnitExampleArray.map(dru => {
                        return {
                            id: dru.label,
                            title: dru.label,
                            value: dru.value
                        }
                    });
            }
        }

        return (
            <GdModal
                title={existingCategory ? `${this.props.item.name}` : `Create Data Type`}
                open={this.props.open}
                footer={footer}
                onClose={this.closeModal}>
                {this.state.tabs ? <Tabs tabData={this.state.tabs}/> : null}
                {!existingCategory && <div className={'modal-info'}>
                    Data types belongs to a data category and defines a specific data format. E.g. Indoor
                    Temperature, Outdoor Temperature, Wind Speed
                </div>}
                {this.state.loaded ? this.state.tabs[0][0].selected ?
                    <CardBody>
                        <div
                            className={'Add-Edit-Custom-Field ' + (this.errors['icon'] && this.state.triedToSave ? 'error' : '')}>

                            <div className={'row'}>
                                <Input required
                                       error={this.errors['name'] && this.state.triedToSave}
                                       top='true'
                                       placeholder={'E.g. Indoor Temperature'}
                                       disabled={viewOnly}
                                       onChange={(e) => this.setState({
                                           name: e.target.value,
                                           hasChanged: true
                                       })}
                                       name={'name'}
                                       value={this.state.name}
                                       label={'Name'}
                                />
                            </div>
                            {!this.props.categoryId ? <div className={'row'} style={{marginTop: '15px'}}>
                                <GdAutocomplete
                                    async
                                    icon
                                    lazyload
                                    error={this.errors['categoryId'] && this.state.triedToSave}
                                    getList={this.getDataCategories}
                                    fixedOptions={[
                                        {
                                            id: 'add_new',
                                            title: 'Create Category',
                                            type: 'New',
                                            icon: 'FaPlus',
                                            customFunction: () => {
                                                this.setState({showDataCategoryModal: true})
                                            }
                                        }
                                    ]}
                                    name={'categoryId'}
                                    getOptionSelected={function (option, value) {
                                        if (option && value) return option.id === value.id;
                                    }}
                                    label={'Category'}
                                    value={this.state.dataCategory}
                                    placeholder={'E.g. Electricity, Gas, Water...'}
                                    onChange={(e, val) => {
                                        this.setState({dataCategory: val, categoryId: val.id});
                                    }}/>
                            </div> : null}
                            <div className={'row'} style={{marginTop: '15px'}}>
                                <Dropdown placeholder={"E.g. m², kWh, %"} label={"Type"}
                                          fixeditems={dataTypes}
                                          disabled={viewOnly}
                                          name={'_dataType'}
                                          value={this.state._dataType}
                                          onChange={(val) => this.onFormChange({
                                              target: {
                                                  name: '_dataType',
                                                  value: val.target.value
                                              }
                                          })}/>
                            </div>
                            <div className={'row'} style={{marginTop: '15px'}}>
                                {UnitAutoCompletes[0]}
                            </div>
                            {defaultUnits && this.state.unit &&
                                <div className={'row'} style={{marginTop: '15px'}}>
                                    <Dropdown label={"Default Unit"}
                                              fixeditems={defaultUnits}
                                              disabled={viewOnly}
                                              name={'defaultMultiple'}
                                              value={this.state.defaultMultiple}
                                              onChange={(val) => this.onFormChange({
                                                  target: {
                                                      name: 'defaultMultiple',
                                                      value: val.target.value
                                                  }
                                              })}/>
                                </div>
                            }
                            {this.state.applyRate &&
                                <div className={'row'} style={{marginTop: '15px'}}>
                                    {UnitAutoCompletes[1]}
                                </div>
                            }

                            {defaultRateUnits && this.state.rateUnit && this.state._dataType === 'rate' &&
                                <div className={'row'} style={{marginTop: '15px'}}>
                                    <Dropdown label={"Default Rate Unit"}
                                              fixeditems={defaultRateUnits}
                                              disabled={viewOnly}
                                              name={'defaultRateMultiple'}
                                              value={this.state.defaultRateMultiple}
                                              onChange={(val) => this.onFormChange({
                                                  target: {
                                                      name: 'defaultRateMultiple',
                                                      value: val.target.value
                                                  }
                                              })}/>
                                </div>
                            }

                        </div>
                    </CardBody> : null : <CardBody><GenericLoader/></CardBody>}
                {this.state.createUnit || this.state.createRateUnit ?
                    <CreateUnit isGod={this.props.user?.god}
                                orgPerm={this.props.user?.orgPermission}
                                rateUnit={this.state.createRateUnit}
                                limitDataType={this.state._dataType === 'instantaneous' ? 'instantaneous' : 'aggregate'}
                                onClose={(created) => {
                                    let stateObj = {};
                                    if (created) {
                                        let unitObj = {
                                            id: created.id,
                                            title: created.name,
                                            subtitle: GenerateExampleUnit(created)
                                        };
                                        if (created.rateUnit) {
                                            stateObj = {
                                                rateUnit: unitObj,
                                                rateUnitId: created.id
                                            }
                                        } else {
                                            stateObj = {
                                                unit: unitObj,
                                                unitId: created.id
                                            }
                                        }
                                    }
                                    this.setState(stateObj, () => {
                                        this.setState({createUnit: false, createRateUnit: false, hasChanged: true});
                                    });
                                }}
                                open={this.state.createRateUnit || this.state.createUnit}/> : null}
                {this.state.showDataCategoryModal ?
                    <CreateDataCategory createCategoryOnly
                                        reloadData={async () => {
                                        }}
                                        onClose={(item) => {
                                            let updateObj = {
                                                showDataCategoryModal: null
                                            };
                                            if (item) {
                                                updateObj.dataCategory = {
                                                    id: item.id,
                                                    title: item.name,
                                                    icon: item.icon,
                                                    color: item.color
                                                };
                                                updateObj.categoryId = item.id;
                                            }
                                            this.setState(updateObj);
                                        }}
                                        open={this.state.showDataCategoryModal}/> : null}
                {this.state.tabs && this.state.tabs[0][1] && this.state.tabs[0][1].selected ? <CardBody>
                    <DeleteModal itemType={'DataType'}
                                 item={this.props.item}
                                 open={this.state.tabs[0][1].selected}
                                 deleteRes={this.closeModal}
                                 onClose={(e) => {
                                     this.onTabClick(e, {id: 1})
                                 }}/>
                </CardBody> : null}
            </GdModal>
        )
    }
}

export default CreateDataType;