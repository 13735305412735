import React from "react";
import "./AssetOverviewPage.scss";
import {
    Button,
    Card,
    CardBody,
    CardHeader, DemandListCell,
    Dropdown,
    Input,
    List,
    StateOptions,
    StandardListCell,
    StateListCell,
    StateSelectMenu
} from "gd-react";
import getFormat from "../../services/formatter";
import GridDuck from "gridduck";
import history from "../../meta/history";
import RulePageTimeswitch from "../rule/RulePageTimeswitch";
import RulePageAlert from "../rule/RulePageAlert";
import RulePageThermostat from "../rule/RulePageThermostat";

const toCamel = (s) => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
};

function skuDetails(sku) {
    let type = StateOptions.find(sO => sO.type === sku);
    if (type) {
        return {
            name: type.label,
            image: '/images/products/' + sku + '.png'
        }
    } else {
        return {
            name: 'Unknown',
            image: '/images/products/unknown.png'
        }
    }
}

class AssetOverviewPage extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        this.updatedExternally = this.updatedExternally.bind(this);
        this.goToAsset = this.goToAsset.bind(this);
        this.getList = this.getList.bind(this);
        this.identify = this.identify.bind(this);
        this.getPhaseBreakdown = this.getPhaseBreakdown.bind(this);
        if (props.item) {
            props.item.on('updated', this.updatedExternally);
            if (props.item.triggerRules && props.item.alertRules && props.item.advancedRules) {
                Promise.all(props.item.triggerRules.concat(props.item.alertRules).concat(props.item.advancedRules).map(rId => GridDuck.getRule({id: rId})))
                    .then(function (rules) {
                        self.setState({rules: rules});
                    });
            }
            if (props.item.parentAsset) {
                GridDuck.getAsset({id: props.item.parentAsset}).then(function (parentAsset) {
                    self.setState({parentName: parentAsset.name});
                });
            }
            this.state = {
                updateRef: 0,
                asset: props.item,
                childrenFilters: [
                    {
                        field: 'parentAsset',
                        value: this.props.item.id
                    }
                ]
            };
        } else {
            this.state = {updateRef: 0};
        }
        GridDuck.getAccount({id: GridDuck.userId}).then(function (account) {
            self.setState({
                orgPermission: account.orgPermission,
            })
        });

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        let self = this;
        if (prevProps !== this.props || this.props.item.id !== prevProps.item.id) {
            if (prevProps.item) {
                prevProps.item.off('updated', this.updatedExternally);
            }
            this.setState({
                asset: this.props.item,
                childrenFilters: [
                    {
                        field: 'parentAsset',
                        value: this.props.item.id
                    }
                ]
            });
            this.props.item.on('updated', this.updatedExternally);
            if (this.props.item.parentAsset) {
                GridDuck.getAsset({id: this.props.item.parentAsset}).then(function (parentAsset) {
                    self.setState({parentName: parentAsset.name});
                });
            } else {
                self.setState({parentName: null});
            }
        }
    }

    componentDidMount() {
        let self = this
        this.updateInterval = setInterval(() => this.setState({updateRef: this.state.updateRef + 1}), 60000);
        if (this.props.item.modbusId) {
            GridDuck.getModbusModel({id: this.props.item.modbusId}).then((res) => {
                    self.setState({modbusData: res})
                }
            )
        }
    }


    componentWillUnmount() {
        if (this.props.item && this.props.item.off) {
            this.props.item.off('updated', this.updatedExternally);
        }
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
    }

    updatedExternally(field) {
        let self = this;
        const allowedFields = [
            'name',
            'sku',
            'code',
            'demand',
            'consumption',
            'signalStrength',
            'lastContacted',
            'demandPhaseOne',
            'demandPhaseTwo',
            'demandPhaseThree',
            'pfPhaseOne',
            'pfPhaseTwo',
            'pfPhaseThree'
        ]
        if (allowedFields.indexOf(field) !== -1) {
            if (field === 'demandPhaseOne') {
                if (self.getBreakdownResolve) {
                    self.getBreakdownResolve();
                }
                this.setState({gettingPhaseOne: false});
            }
            if (field === 'demandPhaseTwo') {
                if (self.getBreakdownResolve) {
                    self.getBreakdownResolve();
                }
                this.setState({gettingPhaseTwo: false});
            }
            if (field === 'demandPhaseThree') {
                if (self.getBreakdownResolve) {
                    self.getBreakdownResolve();
                }
                this.setState({gettingPhaseThree: false});
            }
            if (field === 'pfPhaseOne') {
                if (Math.abs(this.props.item.demandPhaseOne) >= 5) {
                    if (self.getBreakdownResolve) {
                        self.getBreakdownResolve();
                    }
                    this.setState({gettingPfOne: false});
                }
            }
            if (field === 'pfPhaseTwo') {
                if (Math.abs(this.props.item.demandPhaseTwo) >= 5) {
                    if (self.getBreakdownResolve) {
                        self.getBreakdownResolve();
                    }
                    this.setState({gettingPfTwo: false});
                }
            }
            if (field === 'pfPhaseThree') {
                if (Math.abs(this.props.item.demandPhaseThree) >= 5) {
                    if (self.getBreakdownResolve) {
                        self.getBreakdownResolve();
                    }
                    this.setState({gettingPfThree: false});
                }
            }
            this.setState({updateRef: this.state.updateRef++});
        }
    }

    goToAsset(asset) {
        history.push("/device/" + asset.id);
    }

    getList(retrievalParams) {
        return GridDuck.getAssets(retrievalParams);
    }

    identify() {
        return GridDuck.createAssetIdentification({assetId: this.state.asset.id})
    }

    getPhaseBreakdown() {
        let self = this;
        this.setState({
            gettingPhaseOne: true,
            gettingPhaseTwo: true,
            gettingPhaseThree: true,
            gettingPfOne: true,
            gettingPfTwo: true,
            gettingPfThree: true
        });
        self.resolved = false;
        return GridDuck.createGetPhaseBreakdown({assetId: this.state.asset.id})
            .then(function () {
                return new Promise(function (resolve, reject) {
                    self.getBreakdownResolve = function () {
                        self.resolved = true;
                        resolve();
                    }
                    setTimeout(function () {
                        if (!self.resolved) {
                            reject();
                        }
                    }, 10000)
                });
            })
    }
}

export default AssetOverviewPage;
