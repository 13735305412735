import React from "react";
import './TimeBar.scss'

class TimeBarSegment extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {

        return (
            <div className={'segment ' + (this.props.active ? 'active' : '')}/>
        )
    }
}

export default TimeBarSegment;