import GridDuck from "gridduck";
const RUN_AT_ONCE = 3;

class FileUploadQueue {
	constructor(fileManager) {
		this.inQueue = [];
		this.uploading = [];
		this.fileManager = fileManager;
	}

	startUpload(newFile) {
		let self = this;
		this.uploading.push(newFile);
		newFile.status = 'UPLOADING';
		GridDuck.createFile(newFile, function uploadProgress(progressEvt) {
			self.fileManager.setState(function(state) {
				let wantedFile = state.uploadingFiles.find(f => f.ref === newFile.ref);
				wantedFile.uploadProgress = Math.round((progressEvt.loaded / newFile.size)*100);
				return state;
			})
		}).then(function (file) {
			console.log("Index to remove", self.uploading.indexOf(newFile));
			self.uploading.splice(self.uploading.indexOf(newFile),1);
			self.fileManager.setState(state => {
				let wantedFile = state.uploadingFiles.find(f => f.ref === newFile.ref);
				state.uploadingFiles.splice(state.uploadingFiles.indexOf(wantedFile),1);
				return state;
			});
			self.processFromQueue();
		});
	}

	upload(file) {
		if (this.uploading.length < RUN_AT_ONCE) {
			this.startUpload(file);
		} else {
			file.status = 'QUEUED';
			this.inQueue.push(file);
		}
	}

	processFromQueue() {
		console.log("Process from queue, uploading: "+ this.uploading.length + " queue length: " + this.inQueue.length);
		let filesToDownload = Math.min(RUN_AT_ONCE - this.uploading.length, this.inQueue.length);
		if (filesToDownload > 0) {
			for (let i = 0; i < filesToDownload; i++) {
				let newFile = this.inQueue.shift();
				this.startUpload(newFile);
			}
		}
	}
}

export default FileUploadQueue;
