import React from "react";
import "../asset/AssetOverviewPage.scss";
import {Button, Card, CardBody, CardHeader, StateSelectMenu, Status} from "gd-react";
import getFormat from "../../services/formatter";
import history from "../../meta/history";
import GridDuck from "gridduck";
import IssuePopover from "../../components/IssuePopover/IssuePopover";

class GatewayOverviewPage extends React.Component {

    constructor(props) {
        super(props);
        let self = this;
        this.updatedExternally = this.updatedExternally.bind(this);
        if (props.item) {
            props.item.on('updated', this.updatedExternally);
        }
        this.state = {updateRef: 0};
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function(account) {
                self.setState({account: account});
            });
    }

    updatedExternally(field) {
        console.log(this.props.item, ' : updated');
        this.setState({updateRef: this.state.updateRef++});
    }

    componentDidMount() {
        this.updateInterval = setInterval(() => this.setState({updateRef: this.state.updateRef + 1}), 60000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let self = this;
        console.log(this.props.item, ' : item');
        if (prevProps !== this.props || this.props.item.id !== prevProps.item.id) {
            if (prevProps.item) {
                prevProps.item.off('updated', this.updatedExternally);
            }
            this.props.item.on('updated', this.updatedExternally);
        }
    }

    componentWillUnmount() {
        if (this.props.item && this.props.item.off) {
            this.props.item.off('updated', this.updatedExternally);
        }
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
    }

    render() {
        let summary;
        let realTime;
        if (this.props.item || this.props.virtualGateway) {
            console.log(this.props.item, ' gateway in props');
            if (this.props.item) {
                let imageStyle = {
                    backgroundImage: `url('/images/products/GAT-COM.png')`
                }
                summary = (<div className={"top-summary"}>
                    <div className={"left-image"} style={imageStyle}>
                    </div>
                    <div className={"right-summary"}>
                        <p className={"asset-data"}><span
                            className={"heading"}>Name:&nbsp;</span> {this.props.item.name}</p>
                        {this.props.virtualGateway ? <p className={"asset-data"}><span
                            className={"heading"}>Virtually Generated from:&nbsp;</span> <a style={{cursor: 'pointer'}}
                                                                                            onClick={() => history.push('/hub/' + this.props.virtualGateway?.id)}>{this.props.virtualGateway.name}</a>
                        </p> : null}
                        <p className={"asset-data"}><span
                            className={"heading"}>Serial Number:&nbsp;</span> {this.props.item?.serialNumber || this.props.virtualGateway?.serialNumber}
                        </p>
                        {this.props.item && this.props.item.modelNumber ? <p className={"asset-data"}><span
                            className={"heading"}>Model Number:&nbsp;</span> {this.props.item?.modelNumber || this.props.virtualGateway?.modelNumber}
                        </p> : null}
                        <p className={"asset-data"}><span
                            className={"heading"}>Ethernet MAC:&nbsp;</span> {this.props.item?.ethernetMac || this.props.virtualGateway?.ethernetMac}
                        </p>
                        <p className={"asset-data"}><span
                            className={"heading"}>WLAN MAC:&nbsp;</span> {this.props.item?.wlanMac || this.props.virtualGateway?.wlanMac}
                        </p>
                        <p className={"asset-data"}><span className={"heading"}>ID:&nbsp;</span> {this.props.item.id}
                        </p>
                    </div>
                </div>);
                let imsiLink;
                if (this.state.account && this.state.account.god && this.props.item.imsi) {
                    if (this.props.item.imsi.substr(0, 3) === '295' || this.props.item.imsi.substr(0, 3) === '206') {
                        let fixedImsi = this.props.item.imsi;
                        if (fixedImsi.substr(0, 5) === '20601') {
                            fixedImsi = '2950900013' + fixedImsi.substr(10)
                        }
                        imsiLink = (<span> (<a
                            href={"https://portal.emnify.com/sim-inventory?perPage=5&page=1&search=imsi%3A" + fixedImsi}
                            target={"_blank"}>Emnify</a>)</span>)
                    } else {
                        imsiLink = (<span> (<a href={"https://simpro.wirelesslogic.com/sims"} target={"_blank"}>Wireless Logic</a>)</span>)
                    }
                }
                realTime = (
                    <Card className={"real-time"}>
                        <CardHeader title={"Real-time info"}/>
                        <CardBody>
                            <div className={'row'} style={{
                                height: '42px',
                                alignItems: 'center',
                                marginBottom: '20px'
                            }}><p style={{fontWeight: '700', margin: 'none'}}><Status
                                endAdornment={()=><IssuePopover
                                    updateDisconnectedDrawer={this.props.updateFunctions.updateDisconnectedDrawer}
                                    item={this.props.item}/>}
                                value={this.props.item.disconnected} object={this.props.item}/></p></div>
                            {this.props.item.activeConnection ? (<p className={"asset-data"}><span
                                className={"heading"}>Connected via:&nbsp;</span>{this.props.item.activeConnection.charAt(0).toUpperCase() + this.props.item.activeConnection.slice(1)}
                            </p>) : ''}
                            {this.props.item.mobileStrength ? (
                                <p className={"asset-data"}><span
                                    className={"heading"}>Mobile signal quality:&nbsp;</span>{getFormat('mobileSignalStrength')(this.props.item.mobileStrength)}
                                </p>) : ''}
                            {this.props.item.wifiStrength ? (
                                <p className={"asset-data"}><span
                                    className={"heading"}>WiFi signal strength:&nbsp;</span>{getFormat('signal', false, 'Wifi')(this.props.item.wifiStrength)}
                                </p>) : ''}
                            {this.props.item.powerSource ? (<p className={"asset-data"}><span className={"heading"}>Power source:&nbsp;</span>{this.props.item.powerSource.charAt(0).toUpperCase() + this.props.item.powerSource.slice(1)}
                            </p>) : ''}
                            {this.props.item.ipAddress ? (<p className={"asset-data"}><span className={"heading"}>IP address:&nbsp;</span>{this.props.item.ipAddress.substr(0, this.props.item.ipAddress.indexOf(','))}
                            </p>) : ''}
                            {this.props.item.imsi ? (
                                <p className={"asset-data"}><span
                                    className={"heading"}>IMSI:&nbsp;</span>{this.props.item.imsi}
                                    {imsiLink}
                                </p>) : ''}
                            {this.props.item.lastContacted ? (
                                <p className={"asset-data"}><span
                                    className={"heading"}>Last contacted:&nbsp;</span>{getFormat('lastContacted')(this.props.item.lastContacted)}
                                </p>) : ''}
                        </CardBody>
                    </Card>
                )
            }
            return (
                <div className={"overview-asset page narrow not-flex grey"}>
                    <div className={'column top'}>
                        <div className={'detail-content'}>
                            {summary}
                        </div>
                    </div>
                    <div className={'column'}>
                        <div className={'detail-content'}>
                            {realTime}
                        </div>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }
}

export default GatewayOverviewPage;
