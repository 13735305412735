import React from "react";
import FileUploadDropper from "./FileUploadDropper";
import "./FileManager.scss"
import {v4 as uuidv4} from 'uuid';
import GridDuck from "gridduck";
import FileList from "./FileList";
import FileSummary from "./FileSummary";
import Modal from "react-modal";
import {Button, Card, CardBody, CardHeader} from "gd-react";
import FileManagerFilterBar from "./FileManagerFilterBar";
import FileUploadQueue from "./FileUploadQueue";
import {confirmAlert} from 'react-confirm-alert';
import GenericLoader from "../GenericLoader";
import GdModal from "../GdModal/GdModal";

class FileManager extends React.Component {
	constructor(props) {
		super(props);
		let self = this;
		this.keydown = this.keyDown.bind(this);
		this.downloadFiles = this.downloadFiles.bind(this);
		this.selectFile = this.selectFile.bind(this);
		this.selectFiles = this.selectFiles.bind(this);
		this.clearSelection = this.clearSelection.bind(this);
		this.blurredInput = this.blurredInput.bind(this);
		this.focusedInput = this.focusedInput.bind(this);
		this.openFile = this.openFile.bind(this);
		this.hideFile = this.hideFile.bind(this);
		this.onChangeFilterText = this.onChangeFilterText.bind(this);
		this.onChangeFilterType = this.onChangeFilterType.bind(this);
		this.updateFilterResults = this.updateFilterResults.bind(this);
		this.deleteFiles = this.deleteFiles.bind(this);
		this.fileListUpdated = this.fileListUpdated.bind(this);
		this.getFiles = this.getFiles.bind(this);

		this.nameInputRef = React.createRef();
		this.descInputRef = React.createRef();


		this.fileUploadQueue = new FileUploadQueue(this);

		this.state = {
			files: [],
			selectedFiles: [],
			uploadingFiles: [],
			previewFile: false,
			loaded: false,
			inputFocused: false
		}
		let filters = [
			{
				field: 'itemType',
				value: this.props.itemType
			},
			{
				field: 'itemId',
				value: this.props.itemId
			},
		];
		GridDuck.getFiles({
			filters: filters
		}).then(function (files) {
			files.on('updated', self.fileListUpdated);
			self.setState({
				files: files.list,
				allFiles: files.list,
				loaded: true
			});
			self.files = files;
		});
		switch(this.props.itemType) {
			case 'site':
				GridDuck.getSite({id: this.props.itemId})
					.then(function(site) {
						self.setState({orgPermission: site._permission});
					})
				break;
		}
	}

	fileListUpdated(field, val) {
		console.log("File list updated", field, val);
		let self = this;
		if (field === 'list') {
			this.setState({
				allFiles: val,
				selectedFiles: [],
				previewFile: undefined
			}, function () {
				self.updateFilterResults()
			});
		}
	}

	deleteFiles(files) {
		let self = this;
		confirmAlert({
			customUI: ({onClose}) => {
				return (
					<div className='dialog'>
						<div className={"top-content"}>
							<h2>Delete file{this.state.selectedFiles.length > 1 ? 's' : ''}</h2>
							<p>Are you sure you want to
								delete {this.state.selectedFiles.length} file{(this.state.selectedFiles.length > 1 ? 's' : '')}</p>
						</div>
						<div className={"bottom-menu"}>
							<Button color={'gd-grey'} label={"No"} onClick={onClose}/>
							<Button color={'gd-red'}
									onClick={() => {
										self.setState({deleting: true})
										let promises = [];
										files.forEach(function (file) {
											promises.push(file.delete());
										});
										Promise.all(promises).then(function () {
											self.setState({deleting: false})
										})
										onClose();
									}}
									label={"Yes, delete"}
							/>
						</div>
					</div>
				);
			}
		});
	}

	getFiles(files) {
		let self = this;
		if (files.length === 1) {
			window.open(files[0].location, '_blank');
		} else if (files.length > 1) {
			self.setState({zipping: true});
			GridDuck.createZip({
				siteId: this.props.itemId,
				files: files.map(f => f.id)
			}).then(function (zip) {
				self.setState({zipping: false});
				window.open(zip.location, '_blank');
			});
		}
	}

	//FIXME: general place for this
	generalFileType(fileMime) {
		if (fileMime.indexOf('image') !== -1) {
			return 'image';
		} else if (fileMime.indexOf('pdf') !== -1) {
			return 'pdf';
		} else if (fileMime === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
			return 'powerpoint';
		} else if (fileMime === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileMime === 'application/msword') {
			return 'word';
		} else if (fileMime.indexOf('video') !== -1) {
			return 'video';
		} else {
			return 'other'
		}
	};

	generateUrl(location, name, type, mime) {

	}

	focusedInput() {
		this.setState({inputFocused: true})
	}

	blurredInput() {
		this.setState({inputFocused: false})
	}

	keyDown(evt, self) {
		if (this.state.inputFocused) return
		switch (evt.which) {
			case 32:
				if (this.state.selectedFiles.length === 1) {
					console.log(this.nameInputRef.current, document.activeElement);
					if (this.descInputRef.current !== document.activeElement && this.nameInputRef.current !== document.activeElement) {
						this.openFile(this.state.selectedFiles[0]);
					}
				}
				break;
			case 39:
				if (self.state.previewFile && self.state.previewType === 'image') {
					let images = self.state.files.filter(function (file) {
						return (self.generalFileType(file.type) === 'image');
					});
					let thisIndex = images.indexOf(self.state.previewFile);
					if (images.length > thisIndex + 1) {
						self.setState({
							previewFile: images[thisIndex + 1],
							selectedFiles: [images[thisIndex + 1]]
						});
					}
				} else if (self.state.selectedFiles.length === 1) {
					let thisIndex = self.state.files.indexOf(self.state.selectedFiles[0]);
					if (self.state.files.length > thisIndex + 1) {
						self.setState({
							selectedFiles: [self.state.files[thisIndex + 1]]
						});
					}
				}
				break;
			case 37:
				if (self.state.previewFile && self.state.previewType === 'image') {
					let images = self.state.files.filter(function (file) {
						return (self.generalFileType(file.type) === 'image');
					});
					let thisIndex = images.indexOf(self.state.previewFile);
					if (thisIndex > 0) {
						self.setState({
							previewFile: images[thisIndex - 1],
							selectedFiles: [images[thisIndex - 1]]
						});
					}
				} else if (self.state.selectedFiles.length === 1) {
					let thisIndex = self.state.files.indexOf(self.state.selectedFiles[0]);
					if (thisIndex > 0) {
						self.setState({
							selectedFiles: [self.state.files[thisIndex - 1]]
						});
					}
				}
				break;
			default:
				break;
		}
	}

	componentDidMount() {
		window.addEventListener("keydown", (evt) => this.keyDown(evt, this));
	}

	componentWillUnmount() {
		window.removeEventListener("keydown", (evt) => this.keyDown(evt, this));
		this.files.off('updated', this.fileListUpdated);
	}

	hideFile() {
		this.setState({previewFile: null});
	}

	openFile(file) {
		let fileType = this.generalFileType(file.type);
		switch (fileType) {
			case 'image':
				this.setState({previewFile: file, previewType: 'image'});
				break;
			case 'video':
				this.setState({previewFile: file, previewType: 'video'});
				break;
			case 'pdf':
				return window.open('https://docs.google.com/viewer?url=' + file.location);
			case 'powerpoint':
			case 'word':
				return window.open('https://view.officeapps.live.com/op/embed.aspx?src=' + file.location);
			default:
				return;
		}
	}

	onChangeFilterText(text) {
		this.filteredText = text.toLowerCase();
		this.updateFilterResults();
	}

	onChangeFilterType(type) {
		this.filteredType = type;
		this.updateFilterResults();
	}

	updateFilterResults() {
		let self = this;
		let filteredFiles = this.state.allFiles;
		console.log(filteredFiles, ' : filtered files');
		if (self.filteredType !== undefined && self.filteredType !== 'all') {
			filteredFiles = this.state.allFiles.filter(function (file) {
				return (self.generalFileType(file.type) === self.filteredType)
			});
		}
		if (self.filteredText !== undefined && self.filteredText !== '') {
			filteredFiles = filteredFiles.filter(function (file) {
				return file.name.toLowerCase().indexOf(self.filteredText) > -1;
			});
		}

		this.setState({files: filteredFiles});
	}

	downloadFiles(files) {
		let self = this;
		for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
			let file = files[fileIndex];
			let newFile = {
				itemType: self.props.itemType,
				itemId: self.props.itemId,
				name: file.name,
				type: file.type,
				size: file.size,
				file: file,
				ref: uuidv4(),
			};
			this.setState(state => {
				state.uploadingFiles = [...state.uploadingFiles].concat([newFile]);
				return state;
			});
			this.fileUploadQueue.upload(newFile);
		}

	}

	clearSelection() {
		console.log("clear selection");
		// this.setState({selectedFiles: []});
	}

	selectFile(file) {
		console.log("select file", file)
		this.setState({selectedFiles: [file]});
	}

	selectFiles(files) {
		console.log("select files", files);
		this.setState({selectedFiles: files});
	}

	render() {
		let modalContent;
		if (this.state.previewFile) {
			let cardContent;
			if (this.state.previewType === 'image') {
				cardContent = (
					<div style={{
						justifyContent: 'center',
						alignItems: 'center',
						display: 'flex'
					}}><img onLoad={() => this.setState({imgLoaded: true})} className={"image-preview"} alt={"preview"}
							src={this.state.previewFile.location}/></div>)
			} else if (this.state.previewType === 'video') {
				cardContent = (<div style={{
					justifyContent: 'center',
					alignItems: 'center',
					display: 'flex'
				}}>
					<video controls className={"video-preview"} src={this.state.previewFile.location}/>
				</div>)
			}
			modalContent = (
				<CardBody>
					{cardContent}
				</CardBody>
			)
		}
		const customStyles = {
			content: {
				top: '50%',
				left: '50%',
				right: 'auto',
				bottom: 'auto',
				marginRight: '-50%',
				transform: 'translate(-50%, -50%)',
				padding: '0px',
				background: 'none',
				border: 'none'
			},
			overlay: {
				backgroundColor: 'rgba(33,33,33,0.5)'
			}
		};
		let previewModal =
			this.state.previewFile ? <GdModal wide
											  open={this.state.previewFile}
											  onClose={this.hideFile}
											  title={this.state.previewFile.name}
											  style={customStyles}>
				{modalContent}
			</GdModal> : null;

		return (
			<div className={"file-manager " + ((this.state.zipping || this.state.deleting) ? 'no-pointer' : '')}>
				{previewModal}
				{this.state.loaded ? <div className={"fm-top-bar"}>
					<FileManagerFilterBar onChangeFilterText={this.onChangeFilterText}
										  onChangeFilterType={this.onChangeFilterType}
										  selectedFiles={this.state.selectedFiles}
										  files={this.state.files}
										  openFile={this.openFile}
										  readOnly={(this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'edit' && this.state.orgPermission !== 'owner')}
										  downloading={this.state.zipping}
										  deleting={this.state.deleting}
										  deleteFiles={this.deleteFiles}
										  downloadFiles={this.getFiles}/>
				</div> : <GenericLoader text={"Fetching"} textLineTwo={"Files..."}/>}
				{this.state.loaded ? <FileUploadDropper showPrompt={this.state.files.length === 0 && !this.state.uploadingFiles.length}
														downloadFiles={this.downloadFiles}
														disabled={(this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'edit' && this.state.orgPermission !== 'owner')}>
					<div className={"fm-container"}>
						<FileList disabled={(this.state.zipping || this.state.deleting)} uploadingFiles={this.state.uploadingFiles} openFile={this.openFile}
								  selectFiles={this.selectFiles} onSelect={this.selectFile}
								  files={this.state.files} selectedFiles={this.state.selectedFiles}/>
						{this.state.files.length ? <FileSummary focusinput={this.focusedInput} blurinput={this.blurredInput} openFile={this.openFile} files={this.state.files}
																readOnly={(this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'edit' && this.state.orgPermission !== 'owner')}
																selectedFiles={this.state.selectedFiles}
																nameInputRef={this.nameInputRef}
																descInputRef={this.descInputRef}/> : ''}
					</div>
				</FileUploadDropper> : ''}
			</div>
		)
	}
}

export default FileManager;
