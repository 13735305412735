import React from "react";
import "./OrderCheckoutPage.scss";

class ProductList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let self = this;
        return <div className={'product-list'}>
            {this.props.session && this.props.session.physicalItems.map(function (physicalItem) {
                let pIFP = self.props.products.find((product) => product.id === physicalItem.product);
                return <div className={'row'} key={physicalItem.product}>
                    <div className={'image-wrapper'}>
                        <div className={'image'}
                             style={{backgroundImage: 'url("' + pIFP.images[0] + '")'}}/>
                    </div>
                    <div className={'column'}>
                        <p style={{fontWeight: '700'}}>{pIFP.name}</p>
                        <p>x{physicalItem.quantity}</p>
                    </div>
                </div>
            })}
        </div>
    }

}

export default ProductList;