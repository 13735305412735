import React, {Component} from "react";
import "./AddEditDeviceCategoryModal.scss";
import '../../styles/_layout.scss';
import {
    Button,
    CardBody,
    Input,
    Icon,
    EditableList,
    StandardListCell,
    Tabs
} from "gd-react";
import GridDuck from "gridduck";
import moment from "moment-timezone";
import SaveBar from "../../components/SaveBar/SaveBar";
import history from "../../meta/history";
import GenericLoader from "../../components/GenericLoader";
import GdModal from "../../components/GdModal/GdModal";
import IconSearch from "../../components/IconSearch/IconSearch";
import DeleteModal from "../Delete/DeleteModal";
import Toggle from "../../components/Toggle/Toggle";

class AddEditDeviceCategoryModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.save = this.save.bind(this)
        this.delete = this.delete.bind(this)
        this.onTabClick = this.onTabClick.bind(this)
        this.checkErrors = this.checkErrors.bind(this)
        this.getActiveList = this.getActiveList.bind(this)
        this.getAvailableList = this.getAvailableList.bind(this)
        this.devicesChanged = this.devicesChanged.bind(this)
        this.errors = {};
        this.changed = false;
        this.state = {
            name: '',
            loaded: false,
            hasChanged: false,
            devicesChanged: false,
            colour: '',
            icon: '',
            global: false,
            devices: [],
            anchorEl: null,
            tabs: [[
                {
                    id: 1,
                    title: 'General',
                    selected: (this.props.openOnTab === 1 || !this.props.openOnTab),
                    onTabClick: this.onTabClick

                },
            ]]
        }
        if (this.props.item?.id) {
            this.state.tabs[0][1] = {
                id: 2,
                title: 'Devices',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 2)
            }
        }
        if (this.props.item?._permission && this.props.item._permission !== 'view') {
            this.state.tabs[0][2] = {
                id: 3,
                title: 'Delete',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 3)
            }
        }
        this.modalClosed = false;

    }

    async componentDidMount() {
        if (this.props.item?.id) {
            let state = {
                name: this.props.item.name,
                colour: this.props.item.colour,
                icon: this.props.item.icon,
                loaded: true,
                global: (this.props.item.organisationId) ? false : true
            }
            this.setState(state)
        }
    }

    delete() {
        let self = this;
        return this.props.item.delete().then(function () {
            if (self.props.onDelete) self.props.onDelete();
        })
    }

    async getActiveList(params) {
        let self = this

        if (!this.props.item || !this.props.item.name) return Promise.resolve({list: [], total: 0})

        if (!this.state.devices.length) {
            params.filters = [
                {
                    field: 'deviceCategory',
                    value: [this.props.item.name]
                },
                // {field: '_permission', value: this.perm_levels}
            ]
            let res = await GridDuck.getAssets(params);
            let list = res.list;
            self.setState({devices: list});
            return Promise.resolve({list: list, total: list.length});
        } else return Promise.resolve({list: self.state.devices, total: self.state.devices.length});
    }

    getAvailableList(params) {
        return GridDuck.getAssets(params);
    }

    devicesChanged(newList) {
        this.setState({
            devices: newList,
            hasChanged: true,
            devicesChanged: true
        });
    }

    onTabClick(ev, tab) {
        this.state.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    async save() {
        this.setState({triedToSave: true})
        if (Object.keys(this.errors).length > 0) {
            return Promise.reject()
        }

        let category = {
            name: this.state.name,
            colour: this.state.colour,
            icon: this.state.icon,
            global: this.state.global === 'true' ? true : false,
        }, res;
        if (this.state.devicesChanged) {
            category.assetIds = this.state.devices.map((d) => d.id)
        }
        if (this.props.item?.id) {
            res = this.props.item.set(category)
        } else {
            res = GridDuck.createDeviceCategory(category)
        }
        await res.then((res) => {
            if (res?.name && this.props.setNewDeviceCategory) {
                this.props.setNewDeviceCategory(res.name)
            }
            setTimeout(this.closeModal(), 1000)
        })
    }

    checkErrors() {
        let errors = {};
        if (!this.state.name) errors['name'] = true;
        if (!this.state.icon) errors['icon'] = true;
        this.errors = errors;
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    render() {
        this.checkErrors()
        let existingCategory = this.props.item?.id
        let viewOnly = false;
        if (existingCategory && this.props.item._permission === 'view') {
            viewOnly = true
        }

        let footer;
        if (this.state.hasChanged || !existingCategory) footer =
            <div><Button label={existingCategory ? 'Save' : 'Create'}
                         progressRes
                         additionalclasses={'sm'}
                         onClick={this.save}/></div>

        return (
            <GdModal
                title={existingCategory ? `${this.props.item.name}` : `Create Device Category`}
                open={this.props.open}
                footer={footer}
                onClose={this.closeModal}>
                {this.state.tabs ? <Tabs tabData={this.state.tabs}/> : null}
                {this.state.tabs[0][0].selected ?
                    <CardBody >
                        <div
                            className={'Add-Edit-Custom-Field ' + (this.errors['icon'] && this.state.triedToSave ? 'error' : '')}>
                            <div className={'row'} style={{marginBottom: '15px'}}>
                                {this.props.isGod ? <Toggle
                                    onClick={(val) => this.setState({
                                        global: val,
                                        hasChanged: true
                                    })}
                                    inactivestatename={'Global'}
                                    activestatename={'Global'}
                                    active={this.state.global.toString()}
                                    />
                                    : null}
                            </div>
                            <div className={'row'} style={{marginBottom: '20px', alignItems: 'end'}}>
                                <div
                                    className={viewOnly ? 'chosen-icon-wrapper-view' : 'chosen-icon-wrapper ' + (this.errors['icon'] ? 'error' : '')}
                                    onClick={viewOnly ? null : (e) => this.setState({anchorEl: e.currentTarget})}>
                                    <Icon color={this.state.colour} icon={this.state.icon || 'IoMdAddCircleOutline'}
                                          size={this.state.icon ? '50' : '20'}/>
                                    {!this.state.icon ? <p>Choose Icon</p> : null}
                                    {this.state.icon ?
                                        <div className={'remove-icon'}>
                                            <Icon color={'gd-red'} icon={'MdCancel'}
                                                  onIconClick={viewOnly ? null : (e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      this.setState({icon: null})
                                                  }}/>
                                        </div> : null}
                                </div>
                            </div>

                            {this.state.anchorEl ? <IconSearch
                                colour={this.state.colour}
                                onColourChange={(c) => this.setState({colour: c, hasChanged: true})}
                                onIconChange={(i) => this.setState({icon: i, hasChanged: true})}
                                onClose={() => this.setState({anchorEl: null})}
                                anchorEl={this.state.anchorEl}/> : null}
                            <div className={'row'} style={{marginBottom: '15px'}}>
                                <Input required
                                       error={this.errors['name'] && this.state.triedToSave}
                                       top='true'
                                       disabled={viewOnly}
                                       onChange={(e) => this.setState({
                                           name: e.target.value,
                                           hasChanged: true
                                       })}
                                       name={'name'}
                                       value={this.state.name}
                                       label={'Name'}
                                />
                            </div>
                        </div>
                    </CardBody> : null}
                {this.state.tabs[0][1] && this.state.tabs[0][1].selected ? <CardBody additionalclasses={'no-padding'}>
                    <div style={{height: '100%'}}>
                        {this.state.loaded ? <div className={'row'} style={{height: '100%'}}>
                            <EditableList
                                isEditing={!this.props.item || this.props.item._permission !== 'view'}
                                disabled={viewOnly}
                                columns={[
                                    {
                                        component: StandardListCell,
                                        bold: true,
                                        title: 'Name',
                                        field: 'name',
                                        sortable: true,
                                        defaultSort: 'asc'
                                    },
                                    {
                                        component: StandardListCell,
                                        title: 'Access level',
                                        field: '_permission',
                                        maxWidth: 'none'
                                    },
                                ]}
                                key={1}
                                onChange={this.devicesChanged}
                                noCancel={true}
                                noSave
                                getActiveList={this.getActiveList}
                                getAvailableList={this.getAvailableList}
                            />
                        </div> : <GenericLoader/>}
                    </div>
                </CardBody> : null}
                {this.state.tabs[0][2] && this.state.tabs[0][2].selected ? <CardBody>
                    <DeleteModal itemType={'deviceCategory'}
                                 item={this.props.item}
                                 open={this.state.tabs[0][2].selected}
                                 deleteRes={this.closeModal}
                                 onClose={(e) => {
                                     this.onTabClick(e, {id: 1})
                                 }}/>
                </CardBody> : null}
            </GdModal>
        )
    }
}

export default AddEditDeviceCategoryModal
