import React, {Component} from "react";
import "./dataUpload.scss";
import '../../styles/_layout.scss';
import {
    Button,
    CardBody,
    Input,
    Icon,
    EditableList,
    StandardListCell,
    Tabs
} from "gd-react";
import GridDuck from "gridduck";
import GdModal from "../../components/GdModal/GdModal";
import IconSearch from "../../components/IconSearch/IconSearch";
import Toggle from "../../components/Toggle/Toggle";
import DeleteModal from "../../modals/Delete/DeleteModal";
import DataTypeListPage from "./DataTypeList";

class CreateDataCategory extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.save = this.save.bind(this)
        this.delete = this.delete.bind(this)
        this.onTabClick = this.onTabClick.bind(this)
        this.checkErrors = this.checkErrors.bind(this)
        this.loadTabs = this.loadTabs.bind(this)
        this.errors = {};
        this.changed = false;
        let urlParams = new URLSearchParams(window.location.search);
        this.dataTypeId = urlParams.get('datatypeid');

        this.state = {
            name: '',
            loaded: false,
            hasChanged: false,
            datasChanged: false,
            color: '',
            icon: '',
            datas: [],
            anchorEl: null,
            tabs: [[
                {
                    id: 1,
                    title: 'General',
                    selected: !this.dataTypeId,
                    onTabClick: this.onTabClick
                },
                {
                    id: 2,
                    title: 'Data Types',
                    selected: this.dataTypeId,
                    onTabClick: this.onTabClick,
                    disabled: !this.props.item || !this.props.item.id
                }
            ]]
        }


        if (this.props.item?._permission && this.props.item._permission !== 'view') {
            this.state.tabs[0][2] = {
                id: 3,
                title: 'Delete',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 3)
            }
        }
        this.modalClosed = false;

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!prevProps.item && this.props.item) || (prevProps.item?.id !== this.props.item?.id)) {
            this.loadTabs();
        }
    }

    loadTabs() {
        let tabs = [[
            {
                id: 1,
                title: 'General',
                selected: !this.dataTypeId,
                onTabClick: this.onTabClick
            },
            {
                id: 2,
                title: 'Data Types',
                selected: this.dataTypeId,
                onTabClick: this.onTabClick,
                disabled: !this.props.item || !this.props.item.id
            }
        ]];
        if (this.props.item?._permission && this.props.item._permission !== 'view') {
            tabs[0][2] = {
                id: 3,
                title: 'Delete',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 3)
            }
        }
        this.setState({tabs: tabs})
    }

    async componentDidMount() {
        if (this.props.item?.id) {
            let state = {
                name: this.props.item.name,
                color: this.props.item.color,
                icon: this.props.item.icon,
                loaded: true
            }
            this.setState(state)
        }
    }

    delete() {
        let self = this;
        return this.props.item.delete().then(function () {
            if (self.props.onDelete) self.props.onDelete();
        })
    }

    onTabClick(ev, tab) {
        this.state.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    async save(e) {
        let self = this;
        this.setState({triedToSave: true})
        if (Object.keys(this.errors).length > 0) {
            return Promise.reject()
        }

        let category = {
            name: this.state.name,
            color: this.state.color,
            icon: this.state.icon
        }, res;

        if (this.props.item?.id) {
            res = this.props.item.set(category)
        } else {
            res = GridDuck.createDataCategory(category)
        }

        await res.then((res) => {
            if (res?.name && this.props.setNewDataCategory) {
                this.props.setNewDataCategory(res.name)
            }
            if (this.props.item?.id) {
                setTimeout(this.closeModal, 1000);
            } else {
                self.props.reloadData(res?.id).then(() => {
                    if (!this.props.createCategoryOnly) {
                        self.onTabClick(e, {id: 2})
                    } else {
                        setTimeout(() => this.closeModal(res), 1000);
                    }
                });
            }
        })
    }

    checkErrors() {
        let errors = {};
        if (!this.state.name) errors['name'] = true;
        if (!this.state.icon) errors['icon'] = true;
        this.errors = errors;
    }

    closeModal(res) {
        this.modalClosed = true;
        this.props.onClose(res);
    }

    render() {
        this.checkErrors()
        let existingCategory = this.props.item?.id
        let viewOnly = false;
        if (existingCategory && this.props.item._permission === 'view') {
            viewOnly = true
        }

        let footer;
        if (this.state.hasChanged && existingCategory) {
            footer =
                <div><Button label={'Save'}
                             progressRes
                             additionalclasses={'sm'}
                             onClick={this.save}/></div>
        }
        if (!existingCategory) {
            footer =
                <div><Button
                    label={(!this.props.createCategoryOnly && this.state.tabs[0][0].selected) ? 'Next' : 'Save'}
                    progressRes
                    disabled={Object.values(this.errors).find(e => e)}
                    additionalclasses={'sm'}
                    onClick={this.save}/></div>
        }

        return (
            <GdModal
                title={existingCategory ? `${this.props.item.name}` : `Create Data Category`}
                open={this.props.open}
                footer={footer}
                wide={this.state.tabs && this.state.tabs[0][1].selected}
                onClose={this.closeModal}>
                {this.state.tabs && !this.props.createCategoryOnly ? <Tabs tabData={this.state.tabs}/> : null}
                {!existingCategory && <div className={'modal-info'}>
                    There are two layers to how data is stored in GridDuck.<br/><br/>
                    1. <strong>Data Category</strong> - This is the tab that appears on the data page. E.g.
                    Environmental<br/>
                    2. <strong>Data Type</strong> - Each type belongs to a category and defines a specific data format.
                    E.g. Indoor
                    Temperature, Outdoor Temperature, Wind Speed<br/><br/>
                    In the above examples you would have a tab called Environmental with three graph option of Indoor
                    temperature, Outdoor temperature and Wind Speed.
                </div>}
                {this.state.tabs[0][0].selected ?
                    <CardBody>
                        <div
                            className={'Add-Edit-Custom-Field ' + (this.errors['icon'] && this.state.triedToSave ? 'error' : '')}>
                            <div className={'row'} style={{marginBottom: '20px', alignItems: 'end'}}>
                                <div
                                    className={viewOnly ? 'chosen-icon-wrapper-view' : 'chosen-icon-wrapper ' + (this.errors['icon'] ? 'error' : '')}
                                    onClick={viewOnly ? null : (e) => this.setState({anchorEl: e.currentTarget})}>
                                    <Icon color={this.state.color} icon={this.state.icon || 'IoMdAddCircleOutline'}
                                          size={this.state.icon ? '50' : '20'}/>
                                    {!this.state.icon ? <p>Choose Icon</p> : null}
                                    {this.state.icon ?
                                        <div className={'remove-icon'}>
                                            <Icon color={'gd-red'} icon={'MdCancel'}
                                                  onIconClick={viewOnly ? null : (e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      this.setState({icon: null})
                                                  }}/>
                                        </div> : null}
                                </div>
                            </div>

                            {this.state.anchorEl ? <IconSearch
                                colour={this.state.color}
                                onColourChange={(c) => this.setState({color: c, hasChanged: true})}
                                onIconChange={(i) => this.setState({icon: i, hasChanged: true})}
                                onClose={() => this.setState({anchorEl: null})}
                                anchorEl={this.state.anchorEl}/> : null}
                            <div className={'row'} style={{marginBottom: '15px'}}>
                                <Input required
                                       error={this.errors['name'] && this.state.triedToSave}
                                       top='true'
                                       disabled={viewOnly}
                                       onChange={(e) => this.setState({
                                           name: e.target.value,
                                           hasChanged: true
                                       })}
                                       name={'name'}
                                       value={this.state.name}
                                       label={'Name'}
                                />
                            </div>
                        </div>
                    </CardBody> : null}
                {this.state.tabs[0][1].selected ?
                    <CardBody>
                        <DataTypeListPage categoryId={this.props.item ? this.props.item.id : null}/>
                    </CardBody> : null}
                {this.state.tabs[0][2] && this.state.tabs[0][2].selected ? <CardBody>
                    <DeleteModal itemType={'dataCategory'}
                                 item={this.props.item}
                                 open={this.state.tabs[0][2].selected}
                                 deleteRes={this.closeModal}
                                 onClose={(e) => {
                                     this.onTabClick(e, {id: 1})
                                 }}/>
                </CardBody> : null}
            </GdModal>
        )
    }
}

export default CreateDataCategory