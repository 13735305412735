import React, {Component} from 'react';
import {Button} from "gd-react";
import CategoricalCategory from "./CategoricalCategory";

class CategoricalForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: this.props.unit && this.props.unit.metaData.categories && this.props.unit.metaData.categories.length ? this.props.unit.metaData.categories : [{
                id: this.props.unit.unitType === 'boolean' ? 'true' : Math.random() + '',
                value: this.props.unit.unitType === 'boolean' ? 'true' : '',
                label: ''
            }]
        };
        if (this.props.unit.unitType === 'boolean' && (!this.props.unit.metaData || !this.props.unit.metaData.categories)) {
            this.state.categories.push({
                id: 'false',
                value: 'false',
                label: ''
            });
        }
        this.errors = {};
        let self = this;
        ['checkErrors', 'formUpdate', 'addCategory', 'removeCategory'].forEach((func) => {
            self[func] = self[func].bind(self);
        });
    }

    componentDidMount() {
        this.formUpdate({updateFunc: (cb) => cb()});
    }

    checkErrors() {
        this.state.categories.forEach(r => {
            this.errors[r.id + 'label'] = !r.label || !r.label.length;
            this.errors[r.id + 'value'] = !r.value || !r.value.length;
        });
    }

    formUpdate(formData) {
        let self = this;
        formData.updateFunc(() => {
            self.checkErrors();
            self.props.onChange({metaData: self.state, errors: self.errors});
        });
    }

    addCategory() {
        this.formUpdate({
            updateFunc: (cb) => {
                this.state.categories.push({
                    id: Math.random() + '',
                    value: '',
                    label: ''
                });
                cb();
            }
        });
    }

    removeCategory(itemToRemove) {
        this.formUpdate({
            updateFunc: (cb) => {
                this.state.categories.splice(this.state.categories.indexOf(itemToRemove), 1);
                cb();
            }
        });
    }

    render() {
        let categories = this.state.categories && this.state.categories.map((c ,i) => {
            return <CategoricalCategory unitType={this.props.unit.unitType} key={c.id} index={i} removeCategory={this.removeCategory}
                                        errors={this.errors}
                                        onChange={(res) => this.formUpdate({
                                            updateFunc: (cb) => {
                                                this.state.categories.map(r => {
                                                    if (r.id === res.id) {
                                                        r[res.key] = res.value;
                                                    }
                                                })
                                                cb();
                                            }
                                        })} category={c} categories={this.state.categories}/>
        });
        return (
            <div>
                {categories}
                {this.props.unit.unitType !== 'dimensionless' && this.props.unit.unitType !== 'boolean' ?
                    <div className={'row reverse center'} style={{marginTop: '10px'}}>
                        <Button onClick={this.addCategory} label={'+ New Label'}/>
                    </div> : null}
            </div>
        );
    }
}

export default CategoricalForm;