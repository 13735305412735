import React from "react";
import GridDuck from "gridduck";
import {
    StateOptions,
    Button,
    Icon,
    ScheduleSetter,
    ScheduleTimeline,
    List,
    StandardListCell,
    LastActiveCell,
    EventBreakdownCell,
    GdCheckbox
} from "gd-react";
import "../Rules.scss";
import history from "../../meta/history";

import moment from "moment";
import {legacyRule} from "../../services/parser";

class RulePageAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assetNames: [],
            assetIds: []
        };
        let self = this;
        let rule = legacyRule(this.props.rule);
        let promises = rule.assets.map(a => GridDuck.getAsset({id: a}));
        Promise.all(promises).then(function (results) {
            self.setState({
                assetIds: rule.assets,
                assetNames: results.map(a => a.name)
            });
        })
        this.getEventList = this.getEventList.bind(this);
        this.getAssetName = this.getAssetName.bind(this);
    }

    isDifferentAssets(oldAssets, newAssets) {
        if (oldAssets.length !== newAssets.length) {
            return true;
        }
        return !oldAssets.every((value, index) => value === newAssets[index]);
    }

    getAssetName(assetId) {
        return GridDuck.getAsset({id: assetId}).then(function (asset) {
            return Promise.resolve(asset.name);
        });
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {

        let rule = legacyRule(this.props.rule);
        let self = this;
        if (this.isDifferentAssets(this.state.assetIds, rule.assets)) {
            let promises = rule.assets.map(a => GridDuck.getAsset({id: a}));
            Promise.all(promises).then(function (results) {
                self.setState({
                    assetNames: results.map(a => a.name),
                    assetIds: results.map(a => a.id)
                });
            })
        }
    }

    getEventList(params) {
        let self = this;
        console.log("Get event list", params);
        return GridDuck.getRuleEvents(params);
    }


    render() {
        let self = this;
        let assetNames;
        if (this.state.assetNames) {
            assetNames = this.state.assetNames.map(function (name, index) {
                return (<span style={{'text-decoration': 'underline', 'cursor': 'pointer'}}
                              onClick={() => history.push("/device/" + self.state.assetIds[index])}>{name + (index < self.state.assetNames.length - 1 ? ', ' : '')}</span>)
            });
        }

        let dataType;
        let rule = legacyRule(this.props.rule);
        let dT;
        let unit;
        let factor = 1;

        StateOptions.forEach(function (stateOption) {
            stateOption.dataTypes.forEach(function (dt) {
                if (dt.type === rule.dataType) {
                    dT = dt;
                }
            })
        })
        if (dT) {
            dataType = dT.label;
            unit = dT.unit;
            if (dT.factor) {
                factor = dT.factor;
            }
        }

        let assetLogic;
        if (rule.dataContext.requireAll) {
            assetLogic = "all of";
        } else {
            assetLogic = "any of";
        }
        let thresholdType = rule.dataContext.thresholdType;
        let threshold = rule.dataContext.threshold;
        let curPrev;
        let duration;
        let over;
        let link;
        let throttleDescription;
        if (rule.dataContext.instantaneous) {

        } else if (rule.dataContext.periodStartDefined) {
            curPrev = 'current';
            over = "over the";
            if (parseInt(rule.dataContext.periodStartDefined) === rule.dataContext.periodStartDefined) {
                duration = moment.duration(rule.dataContext.periodStartDefined, 'seconds').humanize().replace('a ', '').replace('an ', '');
            } else {
                duration = rule.dataContext.periodStartDefined;
            }
        } else {
            over = "over the";
            curPrev = 'previous';
            if (rule.dataContext.periodDuration === 86400) {
                duration = '24 hours';
            } else {
                duration = moment.duration(rule.dataContext.periodDuration, 'seconds').humanize().replace('a ', '').replace('an ', '')
            }
        }
        if (!rule.dataContext.periodStartDefined && !rule.dataContext.instantaneous) {
            throttleDescription = " at most every " + moment.duration(rule.throttleDuration, 'seconds').humanize().replace('a ', '').replace('an ', '');
        }
        let recipients = [];
        if (rule.alerts && rule.alerts.length > 0 && rule.alerts[0].recipients) {
            recipients = [rule.alerts[0].recipients.map((r, i) => (
                <span key={Math.random()}
                      className={"recipient"}>{r + (i < rule.alerts[0].recipients.length - 1 ? ', ' : '')}</span>))];
        }
        let alertLog;
        if (rule && !this.props.isMobile) {
            link = (
                <div style={{display: 'inline-block', float: 'left'}}>
                    <Button label={"View Rule"} color={'gd-grey'}
                            onClick={() => history.push("/alerts/" + rule.id)}/>
                </div>
            )
            alertLog = (
                <div className={"alert-log"}>
                    <p className={"label"}>Recently triggered notifications</p>
                    <div className={"action"}>
                        <List
                            nohover
                            columns={[
                                {
                                    component: LastActiveCell,
                                    bold: true,
                                    title: 'Date',
                                    field: 'time',
                                    sortable: true,
                                    defaultSort: 'desc'
                                },
                                {
                                    component: EventBreakdownCell,
                                    title: 'Breakdown',
                                    field: 'event',
                                    getAssetName: this.getAssetName
                                }
                            ]}
                            filters={[
                                {
                                    field: 'ruleId',
                                    value: self.props.rule.id
                                },
                                {
                                    field: 'start',
                                    value: moment().subtract(1, 'months').unix()
                                },
                                {
                                    field: 'end',
                                    value: Math.round(new Date().getTime() / 1000)
                                }]
                            }
                            itemsPerPage={10}
                            getlist={this.getEventList}
                        />
                    </div>
                </div>
            )
        }
        let status;
        if (rule.persistent) {
            status = (<div className={"alert-preview"}>
                <p className={"label"}>Current Status</p>
                <p className={'trig-by'}>{rule.active ? 'Active' : 'Inactive'}</p>
            </div>);
        }

        return (
            <div className={"details"}>
                <div className={'details-inner'}>
                    <div className={"detail-card"}>
                        {/*<Icon color={'orange'} size={!this.props.strippedback ? '50' : '20'} icon={'FaBell'}/>*/}
                        {/*<div className={"right-content"}>*/}
                        {/*    <h1>{rule.name}</h1>*/}
                        <p>{rule.description}</p>
                        {/*</div>*/}
                    </div>
                    {this.props.strippedback ? link : ''}
                    {!this.props.strippedback ? status : ''}
                    {!this.props.strippedback ? <div className={"alert-preview"}>
                        <p className={"label"}>Active Times</p>
                        {rule.isSchedule ? <div>
                            <ScheduleTimeline
                                assetType={'default'}
                                inactiveState={-1}
                                rules={rule.conditions}
                                actions={[{
                                    id: -1,
                                    name: 'Active',
                                    color: '#49c570'
                                }]}
                                inactiveState={'inactive'}/>
                            <ScheduleSetter
                                actions={[{
                                    id: -1,
                                    name: 'Active',
                                    color: '#49c570'
                                }]}
                                hideActionList
                                defaultState={-1}
                                assetType={'default'}
                                rules={rule.conditions}
                            />
                        </div> : <GdCheckbox checked={!rule.isSchedule} name={'allTheTime'}
                                             disabled
                                             label={'All the time'}/>}
                    </div> : ''}
                    {!this.props.strippedback ? <div className={"alert-preview"}>
                        <p className={"label"}>Triggered</p>
                        <p className={'trig-by'}>If
                            the {dataType} goes {thresholdType} {threshold * factor}{unit} for {assetLogic} {assetNames} {over} {curPrev} {duration}{throttleDescription}.</p>
                    </div> : ''}
                    {!this.props.strippedback ? <div className={"alert-preview"}>
                        <p className={"label"}>Sending notifications to</p>
                        <p className={'trig-by'}>{recipients}</p>
                    </div> : ''}
                    {!this.props.strippedback ? alertLog : ''}
                </div>
            </div>
        );
    }
}

export default RulePageAlert;
