import React, {Component} from "react";
// import "./AddSiteModal.scss";
import "./DeleteModal.scss"
import '../../styles/_layout.scss';
import {Button, Card, CardBody, CardHeader, Dropdown, Input, Loader, Toast} from "gd-react";
import GridDuck from "gridduck";
import moment from "moment-timezone";
import SaveBar from "../../components/SaveBar/SaveBar";
import history from "../../meta/history";
import GenericLoader from "../../components/GenericLoader";
import BreadcrumbService from "../../meta/breadcrumb-service";
import GdModal from "../../components/GdModal/GdModal";

class DeleteModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.delete = this.delete.bind(this);
        this.setDeviceStates = this.setDeviceStates.bind(this)

        this.state = {
            loaded: true,
            text: '',
            error: false,
            showToast: null
        }
        this.modalClosed = false;
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    onInputChange(val) {
        let self = this;
        this.setState({text: val.target.value})
        if (val.target.value === 'DELETE') {
            this.setState({error: false})
        }
    }

    async setDeviceStates() {
        let assets = await Promise.all(this.props.item.alerts[0].assets.map((a) => {
            return GridDuck.getAsset({id: a})
        }))
        if (assets.length) {
            assets.map((a) => {
                let targetState = a.currentState === 'ON' ? 'ALWAYS_ON' : 'ALWAYS_OFF'
                a.setUserState(targetState)
            })
        }
    }

    async delete() {
        let self = this;
        let item = this.props.item;
        let type = this.props.itemType;
        if (this.state.text === 'DELETE') {
            await item.delete();
            self.setState({showToast: item.name + ' deleted'});
            if (type === 'site' || type === 'siteGroup' || type === 'hub') {
                history.push('/siteGroup/all')
            } else if (type === 'automation') {
                if (item.alerts[0]?.assets) {
                    await this.setDeviceStates()
                }
                history.push('/automations')
            } else if (type === 'group') {
                history.push('/device-groups')
            } else if (type === 'user') {
                history.push('/organisation')
            } else if (type === 'modbusModel') {
                history.push('/settings/organisation/modbus-models')
            } else if (type === 'occupancySchedule') {
                history.push('/settings/organisation/occupancy-schedules')
            } else if (type === 'orgPartner') {
                history.push('/organisation/partners')
            }
            if (type === 'location') {
                self.props.deleteRes()
            } else {
                if (item.groupIds && item.groupIds[0]) {
                    history.push('/site/' + item.groupIds[0] + '/devices')
                }
            }
            if (self.props.deleteRes) self.props.deleteRes();
            self.closeModal()
            return Promise.resolve();
        }
    }

    render() {
        let footer;
        let type = this.props.itemType;
        if (this.state.loaded) footer =
            <div className={'footer-container'}>
                <div className={'button-container'}>
                    <Button label={'No, Cancel'} outline onClick={this.closeModal} color={'gd-grey'}/>
                </div>
                <div className={'button-container'}>
                    <Button disabled={this.state.text !== 'DELETE'}
                            progressRes label={'Yes, Delete'}
                            onClick={this.delete}
                            color={'gd-red'}/>
                </div>
            </div>;
        return (
            <div>
                <GdModal
                    open={this.props.open}
                    contentLabel={'Delete'}
                    title={'Delete ' + (this.props.item.name || this.props.item.displayName)}
                    footer={footer}
                    onClose={this.closeModal}>
                    {this.state.loaded ? <CardBody>
                        <div>
                            <p>Are you sure you want to permanently
                                delete <strong>{this.props.item.name}</strong>?
                            </p>
                        </div>
                        {type === 'site' ? <div>
                            <p>Deleting this site will also delete ALL
                                associated <strong>Hubs</strong> and <strong>Devices</strong>.</p>
                        </div> : ''}
                        {type === 'location' ? <div>
                            <p>Deleting this location will also delete it everywhere else it's used</p>
                        </div> : ''}
                        {type === 'hub' ? <div>
                            <p>Deleting this hub will also delete ALL associated <strong>Devices</strong>.</p>
                        </div> : ''}
                        <div>
                            <p>Type <strong>'DELETE'</strong> in the input to confirm.</p>
                        </div>
                        <div>
                            <Input type="text"
                                   error={this.state.error}
                                   autoFocus
                                   onChange={this.onInputChange}
                                   placeholder={'DELETE'}
                                   value={this.state.text}/>
                        </div>
                    </CardBody> : ''}
                    {!this.state.loaded ? <CardBody>
                        <GenericLoader/>
                    </CardBody> : ''}
                </GdModal>
                <Toast onClose={() => this.setState({showToast: null})}
                       message={this.state.showToast}
                       open={this.state.showToast}
                       severity="success"
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                       }}
                />
            </div>
        )
    }
}

export default DeleteModal;
