import React, {Component} from "react";
import '../../styles/_layout.scss';
import GridDuck from "gridduck";
import {CardBody, Icon} from "gd-react";
import SideDrawer from "../SideDrawer";
import {deleteCookie} from "../../services/cookieManager";
import GaiaConversations from "./GaiaConversations";
import GaiaConversation from "./GaiaConversation";
import gaiaLogo from '../../images/gaia-text.png';
import './Gaia.scss'
import MessageBox from "./MessageBox";

class Gaia extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.startConversation = this.startConversation.bind(this);
        this.updateConversations = this.updateConversations.bind(this);
        this.modalClosed = false;
        this.state = {
            conversations: [],
            conversationStarter: '',
            convoLength: 0
        }
    }

    updateConversations() {
        console.log('conversations list updated: ', this.conversations.list);
        this.setState({conversations: this.conversations.list}, () => {
            if (this.state.convoLength !== this.conversations.list.length) {
                this.setState({loadingConversation: false}, () => {
                    if (this.state.convoLength < this.conversations.list.length) {
                        this.setState({activeConversation: this.state.conversations[0], convoLength: this.conversations.list.length})
                    } else {
                        this.setState({convoLength: this.conversations.list.length})
                    }
                });
            }
        });
    }

    async componentDidMount() {
        let conversations = await GridDuck.getGaiaConversations({getAll: true});
        this.conversations = conversations;
        this.conversations.on('updated', this.updateConversations);
        this.setState({conversations: conversations.list, convoLength: conversations.list.length, loaded: true});
    }

    async startConversation(message) {
        this.setState({loadingConversation: true});
        await GridDuck.createGaiaMessage({content: message});
    }

    closeModal() {
        this.modalClosed = true;
        deleteCookie('gaiaDrawerOpen', 'true');
        this.props.onClose();
    }

    render() {
        return (
            <SideDrawer className={'manage-disconnections gaia'}
                        title={<div className={'row space-between'}>
                            <div className={'column ellipsis-wrapper'}>
                                {this.state.activeConversation ? <div className={'row'}>
                                    <div className={'back-button'}
                                         onClick={() => this.setState({activeConversation: null})}><Icon
                                        icon={'FaChevronLeft'}/> Back to chats
                                    </div>
                                </div> : null}
                                <div
                                    className={'row'}>{this.state.activeConversation ? <div className={'active-title'}>{this.state.activeConversation.title}</div> :
                                    <img className={'gaia-logo'} alt={'gaia'} src={gaiaLogo}/>}</div>
                                {!this.state.activeConversation ? [
                                    (<div className={'row gaia-title'}>AI Assistant</div>),
                                    (<div className={'row gaia-description'}>Hey, I'm gaia and I can help you with
                                        anything from understanding your data better to how to use the
                                        dashboard.</div>)] : null}
                            < /div>
                        </div>}
                        footer={null}
                        open={this.props.open}
                        contentLabel={'disconnected'}
                        onClose={this.closeModal}>
                <CardBody additionalclasses={'no-padding rule gaia'}>
                    {this.state.activeConversation ?
                        <GaiaConversation conversation={this.state.activeConversation}/> :
                        [(<GaiaConversations
                            loaded={this.state.loaded}
                            loading={this.state.loadingConversation}
                            onSelect={(conversation) => this.setState({activeConversation: conversation})}
                            conversations={this.state.conversations}/>),
                            (<MessageBox placeholder={'Start new chat...'}
                                         sendMessage={this.startConversation}/>)]}
                </CardBody>
            </SideDrawer>
        )
    }
}


export default Gaia;