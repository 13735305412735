import React from "react";
import './IntegrationManagement.scss'
import {Icon, Loader} from "gd-react";

class IntegrationStatus extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.item.metadata.setup && !this.props.item.metadata.connected ?
                    <div className={'status-tab awaiting-connection ' + (this.props.inline ? 'inline' : '')}><Loader
                        type={'circular'}
                        size={'20'} color={'gd-green'}/>Awaiting connection
                    </div> : null}
                {this.props.item.metadata.connected ?
                    <div className={'status-tab connected ' + (this.props.inline ? 'inline' : '')}><Icon
                        icon={'FaCheck'} size={'11'}
                        color={'white'}/> Connected
                    </div> : null}
            </div>
        )
    }
}

export default IntegrationStatus;