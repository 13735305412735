import React from "react";
import {Button, Card, CardBody, CardHeader, Input, Toast} from "gd-react";
import '../../styles/_layout.scss';
import SaveBar from "../../components/SaveBar/SaveBar";
import DeleteModal from "../../modals/Delete/DeleteModal";
import GenericLoader from "../../components/GenericLoader";

class SiteGroupSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleClose = this.handleClose.bind(this);
        this.hasChanged = this.hasChanged.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.showDeleteModal = this.showDeleteModal.bind(this)
        this.state = {
            siteGroup: {
                name: ''
            },
            showDeleteModal: false
        }
        this.setSiteGroupInfo = this.setSiteGroupInfo.bind(this);
        this.siteGroup = this.props.item;
    }

    componentDidMount() {
        this.setSiteGroupInfo();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.item && this.props.item && prevProps.item.id !== this.props.item.id){
            this.setSiteGroupInfo();
        }
    }

    showDeleteModal() {
    	this.setState({showDeleteModal: true})
	}

    setSiteGroupInfo() {
        this.siteGroup = this.props.item;
        this.setState({
            siteGroup: {
                name: this.props.item.name
            }
        });
    }

    onFormChange(val) {
        this.state.siteGroup[val.target.name] = val.target.value;
        this.setState({siteGroup: this.state.siteGroup});
    }

    hasChanged() {
        let self = this;
        let hasChanged = false;
        if (self.siteGroup && self.state.siteGroup) {
            Object.entries(self.siteGroup).map(([key, value]) => {
                let updatedKey = key.replace(/^_+/i, '');
                if ((updatedKey in self.state.siteGroup) && self.state.siteGroup[updatedKey] !== value) {
                    hasChanged = true;
                }
            })
        }
        return hasChanged;
    }

    cancelChanges() {
        this.setSiteGroupInfo();
    }

    checkErrors() {
        let errors = {};
        if (!this.state.siteGroup.name.length) errors['name'] = true;
        this.errors = errors;
    }

    saveChanges(e) {
        let self = this;
        this.setState({triedToSave: true})
        if (Object.keys(this.errors).length > 0) return new Promise(function (resolve, reject) { return reject();}).catch(function () {
            return Promise.reject();
        });
        if (e) {
            e.preventDefault();
        }

        return this.props.item.set(this.state.siteGroup).then(function () {
            self.setState({open: true})
            self.setSiteGroupInfo();
        });
    }

    handleClose() {
        this.setState({open: false})
    }

    render() {
        this.checkErrors();
        let settingsEditCard, settingsAdvancedCard;
        let footerSaveBar = this.hasChanged() ?
            <div className={'column'}>
                <div className={'detail-content'}>
                    <SaveBar onSaveClick={this.saveChanges} onCancelClick={this.cancelChanges}/></div>
            </div> : '';

        if (this.state.siteGroup && this.props.loaded) {
            settingsEditCard = (
                <Card>
                    <CardHeader title={'General'}/>
                    <CardBody>
                        <Input top='true' label={'Name'} required name={'name'} value={this.state.siteGroup.name}
                               error={this.errors['name'] && this.state.triedToSave}
                               onKeyPress={(event) => {
                                   if (event.key === 'Enter') this.saveChanges();
                               }}
                               onChange={this.onFormChange}/>
                    </CardBody>
                </Card>
            )
			settingsAdvancedCard = (
				<Card>
					<CardHeader title={'Advanced'}/>
					<CardBody>
						{this.props.item._permission === 'admin' ? <Button label={'Delete Site Group'} color={'gd-red'} onClick={this.showDeleteModal}/> : ''}
					</CardBody>
				</Card>
			)

        }

        return (
            <div className={"page narrow not-flex grey"}>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        {settingsEditCard}
                        {!this.props.loaded ? <GenericLoader/> : null}
                    </div><div className={'detail-content'}>
					{this.props.item._permission === 'admin' ? settingsAdvancedCard : null}
				</div>

                </div>
                {footerSaveBar}
				<DeleteModal
					itemType={'siteGroup'}
					item={this.props.item}
					open={this.state.showDeleteModal}
					onClose={() => this.setState({showDeleteModal: false})} />
                <Toast onClose={this.handleClose} message={"Save Successful"}
                       anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                       open={this.state.open}/>
            </div>
        )
    }
}

export default SiteGroupSettings;
