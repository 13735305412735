import React from "react";
import {
    Button,
    List,
    StandardListCell
} from "gd-react";
import GridDuck from 'gridduck';
import './dataUpload.scss'
import history from "../../meta/history";
import {camelfy} from "../../services/CamelfyRequestData";
import CreateDataType from "./CreateDataType";
import NoItems from "../../components/noItems";
import CreateDataUpload from "./CreateDataUpload";

let pagePath;

class DataUploadListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDataUploadModal: null,
            updateRef: 0
        };
        this.getList = this.getList.bind(this);
        this.updateDataUpload = this.updateDataUpload.bind(this);
    }

    async componentDidMount() {
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (user) {
                self.setState({user: user});
            });
        let urlParams = new URLSearchParams(window.location.search);
        let customFieldTypeId = urlParams.get('id');
        let alreadyOpen = urlParams.get('alreadyOpen');
        if (customFieldTypeId && !alreadyOpen) {
            // camelfy((...args) => GridDuck.getDataUpload(...args), {id: customFieldTypeId}).then((dataUpload) => {
            GridDuck.getDataUpload({id: customFieldTypeId}).then((dataUpload) => {
                self.setState({showDataUploadModal: dataUpload});
            });
        }
    }

    // componentWillUnmount() {
    //     this.dataUpload.on('updated', this.updateDataUpload);
    // }

    async getList(params) {
        // return camelfy((...args) => GridDuck.getDataUploads(...args), params);
        return GridDuck.getDataUploads(params);
    }

    updateDataUpload() {
        this.setState({updateRef: this.state.updateRef++});
    }

    render() {
        return (
            this.state.user ? <div className={'page data-upload'}>
                <List
                    noitemelem={<NoItems text={'No Data Types'}/>}
                    columns={[
                        {
                            component: StandardListCell,
                            title: 'Template Name',
                            field: 'groupName'
                        },
                        {
                            component: StandardListCell,
                            title: 'CSV Files',
                            field: 'csvFileNames',
                            custom: function (item) {
                                return item.csvFileNames.map((name, i) => {
                                    if (i !== item.csvFileNames.length - 1) {
                                        return name + ', ';
                                    } else return name
                                })
                            },
                        },
                        {
                            component: StandardListCell,
                            title: 'Status',
                            custom: function (item) {
                                return item.status
                            },
                            field: 'status',
                            maxWidth: '250px'
                        }]}
                    getlist={this.getList}
                    rowOnClick={(item) => {
                        this.setState({showDataUploadModal: item})
                        history.push('/settings/organisation/data-management/uploads?id=' + item.id);
                        // let newUrl = pagePath.replace(/([&?])datatypeid=[^&]*&?/, '$1').replace(/&$/, '').replace(/\?$/, '');
                        // history.push(newUrl + '&datatypeid=' + item.id);
                    }}
                />

                {this.state.showDataUploadModal ?
                    <CreateDataUpload isGod={this.state.user.god}
                                      user={this.state.user}
                                      orgPerm={this.state.user.orgPermission}
                                      item={this.state.showDataUploadModal}
                                      reloadItem={async (id, cb) => {
                                          // this.dataUpload = await camelfy((...args) => GridDuck.getDataUpload(...args), {id: id});
                                          this.dataUpload = await GridDuck.getDataUpload({id: id});
                                          // this.dataUpload.on('updated', this.updateDataUpload);
                                          this.setState({showDataUploadModal: this.dataUpload}, () => {
                                              if (cb) cb();
                                          });
                                          history.push('/settings/organisation/data-management/uploads?id=' + this.dataUpload.id);
                                      }}
                                      onClose={() => {
                                          this.setState({
                                              showDataUploadModal: null
                                          })
                                          history.push('/settings/organisation/data-management/uploads');
                                          // const newUrl = pagePath.replace(/([&?])datatypeid=[^&]*&?/, '$1').replace(/&$/, '').replace(/\?$/, '');
                                          // history.push(newUrl);
                                      }}
                                      open={this.state.showDataUploadModal}/> : null}

            </div> : null)
    }
}

export default DataUploadListPage;