import React, {Component} from 'react';
import MetricForm from "./UnitTypeForms/MetricForm";
import NonMetricForm from "./UnitTypeForms/NonMetricForm";
import DimensionlessForm from "./UnitTypeForms/DimensionlessForm";
import LogarithmicForm from "./UnitTypeForms/LogarithmicForm";
import CategoricalForm from "./UnitTypeForms/CategoricalForm";

function UnitDetailsForm({unit, onChange, triedToSave}) {
    let UnitComponent;
    switch (unit.unitType) {
        case 'metric':
            UnitComponent = MetricForm;
            break;
        case 'nonMetric':
            UnitComponent = NonMetricForm;
            break;
        case 'dimensionless':
            UnitComponent = DimensionlessForm;
            break;
        case 'boolean':
            UnitComponent = CategoricalForm;
            break;
        case 'logarithmic':
            UnitComponent = LogarithmicForm;
            break;
        case 'categorical':
            UnitComponent = CategoricalForm;
            break;
    }
    if (UnitComponent) {
        return <UnitComponent triedToSave={triedToSave} unit={unit} onChange={onChange}/>;
    } else {
        return <div>Please select a unit type.</div>;
    }
}

export default UnitDetailsForm;