import React from "react";
import "./widget.scss";
import {Icon, StateOptions, Tooltip} from "gd-react";
import history from "../../../meta/history";

class WidgetHeader extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {};
        this.goToDataPage = this.goToDataPage.bind(this);
    }

    snakeCase = string => {
        return string.replace(/\W+/g, " ")
            .split(/ |\B(?=[A-Z])/)
            .map(word => word.toLowerCase())
            .join('_');
    };

    goToDataPage() {
        let itemType = this.props.info.className, url_item_type, dataType, dateData = this.props.info.dateData,
            widget_type = this.props.info.widgetType;
        switch (itemType) {
            case 'asset-page':
                url_item_type = 'device';
                break;
            case 'group-page':
                url_item_type = 'group';
                break;
            case 'site-page':
                url_item_type = 'site';
                break;
            case 'site-group-page':
                url_item_type = 'siteGroup';
                break;
            case 'client-page':
                url_item_type = 'client';
                break;
        }

        let data_type_in_url = this.props.info.dataType;
        // if (data_type_in_url === 'DELIVERED') data_type_in_url = 'CONSUMPTION'
        // if (data_type_in_url === 'DELIVERED_GAS') data_type_in_url = 'GAS_PULSE'
        // if (data_type_in_url === 'DELIVERED_WATER') data_type_in_url = 'WATER_PULSE'
        // if (data_type_in_url === 'DELIVERED_WATER_HOT') data_type_in_url = 'WATER_HOT_PULSE'

        if (this.props.info.title === 'Site Breakdown') {
            widget_type = 'site_breakdown'
        } else if (this.props.info.title === 'Category Breakdown') {
            widget_type = 'category_breakdown'
        }
        let newurl = '/' + url_item_type + '/' + this.props.info.item.id + '/data/' + data_type_in_url.toLowerCase();
        let params = '';
        params += '?dR=' + (dateData.d_rstring ? dateData.d_rstring : 'week_so_far');
        params += '&ty=' + widget_type;
        params += '&g=' + this.props.info.graphType;
        if (this.props.info.graphType === 'line') {
            params += '&combined=true';
        }
        if (dateData.d_rstring === 'custom') {
            params += '&s=' + dateData.start;
            params += '&e=' + dateData.end;
        }
        if (dateData.c_rstring) {
            params += '&cR=' + (dateData.c_rstring ? dateData.c_rstring : 'previous_day');
        }
        if (dateData.c_rstring === 'custom' && this.props.info.graphType !== 'line') {
            params += '&cS=' + dateData.compare_start;
            params += '&cE=' + dateData.compare_end;
        }
        newurl += params;
        window._current_params = params;
        history.push(newurl);
    }

    toTitleCase(str) {
        return str
            .split('_')
            .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

    render() {
        console.log(this.props.info.widget, ' : widget');
        return <div className={'widget-header'}>
            <div style={{display: 'flex'}}>
                {this.props.info.icon ? <Tooltip label={this.toTitleCase(this.props.info.type)}>
                    <div className={'icon-wrapper'} style={{backgroundColor: this.props.info.color}}>
                        {this.props.info.icon ? <Icon icon={this.props.info.icon}
                              size={12}
                              color={'white'}/> : null}
                    </div>
                </Tooltip> : null}
                {this.props.info.widget?.sensor ? (this.toTitleCase(this.props.info.type) + ' ') : this.toTitleCase(this.props.info.title)}
            </div>
            {this.props.info.title !== '' ? <Tooltip label={'View on Data Page'}>
                <div className={'button-wrapper'}
                     onClick={this.goToDataPage}><Icon size={17}
                                                       color={'darkgrey'}
                                                       icon={'FiArrowUpRight'}/>
                </div>
            </Tooltip> : null}
        </div>
    }

}

export default WidgetHeader;
