import React from "react";
import {Button, Card, CardBody, CardHeader, Dropdown, Input, Loader, Toast} from "gd-react";
import GridDuck from "gridduck";
import cookie from "react-cookies";

class GatewayAdmin extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        this.state = {updateRef: 0};
        this.updatedExternally = this.updatedExternally.bind(this);
        this.transmitUpdate = this.transmitUpdate.bind(this);
        if (props.item) {
            this.state = {updateRef: 0, updateSlot: this.props.item.updateSlot};
            props.item.on('updated', this.updatedExternally);
            this.updatedExternally();
        }
        GridDuck.getGatewaySoftwareVersions({getAll: true}).then(function (versionList) {
            self.setState({versionList: versionList});
        })
    }

    updatedExternally(field) {
        let self = this;
        this.setState({updateRef: this.state.updateRef++, updateSlot: this.props.item.updateSlot});
        GridDuck.getGatewaySoftwareVersion({id: this.props.item.version})
            .then(function (gatewaySoftwareVersion) {
                self.setState({
                    currentVersion: gatewaySoftwareVersion
                });
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let self = this;
        if (prevProps !== this.props || this.props.item.id !== prevProps.item.id) {
            if (prevProps.item) {
                prevProps.item.off('updated', this.updatedExternally);
            }
            this.props.item.on('updated', this.updatedExternally);
            this.updatedExternally();
        }
    }

    componentWillUnmount() {
        if (this.props.item && this.props.item.off) {
            this.props.item.off('updated', this.updatedExternally);
        }
    }

    transmitUpdate() {
        return this.props.item.set({
            targetVersion: this.state.targetVersion
        });
    }

    render() {
        let self = this;

        let updateSlots = []
        for (let i = 0; i < 24; i++) {
            updateSlots.push({
                value: i,
                title: (i > 9 ? i : '0' + i) + ':00'
            })
        }
        updateSlots.push(
            {
                value: -1,
                title: 'Instant'
            }
        );
        updateSlots.push(
            {
                value: 25,
                title: 'Never'
            }
        );

        let updateSlotButton;

        if (this.state.updateSlot !== this.props.item.updateSlot) {
            updateSlotButton = (
                <Button onClick={() => self.props.item.set({
                    updateSlot: this.state.updateSlot
                })}
                        progressRes
                        additionalclasses={'sm'}
                        color={'gd-green'}
                        label={'Update Slot'}/>
            );
        }

        let currentDetails = [];
        if (this.state.currentVersion) {
            currentDetails = [
                (<h3>{this.state.currentVersion.title} ({this.state.currentVersion.id})</h3>),
                (<h4>"{this.state.currentVersion.versionString}"</h4>),
                (<p>{this.state.currentVersion.notes}</p>)
            ];
        }

        let otaPreview;
        let updateStatus;
        if (this.props.item.updateState !== 'running_normally') {
            let updateSummary = [];
            if (this.state.versionList) {
                let updateItem = this.state.versionList.list.find(v => v.id === this.props.item.targetVersion);
                if (updateItem) {
                    updateSummary = [(<p>Updating to {updateItem.title} ({updateItem.id})</p>)];
                }
            }
            let loader;
            if (this.props.item.updateState) {
                if (this.props.item.updateState.substr(0, 11) === 'downloading') {
                    loader = (<Loader type={'circular'} withprogress size={50}
                                      value={this.props.item.updateState.substr(12)}/>)
                    updateSummary.push(
                        (<p> - Downloading</p>)
                    )
                } else {
                    loader = (<Loader type={'circular'} size={50}/>);
                    let state = this.props.item.updateState;
                    updateSummary.push(
                        (<p> - {state.charAt(0).toUpperCase() + state.slice(1)}</p>)
                    )
                }
            }
            updateStatus = [
                (<h4>Updating Status</h4>),
                (<div className={'row'} style={{marginTop: '20px'}}>
                        {loader}
                        {updateSummary}
                    </div>)
            ]
        }

        if (this.state.targetVersion) {
            let targetUpdate = this.state.versionList.list.find(v => v.id === this.state.targetVersion);
            if (targetUpdate) {
                otaPreview = (
                    <div>
                        <h4>{targetUpdate.title} ({targetUpdate.id})</h4>
                        <h5>"{targetUpdate.versionString}"</h5>
                        <p>{targetUpdate.notes}</p>
                        {targetUpdate.id !== this.props.item.targetVersion ?
                            <Button onClick={self.transmitUpdate} progressRes additionalclasses={'sm'}
                                    onComplete={() => self.setState({targetVersion: null})} color={'gd-green'}
                                    label={'Update'}/> : ''}
                        <br/>
                        {targetUpdate.id !== this.props.item.targetVersion ?
                            <Button onClick={() => self.setState({targetVersion: null})} additionalclasses={'sm'}
                                    color={'gd-red'} label={'Cancel'}/> : ''}
                    </div>
                )
            }
        }
        return (
            <div className={"page grey narrow not-flex"}>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader title={'Update Slot'}/>
                            <CardBody>
                                <Dropdown
                                    value={this.state.updateSlot}
                                    style={{"padding": "10px"}}
                                    onChange={function (e) {
                                        self.setState({updateSlot: e.target.value});
                                    }}
                                    fixeditems={updateSlots}
                                />
                                {updateSlotButton}
                            </CardBody>
                        </Card>
                    </div>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader title={'Current Firmware Version'}/>
                            <CardBody style={{"height": "auto !important"}}>
                                {currentDetails}
                                {updateStatus}
                                <h4>Send OTA Update</h4>
                                <br/>
                            <Dropdown
                                    value={this.state.targetVersion}
                                    style={{"padding": "10px"}}
                                    onChange={function (e) {
                                        self.setState({targetVersion: e.target.value});
                                    }}
                                    fixeditems={this.state.versionList ? this.state.versionList.list.map(v => (
                                        {
                                            title: v.title + ' (' + v.id + ')',
                                            value: v.id
                                        })) : []}
                                />
                                {otaPreview}

                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }

}

export default GatewayAdmin;
