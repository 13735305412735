import React from 'react';
import './DesktopLogin.scss';
import {Link} from "react-router-dom";
import {Input, Button} from "gd-react";
import GridDuck from "gridduck";
import animatedLogo from "../images/animated-logo-once.svg";
import {Icon} from "@material-ui/core";
import history from "../meta/history";

class Forgot extends React.Component {

	constructor(props) {
		super(props);
		this.changeEmail = this.changeEmail.bind(this);
		this.submit = this.submit.bind(this);
		this.state = {
			emailAddress: ''
		};
	}

	changeEmail(target) {
		this.setState({emailAddress: target.target.value});
	}

	submit() {
		let self = this;
		self.setState({triedToSubmit: true});
		if (!this.state.emailAddress.length) {
			return Promise.reject();
		}

		self.setState({submitting: true, progressState: 'loading'});

		return GridDuck.createResetToken({
			emailAddress: this.state.emailAddress
		}).then(function (token) {
			self.setState({progressState: 'success', resetPasswordSent: true});
		}).catch(function (err) {
			if (err.response.status === 404) {
				self.setState({progressState: 'failed', submitting: false, loginError: 'Incorrect email address'});
				setTimeout(function () {
					self.setState({progressState: '', loginError: ''});
				}, 2500)
			} else {
				self.setState({progressState: 'failed', submitting: false, loginError: 'Unknown error'});
				setTimeout(function () {
					self.setState({progressState: '', loginError: ''});
				}, 2500)
			}
		})
	}
}

export default Forgot;
