import React from "react";
import './ListPopover.scss'
import {Menu} from "@material-ui/core";

class ListPopover extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let open = Boolean(this.props.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        return (
            <Menu
                open={open}
                {...this.props}
            >
                {this.props.children}
            </Menu>
        )
    }
}

export default ListPopover