import React from "react";
import {
	Dropdown,
	Button,
	Loader,
	Icon,
	Input
} from "gd-react";
import GridDuck from "gridduck";
import "./CreateAssetPage.scss";
import GenericLoader from "../../components/GenericLoader";

class CreateAssetPage extends React.Component {
	constructor() {
		super();
		this.state = {site: ''};
		let self = this;
		GridDuck.getSites({
			sortBy: {
				field: 'name',
				type: 'asc'
			},
			getAll: true
		}).then(function (sites) {
			self.setState({sites: sites.list});
		});

		this.selectSite = this.selectSite.bind(this);
		this.cancelScan = this.cancelScan.bind(this);
		this.submitAssets = this.submitAssets.bind(this);
		this.scan = this.scan.bind(this);
		this.createAsset = this.createAsset.bind(this);
		this.removeAsset = this.removeAsset.bind(this);
		this.identify = this.identify.bind(this);
		this.setNewAssetName = this.setNewAssetName.bind(this);
		this.scanningResultsUpdated = this.scanningResultsUpdated.bind(this);
		this.setNewAssetImpKwh = this.setNewAssetImpKwh.bind(this);
	}

	setNewAssetImpKwh(newAsset) {
		let self = this;
		return function (val) {
			let newAssets = self.state.newAssets;
			let stateNewAsset = newAssets.find(a => a.code === newAsset.code);
			stateNewAsset.impKwh = val.target.value;
			self.setState({newAssets: newAssets});
		}
	}

	cancelScan() {
		let self = this;
		return GridDuck.getGateways({
			filters: [
				{
					field: 'siteId',
					value: this.state.site.id
				}
			]
		}).then(function (gatewayList) {
			let gateways = gatewayList.list;
			let promises = gateways.map(g => g.setScanning(false));
			return Promise.all(promises);
		}).then(function () {
			self.setState({showScanningInstructions: false, scanningResults: [], scanning: false, newAssets: []});
		});
	}

	submitAssets() {
		let self = this;
		let promises = this.state.newAssets.map(function (newAsset) {
			console.log("new asset", newAsset);
			return GridDuck.createAsset(newAsset);
		});
		return Promise.all(promises).then(function () {
			self.setState({showScanningInstructions: false, scanningResults: [], scanning: false, newAssets: []});
		});
	}

	removeAsset(newAsset) {
		let newAssets = this.state.newAssets ? this.state.newAssets : [];
		let thisAsset = newAssets.find(a => a.code === newAsset.assetCode);
		if (thisAsset) {
			let index = newAssets.indexOf(thisAsset);
			newAssets.splice(index, 1);
			this.setState({newAssets: newAssets});
		}
	}

	identify(assetCode) {
		console.log('identifying', assetCode)

	}

	setNewAssetName(newAsset) {
		let self = this;
		return function (val) {
			let newAssets = self.state.newAssets;
			let stateNewAsset = newAssets.find(a => a.code === newAsset.code);
			stateNewAsset.name = val.target.value;
			self.setState({newAssets: newAssets});
		}
	}

	createAsset(asset) {
		let newAssets = this.state.newAssets ? this.state.newAssets : [];
		newAssets.push({
			code: asset.assetCode,
			gatewayId: asset.gatewayId,
			siteId: this.state.site.id
		});
		this.setState({
			newAssets: newAssets
		});
	}

	selectSite(site) {
		console.log('select site', site.target.value);
		this.setState({site: site.target.value});
	}

	scanningResultsUpdated() {
		if (this.scanningResults.list.length > 0) {
			this.setState({showScanningInstructions: true});
			this.setState({
				scanningResults: this.scanningResults.list.map(r => ({
					displayName: r.displayName,
					gatewayId: r.gatewayId,
					sku: r.sku,
					displayImage: r.displayImage,
					assetCode: r.assetCode
				}))
			})
		}
	}

	scan() {
		let self = this;
		if (self.scanningResults && self.scanningResults.off) {
			self.scanningResults.off('updated', self.scanningResultsUpdated);
		}
		this.setState({showScanningInstructions: false, scanningResults: []});
		return GridDuck.getGateways({
			filters: [
				{
					field: 'siteId',
					value: this.state.site.id
				}
			]
		}).then(function (gatewayList) {
			let gateways = gatewayList.list;
			let promises = gateways.map(g => g.setScanning(true));
			GridDuck.getDetectedDevices({
				filters: [
					{
						field: 'gatewayId',
						value: gateways.map(g => g.id)
					}
				]
			}).then(function (scanningResults) {
				self.scanningResults = scanningResults;
				scanningResults.on('updated', self.scanningResultsUpdated);
			});
			return Promise.all(promises);
		}).then(function () {
			self.setState({scanning: true});
			return Promise.resolve();
		})
	}

	render() {
		let self = this;
		let siteSelect;
		let scanButton;
		let scanningText;
		let scanningLoader;
		let scanningResultsInstructions;
		let scanningResults;
		let addButton;
		let cancelButton;
		if (this.state.sites && !this.state.scanning) {
			siteSelect = (<Dropdown placeholder={"Select a site"} label={"Site"}
									fixeditems={this.state.sites.map(s => ({
										title: s.name,
										value: s,
										id: s.id
									}))}
									name={'Site'}
									value={this.state.site}
									onChange={this.selectSite}/>
			);
			if (this.state.site) {
				scanButton = (
					<Button label={"Scan for devices"} progressRes onClick={this.scan}/>
				)
			}
		}
		if (this.state.scanning) {
			cancelButton = (
				<Button onClick={this.cancelScan} progressRes={true} label={"Cancel scan"} color={"gd-red"}/>);
			scanningText = [(<p className={"scanning-notice"}>Scanning for devices in {this.state.siteName}...</p>), (
				<p className={"scanning-notice"}>This may take a while</p>)];
			scanningLoader = (<GenericLoader/>)
			if (this.state.showScanningInstructions) {
				scanningResultsInstructions = (
					<div className={"scanning-instructions"}>
						1. Click the plus next to the device you want to add<br/>
						2. Name the device you wish to add E.g. "Kitchen Fridge".<br/>
						3. Then add one or more tags that describe the type of device E.g. "Fridge"<br/>
						<br/>
						You can also click the identify button to make the light flash on the device.
					</div>
				)
			}
			let innerScanningResults = [];
			this.state.scanningResults.forEach(function (result) {
				console.log(result);
				let newAsset = self.state.newAssets ? self.state.newAssets.find(a => a.code === result.assetCode) : null;
				if (newAsset) {
					innerScanningResults.push(
						<div className={"scanning-result"}>
							<div className={"image"} style={{backgroundImage: "url(" + result.displayImage + ")"}}/>
							<div className={"description"}><Input nolabel='true'
																  placeholder={result.sku === 'CLI-SEN-POW' ? "Clamp 1 name" : result.sku === 'SOCKET-DOUBLE-OWON' ? "Gang One name" : "Device name"}
																  onChange={self.setNewAssetName(newAsset)}/></div>
							{result.sku === 'CLI-SEN-POW' || result.sku === 'SOCKET-DOUBLE-OWON' ?
								<div className={"description"}><Input nolabel='true' placeholder={result.sku === 'SOCKET-DOUBLE-OWON' ? "Gang Two name" : "Clamp 2 name"}
																	  onChange={self.setNewAssetClamp2Name(newAsset)}/>
								</div> : null}
							{result.sku === 'MET-REA' ?
								<div className={"impKwh"}><Input nolabel='true' placeholder={"Impressions per kWh"}
																 onChange={self.setNewAssetImpKwh(newAsset)}/>
								</div> : null}
							<div className={"add-button"} onClick={() => self.removeAsset(result)}><Icon
								icon={"MdRemove"} size={40}/></div>
							<div className={"identify-button"} onClick={() => self.identify(result)}><Icon
								icon={"IoMdFlashlight"} size={40}/></div>
						</div>
					)
				} else {
					innerScanningResults.push(
						<div className={"scanning-result"}>
							<div className={"image"} style={{backgroundImage: "url(" + result.displayImage + ")"}}/>
							<div className={"description"}>{result.displayName} ({result.assetCode.substr(-4)})</div>
							<div className={"add-button"} onClick={() => self.createAsset(result)}><Icon
								icon={"MdAdd"} size={40}/></div>
							<div className={"identify-button"} onClick={() => self.identify(result)}>
								{this.state.identifying ? <Loader type={'circular'} size={12}/> : <Icon
								icon={"IoMdFlashlight"} size={40}/>}
								</div>
						</div>
					)
				}
			})
			if (this.state.newAssets && this.state.newAssets.length > 0 && !this.state.newAssets.find(a => !a.name)) {
				addButton = (<Button onClick={this.submitAssets}
									 label={"Add device" + (this.state.newAssets.length > 1 ? 's' : '')}/>)
			}
			scanningResults = (<div className={"scanning-results"}>
				{innerScanningResults}
			</div>);
		}
		return (<div className={"create-asset-page"}>
			{siteSelect}
			{scanButton}
			{scanningText}
			{scanningLoader}
			{scanningResultsInstructions}
			{scanningResults}
			<div className={"controls"}>
				{cancelButton}
				{addButton}
			</div>
		</div>);
	}
}

export default CreateAssetPage;
