import React from "react";
import "./InteractiveTooltip.scss";
import {Tooltip} from "@material-ui/core";

class InteractiveTooltip extends React.Component {
    render() {
        return (
            <Tooltip
                {...this.props}
                interactive={true}
                arrow
                title={this.props.title}
                classes={{
                    popper: 'spend-popper',
                    tooltip: 'spend-tooltip',
                    arrow: 'spend-arrow'
                }}
            >
                {this.props.children}
            </Tooltip>
        )
    }
}

export default InteractiveTooltip;
