import React from "react";
import "./GettingStarted.scss";
import QrCode from "../images/installer_qr_code.png";
import createSiteImg from "../images/create_site_in_app.png";
import addHub from "../images/scan_hub_in_app.png";
import addDevices from "../images/add_manual_in_app.png";
import {Icon} from "gd-react";
import GridDuck from "gridduck";

class GettingStarted extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.steps = [
            {
                step_header: 'Download the GridDuck Installer',
                img: QrCode,
                closed: this.props.closed && this.props.closed.one,
                img_class: 'qr-code',
                body_HTML: <p>
                    Scan the QR code with your phones camera app<br/><br/>
                    Or search for "GridDuck Installer" in the app store.
                </p>
            },
            {
                step_header: 'Create or select a site',
                img: createSiteImg,
                closed: this.props.closed && this.props.closed.two,
                img_class: 'create-site',
                body_HTML: <p>
                    Open up the installer app and login.<br/><br/>
                    You can then either select a site or create a new one.
                </p>
            },
            {
                step_header: 'Add your hubs',
                img: addHub,
                closed: this.props.closed && this.props.closed.three,
                img_class: 'add-hub',
                body_HTML: <p>
                    Once inside the site, click the <span className={'plus-icon'}>+</span> button at the
                    bottom of the screen and select
                    "Hub".<br/><br/>
                    Then scan the QR code on the bottom of the hub.<br/><br/>
                    <span className={'lighter-italic'}>Or enter the serial number (SN) and installation code (Inst Code) manually.</span>
                </p>
            },
            {
                step_header: 'Add your devices',
                img: addDevices,
                closed: this.props.closed && this.props.closed.four,
                img_class: 'create-site',
                body_HTML: <p>
                    Click the <span className={'plus-icon'}>+</span> button at the bottom of the screen
                    and select "Devices".<br/><br/>
                    Input the MAC Address, Device Type, and closest Hub to your device.<br/><br/>
                    Tap next to start adding the name and data for your device.<br/><br/>
                    When you're finished, click 'Add Device' to add it to your site.
                </p>
            }
        ]
    }

    componentDidMount() {
        let self = this;
        if (this.props.permission === 'view') {
            GridDuck.getOrganisationMembers({getAll: true}).then((members) => {
                self.setState({orgOwner: members.list.find((m) => m.orgPermission === 'owner').username});
            })
        }
    }

    render() {
        let self = this;
        return (
            <div className={'getting-started-wrapper'}>
                <p className={'gs-header'}>Welcome!</p>
                <p className={'gs-subheader'}>First things first, let's get your kit set up so you can start saving
                    energy.</p>
                {this.props.permission !== 'view' ? <div className={'gs-steps-wrapper'}>
                    {this.steps.map((step, i) => {
                        return <div className={'gs-step'} key={i}>
                            <div className={'gs-step-title-wrapper'} onClick={() => {
                                step.closed = !step.closed;
                                self.setState({update: self.state.update + 1});
                            }}>
                                <p className={'gs-step-number'}>{i + 1}</p>
                                <p className={'gs-step-header'}>{step.step_header}</p>
                                <div className={'gs-step-icon'}><Icon
                                    icon={step.closed ? 'FaChevronUp' : 'FaChevronDown'}/>
                                </div>
                            </div>
                            <div className={'gs-body-wrapper' + (step.closed ? ' closed' : '')}>
                                <div className={'gs-image ' + step.img_class}><img alt={'step-img'} src={step.img}/>
                                </div>
                                <div className={'gs-body-text'}>{step.body_HTML}</div>
                            </div>
                        </div>
                    })}

                </div> : <div className={'get-in-touch'}>
                    <p>You don't have a high enough access level to set up {window._companyName}.<br/><br/>Get in touch with <strong>{this.state.orgOwner}</strong> and ask them to either give you a higher permission level
                        or to follow the steps to get their kit set up.</p>
                </div>}
            </div>
        )
    }
}

export default GettingStarted;