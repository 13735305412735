import React from 'react';
import './TopNav.scss';
import '../styles/_theme.scss'
import {Icon, Button, GlobalSearch, Loader, CardBody, Toast, Menu} from 'gd-react';
import {Tooltip} from "@material-ui/core";
import getFormat from "../services/formatter";

const themes = {
    light: {
        icon: 'FaSun',
        title: 'Light'
    },
    dark: {
        icon: 'FaMoon',
        title: 'Dark'
    }
}

class NotificationsButton extends React.Component {
    componentDidMount() {
        this.notificationAudio = new Audio('/sounds/GD-Notification.mp3');
        this.errorAudio = new Audio('/sounds/GD-Disconnection.mp3');
        this.colours = {
            'info': '#49b9c4',
            'error': '#E43536FF',
            'warning': '#f89d41',
        }
    }

    constructor(props) {
        super(props);
        let self = this;
        this.state = {};

    }

    // todo create gradient for border, background icon

    render() {
        let _colours = '#808080';
        let notificationTypes = this.props.notificationsTypes
        if (notificationTypes && notificationTypes.length) {
            // _colours = notificationTypes.map(nt => this.colours[nt]).toString();
            _colours = 'orange';
        }
        let background = (!notificationTypes || !notificationTypes.length || notificationTypes.length === 1) ? _colours : 'linear-gradient(90deg, ' + _colours + ')';
        console.log(_colours, ' : colours');
        console.log(background, ' : gradient');
        let indicatorDots;
        // switch (notificationTypes?.length) {
        //     case 1:
        //         indicatorDots = <div className={'indicator-container single-type'}>
        //             <div className={'row'}>
        //                 <div className={'dot'} style={{backgroundColor: this.colours[notificationTypes[0]]}}/>
        //             </div>
        //         </div>
        //         break;
        //     case 2:
        //         indicatorDots = <div className={'indicator-container double-type'}>
        //             <div className={'row'}>
        //                 <div className={'dot'} style={{backgroundColor: this.colours[notificationTypes[0]]}}/>
        //                 <div className={'dot'} style={{backgroundColor: this.colours[notificationTypes[1]]}}/>
        //             </div>
        //         </div>
        //         break;
        //     case 3:
        //         indicatorDots = <div className={'indicator-container double-type'}>
        //             <div className={'row'}>
        //                 <div className={'dot'} style={{backgroundColor: this.colours[notificationTypes[0]]}}/>
        //             </div>
        //             <div className={'row'}>
        //                 <div className={'dot'} style={{backgroundColor: this.colours[notificationTypes[1]]}}/>
        //                 <div className={'dot'} style={{backgroundColor: this.colours[notificationTypes[2]]}}/>
        //             </div>
        //         </div>
        //         break;
        //
        // }

        return (
            <div>
                <Tooltip
                    title={'Notifications'}>
                    {/*<div*/}
                    {/*    // className={'top-nav-icon alerts ' + (this.props.notificationsCount ? ' activated' : '') + (this.state.animateNotifications ? ' animate-glow-zoom' : '')}*/}
                    {/*    className={'notification-button-container'}*/}
                    {/*    style={{background: background}}*/}
                    {/*    >*/}
                        <div
                            onClick={this.props.onClick}
                            className={'top-nav-icon' + (this.props.notificationsCount ? ' activated ' : ' no-border ') + ((notificationTypes && notificationTypes.length) ? ' alert ' : ' no-border') + (this.state.animateNotifications ? ' animate-glow-zoom ' : '')}>
                            {this.props.notificationsCount ?
                                <p
                                    className={'not-dot'} style={{
                                    margin: 0,
                                    lineHeight: '15px',
                                    color: _colours
                                }}>{getFormat('comma')(this.props.notificationsCount)}</p>
                                : null}
                            <div className={'icon-w'}>
                                {/*// todo allow for linear gradients on icon svgs, assign with fill property*/}
                                {this.props.incidentsLoaded ? <Icon
                                        icon={'FaBell'}
                                        color={_colours}
                                        // style={{fill: 'red'}}
                                        size={'12'}/> :
                                    <Loader type={'circular'} size={13}/>}
                            </div>
                        {/*</div>*/}
                    </div>
                </Tooltip>

            </div>
        );
    }
}

export default NotificationsButton;
