import React, {Component} from "react";
import "./AddEditCustomField.scss";
import '../../styles/_layout.scss';
import {
    Button,
    Input,
    Dropdown,
    Icon
} from "gd-react";

class CustomFieldsForm extends Component {

    constructor(props) {
        super(props);
        this.errors = {};
        this.changed = false;
        this.state = {}
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    render() {
        console.log(this.props.customFields, ' custom fields props')
        let self = this
        let message;
        if (!this.props.customFields.length) {
            message = <div className={'row'}>
                <p>Click below to create and apply your first custom field</p>
            </div>
        }
        let addNewButton = <div className={'row'} style={{width: '180px', marginTop: '10px'}}>
            <Button label={'+ Add New'}
                    color={"gd-grey"}
                    outline
                    onClick={this.props.addRow}/>
        </div>

        let rows = this.props.customFields.map((r) => {
            let valueInput;
            console.log(r.existingCustomFields, ' existing custom fields')
            switch (r.fieldType) {
                case 'text':
                    if (r.value === 'add_new' || r.existingCustomFields.length === 1) {
                        valueInput = <div style={{
                            width: '300px',
                            marginTop: '-15px',
                            marginBottom: '7px'
                        }}>
                            <Input label={'Value'}
                                   onChange={(e) => {
                                       self.props.editValues(e, r, true)
                                   }}
                                   error={this.props.errors['value' + r.id] && this.props.triedToSave}
                                   placeholder={'Type New Text Value'}
                                   value={r.newValue}
                            />
                        </div>
                    } else {
                        //todo filter fixeditems for already exist in customfields
                        // filter((ef) => customfieldsarray.map
                        valueInput = <div style={{
                            width: '300px',
                        }}>
                            <Dropdown label={'Value'}
                                      onChange={(e) => {

                                          self.props.editValues(e, r, false)
                                      }}
                                      error={this.props.errors['value' + r.id] && this.props.triedToSave}
                                      placeholder={'Select Value'}
                                      fixeditems={
                                          r.existingCustomFields
                                              .filter((ef) => self.props.customFields.map((cf) => cf.customFieldId).indexOf(ef.id) < 0 || ef.id === r.customFieldId)
                                      }
                                      value={r.value}/>
                        </div>
                    }
                    break;
                case 'number':
                    if (r.value === 'add_new' || r.existingCustomFields.length === 1) {
                        valueInput = <div style={{
                            width: '300px', top: 0,
                            display: 'flex',
                            marginTop: '-15px',
                            alignItems: 'self-start'
                        }}>
                            <Input label={'Value'}
                                   onChange={(e) => {
                                       self.props.editValues(e, r, true)
                                   }}
                                   error={this.props.errors['value' + r.id] && this.props.triedToSave}
                                   placeholder={'Type New Number Value'}
                                   type={'number'}
                                   value={r.newValue}
                            />
                        </div>
                    } else {
                        valueInput = <div style={{
                            width: '300px',
                        }}>
                            <Dropdown label={'Value'}
                                      onChange={(e) => {
                                          self.props.editValues(e, r, false)
                                      }}
                                      error={this.props.errors['value' + r.id] && this.props.triedToSave}
                                      placeholder={'Select Value'}
                                      fixeditems={
                                          r.existingCustomFields
                                              .filter((ef) => self.props.customFields.map((cf) => cf.customFieldId).indexOf(ef.id) < 0 || ef.id === r.customFieldId)
                                      }
                                      value={r.value}/>
                        </div>
                    }
                    break;
                case 'boolean':
                    valueInput = <div style={{
                        width: '300px',
                    }}>
                        <Dropdown label={'Value'}
                                  onChange={(e) => {
                                      self.props.editBoolValues(e, r)
                                  }}
                                  placeholder={'Select Value'}
                                  fixeditems={[
                                      {
                                          title: 'True',
                                          value: 'true'
                                      },
                                      {
                                          title: 'False',
                                          value: 'false'
                                      }
                                  ]}
                                  error={this.props.errors['value' + r.id] && this.props.triedToSave}
                                  value={r.value ? 'true' : 'false'}/>
                    </div>
                    break;
                default:
                    valueInput = null;
            }

            //todo return custom field icon AND icon showing custom field type in dropdown below

            console.log(r, ' r given to dropdown')

            return <div key={r.id}>
                <div className={'row'}>
                    <div className={'row'} style={{marginTop: '1px'}}>
                        <div style={{
                            width: '180px', marginRight: '10px',
                        }}>

                            <Dropdown label={'Field'}
                                      onChange={(e) => {
                                          self.props.editCustomFields(e, r)
                                      }}
                                      disabled={r.customFieldId ? true : false}
                                      error={this.props.errors['field' + r.id] && this.props.triedToSave}
                                      placeholder={'Select Field'}
                                      fixeditems={this.props.fixedCustomFieldNames}
                                      value={r.field}/>
                        </div>
                        {valueInput}
                        <div style={{
                            height: '60px',
                            width: '60px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: '11px'
                        }}>
                            <Icon color={'gd-red'} size={'16'} icon={'FaTrash'}
                                  onIconClick={() => {
                                      this.props.removeCustomField(r)
                                  }}/>
                        </div>
                    </div>
                </div>
                <div className={'divider'} style={{marginTop: '10px'}}/>
            </div>
        })
        return (
            <div className={'Add-Edit-Custom-Field'}>
                {rows}
                {this.props.customFields.length === 0 ? message : null}
                {addNewButton}
            </div>

        )
    }
}

export default CustomFieldsForm;