import React from "react";
import {StatusListCell, LastActiveCell, List, StandardListCell} from "gd-react";
import GridDuck from 'gridduck';
import history from "../../meta/history";
import AddHubModal from "../../modals/AddHub/AddHubModal";
import NoItems from "../../components/noItems";


class SiteSettingsGateways extends React.Component {
	constructor(props) {
		super(props);
		this.getList = this.getList.bind(this);
		this.state = {}
		this.goToGateway = this.goToGateway.bind(this);
	}

	componentDidMount() {
		let self = this;
		GridDuck.getAccount({id: GridDuck.userId}).then(function(account) {
			self.setState({orgPermission: account.orgPermission});
		});
	}

	getList(retrievalParams) {
		return GridDuck.getGateways(retrievalParams);
	}

	goToGateway(gateway) {
		history.push("/hub/" + gateway.id);
	}
}

export default SiteSettingsGateways;
