import React, {Component} from "react";
import "./AddPartnerModal.scss";
import '../../styles/_layout.scss';
import {
    Button,
    CardBody,
    Dropdown,
    GdAutocomplete,
    Input,
} from "gd-react";
import InputAdornment from '@material-ui/core/InputAdornment';
import GridDuck from "gridduck";
import moment from "moment-timezone";
import SaveBar from "../../components/SaveBar/SaveBar";
import history from "../../meta/history";
import GenericLoader from "../../components/GenericLoader";
import TemplateCard from "../../pages/create-rule/TemplateCard";
import OptionCard from "../../components/OptionCard/OptionCard";
import '../../pages/create-rule/TemplateCard.scss'
import GdModal from "../../components/GdModal/GdModal";
import * as _ from 'lodash';

class AddPartnerModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.selectEmail = this.selectEmail.bind(this);
        this.searchEmail = this.searchEmail.bind(this);
        this.createPartner = this.createPartner.bind(this);
        this.sitesChanged = this.sitesChanged.bind(this);
        this.getActiveList = this.getActiveList.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.resetOrg = this.resetOrg.bind(this);
        this.moveToTab = this.moveToTab.bind(this);
        this.createOrg = this.createOrg.bind(this);
        this.chunkEmailsIntoValidInvalid = this.chunkEmailsIntoValidInvalid.bind(this)
        this.directionSelect = this.directionSelect.bind(this);
        this.typeSelect = this.typeSelect.bind(this);
        this.checkForExisting = this.checkForExisting.bind(this)
        this.onEmailsChange = this.onEmailsChange.bind(this);
        this.onPartnerDirectionChange = this.onPartnerDirectionChange.bind(this);
        this.errors = {};
        this.changed = false;
        this.state = {
            emailsVerified: null,
            existingEmails: [],
            partnerType: null,
            partnerDirection: 'your',
            partnerDirections: [
                {
                    id: 0,
                    title: 'You are their...',
                    value: 'their'
                },
                {
                    id: 1,
                    title: 'They are your...',
                    value: 'your'
                }
            ],
            orgExists: null,
            ownerOrg: this.props.ownerOrg,
            email: '',
            newEmail: '',
            partnerOrg: null,
            newOrgName: '',
            tab: 0,
            sites: [],
            invites: [],
            availableSites: []
        }
        this.modalClosed = false;
    }

    sitesChanged(fakeEv, newList) {
        console.log("sites changed", newList);
        this.setState({
            sites: newList
        });
        console.log(this.state.sites, ' sites in state')
    }

    getAvailableList(params) {
        return GridDuck.getSites(params);
    }

    getActiveList(params) {
        params.filters = params.filters ? params.filters : [];
        params.filters.push(
            {
                field: 'id',
                value: this.state.sites ? this.state.sites : []
            }
        )
        console.log("getActiveList", params);
        return GridDuck.getSites(params);
    }

    createOrg() {
        let self = this;
        let orgName;
        let orgEmails = [];
        if (this.state.newOrgName) orgName = this.state.newOrgName
        if (this.state.newOrgEmail) orgEmails = this.state.newOrgEmail
        if (orgName && orgEmails) console.log(orgName, 'orgName', orgEmails, ' orgemails')
        this.setState({creatingOrg: false})
    }

    onPartnerDirectionChange(val) {
        this.setState({partnerDirection: val.target.value});
    }

    createPartner() {

        let self = this;

        let newPerm = {
            partnerDirection: this.state.partnerDirection,
            sites: this.state.sites.map((s) => s.id),
            permType: this.state.partnerType
        };
        if (this.state.orgExists === 'false') {
            newPerm.orgName = this.state.newOrgName;
            newPerm.orgEmailAddress = this.state.invites.emails;
        } else {
            newPerm.orgId = this.state.partnerOrgId;
        }

        return GridDuck.createOrgPartner(newPerm).then(function (res) {
            self.closeModal();
            history.push("/organisation/partners");
        })
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    selectEmail(item, other) {
        let self = this;
        this.setState({
            partnerOrgName: other.subTitle,
            partnerOrgId: other.id
        });
        // this.onEmailsChange(item);
        GridDuck.getSites({getAll: true}).then(function (siteList) {
            self.setState({
                loaded: true,
                availableSites: siteList.list.map((s) => ({title: s.name, value: s.id, id: s.id}))
            })
        })
    }

    searchEmail(string) {
        return GridDuck.getOrganisationSearchs({
            filters: [{
                field: 'query',
                value: string
            }]
        }).then(function (results) {
            let fixedResults = results.list.map(function (r) {
                return {
                    id: r.orgId,
                    title: r.username,
                    subTitle: r.orgName,
                    icon: 'GoPerson'
                }
            });
            return Promise.resolve(fixedResults)
        });
    }

    validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    resetOrg() {
        this.setState({selectedOrg: null, selectedOrgId: null});
    }

    typeSelect(type) {
        let self = this
        this.setState({partnerType: type})
        if (type === 'customer') {
            self.existsSelect('false')
            this.moveToTab(2);
        } else {
            this.moveToTab(1);
        }
    }

    directionSelect(relationship) {
        this.setState({partnerDirection: relationship})
    }

    existsSelect(bool) {
        this.setState({orgExists: bool})
        this.moveToTab(2);
    }

    checkForExisting() {
        let enteredEmails = this.state.invites.emails;
        this.setState({existingEmails: []})
        enteredEmails.forEach((e) => {
            return GridDuck.getOrganisationSearchs({
                filters: [{
                    field: 'query',
                    value: e
                }]
            }).then((res) => {
                if (res.list && res.list.length) {
                    console.log(e, ' already exists')
                    let existingEmails = this.state.existingEmails;
                    existingEmails.push(e)
                    this.setState({existingEmails: existingEmails})
                }
            })
        })
    }

    onEmailsChange(val) {
        let self = this
        let emailsSeparated = this.chunkEmailsIntoValidInvalid(val.target.value);
        let invites = this.state.invites;
        invites.emails = emailsSeparated.validEmails;
        self.setState({invites: invites, processed: emailsSeparated, newEmail: val.target.value});
        if (invites.emails.length > 0) this.checkForExisting()
    }

    chunkEmailsIntoValidInvalid(emails) {
        console.log(emails, ' emails at chunk emails func')
        let self = this;
        let parsedEmails = {
            validEmails: [],
            invalidEmails: []
        };

        let emailArray;

        emailArray = emails.split(',');
        emailArray = emailArray.map(function (email) {
            return email.replace(/\s/g, '');
        });

        emailArray.forEach(function (email) {
            if (self.validateEmail(email)) parsedEmails.validEmails.push(email);
            else parsedEmails.invalidEmails.push(email);
        });

        return parsedEmails
    }

    findSites() {
        let self = this;
        GridDuck.getSites({getAll: true}).then(function (siteList) {
            self.setState({
                loaded: true,
                availableSites: siteList.list.map((s) => ({title: s.name, value: s.id, id: s.id}))
            })
        })
    }

    moveToTab(tabId) {
        this.setState({triedToNext: true})
        if (Object.keys(this.errors).length > 0) return;
        this.setState({tab: tabId})
    }

    render() {

        let footer;
        if (this.state.tab === 0) footer =
            <div><Button label={'Next'} disabled={(!this.state.partnerType || !this.state.partnerDirection)}
                         onClick={() => this.moveToTab(1)}/></div>
        if (this.state.tab === 1) footer =
            <div><Button label={'Previous'} onClick={() => {
                this.moveToTab(0)
                this.setState({partnerType: null})
            }}/> <Button label={'Next'}
                         disabled={!this.state.orgExists}
                         onClick={() => this.moveToTab(2)}/>
            </div>
        if (this.state.tab === 2) footer = <div><Button label={'Previous'} onClick={() => {
            if (this.state.partnerType === 'customer') {
                this.moveToTab(0)
                this.setState({orgExists: null, partnerType: null})
            } else {
                this.moveToTab(1)
                this.setState({orgExists: null})
            }
        }}/> <Button
            label={'Next'}
            // disabled={this.state.orgExists === 'true' ? !this.state.partnerOrgName : (!this.state.invites.emails?.length)}
            disabled={this.state.orgExists === 'true' ? !this.state.partnerOrgName : (!this.state.invites.emails?.length || this.state.existingEmails.length > 0 || this.state.newOrgName === '')}
            onClick={() => {
                this.moveToTab(3)
                this.findSites()
            }
            }/></div>
        if (this.state.tab === 3) footer =
            <div><Button label={'Previous'} onClick={() => this.moveToTab(2)}/> <Button
                disabled={this.state.existingEmails.length > 0} label={'Finish'}
                onClick={this.createPartner}/>
            </div>
        let partnerText = this.state.partnerType;
        if (this.state.partnerType === 'customer') {
            partnerText = 'client';
        }

        return (
            <GdModal
                title={`Add ${_.capitalize(partnerText) || 'Partner'}`}
                open={this.props.open}
                footer={footer}
                onClose={this.closeModal}>
                {this.state.tab === 0 ?
                    <CardBody>
                        <div className={'modal-question-container'}>
                            <p className={'question'}>This organisation is your...</p>
                            {/*<Dropdown value={this.state.partnerDirection} name={'partnerDirection'} label={''}*/}
                            {/*          fixeditems={this.state.partnerDirections}*/}
                            {/*          onChange={this.onPartnerDirectionChange}/>*/}
                        </div>
                        <OptionCard selected={this.state.partnerType === 'installer'}
                                    onClick={() => this.typeSelect('installer')} type={'installer'}/>
                        <OptionCard selected={this.state.partnerType === 'customer'}
                                    onClick={() => this.typeSelect('customer')} type={'customer'}/>
                    </CardBody> : ''}
                {this.state.tab === 1 ?
                    <CardBody>
                        <div className={'modal-question-container'}>
                            <p className={'question'}>Does the organisation have a GridDuck account?</p>
                        </div>
                        <OptionCard ptype={this.state.partnerType} selected={this.state.orgExists === 'true'}
                                    onClick={() => this.existsSelect('true')}
                                    type={'chooseExisting'}/>
                        <OptionCard ptype={this.state.partnerType} selected={this.state.orgExists === 'false'}
                                    onClick={() => this.existsSelect('false')}
                                    type={'createNew'}/>
                    </CardBody> : ''}
                {this.state.tab === 2 ?
                    <CardBody>
                        <div className={'modal-question-container'}
                             style={{flexDirection: 'column', alignItems: 'start'}}>
                            <p style={{marginBottom: '20px'}}
                               className={'question'}>{this.state.orgExists === 'true' ? 'Choose your existing partner' : 'Enter details of organisation you want to create'}</p>
                            {this.state.orgExists === 'true' ?
                                <p>Search for the organisation by entering the email address of one of it's
                                    members</p> : null}
                        </div>
                        {this.state.orgExists === 'true' ?
                            <div className={'tabs'} style={{marginBottom: '30px', marginTop: '15px'}}>
                                <GdAutocomplete
                                    autoFocus
                                    async
                                    inputblur={this.blurTextInput}
                                    label={'Member\'s email address'}
                                    onChange={this.selectEmail}
                                    getList={this.searchEmail}
                                    placeholder={'example@gridduck.com'}/>
                                {this.state.partnerOrgName ? <p style={{
                                    marginTop: '10px',
                                    padding: '20px 25px',
                                    fontSize: '12px',
                                    borderRadius: '5px',
                                    backgroundColor: 'whitesmoke'
                                }}><span style={{
                                    fontWeight: '700',
                                    fontSize: '14px',
                                }}>{this.state.partnerOrgName ? this.state.partnerOrgName : null}</span></p> : null}
                            </div> : <div className={'column'}>
                                <Input required
                                       onChange={(val) => this.setState({newOrgName: val.target.value})}
                                       top={'true'}
                                       name={'orgName'} value={this.state.newOrgName}
                                       label={'Name of organisation you\'re inviting to GridDuck'}/>
                                <Input required
                                       onChange={this.onEmailsChange}
                                       multiline
                                       inputProps={{minRows: 3, maxRows: 4}}
                                       name={'orgEmail'} value={this.state.newEmail}
                                       label={"Email addresses of members you'd like to invite (separated by ,)"}/>
                                <div className={'email-error'}>
                                    {this.state.existingEmails.length > 0 ?
                                        <p>GridDuck account already registered to an existing user </p> : null}
                                </div>
                            </div>}
                    </CardBody> : ''}
                {this.state.tab === 3 ?

                    <CardBody>
                        <div className={'modal-question-container'}>
                            <p className={'question'}>Do you want to give access to any existing sites?</p>
                        </div>
                        {this.state.loaded ?
                            <div className={'row tabs'} style={{marginBottom: '30px', marginTop: '15px'}}>
                                <GdAutocomplete
                                    filterSelectedOptions
                                    multiple
                                    value={this.state.sites}
                                    name={'sites'}
                                    placeholder={'Select sites'}
                                    options={this.state.availableSites}
                                    onChange={this.sitesChanged}
                                    label="Sites to give admin access to"/>
                            </div>
                            : (<GenericLoader type={'circular'}/>)}
                    </CardBody> : ''}
            </GdModal>
        )
    }
}

export default AddPartnerModal
