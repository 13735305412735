import React from "react";
import {Card, CardBody, CardHeader, Button, Icon} from "gd-react";
import IconSearch from "../../components/IconSearch/IconSearch";

class TestIcons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            colour: '',
            selectedIcon: ''
        };
    }

    render() {
        return (
            <div className='page narrow Account not-flex grey'>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader>Icons</CardHeader>
                            <CardBody>
                                <div className={'row'} style={{marginBottom: '20px', alignItems: 'end'}}>
                                    {this.state.selectedIcon ? <Icon color={this.state.colour} icon={this.state.selectedIcon} size={'50'}/> : null}
                                    <p style={{paddingLeft: '10px'}}>{this.state.selectedIcon}</p>
                                </div>
                                <div className={'row'}>
                                    <Button label={'Change Icon'}
                                            onClick={(e) => this.setState({anchorEl: e.currentTarget})}/>
                                </div>
                                <IconSearch onClose={(icon) => this.setState({anchorEl: null, selectedIcon: icon ? icon : this.state.selectedIcon})}
                                            onColourChange={(c) => this.setState({colour: c, changedCustomFieldType: true})}
                                            onIconChange={(i) => this.setState({selectedIcon: i, changedCustomFieldType: true})}
                                            anchorEl={this.state.anchorEl}/>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default TestIcons;