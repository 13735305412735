import React from "react";
import {Input} from "gd-react";
import "./OpeningHours.scss";
import Toggle from "../Toggle/Toggle";

class OpeningHourDay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            update: 0,
            open: '',
            close: ''
        };
        this.updateTextInput = this.updateTextInput.bind(this);
    }

    updateTextInput (val) {
        this.state[val.target.name] = val.target.value;
        let update = this.state.update++;
        this.setState({update: update})
        this.props.updateValue(val.target.name, val.target.value);
    }

    componentDidMount() {
        this.setState({open: this.props.day.open, close: this.props.day.close})
    }

    render() {
        if (this.props.day) {
            return (
                <div className={'row opening-hour'}>
                    <p className={'day'}>{this.props.day.day}</p>
                    <div className={'toggle-wrap'}>
                        <Toggle onClick={(val) => {
                            this.props.updateValue('not_closed', val);
                        }} inactivestatename={'Closed'}
                                activestatename={'Open'}
                                active={this.props.day.not_closed}/>
                    </div>
                    {this.props.day.not_closed === 'true' ? <div className={'row'}>
                        <Input top={'true'} type={'time'} required label={""} name={'open'} placeholder={'09:00'}
                               value={this.state.open}
                               onChange={this.updateTextInput}/>
                        <p className={'dash'}>-</p>
                        <Input top={'true'} type={'time'} required name={'close'} label={""} placeholder={'17:00'}
                               value={this.state.close}
                               onChange={this.updateTextInput}/>
                    </div> : null}
                </div>
            )
        } else return null;
    }
}

export default OpeningHourDay;