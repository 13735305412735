import React from "react";
import {Card, CardBody, Icon} from "gd-react";
import './OptionCard.scss'

class OptionCard extends React.Component {
    render() {
        let icon, name, description, color;
        if (this.props.type) {
            switch (this.props.type) {
                case 'installer':
                    icon = 'FaTools';
                    color = 'gd-blue'
                    name = 'Installer';
                    description = 'This organisation will have create and edit access to the sites that you choose, and be able to make new sites for you';
                    break;
                case 'customer':
                    icon = 'FaBriefcase';
                    color = 'orange'
                    name = 'Client';
                    description = 'This organisation will have create and edit access to only the sites that you choose, and view the dashboard with your company brand';
                    break;
                case 'createNew':
                    icon = 'FaUserPlus';
                    color = '#49c570'
                    name = 'No, create a new organisation';
                    // description = 'Create a new organisation and assign as your installer';
                    break;
                case 'chooseExisting':
                    icon = 'FaUserCheck';
                    color = 'gd-blue'
                    name = 'Yes, choose an existing organisation';
                    // description = 'Choose an existing organisation to be assigned as your installer';
                    break;
                case 'theyareyour':
                    name = 'They are your...';
                    description = 'whatevevralskdadsasdasdasd';
                    break;
                case 'youaretheir':
                    name = 'You are their...';
                    description = 'whatevevralskdads';
                    break;
                default:
                    icon = 'GiMaterialsScience';
                    color = 'gd-blue'
                    name = 'Advanced';
                    description = 'Advanced mode - pick and choose what you want';
            }
        }
        return (
            <div className={this.props.selected ? "partner-card selected" : "partner-card"} {...this.props}>
                <div className={'partner-card-contents'}>
                    {this.props.textOnly ? null : <div className={"left-section"}>
                        <Icon color={color ? color : this.props.color} icon={icon ? icon : this.props.icon}
                              size={!this.props.size ? 30 : this.props.size}/>
                    </div>}
                    <div className={"right-section"}>
                        <h4>{name ? name : this.props.name}</h4>
                        {this.props.textOnly ? null : <div><p>{description ? description : this.props.description}</p></div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default OptionCard;