import {Icon, Input} from "gd-react";
import React, {Component} from "react";
import '../../styles/_layout.scss';
import './Gaia.scss'

class MessageBox extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.state = {
            newMessage: '',
            updateRef: 0
        }
        this.sendMessage = this.sendMessage.bind(this);
    }

    sendMessage(newMessage) {
        if (this.props.disabled) return;
        let _newMessage = newMessage + '';
        this.setState({newMessage: ''});
        this.props.sendMessage(_newMessage);
    }

    render() {
        return (
            <div className={'row space-between message-box'}>
                <Input onKeyPress={(event) => {
                    if (event.key === 'Enter' && this.state.newMessage) {
                        this.sendMessage(this.state.newMessage);
                    }

                }} onChange={(e) => this.setState({newMessage: e.target.value})} autoFocus value={this.state.newMessage}
                       placeholder={this.props.placeholder || 'Message gaia...'}/>
                <div className={'send-button ' + (!this.state.newMessage.length ? 'no-pointer' : '')}
                     onClick={() => this.state.newMessage ? this.sendMessage(this.state.newMessage) : null}>
                    <Icon disabled={!this.state.newMessage.length} color={'gd-brand'} icon={'IoMdSend'}/>
                </div>
            </div>
        )
    }
}


export default MessageBox;