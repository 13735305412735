import "./Legend.scss";
import React from "react";
import {Button, DateTimeRangePicker, Dropdown, Icon, Tooltip, GdCheckbox} from "gd-react";
import moment from "moment-timezone";
import {floorFiveMinutes} from "../services/dateManipulation";

class LegendRange extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onClick = this.onClick.bind(this);
        this.reset = this.reset.bind(this);
        this.toggleLegend = this.toggleLegend.bind(this);
        this.rangeUpdated = this.rangeUpdated.bind(this);
        this.compareRangeUpdated = this.compareRangeUpdated.bind(this);
        this.dateSelection = this.dateSelection.bind(this);
        this.granularitySelection = this.granularitySelection.bind(this);
        this.cancelComparing = this.cancelComparing.bind(this);
        this.setInitialComparison = this.setInitialComparison.bind(this);
        this.compareDateSelection = this.compareDateSelection.bind(this);
        let compareOptions = [
            {
                value: 'previous_15_mins',
                comparingTo: 'last_15_mins',
                title: 'Previous 15 mins'
            },
            {
                value: 'yesterday_so_far',
                comparingTo: 'today_so_far',
                title: 'Yesterday'
            },
            {
                value: 'previous_day',
                comparingTo: 'yesterday',
                title: 'Previous Day'
            },
            {
                value: 'last_week_so_far',
                comparingTo: 'week_so_far',
                title: 'Last Week'
            },
            {
                value: 'previous_week',
                comparingTo: 'last_week',
                title: 'Previous Week'
            },
            {
                value: 'last_month_so_far',
                comparingTo: 'month_so_far',
                title: 'Last Month'
            },
            {
                value: 'previous_month',
                comparingTo: 'last_month',
                title: 'Previous Month'
            },
            {
                value: 'last_year_so_far',
                comparingTo: 'year_so_far',
                title: 'Last Year'
            },
            {
                value: 'previous_year',
                comparingTo: 'last_year',
                title: 'Previous Year'
            },
            {
                value: 'custom',
                title: 'Custom'
            }
        ];
        // if (this.props.isAll) {
        //     compareOptions = [
        //         {
        //             value: 'previous_day',
        //             comparingTo: 'yesterday',
        //             title: 'Previous Day'
        //         },
        //         {
        //             value: 'previous_week',
        //             comparingTo: 'last_week',
        //             title: 'Previous Week'
        //         },
        //         {
        //             value: 'previous_month',
        //             comparingTo: 'last_month',
        //             title: 'Previous Month'
        //         }
        //     ];
        // }
        console.log(this.props, ' : this.props graph')

        this.state = {
            start: this.props.start,
            end: this.props.end,
            dateSelection: this.props.dRString ? this.props.dRString : this.props.isAll ? 'week_so_far' : 'week_so_far',
            granularity: this.props.graphType === 'hour_of_day' ? 60 * 60 : this.props.granularity ? this.props.granularity : this.props.dRString && this.props.dRString === 'last_15_mins' ? 30 : 60 * 60,
            highlighted: [],
            hidden: [],
            isComparing: this.props.cRString ? true : false,
            open: this.props.startCollapsed ? false : true,
            compareStart: this.props.compareStart,
            compareEnd: this.props.compareEnd,
            compareSelectValue: this.props.compareStart ? [new Date(this.props.compareStart * 1000), new Date(this.props.compareEnd * 1000)] : [],
            compareDateSelection: this.props.cRString || '',
            comparisonOptions: compareOptions
        }
    }

    componentDidMount() {
        if (this.props.updateonload) {
            this.dateSelection({target: {value: this.state.dateSelection, firstTime: true}});
        }
    }

    // componentWillUnmount() {
    //     this.setState({
    //         start: null, end: null, dateSelection: null,
    //         granularity: null,
    //         isComparing: null,
    //         compareStart: null,
    //         compareEnd: null,
    //         compareSelectValue: null,
    //         compareDateSelection: null
    //     })
    // }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.dRString !== undefined && this.props.dRString !== this.state.dateSelection && this.props.dRString !== prevProps.dRString) {
            this.setState({dateSelection: this.props.dRString});
        }
        if (this.props.start !== this.state.start && this.props.start !== prevProps.start) {
            this.setState({start: this.props.start});
        }
        if (this.props.end !== this.state.end && this.props.end !== prevProps.end) {
            this.setState({end: this.props.end});
        }
        if (this.props.granularity !== this.state.granularity && this.props.granularity !== prevProps.granularity) {
            this.setState({granularity: this.props.granularity});
        }
        // Object.entries(this.props).forEach(([key, val]) =>
        // prevProps[key] !== val && console.log(`Prop '${key}' changed`)
        // );
        if (this.state) {
            // Object.entries(this.state).forEach(([key, val]) =>
            // prevState[key] !== val && console.log(`State '${key}' changed`)
            // );
        }
    }

    reset() {
        this.setState({
            highlighted: [],
            hidden: []
        });
        this.props.onHover([]);
        this.props.onHide([]);
        this.props.resetZoom();
    }

    granularitySelection(e) {
        let self = this;
        let granularity = e.target.value;
        self.setState({
            granularity: granularity,
            highlighted: [],
            hidden: []
        }, function () {
            if (self.state.compareDateSelection) {
                self.props.set(self.state.start, self.state.end, {
                    start: self.state.compareStart,
                    end: self.state.compareEnd,
                    show: true
                }, granularity);
            } else {
                self.props.set(self.state.start, self.state.end, null, granularity);
            }
        });
    }

    dateSelection(e) {
        let self = this;
        let start;
        let end;
        let granularity;

        if (this.props.onSelectionTypeChange) this.props.onSelectionTypeChange(e.target.value);

        switch (e.target.value) {
            case 'last_15_mins':
                start = moment().tz(self.props.timezone).subtract(15, 'minutes').unix();
                end = moment().tz(self.props.timezone).unix();
                granularity = 30;
                break;
            case 'today_so_far':
                start = moment().tz(self.props.timezone).startOf('day').unix();
                end = floorFiveMinutes(moment().tz(self.props.timezone).unix());
                granularity = 60 * 30;
                break;
            case 'yesterday':
                start = moment().tz(self.props.timezone).subtract(1, 'day').startOf('day').unix()
                end = moment().tz(self.props.timezone).startOf('day').unix();
                granularity = 60 * 30;
                break;
            case 'week_so_far':
                start = moment().tz(self.props.timezone).startOf('isoWeek').unix();
                end = floorFiveMinutes(moment().tz(self.props.timezone).unix());
                granularity = self.props.graphType === 'hour_of_day' ? 60 * 60 : 60 * 30;
                break;
            case 'last_week':
                start = moment().tz(self.props.timezone).subtract(7, 'day').startOf('isoWeek').unix();
                end = moment().tz(self.props.timezone).startOf('isoWeek').unix();
                granularity = 60 * 30;
                break;
            case 'month_so_far':
                start = moment().tz(self.props.timezone).startOf('month').unix();
                end = floorFiveMinutes(moment().tz(self.props.timezone).unix());
                granularity = self.props.graphType === 'hour_of_day' ? 60 * 60 : 60 * 60 * 24;
                break;
            case 'last_month':
                start = moment().tz(self.props.timezone).subtract(1, 'month').startOf('month').unix();
                end = moment().tz(self.props.timezone).startOf('month').unix();
                granularity = self.props.graphType === 'hour_of_day' ? 60 * 60 : 60 * 60 * 24;
                break;
            case 'year_so_far':
                start = moment().tz(self.props.timezone).startOf('year').unix();
                end = floorFiveMinutes(moment().tz(self.props.timezone).unix());
                granularity = self.props.graphType === 'hour_of_day' ? 60 * 60 : 'monthly';
                break;
            case 'last_year':
                start = moment().tz(self.props.timezone).subtract(1, 'year').startOf('year').unix();
                end = moment().tz(self.props.timezone).startOf('year').unix();
                granularity = self.props.graphType === 'hour_of_day' ? 60 * 60 : 'monthly';
                break;
            case 'custom':
                start = this.state.start || moment().tz(self.props.timezone).subtract(7, 'day').startOf('isoWeek').unix();
                end = this.state.end || moment().tz(self.props.timezone).startOf('isoWeek').unix()
                granularity = self.props.graphType === 'hour_of_day' ? 60 * 60 : 60 * 60 * 24;
                this.setState({
                    start: start,
                    end: end,
                    dateSelection: e.target.value,
                    highlighted: [],
                    hidden: [],
                    isComparing: false,
                    compareDateSelection: '',
                    granularity: granularity
                }, function () {
                    self.props.onIsComparingChange(false);
                    console.log(e.target.firstTime, ' : e.target.firstTime');
                    self.props.set(self.state.start, self.state.end, null, granularity, {dRString: e.target.value, firstTime: e.target.firstTime});
                });
                return;
            default:
                start = moment().tz(self.props.timezone).startOf('day').unix();
                end = moment().tz(self.props.timezone).unix();
                break;
        }

        let hour = 3600, day = hour * 24, week = day * 7, month = week * 4.345, year = month * 12;

        if (self.props.graphType !== 'hour_of_day' && self.props.graphType !== 'day_of_week') {
            let distance = end - start;

            if (distance >= year) granularity = 'monthly';
            if (distance > month * 2 && distance < year) granularity = week;
            if (distance <= month * 2 && distance >= week * 2) granularity = day;
            if (distance < day * 2 && distance >= hour * 2) granularity = hour / 2;
        }

        this.setState({
            dateSelection: e.target.value,
            start: start,
            end: end,
            highlighted: [],
            hidden: [],
            isComparing: false,
            compareDateSelection: '',
            compareStart: null,
            compareEnd: null,
            granularity: granularity,
        }, function () {
            self.props.onIsComparingChange(false);
            self.props.set(self.state.start, self.state.end, null, granularity, {dRString: e.target.value, firstTime: e.target.firstTime});
        })
    }

    cancelComparing() {
        let self = this;
        this.setState({
            isComparing: false,
            compareStart: null,
            compareEnd: null,
            compareDateSelection: '',
            highlighted: [],
            hidden: []
        }, function () {
            self.props.onIsComparingChange(false);
            self.props.set(self.state.start, self.state.end, null, self.state.granularity, {
                dRString: this.dRString,
                cRString: null
            });
        });
    }

    rangeUpdated(e) {
        let timePeriod = (e.end.getTime() - e.start.getTime()) / 1000;
        let granularity = 60 * 5;
        if (timePeriod > 2 * 24 * 60 * 60) {
            granularity = 60 * 60;
        }
        if (timePeriod > 7 * 24 * 60 * 60) {
            granularity = 60 * 60 * 24;
        }
        if (timePeriod > 100 * 24 * 60 * 60) {
            granularity = 'monthly';
        }
        let self = this;
        this.setState({
            start: e.start.getTime() / 1000,
            end: e.end.getTime() / 1000,
            granularity: granularity,
            isComparing: false,
            compareStart: null,
            compareEnd: null,
            compareDateSelection: '',
            highlighted: [],
            hidden: []
        }, function () {
            self.props.onIsComparingChange(false);
            self.props.set(self.state.start, self.state.end, null, self.state.granularity, {
                dRString: self.state.dateSelection
            });
        })

    }

    compareDateSelection(e) {
        let self = this;
        //1. Check what the selection is, if blank then fill with the default as the non custom option
        let compareDateSelection = e.target.value, start, end;
        this.setState({compareDateSelection: compareDateSelection})

        switch (e.target.value) {
            case 'previous_15_mins':
                start = moment().tz(self.props.timezone).subtract(30, 'minutes').unix();
                end = moment().tz(self.props.timezone).subtract(15, 'minutes').unix();
                break;
            case 'yesterday_so_far':
                start = moment().tz(self.props.timezone).startOf('day').subtract(1, 'day').unix();
                end = floorFiveMinutes(moment().tz(self.props.timezone).subtract(1, 'day').unix());
                break;
            case 'previous_day':
                start = moment().tz(self.props.timezone).subtract(2, 'days').startOf('day').unix()
                end = moment().tz(self.props.timezone).subtract(1, 'days').startOf('day').unix();
                break;
            case 'last_week_so_far':
                start = moment().tz(self.props.timezone).startOf('isoWeek').subtract(1, 'week').unix();
                end = floorFiveMinutes(moment().tz(self.props.timezone).subtract(7, 'days').unix());
                break;
            case 'previous_week':
                start = moment().tz(self.props.timezone).subtract(2, 'weeks').startOf('isoWeek').unix();
                end = moment().tz(self.props.timezone).subtract(1, 'weeks').startOf('isoWeek').unix();
                break;
            case 'last_month_so_far':
                start = moment().tz(self.props.timezone).startOf('month').subtract(1, 'month').unix();
                end = floorFiveMinutes(moment().tz(self.props.timezone).subtract(1, 'month').unix());
                break;
            case 'previous_month':
                start = moment().tz(self.props.timezone).subtract(2, 'months').startOf('month').unix();
                end = moment().tz(self.props.timezone).subtract(1, 'months').startOf('month').unix();
                break;
            case 'last_year_so_far':
                start = moment().tz(self.props.timezone).startOf('year').subtract(1, 'year').unix();
                end = floorFiveMinutes(moment().tz(self.props.timezone).subtract(1, 'year').unix());
                break;
            case 'previous_year':
                start = moment().tz(self.props.timezone).subtract(2, 'years').startOf('year').unix();
                end = moment().tz(self.props.timezone).subtract(1, 'years').startOf('year').unix();
                break;
            case 'custom':
                if (!this.state.compareStart && !this.state.compareEnd) {
                    let curStartUnix = self.state.start;
                    let curEndUnix = self.state.end;
                    start = curStartUnix - (curEndUnix - curStartUnix);
                    end = curStartUnix
                } else {
                    start = self.state.compareStart;
                    end = self.state.compareEnd;
                }
        }
        this.cRString = e.target.value;
        this.compareRangeUpdated({start: start, end: end, manually: true})
        self.props.onIsComparingChange(e.target.value);
    }

    setInitialComparison() {
        let self = this;

        this.setState({isComparing: true}, () => {
            self.compareDateSelection({
                target: self.state.comparisonOptions.filter((pv) => pv.comparingTo === self.state.dateSelection || pv.value === 'custom')[0],
                firstTime: true
            });
        });
    }

    compareRangeUpdated(e) {
        let self = this;
        let updateObj = {}
        let compareStart = e.manually ? e.start : e.start.getTime() / 1000;
        let compareEnd = e.manually ? e.end : e.end.getTime() / 1000;
        updateObj = {
            compareStart: compareStart,
            isComparing: compareStart && compareEnd,
            compareEnd: compareEnd,
            highlighted: [],
            hidden: [],
            compareSelectValue: compareStart && compareEnd ? [new Date(compareStart * 1000), new Date(compareEnd * 1000)] : null
        };
        if (!e.manually) {
            this.cRString = 'custom';
        }

        this.setState(updateObj, function () {
            self.props.set(self.state.start, self.state.end, {
                start: self.state.compareStart,
                end: self.state.compareEnd,
                show: true
            }, self.state.granularity, {
                dRString: self.state.dateSelection,
                cRString: self.cRString
            });
        })
    }

    toggleLegend() {
        this.setState({open: !this.state.open});
    }

    onMouseEnter(item) {
        if (this.props.onHover) {
            if (!~this.state.highlighted.indexOf(item.id)) {
                this.setState(state => {
                    let list = [...state.highlighted, item.id];
                    this.props.onHover(list);
                    return {
                        highlighted: list,
                        hidden: state.hidden,
                    };
                });
            }
        }
    }

    onMouseLeave(item) {
        if (this.props.onHover) {
            if (~this.state.highlighted.indexOf(item.id)) {
                this.setState(state => {
                    const list = state.highlighted.filter(hitem => hitem !== item.id);
                    this.props.onHover(list);
                    return {
                        hidden: state.hidden,
                        highlighted: list,
                    };
                });
            }
        }
    }

    onClick(item) {
        if (this.props.onHide) {
            if (!~this.state.hidden.indexOf(item.id)) {
                this.setState(state => {
                    let list = [...state.hidden, item.id];
                    this.props.onHide(list);
                    return {
                        modified: true,
                        hidden: list,
                        highlighted: state.highlighted,
                    };
                });
            } else {
                this.setState(state => {
                    const list = state.hidden.filter(hitem => hitem !== item.id);
                    this.props.onHide(list);
                    return {
                        modified: true,
                        highlighted: state.highlighted,
                        hidden: list,
                    };
                });
            }
        }
    }

    render() {
        let dateSelect;
        let compareSelect;
        let dateSelectValue = [new Date(this.state.start * 1000), new Date(this.state.end * 1000)];
        let customSelect;
        if (this.state.dateSelection === 'custom') {
            customSelect = (
                <DateTimeRangePicker
                    summaryMode={this.props.summaryMode}
                    disabled={this.props.disabled}
                    pastOnly
                    cleanable={false} key={'selector'} placement={'auto'} onChange={this.rangeUpdated}
                    nolabel={true} value={dateSelectValue}/>);
        }
        if (((this.props.graph === 'line') || this.props.graph === 'bar' || this.props.graphType === 'hour_of_day' || this.props.graphType === 'breakdown' || this.props.graphType === 'site_breakdown' || this.props.graphType === 'day_of_week') && this.props.graphType !== 'heatmap') {
            // if ((!this.props.isAll) || (this.state.dateSelection !== 'last_month')) {
            compareSelect = (
                <Button additionalclasses={'mini'} disabled={this.props.disabled} label={"Compare to..."}
                        onClick={this.setInitialComparison}/>);
            // } else if (this.state.isComparing) {
            compareSelect = [
                (!this.props.summaryMode ?
                    <span key={'ctl'} className={'compare-to-label'}>Compare to</span> : null),
                (<div key={'ctl2'}
                      className={'row'}>
                    <Dropdown disabled={this.props.disabled} key={"dropdown-date"} nolabel='true'
                              borderless
                              noMargin
                              icon={'FaArrowsAltH'}
                              placeholder={'Compare to...'}
                        // cancelIcon={!this.props.disabled}
                        // cancelFunction={this.cancelComparing}
                              onChange={this.compareDateSelection}
                              fixeditems={this.state.comparisonOptions.filter((pv) => pv.comparingTo === this.state.dateSelection || pv.value === 'custom')}
                              value={this.state.compareDateSelection}/>
                </div>)
            ]
            if (this.state.compareDateSelection === 'custom') {
                compareSelect.push((
                    <DateTimeRangePicker
                        disabled={this.props.disabled}
                        summaryMode={this.props.summaryMode}
                        key={'ctl3'}
                        pastOnly
                        value={this.state.compareSelectValue}
                        onChange={this.compareRangeUpdated}
                        fixedDuration={(this.state.end - this.state.start)}/>
                ))
            }
            compareSelect.push(!this.props.disabled && this.state.isComparing ?
                <Tooltip label={'Remove comparison'}>
                    <div className={'cancel-compare'}><Icon color={'gd-red'} size={'13'} icon={'FaTimes'}
                                                            onIconClick={this.cancelComparing}/>
                    </div>
                </Tooltip> : null)
            // }
        }
        let dateSelectRange = [
            {
                value: 'today_so_far',
                title: 'Today so far'
            },
            {
                value: 'yesterday',
                title: 'Yesterday'
            },
            {
                value: 'week_so_far',
                title: 'Week So Far'
            },
            {
                value: 'last_week',
                title: 'Last Week'
            },
            {
                value: 'month_so_far',
                title: 'Month So Far'
            },
            {
                value: 'last_month',
                title: 'Last Month'
            },
            {
                value: 'year_so_far',
                title: 'Year So Far'
            },
            {
                value: 'last_year',
                title: 'Last Year'
            },
            {
                value: 'custom',
                title: 'Custom'
            }
        ];
        if (this.props.isAll) {
            dateSelectRange = dateSelectRange.slice(1);
        }

        if (this.props.graphType === 'heatmap') {
            dateSelectRange = [
                {
                    value: 'yesterday',
                    title: 'Yesterday'
                },
                {
                    value: 'week_so_far',
                    title: 'Week So Far'
                },
                {
                    value: 'last_week',
                    title: 'Last Week'
                },
                {
                    value: 'month_so_far',
                    title: 'Month So Far'
                },
                {
                    value: 'last_month',
                    title: 'Last Month'
                },
                {
                    value: 'custom',
                    title: 'Custom'
                }
            ]
        }

        if ((this.props.category !== 'Gas' && this.props.category !== 'Water' || this.props.category !== 'Hot Water') && !this.props.isAll && this.props.graphType !== 'heatmap') {
            dateSelectRange.unshift({
                value: 'last_15_mins',
                title: 'Last 15 mins'
            });
        }

        if (!dateSelectRange.find(d => d.value === this.state.dateSelection)) {
            this.dateSelection({
                target: {
                    value: dateSelectRange[0].value
                }
            })
        }
        if (this.state.compareDateSelection && this.state.compareDateSelection !== 'custom' && this.state.compareOptions && !this.state.compareOptions.find(d => d.value === this.state.compareDateSelection)) {
            this.compareRangeUpdated({
                target: {
                    value: this.state.compareOptions[0].value
                }
            })
        }

        dateSelect = (<div className={"label"}>
            {!this.props.summaryMode ? <span>Date Range</span> : null}
            <Dropdown disabled={this.props.disabled} key={"dropdown-date"} nolabel='true' onChange={this.dateSelection}
                      borderless
                      icon={'FaCalendar'}
                      fixeditems={dateSelectRange} value={this.state.dateSelection}/>
            {customSelect}

            {compareSelect}
        </div>)
        let granularity;
        let granularityOptions = [];

        if (this.props.time === 'realtime' && (this.props.graphType === 'breakdown' || this.props.graphType === 'site_breakdown')) {
            dateSelect = null;
        }

        let period = this.state.end - this.state.start;
        let hour = 60 * 60;
        if (period <= hour && !this.props.isAll) {
            if (period > hour / 2) {
                granularityOptions.unshift({
                    value: 900,
                    title: 'Every 15 Minutes'
                })
            }
            granularityOptions.unshift({
                value: 300,
                title: 'Every 5 Minutes'
            })
            granularityOptions.unshift({
                value: 30,
                title: 'Every 30 Seconds'
            })
        }
        if (period > hour && period < hour * 24 * 7) {
            if (!this.props.isAll) {
                if (period > hour / 2) {
                    granularityOptions.unshift({
                        value: 900,
                        title: 'Every 15 Minutes'
                    })
                }
                granularityOptions.unshift({
                    value: 300,
                    title: 'Every 5 Minutes'
                })
            }
            granularityOptions.push({
                    value: 60 * 30,
                    title: 'Half Hourly',
                },
                {
                    value: 60 * 60,
                    title: 'Hourly',
                })
        }
        if (period > hour * 24) {
            granularityOptions.push(
                {
                    value: 60 * 60 * 24,
                    title: 'Daily'
                }
            );
            if (period < hour * 24 * 14 && !granularityOptions.find(o => o.title === 'Hourly')) {
                granularityOptions.push(
                    {
                        value: 60 * 60,
                        title: 'Hourly',
                    });
            }
        }
        if (period > hour * 24 * 7) {
            granularityOptions.push(
                {
                    value: 60 * 60 * 24 * 7,
                    title: 'Weekly'
                }
            );
        }
        if (period > hour * 24 * 31) {
            granularityOptions.push(
                {
                    value: 'monthly',
                    title: 'Monthly'
                }
            );
        }
        if (this.state.dateSelection === 'last_month') {
            granularityOptions.push(
                {
                    value: 60 * 60,
                    title: 'Hourly',
                }
            )
        }
        if (this.state.dateSelection === 'last_week') {
            granularityOptions.push(
                {
                    value: 60 * 30,
                    title: 'Half Hourly',
                }
            )
        }
        if (((this.props.graph === 'bar' || this.props.graph === 'line') && this.props.graphType !== 'day_of_week' && this.props.graphType !== 'hour_of_day' && this.props.graphType !== 'category_breakdown' && this.props.graphType !== 'breakdown' && this.props.graphType !== 'heatmap' && this.props.graphType !== 'site_breakdown') && (!this.props.summaryMode || this.props.withGranularity)) {
            granularity = (
                <Dropdown
                    disabled={this.props.disabled}
                    borderless
                    value={this.state.granularity}
                    placeholder={'Granularity'}
                    key={"granularity-dropdown"}
                    nolabel='true'
                    onChange={this.granularitySelection}
                    fixeditems={granularityOptions.map(co => {
                        co.icon = 'MdFormatLineSpacing';
                        return co;
                    })}/>)
        }
        return (
            <React.Fragment>
                <div
                    className={'legend-date' + (this.props.summaryMode ? ' summary-mode ' : '') + (this.props.graph === 'bar' || this.props.graphType === 'breakdown' || this.props.graphType === 'hour_of_day' || this.props.graphType === 'day_of_week' ? ' extended' : '') + (this.state.dateSelection === 'custom' ? ' custom' : '')}>
                    {dateSelect}
                    {granularity}
                </div>
            </React.Fragment>
        )
    }
}

export default LegendRange;
