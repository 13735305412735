import React, {Component} from "react";
import '../../pages/organisation/MainOrganisationPage.scss'
import '../../styles/_layout.scss';
import GridDuck from "gridduck";
import {
    Button,
    CardBody,
    Tabs,
    Icon,
    GdCheckbox,
    Dropdown,
    Input,
    Loader
} from "gd-react";
import GenericLoader from "../../components/GenericLoader";
import GdModal from "../../components/GdModal/GdModal";
import NoItems from "../noItems";
import history from "../../meta/history";
import DeleteModal from "../../modals/Delete/DeleteModal";
import Popover from "@material-ui/core/Popover";
import {ChromePicker} from "react-color";
import {v4 as uuidv4} from "uuid";
import generatePaletteFromBrandCol from "../../services/generatePaletteFromBrandCol";
import cookie from "react-cookies";

class PartnerOrgDrawer extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.onTabClick = this.onTabClick.bind(this)
        this.onCheckboxChange = this.onCheckboxChange.bind(this)
        this.savePartnerOrg = this.savePartnerOrg.bind(this)
        this.checkChanged = this.checkChanged.bind(this)
        this.sitesChanged = this.sitesChanged.bind(this)
        this.getAllSites = this.getAllSites.bind(this)
        this.changePerm = this.changePerm.bind(this)
        this.imageUploaded = this.imageUploaded.bind(this)
        this.changeGeneralPerm = this.changeGeneralPerm.bind(this)
        this.loginAs = this.loginAs.bind(this);
        this.errors = {};

        this.state = {
            loaded: false,
            sitesLimitedTo: [],
            orgOverridePerms: null,
            permission: null,
            squareUploadProgress: 0
        }
        this.modalClosed = false;
        this.tabs = [[{
            id: 1,
            title: 'Details',
            onTabClick: this.onTabClick,
            selected: (this.props.openOnTab === 1 || !this.props.openOnTab)
        }]]

        // if orgPermName < this.perm.rank, filter out this perm
        this.perms = [
            {
                id: 'owner', title: 'Owner', value: 'owner', rank: 0
            }, {
                id: 'admin', title: 'Admin', value: 'admin', rank: 1
            }, {
                id: 'edit', title: 'Edit', value: 'edit', rank: 2
            }, {
                id: 'boost', title: 'Boost / Snooze', value: 'boost', rank: 3
            }, {
                id: 'view', title: 'View', value: 'view', rank: 4
            }]
    }


    async componentDidMount() {
        let partnerToManage;
        let account = await GridDuck.getAccount({id: GridDuck.userId});
        let value = 'Our ';
        this.org_id = account.organisationId;
        if (this.props.item.orgFromId !== account.organisationId) {
            value = "Their ";
            partnerToManage = {
                id: this.props.item.id,
                name: this.props.item.orgFromName,
                logoImage: this.props.item.orgFromLogoImage,
                squareLogoImage: this.props.item.squareLogoImage,
                contactEmail: this.props.item.orgFromContactEmail
            }
        } else {
            partnerToManage = {
                id: this.props.item.id,
                name: this.props.item.orgToName,
                owner: true,
                logoImage: this.props.item.logoImage,
                squareLogoImage: this.props.item.squareLogoImage,
                contactEmail: this.props.item.contactEmail,
                inheritWhiteLabelFrom: this.props.item.inheritWhiteLabelFrom,
                whiteLabel: this.props.item.whiteLabel || !!(this.props.item.inheritWhiteLabelFrom),
                inheritWhiteLabelFromBool: !!(this.props.item.inheritWhiteLabelFrom),
                inheritedLogo: this.props.item.orgFromLogoImage,
                inheritedSquareLogo: this.props.item.orgFromSquareLogoImage,
                inheritWhiteLabelFromName: this.props.item.inheritWhiteLabelFrom && this.props.item.inheritWhiteLabelFrom === account.organisationId ? account.organisationName : '',
                whiteLabelCustomTheme: this.props.item.whiteLabelCustomTheme,
                whiteLabelTheme: this.props.item.whiteLabelTheme || generatePaletteFromBrandCol()
            }
        }
        value += this.props.item.permType;
        partnerToManage.relationship = value;
        if (this.props.item.id !== GridDuck.userId && (this.props.hasAdmin || this.props.hasOwner) && this.props.item.orgFromId === account.organisationId) {
            this.tabs[0].push({
                id: 2, title: 'White Label', onTabClick: this.onTabClick, selected: (this.props.openOnTab === 2)
            })
        }

        let deleteObj = this.props.item
        deleteObj.name = partnerToManage.name
        this.setState({
            partnerToManage: partnerToManage,
            deleteObj: deleteObj,
            permission: this.props.item.orgPermission,
            loaded: true,
            accentColour: partnerToManage.whiteLabelTheme ? partnerToManage.whiteLabelTheme.light.primary_accent : generatePaletteFromBrandCol().light.primary_accent,
        });
    }

    loginAs(client) {
        console.log(`Login as username`);
        console.log(client.contactEmail);
        return GridDuck.createLoginAsPartner({username: client.contactEmail})
            .then(function (loginAs) {
                let existingAccessToken = cookie.load('accessToken');
                console.log(existingAccessToken, ' : existing access token');
                cookie.save('existingAccessToken', existingAccessToken, {path: '/'});
                let accessToken = {
                    expires: loginAs.expires,
                    token: loginAs.token,
                    mfaRequired: loginAs.mfaRequired,
                    partnerOverride: loginAs.partnerOverride,
                    scope: loginAs.scope,
                    tokenType: loginAs.tokenType,
                    user: {id: loginAs.user.id}
                };
                delete accessToken.scope;
                GridDuck.setAccessToken({
                    accessToken
                });
                cookie.save('accessToken', accessToken, {path: '/'});
                history.push("/")
                window.location.reload();
            });
    }

    squareImageUploaded(selectorFiles) {
        let file = selectorFiles[0];
        if (!file) return;
        let self = this;
        let newFile = {
            itemType: 'partnerOrgSquare',
            itemId: this.props.item.id,
            name: file.name,
            type: file.type,
            size: file.size,
            file: file,
            ref: uuidv4(),
        };
        this.setState({uploadingSquareImage: true, squareUploadProgress: 0, squareUploadComplete: false});
        GridDuck.createFile(newFile, function uploadProgress(progressEvt) {
            console.log("upload progress", uploadProgress);
            self.setState({squareUploadProgress: Math.round((progressEvt.loaded / newFile.size) * 100)});
        }).then(function (file) {
            console.log(file, ' : file');
            self.state.partnerToManage.squareLogoImage = file.location;
            self.setState({squareUploadComplete: true, uploadingSquareImage: false});
            console.log("file uploaded");
            console.log(self.props.item);
        });
    }

    imageUploaded(selectorFiles) {
        let file = selectorFiles[0];
        if (!file) return;
        let self = this;
        let newFile = {
            itemType: 'partnerOrg',
            itemId: this.props.item.id,
            name: encodeURIComponent(file.name),
            type: file.type,
            size: file.size,
            file: file,
            ref: uuidv4(),
        };
        this.setState({uploadingImage: true, uploadProgress: 0, uploadComplete: false});
        GridDuck.createFile(newFile, function uploadProgress(progressEvt) {
            self.setState({uploadProgress: Math.round((progressEvt.loaded / newFile.size) * 100)});
        }).then(function (file) {
            self.state.partnerToManage.logoImage = file.location;
            self.setState({uploadComplete: true, uploadingImage: false});
        });
    }

    onTabClick(ev, tab) {
        this.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    checkChanged() {
        let hasChanged = false;
        if (this.props.item && (this.state.orgOverridePerms !== this.props.item.orgOverridePerms) || this.state.sitesChanged || (this.state.permission !== this.props.item.orgPermission)) {
            hasChanged = true
        }
        this.hasChanged = hasChanged
    }

    async savePartnerOrg() {
        let partnerOrg = this.props.item;
        let memberObj = {
            whiteLabel: this.state.partnerToManage.whiteLabel,
            inheritWhiteLabelFrom: this.state.partnerToManage.inheritWhiteLabelFromBool ? this.org_id : null,
            whiteLabelCustomTheme: this.state.partnerToManage.whiteLabelCustomTheme
        }
        if (memberObj.whiteLabelCustomTheme) {
            memberObj.whiteLabelTheme = generatePaletteFromBrandCol(this.state.accentColour);
        }
        await partnerOrg.set(memberObj).then(() => {
            // setTimeout(() => this.closeModal(), 1000)
        });
    }

    closeModal() {
        this.setState({
            loaded: false,
            sitesLimitedTo: [],
            orgOverridePerms: null,
            permission: null
        })
        this.modalClosed = true;
        this.props.onClose();
    }

    async getAllSites(params) {
        if (!params.filters) params.filters = [];
        let sitesRes = await GridDuck.getSites(params);
        let sites = sitesRes.list.map((s) => {
            return {
                siteId: s.id,
                id: s.id,
                name: s.name,
                orgPermName: s._permission,
                userPermName: s._permission,
                orgPermRank: this.perms.find((p) => p.id === s._permission).rank,
            }
        })
        let res = {
            list: sites
        }
        return Promise.resolve(res)
    }

    async getUserSites(params) {
        let self = this

        if (!this.props.item || !this.props.item.id || !this.props.item.orgOverridePerms) return Promise.resolve({
            list: [], total: 0
        })

        if (!this.state.sitesLimitedTo.length) {

            let filters = [{
                "field": "id", "value": this.props.item.id
            }]

            let userRes = await GridDuck.getOrganisationMembers({filters: filters});

            let list = userRes.list[0].sitesLimitedTo;
            self.setState({sitesLimitedTo: list});
            return Promise.resolve({list: list, total: list.length});
        } else return Promise.resolve({list: self.state.sitesLimitedTo, total: self.state.sitesLimitedTo.length});

    }

    sitesChanged(newSitesList) {
        this.setState({sitesLimitedTo: newSitesList, formUpdated: true, sitesChanged: true})
    }

    onCheckboxChange(val) {
        if (val.target.name === 'whiteLabel' && !val.target.checked) {
            this.state.partnerToManage['inheritWhiteLabelFromBool'] = false;
        }
        this.state.partnerToManage[val.target.name] = val.target.checked
        this.setState(this.state);
    }

    changePerm(val, item) {
        let element = this.state.sitesLimitedTo.find((s) => s.siteId === item.siteId)
        element.userPermName = val.target.value;
        this.state.sitesChanged = true;
        this.setState(this.state)
    }

    changeGeneralPerm(val) {
        this.setState({permission: val.target.value})
    }

    render() {
        this.checkChanged()
        let item = this.state.partnerToManage || {};
        console.log(item, ' : item');
        let notAllowedToChangePerms = (!this.props.hasAdmin && !this.props.hasOwner);
        let self = this;
        let footer;
        if (this.tabs[0][1] && this.tabs[0][1].selected && this.hasChanged) {
            footer = <Button additionalclasses={'md'}
                             progressRes onClick={this.savePartnerOrg}
                             color={'gd-brand'}
                             label={'Save'}/>;
        }
        let imageStyle;
        let inheritedLogoStyle;
        let inheritedSquareLogoStyle;
        if (item && item.logoImage) {
            imageStyle = {
                backgroundImage: 'url(' + item.logoImage + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '90%',
                backgroundColor: 'white'
            }
        }
        if (item && item.inheritedLogo) {
            inheritedLogoStyle = {
                backgroundImage: 'url(' + item.logoImage + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '90%',
                backgroundColor: 'white'
            }
            inheritedSquareLogoStyle = {
                backgroundImage: 'url(' + item.squareLogoImage + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '90%',
                backgroundColor: 'white'
            }
        }
        let profileImage;
        if (item.name) {
            let matches = item.name ? item.name.match(/\b(\w)/g) : '';
            let acronym = matches.join('');
            profileImage = <div
                className={'profile-image'}
                style={imageStyle} onClick={() => this.setState({showProfilePicModal: true})}>
                {!item.logoImage ? acronym : ''}
            </div>
        }
        return (<GdModal
            title={item.name}
            footer={footer}
            open={this.props.open}
            contentLabel={'partner'}
            onClose={this.closeModal}>
            <CardBody additionalclasses={'no-padding rule'}>
                {this.state.loaded ? <div className={'user-drawer inner-rule'}>
                    <div className={"details"}>
                        <div className={'details-inner'} style={{overflow: 'hidden'}}>
                            <div className={'inner-header no-padding'}>
                                <Tabs tabData={this.tabs}/>
                            </div>
                            {this.tabs[0][0] && this.tabs[0][0].selected ? <div className={'column user-wrapper'}>
                                {profileImage}
                                <div className={'user-details'}>
                                    <p className={'header'}>{item.name}</p>
                                    <p className={'subHeader row'}
                                       style={{marginTop: '10px'}}><span
                                        style={{marginRight: '10px'}}>Relationship: {item.relationship.toLowerCase().indexOf('customer') !== -1 ? 'Client' : 'Installer'}</span></p>
                                </div>
                                <div className={'user-details'}>
                                    <p className={'subHeader with-icon'} style={{fontWeight: '600'}}><Icon
                                        icon={'MdEmail'}/><a
                                        href={'mailto:' + item.username}>{item.contactEmail}</a></p>
                                    {item.owner && item.inheritWhiteLabelFrom && item.inheritWhiteLabelFromName ?
                                        <div className={'subHeader with-icon'}><Icon size={'8px'} icon={'FaTag'}/>
                                            <span
                                                style={{fontWeight: '600'}}><a
                                                onClick={() => this.onTabClick(null, {id: 2})}>Inheriting {item.inheritWhiteLabelFromName} branding </a></span>
                                        </div> : null}
                                    {item.owner && !item.inheritWhiteLabelFrom ?
                                        <div className={'subHeader with-icon'}><Icon size={'8px'} icon={'FaTag'}/><span
                                            style={{fontWeight: '600'}}><a
                                            onClick={() => this.onTabClick(null, {id: 2})}>Set up White Label</a></span>
                                        </div> : null}
                                    {/*//FIXME: @Alex LoginAsPartner*/}
                                </div>
                                {(this.props.hasOwner || this.props.hasAdmin) && item.relationship.toLowerCase().indexOf('customer') !== -1 ? <div className={'user-details'} style={{display: 'flex'}}>
                                    <Button onClick={() => this.loginAs(item)} color={'gd-brand'}
                                            label={<div><Icon icon={'FaEye'}/>View Dashboard as {item.name}</div>}/>
                                </div> : null}
                                {(this.props.hasOwner) ? <div className={'user-details'} style={{display: 'flex'}}>
                                    <Button onClick={() => this.setState({showDeleteModal: true})} color={'gd-red'}
                                            label={<div><Icon icon={'AiFillDelete'}/>Sever Partnership</div>}/>
                                </div> : null}
                            </div> : null}
                            {this.tabs[0][1] && this.tabs[0][1].selected && !this.state.hideIgnored ?
                                <div className={'column user-wrapper'}>

                                    <div className={'row'} style={{flex: 'none'}}>
                                        <GdCheckbox checked={item.whiteLabel} name={'whiteLabel'}
                                                    onChange={(e) => {
                                                        this.onCheckboxChange(e, {whiteLabelChanged: true});
                                                    }}
                                                    label={'Enable White Label'}/>
                                    </div>
                                    {item.whiteLabel ? <div className={'row'} style={{flex: 'none'}}>
                                        <GdCheckbox checked={item.inheritWhiteLabelFromBool}
                                                    name={'inheritWhiteLabelFromBool'}
                                                    onChange={(e) => {
                                                        this.onCheckboxChange(e, {whiteLabelChanged: true});
                                                    }}
                                                    label={'Inherit Branding from your Organisation'}/>
                                    </div> : null}
                                    {item.whiteLabel && !item.inheritWhiteLabelFromBool ?
                                        <div>
                                            <div className={'row ver-center Account'}
                                                 style={{flex: 'none', paddingBottom: 0}}>
                                                <div>
                                                    <label className={'logo-label'}>Logo Icon + Text</label>
                                                    <div className={'profile-picture org long'}
                                                         style={inheritedLogoStyle}>
                                                        {!item.logoImage ?
                                                            <Icon icon={'FaImage'} size={'50'}/> : ''}
                                                    </div>
                                                </div>
                                                {item.whiteLabel && !item.inheritWhiteLabelFromBool ?
                                                    <div>
                                                        <input accept={'image/*'} id={"image-upload"}
                                                               ref={input => this.imageUploadRef = input}
                                                               type={"file"}
                                                               onChange={(e) => this.imageUploaded(e.target.files)}
                                                               hidden/>
                                                        <div className={'row'}>
                                                            {this.props.hasOwner || this.props.hasAdmin ? !window.$exampleAccount ? !this.state.uploadingImage ?
                                                                <Button label={'Upload'}
                                                                        onClick={() => this.imageUploadRef.click()}/> :
                                                                <Loader type={'circular'} withprogress size={50}
                                                                        value={this.state.uploadProgress < 100 ? this.state.uploadProgress : 100}/> : '' : ''}
                                                        </div>
                                                        <div className={'row'} style={{
                                                            textAlign: 'center',
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <a style={{fontSize: '11px', marginTop: '10px'}}
                                                               target={'_blank'}
                                                               className={'link'}
                                                               href={'https://s3.eu-west-1.amazonaws.com/gridduck.com/GridDuck-White-Label-Asset-Guide.pdf'}>
                                                                White Label Logo Guide
                                                            </a>
                                                        </div>

                                                    </div> : null}

                                            </div>
                                            <div className={'row ver-center Account'}
                                                 style={{flex: 'none', paddingBottom: 0}}>
                                                <div>
                                                    <label className={'logo-label'}>Logo Icon (square format)</label>
                                                    <div className={'profile-picture org'}
                                                         style={inheritedSquareLogoStyle}>
                                                        {!item.squareLogoImage ?
                                                            <Icon icon={'FaImage'} size={'50'}/> : ''}
                                                    </div>
                                                </div>
                                                {item.whiteLabel && !item.inheritWhiteLabelFromBool ?
                                                    <div>
                                                        <input accept={'image/*'} id={"image-upload"}
                                                               ref={input => this.squareImageUploadRef = input}
                                                               type={"file"}
                                                               onChange={(e) => this.squareImageUploaded(e.target.files)}
                                                               hidden/>
                                                        <div className={'row'}>
                                                            {this.props.hasOwner || this.props.hasAdmin ? !window.$exampleAccount ? !this.state.uploadingSquareImage ?
                                                                <Button label={'Upload'}
                                                                        onClick={() => this.squareImageUploadRef.click()}/> :
                                                                <Loader type={'circular'} withprogress size={50}
                                                                        value={this.state.squareUploadProgress < 100 ? this.state.squareUploadProgress : 100}/> : '' : ''}
                                                        </div>

                                                    </div> : null}

                                            </div>
                                        </div> : null}
                                    {item.whiteLabel && !item.inheritWhiteLabelFromBool ?
                                        <div className={'row'} style={{flex: 'none'}}>
                                            <GdCheckbox checked={item.whiteLabelCustomTheme}
                                                        name={'whiteLabelCustomTheme'}
                                                        onChange={(e) => {
                                                            this.onCheckboxChange(e, {whiteLabelChanged: true});
                                                        }}
                                                        label={'Use Custom Colour'}/>
                                        </div> : null}
                                    {item.whiteLabel && !item.inheritWhiteLabelFromBool && item.whiteLabelCustomTheme ?
                                        <div className={'row'} style={{flex: 'none'}}>

                                            <div onClick={(e) => {
                                                this.setState({
                                                    showPicker: true,
                                                    anchorEl: e.currentTarget
                                                });
                                            }}>
                                                <div className={'clickable'} style={{
                                                    backgroundColor: this.state.accentColour,
                                                    width: '50px',
                                                    height: '50px',
                                                    borderRadius: '10px'
                                                }}/>
                                                <Input onChange={(e) => {
                                                    this.setState({
                                                        showPicker: true,
                                                        anchorEl: e.currentTarget
                                                    });
                                                }} label={'Hex Code'} value={this.state.accentColour}/>
                                            </div>
                                            <Popover
                                                id={'color-picker'}
                                                disablePortal
                                                open={this.state.showPicker}
                                                anchorEl={this.state.anchorEl}
                                                onClose={() => this.setState({showPicker: null})}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}>
                                                <ChromePicker
                                                    color={this.state.accentColour}
                                                    onChangeComplete={(color) => {
                                                        self.setState({
                                                            accentColour: color.hex,
                                                            whiteLabelChanged: true,
                                                            hasChanged: true
                                                        });
                                                    }}
                                                /> </Popover>

                                        </div> : null}

                                </div> : null}
                        </div>
                    </div>
                    <DeleteModal
                        itemType={'orgPartner'}
                        item={this.state.deleteObj}
                        open={this.state.showDeleteModal}
                        onClose={() => this.setState({showDeleteModal: false})}/>
                </div> : <GenericLoader/>}
            </CardBody>
        </GdModal>)
    }
}


export default PartnerOrgDrawer;
