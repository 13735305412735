import React from 'react';
import GridDuck from 'gridduck';
import BreadcrumbService from "../../meta/breadcrumb-service";
import history from "../../meta/history";

class SiteGroupSites extends React.Component {
	constructor(props) {
		super(props);
		let {itemId} = this.props.match.params;
		BreadcrumbService.siteGroup = itemId;
		this.getList = this.getList.bind(this);
		this.goToSite = this.goToSite.bind(this);
		this.getAvailableList = this.getAvailableList.bind(this);
		this.save = this.save.bind(this);
		this.accountUpdated = this.accountUpdated.bind(this);
		let self = this;
		this.state = {
			siteGroupId: itemId
		}
		console.log('site group sites triggered')
		GridDuck.getAccount({id: GridDuck.userId})
			.then(function (account) {
				self.account = account;
				self.setState({account: account});
				// self.account.on('updated', self.accountUpdated);
			})
	}

	accountUpdated() {
		this.setState({account: this.account});
	}

	async getList(retrievalParams) {
		console.log("getSites Here 2", retrievalParams);
		retrievalParams.filters = this.props.listFilters;
		return GridDuck.getSites(retrievalParams);
	}

	save(newSites) {
		return this.props.item.setSiteIds(newSites.map(s => s.id));
	}

	goToSite(site) {
		history.push("/site/" + site.id);
	}

	getAvailableList(retrievalParams) {
		return GridDuck.getSites(retrievalParams);
	}

	componentWillUnmount() {
		if (this.account && this.account.off) {
			this.account.off('updated', this.accountUpdated);
		}
	}
}

export default SiteGroupSites;