import React from "react";
import {Menu} from '@material-ui/core'
import {Icon, Dropdown, Button} from "gd-react";
import LMenu from "./Menu/Menu";

class LeftNavDropdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let selectedItem = this.props.options.find(o => o.value === this.props.value) || {label: this.props.placeholder};
        return (

            <LMenu controlFromElement={<div
                className={'filter-menu left-nav-dd menu-item ' + (!this.props.open ? ' closed' : '')}>
                <div className={'fake-dropdown'}>
                    {this.props.open && this.props.title ? <div>{this.props.title}</div> : null}
                    <div style={{display: 'inline-flex', alignItems: 'center', fontWeight: '600'}}>
                        {selectedItem && selectedItem.icon ? <div className={'filter-icon'}><Icon size={'10'} icon={selectedItem.icon.name}/></div> : null}
                        {this.props.open ? selectedItem.label : null}
                        {/*{!this.props.title ? <span/> : null}*/}

                    </div>
                    {this.props.open ?
                        <div className={'filter-chevron'}><Icon size={'9'} icon={'FaChevronDown'}/></div> : null}
                </div>
            </div>
            }
                   menuItems={this.props.options}/>

        )
    }
}

export default LeftNavDropdown;