import React from "react";
import {Button, Card, CardBody, CardHeader, Input, Toast} from "gd-react";
import GridDuck from "gridduck";
import '../../styles/_layout.scss';
import SaveBar from "../../components/SaveBar/SaveBar";
import DeleteModal from "../../modals/Delete/DeleteModal";
import LocationForm from "../../components/LocationForm";

class GatewaySettings extends React.Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.setSiteInfo = this.setSiteInfo.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.hasChanged = this.hasChanged.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.showDeleteModal = this.showDeleteModal.bind(this);
        this.errors = {};
        this.state = {
            gateway: {
                name: '',
                locationId: '',
                newLocationName: '',
                newLocationMetaData: ''
            },
            showDeleteModal: false,
        };
        this.gateway = this.props.item;
        this.locationFormRef = React.createRef();
        this.getSiteList = this.getSiteList.bind(this);
        this.siteChanged = this.siteChanged.bind(this);
    }

    componentDidMount() {
        this.setSiteInfo();
    }

    setSiteInfo() {
        let self = this;
        setTimeout(() => {
            self.setState({
                gateway: {
                    name: self.gateway.name,
                    siteName: self.gateway.siteName,
                    siteId: self.gateway.siteId,
                    locationId: self.gateway.locationId
                },
                reload: false,
                hasChanged: false
            })
        }, 100)
    }

    onFormChange(val) {
        this.state.gateway[val.target.name] = val.target.value;
        this.setState({gateway: this.state.gateway});
    }

    hasChanged() {
        let self = this;
        let hasChanged = false;
        if (self.gateway && self.state.gateway) {
            Object.entries(self.gateway).map(([key, value]) => {
                let updatedKey = key.replace(/^_+/i, '');
                if ((updatedKey in self.state.gateway) && self.state.gateway[updatedKey] !== value) {
                    hasChanged = true;
                }
            })
        }
        if (this.state.hasChanged) hasChanged = true;
        return hasChanged;
    }

    cancelChanges() {
        this.setState({reload: true}, () => {
            this.setSiteInfo();
        });
    }

    checkErrors() {
        let errors = {};
        if (!this.state.gateway.name.length) errors['name'] = true;
        this.errors = errors;
    }

    async saveChanges(e) {
        this.setState({triedToSave: true})
        let locationErrors = this.locationFormRef.current.checkErrors(true);

        if (Object.keys(this.errors).length > 0 || Object.keys(locationErrors).length > 0) return Promise.reject();

        if (e) {
            e.preventDefault();
        }

        let self = this;
        let gateway = this.state.gateway;
        let locRes = await this.locationFormRef.current.createNewLocation();
        gateway.locationId = locRes.id;
        await this.props.item.set(gateway);
        self.setState({open: true, reload: true}, () => {
            self.setSiteInfo();
        })
    }

    handleClose() {
        this.setState({open: false})
    }

    siteChanged(item) {
        this.state.gateway.siteId = item.id;
        this.state.gateway.siteName = item.title;
        this.setState({
            gateway: this.state.gateway
        });
    }

    showDeleteModal() {
        this.setState({showDeleteModal: true})
    }

    getSiteList(searchTerm) {
        return GridDuck.getSites({
            filters: [{
                field: 'search',
                value: searchTerm
            }]
        }).then(function (siteList) {
            return Promise.resolve(
                siteList.list.map(
                    s => ({
                        id: s.id,
                        title: s.name
                    })
                )
            )
        })
    }

    render() {
        this.checkErrors();
        let settingsEditCard;
        let advancedCard;

        let footerSaveBar = this.hasChanged() ?
            <div className={'detail-content'}><SaveBar onSaveClick={this.saveChanges}
                                                       onCancelClick={this.cancelChanges}/></div> : '';

        if (this.state.gateway && !this.state.reload) {
            settingsEditCard = (
                <Card>
                    <CardHeader title={'General'}/>
                    <CardBody>
                        <div className={'row'} style={{marginBottom: '15px'}}>
                            <Input top='true' label={'Name'} required name={'name'} value={this.state.gateway.name}
                                   error={this.errors['name'] && this.state.triedToSave}
                                   disabled={this.props.item._permission !== 'admin'}
                                   onKeyPress={(event) => {
                                       if (event.key === 'Enter') this.saveChanges();
                                   }}
                                   onChange={this.onFormChange}/>
                        </div>
                        <LocationForm ref={this.locationFormRef}
                                      disabled={this.props.item._permission !== 'admin'}
                                      value={this.props.item.locationId}
                                      onLocationIdChange={(val) => this.setState({locationId: val, hasChanged: true})}
                                      onNewLocationNameChange={(val) => this.setState({
                                          newLocationName: val,
                                          hasChanged: true
                                      })}
                                      onNewLocationTimezoneChange={(val) => this.setState({
                                          newLocationTimezone: val,
                                          hasChanged: true
                                      })}
                                      onNewLocationMetadataChange={(val) => this.setState({
                                          newLocationMetaData: val,
                                          hasChanged: true
                                      })}/>
                    </CardBody>
                </Card>
            )
            advancedCard = (
                <Card>
                    <CardHeader title={'Advanced'}/>
                    <CardBody>
                        {this.props.item._permission === 'admin' ?
                            <Button label={'Delete Hub'} color={'gd-red'} onClick={this.showDeleteModal}/> : ''}
                    </CardBody>
                </Card>
            )
        }

        return (
            <div className={"page grey narrow not-flex"}>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        {settingsEditCard}
                    </div>
                    <div className={'detail-content'}>
                        {this.props.item._permission === 'admin' ? advancedCard : null}
                    </div>
                    {footerSaveBar}
                </div>
                <DeleteModal
                    itemType={'hub'}
                    item={this.props.item}
                    open={this.state.showDeleteModal}
                    onClose={() => this.setState({showDeleteModal: false})}/>
                <Toast onClose={this.handleClose} message={"Save Successful"}
                       anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                       open={this.state.open}/>
            </div>
        )
    }
}

export default GatewaySettings;
