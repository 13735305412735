import React from "react";
import "./Reports.scss";
import {Input, Button, CardBody, GdModal, Icon, Tooltip, Toast} from "gd-react";
import * as _ from 'underscore'
import {Document, Page} from 'react-pdf';
import Toggle from "../../components/Toggle/Toggle";
import getFormat from "../../services/formatter";
import history from "../../meta/history"
import ShareDataRequestModal from "../../modals/ShareDataRequest/ShareDataRequestModal";
import GridDuck from "gridduck";
import GenericLoader from "../../components/GenericLoader";
import EditReport from "../../modals/EditReport/EditReport";
import ReportListItem from "./ReportListItem";
import PdfListItem from "./PdfListItem";
import MainReportsPage from './MainReportsPage'
// import {isMobile} from "react-device-detect";

if (window.innerWidth < 800) import ("./MobileOverrides.scss").then((isMob) => {
    console.log(isMob);
});


class MobileMainReportsPage extends MainReportsPage {
    render() {

        let self = this, totals = [];
        //TODO make report list item component to then track sockets
        let reportsDOM = this.state.reports && this.state.reports.length ? this.state.reports.map(function (report) {
            return <ReportListItem key={report.id} onClick={self.selectReport} item={report}/>
        }) : null;

        if (this.state.pdfs) {
            totals = [this.state.pdfs.summary[0]];
            if (this.state.pdfs.combined && this.state.pdfs.combined.length) {
                totals.push(this.state.pdfs.combined[0])
            }
        }

        return (
            <div className={'Reports'} ref={this.pdfViewRef}>
                <div className={'left-panel ' + (this.state.selectedReport ? 'show-details' : '')}>
                    <div className={'wide-inner-panel'}>
                        <div className={'report-details report-list'}>
                            <div className={'details-section'}>
                                <Input value={this.state.reportSearchFilter} onChange={(e) => this.searchReports(e)}
                                       InputProps={{
                                           startAdornment: (<Icon color={'grey'} size={'12'} icon={'FaSearch'}/>)
                                       }} placeholder={'Search'}/>
                                {this.state.reportSearchFilter.length ?
                                    <div onClick={() => this.searchReports()} className={'cancel-search'}><Icon
                                        color={'grey'} size={'12'} icon={'FaTimes'}/></div> : null}
                            </div>
                            <div className={'list-section'}>
                                {reportsDOM && this.state.loaded ? reportsDOM : null}
                                {!this.state.loaded ?
                                    <GenericLoader text={"Fetching"} textLineTwo={"Reports..."}/> : null}
                                {this.state.loaded && !this.state.reports.length ? <p style={{
                                    textAlign: 'center',
                                    marginTop: '50px'
                                }}>No reports matching "{this.state.reportSearchFilter}"</p> : null}
                            </div>
                        </div>
                        <div className={'report-details'}>
                            <div className={'report-header'}>
                                <Tooltip title={'Back'} label={'Back'} position={'bottom'}>
                                    <div className={'left-arrow'} onClick={() => {
                                        history.push('/reports');
                                        this.setState({
                                            selectedReport: null,
                                            selectedPdf: null,
                                            selectedPdfToShow: null,
                                            reportHistory: null,
                                            pageNumber: 1,
                                            factorIncrease: 1
                                        })
                                    }}>
                                        <Icon color={'grey'} size={'13'} icon={'FaChevronLeft'}/></div>
                                </Tooltip>
                                {this.state.selectedReport ? <p>{this.state.selectedReport.name}</p> : ''}
                                <span style={{display: 'flex', flex: '1 1'}}/>
                            </div>
                            {this.state.selectedReport ?
                                <div className={'details-section'}>
                                    <div className={'details-row'}>
                                        <Toggle onClick={() => this.setState({showToggleActiveModal: true})}
                                                inactivestatename={'Inactive'} activestatename={'Active'}
                                                active={this.state.selectedReport.active.toString()}/>
                                    </div>

                                    <div className={'details-row'}>
                                        <div className={'details-row-icon'}>
                                            <Tooltip label={'Sent out'} position={'top'}>
                                                <Icon color={'grey'} size={'13'}
                                                      icon={this.state.selectedReport.frequency === 'monthly' ? 'FaCalendarWeek' : 'FaCalendarDay'}/>
                                            </Tooltip>
                                        </div>
                                        <p style={{
                                            marginBottom: '0',
                                            paddingTop: '6px'
                                        }}>{getFormat('titleCase')(this.state.selectedReport.frequency)}</p>
                                        {/*on {this.state.selectedReport.frequency === 'monthly' ? ('the ' + getFormat('ordinalSuffix')(this.state.selectedReport.repeatOn)) : getFormat('weekDay')(this.state.selectedReport.repeatOn)}*/}
                                    </div>
                                    <div className={'details-row'}>
                                        <div className={'details-row-icon'}>
                                            <Icon color={'grey'}
                                                  size={'13'}
                                                  icon={'FaPlug'}/>
                                        </div>
                                        {(this.state.retrievedAssetIds || this.state.selectedReport.assetIds) &&
                                            <p style={{marginBottom: '0', paddingTop: '5px'}}
                                               className={'link'}
                                               onClick={() => this.openListModal('devices', this.state.selectedReport)}>{this.state.retrievedAssetIds ? this.state.retrievedAssetIds.length : this.state.selectedReport.assetIds.length} Device{(this.state.retrievedAssetIds ? this.state.retrievedAssetIds.length : this.state.selectedReport.assetIds.length) > 1 ? 's' : null}</p>}
                                    </div>
                                    <div className={'details-row'}>
                                        <div className={'details-row-icon'}>
                                            <Tooltip label={'Send to'} position={'top'}>
                                                <Icon color={'grey'} size={'13'} icon={'FaShare'}/>
                                            </Tooltip>
                                        </div>
                                        <p style={{marginBottom: '0', paddingTop: '2px'}}
                                           className={'link'}
                                           onClick={() => this.openListModal('recipients', this.state.selectedReport)}>{this.state.selectedReport.recipients.length} Recipient{this.state.selectedReport.recipients.length > 1 ? 's' : null}</p>
                                    </div>
                                </div> : null}
                            {this.state.loadingReportHistory ?
                                <GenericLoader text={'Fetching'} textLineTwo={"Historic Reports"}/> : null}
                            {!this.state.loadingReportHistory && (!this.state.reportHistory || !this.state.reportHistory.length) ?
                                <div className={'list-section'}><p style={{
                                    textAlign: 'center',
                                    marginTop: '50px'
                                }}>No reports generated yet</p></div> : null}

                            {this.state.selectedReport ?
                                <div
                                    className={'wide-inner-panel-inner ' + (this.state.selectedPdf ? 'showing-pdfs' : '')}>
                                    <div className={'list-section'}>
                                        {this.state.reportHistory && this.state.reportHistory.length && !this.state.loadingReportHistory ?
                                            <div className={'list-section'}>
                                                {this.state.reportHistory.map(function (pdf) {
                                                    return <div
                                                        key={pdf.startTime + 'pdf'}
                                                        className={'report-list-item pdf'}
                                                        onClick={() => self.selectDateRange(self.state.selectedReport, pdf.startTime, pdf)}>
                                                        <p>{pdf.rangeName}</p>
                                                        <div className={'right-arrow'}>
                                                            <Icon color={'grey'} size={'13'} icon={'FaChevronRight'}/>
                                                        </div>
                                                    </div>
                                                })}
                                            </div> :
                                            null}
                                    </div>
                                    <div className={'report-details'}>
                                        <div className={'report-header'}>
                                            <Tooltip label={'Back'} position={'bottom'}>
                                                <div className={'left-arrow'} onClick={() => this.setState({
                                                    selectedPdf: null,
                                                    selectedPdfToShow: null,
                                                    pageNumber: 1,
                                                    factorIncrease: 1
                                                })}>
                                                    <Icon color={'grey'} size={'13'} icon={'FaChevronLeft'}/></div>
                                            </Tooltip>
                                            {this.state.selectedPdf ? <p>{this.state.selectedPdf.rangeName}</p> : ''}
                                            <span style={{display: 'flex', flex: '1 1'}}/>
                                        </div>

                                        {this.state.selectedPdf && (!this.state.pdfs || !this.state.pdfs.summary) &&
                                            <GenericLoader text={"Fetching"} textLineTwo={"PDFs..."}/>}

                                        <div className={'list-section'}>
                                            {this.state.selectedPdf && this.state.pdfs &&
                                                <p className={'divider-text'}>Total</p>}
                                            {this.state.selectedPdf && this.state.pdfs && this.state.pdfs.summary && totals.map(function (pdf) {
                                                return <PdfListItem downloadPdf={() => self.downloadPdf(pdf)}
                                                                    sharePdf={() => self.sharePdf(pdf)}
                                                                    selectedPdfToShow={self.state.selectedPdfToShow}
                                                                    pdf={pdf}
                                                                    onClick={self.openPdf}/>
                                            })}
                                            {this.state.selectedPdf && this.state.pdfs && this.state.pdfs.device && this.state.pdfs.device.length &&
                                                <p className={'divider-text'}>Individual Device</p>}
                                            {this.state.selectedPdf && this.state.pdfs && this.state.pdfs.device && this.state.pdfs.device.length && this.state.pdfs.device.map(function (pdf) {
                                                return <PdfListItem downloadPdf={() => self.downloadPdf(pdf)}
                                                                    sharePdf={(pdf) => self.sharePdf(pdf)}
                                                                    selectedPdfToShow={self.state.selectedPdfToShow}
                                                                    pdf={pdf}
                                                                    key={pdf.id}
                                                                    onClick={self.openPdf}/>
                                            })}
                                        </div>
                                    </div>
                                </div>
                                : null}

                        </div>
                    </div>
                </div>
                {this.state.showToggleActiveModal ? <GdModal
                    open={this.state.showToggleActiveModal}
                    contentLabel={this.state.selectedReport.name}
                    title={(this.state.selectedReport.active ? "Deactivate" : 'Activate') + ' report ' + this.state.selectedReport.name}
                    footer={<div style={{display: 'flex', flex: '1 1'}}>
                        <Button additionalclasses={'sm'} label={"Cancel"} color={'gd-grey'} outline
                                onClick={() => this.setState({showToggleActiveModal: false})}/>
                        <span style={{display: 'flex', flex: '1 1'}}/>
                        <Button color={this.state.selectedReport.active ? 'gd-red' : 'gd-green'}
                                additionalclasses={'sm'}
                                label={this.state.selectedReport.active ? "Deactivate" : 'Activate'}
                                onClick={() => {
                                    this.setState({showToggleActiveModal: false});
                                    this.toggleReportActive();
                                }}/>

                    </div>}
                    onClose={() => this.setState({showToggleActiveModal: false})}>
                    <CardBody>
                        <p>Are you sure you want to {this.state.selectedReport.active ? "deactivate" : 'activate'} this
                            report.<br/><br/>You
                            will {this.state.selectedReport.active ? "no longer receive emails and the PDFs will no longer be generated." : 'start receiving emails and regular PDFs will be generated again.'}
                        </p>
                    </CardBody>
                </GdModal> : null}
                {this.state.showListModal ? <GdModal
                    open={!!(this.state.showListModal)}
                    contentLabel={this.state.showListModal.title}
                    title={this.state.showListModal.title}
                    onClose={() => this.setState({showListModal: false, showEditReportModal: false, openOnTab: null})}>
                    <CardBody>

                        {this.state.showListModal.items ? this.state.showListModal.items.map(function (item, i) {
                            return <div key={i + 'lis'} className={'list-item-modal'}>{item}</div>
                        }) : null}
                        {this.state.showListModal.loading ? <GenericLoader/> : null}

                    </CardBody>
                </GdModal> : null}

                <Toast onClose={() => this.setState({showSuccessToast: false})} message={"Save Successful"}
                       anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                       open={this.state.showSuccessToast}/>

                <Toast onClose={() => this.setState({showDeletedToast: false})} message={"Delete Successful"}
                       anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                       open={this.state.showDeletedToast}/>

                {this.state.showEditReportModal && this.state.selectedReport ? <EditReport onDelete={() => {
                    this.setState({showDeletedToast: true});
                    history.push('/reports')
                }} onSave={() => this.setState({showSuccessToast: true})} openOnTab={this.state.openOnTab}
                                                                                           onClose={() => this.setState({
                                                                                               showEditReportModal: null,
                                                                                               openOnTab: null
                                                                                           })}
                                                                                           open={this.state.showEditReportModal}
                                                                                           retrievedAssetIds={this.state.retrievedAssetIds}
                                                                                           item={this.state.selectedReport}/> : null}

                {this.state.shareData ?
                    <ShareDataRequestModal open={!!(this.state.shareData)} report={true}
                                           dataRequestId={this.state.shareData.id}
                                           onClose={() => this.setState({shareData: null})}/> : ''}
            </div>
        )
    }

}

export default MobileMainReportsPage;