import React from 'react';
import {Icon, DataTypes, StateOptions} from "gd-react";

class DeviceDataTypeIcon extends React.Component {
    render() {
        let dataType;

        if (this.props.object.dataType) {
            dataType = DataTypes.find(dt => dt.type === this.props.object.dataType);
        } else {
            if (this.props.object.sku) {
                dataType = StateOptions.find((so) => so.type === this.props.object.sku).dataTypes[0]
            }
        }
        let category = dataType?.categoryDetails;
        let icon = category ? category.icon : 'FaPlug';
        let color = category ? category.color : 'gd-brand';
        if (this.props.type === 'category') {
            icon = this.props.object.deviceCategoryIcon;
            color = this.props.object.deviceCategoryColor;
        }

        return (
            <div className={'list-field'}>
                <Icon icon={icon} size={this.props.size} color={color}/>
            </div>
        );
    }
}

export default DeviceDataTypeIcon;