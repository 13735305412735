import React, {Component} from "react";
import "./CreateReportScheduleModal.scss";
import '../../styles/_layout.scss';
import {CardBody} from "gd-react";
import GenericLoader from "../../components/GenericLoader";
import GdModal from "../../components/GdModal/GdModal";
import CreateReportPage from "../../pages/reports/ReportsCreatePage";
import history from "../../meta/history";

class CreateReportScheduleModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.state = {}
        this.modalClosed = false;
    }

    closeModal(url) {
        if (url) {
            history.push(url);
        }
        this.modalClosed = true;
        this.props.onClose();
    }

    render() {
        return (
            <GdModal
                open={this.props.open}
                contentLabel={'Download Data'}
                title={'Create Report Schedule'}
                wide
                footer={true}
                onClose={this.closeModal}>
                <CardBody additionalclasses={'no-padding'}>
                    <CreateReportPage onSave={(url) => this.closeModal(url)} dataLoaded={true}/>
                </CardBody>
            </GdModal>
        )
    }
}

export default CreateReportScheduleModal;
