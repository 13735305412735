import React from 'react';
import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import GridDuck from "gridduck";
import {Button, Input} from "gd-react";

class CheckoutForm extends React.Component {
    handleSubmit = async (event) => {
        let self = this;
        this.state = {};
        self.setState({cardError: null})
        console.log(this.props, ' : this.props');
        // We don't want to let default form submission hap
        // pen here,
        // which would refresh the page.
        event.preventDefault();

        const {stripe, elements} = this.props

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                name: this.props.name
            },
        });

        console.log(result, ' : create Card');
       if (!result.error) {
           await GridDuck.createPaymentCard({cardId: result.paymentMethod.id});
           console.log('something');
           self.props.closeModal(true);
           console.log("resolve true");
           return Promise.resolve(true);
       }
       else {
           self.setState({cardError: result.error})
           return Promise.reject();
       }
        // stripePaymentMethodHandler(result)
    };

    render() {
        const {stripe} = this.props;

        return (
            <form>
                <CardSection/>
                <br/><br/>
                {this.state && this.state.cardError && <p style={{color: 'red'}}>{this.state.cardError.message}</p>}
                <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                    {/*<Button additionalclasses={'sm'} progressRes onClick={this.handleSubmit} type="submit" disabled={!stripe} label={'Save Card'}/>*/}
                    <Button additionalclasses={'lg'} progressRes onClick={this.handleSubmit} type="submit" disabled={!stripe} label={'Set up SEPA Direct Debit'}/>
                </div>
            </form>
        );
    }
}

export default function InjectedCheckoutForm(props) {
    return (
        <ElementsConsumer>
            {({stripe, elements}) => (
                <CheckoutForm closeModal={props.closeModal} name={props.name} stripe={stripe} elements={elements}/>
            )}
        </ElementsConsumer>
    );
}