import React from "react";
import {Input, Icon, Loader, List, StandardListCell, StatusListCell, LastActiveCell} from "gd-react";
import GridDuck from "gridduck";
import TabbedPage from "../../../components/TabbedPage/TabbedPage";
import "../../organisation/MainOrganisationPage.scss";
import "../audit/MainAuditPage.scss"
import moment from "moment-timezone";
import AuditDevicesPage from '../audit/AuditDevicesPage'
import AuditFilesPage from '../audit/AuditFilesPage'
import AuditHubsPage from '../audit/AuditHubsPage'
import GenericLoader from "../../../components/GenericLoader";
import history from "../../../meta/history";
import NoItems from "../../../components/noItems";
import getFormat from "../../../services/formatter";

class AuditListPage extends React.Component {

	constructor(props) {
		super(props);
		this.getList = this.getList.bind(this);
		this.goToAudit = this.goToAudit.bind(this);
		this.state = {}
	}

	getList(retrievalParams) {
		return GridDuck.getAudits(retrievalParams);
	}

	goToAudit(audit) {
		history.push("/audit/" + audit.id)
	}

	render() {
		let permissions;
		if (this.props.item) {
			permissions = (
				<List
					noitemelem={<NoItems text={'"' + this.props.item.name + '" has no Audits'}/>}
					columns={[
						{
							component: StandardListCell,
							sortable: false,
							title: 'Date',
							custom: function (value) {
								return getFormat('lastContacted')(value.createdDate)
							}
						},
						{
							component: StandardListCell,
							bold: true,
							title: 'Issues',
							field: 'issues',
							custom: function(value) {
								let issueDisplay;
								value.issues === 0 ? issueDisplay = '-' : issueDisplay = value.issues
									return issueDisplay
							}
						},
						{
							component: StandardListCell,
							title: 'Created by',
							maxWidth: 'none',
							custom: function (value) {
								return ' ' + value.installerOrgName + ' (' + value.installerName + ') '
							}
						},

					]}
					getlist={this.getList}
					rowOnClick={this.goToAudit}
					filters={[
						{
							field: 'siteId',
							value: this.props.item.id
						}
					]}
					{...this.props}
				/>
			)

			return (
				<div className='page'>
					{permissions}
				</div>
			);
		}
	}
}

export default AuditListPage
