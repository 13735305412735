import React from "react";
import GridDuck from "gridduck";
import * as _ from 'underscore'
import history from "../../meta/history";
import getFormat from "../../services/formatter";
import {CollapsibleTable, Icon} from "gd-react";

class GlobalInvites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [{field: 'getAllGlobally', value: true}],
            invites: {}
        };
        this.getList = this.getList.bind(this);
        this.getList();
    }

    onFormChange(val) {
        this.setState({emailAddress: val.target.value});
    }

    getList() {
        let self = this;
        let invites;
        GridDuck.getOrganisationInvites({filters: this.state.filters, getAll: true}).then(function (res) {
            console.log(res, ' : invites res');
            invites = _.groupBy(res.list, 'orgName');
            console.log(invites, ' : invites grouped');
            self.setState({invites: invites})
        });
    }

    render() {
        let self = this;
        console.log(Object.keys(this.state.invites).length, ' : invites')
        return (
            <div className='page Account not-flex'>
                {Object.keys(this.state.invites).length ? <div className={'column top'}>
                    <CollapsibleTable columns={{
                        outerColumns: [{title: 'Organisation'}],
                        innerColumns: [{title: 'Email Address'}, {title: 'Invite Link'}, {title: 'Invite Sent'}, {title: 'Link Opened'}]
                    }} tableData={
                        _.map(self.state.invites, function (invite) {
                            console.log(invite, ' : key');
                            return {
                                id: invite[0].emailAddress,
                                data: [
                                    {value: invite[0].orgName ? invite[0].orgName : 'Org Deleted'}
                                ],
                                innerData:
                                    invite.map(function (inviteI) {
                                        return [
                                            {
                                                value: inviteI.emailAddress
                                            },
                                            {
                                                value: 'dashboard.gridduck.com/confirm-invite/' + inviteI.id
                                            },
                                            {
                                                value: getFormat('lastContacted')(inviteI.inviteSent)
                                            },
                                            {
                                                value: getFormat('lastContacted')(inviteI.linkOpened)
                                            }
                                        ]
                                    })
                            }
                        })
                    }/>
                </div> : ''}
            </div>
        )
    }
}

export default GlobalInvites;
