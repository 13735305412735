import React, {Component} from 'react';
import GDGraphs from 'gd-graphs';
import WidgetGraph from "../Summary/Widgets/WidgetGraph";
import WidgetHeader from "../Summary/Widgets/WidgetHeader";

class PreviewGraph extends Component {
    constructor(props) {
        super(props);
        // Initialize state here
        this.state = {};
        let self = this;
        this.asset = this.props.data[1][0];
    }

    render() {
        this.dataType = this.props.dataTypes.find(dt => this.props.data[0].indexOf(dt.dataType.id) !== -1);
        this.dataItem = this.props.dataItems.find(di => this.props.data[0].indexOf(di.itemId?.id || di.itemId) !== -1);
        let data = [];
        let graphId = this.props.data[0];
        if (this.props.data && this.props.data[0] && this.props.data[1] && this.dataItem && this.dataType) {
            data = this.props.data[1].map((d, i) => {
                let _d = Object.assign({}, d);
                _d.type = 'periodic';
                if (_d.type === 'periodic' && i > 0) {
                    let prevValue = this.props.data[1][i - 1].value;
                    _d.consumption = _d.value - prevValue;
                } else {
                    _d.consumption = _d.value;
                }
                _d.assetId = graphId
                _d.time = parseInt(_d.time) / 1000;
                return _d;
            }).filter(d => (d.time && d.value));
            if (data.length) {
                return (
                    <div
                        className={'widget preview ' + (!this.state.ready ? 'loading' : '') + (this.state.showLegend ? 'show-legend' : '')}>
                        <WidgetHeader noLinkOut info={{
                            title: `${this.dataItem?.name} - ${this.dataType?.dataType?.title}`,
                            cleanTitle: true,
                            type: `${this.dataType?.dataType?.title}`,
                            className: 'Example',
                            dataType: `${this.dataType?.dataType?.title}`,
                            icon: this.dataType.dataType.categoryDetails.icon,
                            color: this.dataType.dataType.categoryDetails.color,
                            graphType: 'bar',
                            widgetType: 'historic'
                        }}/>
                        <WidgetGraph data={[{
                            list: data, count: data.length
                        }]}
                                     showLegend={(val) => this.setState({showLegend: val})} dateData={{
                            start: this.props.data[1][0].time,
                            end: data[data.length - 1].time,
                            timezone: 'Europe/London'
                        }}
                                     assets={[{name: 'Example', parent: null, id: graphId}]}
                                     granularity={data[1].time - data[0].time}
                                     color={this.dataType.dataType.categoryDetails.color}
                                     isPendingData={this.props.isPendingData}
                                     onLoad={() => this.setState({ready: true})}
                                     average={this.props.title} dataTypes={[data[0].dataType]}
                                     type={'bar'} id={graphId}/>
                    </div>
                );
            } else return null;
        } else {
            return null
        }
    }
}

export default PreviewGraph;