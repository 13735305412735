import React from "react";
import {Menu} from '@material-ui/core'
import {Icon, GdCheckbox, Dropdown} from "gd-react";
import GridDuck from 'gridduck'
import * as _ from 'lodash';


class NotificationsFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            loading: true,
            notificationTypes: this.props.notificationTypes
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick(e) {
        let self = this;
        self.setState({
            anchorEl: e ? e.currentTarget : this.state.anchorEl
        });
    }

    handleClose() {
        this.setState({anchorEl: null});
    }

    addOrReplaceInArray(array, string, condition) {
        console.log(array, ' : array');
        console.log(string, ' : string');
        console.log(condition, ' : condition');
        // Create a clone of the array
        let clonedArray = [...array];

        const index = clonedArray.indexOf(string);

        if (condition) {
            // Add string if it doesn't exist
            if (index === -1) {
                clonedArray.push(string);
            }
        } else {
            // Remove string if it exists
            if (index > -1) {
                clonedArray.splice(index, 1);
            }
        }

        return clonedArray;
    }

    render() {

        return (
            <div className={'filter-menu'}>
                <div style={{position: 'relative', overflow: 'hidden'}} onClick={this.handleClick}>
                    <div className={'filterIcon clickable'}><Icon icon={'MdFilterList'}/> Filter</div>
                </div>
                <Menu
                    id="filter-menu"
                    getContentAnchorEl={null}
                    anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                    transformOrigin={{vertical: "top", horizontal: "left"}}
                    anchorEl={this.state.anchorEl}
                    open={!!(this.state.anchorEl)}
                    disableEnforceFocus
                    onClose={this.handleClose}>
                    <div className={'filter-menu-container kpi-tooltip'}>
                        <div className={'menu-header'}>
                            Filter {this.props.label}
                        </div>
                        <div className={'column'}>
                            {(this.props.onlyIsDropDown && this.props.potentialOptions.length !== this.props.notificationTypes.length) && <div className={'row'}>
                                <Dropdown style={{minWidth: '100px'}} value={this.props.onlyIs} fixeditems={[{
                                    id: 1,
                                    title: 'Is',
                                    value: false
                                },
                                    {id: 2, title: 'Is only', value: true}]}
                                          onChange={
                                              (e)=> this.props.onUpdate(this.props.notificationTypes, e.target.value)
                                          }/>
                            </div>}
                            {this.props.potentialOptions.map(o => <GdCheckbox
                                checked={this.props.notificationTypes.indexOf(o) !== -1}
                                disabled={this.props.notificationTypes.length === 1 && this.props.notificationTypes.indexOf(o) !== -1}
                                onChange={(e) => {
                                    this.props.onUpdate(this.addOrReplaceInArray(this.props.notificationTypes, o, e.target.checked), this.props.onlyIs)
                                }} label={o === 'error' ? 'Disconnection' : _.capitalize(o).replace('_', ' ')}/>)}
                        </div>

                    </div>
                </Menu>
            </div>
        )
    }
}

export default NotificationsFilter;