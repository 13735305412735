import React from "react";
import {Dropdown, Icon, Input, Loader} from "gd-react";

class FileManagerFilterBar extends React.Component {
    constructor(props) {
        super(props);
        this.changeSortByFileType = this.changeSortByFileType.bind(this);
        this.state = {
            fileTypes: [
                {
                    id: 'all',
                    title: 'All',
                    value: 'all'
                },
                {
                    id: 'image',
                    title: 'Images',
                    value: 'image'
                },
                {
                    id: 'video',
                    title: 'Videos',
                    value: 'video'
                },
                {
                    id: 'pdf',
                    title: 'PDFs',
                    value: 'pdf'
                },
                {
                    id: 'word',
                    title: 'Word Documents',
                    value: 'word'
                },
                {
                    id: 'powerpoint',
                    title: 'PowerPoints',
                    value: 'powerpoint'
                },
                {
                    id: 'other',
                    title: 'Other',
                    value: 'other'
                }

            ],
            fileType: 'all'
        }
    }

    changeSortByFileType(val) {
        let sortBy = val.target.value;
        this.setState({fileType: sortBy});
        this.props.onChangeFilterType(sortBy);
    }

    generalFileType(fileMime) {
        if (fileMime.indexOf('image') !== -1) {
            return 'image';
        } else if (fileMime.indexOf('pdf') !== -1) {
            return 'pdf';
        } else if (fileMime === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            return 'powerpoint';
        } else if (fileMime === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileMime === 'application/msword') {
            return 'word';
        } else if (fileMime.indexOf('video') !== -1) {
            return 'video';
        } else {
            return 'other'
        }
    };

    render() {
        let manageActions;
        if (this.props.selectedFiles.length === 1) {
            manageActions = (
                <div className={"file-manager-action-bar"}>
                    {this.generalFileType(this.props.selectedFiles[0].type) !== 'other' ? <Icon size={'20'} color={"gd-grey"} icon={"AiFillEye"}
                          onIconClick={() => this.props.openFile(this.props.selectedFiles[0])}/> : ''}
					{this.props.readOnly ? '' : (<Icon size={'20'} color={"gd-grey"} icon={"AiFillDelete"}
                          onIconClick={() => this.props.deleteFiles(this.props.selectedFiles)}/>)}
                    {this.props.downloading ? <Loader type={'circular'} size={12}/> :
                        <Icon size={'20'} color={"gd-grey"} icon={"AiOutlineDownload"}
                              onIconClick={() => this.props.downloadFiles(this.props.selectedFiles)}/>}
                </div>
            );
        } else if (this.props.selectedFiles.length > 1) {
            manageActions = (
                <div className={"file-manager-action-bar"}>
                    {this.props.downloading || this.props.deleting || this.props.readOnly ? '' : <Icon
                        size={'20'} color={"gd-grey"} icon={"AiFillDelete"}
                        onIconClick={() => this.props.deleteFiles(this.props.selectedFiles)}/>}
                    {this.props.downloading || this.props.deleting ? '' :
                        <Icon size={'20'} color={"gd-grey"} icon={"AiOutlineDownload"}
                              onIconClick={() => this.props.downloadFiles(this.props.selectedFiles)}/>}
                    {this.props.downloading || this.props.deleting ? <Loader type={'circular'} size={25}/> : ''}
                </div>
            );
        }
        let fileCount;
        if (this.props.selectedFiles.length > 0) {
            fileCount = (
                <div className={"file-manager-count"}>
                    {this.props.selectedFiles.length} / {this.props.files.length} files
                </div>
            )
        } else {
            fileCount = (
                <div className={"file-manager-count"}>
                    {this.props.files.length} files
                </div>
            )
        }
        return (
            <div className={"file-manager-filter-bar"}>
                <Input className={'with-icon'} InputProps={{
                    startAdornment: <Icon icon={'FiSearch'} color={'gd-grey'} size={'14'}/>
                }} nolabel='true' label={''} placeholder={"Search file name"} onChange={(evt) => {
                    this.props.onChangeFilterText(evt.target.value)
                }}/>
                <Dropdown value={this.state.fileType} nolabel='true' label={''} fixeditems={this.state.fileTypes}
                          onChange={this.changeSortByFileType}/>
                {fileCount}
                {manageActions}
            </div>
        )
    }

}

export default FileManagerFilterBar;
