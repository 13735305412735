import {Dropdown, GdAutocomplete, Icon, Input, Toast, Tooltip} from "gd-react";
import React from "react";
import './TimeBar.scss'
import TimeBar from "./TimeBar";
import TimeBarSegment from "./TimeBarSegment";

class TariffTimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minutes: [
                {
                    id: 0,
                    title: '00',
                    value: 0
                },
                {
                    id: 1,
                    title: '30',
                    value: 30
                },
            ],
            hours: [
                {
                    id: 0,
                    title: '00',
                    value: 0
                },
                {
                    id: 1,
                    title: '01',
                    value: 1
                },
                {
                    id: 2,
                    title: '02',
                    value: 2
                },
                {
                    id: 3,
                    title: '03',
                    value: 3
                },
                {
                    id: 4,
                    title: '04',
                    value: 4
                },
                {
                    id: 5,
                    title: '05',
                    value: 5
                },
                {
                    id: 6,
                    title: '06',
                    value: 6
                },
                {
                    id: 7,
                    title: '07',
                    value: 7
                },
                {
                    id: 8,
                    title: '08',
                    value: 8
                },
                {
                    id: 9,
                    title: '09',
                    value: 9
                },
                {
                    id: 10,
                    title: '10',
                    value: 10
                },
                {
                    id: 11,
                    title: '11',
                    value: 11
                },
                {
                    id: 12,
                    title: '12',
                    value: 12
                },
                {
                    id: 13,
                    title: '13',
                    value: 13
                },
                {
                    id: 14,
                    title: '14',
                    value: 14
                },
                {
                    id: 15,
                    title: '15',
                    value: 15
                },
                {
                    id: 16,
                    title: '16',
                    value: 16
                },
                {
                    id: 17,
                    title: '17',
                    value: 17
                },
                {
                    id: 18,
                    title: '18',
                    value: 18
                },
                {
                    id: 19,
                    title: '19',
                    value: 19
                },
                {
                    id: 20,
                    title: '20',
                    value: 20
                },
                {
                    id: 21,
                    title: '21',
                    value: 21
                },
                {
                    id: 22,
                    title: '22',
                    value: 22
                },
                {
                    id: 23,
                    title: '23',
                    value: 23
                },
            ],
        };
    }

    render() {

        return (
            <div>
                <span className={'start-end-heading'}>{this.props.label}</span>
                <div className={'row'} style={{marginBottom: '15px'}}>
                    <div>
                        <Dropdown label={'Hour'}
                                  onChange={(e) => this.props.onHourChange(e.target.value)}
                                  fixeditems={this.state.hours}
                                  placeholder={'00'}
                                  disabled={this.props.disabled}
                                  error={!!(this.props.hourError)}
                                  value={this.props.hour}
                        />
                    </div>

                    <div style={{paddingLeft: '10px'}}>
                        <Dropdown label={'Minutes'}
                                  placeholder={'00'}
                                  onChange={(e) => this.props.onMinuteChange(e.target.value)}
                                  fixeditems={this.state.minutes}
                                  disabled={this.props.disabled}
                                  error={!!(this.props.minuteError)}
                                  value={this.props.minute}
                        />
                    </div>
                </div>
            </div>
        )

    }
}

export default TariffTimePicker;








