import React, {Component} from "react";
import "./AddMemberModal.scss";
import '../../styles/_layout.scss';
import {Button, Card, CardBody, CardHeader, Dropdown, GdAutocomplete, Icon, Input, Loader} from "gd-react";
import GridDuck from "gridduck";
import moment from "moment-timezone";
import SaveBar from "../../components/SaveBar/SaveBar";
import history from "../../meta/history";
import GdModal from "../../components/GdModal/GdModal";

class AddMemberModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.onEmailsChange = this.onEmailsChange.bind(this);
        this.chunkEmailsIntoValidInvalid = this.chunkEmailsIntoValidInvalid.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.errors = {};
        this.changed = false;

        this.state = {
            invites: {emails: [], orgPermission: 'admin'},
            loaded: false,
            emails: '',
            accessLevels: [
                {
                    id: 0,
                    title: 'Owner',
                    value: 'owner'
                },
                {
                    id: 1,
                    title: 'Admin',
                    value: 'admin'
                },
                {
                    id: 2,
                    title: 'Edit',
                    value: 'edit'
                },
                {
                    id: 3,
                    title: 'Boost/Snooze',
                    value: 'boost_snooze'
                },
                {
                    id: 4,
                    title: 'View',
                    value: 'view'
                }
            ]
        }

        this.modalClosed = false;
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    onFormChange(val) {
        console.log(val.target.name, ' : name');
        console.log(val.target.value, ' : value');
        this.state.invites[val.target.name] = val.target.value;
        this.setState({invites: this.state.invites});
    }

    onEmailsChange(val) {
        let emailsSeparated = this.chunkEmailsIntoValidInvalid(val.target.value);
        let invites = this.state.invites;
        invites.emails = emailsSeparated.validEmails;
        this.setState({emails: val.target.value, invites: invites, processed: emailsSeparated});
    }

    saveChanges() {
        let self = this;
        this.setState({triedToSave: true})
        let emails = this.state.processed;
        console.log("save changes", this.state);

        if (!this.state.emails.length) {
            this.errors['emails-empty'] = true;
            return Promise.reject();
        }

        if (emails.invalidEmails.length) {
            let invalidEmails = emails.invalidEmails.map(function (invalidEmail, i) {
                return <span key={i}
                             className={'invalid-emails'}>{invalidEmail}{i !== emails.invalidEmails.length - 1 ? ',' : ''} </span>
            })
            this.setState({invalidEmails: invalidEmails})
            this.errors['emails-invalid'] = true;
            return Promise.reject();
        }

        let promises = [];

        this.state.invites.emails.forEach(function (email) {
            promises.push(GridDuck.createOrganisationInvite({
                orgPermission: self.state.invites.orgPermission,
                emailAddress: email
            }));
        })

        return Promise.all(promises).then(function () {
            console.log(self.state.invites.emails.length, ' : this.state.invites.emails.length ');
            let toastText = (self.state.invites.emails.length + ' Invite' + (self.state.invites.emails.length > 1 ? 's' : '') + ' Sent');
            // console.log(toastText, ' : toastText');
            history.push({
                pathname: '/organisation/invites',
                state: {
                    showToast: toastText.toString()
                }
            })
            self.state.invitesSent = true;
            self.closeModal();
            return Promise.resolve();
        }).catch(function (err) {
            console.log(err, " error")
            return Promise.reject();
        });


    }

    validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    chunkEmailsIntoValidInvalid(emails) {
        let self = this;
        let parsedEmails = {
            validEmails: [],
            invalidEmails: []
        };

        let emailArray;

        emailArray = emails.split(',');
        emailArray = emailArray.map(function (email) {
            return email.replace(/\s/g, '');
        });

        emailArray.forEach(function (email) {
            if (self.validateEmail(email)) parsedEmails.validEmails.push(email);
            else parsedEmails.invalidEmails.push(email);
        });

        return parsedEmails
    }

    // checkErrors() {
    //     let errors = {};
    //     if (!this.state.site || !this.state.site.id) errors['site'] = true;
    //     if (!this.state.hubsToAdd || !this.state.hubsToAdd.length) errors['hubs'] = true;
    //     this.errors = errors;
    // }

    render() {
        let footer;
        footer = <Button label={'Send Invite/s'} additionalclasses={'sm'}
                         progressRes={true}
                         color={'gd-green'}
                         onClick={this.saveChanges}/>;
        return (
            <GdModal
                title={"Invite Organisation Members"}
                open={this.props.open}
                footer={footer}
                contentLabel={'Invite Organisation Members'}
                onClose={this.closeModal}>
                <CardBody>
                    <Dropdown name={'orgPermission'} value={this.state.invites.orgPermission}
                              onChange={this.onFormChange}
                              label={'Access Level'} fixeditems={this.state.accessLevels}/>
                    <Input multiline required
                           error={(this.errors['emails-empty'] || this.errors['emails-invalid']) && this.state.triedToSave}
                           onChange={this.onEmailsChange}
                           name={'name'} value={this.state.emails}
                           inputProps={{minRows: 3, maxRows: 4}}
                           label={'Enter email addresses separated by a comma (,)'}/>
                    {this.errors['emails-invalid'] ? <p className={'error-text'}>
                        The following emails {this.state.invalidEmails} were invalid
                    </p> : ''}
                </CardBody>
            </GdModal>
        )
    }
}

export default AddMemberModal;
