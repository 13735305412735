import React from "react";
import SWR_IMAGE from '../images/Something-Went-Wrong.gif'
import './index.scss'
import {Button} from "gd-react";
import history from "./history";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        if (document.location.href.indexOf('localhost') === -1) {
            this.setState({hasError: true});
        }
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div className={'something-went-wrong'}>
                <div className={'upper-container'}>
                    <img src={SWR_IMAGE}/>
                    <p>Something went wrong</p>
                    <Button onClick={() =>window.location = '/'} label={'Click to refresh'}/>
                </div>

            </div>
        }
        return this.props.children;
    }
}

export default ErrorBoundary;