import React from 'react';
import GridDuck from 'gridduck';
import {DemandListCell, List, StandardListCell, StateListCell} from "gd-react";
import NoItems from "../../../components/noItems";
import moment from "moment-timezone";
import getFormat from "../../../services/formatter";
import AuditModal from "../../../modals/AuditFile/AuditModal";
import FileManager from "../../../components/FileManager/FileManager";
import history from "../../../meta/history";

class AuditFilesPage extends React.Component {
	constructor(props) {
		super(props);
		let self = this;
		this.getFiles = this.getFiles.bind(this)
		this.openFileModal = this.openFileModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.goToSite = this.goToSite.bind(this)
		this.state = {
			modalOpen: false,
			file: null,
			files: this.props.item ? this.props.item.files : null
		}
	}

	getFiles() {
		if (this.state.files) return this.state.files
		let {auditId} = this.props.match.params;
		return GridDuck.getAudit({
			id: auditId
		}).then((audit) => {
			let resList = {};
			resList.list = audit.files;
			this.setState({loaded: true, audit: audit})
			return resList
		})
	}

	openFileModal(file) {
		let self = this;
		self.setState({modalOpen: true, file: file})
	}

	closeModal() {
		let self = this;
		self.setState({modalOpen: false, file: null})
	}

	goToSite() {
		history.push('/site/' + this.state.audit.siteId + '/settings/files')
	}

	render() {

		let auditHeader;
		if (this.state.audit) auditHeader = (
			<div className={'audit-header'}>
				<p className={'audit-header-text'}>Information below fixed as
					of {getFormat('lastContacted')(this.state.audit.createdDate)}
				</p>
				<div
					className={'audit-site-link'}
					onClick={this.goToSite}><p className={'audit-header-text link'}>Go to current files</p></div>
			</div>
		)

		let permissions;

		let modal;
		if (this.state.loaded) modal = <AuditModal audit={this.state.audit} itemType={"site"} itemId={this.state.audit.siteId} item={this.state.file} previewType={'file'} onClose={this.closeModal} open={this.state.modalOpen} />

		permissions = (
			<List
				noitemelem={<NoItems text={'No Files Audited'}/>}
				columns={[
					{
						component: StandardListCell,
						sortable: true,
						title: 'Name',
						field: 'name',
					},
					{
						component: StandardListCell,
						sortable: true,
						title: 'File Type',
						field: 'type',
					},
					{
						component: StandardListCell,
						title: 'Size',
						field: 'size',
						maxWidth: 'none',
						custom: function(value) {
							return getFormat('fileSize')(value.size)
						}
					},
				]}
				getlist={this.getFiles}
				rowOnClick={this.openFileModal}
				filters={[]}
			/>
		)


		return (
			<div className='page'>
				{auditHeader}
				{permissions}
				{modal}
			</div>
		)
	}
}

export default AuditFilesPage;
