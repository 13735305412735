import React from "react";
import GridDuck from "gridduck";
import './DuckCharge.scss';
import {List, StandardListCell, DemandListCell, Tabs} from "gd-react";
import NoItems from "../../components/noItems";
import formatter from "../../services/formatter";

class DuckCharge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.onTabClick = this.onTabClick.bind(this);
        this.tabs = [[
            {
                id: 1,
                title: 'Users',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 1 || !this.props.openOnTab)
            },
            {
                id: 2,
                title: 'Charge Points',
                onTabClick: this.onTabClick,
                disabled: !this.props.item,
                selected: (this.props.openOnTab === 2)
            },
            {
                id: 3,
                title: 'Sessions',
                onTabClick: this.onTabClick,
                disabled: !this.props.item,
                selected: (this.props.openOnTab === 3)
            }
        ]]

    }

    onTabClick(ev, tab) {
        this.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    componentDidMount() {
        let self = this;
        GridDuck.getDuckChargeAdmins({
            getAll: true, filters: [
                {
                    field: 'kpis',
                    value: true
                }
            ]
        }).then((DuckChargeAdminData) => {
            console.log(DuckChargeAdminData, ' : duck charge Admin Data');
            self.setState({kpis: DuckChargeAdminData.list[0].kpis});
        })
    }

    async getUsersList(retrievalParams) {
        let res = await GridDuck.getDuckChargeAdmins({
            getAll: true, filters: [
                {
                    field: 'users',
                    value: true
                }
            ]
        });
        return {list: res.list[0].users, total: res.list[0].users.length};
    }
    async getChargePointList(retrievalParams) {
        let res = await GridDuck.getDuckChargeAdmins({
            getAll: true, filters: [
                {
                    field: 'chargePoints',
                    value: true
                }
            ]
        });
        console.log(res.list[0].chargePoints, ' : res.list[0].chargePoints');
        return {list: res.list[0].chargePoints, total: res.list[0].chargePoints.length};
    }
    async getSessionsList(retrievalParams) {
        let res = await GridDuck.getDuckChargeAdmins({
            getAll: true, filters: [
                {
                    field: 'sessions',
                    value: true
                }
            ]
        });
        console.log(res.list[0].sessions, ' : res.list[0].sessions');
        return {list: res.list[0].sessions, total: res.list[0].sessions.length};
    }

    render() {
        return (
            <div className='page Account DuckCharge not-flex grey'>
                <div className={'duck-charge-kpis row'}>
                    <div className={'duckcharge-kpi'}>
                        <div
                            className={'duckcharge-kpi-number'}>{this.state.kpis ? this.state.kpis.users : 'Loading'}</div>
                        <div className={'duckcharge-kpi-label'}>App Users</div>
                    </div>
                    <div className={'duckcharge-kpi'}>
                        <div
                            className={'duckcharge-kpi-number'}>{this.state.kpis ? this.state.kpis.charge_points : 'Loading'}</div>
                        <div className={'duckcharge-kpi-label'}>Charge Points</div>
                    </div>
                    <div className={'duckcharge-kpi'}>
                        <div
                            className={'duckcharge-kpi-number'}>{this.state.kpis ? this.state.kpis.sessions : 'Loading'}</div>
                        <div className={'duckcharge-kpi-label'}>Total Sessions</div>
                    </div>
                    <div className={'duckcharge-kpi'}>
                        <div
                            className={'duckcharge-kpi-number'}>{this.state.kpis ? this.state.kpis.sessions_per_day : 'Loading'}</div>
                        <div className={'duckcharge-kpi-label'}>Sessions Per Day<br/><span style={{fontSize: '12px'}}>since 01/06/2023</span>
                        </div>
                    </div>
                </div>
                {this.tabs ? <Tabs tabData={this.tabs}/> : null}
                {this.tabs[0][0] && this.tabs[0][0].selected ? <div className={'row'}>
                    <List
                        noitemelem={<NoItems text={'No Users'}/>}
                        columns={[
                            {component: StandardListCell, title: 'Email Address', field: 'username', maxWidth: 'auto'}
                        ]}
                        getlist={this.getUsersList}
                        rowOnClick={() => {
                        }}/>
                </div> : null}
                {this.tabs[0][0] && this.tabs[0][1].selected ? <div className={'row'}>
                    <List
                        noitemelem={<NoItems text={'No Charge Points'}/>}
                        columns={[
                            {component: StandardListCell, title: 'Name', field: 'chargePointName'},
                            {component: StandardListCell, title: 'Station', field: 'stationName', maxWidth: 'auto'},
                        ]}
                        getlist={this.getChargePointList}
                        rowOnClick={() => {
                        }}/>
                </div> : null}
                {this.tabs[0][0] && this.tabs[0][2].selected ? <div className={'row'}>
                    <List
                        noitemelem={<NoItems text={'No Sessions'}/>}
                        columns={[
                            {component: StandardListCell, title: 'Start', field: 'startTime'},
                            {component: StandardListCell, title: 'End', field: 'endTime'},
                            {component: DemandListCell, title: 'Total Usage', field: 'totalUsage'},
                            {component: StandardListCell, title: 'Total Cost', field: 'totalCost', custom: (item) => formatter('currency')('gbp').format(item.totalCost / 100)}
                        ]}
                        getlist={this.getSessionsList}
                        rowOnClick={() => {
                        }}/>
                </div> : null}
            </div>
        )
    }
}

export default DuckCharge;