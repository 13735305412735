import React, { Component } from 'react';

class LogarithmicForm extends Component {
    constructor(props) {
        super(props);
        // Initialize state here
        this.state = {};
    }


    render() {
        return (
            <div>
                LogarithmicForm
            </div>
        );
    }
}

export default LogarithmicForm;