import React from "react";
import GridDuck from "gridduck";
import {
	StateOptions,
	Icon,
	List,
	LastActiveCell,
	EventBreakdownCell, Button
} from "gd-react";
import "../Rules.scss";

import moment from "moment";
import history from "../../meta/history";

class RulePageThermostat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {assetNames: [], assetIds: []};
        let self = this;
        console.log("Rule Page Alert", props);
        if (props.rule.triggers && props.rule.triggers.length > 0 && props.rule.triggers[0].assets) {
            console.log(props.rule.triggers[0].assets);
            let promises = props.rule.triggers[0].assets.map(function (a) {
                return GridDuck.getAsset({id: a})
            });
            Promise.all(promises).then(function (results) {
                console.log("name results", results);
                self.setState({
                    assetIds: props.rule.triggers[0].assets,
                    assetNames: results.map(a => a.name + " (" + a.siteName + ")")
                });
            })
        }
        if (props.rule.alerts && props.rule.alerts.length > 0 && props.rule.alerts[0].assets) {
            let alertAssetsPromises = self.props.rule.alerts[0].assets.map(a => GridDuck.getAsset({id: a}));
            Promise.all(alertAssetsPromises).then(function (results) {
                self.setState({
                    alertAssetNames: results.map(a => a.name + " (" + a.siteName + ")"),
					alertAssetIds: self.props.rule.alerts[0].assets
                });
            });
        }
        this.getEventList = this.getEventList.bind(this);
        this.getAssetName = this.getAssetName.bind(this);
    }

    isDifferentAssets(oldAssets, newAssets) {
        if (oldAssets.length !== newAssets.length) {
            return true;
        }
        return !oldAssets.every((value, index) => value === newAssets[index]);
    }

    getAssetName(assetId) {
        return GridDuck.getAsset({id: assetId}).then(function (asset) {
            return Promise.resolve(asset.name);
        });
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        console.log("Component changed", this.state.assetIds, this.props.rule.triggers[0].assets, prevProps.rule.triggers[0].assets);
        let self = this;
        if (this.props.rule.triggers[0]) {
            if (!prevProps.rule || !prevProps.rule.triggers || prevProps.rule.triggers.length < 1 || this.isDifferentAssets(prevProps.rule.triggers[0].assets, this.props.rule.triggers[0].assets)) {
                console.log("change asset names");
                let promises = self.props.rule.triggers[0].assets.map(function (a) {
                    return GridDuck.getAsset({id: a});
                });
                Promise.all(promises).then(function (results) {
                    self.setState({
                        assetNames: results.map(a => a.name + " (" + a.siteName + ")"),
                        assetIds: results.map(a => a.id)
                    });
                })
                let alertAssetsPromises = self.props.rule.alerts[0].assets.map(a => GridDuck.getAsset({id: a}));
                Promise.all(alertAssetsPromises).then(function (results) {
                    self.setState({
                        alertAssetNames: results.map(a => a.name + " (" + a.siteName + ")")
                    });
                });
            }
        }
    }

    getEventList(params) {
		console.log("Get event list", params);
        return GridDuck.getRuleEvents(params);
    }


    render() {
        console.log("Rule page thermostat", this.props.rule);
        let assetLogic;
        let alertLog;
        let until;

        // let curPrev;
        // let duration;
        let assetNames;
        let alertAssetNames;
        // let over;
        // let throttleDescription;
        let dataType;
        let self = this;
        let alertState = {
            label: ''
        };
        let threshold;
        let thresholdType;
        let status;
        let dT;
        let link;
        let unit;
        if (this.state.alertAssetNames) {
			alertAssetNames = this.state.alertAssetNames.map(function(name, index) {
				return (<span style={{'text-decoration':'underline', 'cursor': 'pointer'}} onClick={() => history.push("/device/" + self.state.alertAssetIds[index])}>{name}</span>)
			});
        }
        if (self.props.rule.triggers.length > 0) {
            if (this.state.assetNames) {
                assetNames = this.state.assetNames.map(function(name, index) {
					return (<span style={{'text-decoration':'underline', 'cursor': 'pointer'}} onClick={() => history.push("/device/" + self.state.assetIds[index])}>{name}</span>)
				});
            }
            let factor = 1;
            if (self.props.rule.triggers[0].dataType === 'TEMPERATURE' || self.props.rule.triggers[0].dataType === 'HUMIDITY') {
                factor = 100;
            }
            if (self.props.rule.triggers[0].dataType === 'CURRENT' || self.props.rule.triggers[0].dataType === 'HUMIDITY') {
                factor = 1000;
            }
            thresholdType = this.props.rule.triggers[0].thresholdType;
            threshold = this.props.rule.triggers[0].threshold / factor;


            StateOptions.forEach(function (stateOption) {
                stateOption.dataTypes.forEach(function (dataType) {
                    if (dataType.type === self.props.rule.triggers[0].dataType) {
                        dT = dataType;
                    }
                });
                stateOption.options.forEach(function (option) {
                    if (option.state === self.props.rule.alerts[0].state) {
                        alertState = option;
                    }
                })
            })
            if (dT) {
                dataType = dT.label;
                unit = dT.unit;
            }

            if (this.props.rule.triggers[0].requireAll) {
                assetLogic = "all of";
            } else {
                assetLogic = "any of";
            }

            if (this.props.rule) {
                alertLog = (
                    <div className={"alert-log"}>
                        <p className={"label"}>Recently triggered alerts</p>
                        <div className={"action"}>
                            <List
                                columns={[
                                    {
                                        component: LastActiveCell,
                                        bold: true,
                                        title: 'Date',
                                        field: 'time',
                                        sortable: false,
                                        defaultSort: 'desc'
                                    },
                                    {
                                        component: EventBreakdownCell,
                                        title: 'Breakdown',
                                        field: 'event',
                                        getAssetName: this.getAssetName
                                    }
                                ]}
                                filters={[
                                    {
                                        field: 'ruleId',
                                        value: self.props.rule.id
                                    },
                                    {
                                        field: 'start',
                                        value: Math.round(moment().subtract(1, 'months').unix() / 100) * 100
                                    },
                                    {
                                        field: 'end',
                                        value: Math.ceil(new Date().getTime() / 100000) * 100
                                    }]
                                }
								itemsPerPage={10}
								getlist={this.getEventList}
                            />
                        </div>
                    </div>
                )
            }
            if (this.props.rule.persistent) {
                status = (<div className={"alert-preview"}>
                    <p className={"label"}>Current Status</p>
                    <p className={'trig-by'}>{this.props.rule.active ? 'Active' : 'Inactive'}</p>
                </div>);
            }
            if (this.props.rule.triggers && !this.props.rule.triggers[0].isSymmetric && this.props.rule.triggers[0].thresholdExit !== null) {
                until = ' until it gets to ' + this.props.rule.triggers[0].thresholdExit / factor + unit
            }
            if (!this.props.isMobile) {
				link = (
					<div style={{display: 'inline-block', float: 'left'}}>
						<Button label={"View Rule"} color={'gd-grey'}
								onClick={() => history.push("/automations/" + this.props.rule.id)}/>
					</div>
				)
			}
        }

        return (
            <div className={"details"}>
                <div className={'details-inner'} style={{'maxWidth': '650px'}}>
                    <div className={"detail-card"}>
                        {/*<Icon color={'gd-red'} size={!this.props.strippedback ? '50' : '20'}*/}
                        {/*      icon={'FaThermometerThreeQuarters'}/>*/}
                        {/*<div className={"right-content"}>*/}
                            {/*<h1>{this.props.rule.name}</h1>*/}
                            <p>{this.props.rule.description}</p>
                        {/*</div>*/}
					</div>
					{this.props.strippedback ? link : ''}
					{!this.props.strippedback ? status : ''}
                    {!this.props.strippedback ? <div className={"alert-preview"}>
                        <p className={"label"}>Triggered</p>
                        <p className={'trig-by'}>If
                            the {dataType} goes {thresholdType} {threshold}{unit} for {assetLogic} {assetNames}{until}.</p>
                    </div> : ''}
                    {!this.props.strippedback ? <div className={"alert-preview"}>
                        <p className={"label"}>Changing state of these assets to {alertState.label}</p>
                        <p className={'trig-by'}>{alertAssetNames}</p>

                    </div> : ''}
                    {!this.props.strippedback ? alertLog : ''}
                </div>
            </div>
        );
    }
}

export default RulePageThermostat;
