import React, {Component} from 'react';
import {Input} from "gd-react";
import Icon from "../../../components/Icon/Icon";

class CategoricalCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let i = this.props.index;
        let boolean = this.props.unitType === 'boolean';
        let booleanLabel = '';
        if (boolean) {
            booleanLabel = i === 0 ? 'True ' : 'False ';
        }

        return (
            <div className={'row'} style={{alignItems: 'end'}}>
                <div className={'form-divider'}/>
                <Input required
                       onChange={(val) => this.props.onChange({
                           id: this.props.category.id,
                           key: 'value',
                           value: val.target.value
                       })}
                       placeholder={'E.g. LOW'}
                       value={this.props.category.value}
                       error={this.props.errors[this.props.category.id + 'value'] && this.props.triedToSave}
                       label={(boolean || i === 0) ? booleanLabel + 'Value' : ''}/>
                <div className={'form-divider'}/>
                <Input required
                       onChange={(val) => this.props.onChange({
                           id: this.props.category.id,
                           key: 'label',
                           value: val.target.value
                       })}
                       placeholder={'E.g. Low'}
                       value={this.props.category.label}
                       error={this.props.errors[this.props.category.id + 'label'] && this.props.triedToSave}
                       label={(boolean || i === 0) ? (booleanLabel + 'Label') : ''}/>
                <div className={'form-divider'}/>
                {this.props.categories.length > 1 && this.props.unitType !== 'boolean' && <div style={{
                    height: '47px',
                    minWidth: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}><Icon icon={'FaTrash'} color={'gd-red'} onIconClick={() => {
                    this.props.removeCategory(this.props.category)
                }}/></div>}
            </div>
        );
    }
}

export default CategoricalCategory;