import React, {Component} from 'react';
import {GdCheckbox, Dropdown} from "gd-react";
import GenerateSingleDateString from "../pages/dataUpload/GenerateSingleDateString";
import * as _ from 'lodash';

let subTitleStyle = {color: 'grey', fontWeight: '500', marginRight: '5px'};

const dateFormatOptions = (params) => {
    let compareFunc = params?.compareFunc, dataOnly = params?.dataOnly;
    let formatOptions = [
        {divider: true, "example": "Date"},
        {"value": "DD", "title": "2 digit day of month", "example": "DD", category: 'day', categoryOrder: 1},
        {"value": "D", "title": "1 digit day of month", "example": "D", category: 'day', categoryOrder: 1},
        {"value": "Do", "title": "Day of the month with ordinator", "example": "Do", category: 'day', categoryOrder: 1},
        {"value": "MM", "title": "2 digit month", "example": "MM", category: 'month', categoryOrder: 2},
        {"value": "M", "title": "1 digit month", "example": "M", category: 'month', categoryOrder: 2},
        {"value": "YYYY", "title": "4 digit year", "example": "YYYY", category: 'year', categoryOrder: 3},
        {"value": "YY", "title": "2 digit year", "example": "YY", category: 'year', categoryOrder: 3},
        {divider: true, "example": "Time"},
        {"value": "HH", "title": "2 digit 24-hour format hour", "example": "HH", category: 'hour', categoryOrder: 4},
        {"value": "H", "title": "1 digit 24-hour format hour", "example": "H", category: 'hour', categoryOrder: 4},
        {"value": "hh", "title": "2 digit hour in 12-hour format", "example": "hh", category: 'hour', categoryOrder: 4},
        {"value": "h", "title": "1 digit hour in 12-hour format", "example": "h", category: 'hour', categoryOrder: 4},
        {"value": "mm", "title": "2 digit minute", "example": "mm", category: 'minute', categoryOrder: 5},
        {"value": "m", "title": "1 digit minute", "example": "m", category: 'minute', categoryOrder: 5},
        {"value": "ss", "title": "2 digit second", "example": "ss", category: 'second', categoryOrder: 6},
        {"value": "s", "title": "1 digit second", "example": "s", category: 'second', categoryOrder: 6},
        {"value": "a", "title": "AM/PM indicator", "example": "a", category: 'amPm', categoryOrder: 7},
        {divider: true, "example": "Delimiters"},
        {"value": " ", "title": "Space", "example": ""},
        {"value": ":", "title": "Colon", "example": ":"},
        {"value": "/", "title": "Slash", "example": "/"},
        {"value": "-", "title": "Dash", "example": "-"},
        {"value": ".", "title": "Period", "example": "."},
        {"value": ",", "title": "Comma", "example": ","},
        {"value": "@ ", "title": "at", "example": "@"},
        {"value": "T", "title": "ISO 8601 time element", "example": "T"},
        {"value": "Z", "title": "UTC indicator", "example": "Z"},
        // {"value": "custom", "title": "Custom Delimiter", "example": "_, ^, $"},
    ]

    if (compareFunc) {
        formatOptions = formatOptions.filter(compareFunc);
    }

    if (dataOnly) {
        return formatOptions;
    }

    return formatOptions.map(fo => {
        return {
            title: <span style={subTitleStyle}>{fo.example}</span>,
            subtitle: <span>- {fo.title}</span>,
            id: fo.value,
            value: fo.value,
            divider: fo.divider,
            category: fo.category,
            categoryOrder: fo.categoryOrder
        }
    })
}

class dateFormatGenerator extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        //     dateMultipleColumns: false,
        //     dateFormat: [],
        //     dateColumn: '',
        //     secondColumn: '',
        //     secondColumnFormat: '',
        //     minuteColumn: '',
        //     minuteColumnFormat: '',
        //     hourColumn: '',
        //     hourColumnFormat: '',
        //     dayColumn: '',
        //     dayColumnFormat: '',
        //     monthColumn: '',
        //     monthColumnFormat: '',
        //     yearColumn: '',
        //     yearColumnFormat: '',
        //     unixFormat: ';
        //     dateFormatExample: ';
        let dateColumn = this.props.columnData?.find(cd => cd.possibleDate);
        if (!this.props.dataType?.dateColumn && dateColumn) {
            this.props.onChange({
                dt: this.props.dataType,
                label: 'dateColumn',
                value: dateColumn.id
            });
        }
        ['generateDateSpreadComponents'].forEach(func => this[func] = this[func].bind(this));
    }

    generateDateSpreadComponents() {
        let dateSpreadOverColumnsComponents = _.groupBy(dateFormatOptions().filter(fo => fo.category), 'categoryOrder')
        return Object.values(dateSpreadOverColumnsComponents).map((formatArray, i) => {
            if (formatArray[0].category === 'amPm' && (!this.props.dataType.hourColumnFormat || (this.props.dataType.hourColumnFormat.indexOf('H') !== -1))) return null;
            if (!this.props.dataType[`${formatArray[0].category}ColumnFormat`]) {
                this.props.onChange({
                    dt: this.props.dataType,
                    label: `${formatArray[0].category}ColumnFormat`,
                    value: formatArray[0].value
                });
            }
            return <div className={'separate-column-date'}>
                {this.props.columnsDropDown(_.capitalize(formatArray[0].category), this.props.dataType[`${formatArray[0].category}Column`], `${formatArray[0].category}_column`, (val) => {
                    this.props.onChange({
                        dt: this.props.dataType,
                        label: `${formatArray[0].category}Column`,
                        value: val
                    });
                }, this.props.dataType, {dateOnly: true, filterOutSelectedAlready: true, error: this.props.triedToSave && this.props.dataType.errors['dateMultipleColumns']})}
                <div className={'row'} style={{marginTop: '15px'}}>
                    <Dropdown label={"Format"}
                              fixeditems={formatArray.map(f => {
                                  return {
                                      title: f.title,
                                      subtitle: f.subtitle,
                                      value: f.value
                                  }
                              })}
                              name={`${formatArray[0].category}ColumnFormat`}
                              value={this.props.dataType[`${formatArray[0].category}ColumnFormat`]}
                              onChange={(val) => {
                                  this.props.onChange({
                                      dt: this.props.dataType,
                                      label: `${formatArray[0].category}ColumnFormat`,
                                      value: val.target.value
                                  });
                              }}/>
                </div>
            </div>
        })
    }

    render() {
        let formatOptions = this.props.columnData?.find(cd => cd.id === this.props.dataType?.dateColumn)?.dateFormatOptions;
        let selectedFormat = formatOptions?.find(fo => fo.value === this.props.dataType.dateFormatExample);
        let unixFromSelectedFormat = selectedFormat && selectedFormat.type === 'unix';
        let dateStringFromSelectedFormat = selectedFormat && selectedFormat.type === 'date-string';
        let unitMustBeUnix = (this.props.dataType.dateColumn && this.props.columnData.find(cd => cd.id === this.props.dataType.dateColumn) && !this.props.columnData.find(cd => cd.id === this.props.dataType.dateColumn).containsString && this.props.columnData.find(cd => cd.id === this.props.dataType.dateColumn).containsNumeric);
        let unitMustBeDateString = (this.props.dataType.dateColumn && this.props.columnData.find(cd => cd.id === this.props.dataType.dateColumn) && !this.props.columnData.find(cd => cd.id === this.props.dataType.dateColumn).containsNumeric && this.props.columnData.find(cd => cd.id === this.props.dataType.dateColumn).containsString);
        let dateSpreadOverColumnsComponents = this.generateDateSpreadComponents();
        return (
            <div className={'column'} style={{overflow: 'hidden', position: 'relative'}}>
                <label style={{paddingLeft: '5px'}}>Date Details</label>
                <GdCheckbox checked={this.props.dataType.dateMultipleColumns} name={'dateMultipleColumns'}
                            onChange={(val) => {
                                this.props.onChange({
                                    dt: this.props.dataType,
                                    label: 'dateMultipleColumns',
                                    value: val.target.checked
                                });
                            }} label={'Date spread across multiple columns'}/>
                {!this.props.dataType.dateMultipleColumns ?
                    <div className={'row date-in-one-column'}>
                        <div className={'column'}>
                            <div className={'row'}>
                                {this.props.columnsDropDown('Date Column', this.props.dataType.dateColumn, 'date_column', (val) => {
                                    this.props.onChange({
                                        dt: this.props.dataType,
                                        label: 'dateColumn',
                                        value: val
                                    });
                                }, null, {dateOnly: true, error: this.props.dataType.errors['dateColumn'] && this.props.triedToSave})}
                            </div>
                            {this.props.dataType.dateColumn && formatOptions && formatOptions.length ?
                                <div className={'row'} style={{marginTop: '15px'}}>
                                    <Dropdown key={"dateFormatExample"} label={'Detected Formats'}
                                              placeholder={'Select Date Format'}
                                              error={this.props.dataType.errors['dateFormatExample'] && this.props.triedToSave}
                                              onChange={(val, obj) => {
                                                  let formatOption = formatOptions.find(fo => fo.value === val.target.value);
                                                  let updateObj = {
                                                      dt: this.props.dataType,
                                                      label: 'dateFormatExample',
                                                      value: formatOption.likelyFormat || formatOption.value,
                                                  };
                                                  if (formatOption.type === 'unix') {
                                                      updateObj.cb = () => {
                                                          this.props.onChange({
                                                              dt: this.props.dataType,
                                                              label: 'unixFormat',
                                                              value: formatOption.timeUnit,
                                                          });
                                                      }
                                                  }
                                                  this.props.onChange(updateObj);

                                              }}
                                              fixeditems={formatOptions}
                                              value={this.props.dataType.dateFormatExample}/>
                                </div> : null}


                            {unitMustBeUnix || unixFromSelectedFormat ?
                                <div>
                                    {unitMustBeUnix ?
                                        <p className={'info-text'}>Based on the data in that column you should be using
                                            Unix
                                            Timestamps</p> :
                                        <p className={'info-text'}>The detected unit of time
                                            was {selectedFormat.timeUnit}</p>}
                                    <Dropdown key={"unix-timestamp"} label={'What is the unit of time?'}
                                              placeholder={'None'}
                                              error={this.props.dataType.errors['unixFormat'] && this.props.triedToSave}
                                              onChange={(val) => {
                                                  this.props.onChange({
                                                      dt: this.props.dataType,
                                                      label: 'unixFormat',
                                                      value: val.target.value
                                                  });
                                              }}
                                              fixeditems={[
                                                  {title: 'Seconds', value: 'seconds'},
                                                  {
                                                      title: 'Milliseconds',
                                                      value: 'milliseconds'
                                                  }
                                              ]} value={this.props.dataType.unixFormat}/>
                                </div> : null}
                            {unitMustBeDateString || dateStringFromSelectedFormat ?
                                <div>
                                    {unitMustBeDateString ?
                                        <p className={'info-text'}>Based on the data in that column you should be using
                                            a
                                            Date
                                            string (e.g. 01/01/2023 12:00:00)</p> :
                                        <p className={'info-text'}>The detected date string format
                                            was {selectedFormat.likelyFormat}</p>}
                                    <label style={{paddingLeft: '5px', color: this.props.dataType.errors['dateFormat'] && this.props.triedToSave ? 'red' : 'inherit'}}>Date Format</label>
                                    <p className={'info-text'}>Add components to describe the format of the dates in
                                        "{this.props.dataType.dateColumn}" column. Don't forget to add separators (E.g.
                                        /, :, -)</p>
                                    <GenerateSingleDateString dateFormatOptions={dateFormatOptions}
                                                              error={this.props.dataType.errors['dateFormat'] && this.props.triedToSave}
                                                              detectedFormat={this.props.dataType.dateFormatExample}
                                                              onChange={(val) => {
                                                                  this.props.onChange({
                                                                      dt: this.props.dataType,
                                                                      label: 'dateFormat',
                                                                      value: val
                                                                  });
                                                              }} dateFormat={this.props.dataType.dateFormat}/>
                                </div> : null}
                        </div>
                    </div> :
                    <div className={'date-across-multiple-columns row'}>
                        {dateSpreadOverColumnsComponents}
                    </div>}
            </div>
        );
    }
}

export default dateFormatGenerator;