import React from "react";
import FileCard from "./FileCard";
import {SelectableGroup} from './../../lib/react-selectable-fast/index';

class FileList extends React.Component {
    constructor(props) {
        super(props);
        this.getFiles = this.getFiles.bind(this);
        this.handleSelectionFinish = this.handleSelectionFinish.bind(this);
        this.handleSelectionClear = this.handleSelectionClear.bind(this);
        this.handleSelectedItemUnmount = this.handleSelectedItemUnmount.bind(this);
        this.handleSelecting = this.handleSelecting.bind(this);
        this.selectedList = [];
        this.state = {
            tolerance: 1,
            isGlobal: true
        }
    }


    getFiles() {
        let self = this;
        let files = [];
        this.props.uploadingFiles.forEach(function (uploadingFile) {
            files.push(
                <FileCard key={Math.random()}
                          uploading
                          file={uploadingFile}
                />
            )
        })
        this.props.files.forEach(function (file) {
            files.push(
                <FileCard key={Math.random()}
                          openFile={self.props.openFile}
                          selected={self.props.selectedFiles.indexOf(file) > -1}
                          file={file}
                />
            )
        })
        return files;
    }

    handleSelecting(elemList) {
        if (elemList.length !== this.selectedList.length) {
            this.props.selectFiles(elemList.map(elem => elem.props.file));
            this.selectedList = elemList;
        }
    }

    handleSelectionClear() {
        console.log("handle selection clear");
    }

    handleSelectionFinish(elemList) {
        if (elemList.length !== this.selectedList.length) {
            this.props.selectFiles(elemList.map(elem => elem.props.file));
            this.selectedList = elemList;
        }
    }

    handleSelectedItemUnmount() {
        console.log("item unmound");
    }

    render() {
        let files = this.getFiles();
        return (
            <SelectableGroup
                selectboxClassName="select-box"
                className="file-list"
                clickClassName="tick"
                disabled={this.props.disabled}
                mixedDeselect={true}
                resetOnStart={true}
                enableDeselect={true}
                deselectOnEsc={true}
                tolerance={this.state.tolerance}
                globalMouse={this.state.isGlobal}
                allowClickWithoutSelected={true}
                duringSelection={this.handleSelecting}
                onSelectionClear={this.handleSelectionClear}
                onSelectionFinish={this.handleSelectionFinish}
                onSelectedItemUnmount={this.handleSelectedItemUnmount}
                ignoreList={['.not-selectable']}
                allowCtrlClick={true}
                allowShiftClick={true}>
                {files}
            </SelectableGroup>
        );
    }


}

export default FileList;
