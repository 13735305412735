import {Redirect, Route} from "react-router-dom";
import React from "react";
import GridDuck from 'gridduck';
import DecoratedPage from "./DecoratedPage";
import history from "../meta/history";

//Sorry future guys, this is a bit of a mess to follow.
//This is a Higher Order Component and uses some newer syntax. I've killed off the arrow functions and ternaries as it makes this impossible to comprehend...
//A Higher Order Component (React)
//https://reactjs.org/docs/higher-order-components.html
//Newish JS Syntax in use:
//spread syntax: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax
//rest parameters: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/rest_parameters
//named parameters, component argument object renamed to Component, not sure what this is called to cite the notes
//object initializer maybe covers it: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Object_initializer
//then we're onto standard basic JSX (React)... props is injected into render obvs, and render is always a function
//https://reactjs.org/docs/components-and-props.html

const PublicPage = function ({component: Component, ...rest}) {
    let renderObj = function (props) {
        if (GridDuck.getAccessToken() && window.location.pathname === '/') {
			return (<Redirect to='/siteGroup/all'/>)
        } else {
			return (
				<Component {...props}/>
			)
        }
    };
    return (<Route {...rest} render={renderObj}/>)
};

export default PublicPage;
