import React from "react";
import "./ShareWithOrg.scss"
import {Dropdown, GdAutocomplete, Button, Input} from "gd-react";
import GridDuck from "gridduck";

class ShareWithOrg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inFocus: false,
            selectedAccessLevel: 'view',
            selectedPartnerType: 'installer'
        }
        this.textInputRef = React.createRef();
        this.focus = this.focus.bind(this);
        this.blurTextInput = this.blurTextInput.bind(this);
        this.searchEmail = this.searchEmail.bind(this);
        this.selectEmail = this.selectEmail.bind(this);
        this.selectedAccessLevel = this.selectedAccessLevel.bind(this);
        this.selectedPartnerType = this.selectedPartnerType.bind(this);
        this.createPermission = this.createPermission.bind(this);
        this.createPartnerPermission = this.createPartnerPermission.bind(this);
    }

    focus() {
        let self = this;
        this.setState({inFocus: true}, function () {
            if (self.textInputRef.current && self.textInputRef.current.focusInput) {
                self.textInputRef.current.focusInput();
            }
        })
    }

    createPermission() {
        let newPermission = {
            siteId: this.props.site.id,
            orgId: this.state.selectedOrgId,
            orgName: this.state.selectedOrg,
            permName: this.state.selectedAccessLevel
        };
        return GridDuck.createSitePermission(newPermission);
    }

    createPartnerPermission() {
        console.log(this.state.selectedPartnerType, ' : selectedPartnerType');
        let newPerm = {
            orgFromId: this.props.ownorgid,
            orgToId: this.state.selectedOrgId,
            permType: this.state.selectedPartnerType
        }
        this.setState({inFocus: false, selectedOrg: false});
        return GridDuck.createOrgPartner(newPerm);
    }

    blurTextInput() {
        this.setState({inFocus: false})
    }

    selectedAccessLevel(level) {
        this.setState({selectedAccessLevel: level.target.value});
    }

    selectedPartnerType(level) {
        console.log("selected access level", level.value);
        this.setState({selectedPartnerType: level.value});
    }

	selectEmail(item, other) {
		this.setState({
			selectedOrg: other.subTitle,
			selectedOrgId: other.id
		});
	}

    searchEmail(string) {
        return GridDuck.getOrganisationSearchs({
            filters: [{
                field: 'query',
                value: string
            }]
        }).then(function (results) {
            let fixedResults = results.list.map(function (r) {
                return {
                    id: r.orgId,
                    title: r.username,
                    subTitle: r.orgName,
                    icon: 'GoPerson'
                }
            });
            return Promise.resolve(fixedResults)
        });
    }

    render() {
        let button, input, accessLevel, partnerType, confirm, cancel;
        if (!this.state.inFocus && !this.state.selectedOrg) {
            button = (
                <div className={'normal-width'}><Button label={!this.props.partner ? "Share With Organisation" : "Add Partner"} onClick={this.focus}/></div>
            );
        }
        if (this.state.inFocus && !this.state.selectedOrg) {
            input = (
                <GdAutocomplete
                    autoFocus
                    async
                    inputblur={this.blurTextInput}
                    label={'Member\'s email address'}
                    onChange={this.selectEmail}
                    getList={this.searchEmail}
                    placeholder={'example@gridduck.com'}/>
            )
        }
        if (this.state.selectedOrg) {
            let accessLevels = [
                {
                    id: 0,
                    title: 'Admin',
                    value: 'admin',
                },
                {
                    id: 1,
                    title: 'Edit',
                    value: 'edit',
                },
                {
                    id: 2,
                    title: 'Boost/Snooze',
                    value: 'boost_snooze',
                },
                {
                    id: 3,
                    title: 'View',
                    value: 'view',
                }
            ]
            let partnerTypes = [
                {
                    id: 0,
                    title: 'Installer',
                    value: 'installer',
                }
            ]
            input = (
                <Input top label={'Organisation'} disabled={true} value={this.state.selectedOrg}/>
            )
            accessLevel = (
                <Dropdown onChange={this.selectedAccessLevel} label={'Access Level'} fixeditems={accessLevels}
                          value={this.state.selectedAccessLevel}/>
            )
            partnerType = (
                <Dropdown disabled onSelect={this.selectedPartnerType} label={'Partner Type'} fixeditems={partnerTypes}
                          value={this.state.selectedPartnerType}/>
            )
            confirm = (
                <Button style={{marginTop: '20px'}} label={"Share"} additionalclasses={'sm'}
                        onClick={!this.props.partner ? this.createPermission : this.createPartnerPermission}
                        progressRes/>
            )
            cancel = (
                <Button style={{marginTop: '20px'}} label={"Cancel"} color={"gd-grey"} outline
                        onClick={() => this.setState({selectedOrg: null, inFocus: false})}/>
            )
        }
        return (<div className={"row reverse sharewithorg"}>
            {cancel}
            {confirm}
            {button}
            {!this.props.partner ? accessLevel : partnerType}
            {input}

        </div>)
    }
}

export default ShareWithOrg;
