import React from "react";
import "./TermsOfSale.scss";
import {Button, CardBody, GdCheckbox, GdModal, Icon, Input, Tooltip} from "gd-react";
import GenericLoader from "./GenericLoader";

class PaymentMethods extends React.Component {

    constructor(props) {
        super(props);
        this.toTitleCase = this.toTitleCase.bind(this);
        this.deleteCard = this.deleteCard.bind(this);
        this.state = {}
    }

    toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    deleteCard(card) {
        let self = this;
        self.setState({loaded: false});
        return card.delete().then(function () {
            self.props.loadMethods();
            self.setState({cardToDelete: null});
        });
    }

    render() {
        let self = this;
        return (
            <div className={'card-list'}>
                {this.props.methods.map(function (c) {
                    if (c.card) {
                        return <div className={'card'} key={c.id}>
                            <div className={'column'}>
                                <p style={{marginTop: 0}}>Debit Card
                                    ({self.toTitleCase(c.card.brand)})</p>
                                <p className={'expires'}> xxxx xxxx
                                    xxxx {c.card.last4}</p>
                                <p className={'expires'}>Expires: {c.card.exp_month || c.card.expMonth}/{c.card.exp_year || c.card.expYear}</p>
                            </div>
                            <div className={'end-icon'}>
                                {c.card.default_payment_method || c.card.defaultPaymentMethod ?
                                    <p className={'default-icon ' + (self.props.methods.length === 1 ? 'no-margin' : '')}>Default</p> :
                                    self.props.editable ?
                                        <p className={'default-icon make-default ' + (self.props.methods.length === 1 ? 'no-margin' : '')}
                                           onClick={() => {
                                               self.setState({loaded: false});
                                               c.set({card: {default_payment_method: true}}).then(() => self.props.loadMethods())
                                           }}>Make
                                            default</p> : null}
                            </div>
                            {self.props.methods.length > 1 && self.props.editable ? <div className={'end-icon'}>
                                <Tooltip label={'Delete Card'} title={'Delete Card'} position={'top'}>
                                    <Icon icon={'FaTrash'}
                                          onIconClick={() => self.setState({cardToDelete: c, showDeleteModal: true})}
                                          size={14}
                                          color={'gd-red'}/>
                                </Tooltip>
                            </div> : null}
                        </div>
                    }
                    if (c.bacs_debit) {
                        return <div className={'card'} key={c.id}>
                            <div className={'column'}>
                                <p style={{marginTop: 0}}>Direct Debit (Bacs)</p>
                                <p className={'expires'}>xxxx {c.bacs_debit.last4}</p>
                                <p className={'expires'}>{c.bacs_debit.sort_code.match(/.{1,2}/g).join('-')}</p>
                            </div>
                            <div className={'end-icon'}>
                                {c.bacs_debit.default_payment_method || c.bacs_debit.defaultPaymentMethod ?
                                    <p className={'default-icon ' + (self.props.methods.length === 1 ? 'no-margin' : '')}>Default</p> :
                                    self.props.editable ?
                                        <p className={'default-icon make-default ' + (self.props.methods.length === 1 ? 'no-margin' : '')}
                                           onClick={() => {
                                               self.setState({loaded: false});
                                               c.set({bacs_debit: {default_payment_method: true}}).then(() => self.props.loadMethods())
                                           }}>Make
                                            default</p> : null}
                            </div>
                            {self.props.methods.length > 1 && self.props.editable ? <div className={'end-icon'}>
                                <Tooltip label={'Delete Card'} title={'Delete Card'} position={'top'}>
                                    <Icon icon={'FaTrash'}
                                          onIconClick={() => self.setState({cardToDelete: c, showDeleteModal: true})}
                                          size={14}
                                          color={'gd-red'}/>
                                </Tooltip>
                            </div> : null}
                        </div>
                    }
                })}
                {this.state.showDeleteModal ? <GdModal
                    open={this.state.showDeleteModal}
                    contentLabel={"Delete Payment Method"}
                    title={"Delete Payment Method"}
                    footer={
                        <Button additionalclasses={'md'} label={'Delete Payment Method'}
                                onClick={() => this.deleteCard(this.state.cardToDelete)}
                                color={'gd-red'}
                                progressRes onComplete={() => this.setState({showDeleteModal: false})}/>
                    }
                    onClose={() => this.setState({showDeleteModal: false})}>
                    <CardBody>
                        {this.state.cardToDelete.card ? <div>
                            <p style={{marginTop: 0}}>Debit Card
                                ({self.toTitleCase(this.state.cardToDelete.card.brand)})</p>
                            <p className={'expires'}> xxxx xxxx
                                xxxx {this.state.cardToDelete.card.last4}</p>
                            <p className={'expires'}>Expires: {this.state.cardToDelete.card.exp_month || this.state.cardToDelete.card.expMonth}/{this.state.cardToDelete.card.exp_year || this.state.cardToDelete.card.expYear}</p>
                        </div> : null}
                        {this.state.cardToDelete.bacs_debit ? <div>
                            <p style={{marginTop: 0}}>Direct Debit (Bacs)</p>
                            <p className={'expires'}>xxxx {this.state.cardToDelete.bacs_debit.last4}</p>
                            <p className={'expires'}>{this.state.cardToDelete.bacs_debit.sort_code.match(/.{1,2}/g).join('-')}</p>
                        </div> : null}
                    </CardBody>
                </GdModal> : null}
            </div>
        )
    }

}

export default PaymentMethods;