import React from "react";
import {Button, CollapsibleTable} from "gd-react";
import '../../styles/_layout.scss';
import GridDuck from 'gridduck';
import getFormat from "../../services/formatter";


class SiteSettingsPhaseAudit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: []
        }
        this.auditPhases = this.auditPhases.bind(this);
        this.assetUpdated = this.assetUpdated.bind(this);
        this.getInnerData = this.getInnerData.bind(this);
    }

    getInnerData(assetId) {
        console.log("get inner data", assetId);
        return Promise.resolve([]);
    }

    assetUpdated(field,value,asset) {
        let self = this;
        const allowedFields = [
            'demandPhaseOne',
            'demandPhaseTwo',
            'demandPhaseThree',
            'pfPhaseOne',
            'pfPhaseTwo',
            'pfPhaseThree'
        ]
        let fixedValue;
        if (allowedFields.indexOf(field) !== -1) {
            let updatedTableData = self.state.tableData;
            let thisAsset = updatedTableData.find(a => a.id === asset.id);
            if (thisAsset) {
                let fieldName = 'L1';
                let innerIndex = 1;
                let fixedValue = value;
                switch(field) {
                    case 'pfPhaseOne':
                        fieldName = 'L1';
                        innerIndex = 2;
                        break;
                    case 'demandPhaseOne':
                        fieldName = 'L1';
                        innerIndex = 1;
                        value = getFormat('consumption')(value);
                        if (Math.abs(value) < 5) {
                            value = 0;
                        }
                        break;
                    case 'pfPhaseTwo':
                        fieldName = 'L2';
                        innerIndex = 2;
                        break;
                    case 'demandPhaseTwo':
                        fieldName = 'L2';
                        innerIndex = 1;
                        if (Math.abs(value) < 5) {
                            value = 0;
                        }
                        value = getFormat('consumption')(value);
                        break;
                    case 'pfPhaseThree':
                        fieldName = 'L3';
                        innerIndex = 2;
                        break;
                    case 'demandPhaseThree':
                        fieldName = 'L3';
                        innerIndex = 1;
                        if (Math.abs(value) < 5) {
                            value = 0;
                        }
                        value = getFormat('consumption')(value);
                        break;
                }
                if (!thisAsset.innerData) {
                    thisAsset.innerData = [
                        [
                            {value: fieldName},
                        ]
                    ]
                    thisAsset.innerData[0][innerIndex] = {value: value}
                } else {
                    let thisInnerRow = thisAsset.innerData.find(iD => iD[0].value === fieldName);
                    if (thisInnerRow) {
                        thisInnerRow[innerIndex] = {value: value}
                    } else {
                        thisAsset.innerData.push(
                            [
                                {value: fieldName},
                            ]
                        )
                        thisAsset.innerData[thisAsset.innerData.length - 1][innerIndex] = {value: value}
                    }
                }
                console.log("Updated table data", updatedTableData);
                self.setState({tableData: updatedTableData});
            } else {
                console.log("Can't find asset", field, asset, updatedTableData);
            }
        }
    }


    auditPhases() {
        this.setState({
            tableData: []
        });
        let self = this;
        return GridDuck.getAssets({
            filters: [
                {
                    field: 'siteId',
                    value: this.props.item.id
                },
                {
                    field: 'sku',
                    value: [
                        'SEN-POW-OWON',
                        'SEN-POW-OWON-OLD'
                    ]
                }
            ],
            getAll: true
        }).then(function (assets) {
            self.setState({
                tableData: assets.list.map(function (asset) {
                    return {
                        id: asset.id,
                        data: [
                            {
                                value: asset.name
                            },
                            {
                                value: asset.id
                            }
                        ],
                        innerData: []
                    }
                })
            });
            self.assets = assets.list;
            self.assets.forEach(function(asset) {
                asset.on('updated', self.assetUpdated);
            })
            self.assets.forEach(function(asset) {
                GridDuck.createGetPhaseBreakdown({assetId: asset.id});
            });
        })
    }

    render() {

        return (
            <div className={"page narrow not-flex grey"}>
                <div className={'column top'}>
                    <h3>Phase Audits</h3><br/>
                    <Button label={'Audit All Clamp Phases'} onClick={this.auditPhases}/>
                    <br/>
                    <div className={'detail-content'}>
                        <CollapsibleTable
                            defaultOpen={true}
                            columns={{
                                outerColumns: [
                                    {title: 'Clamp Name'},
                                    {title: 'Clamp Code'}],
                                innerColumns: [
                                    {title: 'Phase'},
                                    {title: 'Demand'},
                                    {title: 'Power Factor'}
                                ]
                            }}
                            tableData={this.state.tableData}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default SiteSettingsPhaseAudit;
