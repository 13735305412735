import React, {Component} from "react";
import "./GdModal/GdModal.scss";
import {Portal} from 'react-portal';
import {Card, CardHeader, Icon} from 'gd-react'

class SideDrawer extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.stopProp = this.stopProp.bind(this);
        this.resizeDrawer = this.resizeDrawer.bind(this);
        this.mouseUpEvent = this.mouseUpEvent.bind(this);
        this.id = Math.round(Math.random() * 1000000).toString();
    }

    resizeDrawer(e) {
        let self = this;
        self.drawerDom = self.drawerDom || document.getElementById(this.id);
        if (self.drawerDom && self.mouseDownOnDragHandle) {
            let rect = self.drawerDom.getBoundingClientRect();
            let rightOffset = (window.innerWidth - rect.right);
            let newWidth = (window.innerWidth - e.clientX - rightOffset);
            window.requestAnimationFrame(() => self.drawerDom.style.width = newWidth + "px");
        }
    }

    mouseUpEvent() {
        document.getElementsByTagName("body")[0].style.userSelect = 'auto';
        window.removeEventListener("mousemove", this.resizeDrawer);
        window.removeEventListener("mouseup", this.mouseUpEvent);
        this.mouseDownOnDragHandle = false;
    }

    stopProp(ev) {
        ev.stopPropagation();
        ev.preventDefault();
    }

    render() {


        let className = '';
        if (this.props.wide) {
            className += ' wide';
        }
        if (this.props.isMobile) {
            className += ' fullscreen';
        }
        if (this.props.almostFullScreen) {
            className += ' almostFullScreen';
        }

        return (
            <Portal node={document && document.getElementById('decorated_page')}>
                <div onMouseDown={() => {
                    console.log('Mouse down on handle');
                    document.getElementsByTagName("body")[0].style.userSelect = 'none';
                    this.mouseDownOnDragHandle = true;
                    window.addEventListener("mousemove", this.resizeDrawer);
                    window.addEventListener("mouseup", this.mouseUpEvent);
                }} className={'drag-handle'}/>
                <div className={'side-drawer ' + (this.props.className ? this.props.className : '')} id={this.id}>
                    <div className={'modal-body-wrapper drawer'}>
                        <div className={'modal-body'}>
                            <Card className={className}>
                                <div className={'row'} style={{overflow: 'hidden', width: '100%'}}>
                                    <div className={'column'} style={{width: '100%'}}>
                                        <CardHeader additionalClasses={'drawer'} title={this.props.title}>
                                            <div className={'row'}>
                                                <div className={'clickable close-butt'} onClick={this.props.onClose}>
                                                    <span>Close Window</span>
                                                    <Icon icon={'FaChevronRight'} color={'gd-grey'}/>
                                                </div>
                                            </div>
                                        </CardHeader>
                                        {this.props.children}
                                        {this.props.footer ? <div id={'drawer-footer'}
                                             className={'modal-footer drawer ' + (this.props.footer ? 'with-top-border' : '')}>{this.props.footer}</div> : null}
                                    </div>
                                    {this.props.sideContent}
                                </div>
                            </Card>

                        </div>
                    </div>
                </div>
            </Portal>
        )
    }
}

export default SideDrawer;
