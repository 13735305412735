import React from "react";
import "./DeviceHubIssueOverviewIcons.scss";
import Icon from "../Icon/Icon";
import {Tooltip} from "gd-react";
import history from "../../meta/history";

let device_types = [
    {field: "asset", label: ""}, {field: "gateway", label: "Hub"}
]
let issue_types = [
    {
        field: 'Disconnected',
        icon: 'FaUnlink',
        label: 'Disconnected',
        color: 'red'
    },
    {
        field: 'Intermittent',
        icon: 'FaUnlink',
        label: 'Intermittent connection',
        color: 'orange'
    },
    {
        field: 'LowBattery',
        icon: 'MdBattery20',
        label: 'Low battery',
        color: 'orange'
    },
    {
        field: 'LowSignal',
        icon: 'MdSignalCellular1Bar',
        label: 'Low signal',
        color: 'orange'
    },
    {
        field: 'Fault',
        icon: 'MdSignalCellular1Bar',
        label: 'Low signal',
        color: 'orange'
    },
    {
        field: 'Anomaly',
        icon: 'FaRobot',
        label: 'Anomaly',
        color: 'orange'
    }
]

class DeviceHubIssueOverviewIcons extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (<div className={"row device-hub-issue-overview-icons"}>{device_types.map(dt => {
            if (issue_types.find(it => this.props.item[dt.field + it.field])) {
                return <div className={'icon-column column'}>
                    <p className={'issue-type-name row'}>{dt.label === 'Hub' ?
                        <Tooltip label={this.props.item.gatewayName}>
                            <div style={{marginRight: '2px'}} className={'clickable'} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push(`/hub/${this.props.item.gatewayId}`)
                            }}>{dt.label}</div>
                        </Tooltip> : null} Issues</p>
                    <div className={'row'}>
                        <div className={'issue-icon-row'}>
                            {issue_types.map(it => {
                                if (this.props.item[dt.field + it.field]) {
                                    return <Tooltip label={it.label}>
                                        <div className={'icon-wrapper-issue'}><Icon size={'10'} icon={it.icon}
                                                                                    color={it.color}/>
                                        </div>
                                    </Tooltip>
                                }
                            })}
                        </div>
                    </div>
                </div>
            }
        })}</div>)
    }
}

export default DeviceHubIssueOverviewIcons;