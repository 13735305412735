import React, {Component} from 'react';
import GridDuck from 'gridduck';
import {GdAutocomplete, Dropdown} from 'gd-react';
import CreateDataType from "./CreateDataType";
import GenerateExampleUnit from "../../services/GenerateExampleUnit";
import {camelfy} from "../../services/CamelfyRequestData";
import DateFormatGenerator from "../../services/DateFormatGenerator";

class DataTypeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataType: this.props.dataType?.dataType,
            valueColumnsOptions: this.props.columnsDropDown(null, null, null, null, this.props.dataType.dataType, {
                dataOnly: true,
                noNone: true
            })
        };
        this.dataTypeCount = 0;
        ['updateValueColumnsOptions', 'getDataTypes'].forEach(f => this[f] = this[f].bind(this));
    }

    updateValueColumnsOptions() {
        let columnDropDown = this.props.columnsDropDown(null, null, null, null, this.props.dataType.dataType, {
            dataOnly: true,
            noNone: true
        });
        this.setState({
            valueColumnsOptions: columnDropDown
        })
    }

    async getDataTypes(searchTerm, reset) {
        let filters = [];
        if (this.dataTypes && this.dataTypeCount && this.dataTypes.length === this.dataTypeCount && !reset) {
            return Promise.resolve([]);
        }
        if (searchTerm) filters.push({
            field: 'search',
            value: searchTerm
        });
        let itemsRes = await camelfy((...args) => GridDuck.getDataTypes(...args), {filters: filters});
        this.dataTypeCount = itemsRes.total;
        let items = itemsRes.list
            .map((s) => {
                return {
                    id: s.id,
                    title: s.name,
                    icon: s.dataType === 'instantaneous' ? 'FaChartLine' : 'FaChartBar',
                    categoryDetails: s.categoryDetails,
                    categoryId: s.categoryId,
                    defaultRateMultiple: s.defaultRateMultiple,
                    defaultMultiple: s.defaultMultiple,
                    unitDetails: s.unitDetails,
                    rateUnitDetails: s.rateUnitDetails,
                    unitId: s.unitId,
                    applyRate: s.applyRate,
                    rateUnitId: s.rateUnitId,
                    subtitle: s.unitDetails?.name + (s.rateUnitDetails?.name ? (' / ' + s.rateUnitDetails?.name) : ''),
                    dataType: s.dataType
                }
            }).sort((a, b) => {
                if (a?.name && b?.name) {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                }
            }).sort((a, b) => {
                if (a.categoryDetails?.name && b.categoryDetails?.name) {
                    const nameA = a.categoryDetails.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.categoryDetails.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                }
            }).filter(u => searchTerm ? (u.title.toLowerCase().startsWith(searchTerm.toLowerCase())) : true)
        this.dataTypes = items;

        return Promise.resolve(items);
    }

    render() {
        let defaultUnits;
        let defaultRateUnits;
        if (this.props.dataType.dataType?.id) {
            let UnitExampleArray = GenerateExampleUnit(this.props.dataType.dataType.unitDetails, {array: true});
            if (UnitExampleArray && UnitExampleArray.length && UnitExampleArray[0].label && UnitExampleArray[0].value) {
                defaultUnits =
                    UnitExampleArray.map(dru => {
                        return {
                            id: dru.label,
                            title: dru.label,
                            value: dru.value
                        }
                    });
            }
            if (this.props.dataType.dataType?.rateUnitId) {
                let RateUnitExampleArray = GenerateExampleUnit(this.props.dataType.dataType.rateUnitDetails, {array: true});
                if (RateUnitExampleArray && RateUnitExampleArray.length && RateUnitExampleArray[0].label && RateUnitExampleArray[0].value) {
                    defaultRateUnits =
                        RateUnitExampleArray.map(dru => {
                            return {
                                id: dru.label,
                                title: dru.label,
                                value: dru.value
                            }
                        });
                }
            }
        }

        return (
            <div>
                <div className={'row'} style={{marginTop: '5px'}}>
                    <GdAutocomplete
                        async
                        icon
                        lazyload
                        groupBy={(dt) => dt.categoryDetails?.name}
                        groupIcon={(dt) => dt.categoryDetails?.icon}
                        groupIconColor={(dt) => dt.categoryDetails?.color}
                        showGroupHeading
                        error={!this.props.dataType.dataType && this.props.triedToSave}
                        getList={this.getDataTypes}
                        fixedOptions={[
                            {
                                id: 'add_new',
                                title: 'Create Data Type',
                                type: 'New',
                                icon: 'FaPlus',
                                customFunction: () => {
                                    this.setState({showDataTypeModal: true})
                                }
                            }
                        ]}
                        name={'dataType'}
                        getOptionSelected={function (option, value) {
                            if (option && value) return option.id === value.id;
                        }}
                        value={this.props.dataType.dataType}
                        label={'GridDuck Data Type'}
                        autoFocus={!this.props.dataType.dataType}
                        placeholder={'E.g. Electricity Quantity, Electricity Rate, Items Produced...'}
                        onChange={(e, val) => {
                            this.props.onChange({
                                dt: this.props.dataType,
                                label: 'dataType',
                                value: val,
                                cb: () => this.props.onChange({
                                    dt: this.props.dataType,
                                    label: 'valueColumns',
                                    value: [],
                                    cb: this.updateValueColumnsOptions
                                })
                            });
                        }}/>
                </div>

                {this.state.valueColumnsOptions && this.props.dataType.dataType?.id ? <div className={'row'} style={{marginTop: '15px'}}>
                    <GdAutocomplete filterSelectedOptions
                                    multiple
                                    value={this.props.dataType.valueColumns}
                                    name={'siteGroups'}
                                    placeholder={'Select column/s'}
                                    options={this.state.valueColumnsOptions}
                                    getOptionSelected={function (option, value) {
                                        if (option && value) return option === value || option.id === value;
                                    }}
                                    error={(this.props.dataType.errors['valueColumns']) && this.props.triedToSave}
                                    onChange={(ev, newValue) => {
                                        let newValues = newValue.map(function (s) {
                                            return s.id || s;
                                        });
                                        this.props.onChange({
                                            dt: this.props.dataType,
                                            label: 'valueColumns',
                                            value: newValues
                                        });
                                    }}
                                    label="CSV data value column/s"/>

                    {/*{this.props.columnsDropDown('Value Column', this.props.dataType.valueColumn, 'value_column', (val) => {*/}

                    {/*}, this.props.dataType.dataType)}*/}
                </div> : null}
                {this.props.dataType.valueColumns.length && this.props.dataType.dataType && this.props.dataType.dataType.dataType === 'aggregate' ?
                    <div className={'row'} style={{marginTop: '15px'}}>
                        <Dropdown label={"Each value is the..."}
                                  fixeditems={[
                                      {
                                          title: 'total since previous value',
                                          value: 'periodic'
                                      },
                                      {
                                          title: 'all time total',
                                          value: 'all_time'
                                      }
                                  ]}
                                  name={'valueTotalType'}
                                  error={this.props.dataType.errors['valueTotalType'] && this.props.triedToSave}
                                  value={this.props.dataType.valueTotalType}
                                  onChange={(val) => {
                                      this.props.onChange({
                                          dt: this.props.dataType,
                                          label: 'valueTotalType',
                                          value: val.target.value
                                      });
                                  }}/></div> : null}
                {/*multipleOfBase*/}
                {/*rateMultipleOfBase*/}
                {defaultUnits && this.props.dataType.valueColumns.length ?
                    <div className={'row'} style={{marginTop: '15px'}}>
                        <Dropdown label={"Unit of data in value columns"}
                                  fixeditems={defaultUnits}
                                  name={'multipleOfBase'}
                                  value={this.props.dataType.multipleOfBase}
                                  error={this.props.dataType.errors['multipleOfBase'] && this.props.triedToSave}
                                  onChange={(val) => {
                                      this.props.onChange({
                                          dt: this.props.dataType,
                                          label: 'multipleOfBase',
                                          value: val.target.value
                                      });
                                  }}/>
                    </div> : null}
                {defaultRateUnits && this.props.dataType.valueColumns.length ?
                    <div className={'row'} style={{marginTop: '15px'}}>
                        <Dropdown label={"Rate Unit of data in column/s"}
                                  fixeditems={defaultRateUnits}
                                  name={'rateMultipleOfBase'}
                                  error={this.props.dataType.errors['rateMultipleOfBase'] && this.props.triedToSave}
                                  value={this.props.dataType.rateMultipleOfBase}
                                  onChange={(val) => {
                                      this.props.onChange({
                                          dt: this.props.dataType,
                                          label: 'rateMultipleOfBase',
                                          value: val.target.value
                                      });
                                  }}/>
                    </div> : null}
                {this.props.dataType.valueColumns.length ?
                    <div className={'row'} style={{marginTop: '15px'}}>
                        <DateFormatGenerator columnData={this.props.columnData} dataType={this.props.dataType}
                                             onChange={this.props.onChange}
                                             triedToSave={this.props.triedToSave}
                                             columnsDropDown={this.props.columnsDropDown}/>
                    </div> : null}
                {this.state.showDataTypeModal ?
                    <CreateDataType categoryId={this.state.dataCategory?.id}
                                    onClose={(item) => {
                                        let updateObj = {
                                            showDataTypeModal: null
                                        };
                                        if (item) {

                                            this.props.onChange({
                                                dt: this.props.dataType,
                                                label: 'dataType',
                                                value: {
                                                    id: item.id,
                                                    title: item.name,
                                                    icon: item.dataType === 'instantaneous' ? 'FaChartLine' : 'FaChartBar',
                                                },
                                                cb: () => this.props.onChange({
                                                    dt: this.props.dataType,
                                                    label: 'valueColumns',
                                                    value: [],
                                                    cb: this.updateValueColumnsOptions
                                                })
                                            });
                                        }

                                        this.setState(updateObj);
                                    }}
                                    open={this.state.showDataTypeModal}/> : null}
            </div>
        );
    }
}

export default DataTypeDetails;