import React, { Component } from "react";
import '../../styles/_layout.scss';
import './Gaia.scss';
import FlexibleChart from './FlexibleChart'; // Import the FlexibleChart component
import getFormat from "../../services/formatter";

class GaiaMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.updateMessage = this.updateMessage.bind(this);
    }

    updateMessage() {
        this.setState({ updateRef: this.props.updateRef + 1 });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.message?.id !== this.props.message?.id) {
            this.props.message.on('updated', this.updateMessage);
        }
    }

    // Extract and parse chart data from the message content string
    extractFlexibleChartProps(htmlString) {
        const chartTypeRegex = /chartType="([^"]*)"/;
        const chartDataRegex = /chartData=\{([\s\S]*?)\}\s+chartOptions/;
        const chartOptionsRegex = /chartOptions=\{([\s\S]*?)\}\s*\/>/;

        // Extract chartType
        const chartTypeMatch = htmlString.match(chartTypeRegex);
        const chartType = chartTypeMatch ? chartTypeMatch[1] : null;

        // Extract chartData
        const chartDataMatch = htmlString.match(chartDataRegex);
        let chartData = chartDataMatch ? chartDataMatch[1].trim() : null;

        // Extract chartOptions
        const chartOptionsMatch = htmlString.match(chartOptionsRegex);
        let chartOptions = chartOptionsMatch ? chartOptionsMatch[1].trim() : null;

        // Convert chartData and chartOptions from string to object
        try {
            chartData = chartData ? eval(`(${chartData})`) : null;
            chartOptions = chartOptions ? eval(`(${chartOptions})`) : null;
        } catch (e) {
            console.error("Error parsing chartData or chartOptions:", e);
        }

        return {
            chartType,
            chartData,
            chartOptions
        };
    }

    renderChart(chartProps) {
        if (chartProps && chartProps.chartType && chartProps.chartData && chartProps.chartOptions) {
            const { chartType, chartData, chartOptions } = chartProps;
            return (
                <FlexibleChart
                    chartType={chartType}
                    chartData={chartData}
                    chartOptions={chartOptions}
                />
            );
        }
        return null;
    }

    render() {
        const { message } = this.props;

        // Extract chart props from the message content
        let chartProps = message.content ? this.extractFlexibleChartProps(message.content) : null;

        // Remove the <FlexibleChart /> from the string and only keep the rest of the HTML
        let sanitizedHtmlContent = message.content ? message.content.replace(/<FlexibleChart(.+?)\/>/, '') : null;

        return (
            <div className={'row ' + (message.role === 'user' ? 'reverse' : '')}>
                <div className={'message-cont ' + (message.role === 'user' ? 'user' : '')}>
                    <div className={'message ' + message.role}>
                        {/* Render the HTML content without the chart */}
                        {sanitizedHtmlContent ? (
                            <div dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }} />
                        ) : (
                            <div className="thinking-container">
                                <div className="thinking-dot"></div>
                                <div className="thinking-dot"></div>
                                <div className="thinking-dot"></div>
                            </div>
                        )}

                        {/* Render the chart if chartProps were extracted correctly */}
                        {chartProps ? this.renderChart(chartProps) : null}
                    </div>

                    {/* Display typing indicator if assistant is typing */}
                    {message.role === 'assistant' && !message.content ? (
                        <div className={'row message-timestamp ' + (message.role === 'user' ? 'reverse' : '')}>
                            gaia is typing
                        </div>
                    ) : null}

                    {/* Display timestamp */}
                    {message.createdAt ? (
                        <div className={'row message-timestamp ' + (message.role === 'user' ? 'reverse' : '')}>
                            {getFormat('lastContacted')(message.createdAt)}
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }


}

export default GaiaMessage;