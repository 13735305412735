import React from "react";
import {StatusListCell, LastActiveCell, List, StandardListCell} from "gd-react";
import GridDuck from 'gridduck';
import history from "../../meta/history";
import AddHubModal from "../../modals/AddHub/AddHubModal";
import NoItems from "../../components/noItems";
import SiteSettingsGateways from "./SiteSettingsGateways";
import GettingStarted from "../../components/GettingStarted";
import IssuePopover from "../../components/IssuePopover/IssuePopover";


class DesktopSiteSettingsUnusedGateways extends SiteSettingsGateways {
	render() {
		let permissions;
		console.log(this.props, ' : all hub props');
		if (this.props.item && this.state.orgPermission) {
			let filters;
			if (this.props.filterType === 'site_group_id' && this.props.filterId && this.props.filterId.indexOf('all') === -1) {
				filters = [
					{
						field: 'siteGroupId',
						value: this.props.filterId
					}
				]
			}
			if (this.props.filterType === 'org_partner_id') {
				filters = [
					{
						field: 'orgPartnerId',
						value: this.props.filterId
					}
				]
			}

			permissions = (
				<List
					noitemelem={<GettingStarted permission={this.state.orgPermission} closed={{one: true, two: true}}/>}
					searchable
					columns={[
						{
							component: StandardListCell,
							bold: true,
							title: 'Name',
							field: 'name'
						},
						{
							component: StatusListCell,
							title: 'Status',
							endAdornment: (object) => {
								return <IssuePopover updateDisconnectedDrawer={this.props.updateFunctions.updateDisconnectedDrawer} item={object}/>
							},
							field: 'disconnected',
						},
						{
							component: StandardListCell,
							title: 'Power source',
							field: 'powerSource',
						},
						{
							component: StandardListCell,
							title: 'Connection type',
							field: 'activeConnection',
						},
						{
							component: LastActiveCell,
							title: 'Last contacted',
							field: 'lastContacted',
						},
						{
							component: StandardListCell,
							title: 'Access level',
							field: '_permission',
						},
					]}
					getlist={this.getList}
					rowOnClick={this.goToGateway}
					filters={filters}
				/>
			)
		}
		return (
			<div className='page'>
				{permissions}
			</div>
		);
	}
}

export default DesktopSiteSettingsUnusedGateways;
