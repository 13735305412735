import React from 'react';
import GridDuck from 'gridduck';
import {DemandListCell, List, SensorDataListCell, StandardListCell, StatusListCell} from "gd-react";
import NoItems from "../../../components/noItems";
import moment from "moment-timezone";
import AuditModal from "../../../modals/AuditFile/AuditModal";
import getFormat from "../../../services/formatter";
import history from "../../../meta/history";

class AuditDevicesPage extends React.Component {
	constructor(props) {
		super(props);
		let self = this;
		this.getDevices = this.getDevices.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.openDeviceModal = this.openDeviceModal.bind(this)
		this.goToSite = this.goToSite.bind(this)
		this.state = {
			modalOpen: false,
			device: null,
			devices: this.props.item ? this.props.item.devices : null
		}


	}

	getDevices() {
		if (this.state.devices) {return this.state.devices}
		let {auditId} = this.props.match.params;
		return GridDuck.getAudit({
			id: auditId
		}).then((audit) => {
			let resList = {};
			resList.list = audit.devices;
			console.log(resList.list, ' devices list')
			this.setState({loaded: true, audit: audit,})
			return resList
		})
	}

	openDeviceModal(device) {
		let self = this;
		self.setState({modalOpen: true, device: device})
	}

	closeModal() {
		let self = this;
		self.setState({modalOpen: false, device: null})
	}

	goToSite() {
		history.push('/site/' + this.state.audit.siteId)
	}

	render() {

		let auditHeader;

		if (this.state.audit) auditHeader = (
			<div className={'audit-header'}>
				<p className={'audit-header-text'}>Information below fixed as
					of {getFormat('lastContacted')(this.state.audit.createdDate)}
				</p>
				<div
					className={'audit-site-link'}
					onClick={this.goToSite}><p className={'audit-header-text link'}>Go to current devices</p></div>
			</div>
		)

		let modal;

		if (this.state.loaded) modal = <AuditModal audit={this.state.audit}  itemType={"site"} itemId={this.state.audit.siteId} item={this.state.device} previewType={'device'} onClose={this.closeModal} open={this.state.modalOpen} />

		let permissions;

			permissions = (
				<List
					noitemelem={<NoItems text={'No Devices Audited'}/>}
					columns={[
						{
							component: StandardListCell,
							sortable: true,
							title: 'Name',
							field: 'name',
						},
						{
							component: StatusListCell,
							sortable: false,
							title: 'Status',
							field: 'disconnected',
							showLabel: true,
						},
						{
							component: SensorDataListCell,
							title: 'Data',
							field: 'temperature',
							maxWidth: 'none'
						},
					]}
					getlist={this.getDevices}
					rowOnClick={this.openDeviceModal}
					filters={[]}
				/>
			)


		return (
			<div className='page'>
				{auditHeader}
				{permissions}
				{modal}
			</div>
		)
	}
}

export default AuditDevicesPage;
