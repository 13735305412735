import React from "react";
import {StateOptions, Button, EditableList, Icon, Input} from "gd-react";
import "./CreateReport.scss";
import "../reports/Reports.scss"
import GridDuck from "gridduck";
import history from "../../meta/history";
import OptionCard from "../../components/OptionCard/OptionCard";
import CreateDataDownload from "../dataDownload/CreateDataDownload";
import Toggle from "../../components/Toggle/Toggle";
import * as _ from "underscore"
import getFormat from "../../services/formatter";
import {createPortal} from "react-dom";

class CreateReportPage extends React.Component {
    constructor(props) {
        super(props);
        console.log("report page alert", this.props);
        console.log(StateOptions, ' : StateOptions');
        this.state = {
            page: 1,
            report: {
                recipients: []
            },
            repeatOnWeekly: 1,
            repeatOnMonthly: 1,
            devices: [],
            reportPerDevice: 'true',
            allDevicesAsOnePDF: 'true',
            ddState: {
                selectionType: 'individual',
                filterLogic: 'OR',
                granularity: 60,
                customGranularity: 60,
                selectedAssets: [],
                filters: [],
                listSaved: false,
                isEditing: false
            }
        };
        this.handleNameChange = this.handleNameChange.bind(this);

        this.blockNext = this.blockNext.bind(this);

        this.getAvailableListRecipients = this.getAvailableListRecipients.bind(this);
        this.handleRecipientListChange = this.handleRecipientListChange.bind(this);
        this.selectedDevicesUpdated = this.selectedDevicesUpdated.bind(this);
        this.getCurrentListRecipients = this.getCurrentListRecipients.bind(this);

        this.save = this.save.bind(this);
        this.saved = this.saved.bind(this);

        let self = this;

        this.electricalDevices = StateOptions.filter((SO) => SO.dataTypes.find((DT) => DT.type === 'CONSUMPTION')).map((FSO) => FSO.type);
        console.log(this.electricalDevices, " : Electrical Devices");

        this.monthDays = [];
        for (let i = 1; i <= 28; i++) {
            self.monthDays.push({
                title: getFormat('ordinalSuffix')(i),
                value: i
            })
        }
        this.weekDays = [
            {
                title: 'Monday',
                value: 1
            },
            {
                title: 'Tuesday',
                value: 2
            },
            {
                title: 'Wednesday',
                value: 3
            },
            {
                title: 'Thursday',
                value: 4
            },
            {
                title: 'Friday',
                value: 5
            },
            {
                title: 'Saturday',
                value: 6
            },
            {
                title: 'Sunday',
                value: 7
            }
        ]

    }

    saved(data) {
        console.log('Saved: ', data);
        if (this.props.onSave) {
            this.props.onSave('/reports/' + this.newReportId);
        }
    }

    selectedDevicesUpdated(devices, ddState, filteredAssetList) {
        console.log(ddState, ' : ddState');
        if (ddState.filters && ddState.filters.length === 1) {
            this.state.report.name = ddState.filters[0].value;
        } else {

            if (devices.length === 1) {
                this.state.report.name = devices[0].name;
            } else if (ddState.selectionType === 'all' && devices.length > 1) {
                this.state.report.name = 'Everything';
            } else {
                this.state.report.name = '';
            }
        }
        this.setState({
            devices: devices,
            ddState: ddState,
            filteredAssetList: filteredAssetList,
            report: this.state.report
        });
    }

    handleRecipientListChange(newRecipientList) {
        let self = this;
        let report = this.state.report;
        report.recipients = newRecipientList.map(a => a.username);
        this.setState(prevState => ({
            report: report
        }));
    }

    getAvailableListRecipients(params) {
        return GridDuck.getOrganisationMembers(params);
    }

    getCurrentListRecipients(params) {
        console.log(this.state.report.recipients, ' : this.state.report.recipients');
        let self = this;
        return GridDuck.getOrganisationMembers({}).then(function (memberList) {
            let actualList = memberList.list.filter(m => self.state.report.recipients && self.state.report.recipients.indexOf(m.username) !== -1);
            return Promise.resolve({list: actualList, total: actualList.length})
        })
    }

    setCustomThrottlePeriod(val) {
        let report = this.state.report;
        report.throttleDuration = val.target.value;
        this.setState({report: report});
    }

    isDifferentList(oldList, newList) {
        return !oldList.every((value, index) => value === newList[index]);
    }

    async save() {
        let newReportProps = {
            name: this.state.report.name,
            includeMegaPdf: this.state.allDevicesAsOnePDF === 'true',
            perDevice: this.state.reportPerDevice === 'true',
            frequency: this.state.frequency,
            repeatOn: this.state.frequency === 'Weekly' ? this.state.repeatOnWeekly : this.state.repeatOnMonthly,
            recipients: this.state.report.recipients
        };

        if (this.state.ddState.selectionType === 'filtered') {
            let groupedFilters = _.groupBy(this.state.ddState.filters, 'type');
            console.log(groupedFilters, ' : grouped filters');
            newReportProps.siteIds = groupedFilters.site && groupedFilters.site.length ? groupedFilters.site.map((site) => site.id) : [];
            newReportProps.groupIds = groupedFilters.group && groupedFilters.group.length ? groupedFilters.group.map((group) => group.id) : [];
            newReportProps.siteGroupIds = groupedFilters['Site Group'] && groupedFilters['Site Group'].length ? groupedFilters['Site Group'].map((overviewGroup) => overviewGroup.id) : [];
        } else {
            newReportProps.assetIds = this.state.devices.map((device) => device.id);
        }

        let newReport = await GridDuck.createReport(newReportProps);
        console.log('Created Report')
        this.newReportId = newReport.id;
        return Promise.resolve(newReport.id);
    }

    handleNameChange(val) {
        let name = val.target.value;
        this.setState(prevState => ({
            report: {
                ...prevState.report,
                name: name
            }
        }));
    }

    blockNext() {
        switch (this.state.page) {
            case 1:
                return !this.state.devices.length;
                break;
            case 2:
                return !this.state.report.name || !this.state.report.name.length
                break;
            case 3:
                if (!this.state.frequency) {
                    return true;
                }
                break;
            case 4:
                if (!this.state.report.recipients || this.state.report.recipients.length === 0) {
                    return true;
                }
                break;
        }
        return false;
    }

    render() {
        console.log(this.state);
        let saveButton;
        let content;
        let self = this;
        if (!this.drawerFooter) {
            this.drawerFooter = document.getElementById('drawer-footer');
            console.log(this.drawerFooter, ' : drawer footer');
        }
        switch (this.state.page) {
            case 1:
                content = (
                    <div className={"details"}>
                        <div className={'details-inner'}>
                            <div className={'inner-header'}>
                                <p className={"title-row"}>
                                    Devices
                                </p>
                                <p className={"help-row"}>
                                    Select the devices you'd like to receive a report on
                                </p>
                            </div>
                            <div className={'inner-body'}>
                                <CreateDataDownload filteredAssetList={this.state.filteredAssetList}
                                                    ddState={this.state.ddState}
                                                    onUpdate={this.selectedDevicesUpdated}
                                                    inPage={'report'}/>
                            </div>
                        </div>
                    </div>)
                break;
            case 2:
                content = (<div className={"details"}>
                    <div className={'details-inner'}>
                        <div className={'inner-header'}>
                            <p className={"title-row"}>
                                Details
                            </p>
                            <p className={"help-row"}>
                                Some basic information about your report
                            </p>
                        </div>
                        <div className={'inner-body'}>
                            <div className={"name"} style={{marginBottom: '15px'}}>
                                <Input label={'Name*'} value={this.state.report.name} autoFocus
                                       onChange={this.handleNameChange}/>
                            </div>
                            {this.state.devices.length > 1 ? <div className={"name"} style={{marginBottom: '15px'}}>
                                <Toggle onClick={(val) => this.setState({reportPerDevice: val})}
                                        inactivestatename={'Send individual report per device as well as the total'}
                                        activestatename={'Send individual report per device as well as the total'}
                                        active={this.state.reportPerDevice}/>
                            </div> : null}
                            {this.state.reportPerDevice === 'true' ?
                                <div className={"name"} style={{marginBottom: '15px'}}>
                                    <Toggle onClick={(val) => this.setState({allDevicesAsOnePDF: val})}
                                            inactivestatename={'Bundle all device reports into one PDF'}
                                            activestatename={'Bundle all device reports into one PDF'}
                                            active={this.state.allDevicesAsOnePDF}/>
                                </div> : null}

                        </div>
                    </div>
                </div>)
                break;
            case 3:
                content = (
                    <div className={"details"}>
                        <div className={'details-inner'}>
                            <div className={'inner-header'}>
                                <p className={"title-row"}>
                                    Frequency
                                </p>
                                <p className={"help-row"}>
                                    Choose how often you'd like to receive the report
                                </p>
                            </div>
                            <div className={'inner-body'}>
                                <div className={'row'}>
                                    <OptionCard onClick={() => this.setState({frequency: 'Weekly'})}
                                                selected={this.state.frequency === 'Weekly'}
                                                description={this.state.frequency === 'Weekly' ?
                                                    <div className={'frequency-day'}>Every Monday
                                                        {/*<Dropdown*/}
                                                        {/*name={'frequency'}*/}
                                                        {/*disabled*/}
                                                        {/*value={this.state.repeatOnWeekly}*/}
                                                        {/*onChange={(e) => {*/}
                                                        {/*    this.setState({repeatOnWeekly: e.target.value})*/}
                                                        {/*}}*/}
                                                        {/*fixeditems={this.weekDays}/>*/}
                                                    </div> : ''}
                                                color={'gd-grey'} icon={'FaCalendarDay'} name={'Weekly'}/>
                                </div>
                                <div className={'row'}>
                                    <OptionCard onClick={() => this.setState({frequency: 'Monthly'})}
                                                selected={this.state.frequency === 'Monthly'}
                                                description={this.state.frequency === 'Monthly' ?
                                                    <div className={'frequency-day'}>Every 1st
                                                        {/*<Dropdown*/}
                                                        {/*name={'frequency'}*/}
                                                        {/*disabled*/}
                                                        {/*value={this.state.repeatOnMonthly}*/}
                                                        {/*onChange={(e) => {*/}
                                                        {/*    this.setState({repeatOnMonthly: e.target.value})*/}
                                                        {/*}}*/}
                                                        {/*fixeditems={this.monthDays}/>*/}
                                                    </div> : ''}
                                                color={'gd-grey'} icon={'FaCalendarWeek'} name={'Monthly'}/>
                                </div>
                            </div>
                        </div>
                    </div>)
                break;
            case 4:
                content = (
                    <div className={"details"}>
                        <div className={'details-inner'}>
                            <div className={'inner-header'}>
                                <p className={"title-row"}>
                                    Recipients
                                </p>
                                <p className={"help-row"}>
                                    Who this report will be sent to
                                </p>
                            </div>
                            <div className={'inner-body'}>
                                <EditableList
                                    isEditing={true}
                                    noCancel={true}
                                    key={2}
                                    getActiveList={this.getCurrentListRecipients}
                                    getAvailableList={this.getAvailableListRecipients}
                                    save={this.save}
                                    noSave={true}
                                    onChange={this.handleRecipientListChange}/>
                            </div>
                        </div>
                    </div>);
        }

        return (
            <div className={"inner-rule padded"}>
                {content}
                {this.drawerFooter ? createPortal(<div
                    style={{display: 'flex', flexDirection: 'row-reverse', flex: '1 1'}}>
                    {this.state.page === 1 ? <span/> : ''}
                    {this.state.page === 4 ?
                        <Button color={'gd-green'} additionalclasses={'sm'} label={"Save"}
                                disabled={this.blockNext()}
                                progressRes={true} onClick={this.save} onComplete={this.saved}/> : ''}
                    {this.state.page !== 4 ? <Button disabled={this.blockNext()} label={"Next"}
                                                     onClick={() => this.setState({page: this.state.page + 1})}/> : ''}
                    {this.state.page !== 1 ? <div style={{marginRight: '10px'}}><Button label={"Back"} color={'gd-grey'}
                                                                                        onClick={() => this.setState({page: this.state.page - 1})}/>
                    </div> : ''}


                </div>, this.drawerFooter) : null}
                {/*<Prompt*/}
                {/*    when={this.hasChanged()}*/}
                {/*    message="You have unsaved changes. Do you definitely want to leave?"*/}
                {/*/>*/}
            </div>)
    }
}

export default CreateReportPage;