import React from "react";
import {Icon} from "gd-react";
import './ContextBar.scss'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import {Navigation} from "swiper";
import DisconnectedDrawer from "./Disconnected/DisconnectedDrawer";

class ContextBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDisconnectedDrawer: false
        }
    }

    render() {
        return [(
            <Swiper spaceBetween={0}
                    slidesPerView={'auto'}
                    navigation={true} modules={[Navigation]}
                    // id={'ContextBar'}
                    className={'context-bar row'}>
                {this.props.contextItems.map((ci, index) => <SwiperSlide key={ci.title + index}
                                                                style={{width: 'auto'}}>
                    <div className={'context-item clickable'} onClick={() => this.setState({openDisconnectedDrawer: true})}>
                        <div className={'row'} style={{alignItems: 'center', lineHeight: '35px'}}>
                            <div className={'context-icon'}><Icon icon={ci.icon} color={ci.color || 'inherit'}
                                                                  size={'10'}/></div>
                            <p><span>{ci.count}</span> {ci.title}</p>
                        </div>
                    </div>
                </SwiperSlide>)}
            </Swiper>
        ), (this.state.openDisconnectedDrawer &&
                <DisconnectedDrawer
                    open={this.state.openDisconnectedDrawer}
                    filterByItem={this.props.filterByItem}
                    account={this.user}
                    issuesCount={this.state.incidentCount}
                    ignoredDevicesCount={this.state.ignoredDevicesCount}
                    openOnTab={this.state.openDisconnectedOnTab}
                    onClose={() => this.setState({openDisconnectedDrawer: false})}/>)]
    }
}

export default ContextBar;
