import React from "react";
import {Button, Dropdown, EditableList, GdCheckbox, Input, StandardListCell, StateOptions} from "gd-react";
import Modal from "react-modal";
import GridDuck from "gridduck";
import Toggle from "../Toggle/Toggle";

class DataCondition extends React.Component {
    constructor(props) {
        super(props);
        this.selectAssets = this.selectAssets.bind(this);
        this.closeAssetsModal = this.closeAssetsModal.bind(this);
        this.getActiveList = this.getActiveList.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.setAssets = this.setAssets.bind(this);
        this.updateViaProps = this.updateViaProps.bind(this);
        this.selectThresholdAssets = this.selectThresholdAssets.bind(this);
        this.setThresholdAssets = this.setThresholdAssets.bind(this);
        this.getActiveThresholdList = this.getActiveThresholdList.bind(this);

        this.updateState = this.updateState.bind(this);

        this.state = this.updateState();
    }

    updateState() {
        return {
            thresholdAssetNames: this.props.action.thresholdAssetNames ? this.props.action.thresholdAssetNames : 'Select threshold devices',
            thresholdAssets: this.props.action.thresholdAssets ? this.props.action.thresholdAssets : [],
            thresholdAssetMaths: this.props.action.thresholdAssetMaths,
            assetNames: this.props.action.assetNames ? this.props.action.assetNames : 'Select devices',
            assets: this.props.action.assets ? this.props.action.assets : [],
            dataType: this.props.action.dataType ? this.props.action.dataType : 'CONSUMPTION',
            assetLogic: this.props.action.assetLogic ? this.props.action.assetLogic : 'some',
            customMaths: this.props.action.customMaths,
            comparator: this.props.action.thresholdType,
            isSymmetric: this.props.action.isSymmetric ? this.props.action.isSymmetric : false,
            dynamicThreshold: this.props.action.dynamicThreshold,
            thresholdDataType: this.props.action.thresholdDataType,
            threshold: this.props.action.threshold,
            thresholdExit: this.props.action.thresholdExit,
            persistent: !this.props.action.instantaneous,
            name: this.props.action.name ? this.props.action.name : null,
            inactiveAction: this.props.action.inactiveAction ? this.props.action.inactiveAction : -1,
            activeAction: this.props.action.activeAction ? this.props.action.activeAction : -1,
            nullAction: this.props.action.nullAction ? this.props.action.nullAction : -1,
            throttleDuration: this.props.action.throttleDuration,
            timeType: this.props.action.timeType,
            startTime: this.props.action.startTime,
            timePeriod: this.props.action.timePeriod,
            customTimePeriod: this.props.action.customTimePeriod
        };
    }

    updateViaProps() {
        let dataCondition = {
            name: this.state.name,
            instantaneous: !this.state.persistent,
            dataType: this.state.dataType,
            threshold: this.state.threshold,
            thresholdType: this.state.comparator,
            thresholdExit: this.state.thresholdExit,
            assetLogic: this.state.assetLogic,
            formula: this.state.customMaths,
            isSymmetric: this.state.isSymmetric,
            assets: this.state.assets,
            assetNames: this.state.assetNames,
            timeType: this.state.timeType,
            startTime: this.state.startTime,
            dynamicThreshold: this.state.dynamicThreshold,
            thresholdDataType: this.state.thresholdDataType,
            thresholdAssetNames: this.state.thresholdAssetNames,
            thresholdAssets: this.state.thresholdAssets,
            inactiveAction: this.state.inactiveAction,
            nullAction: this.state.nullAction,
            activeAction: this.state.activeAction,
            thresholdAssetMaths: this.state.thresholdAssetMaths,
            color: this.props.action.color,
            id: this.props.action.id,
            creating: this.props.action.creating,
            throttleDuration: this.state.throttleDuration,
            timePeriod: this.state.timePeriod,
            customTimePeriod: this.state.customTimePeriod
        };
        if (this.props.onChange) {
            this.props.onChange(dataCondition);
        }
    }


    selectThresholdAssets() {
        this.setState({selectingThresholdAssets: true});
    }

    selectAssets() {
        this.setState({selectingAssets: true});
    }

    closeAssetsModal() {
        this.setState({selectingAssets: false, selectingThresholdAssets: false});
    }

    getAvailableList(params) {
        return GridDuck.getAssets(params)
    }

    getActiveList(props) {
        let promises = this.state.assets.map(a => GridDuck.getAsset({id: a}))
        return Promise.all(promises).then(function (results) {
            return Promise.resolve({list: results, total: results.length});
        })
    }

    getActiveThresholdList(props) {
        let promises = this.state.thresholdAssets.map(a => GridDuck.getAsset({id: a}))
        return Promise.all(promises).then(function (results) {
            return Promise.resolve({list: results, total: results.length});
        });
    }

    setAssets(assets) {
        this.setState({
            assets: assets.map(a => a.id),
            assetNames: assets.map(a => a.name + ' (' + a.siteName + ')').join(", ")
        }, this.updateViaProps);
        return Promise.resolve();
    }

    setThresholdAssets(assets) {
        this.setState({
            thresholdAssets: assets.map(a => a.id),
            thresholdAssetNames: assets.map(a => a.name + ' (' + a.siteName + ')').join(", ")
        }, this.updateViaProps);
        return Promise.resolve();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.action.id !== prevProps.action.id) {
            this.setState(this.updateState());
        }
    }

    render() {
        let self = this;
        let factor = 1;
        if (this.state.dataType === 'TEMPERATURE' || this.state.dataType === 'HUMIDITY') {
            factor = 100;
        }
        if (this.state.dataType === 'CURRENT') {
            factor = 1000;
        }

        let allDataTypes = [];
        let dataOptions = [];
        StateOptions.forEach(function (stateOption) {
            stateOption.dataTypes
                //No more instantaneous only rules for persistent behaviour
                // .filter(dT => self.state.persistent ? dT.graphType === 'line' : true)
                .forEach(function (dataType) {
                    if (!allDataTypes.find(aDT => aDT.value === dataType.type)) {
                        allDataTypes.push(dataType);
                    }
                    if (!dataOptions.find(dO => dO.value === dataType.type)) {
                        let dataOption = {
                            value: dataType.type,
                            title: (!self.state.persistent && dataType.graphType === 'line' ? ' Average ' : '') + dataType.label + "(" + dataType.unit + ")",
                        };
                        dataOptions.push(dataOption);
                    }
                });
        });
        if (self.state.persistent) {
            dataOptions.push({
                value: "duration_since",
                title: "Seconds Since Timestamp",
            })
        }
        let isAggregateDataType;
        let chosenDataType = allDataTypes.find(aDT => aDT.type === self.state.dataType);
        if (chosenDataType) {
            isAggregateDataType = chosenDataType.graphType === 'bar';
        }
        console.log("chosen data type", chosenDataType, isAggregateDataType);
        let assetLogicOptions = [
            {
                value: 'some',
                title: 'Some',
            },
            {
                value: 'all',
                title: 'All',
            },
            {
                value: 'maths',
                title: 'Custom Maths',
            },
        ]
        let thresholdTypes = [
            {
                value: 'above',
                title: 'Above',
            },
            {
                value: 'below',
                title: 'Below',
            },
        ];
        let timeTypeOptions = [
            {
                value: 'continuous',
                title: 'Continuous',
            },
            {
                value: 'calendar',
                title: 'Calendar discrete',
            }
        ]
        if (isAggregateDataType) {
            timeTypeOptions = [
                {
                    value: 'since_timestamp',
                    title: 'Since Timestamp'
                },
                {
                    value: 'previous_interval',
                    title: 'In Previous Interval'
                }
            ];
        }
        let definedTimeOptions;
        if (this.state.timeType === 'discrete') {
            definedTimeOptions = [
                {
                    value: 'year',
                    title: 'Year',
                },
                {
                    value: 'month',
                    title: 'Month',
                },
                {
                    value: 'week',
                    title: 'Week',
                },
                {
                    value: 'day',
                    title: 'Day',
                },
                {
                    value: 'hour',
                    title: 'Hour',
                },
                {
                    value: 'minute',
                    title: 'Minute',
                },
            ]
        } else {
            definedTimeOptions = [
                {
                    value: 'custom',
                    title: 'Custom',
                },
                {
                    value: 86400,
                    title: '24 hours',
                },
                {
                    value: 3600,
                    title: 'Hour',
                },
                {
                    value: 60,
                    title: 'Minute',
                },
            ]
        }

        let timeType;
        let periodStartDefined;
        //discreteTime: true/false to timeType: 'continuous','discrete','since_timestamp'
        if (!this.state.persistent || (this.state.persistent && isAggregateDataType)) {
            timeType = (<Dropdown label={'Time period type'}
                                  onChange={(e) => this.setState({timeType: e.target.value}, this.updateViaProps)}
                                  fixeditems={timeTypeOptions}
                                  value={this.state.timeType}/>);
            if (this.state.timeType !== 'since_timestamp') {
                periodStartDefined = (
                    <Dropdown label={'Time period'}
                              onChange={(e) => this.setState({timePeriod: e.target.value}, this.updateViaProps)}
                              fixeditems={definedTimeOptions} value={this.state.timePeriod}/>);
            } else {
                periodStartDefined = (
                    <Input label={'Timestamp start (unix timestamp)'} value={this.state.startTime}
                           onChange={(e) => this.setState({startTime: parseInt(e.target.value)}, this.updateViaProps)}/>);
            }
        }
        if (self.state.dataType === 'duration_since') {
            periodStartDefined = (
                <Input label={'Timestamp start (unix timestamp)'} value={this.state.startTime}
                       onChange={(e) => this.setState({startTime: parseInt(e.target.value)}, this.updateViaProps)}/>);
        }


        let customPeriod;
        if (this.state.timePeriod === 'custom') {
            customPeriod = (
                <Input label={'Custom time period (s)'} value={this.state.customTimePeriod}
                       onChange={(e) => this.setState({customTimePeriod: e.target.value}, this.updateViaProps)}/>
            );
        }

        let throttleDuration;
        if (this.state.timeType === 'continuous' && !this.state.persistent) {
            let throttleDurationOptions = [
                {
                    value: 'custom',
                    title: 'Custom',
                },
                {
                    value: 86400,
                    title: 'Every 24 hours',
                },
                {
                    value: 3600,
                    title: 'Every Hour',
                },
                {
                    value: 60,
                    title: 'Every Minute',
                },
            ]
            throttleDuration =
                [(<div className={'condition-row'}>
                    <Dropdown label={'Throttle period'} fixeditems={throttleDurationOptions}
                              onChange={(e) => this.setState({throttleDuration: e.target.value}, this.updateViaProps)}
                              value={this.state.throttleDuration}/>
                </div>)];
            if (this.state.throttleDuration === 'custom') {
                throttleDuration.push(
                    <div className={'condition-row'}>
                        <Input label={'Custom throttle period (seconds)'} value={this.state.customThrottleDuration}
                               onChange={(e) => this.setState({customThrottleDuration: e.target.value}, this.updateViaProps)}/>
                    </div>
                )
            }
        }


        return (
            <div className={'details-inner'}>
                <Modal
                    isOpen={this.state.selectingAssets || this.state.selectingThresholdAssets}
                    onRequestClose={this.closeAssetsModal}
                    contentLabel="Select Devices">
                    <EditableList
                        isEditing={true}
                        key={1}
                        noCancel={false}
                        onCancelOnlyCallback={this.closeAssetsModal}
                        editingColumns={[
                            {component: StandardListCell, title: 'Site Name', field: 'siteName'},
                        ]}
                        availableFilters={[{
                            field: 'dataType',
                            value: this.state.selectingThresholdAssets ? this.state.thresholdDataType : this.state.dataType
                        }]}
                        getActiveList={this.state.selectingThresholdAssets ? this.getActiveThresholdList : this.getActiveList}
                        getAvailableList={this.getAvailableList}
                        save={this.state.selectingThresholdAssets ? this.setThresholdAssets : this.setAssets}
                    />
                </Modal>
                {!this.props.noName ?
                    (<div className={'condition-row'}>
                        <Input label={'Name'}
                               value={this.state.name}
                               onChange={function (e) {
                                   self.setState({name: e.target.value}, self.updateViaProps);
                               }}/>
                    </div>) : ''
                }
                <div className={'condition-row'}>
                    <Toggle
                        onClick={(val) => this.setState({persistent: val === 'true'}, self.updateViaProps)}
                        inactivestatename={'Event triggering behaviour'}
                        activestatename={'Persistent state behaviour'}
                        active={this.state.persistent ? 'true' : 'false'}
                    />
                </div>
                <div className={'condition-row'}>
                    <Dropdown label={'Data type'}
                              onChange={(e) => this.setState({dataType: e.target.value}, self.updateViaProps)}
                              value={this.state.dataType}
                              fixeditems={dataOptions}/>
                </div>
                {this.state.dataType !== 'duration_since' ? (
                    <div className={'condition-row assets'}>
                        <Button label={this.state.assetNames} additionalclasses={'fake-input'} nonBold={true}
                                color={"gd-grey"} disabled={!this.state.dataType}
                                outline
                                onClick={this.selectAssets}/>
                    </div>) : ''}
                {this.state.assets.length > 1 ? (
                    <div className={'condition-row'}>
                        <Dropdown label={'Asset Combination Logic'}
                                  onChange={(e) => this.setState({assetLogic: e.target.value}, self.updateViaProps)}
                                  fixeditems={assetLogicOptions}
                                  value={this.state.assetLogic}/>
                    </div>) : null}
                {this.state.assets.length > 1 && this.state.assetLogic === 'maths' ? (
                    <Input label={'Custom maths'} placeholder={"D1 + D2 - D3"}
                           value={this.state.customMaths}
                           onChange={function (e) {
                               self.setState({customMaths: e.target.value}, self.updateViaProps);
                           }}/>
                ) : null}
                <div className={'condition-row'}>
                    <Dropdown label={'Comparator'}
                              onChange={(e) => this.setState({comparator: e.target.value}, self.updateViaProps)}
                              fixeditems={thresholdTypes}
                              value={this.state.comparator}/>
                </div>
                {this.state.dataType !== 'duration_since' ? (
                    <Toggle
                        onClick={(val) => this.setState({dynamicThreshold: val === 'true'}, self.updateViaProps)}
                        inactivestatename={'Static threshold'}
                        activestatename={'Dynamic threshold'}
                        active={this.state.dynamicThreshold ? 'true' : 'false'}
                    />) : ''}
                {this.state.dynamicThreshold && this.state.dataType !== 'duration_since' ? [
                    (<div className={'condition-row'}>
                        <Dropdown label={'Threshold data type'}
                                  onChange={(e) => this.setState({thresholdDataType: e.target.value}, self.updateViaProps)}
                                  value={this.state.thresholdDataType}
                                  fixeditems={dataOptions}/>
                    </div>),
                    (<div className={'condition-row assets'}>
                        <Button label={this.state.thresholdAssetNames} additionalclasses={'fake-input'} nonBold={true}
                                color={"gd-grey"} disabled={!this.state.thresholdDataType}
                                onClick={this.selectThresholdAssets}/>
                    </div>),
                    (this.state.thresholdAssets.length > 1 ? (
                        <div className={'condition-row'}>
                            <Input label={'Threshold asset maths'} placeholder={"D1 + D2 - D3"}
                                   value={this.state.thresholdAssetMaths}
                                   onChange={function (e) {
                                       self.setState({thresholdAssetMaths: e.target.value}, self.updateViaProps);
                                   }}/>
                        </div>
                    ) : null)
                ] : (<div className={'condition-row'}>
                    <Input label={'Threshold'} placeholder={"Threshold"}
                           type={"number"}
                           value={this.state.threshold / factor}
                           onChange={function (e) {
                               if (isNaN(e.target.value)) {
                                   return;
                               }
                               let factor = 1;
                               if (self.state.dataType === 'TEMPERATURE' || self.state.dataType === 'HUMIDITY') {
                                   factor = 100;
                               }
                               if (self.state.dataType === 'CURRENT' || self.state.dataType === 'HUMIDITY') {
                                   factor = 1000;
                               }
                               self.setState({threshold: parseInt(e.target.value * factor)}, self.updateViaProps);
                           }}/>
                </div>)}
                {this.state.persistent && !this.state.dynamicThreshold && this.state.dataType !== 'duration_since' ? [
                    (<div className={'condition-row'}>
                        <Toggle
                            onClick={(val) => this.setState({isSymmetric: val === 'true'}, self.updateViaProps)}
                            inactivestatename={'Exit on different threshold'}
                            activestatename={'Exit on the same threshold'}
                            active={this.state.isSymmetric ? 'true' : 'false'}
                        />
                    </div>),
                    (!this.state.isSymmetric) ?
                        <div className={'condition-row'}>
                            <Input label={'Exit threshold'} placeholder={"Threshold"}
                                   type={"number"}
                                   value={this.state.thresholdExit / factor}
                                   onChange={function (e) {
                                       if (isNaN(e.target.value)) {
                                           return;
                                       }
                                       let factor = 1;
                                       if (self.state.dataType === 'TEMPERATURE' || self.state.dataType === 'HUMIDITY') {
                                           factor = 100;
                                       }
                                       self.setState({thresholdExit: parseInt(e.target.value * factor)}, self.updateViaProps);
                                   }}/>
                        </div>
                        : ''
                ] : ''}
                {(!this.state.persistent || (this.state.persistent && isAggregateDataType)) ?
                    [
                        (<div className={'condition-row'}>
                            {timeType}
                        </div>),
                        (<div className={'condition-row'}>
                            {periodStartDefined}
                        </div>),
                        customPeriod ? (<div className={'condition-row'}>
                            {customPeriod}
                        </div>) : null
                    ]
                    : self.state.dataType === 'duration_since' ? (<div className={'condition-row'}>
                        {periodStartDefined}
                    </div>) : ''}
                {throttleDuration}
                <div className={'condition-row'}>
                    <Dropdown
                        label={(!this.state.persistent ? 'On-triggered' : 'Active') + ' action'}
                        onChange={(e) => self.setState({activeAction: e.target.value}, self.updateViaProps)}
                        value={this.state.activeAction}
                        fixeditems={this.props.actions.map((a, id) => ({value: a.id, title: a.name}))}/>
                </div>
                {this.state.persistent ? (<div className={'condition-row'}>
                    <Dropdown
                        label={'Inactive action'}
                        onChange={(e) => self.setState({inactiveAction: e.target.value}, self.updateViaProps)}
                        value={this.state.inactiveAction}
                        fixeditems={this.props.actions.map((a, id) => ({value: a.id, title: a.name}))}/>
                </div>) : ''}
                {this.state.persistent ? (<div className={'condition-row'}>
                    <Dropdown
                        label={'No-data action'}
                        onChange={(e) => self.setState({nullAction: e.target.value}, self.updateViaProps)}
                        value={this.state.nullAction}
                        fixeditems={this.props.actions.map((a, id) => ({value: a.id, title: a.name}))}/>
                </div>) : ''}
            </div>);
    }
}

export default DataCondition;
