import React from "react";
import OutstandingAlertsPage from "./OutstandingAlertsPage";
import TabbedPage from "../../components/TabbedPage/TabbedPage";
import IgnoredAlertsPage from "./IgnoredAlertsPage";
import "./AlertsPage.scss";
class MainAlertsPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let tabs = [
			{
				default: true,
				url: null,
				title: 'Outstanding',
				component: OutstandingAlertsPage,
			},
			{
				url: 'ignored',
				title: 'Ignored',
				component: IgnoredAlertsPage,
			}
		]

		return (
			<TabbedPage dataLoaded={true} className={"alerts-page"} tabs={tabs} {...this.props}/>
		)
	}
}

export default MainAlertsPage
