import React from "react";
import {StatusListCell, LastActiveCell, List, StandardListCell} from "gd-react";
import GridDuck from 'gridduck';
import history from "../../meta/history";
import AddHubModal from "../../modals/AddHub/AddHubModal";
import NoItems from "../../components/noItems";
import SiteSettingsGateways from "./SiteSettingsGateways";
import GettingStarted from "../../components/GettingStarted";
import GenericLoader from "../../components/GenericLoader";
import IssuePopover from "../../components/IssuePopover/IssuePopover";


class DesktopSiteSettingsGateways extends SiteSettingsGateways {
	render() {
		let permissions;
		if (this.props.item && this.state.orgPermission) {
			permissions = (
				<List
					searchable
					noitemelem={<GettingStarted permission={this.state.orgPermission} closed={{one: true, two: true}}/>}
					columns={[
						{
							component: StandardListCell,
							bold: true,
							title: 'Name',
							field: 'name',
							sortable: true
						},
						{
							component: StatusListCell,
							endAdornment: (object) => {
								return <IssuePopover updateDisconnectedDrawer={this.props.updateFunctions.updateDisconnectedDrawer} item={object}/>
							},
							title: 'Status',
							field: 'disconnected',
						},
						{
							component: StandardListCell,
							title: 'Power source',
							field: 'powerSource',
						},
						{
							component: StandardListCell,
							title: 'Connection type',
							field: 'activeConnection',
						},
						{
							component: LastActiveCell,
							title: 'Last contacted',
							field: 'lastContacted',
						},
						{
							component: StandardListCell,
							title: 'Access level',
							field: '_permission',
						},
					]}
					getlist={this.getList}
					rowOnClick={this.goToGateway}
					filters={[
						{
							field: 'siteId',
							value: this.props.item.id
						},{
							field: 'locationId',
							value: this.props.item.locationId
						}
					]}
				/>
			)
		}
		return (
			<div className='page'>
				{this.props.loaded ? permissions : <GenericLoader/>}
			</div>
		);
	}
}

export default DesktopSiteSettingsGateways;