import React from "react";
import GridDuck from "gridduck";
import {Button, CardBody, Input} from "gd-react";
import {Link} from "react-router-dom";
import {Icon} from "@material-ui/core";
import animatedLogo from "../images/animated-logo-once.svg";
import PasswordChecker from "../components/PasswordChecker/PasswordChecker";
import history from "../meta/history";
import PasswordReset from "./PasswordReset";

class MobilePasswordReset extends PasswordReset {
    render() {
        let checking;
        let result;
        if (!this.state.checked) {
            checking = (<p>Checking your token</p>);
        } else {
            if (this.state.validated && !this.state.resetComplete) {
                result = (
                    <div style={{width: '80%'}}>
                        {/*<Input label={'New password'} value={this.state.password} onChange={this.handlePasswordChange}/>*/}
                        {/*       onChange={this.handleConfirmPasswordChange}/>*/}
                        <PasswordChecker onChange={this.handlePasswordChange}/>
                        <div style={{
                            'display': 'flex',
                            'flexDirection': 'row-reverse',
                            marginTop: '30px',
                            justifyContent: 'center'
                        }}>
                            <Button
                                label="Reset password"
                                progressRes
                                disabled={!this.state.passed}
                                onClick={this.reset}
                            />
                        </div>
                    </div>

                );
            } else if (this.state.checked && !this.state.validated && !this.state.resetComplete) {
                result = (<div> <p className={'subHeader'}>Whoops!</p>
                    <p className={'header'}>Invalid token</p>
                </div>);
            }else if (this.state.checked && this.state.validated && this.state.resetComplete) {
                result = (<div> <p className={'subHeader'}>Success</p>
                    <p className={'header'}>Your password has been reset</p>
                </div>);
            }
        }
        // <div className={"password-reset"}>
        //     Reset your password? {this.token}
        //     {checking}
        //     {result}
        // </div>
        return (
            <div className={'mobileLoginPage'}>
                <Link to="/" style={{
                    'position': 'absolute',
                    padding: '20px 30px',
                    fontSize: '13px',
                    color: 'grey',
                    textDecoration: 'none'
                }}><Icon/>Back to login</Link>
                {!this.state.resetPasswordSent ? <div style={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'flex': 50,
                    'justifyContent': 'center',
                    'alignItems': 'center'
                }}>
                    {result}

                </div> : <div style={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'flex': 50,
                    'justifyContent': 'center',
                    'alignItems': 'center'
                }}>
                    <p className={'subHeader'}>Request Sent!</p>
                    <p className={'header'}>Check your email for a link to reset your password</p>
                </div>}
            </div>
        )
    }
}

export default MobilePasswordReset;
