import React from 'react';
import './DesktopLogin.scss';
import {Link} from "react-router-dom";
import {Input, Button} from "gd-react";
import GridDuck from "gridduck";
import animatedLogo from "../images/animated-logo-once.svg";
import {Icon} from "@material-ui/core";
import history from "../meta/history";
import Forgot from "./Forgot";

class DesktopForgot extends Forgot {

	render() {
		let logo = require('../images/' + window._loginLogo);
		if (window._companyName !== 'GridDuck') {
			logo = logo.default;
		}
		return (
			<div className={'loginPage'}>
				<Link to="/" style={{
					'position': 'absolute',
					padding: '20px 30px',
					fontSize: '13px',
					color: 'grey',
					textDecoration: 'none'
				}}><Icon/>Back to login</Link>
				{!this.state.resetPasswordSent ? <div style={{
					'display': 'flex',
					'flexDirection': 'column',
					'flex': 50,
					'justifyContent': 'center',
					'alignItems': 'center'
				}}>
					{/*<p className={'header'}>Enter the email address of the account you want resetting</p>*/}
					<p className={'subHeader'}>Forgot Password</p>
					<div style={{width: '400px'}}>
						<Input disabled={this.state.submitting} label={"Email"} required onChange={this.changeEmail}
							   placeholder={'example@gridduck.com'}
							   error={this.state.triedToSubmit && !this.state.emailAddress.length}
							   autoFocus onKeyPress={(event) => {
							if (event.key === 'Enter') this.submit();
						}} value={this.state.emailAddress}/>
						<div style={{
							'display': 'flex',
							'flexDirection': 'row-reverse',
							marginTop: '30px',
							justifyContent: 'center'
						}}>
							<Button progressRes progressState={this.state.progressState} label={"Submit"}
									onClick={this.submit}/>
						</div>
						<p className={'error-text'}>{this.state.loginError}</p>
					</div>

				</div> : <div style={{
					'display': 'flex',
					'flexDirection': 'column',
					'flex': 50,
					'justifyContent': 'center',
					'alignItems': 'center'
				}}>
					<p className={'subHeader'}>Request Sent!</p>
					<p className={'header'}>Check your email for a link to reset your password</p>
				</div>}
				<div style={{
					'display': 'flex',
					'flexDirection': 'column',
					'overflow': 'hidden',
					'flex': 50,
					justifyContent: 'center',
					alignItems: 'center',
					'backgroundColor': window._loginBgCol
				}}>
					<img alt={'img'} src={logo} className={'animated-logo'}/>
					<css-doodle grid='20'>
						{':doodle { grid-gap: 5px; width: 50vw; height: 100vh; position: relative; } border-radius: 100%; border: 0px solid rgb(44 71 82); background: ' + window._loginDotsCol + '; --scaledelay: calc(( (@abs(@abs(@row - @size-row/3) + @abs(@col - @size-col/4) - @size-col) / @size-col) - 1) * -4s) ; animation-name: scale; animation-duration: 5s; animation-iteration-count: infinite; animation-timing-function: ease; animation-delay: var(--scaledelay); transform: scale(0.2); opacity: 0.2; @keyframes scale { 0%{ transform: scale(0.2); opacity: 0.2; } 50%{ transform: scale(0.8); opacity: 0.7; } 100%{ transform: scale(0.2); opacity: 0.1 } }'}
					</css-doodle>
				</div>
			</div>
			// <div>
			// 	Forgot Password<br/>


			// </div>
		);
	}
}

export default DesktopForgot;
