import React from "react";
import {Card, CardBody, Icon} from "gd-react";
import "./RuleCard.scss";
import TimeAgo from 'react-timeago'

class RuleCard extends React.Component {
    constructor(props) {
        super(props);
        this.ruleUpdatedExternally = this.ruleUpdatedExternally.bind(this);
        this.state = {
            ref: 0
        };
        if (this.props.rule && this.props.rule.on) {
            this.props.rule.on('updated', this.ruleUpdatedExternally);
        }
        console.log(props.rule, ' : rule');
    }

    componentWillUnmount() {
        if (this.props.rule && this.props.rule.off) {
            this.props.rule.off('updated', this.ruleUpdatedExternally);
        }
    }


    ruleUpdatedExternally() {
        this.setState({ref: this.state.ref + 1});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps && prevProps.rule.id !== this.props.rule.id) {
            if (prevProps.rule && prevProps.rule.off) {
                prevProps.rule.off('updated', this.ruleUpdatedExternally);
            }
        }
        if (this.props.rule && this.props.rule.id !== prevProps.rule.id && this.props.rule && this.props.rule.on) {
            this.props.rule.on('updated', this.ruleUpdatedExternally);
        }
    }

    render() {

        let icon;
        let color;
        switch (this.props.rule.template) {
            case 'timeswitch':
                icon = 'FaClock';
                color = 'gd-blue';
                break;
            case 'thermostat':
                icon = 'FaThermometerThreeQuarters';
                color = 'gd-red';
                break;
            case 'alert':
                icon = 'FaBell';
                color = 'orange';
                break;
            case 'balancing':
                icon = 'FaBalanceScale';
                color = 'gd-blue';
                break;
            default:
                icon = 'GiMaterialsScience';
                color = 'gd-grey';
        }

        return (
            <div className={'rule-card-wrapper'}>
                <Card className={"rule-card"} onClick={this.props.onClick}>
                    <CardBody>
                        <div className={"card-icon"}>
                            <Icon size={'35'} color={color} icon={icon}/>
                        </div>
                        <div className={"card-content"}>
                            <h4>{this.props.rule.name}</h4>
                            <p>{this.props.rule.description}</p>
                            {this.props.rule.persistent ?
                                <div className={'rule-active-status'}>
                                    <Icon name={'FaCircle'} color={this.props.rule.isActive ? 'gd-green' : 'gd-red'}/>
                                    <p>Currently {!this.props.rule.isActive ? 'in' : ''}active</p>
                                </div> :
                                <div className={'rule-last-triggered'}>
                                    <p>{this.props.rule.lastTriggered ?
                                        <span>Last triggered <TimeAgo date="Aug 29, 2014"/></span> :
                                        <span>Never Triggered</span>}</p>
                                </div>
                            }

                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default RuleCard
