import React from "react";
import "./InteractiveTooltip.scss";
import SomeSitesWithout from "./SomeSiteWithoutTariffs";
import {Icon} from "gd-react";
import InteractiveTooltip from "./InteractiveTooltip";

class SomeSitesWithoutIconWithTooltip extends React.Component {
    render() {
        return (
            <InteractiveTooltip key={'nfc-tt'}
                                title={<SomeSitesWithout buttonClick={this.props.buttonClick} itemId={this.props.itemId} buttonLabel={this.props.buttonLabel} urlItem={this.props.urlItem} sitesWithout={this.props.sitesWithout}
                                                         title={this.props.title}/>}>
                            <span className={'n-f-c-icon'}>
                                <Icon size={this.props.size} icon={'IoMdAlert'}
                                      color={'gd-red'}/>
                        </span></InteractiveTooltip>
        )
    }
}

export default SomeSitesWithoutIconWithTooltip;
