import React, {Component} from "react";
import {Button, CardBody, Input} from "gd-react";
import GridDuck from 'gridduck';
import './ManageApplication.scss'
import GdModal from "../../components/GdModal/GdModal";

class ManageApplication extends Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.validate = this.validate.bind(this);
        this.reset = this.reset.bind(this);
        this.delete = this.delete.bind(this);
        this.state = {
            password: ''
        };
    }

    validate() {
        const self = this;
        return new Promise((resolve, reject) => {
            GridDuck.createRequestSecret({
                applicationId: this.props.application.id,
                password: this.state.password
            }).then(function (requestSecret) {
                self.setState({
                    clientSecret: requestSecret.clientSecret
                });
                resolve();
            }).catch(function () {

                self.setState({
                    error: "Incorrect password"
                });
                reject();
            })
        });
    }

    delete() {
        return this.props.application.delete();
    }

    reset() {
        let self = this;
        return GridDuck.createResetSecret({
            applicationId: this.props.application.id,
            password: this.state.password
        }).then(function (resetSecret) {
            self.setState({
                clientSecret: resetSecret.clientSecret,
            });
        });
    }


    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    render() {
        let content;
        if (!this.state.clientSecret) {
            content = (<div>
                {/*<div className={"row"}>*/}
                {/*    <p className={'info-text'}>Please confirm your credentials</p>*/}
                {/*</div>*/}
                <div className={'row'}>
                    <Input top={'true'} error={this.state.error} type={"password"} required label={"Your password"}
                           value={this.state.password}
                           onChange={e => this.setState({password: e.target.value})}/>
                </div>

            </div>);
        } else if (!this.state.doReset && !this.state.doDelete) {
            content = (<div>
                <div className={"row"}>
                    <Input top={'true'} disabled value={this.props.application.id} label={"Client ID"}/>
                </div>
                <div className={"row"}>
                    <Input disabled value={this.state.clientSecret} label={"Client Secret"}/>
                </div>

            </div>)
        } else if (this.state.doDelete) {
            content = (
                <div>
                    <div className={"reset"}>
                        <p>This action will delete the application. Are you sure?</p>
                    </div>
                </div>
            )
        } else if (this.state.doReset) {
            content = (
                <div>
                    <div className={"reset"}>
                        <p>This action will change the Client Secret and invalidate all currently issued access tokens.
                            Go ahead?</p>
                    </div>
                </div>
            )
        }
        return (
            <GdModal
                open={this.props.open}
                contentLabel={this.props.application.clientName}
                title={this.props.application.clientName}
                footer={<div>
                    {!this.state.clientSecret ? <Button additionalclasses={'md'} progressRes={true} disabled={!this.state.password} label={"Authenticate"}
                                                        onClick={this.validate}/> : ''}
                    {this.state.clientSecret && !this.state.doReset && !this.state.doDelete ?
                        <div style={{float: 'left', marginRight: '10px'}}><Button label={"Reset Client Secret"} color={'gd-grey'}
                                     onClick={() => this.setState({doReset: true})}/></div> : ''}
                    {this.state.clientSecret && !this.state.doReset && !this.state.doDelete ?
                        <Button color={"gd-red"} label={"Delete Application"}
                                onClick={() => this.setState({doDelete: true})}/> : ''}

                    {this.state.doDelete ? <Button color={"gd-red"} label={"Yes, delete it"}
                            onClick={this.delete} progressRes onComplete={this.closeModal}/> : ''}

                    {this.state.doReset ? <Button label={"Reset client secret"}
                                                  onClick={this.reset} progressRes={true} onComplete={() => this.setState({doReset: false})}/> : ''}

                </div>}
                onClose={this.closeModal}>
                <CardBody>
                    {content}
                </CardBody>
            </GdModal>
        )
    }
}

export default ManageApplication;
