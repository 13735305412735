import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    DateTimeRangePicker,
    Dropdown,
    Input,
    Loader,
    StateOptions,
    Toast
} from "gd-react";
import "./reset.scss";
import GridDuck from "gridduck";
import getFormat from "../../services/formatter";
import GDGraphs from "gd-graphs";
import moment from "moment-timezone";

class AssetAdmin extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        this.state = {};
        this.create = this.create.bind(this);
    }

    create() {
        let self = this;
        return GridDuck.createAssetReset({
            assetId: this.props.item.id,
            timestamp: this.state.timestamp,
            value: this.state.value
        }).then(function () {
            self.setState({timestamp: null, value: null});
            return Promise.resolve();
        });
    }

    render() {
        let self = this;

        return (
            <div className={"page narrow not-flex grey reset-page"}>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        <Card className={'asset-general-settings'}>
                            <CardHeader title={'General'}/>
                            <CardBody>
                                <Input label={'Timestamp'} type={'number'} name={'timestamp'} value={this.state.timestamp} onChange={(e) => this.setState({timestamp: e.target.value})}/>
                                <br/>
                                {this.state.timestamp ? getFormat('date')(this.state.timestamp/1000) : ' '}
                                <br/>
                                <Input label={'Value'} type={'number'} name={'value'} value={this.state.value} onChange={(e) => this.setState({value: e.target.value})}/>
                                <br/>
                                {this.state.value >= 0 ? 'Positive to correct a negative data spike (Reset)' : 'Negative to correct a positive data spike (Extra data)'}
                                <br/>
                                <br/>
                                <Button label={'Create'} onClick={this.create} progressRes/>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default AssetAdmin;
