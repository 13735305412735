import React, {Component} from "react";
import "./InvoicePreview.scss";
import '../../styles/_layout.scss';
import moment from "moment-timezone";

class InvoicePreview extends Component {
    render() {
        let dueDate;
        if (this.props.params.dueDate) {
            dueDate = moment(this.props.params.dueDate).format('MMMM D YYYY');
        } else {
            dueDate = ''
        }

        return (<div className={"InvoicePreview"}>
            <div style={{paddingLeft: '40px', paddingRight: '40px', overflow: 'hidden', paddingTop: '30px', backgroundColor: 'white'}}>
                <div className="mb20 flex-horizontal flex-justify flex-align-start width-100">
                    <div>
                        {this.props.params.logoImage && this.props.params.showing['logo'] ?
                            <div className="brand-logo-container">
                                <img className="logo-img brand-logo-img" src={this.props.params.logoImage}/>
                            </div> : null}
                        <div className="h1 mb20">
                            Invoice
                        </div>
                        <div className="mb20">
                            <table cellPadding={0} cellSpacing={0} className="metadata" style={{}}>
                                <tbody>
                                <tr>
                                    <td>
                                        Invoice number
                                    </td>
                                    <td>
                                        {this.props.params.id}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Date due
                                    </td>
                                    <td>
                                        {dueDate}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="brand-logo-fallback-container hidden">
                        <div className="h1 logo-gray">
                            DUCKDUCK LTD
                        </div>
                    </div>
                </div>
                <div className="flex-horizontal flex-align-start mb30 break-word">
                    <div className="width-50">
                        <div className="bold">
                            {this.props.params.issuingOrgName}
                        </div>
                        <div>
                            {this.props.params.issuingOrgPhoneNumber}
                        </div>
                        <div>
                            {this.props.params.issuingOrgEmail}
                        </div>
                        {this.props.params.vatId && this.props.params.showing['vatId'] ?
                            <table cellPadding={0} cellSpacing={0} className="mt5">
                                <tbody>
                                <tr>
                                    <td className="nowrap">
                                        {this.props.params.vatId || ''}
                                    </td>
                                </tr>
                                </tbody>
                            </table> : null}
                    </div>
                    <div className="flex-horizontal width-50">
                        <div className="width-100 pr15">
                            <div className="bold">
                                Bill to
                            </div>
                            <div>
                                {this.props.params.customerName}
                            </div>
                            <div>
                            </div>
                            <div>
                                {this.props.params.customerEmail}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb30">
                    <div className="h2">
                        £XXX due {dueDate}
                    </div>
                    {this.props.params.showing['memo'] ? <div className="mt10 maintain-newlines">{this.props.params.memo}</div> : null}
                </div>
                <table cellPadding={0} cellSpacing={0} className="line-item-table mb30" style={{}}>
                    <tbody>
                    <tr className="table-headers">
                        <td className="width-50 center-valign">
                            Description
                        </td>
                        <td className="width-50 center-valign align-right">
                            <div className="line-height-1point3">
                                Amount
                            </div>
                        </td>
                    </tr>
                    <tr className="
      line-item-row
      ">
                        <td>
                            <div style={{wordBreak: 'break-word', paddingLeft: '0px'}}>
                                <div className="bold">
                                    Electricity - Preview Example
                                </div>
                            </div>
                        </td>
                        <td>
                            £XX.XX
                        </td>
                    </tr>
                    <tr className="
      line-item-row
      ">
                        <td>
                            <div style={{wordBreak: 'break-word', paddingLeft: '0px'}}>
                                <div className="bold">
                                    Gas - Preview Example
                                </div>
                            </div>
                        </td>
                        <td>
                            £XX.XX
                        </td>
                    </tr>
                    <tr className="
      line-item-row
      ">
                        <td>
                            <div style={{wordBreak: 'break-word', paddingLeft: '0px'}}>
                                <div className="bold">
                                    Water - Preview Example
                                </div>
                            </div>
                        </td>
                        <td>
                            £XX.XX
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={5} height={15} style={{
                            border: 0,
                            borderCollapse: 'collapse',
                            margin: 0,
                            padding: 0,
                            height: '15px',
                            fontSize: '1px',
                            lineHeight: '1px',
                            msoLineHeightRule: 'exactly'
                        }}>
                            &nbsp;
                        </td>
                    </tr>
                    <tr className="summary-amount-border">
                        <td>
                        </td>
                        <td colSpan={4}>
                        </td>
                    </tr>
                    <tr className="summary-amount ">
                        <td>
                        </td>
                        <td colSpan={3}>
                            Subtotal
                        </td>
                        <td className="align-right">
                            £XX.XX
                        </td>
                    </tr>

                    {this.props.params.vat && this.props.params.showing['vat'] ? <tr className="summary-amount-border">
                        <td>
                        </td>
                        <td colSpan={4}>
                        </td>
                    </tr> : null}
                    {this.props.params.vat && this.props.params.showing['vat'] ? <tr className="summary-amount">
                        <td>
                        </td>
                        <td colSpan={3}>
                            VAT ({this.props.params.vat}%)
                        </td>
                        <td className="align-right">
                            <div>
                                £XX.XX
                            </div>
                        </td>
                    </tr> : null}
                    <tr className="summary-amount-border">
                        <td>
                        </td>
                        <td colSpan={4}>
                        </td>
                    </tr>
                    <tr className="summary-amount ">
                        <td>
                        </td>
                        <td colSpan={3} className="bold">
                            Total
                        </td>
                        <td className="align-right bold">
                            £XX.XX
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div className="pb30 avoid-page-break">
                </div>
                <div className="fs-10">
                </div>
            </div>
            <div className="mt20 pt20 light-gray-border-top fs-10 line-height-140"
                 style={{paddingBottom: '20px', paddingLeft: '40px', paddingRight: '40px', overflow: 'hidden', backgroundColor: 'white'}}>
                <div className="mt20">
                    <div className="flex-horizontal flex-justify">
                        <div className="nowrap">
                            {this.props.params.id} · £XX.XX due {dueDate}
                        </div>
                    </div>
                </div>
            </div>
            <p className={'info-text'}>Preview for layout purposes only, prices generated on creation.</p>
        </div>)
    }
}

export default InvoicePreview;