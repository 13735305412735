import React, {Component} from 'react';
import Icon from "../../components/Icon/Icon";
import {Button} from "gd-react";

class InfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            closed: this.props.startClosed || false,
            triedToClose: this.props.startClosed || false
        };
        ['submit', 'triggerSave'].forEach(f => {
            this[f] = this[f].bind(this);
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps?.status !== this.props?.status && this.props?.status === 'error') {
            this.setState({closed: false});
        }
        if (prevProps?.forceSave !== this.props?.forceSave) {
            this.setState({triedToClose: this.props?.forceSave});
        }
    }

    async submit() {
        return new Promise((resolve, reject) => {
            this.setState({
                closed: this.props.status === 'completed' && !this.state.closed,
                triedToClose: true
            }, async () => {
                await this.props.onDone();
                return resolve();
            });
        });
    }

    async triggerSave() {
        if (!this.state.closed) {
            if (this.props.onNext) {
                let res = await this.props.onNext();
                if (res.continue) this.submit();
            } else {
                this.submit();
            }
        }
    }

    render() {
        let buttons = [(this.state.openedWithEdit && !this.props.noButton && !this.state.closed ?
            <div key={this.props.key + 'buttons'} style={{marginRight: '10px'}}><Button label={'Cancel'}
                                                                                        color={'gd-grey'}
                                                                                        onClick={() => {
                                                                                            this.setState({closed: true}, () => {
                                                                                                if (this.props.onCancel) this.props.onCancel();
                                                                                            });
                                                                                        }}/>
            </div> : null), (!this.props.noButton && (!this.props.noEdit || (this.props.noEdit && !this.state.closed)) ?
            <div key={this.props.key + 'buttons-s'}><Button
                label={this.state.closed ? 'Edit' : this.state.openedWithEdit ? 'Save' : 'Continue'}
                color={this.state.closed ? 'gd-grey' : 'gd-brand'}
                disabled={this.props.disabled}
                onClick={!this.state.closed ? this.props.onNext ? async () => {
                    let res = await this.props.onNext();
                    if (res.continue) this.submit();
                } : this.submit : () => {
                    this.setState({closed: false, openedWithEdit: true}, () => {
                        if (this.props.onEdit) {
                            this.props.onEdit();
                        }
                    });
                }}/></div> : null)]
        return this.props.show ? (
            [(<div
                key={this.props.key + 'i-c'}
                className={'info-card ' + (this.state.closed ? 'closed ' : '') + ((this.state.triedToClose || this.props.forceSave) && ((this.props.status === 'completed' && this.state.closed) || this.props.status === 'error' && !this.state.closed) ? this.props.status : '') + (this.props.show ? ' showing ' : '') + (this.props.belowSubheading ? ' has-below ' : '')}>
                <div className={'info-card-heading row space-between'}>
                    <div className={'row'}>
                        <div className={'complete-icon-wrapper'}>
                            <div className={'complete-icon'}><Icon size={'11px'}
                                                                   icon={this.props.status === 'completed' ? 'FaCheck' : 'FaTimes'}/>
                            </div>
                        </div>
                        <div className={'info-card-header'}>{this.props.heading}{this.props.subheading &&
                            <div className={'info-card-subheading'}> - {this.props.subheading}</div>}</div>
                    </div>

                    {buttons}

                </div>
                <div className={'info-body'}>
                    {this.props.children}
                </div>
                <div className={'row reverse thin-button'} style={{marginTop: '15px'}}>
                    <div style={{display: 'flex'}}>{buttons}</div>
                </div>
            </div>),
                (this.props.belowSubheading ?
                        <div key={this.props.key + '-b-h'} className={'info-card showing has-above no-padding'}>
                            {this.props.belowSubheading}
                        </div> : null
                )]
        ) : null;
    }
}

export default InfoCard;