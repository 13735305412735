import React from "react";
import {
    Button,
    List,
    StandardListCell
} from "gd-react";
import GridDuck from 'gridduck';
import './dataUpload.scss'
import history from "../../meta/history";
import {camelfy} from "../../services/CamelfyRequestData";
import CreateDataType from "./CreateDataType";
import NoItems from "../../components/noItems";
let pagePath;

class DataTypeListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDataCategoryModal: null
        };
        this.getList = this.getList.bind(this);
    }

    async componentDidMount() {
        pagePath = window.location.pathname + window.location.search;
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (user) {
                self.setState({user: user});
            });
        let urlParams = new URLSearchParams(window.location.search);
        let id = urlParams.get('datatypeid');
        let dataType;
        let hasAnyDataTypes = await camelfy((...args) => GridDuck.getDataTypes(...args), {items: 1, offset: 0, filters: [{
                field: 'categoryId',
                value: this.props.categoryId
            }]});
        if (!hasAnyDataTypes.list.length) dataType = true;
        if (id) {
             let dataTypeRes = await camelfy((...args) => GridDuck.getDataTypes(...args), {filters: [{
                     field: 'categoryId',
                     value: this.props.categoryId
                 }, {
                     field: 'id',
                     value: id
                 }]});
        }
        self.setState({showDataCategoryModal: dataType});
    }

    async getList(params) {
        return camelfy((...args) => GridDuck.getDataTypes(...args), params);
    }

    render() {
        return (
            this.state.user ? <div>
                <div className={'widget-board-header'}>
                    <div className={'row reverse place-in-header'}>
                        {this.state.user.orgPermission !== 'view' ?
                            <Button color={'gd-green'} label={'+ Add New Data Type'}
                                    onClick={() => {
                                        this.setState({
                                            showDataCategoryModal: true
                                        })
                                    }}/> : null}
                    </div>
                </div>
                <List
                    noitemelem={<NoItems text={'No Data Types'}/>}
                    columns={[
                        {
                            component: StandardListCell,
                            title: 'Name',
                            field: 'name'
                        },
                        {
                            component: StandardListCell,
                            title: 'Type',
                            custom: function (item) {
                                let dt = item.dataType?.charAt(0).toUpperCase() + item.dataType?.slice(1);
                                if (item.applyRate) dt = 'Rate';
                                return dt;
                            },
                            field: 'dataType',
                            maxWidth: '250px'
                        },
                        {
                            component: StandardListCell,
                            custom: function (item) {
                                return item.unitDetails?.name;
                            },
                            title: 'Unit System',
                            field: 'unitDetails'
                        },
                        {
                            component: StandardListCell,
                            custom: function (item) {
                                return item.rateUnitDetails?.name || '-';
                            },
                            title: 'Rate Unit System',
                            field: 'rateUnitDetails'
                        },
                        {
                            component: StandardListCell,
                            title: 'Permission',
                            field: '_permission',
                            maxWidth: '250px'
                        }]}
                    getlist={this.getList}
                    rowOnClick={(item) => {
                        this.setState({showDataCategoryModal: item})
                        // let newUrl = pagePath.replace(/([&?])datatypeid=[^&]*&?/, '$1').replace(/&$/, '').replace(/\?$/, '');
                        // history.push(newUrl + '&datatypeid=' + item.id);
                    }}
                    filters={[{
                        field: 'categoryId',
                        value: this.props.categoryId
                    }]}/>

                {this.state.showDataCategoryModal ?
                    <CreateDataType isGod={this.state.user.god}
                                    user={this.state.user}
                                    orgPerm={this.state.user.orgPermission}
                                    item={this.state.showDataCategoryModal}
                                    categoryId={this.props.categoryId}
                                    onClose={() => {
                                        this.setState({
                                            showDataCategoryModal: null
                                        })
                                        // const newUrl = pagePath.replace(/([&?])datatypeid=[^&]*&?/, '$1').replace(/&$/, '').replace(/\?$/, '');
                                        // history.push(newUrl);
                                    }}
                                    open={this.state.showDataCategoryModal}/> : null}

            </div> : null)
    }
}

export default DataTypeListPage;