import React, {Component} from "react";
import Modal from '@material-ui/core/Modal';
import "./GdModal.scss";
import {Card, CardHeader, Icon} from 'gd-react'

class GdModal extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.stopProp = this.stopProp.bind(this);
    }

    stopProp(ev) {
        ev.stopPropagation();
        ev.preventDefault();
    }

    render() {

        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: '0px',
                background: 'none',
                border: 'none'
            },
            overlay: {
                transition: 'all 0.3s'
            }
        };

        let className = '';
        if (this.props.wide) {
            className += ' wide';
        }
        if (this.props.isMobile) {
            className += ' fullscreen';
        }
        if (this.props.almostFullScreen) {
            className += ' almostFullScreen';
        }

        return (
            <Modal open={this.props.open}
                   onClose={this.props.onClose}
                   contentlabel={this.props.contentlabel}
                   disableEnforceFocus
                   BackdropProps={{
                       transitionDuration: 500
                   }}
                   style={customStyles}>
                <div className={'modal-body-wrapper'}>
                    <div className={'modal-body'}>
                        <Card className={className}>
                            <div className={'row'} style={{overflow: 'hidden', width: '100%'}}>
                                <div className={'column'} style={{width: '100%'}}>
                                    <CardHeader title={this.props.title}>
                                        <div className={'row'}>
                                            <div className={'clickable close-butt'} onClick={this.props.onClose}>
                                                <span>Close Window</span>
                                                <Icon icon={'FaChevronRight'} color={'gd-grey'}/>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    {this.props.children}
                                    <div id={'drawer-footer'}
                                         className={'modal-footer ' + (this.props.footer ? 'with-top-border' : '')}>{this.props.footer}</div>
                                </div>
                                {this.props.sideContent}
                            </div>
                        </Card>


                    </div>
                </div>
            </Modal>
        )
    }
}

export default GdModal;
