import {StateOptions} from "gd-react";

const getDataType = function (dt) {
    let chosenType, isInstantaneous = false;
    StateOptions.forEach(function (stateOption) {
        stateOption.dataTypes.forEach(function (dataType) {
            if (dataType.type === dt) {
                chosenType = dataType;
            }
        })
    });

    if (chosenType?.graphType !== 'bar') {
        isInstantaneous = true;
    }

    return {
        chosenType: chosenType,
        isInstantaneous: isInstantaneous
    }
}

export const legacyRule = function (rule) {
    // Default Values
    let alerts = [];
    let triggers = [];
    let isSchedule = false;
    let dataContext = {};
    let throttleDuration;
    let customThrottleDuration;
    let periodDuration;
    let periodStartDefined;
    let customPeriod;
    let schedules = [{
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        state: -1
    }];
    // Legacy/v2 Define dataContext
    if (rule.triggers && rule.triggers.length) dataContext = rule.triggers[0];
    if (rule.actions && rule.actions.length && rule.actions.find(a => a.name === 'bounds')) dataContext = rule.actions.find(a => a.name === 'bounds');
    // Set if dataType is instantaneous
    dataContext.instantaneousData = getDataType(dataContext.dataType).isInstantaneous;
    // General definitions
    let inactiveState = -1;
    if (rule.actions && rule.actions.length) {
        throttleDuration = dataContext.throttleDuration;
        periodDuration = dataContext.periodDuration;
        periodStartDefined = dataContext.periodStartDefined;

    } else {
        throttleDuration = rule.throttleDuration;
        periodDuration = rule.periodDuration;
        periodStartDefined = rule.periodStartDefined;
    }
    customThrottleDuration = (throttleDuration !== undefined && throttleDuration !== 86400 && throttleDuration !== 3600 && throttleDuration !== 60);
    customPeriod = (periodStartDefined === null && periodDuration !== 86400 && periodDuration !== 3600 && periodDuration !== 60);
    let assets = dataContext.assets;
    let actions = rule.alerts ? JSON.parse(JSON.stringify(rule.alerts)) : rule.actions;
    // Legacy/v2 Define Asset Logic (all/some)
    if (rule.actions && rule.actions.length) dataContext.requireAll = dataContext.assetLogic === 'all';
    // Legacy/v2 Define Alerts
    if (rule.alerts && rule.alerts.length) alerts = JSON.parse(JSON.stringify(rule.alerts));
    if (rule.actions && rule.actions.length) alerts = [rule.actions.find(a => a.name === 'email')];
    // Legacy/v2 Define Triggers
    if (rule.triggers && rule.triggers.length) triggers = JSON.parse(JSON.stringify(rule.triggers));
    if (rule.actions && rule.actions.length) triggers = [dataContext];
    // Legacy/v2 Define isSchedule
    if (rule.actions && rule.actions.length && rule.actions.find(a => a.isSchedule)) isSchedule = !!rule.actions.find(a => a.isSchedule);
    // Legacy/v2 Define Schedules
    if (isSchedule) {
        if (rule.triggers && rule.triggers.length) schedules = JSON.parse(JSON.stringify(rule.triggers.map(t => t.details)));
        if (rule.actions && rule.actions.length && rule.actions.find(a => a.isSchedule) && rule.actions.find(a => a.isSchedule).schedules) schedules = rule.actions.find(a => a.isSchedule).schedules;
    }

    return {
        id: rule.id,
        name: rule.name,
        description: rule.description,
        inactiveState: inactiveState,
        dataContext: dataContext,
        throttleDuration: throttleDuration,
        periodDuration: periodDuration,
        periodStartDefined: periodStartDefined,
        isSchedule: isSchedule,
        schedules: schedules,
        conditions: schedules,
        customPeriod: customPeriod,
        customThrottleDuration: customThrottleDuration,
        alerts: alerts,
        triggers: triggers,
        actions: actions,
        dataType: dataContext.dataType,
        assets: assets,
        creating: rule.creating
    }
}