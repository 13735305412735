import React, {Component} from "react";
import "./Notifications.scss";
import '../../styles/_layout.scss';
import GridDuck from "gridduck";
import {
    Button,
    CardBody,
    Tabs,
    List,
    Icon,
    StandardListCell
} from "gd-react";
import GenericLoader from "../../components/GenericLoader";
import GdModal from "../../components/GdModal/GdModal";
import NoItems from "../noItems";
import getFormat from "../../services/formatter";
import history from "../../meta/history";
import generateNotificationMetaData from "../../services/generateNotificationMetaData";
import NotificationsFilter from "./NotificationsFilter";
import SideDrawer from "../SideDrawer";
import {deleteCookie} from "../../services/cookieManager";

class NotificationsDrawer extends Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.getList = this.getList.bind(this)
        this.onTabClick = this.onTabClick.bind(this)
        this.navigateToClickedItem = this.navigateToClickedItem.bind(this)
        this.listRef = React.createRef()
        this.errors = {};
        this.state = {
            loaded: false,
            showingNotifications: ['info', 'warning', 'error']
        }
        this.colors = {
            info: '#49b9c4',
            update: '#49b9c4',
            delete: '#49b9c4',
            create: '#49b9c4',
            error: '#fc1e1e',
            warning: '#f89d41',
        }
        this.modalClosed = false;
        this.tabs = [[
            {
                id: 1,
                title: 'All',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 1 || !this.props.openOnTab)
            },
            {
                id: 2,
                title: 'Account',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 2)
            },
            {
                id: 3,
                title: 'Alerts',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 3)
            }
        ]]
        if (this.props.account && (this.props.account.god || this.props.account.username === 'example@gridduck.com')) this.tabs[0].push(
            {
                id: 4,
                title: 'AI Alerts',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 4)
            }
        )
        this.notificationListRef = React.createRef();
    }

    async componentDidMount() {
        let self = this
        self.setState({
            loaded: true
        })
    }

    async getList(params) {
        // params.getAll = true;
        return await GridDuck.getNotifications(params);
        // list.list = generateNotificationMetaData(list.list)
        // return Promise.resolve(list);
    }

    closeModal() {
        this.modalClosed = true;
        deleteCookie('notificationDrawerOpen', 'true');
        this.props.onClose();
    }

    onTabClick(ev, tab) {
        this.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
        this.listRef.current.getItems();
    }

    navigateToClickedItem(item) {
        let _item = generateNotificationMetaData(item);
        if (_item.url) {
            history.push(_item.url);
        }
    }

    render() {
        console.log('rerender triggered')
        let self = this
        return (
            <SideDrawer
                title={<div className={'row space-between'}>Notifications
                    <NotificationsFilter label={'Notifications'} potentialOptions={['info', 'warning', 'error']} onUpdate={(val) => {
                        console.log(val, ' : update types filter to');
                        this.setState({showingNotifications: val}, () => {
                            if (this.notificationListRef && this.notificationListRef.current) this.notificationListRef.current.getItems(null, true, null, true)
                        });
                    }} notificationTypes={this.state.showingNotifications}/>
                </div>}
                // wide
                open={this.props.open}
                contentLabel={'notifications'}
                onClose={this.closeModal}>
                <CardBody additionalclasses={'no-padding'}>
                    {this.state.loaded ?

                        <List
                            ref={this.listRef}
                            removeHeaders
                            lazyload
                            itemsPerPage={20}
                            dynamicRowHeight={(item) => {
                                let _item = generateNotificationMetaData(item);
                                if (_item.subtitle) {
                                    return '50px';
                                } else {
                                    return '35px';
                                }

                            }}
                            noClickCheck={(item) => {
                                let _item = generateNotificationMetaData(item);
                                return _item.noClick;
                            }}
                            noitemelem={<NoItems text={'No notifications to show'}/>}
                            columns={[
                                {
                                    component: StandardListCell,
                                    maxWidth: '20px',
                                    custom: function (item) {
                                        let _item = generateNotificationMetaData(item);
                                        return <div><Icon size={'14'} icon={_item.icon?.name || 'FaQuestion'}
                                                          color={_item?.icon?.color || _item.color}/>
                                        </div>
                                    }
                                },
                                {
                                    component: StandardListCell,
                                    field: 'name',
                                    maxWidth: 'none',
                                    custom: function (item) {
                                        let _item = generateNotificationMetaData(item);
                                        return <div className={'column'}>
                                            <div className={'row'}>
                                                <p className={'notifications-name'}>{_item.name}</p>
                                            </div>
                                            <div className={'notifications-subtitle'}>{_item.subtitle}</div>
                                            <div className={'notifications-subtitle'}>{_item.date}</div>
                                        </div>
                                    }
                                },
                                {
                                    component: StandardListCell,
                                    maxWidth: '30px',
                                    custom: function (item) {
                                        let _item = generateNotificationMetaData(item);
                                        if (_item.noClick) return <div/>;
                                        else {
                                            return <div><Icon size={'14'} icon={'FaArrowRight'}
                                                              color={'grey'}/>
                                            </div>
                                        }
                                    }
                                }
                            ]}
                            getlist={this.getList}
                            filters={[
                                {field: 'category', value: this.state.showingNotifications}
                            ]}
                            rowOnClick={this.navigateToClickedItem}/>
                        : <GenericLoader/>}
                </CardBody>
            </SideDrawer>
        )
    }
}


export default NotificationsDrawer;