import React from 'react';
import './MainOrganisationPage.scss';
import GridDuck from 'gridduck';
import {
	Button,
	List,
	StandardListCell,
	PartnerOrgNameCell,
} from "gd-react";
import AddPartnerModal from "../../modals/AddPartner/AddPartnerModal";
import PartnerOrgDrawer from "../../components/Drawer/PartnerOrgDrawer";

class OrganisationPartners extends React.Component {
	constructor(props) {
		super(props);
		this.getList = this.getList.bind(this);
		this.openPartnerDrawer = this.openPartnerDrawer.bind(this);
		let self = this;
		GridDuck.getAccount({id: GridDuck.userId}).then(function (account) {
			self.setState({
				ownOrgId: account.organisationId,
				ownOrgName: account.organisationName,
				hasOwner: account.orgPermission === 'owner',
				hasAdmin: account.orgPermission === 'admin' || account.orgPermission === 'owner'
			})
		});
		this.state = {
			filters: [
				{field: 'owner', value: true}
			]
		}
	}

	async getList(retrievalParams) {
		return GridDuck.getOrgPartners(retrievalParams);
	}

	openPartnerDrawer(partner) {
		// history.push('/organisation/user/' + user.id);
		this.setState({showPartnerDrawer: partner})
	}

	render() {
		let columns = [
			{component: PartnerOrgNameCell, title: 'Organisation', field: 'orgToName', thisOrg: this.state.ownOrgId},
			{
				component: StandardListCell,
				title: 'Type',
				field: 'permType',
				maxWidth: 'none',
				custom: (item) => {
					return item.permType === 'customer' ? 'client' : item.permType;
				}
			},
		];

		return (
			<div className='page'>
				{this.state.openAdd ? <AddPartnerModal
					ownerOrgName={this.state.ownOrgName}
					ownerOrgId={this.state.ownOrgId}
					open={this.state.openAdd}
					onClose={() => this.setState({openAdd: false})}/> : ''}
				<div className={"notice"} style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}>
                   <span style={{paddingRight: '30px'}}> <strong>This is where you can manage relationships with other organisation on GridDuck</strong><br/><br/>
                    <strong>Installer</strong> - When this organisation creates a new site for you it automatically
                    assigns you access in the dashboard<br/>
                    <strong>Client</strong> - Enable you to invite a client to create a GridDuck organisation and offer a catered experience</span>
					<Button onClick={() => this.setState({openAdd: true})} label={"Add Partner"}/>
				</div>
				<List columns={columns}
					  rowHeight={'30px'}
					  getlist={this.getList}
					  rowOnClick={this.openPartnerDrawer}
					  filters={this.state.filters}
				/>
				{this.state.showPartnerDrawer ? <PartnerOrgDrawer
					onClose={() => {
						this.setState({showPartnerDrawer: null});
					}}
					hasAdmin={this.state.hasAdmin}
					hasOwner={this.state.hasOwner}
					open={!!this.state.showPartnerDrawer}
					item={this.state.showPartnerDrawer}/> : null}
			</div>
		);
	}
}

export default OrganisationPartners;
