import React from "react";
import GridDuck from "gridduck";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
} from "gd-react";
import getFormat from "../../services/formatter";

class DevOps extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        this.state = {
            updateRef: 0
        }
        this.externalUpdate = this.externalUpdate.bind(this);
        GridDuck.getStagingDatabase({
            id: "1"
        }).then(function (stagingDatabase) {
            self.setState({stagingDatabase: stagingDatabase});
            stagingDatabase.on('updated', self.externalUpdate);
        });
    }

    externalUpdate() {
        this.setState({updateRef: this.state.updateRef++});
    }

    componentWillUnmount() {
        if (this.state && this.state.stagingDatabase) {
            this.state.stagingDatabase.off('updated', this.externalUpdate);
        }
    }

    render() {
        return (
            <div className='page narrow Account not-flex grey'>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader>Staging Database</CardHeader>
                            <CardBody>
                                Status: {this.state.stagingDatabase ? this.state.stagingDatabase.status : ''}
                                <br/>
                                Last
                                Updated: {this.state.stagingDatabase ? getFormat('date')(this.state.stagingDatabase.date) : ''}
                                <br/>
                                <Button additionalclasses={'sm'} color={'gd-green'}
                                        onClick={() => this.state.stagingDatabase.set({"status": "Updating"})}
                                        progressRes
                                        label={'Resync'}/>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default DevOps;
