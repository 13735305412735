import moment from "moment-timezone";
import {floorFiveMinutes} from "./dateManipulation";

const processedUrlParams = function (urlParams, timezone, isAll) {
    // console.log(urlParams.get('g'), ' : url params');
    let start = moment().tz(timezone).startOf('isoWeek').unix();
    let end = floorFiveMinutes(moment().tz(timezone).unix());
    if (isAll) {
        start = moment().tz(timezone).startOf('isoWeek').unix();
        end = moment().tz(timezone).startOf('day').unix();
    }
    let granularity;
    granularity = 60 * 30;
    if (urlParams.get('gr')) {
        granularity = urlParams.get('gr') === 'monthly' ? 'monthly' : parseInt(urlParams.get('gr'));
    }
    let dRString = urlParams.get('dR');
    let cRString = urlParams.get('cR');
    let type =  urlParams.get('ty');
    let graph =  urlParams.get('g');
    let combined =  urlParams.get('combined');
    let average =  urlParams.get('avg');
    switch (urlParams.get('dR')) {
        case 'custom':
            start = parseInt(urlParams.get('s'));
            end = parseInt(urlParams.get('e'));
            break;
        case 'last_15_mins':
            start = moment().tz(timezone).subtract(15, 'minutes').unix();
            end = moment().tz(timezone).unix();
            break;
        case 'today_so_far':
            start = moment().tz(timezone).startOf('day').unix();
            end = floorFiveMinutes(moment().tz(timezone).unix());
            break;
        case 'yesterday':
            start = moment().tz(timezone).subtract(1, 'day').startOf('day').unix()
            end = moment().tz(timezone).startOf('day').unix();
            break;
        case 'week_so_far':
            start = moment().tz(timezone).startOf('isoWeek').unix();
            end = floorFiveMinutes(moment().tz(timezone).unix());
            if (isAll) {
                start = moment().tz(timezone).startOf('isoWeek').unix();
                end = moment().tz(timezone).startOf('day').unix();
            }
            break;
        case 'last_week':
            start = moment().tz(timezone).subtract(7, 'day').startOf('isoWeek').unix();
            end = moment().tz(timezone).startOf('isoWeek').unix();
            break;
        case 'month_so_far':
            start = moment().tz(timezone).startOf('month').unix();
            end = floorFiveMinutes(moment().tz(timezone).unix());
            break;
        case 'last_month':
            start = moment().tz(timezone).subtract(1, 'month').startOf('month').unix();
            end = moment().tz(timezone).startOf('month').unix();
            break;
        case 'year_so_far':
            start = moment().tz(timezone).startOf('year').unix();
            end = floorFiveMinutes(moment().tz(timezone).unix());
            break;
        case 'last_year':
            start = moment().tz(timezone).subtract(1, 'year').startOf('year').unix();
            end = moment().tz(timezone).startOf('year').unix();
            break;
    }

    let compareStart;
    let compareEnd;
    switch (urlParams.get('cR')) {
        case 'previous_15_mins':
            compareStart = moment().tz(timezone).subtract(30, 'minutes').unix();
            compareEnd = moment().tz(timezone).subtract(15, 'minutes').unix();
            break;
        case 'yesterday_so_far':
            compareStart = moment().tz(timezone).startOf('day').subtract(1, 'day').unix();
            compareEnd = floorFiveMinutes(moment().tz(timezone).subtract(1, 'day').unix());
            break;
        case 'previous_day':
            compareStart = moment().tz(timezone).subtract(2, 'days').startOf('day').unix()
            compareEnd = moment().tz(timezone).subtract(1, 'days').startOf('day').unix();
            break;
        case 'last_week_so_far':
            compareStart = moment().tz(timezone).startOf('isoWeek').subtract(1, 'week').unix();
            compareEnd = floorFiveMinutes(moment().tz(timezone).subtract(7, 'days').unix());
            break;
        case 'previous_week':
            compareStart = moment().tz(timezone).subtract(2, 'weeks').startOf('isoWeek').unix();
            compareEnd = moment().tz(timezone).subtract(1, 'weeks').startOf('isoWeek').unix();
            break;
        case 'last_month_so_far':
            compareStart = moment().tz(timezone).startOf('month').subtract(1, 'month').unix();
            compareEnd = floorFiveMinutes(moment().tz(timezone).subtract(1, 'month').unix());
            break;
        case 'previous_month':
            compareStart = moment().tz(timezone).subtract(2, 'months').startOf('month').unix();
            compareEnd = moment().tz(timezone).subtract(1, 'months').startOf('month').unix();
            break;
        case 'last_year_so_far':
            compareStart = moment().tz(timezone).startOf('year').subtract(1, 'year').unix();
            compareEnd = floorFiveMinutes(moment().tz(timezone).subtract(1, 'year').unix());
            break;
        case 'previous_year':
            compareStart = moment().tz(timezone).subtract(2, 'years').startOf('year').unix();
            compareEnd = moment().tz(timezone).subtract(1, 'years').startOf('year').unix();
            break;
        case 'custom':
            compareStart = parseInt(urlParams.get('cS'));
            compareEnd = parseInt(urlParams.get('cE'));
            break;
    }

    let granularityOptions = [];

    let period = end -start;
    let hour = 60 * 60;
    if (period < hour) {
        if (period > hour / 2) {
            granularityOptions.unshift({
                value: 900,
                title: '15 Minutes'
            })
        }
        granularityOptions.unshift({
            value: 300,
            title: '5 Minutes'
        })
        granularityOptions.unshift({
            value: 30,
            title: '30 Seconds'
        })
    }
    if (period > hour && period < hour * 24 * 7) {
        if (period > hour / 2) {
            granularityOptions.unshift({
                value: 900,
                title: '15 Minutes'
            })
        }
        granularityOptions.unshift({
            value: 300,
            title: '5 Minutes'
        })
        granularityOptions.push({
                value: 60 * 30,
                title: 'Half Hour',
            },
            {
                value: 60 * 60,
                title: 'Hour',
            })
    }
    if (period > hour * 24) {
        granularityOptions.push(
            {
                value: 60 * 60 * 24,
                title: 'Day'
            }
        );
        if (period < hour * 24 * 14) {
            granularityOptions.push(
                {
                    value: 60 * 60,
                    title: 'Hour',
                });
        }
    }
    if (period > hour * 24 * 7) {
        granularityOptions.push(
            {
                value: 60 * 60 * 24 * 7,
                title: 'Week'
            }
        );
    }
    if (period > hour * 24 * 31) {
        granularityOptions.push(
            {
                value: 'monthly',
                title: 'Month'
            }
        );
    }

    if (!granularityOptions.find(gO => gO.value === granularity) && granularityOptions.length > 0) {
        granularity = granularityOptions[0].value;
    }

    return  {
        dRString: dRString,
        cRString: cRString,
        start: start,
        end: end,
        type: type,
        graph: graph,
        combined: combined,
        average: average,
        compareStart,
        compareEnd,
        granularity
    }
}

export default processedUrlParams;
