import React, {Component} from 'react';
import {Input} from "gd-react";

class DimensionlessForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            singleLabel: this.props.unit && this.props.unit.metaData ? this.props.unit.metaData.singleLabel : '',
            pluralLabel: this.props.unit && this.props.unit.metaData ? this.props.unit.metaData.pluralLabel : ''
        };
        this.errors = {};
        let self = this;
        ['checkErrors', 'formUpdate'].forEach((func) => {
            self[func] = self[func].bind(self);
        });
    }

    componentDidMount() {
        this.formUpdate({updateFunc: (cb) => cb()});
    }

    checkErrors() {
        this.errors['singleLabel'] = !this.state.singleLabel || !this.state.singleLabel.length;
        this.errors['pluralLabel'] = !this.state.pluralLabel || !this.state.pluralLabel.length;
    }

    formUpdate(formData) {
        let self = this;
        formData.updateFunc(() => {
            self.checkErrors();
            self.props.onChange({metaData: self.state, errors: self.errors});
        });
    }

    render() {
        return (
            <div>
                <div className={'row'}>
                    <Input required
                           onChange={(val) => this.formUpdate({updateFunc: (cb) => this.setState({singleLabel: val.target.value}, cb)})}
                           value={this.state.singleLabel}
                           error={this.errors['singleLabel'] && this.props.triedToSave}
                           placeholder={'E.g. Item'}
                           label={'Singular Label'}/>
                </div>
                <div className={'row'}>
                    <Input required
                           onChange={(val) => this.formUpdate({updateFunc: (cb) => this.setState({pluralLabel: val.target.value}, cb)})}
                           value={this.state.pluralLabel}
                           error={this.errors['pluralLabel'] && this.props.triedToSave}
                           placeholder={'E.g. Items'}
                           label={'Plural Label'}/>
                </div>
            </div>
        );
    }
}

export default DimensionlessForm;