import React, {Component} from 'react';
import {Input} from "gd-react";
import Icon from "../../../components/Icon/Icon";

class NonMetricRule extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let i = this.props.index;
        return (
            <div className={'row'} style={{alignItems: 'end'}}>
                {this.props.unitType === 'nonMetric' ? <div style={{width: '130px', minWidth: '130px'}}>
                    <Input required
                           disabled={this.props.rule.id === 'base'}
                           type={'number'}
                           onChange={(val) => this.props.onChange({
                               id: this.props.rule.id,
                               key: 'scaleOfBase',
                               value: val.target.value
                           })}
                           value={this.props.rule.scaleOfBase}
                           error={this.props.errors[this.props.rule.id + 'scaleOfBase'] && this.props.triedToSave}
                           label={i === 0 ? 'Scale of Base' : ''}/>
                </div> : null}
                <div className={'form-divider'}/>
                <Input required
                       onChange={(val) => this.props.onChange({
                           id: this.props.rule.id,
                           key: 'name',
                           value: val.target.value
                       })}
                       value={this.props.rule.name}
                       placeholder={'E.g. Feet'}
                       error={this.props.errors[this.props.rule.id + 'name'] && this.props.triedToSave}
                       label={i === 0 ? 'Name' : ''}/>
                <div className={'form-divider'}/>
                <Input required
                       onChange={(val) => this.props.onChange({
                           id: this.props.rule.id,
                           key: 'symbol',
                           value: val.target.value
                       })}
                       value={this.props.rule.symbol}
                        placeholder={'E.g. ft'}
                       error={this.props.errors[this.props.rule.id + 'symbol'] && this.props.triedToSave}
                       label={i === 0 ? 'Symbol' : ''}/>
                <div className={'form-divider'}/>
                <div style={{
                    height: '47px',
                    minWidth: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}><Icon icon={'FaTrash'} color={this.props.rule.id !== 'base' ? 'gd-red' : 'transparent'} onIconClick={this.props.rule.id !== 'base' ? () => {
                    this.props.removeRule(this.props.rule)
                } : null}/></div>
            </div>
        );
    }
}

export default NonMetricRule;