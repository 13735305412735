import React from "react";
import GridDuck from "gridduck";
import {Button, CardBody, Input} from "gd-react";
import {Link} from "react-router-dom";
import {Icon} from "@material-ui/core";
import animatedLogo from "../images/animated-logo-once.svg";
import PasswordChecker from "../components/PasswordChecker/PasswordChecker";
import history from "../meta/history";

class PasswordReset extends React.Component {
    constructor(props) {
        super(props);
        let {token} = this.props.match.params;
        this.state = {checked: false};
        this.token = token;
        let self = this;
        GridDuck.getResetToken({id: token}).then(function (resetToken) {
            self.setState({
                checked: true,
                token: resetToken,
                validated: true
            });
        }).catch(function (err) {
            self.setState({
                checked: true,
                validated: false
            });
        })
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.reset = this.reset.bind(this);
    }

    handlePasswordChange(passed, pw) {
        this.setState({passed: passed, password: pw});
    }

    handleConfirmPasswordChange(val) {
        this.setState({confirmPassword: val.target.value})
    }

    reset() {
        let self = this;
        if (!this.state.passed) return;
        return this.state.token.setPassword(this.state.password).then(function () {
            self.setState({resetComplete: true})
        })
    }
}

export default PasswordReset;
