import React, {Component} from "react";
import '../../styles/_layout.scss';
import {Button, Card, CardBody, CardHeader, Dropdown, GdAutocomplete, GdModal, Icon, Input, Loader} from "gd-react";
import {Elements, CardElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import InjectedCheckoutForm from "../../components/Payment/CheckoutForm";
const stripePromise = loadStripe('pk_test_eZPotgJgAaKXTSVWk2KROdq9');


class AddPaymentCardModal extends Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);

    }

    closeModal(reload) {
        console.log('close modal 18');
        this.modalClosed = true;
        this.props.onClose(reload);
    }

    render() {

        return (
            <GdModal
                title={"Add Payment Card"}
                open={this.props.open}
                contentLabel={'Add Site'}
                onClose={this.closeModal}>
                <CardBody>
                    <Elements stripe={stripePromise}>
                        <InjectedCheckoutForm closeModal={this.closeModal} name={this.props.item.name}/>
                    </Elements>
                </CardBody>
            </GdModal>
        )
    }
}

export default AddPaymentCardModal;