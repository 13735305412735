import React, {Component} from "react";
import {
    Button,
    CardBody,
    GdAutocomplete,
    GdCheckbox,
    Input
} from "gd-react";
import "./AddHubModal.scss";
// import "../../styles/_theme.scss"
import '../../styles/_layout.scss';
import GridDuck from "gridduck";
import history from "../../meta/history";
import GenericLoader from "../../components/GenericLoader";
import LocationForm from "../../components/LocationForm";
import moment from 'moment-timezone'
import GdModal from "../../components/GdModal/GdModal";

class AddHubModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.onSerialChange = this.onSerialChange.bind(this);
        this.formatHex = this.formatHex.bind(this)
        this.saveChanges = this.saveChanges.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.onSiteChange = this.onSiteChange.bind(this);
        this.onGatewayChange = this.onGatewayChange.bind(this);
        this.onTimezoneChange = this.onTimezoneChange.bind(this)
        this.onNameChange = this.onNameChange.bind(this)
        this.onInstChange = this.onInstChange.bind(this);
        this.errors = {};
        this.locationFormRef = React.createRef();
        this.changed = false;

        this.state = {
            hubs: [],
            serial: '',
            inst: '',
            name: '',
            error: '',
            virtualFromId: null,
            timezone: 'Europe/London',
            virtual: false,
            gateway: null,
            site: '',
            locationId: '',
            newLocationName: '',
            newLocationMetaData: '',
            timezones: moment.tz.names().map(function (name, index) {
                return {
                    id: index,
                    title: name
                };
            }),
        }

        this.modalClosed = false;
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    formatHex(userInput) {
        console.log('format mac address: ', userInput);
        if (userInput) {
            return userInput.replace(/[^A-Fa-f0-9]/g, "").replace(/(.{4})/g, '$1 ').trim().toUpperCase();
        } else return '';
    }

    onSerialChange(input) {
        console.log(input.target.value, ' input in serial')
        let self = this
        let inputText = this.formatHex(input.target.value);

        this.setState({
            serial: inputText,
            error: ''
        });
    }

    onInstChange(val) {
        let inputText = this.formatHex(val.target.value)

        this.setState({
            inst: inputText,
            error: ''
        })
    }

    onNameChange(val) {
        let name = val.target.value;
        this.setState({name: name, error: ''})
    }

    onTimezoneChange(val) {
        let timezone = val.target.value;
        this.setState({timezone: timezone, error: ''})
    }

    onSiteChange(val, newValue) {
        this.state.site = newValue;
        this.setState(this.state);
    }

    onGatewayChange(val, newValue) {
        if (newValue) {
            this.state.gateway = newValue;
            this.state.virtualFromId = newValue.id;
            this.setState(this.state);
        }
    }

    async saveChanges() {
        let self = this;
        this.setState({triedToSave: true})
        let locationErrors = this.locationFormRef.current.checkErrors(true);

        console.log('Create Hub', Object.keys(this.errors));
        if (Object.keys(this.errors).length > 0 || Object.keys(locationErrors).length > 0) return Promise.reject();

        console.log('Passed errors');
        let serial = self.state.serial.replace(/\s/g, ''),
            inst = self.state.inst.replace(/\s/g, ''),
            hub = {
                name: self.state.name,
                serialNumber: serial,
                installationCode: inst
            };


        let locationRes = await this.locationFormRef.current.createNewLocation();
        hub.locationId = locationRes.id;

        console.log(hub, 'hub obj being created')

        let createGateway;
        try {
            createGateway = await GridDuck.createGateway(hub).then((hubRes) => {
                return hubRes
            });
        } catch (err) {
            console.log(err.response.status, ' : err.response.status');
            let errorMessage;
            switch (err.response.status) {
                case 409:
                    errorMessage = 'This hub is already owned by another organisation';
                    break;
                case 404:
                    errorMessage = `Hub with serial number '${this.state.serial}' and installation code '${this.state.inst}' does not exist`;
                    break;
                default:
                    errorMessage = 'Unknown Error'
            }
            self.setState({error: errorMessage})
            return Promise.reject()
        }
        console.log(createGateway.id, ' creategateway.id')
        let id = createGateway.id;
        history.push('/hub/' + id)
        self.closeModal();
    }


    checkErrors() {
        let errors = {};
        if (!this.state.name) errors['name'] = true;
        if (!this.state.serial) errors['serial'] = true
        if (!this.state.inst) errors['inst'] = true
        this.errors = errors;
    }

    render() {
        let footer =
            <Button progressRes additionalclasses={'sm'} color={'gd-green'} label={'Add Hub'}
                    onClick={this.saveChanges}/>;
        this.checkErrors();
        return (
            <GdModal
                title={"Add Hub"}
                open={this.props.open}
                footer={footer}
                onClose={this.closeModal}>
                <CardBody style={{paddingBottom: 0}}>
                    <div>
                        <div className={'row'}>
                            <Input
                                required
                                top={'true'}
                                error={this.errors['name'] && this.state.triedToSave}
                                onChange={this.onNameChange}
                                value={this.state.name}
                                name={'name'}
                                label={'Name'}
                            />
                        </div>
                        <div className={'row'}>
                            <p>Note: the
                                name of the hub should describe its location, to help with install</p>
                        </div>

                        <div className={'row'}>
                            <Input
                                required
                                error={this.errors['serial'] && this.state.triedToSave}
                                onChange={this.onSerialChange}
                                value={this.state.serial}
                                name={'serial'}
                                label={'Serial Number'}
                            />
                        </div>
                        <div className={'row tabs'} style={{marginBottom: '15px'}}>
                            <Input required
                                   error={this.errors['inst'] && this.state.triedToSave}
                                   onChange={this.onInstChange}
                                   name={'inst'}
                                   value={this.state.inst}
                                   label={'Installation Code'}
                            />
                        </div>
                        <div className={'row'} style={{margin: '15px 0'}}>
                            <GdAutocomplete error={this.errors['timezone'] && this.state.triedToSave} required
                                            name={'timezone'}
                                            value={this.state.timezone}
                                            options={this.state.timezones}
                                            onChange={this.onTimezoneChange} label="Timezone"/>
                        </div>
                        <LocationForm ref={this.locationFormRef}
                                      onLocationIdChange={(val) => this.setState({locationId: val})}
                                      onNewLocationNameChange={(val) => this.setState({newLocationName: val})}
                                      onNewLocationTimezoneChange={(val) => this.setState({newLocationTimezone: val})}
                                      onNewLocationMetadataChange={(val) => this.setState({newLocationMetaData: val})}/>
                        {this.state.error !== '' ? <div className={'row'} style={{marginBottom: '15px'}}>
                            <div>
                                <p>{this.state.error}</p>
                            </div>
                        </div> : null}
                    </div>
                </CardBody>
            </GdModal>
        )
    }
}

export default AddHubModal;
