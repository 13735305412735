import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Button, CardBody, Input, Pattern, Toast} from 'gd-react';
import './MobileLogin.scss';
import 'css-doodle'
import history from "../meta/history";
import Login from "./Login";

class MobileLogin extends Login {
    render() {
        if (this.state.loggedIn === true) {
            if (history.completeUrl && history.completeUrl !== '/') {
                return (<Redirect to={history.completeUrl}/>)
            } else {
                return (<Redirect to='/siteGroup/all'/>)
            }
        }
        let loginDiv;
        if (!this.state.mfaRequired && !this.state.mfaSetupRequired) {
            loginDiv = (
                <div style={{width: '100%'}}>
                    <Input disabled={this.state.submitting} onKeyPress={(event) => {
                        if (event.key === 'Enter') this.handleSubmit();
                    }} autoFocus error={this.state.triedToSubmit && !this.state.username.length} name="username"
                           label={'Email'} required type="text" onChange={this.handleChange}
                           value={this.state.username}/>
                    <Input disabled={this.state.submitting} onKeyPress={(event) => {
                        if (event.key === 'Enter') this.handleSubmit();
                    }} error={this.state.triedToSubmit && !this.state.password.length} name="password"
                           label={'Password'} required type="password" onChange={this.handleChange}
                           value={this.state.password}/>
                    <br/>

                    <div style={{'display': 'flex', 'flexDirection': 'row-reverse', 'justifyContent': 'center'}}>
                        <Button
                            label="Login"
                            progressRes
                            progressState={this.state.progressState}
                            onComplete={this.loggedIn}
                            onClick={this.handleSubmit}
                        />
                    </div>
                    <p className={'error-text'}>{this.state.loginError}</p>
					<div style={{'display': 'flex', 'flexDirection': 'row-reverse'}}>
						<Link style={{
							color: '#989898',
							fontSize: '13px',
							cursor: 'pointer',
							marginTop: '30px',
							paddingRight: '4px'
						}} to="/forgot">Can't remember your password?</Link>
					</div>
                </div>
            )
        } else if (this.state.mfaRequired) {
            loginDiv = (
                <div style={{width: '100%'}}>
                    <p>Multi-factor authentication is enabled for this account. <br/><br/>Please enter your
                        authentication token below.</p>
                    <Input onKeyPress={(event) => {
                        if (event.key === 'Enter') this.handleMFASubmit();
                    }} autoFocus name="mfaToken"
						   key={'mfa-token'}
                           disabled={this.state.submitting}
                           label={'MFA Token'} required type="text" onChange={this.handlMFAChange}
                           value={this.state.mfaToken}/>
                    <br/>
                    <div style={{'display': 'flex', 'flexDirection': 'row-reverse', 'justifyContent': 'center'}}>
                        <Button
                            label="Authenticate"
                            progressState={this.state.progressState}
                            progressRes
                            onClick={this.handleMFASubmit}
                        />
                    </div>
                    <p className={'error-text'}>{this.state.mfaError}</p>
                </div>
            )
        } else if (this.state.mfaSetupRequired) {
            loginDiv = (
                <div style={{width: '100%', position: 'relative', overflow: 'auto'}}>
                    <p>Multi-factor authentication (MFA) is enforced for this account's organisation.</p>
                    <p>Setup MFA by creating a new authentication profile with the QR code below on your preferred MFA
                        application, such as Google Authenticator.</p>
                    <img alt={'img'} style={{"width": "200px", "height": "200px"}} src={this.state.mfaSetupImage}/>
                    <p>Then verify that you have set it up correctly by entering in the current authentication code from
                        the app below:</p>
                    <Input style={{marginBottom: '30px'}} onKeyPress={(event) => {
                        if (event.key === 'Enter') this.handleMFASubmit();
                    }} autoFocus disabled={this.state.submitting} name={'verificationToken'}
                           onChange={this.onVerificationTokenChange} value={this.state.verificationToken}
                           label={'Authentication Code'}/>
                    <div className={'row'}>
                        <Button progressState={this.state.progressState}
                                onClick={this.verifyToken} label={'Verify Token'} progressRes
                                onComplete={() => this.setState({mfaEnabled: true, mfaSetup: false})}/>
                    </div>
                    <p className={'error-text'}>{this.state.mfaError}</p>
                </div>
            )
        }
        return (
            <div className={'mobileLoginPage'}>
                <Toast onClose={() => this.setState({showToast: null})} message={this.state.showToast}
                       open={this.state.showToast}
                       severity="success"
                       anchorOrigin={{
                           vertical: 'top',
                           horizontal: 'center',
                       }}
                />
                <div style={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'flex': 100,
                    'justifyContent': 'center',
                    'alignItems': 'center',
					'padding': '20px'
                }}>
                    <p className={'header'}>Welcome to</p>
                    <p className={'subHeader'}>{window._companyName}</p>
                    {loginDiv}
                </div>
            </div>
        );
    }

}

export default MobileLogin;
