import React from 'react';
import './ThemePicker.scss';
import '../components/KPI.scss';
import {Icon} from "gd-react";
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import {ChromePicker, SketchPicker} from "react-color";
import Popover from "@material-ui/core/Popover";

const themes = [
    {
        icon: 'FaSun',
        code: 'light',
        title: 'Light'
    },
    {
        icon: 'FaMoon',
        code: 'dark',
        title: 'Dark'
    }
]

let paletteColours = [
    {
        code: 'primaryAccent',
        name: 'Buttons and input borders'
    },
    {
        code: 'navBackground',
        name: 'Navigation background'
    },
    {
        code: 'fontColourNav',
        name: 'Navigation text'
    },
    {
        code: 'border',
        name: 'Borders'
    },
    {
        code: 'listItemHover',
        name: 'Navigation list item hover'
    },
    {
        code: 'listItemActive',
        name: 'Navigation list item selected'
    },
    {
        code: 'mainListItemHover',
        name: 'Main list item hover'
    }
]


class ThemePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openThemePicker: false,
            update: 0
        }
        this.closeThemePicker = this.closeThemePicker.bind(this);
        this.carouselRef = React.createRef();
        this.themes = this.props.themes || [];
        let defaultIndex = this.themes.findIndex(t => t.code === 'default');
        let customItem = this.themes.find(t => t.code === 'custom');
        let customIndex = this.themes.findIndex(t => t.code === 'custom');
        if (customItem) {
            this.themes.splice(customIndex, 1)
        }
        let defaultItem = this.themes.find(t => t.code === 'default');
        this.themes.splice(defaultIndex, 1)
        if (window._defaultThemeOverride) {
            this.themes.unshift(window._defaultThemeOverride);
        } else {
            this.themes.unshift(defaultItem);
        }
        console.log(this.themes, ' : themes');
        let customPalette = this.props.userTheme || this.themes[0].palette;
        this.themes.push({
            code: 'custom',
            name: 'Custom',
            palette: customPalette
        });
        this.moveToSlide = this.moveToSlide.bind(this);
        this.selectTheme = this.selectTheme.bind(this);
    }

    componentDidMount() {
        this.moveToSlide(this.props.theme, true);
    }

    moveToSlide(theme, initialLoad) {
        let carousel = this.carouselRef.current ? this.carouselRef.current.swiper : null;
        let themes = this.themes;
        if (carousel) {
            let activeTheme = themes.findIndex(t => theme === t.code);
            if (!initialLoad || (initialLoad && activeTheme)) {
                carousel.slideTo(activeTheme, 0);
            }
        }
    }

    closeThemePicker() {
        if (this.props.closePicker) this.props.closePicker();
    }

    selectTheme(theme) {
        theme.code === 'custom' ? this.props.updateCustomTheme(theme) : this.props.selectTheme(theme)
        this.moveToSlide(theme.code);
    }

    render() {
        console.log('themes: ', this.themes);
        console.log('theme: ', this.props.theme);
        let self = this;

        return (
            <div
                className={'theme-picker ' + (this.state.openThemePicker ? 'open' : '') + (this.props.mobile ? 'on-mobile' : '')}>
                <div className={'upper-section'}>
                    <div className={'header-bar-theme'}>
                        <p>Themes</p>
                        <Icon size={'15'} icon={'FaTimes'} onIconClick={this.closeThemePicker}>Close</Icon>
                    </div>
                    <div className={'theme-previews'}>
                        <Swiper
                            centeredSlides
                            loop
                            ref={this.carouselRef}
                            navigation={true} modules={[Navigation]}
                            slidesPerView={'auto'}>
                            {this.themes.map(t => {
                                return <SwiperSlide key={t.code} style={{width: 'auto'}}>
                                    <div className={'theme-preview'}>
                                        <div
                                            onClick={() => self.selectTheme(t)}
                                            className={'preview-window'}
                                            style={{borderColor: this.props.theme === t.code ? t.palette[this.props.darkLight].primaryAccent : t.palette[this.props.darkLight].border}}>
                                            <div style={{
                                                backgroundColor: t.palette[this.props.darkLight].navBackground,
                                                borderColor: t.palette[this.props.darkLight].border
                                            }}
                                                 className={'preview-left-nav'}/>
                                            <div className={'preview-main'}>
                                                <div
                                                    style={{
                                                        backgroundColor: t.palette[this.props.darkLight].navBackground,
                                                        borderColor: t.palette[this.props.darkLight].border
                                                    }}
                                                    className={'preview-header'}/>
                                                <div className={'preview-widget-wrapper'}>
                                                    <div className={'preview-widget'}
                                                         style={{borderColor: t.palette[this.props.darkLight].border}}>
                                                        <div
                                                            style={{backgroundColor: t.palette[this.props.darkLight].primaryAccent}}
                                                            className={'preview-dot'}/>
                                                    </div>
                                                    <div className={'preview-widget'}
                                                         style={{borderColor: t.palette[this.props.darkLight].border}}>
                                                        <div
                                                            style={{backgroundColor: t.palette[this.props.darkLight].primaryAccent}}
                                                            className={'preview-dot'}/>
                                                    </div>
                                                    <div className={'preview-widget'}
                                                         style={{borderColor: t.palette[this.props.darkLight].border}}>
                                                        <div
                                                            style={{backgroundColor: t.palette[this.props.darkLight].primaryAccent}}
                                                            className={'preview-dot'}/>
                                                    </div>
                                                    <div className={'preview-widget'}
                                                         style={{borderColor: t.palette[this.props.darkLight].border}}>
                                                        <div
                                                            style={{backgroundColor: t.palette[this.props.darkLight].primaryAccent}}
                                                            className={'preview-dot'}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'preview-label'}>
                                            <p>{t.name}</p>
                                            {this.props.theme === t.code ?
                                                <Icon icon={'FaCheck'} size={'12'}/> : null}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            })}
                        </Swiper>
                    </div>
                </div>
                <div className={'palette-colours-wrapper'}>
                    <div className={'palette-colours'}>
                        {themes.map(t => {
                            return <div className={'theme-palette ' + t.code} key={t.code}>
                                <p className={'theme-title'}>{t.title} Mode Colours</p>
                                {paletteColours.map((pc) => {
                                    let activeTheme = this.themes.find((t) => this.props.theme === t.code);
                                    console.log(activeTheme, ' : active theme');
                                    return <div className={'palette-colour'} key={pc.code + t.code}>
                                        <div className={'colour-preview'}
                                             onClick={(e) => {
                                                 console.log(pc.code, ' : pc.code');
                                                 if (activeTheme.code !== 'custom') {
                                                     let customTheme = this.themes.find((t) => 'custom' === t.code);
                                                     this.selectTheme(customTheme);
                                                 } else {
                                                     console.log('show picker')
                                                     this.setState({
                                                         showPicker: (pc.code + '' + t.code),
                                                         anchorEl: e.currentTarget
                                                     });
                                                 }
                                             }}
                                             style={{backgroundColor: activeTheme.palette[t.code][pc.code]}}/>
                                        <Popover
                                            id={'color-picker-' + activeTheme.palette[t.code]}
                                            disablePortal
                                            open={this.state.showPicker === (pc.code + '' + t.code)}
                                            anchorEl={this.state.anchorEl}
                                            onClose={() => this.setState({showPicker: null})}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}>
                                            <ChromePicker
                                                color={activeTheme.palette[t.code][pc.code]}
                                                onChangeComplete={(color) => {
                                                    activeTheme.palette[t.code][pc.code] = color.hex;
                                                    self.setState({update: +1}, () => {
                                                        self.props.updateCustomTheme(t)
                                                    });
                                                }}
                                            /> </Popover>
                                        <p>{pc.name}</p>
                                    </div>
                                })}
                            </div>
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default ThemePicker;