import React from "react";
import GridDuck from "gridduck";
import history from "../../meta/history";
import * as _ from 'underscore';
import moment from "moment";
import {
    LastActiveCell,
    CollapsibleTable,
    Icon,
    Pagination,
    Menu,
    Input,
    Tooltip,
    GdCheckbox,
    DataTypes,
    Dropdown,
    Loader,
    Button,
    DateTimeRangePicker
} from "gd-react";
import GenericLoader from "../../components/GenericLoader";
import cookie from "react-cookies";
import SubscriptionComponent from "./OrgMgmtTools/DisplaySubscriptions";
import DetailsPopover from "./OrgMgmtTools/DetailsPopover";
import './OrganisationManagement.scss';
import getFormat from "../../services/formatter";

class OrganisationManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [
                {
                    field: 'getAllGlobally',
                    value: true
                }
            ],
            organisations: null,
            pageNum: 1,
            itemsPerPage: 25,
            editingIsFree: false,
            exhibitionOnly: false,
            onlyWithDevices: false,
            noExhibition: true,
            paidOnly: false,
            includeInternal: false,
            dateRange: {},
            dataTypes: [],
            dataType: null,
            lockedOnly: false
        };
        this.loading = false;
        this.getList = this.getList.bind(this);
        this.getUsage = this.getUsage.bind(this)
        this.editFreePaid = this.editFreePaid.bind(this);
        this.editLocked = this.editLocked.bind(this);
        this.loginAsUser = this.loginAsUser.bind(this);
        this.getDataTypes = this.getDataTypes.bind(this)
        this.getList();
        this.handleInputChange = this.handleInputChange.bind(this)
        this.debouncedHandleInputChange = this.debounce(this.debouncedChange.bind(this), 500);
    }

    componentDidMount() {
        this.getDataTypes()
    }

    onFormChange(val) {
        this.setState({emailAddress: val.target.value});
    }

    async editFreePaid(organisation, free) {
        await organisation.set({id: organisation.id, free: free});
        this.getList();
    }

    async editLocked(organisation, locked) {
        await organisation.set({id: organisation.id, account_locked: locked});
        this.getList();
    }

    debounce(func, waitTime) {
        let timeout;
        return (...args) => {
            // everytime this is called on keystroke, it wipes the current timer,
            // which may not have executed, and establishes another. eventually when the
            //  keystrokes end, one of these prop funcs will execute
            clearTimeout(timeout)
            timeout = setTimeout(() => func(...args), waitTime)
        }
    }

    convertDataTypeToFormatterString(dataType) {
        switch (dataType) {
            case 'DELIVERED':
                return 'delivered'
            case 'DELIVERED_WATER_HOT':
                return 'waterPulse';
            case 'DELIVERED_GAS':
                return 'gasPulse'
            case 'DELIVERED_GAS_SPEND':
                return 'spend';
            case 'GAS_ENERGY_DELIVERED':
                return 'delivered';
            case 'GAS_ENERGY_DELIVERED_SPEND':
                return 'spend';
            case 'GENERATED_DELIVERED':
                return 'delivered';
            case 'DELIVERED_WATER':
                return 'waterPulse';
            case 'DELIVERED_SPEND':
                return 'spend';
            case 'DELIVERED_WATER_SPEND':
                return 'spend';
            case 'DELIVERED_WATER_HOT_SPEND':
                return 'spend';
            case 'EXPORTED_DELIVERED':
                return 'delivered';
            default:
                return '';
        }
    }

    getDataTypes() {
        let dataTypes = DataTypes.filter((dt) => dt.graphType === 'bar').map((dt) => {
            return {
                title: dt.label + ' (' + dt.unit + ')' + ' - ' + dt.id,
                value: dt.id,
            }
        })
        this.setState({dataTypes: dataTypes})
    }

    handleInputChange(val) {
        let value = val.target.value
        this.debouncedHandleInputChange(value)
    }

    debouncedChange(value) {
        this.setState({
            search: value,
            loaded: false
        }, this.getList)
    }

    generateUsageHTML(organisation) {
        if (!organisation.usage) {
            return <Button
                onClick={() => this.getUsage(organisation)}
                disabled={!this.state.dateRange.start || !this.state.dataType}
                label={"Calculate"}
                color={"gd-brand"}
            />
        } else if (organisation.usage === 'loading') {
            return <div
            ><Loader type={'circular'} size={20}/></div>
        } else {
            return <div>{organisation.usage}</div>
        }
    }

    async getUsage(organisation) {
        if (!this.state.dateRange.start || !this.state.dataType) return
        console.log(this.state.dataType, ' data type in getusage')
        let list = this.state.organisations
        let element = list.find((org) => org.id === organisation.id)
        element.usage = 'loading';
        this.setState({organisations: list})
        const start = Math.round(new Date(this.state.dateRange.start).getTime() / 1000)
        const end = Math.round(new Date(this.state.dateRange.end).getTime() / 1000)
        let granularity = (end - start)
        let data = await GridDuck.getAssetConsumptionBatchs({
            filters: [
                {
                    field: 'organisationId',
                    value: organisation.id
                },
                {
                    field: 'assetId',
                    value: 'filtered'
                },
                {
                    field: 'dataType',
                    value: this.state.dataType
                },
                {
                    field: 'start',
                    value: start
                },
                {
                    field: 'end',
                    value: end
                },
                {
                    field: 'granularity',
                    value: granularity
                }
            ]
        });

        let assetsList = data.list;

        let total = this.generateTotals(assetsList);
        let display_value = getFormat(this.convertDataTypeToFormatterString(this.state.dataType))(total)
        element.usage = display_value
        this.setState({organisations: list})
    }

    generateTotals(assets) {

        // for each asset in the set, add all of the telemetry.consumption values together
        let totalValue = 0;
        assets.forEach((asset) => {
            asset.telemetry.forEach((datum) => {
                if (datum.consumption || datum.consumption === 0) {
                    totalValue += datum.consumption;
                }
            })
        });
        return totalValue;
    }

    getList(pageNum) {
        if (this.loading) return
        this.setState({loaded: false})
        this.loading = true
        let self = this;
        let filters = [];
        if (this.state.exhibitionOnly) {
            filters.push({
                field: 'exhibitionOnly',
                value: true
            });
        }
        if (this.state.onlyWithDevices) {
            filters.push({
                field: 'onlyWithDevices',
                value: true
            });
        }
        if (this.state.noExhibition) {
            filters.push({
                field: 'noExhibition',
                value: true
            });
        }
        if (this.state.paidOnly) {
            filters.push({
                field: 'paidOnly',
                value: true
            });
        }
        if (this.state.includeInternal) {
            filters.push({
                field: 'includeInternal',
                value: true
            });
        }
        if (this.state.lockedOnly) {
            filters.push({
                field: 'lockedOnly',
                value: true
            });
        }
        if (this.state.search) {
            filters.push({
                field: 'search',
                value: this.state.search
            });
        }
        GridDuck.getOrganisationManagements({
            filters: filters,
            items: this.state.itemsPerPage,
            offset: (pageNum - 1) * this.state.itemsPerPage
        }).then(function (res) {
            let sortedOrgList = _.groupBy(res.list, (org) => {
                return !!org.lastActive
            });
            let list = sortedOrgList['true']
            if (sortedOrgList['false']) {
                list = [...sortedOrgList['true'], ...sortedOrgList['false']]
            }
            self.setState({organisations: list, total: res.total, loaded: true}, () => self.loading = false)
        });
    }

    loginAsUser(email) {
        return GridDuck.createLoginAs({username: email})
            .then(function (loginAs) {
                let accessToken = {
                    expires: loginAs.expires,
                    token: loginAs.token,
                    mfaRequired: loginAs.mfaRequired,
                    scope: loginAs.scope,
                    tokenType: loginAs.tokenType,
                    user: {id: loginAs.user.id}
                };
                GridDuck.setAccessToken({
                    accessToken
                });
                delete accessToken.scope;
                cookie.save('accessToken', accessToken, {path: '/'});
                history.push("/")
                window.location.reload();
            });
    }

    render() {
        let self = this;
        return (
            <div className='page Account not-flex'>
                <div className={'row'} style={{padding: '10px 20px'}}>
                    <div className={'column'}>
                        <div className={'kpi-value'}><strong>{this.state.total}</strong> Organisations</div>
                    </div>
                </div>
                <div style={{padding: '10px 20px'}}>
                    <GdCheckbox checked={this.state.exhibitionOnly}
                                name={'exhibitionOnly'}
                                onChange={(val) => this.setState({
                                    exhibitionOnly: val.target.checked,
                                    organisations: null,
                                    loaded: false
                                }, this.getList)}
                                label={'Only show Exhibition Sign ups'}
                    />
                    <GdCheckbox checked={this.state.onlyWithDevices}
                                name={'exhibitionOnly'}
                                onChange={(val) => this.setState({
                                    onlyWithDevices: val.target.checked,
                                    organisations: null,
                                    loaded: false
                                }, this.getList)}
                                label={'Only with devices'}
                    />
                    <GdCheckbox checked={this.state.noExhibition}
                                name={'exhibitionOnly'}
                                onChange={(val) => this.setState({
                                    noExhibition: val.target.checked,
                                    organisations: null,
                                    loaded: false
                                }, this.getList)}
                                label={'No Exhibition'}
                    />
                    <GdCheckbox checked={this.state.paidOnly}
                                name={'paidOnly'}
                                onChange={(val) => this.setState({
                                    paidOnly: val.target.checked,
                                    organisations: null,
                                    loaded: false
                                }, this.getList)}
                                label={'Paid Only'}
                    />
                    <GdCheckbox checked={this.state.includeInternal}
                                name={'includeInternal'}
                                onChange={(val) => this.setState({
                                    includeInternal: val.target.checked,
                                    organisations: null,
                                    loaded: false
                                }, this.getList)}
                                label={'Include Internal Organisations'}
                    />
                    <DateTimeRangePicker pastOnly={true}
                                         placement={'bottomEnd'}
                                         label={'Usage Date Range'}
                                         top={'true'}
                                         value={this.state.dateRange && this.state.dateRange.start && this.state.dateRange.end ? [this.state.dateRange.start, this.state.dateRange.end] : null}
                                         onChange={(val) => {
                                             this.setState({dateRange: val})
                                         }}/>
                    <div className={'row'} style={{marginBottom: '15px', alignItems: 'center'}}>
                        <Dropdown label={'Data Type'}
                                  placeholder={'Select Data Type'}
                                  onChange={(e) => {
                                      this.setState({dataType: e.target.value})
                                  }}
                                  fixeditems={this.state.dataTypes}
                                  value={this.state.dataType}/>
                    </div>
                    <GdCheckbox checked={this.state.lockedOnly}
                                name={'lockedOnly'}
                                onChange={(val) => this.setState({
                                    lockedOnly: val.target.checked,
                                    organisations: null,
                                    loaded: false
                                }, this.getList)}
                                label={'Only locked out'}
                    />
                </div>
                <div className={'search-input-wrapper boxy'}>
                    <Input label={''}
                           nolabel='true'
                           placeholder={'Search'}
                           InputProps={{
                               startAdornment: (
                                   <div className={'input-icon'}>
                                       <Icon color={'grey'} size={'10'} icon={'FaSearch'}/>
                                   </div>)
                           }}
                           onChange={this.handleInputChange}/>
                </div>
                {this.state.loaded ? <div className={'column top'}>
                    <CollapsibleTable columns={{
                        outerColumns: [
                            {title: 'Organisation'},
                            {title: 'Sites'},
                            {title: 'Devices'},
                            {title: 'Active Devices'},
                            {title: 'Usage'},
                            {title: 'Subs'},
                            {title: 'Sub Items'},
                            {title: 'Total Sub Value'},
                            {title: 'Completed Signup'},
                            {title: 'Free/Paid'},
                            {title: 'Locked (unable to login)'}
                        ],
                        innerColumns: [{title: 'Email Address'}, {title: 'Access Level'}, {title: 'Last Active'}]
                    }} tableData={
                        _.map(self.state.organisations, function (organisation) {
                            console.log(organisation, ' : organisation');
                            let subItems = 0;
                            organisation.subscriptions.forEach(function (subscription) {
                                subscription.items.data.forEach(function (item) {
                                    subItems += item.quantity;
                                })
                            });

                            const totalSubscriptionValue = organisation.subscriptions.reduce((total, subscription) => {
                                const subscriptionTotal = subscription.items.data.reduce((subTotal, item) => {
                                    return subTotal + (item.price.unitAmount ? item.price.unitAmount * item.quantity : 0);
                                }, 0);
                                return total + subscriptionTotal;
                            }, 0);

                            let innerData = organisation.users?.map(function (user) {
                                return [
                                    {
                                        value: <div style={{display: 'flex'}}><Tooltip
                                            label={'Login as...'}><Icon
                                            onIconClick={() => self.loginAsUser(user.username)}
                                            icon={'FaKey'} size={'12'} color={'gd-blue'}/></Tooltip><p
                                            style={{margin: '0 10px'}}>{user.username}</p></div>
                                    },
                                    {value: <div style={{display: 'flex'}}>{user.orgPermission}</div>},
                                    {
                                        value: <div style={{display: 'flex'}}><LastActiveCell comparisonOnly
                                                                                              onlineStatus
                                                                                              value={user.lastActive}
                                                                                              object={user}/></div>
                                    }
                                ];
                            });
                            return {
                                id: organisation.id,
                                data: [
                                    {
                                        value: <a target={'_blank'}
                                                  href={'https://dashboard.stripe.com/customers/' + organisation.stripeId}>{organisation.name}</a>
                                    },
                                    {value: <div>{organisation.sites.length}</div>},
                                    {value: <div>{organisation.appliances}</div>},
                                    {value: <div>{organisation.appliances - organisation.issues}</div>},
                                    {
                                        value: self.generateUsageHTML(organisation)
                                    },
                                    {
                                        value: <div className={'clickable link'} onClick={(e) => self.setState({
                                            details: SubscriptionComponent(organisation),
                                            anchorEl: e.currentTarget
                                        })}>{organisation.subscriptions.length}</div>
                                    },
                                    {value: <div>{subItems}</div>},
                                    {value: <div>£{(totalSubscriptionValue / 100).toFixed(2)}</div>},
                                    {value: organisation.acceptedTerms ? 'Yes' : 'No'},
                                    {
                                        value: <div>{organisation.free ? 'Free' : 'Paid'} <Menu
                                            menuHeader={{title: 'Switch'}}
                                            controlFromElement={<Icon icon={'FaEdit'} size={'12'}
                                                                      color={'gd-blue'}/>}
                                            menuItems={[
                                                {
                                                    icon: {
                                                        color: 'gd-grey',
                                                        name: 'FaCheck',
                                                        size: '12'
                                                    },
                                                    label: 'Free',
                                                    onMenuItemClick: function () {
                                                        self.editFreePaid(organisation, true);
                                                    }
                                                },
                                                {
                                                    icon: {
                                                        color: 'gd-grey',
                                                        name: 'FaTimes',
                                                        size: '12'
                                                    },
                                                    label: 'Paid',
                                                    onMenuItemClick: function () {
                                                        self.editFreePaid(organisation, false);
                                                    }
                                                }
                                            ]}/></div>
                                    },
                                    {
                                        value: <div>{organisation.accountLocked ? 'Locked' : '-'} <Menu
                                            controlFromElement={<Icon icon={'FaEdit'} size={'12'}
                                                                      color={'gd-blue'}/>}
                                            menuItems={[
                                                {
                                                    icon: {
                                                        color: 'gd-grey',
                                                        name: 'FaCheck',
                                                        size: '12'
                                                    },
                                                    label: `${organisation.accountLocked ? 'Unlock' : 'Lock'}`,
                                                    onMenuItemClick: function () {
                                                        self.editLocked(organisation, !organisation.accountLocked);
                                                    }
                                                }
                                            ]}/></div>
                                    }
                                ],
                                innerData: innerData
                            }
                        })
                    }/>
                    <DetailsPopover anchorEl={this.state.anchorEl} details={this.state.details}
                                    onClose={() => this.setState({details: null, anchorEl: null})}/>
                </div> : <div style={{
                    display: 'flex',
                    flex: '1 1',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}><GenericLoader text={'Fetching...'} textLineTwo={'Organisations'}/></div>}
                {/*{this.state.editingIsFree ? <Modal*/}
                {/*    isOpen={this.state.editingIsFree}*/}
                {/*    onRequestClose={()=>this.setState({editingIsFree: false})}*/}
                {/*    contentLabel={"Set " + this.state.orgToEdit.name + " to " + (this.state.orgToEdit.free ? 'Paid' : 'Free')}>*/}
                {/*    {"Set " + this.state.orgToEdit.name + " to " + (this.state.orgToEdit.free ? 'Paid' : 'Free')}*/}
                {/*</Modal> : null}*/}
                <div className={'fixed-footer'}>
                    <Pagination pageNum={this.state.pageNum}
                                pages={Math.max(1, Math.ceil(this.state.total / this.state.itemsPerPage))}
                                updateList={this.getList}/>
                </div>

            </div>
        )
    }
}

export default OrganisationManagement;
