import {Dropdown, GdAutocomplete, Icon, Input, Toast, Tooltip} from "gd-react";
import React from "react";
import './TimeBar.scss'
import TimeBarSegment from "./TimeBarSegment";

class TimeBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }


    //0,0 would be 0
    //0, 30 would be 1
    //2, 30 would be 5

    //index = (start_hour) * 2 + (start_minute === '30' ? 1 : 0)

    [{"start_hour": 0, "start_minute": 0, "end_hour": 5, "end_minute": 30, "rate": 36}]

    render() {

        let complete = false;

        if ((this.props.rate.startHour) && (this.props.rate.startMinute) && (this.props.rate.endHour) && (this.props.rate.endMinute)) complete = true;

        let startIndex;

        let endIndex;

        let segments = []

        let startHour = this.props.rate.startHour
            let startMinute = this.props.rate.startMinute
            let endHour = this.props.rate.endHour
            let endMinute = this.props.rate.endMinute
            startIndex = (startHour * 2) + (startMinute === 30 ? 1 : 0)
            endIndex = (endHour * 2) + (endMinute === 30 ? 1 : 0)

        for (let i = 0; i < this.props.increments; i++) {
            let active = true;
            if (startIndex < endIndex) active = (startIndex <= i && endIndex > i);
            else if (startIndex > endIndex) active = (i < endIndex ||  i >= startIndex);
            segments.push(<TimeBarSegment key={i} active={active}/>)
        }

        // if (complete) {
        //     let startHour = this.props.rate.startHour
        //     let startMinute = this.props.rate.startMinute
        //     let endHour = this.props.rate.endHour
        //     let endMinute = this.props.rate.endMinute
        //     startIndex = (startHour * 2) + (startMinute === 30 ? 1 : 0)
        //     endIndex = (endHour * 2) + (endMinute === 30 ? 1 : 0)
        //
        //     if (startIndex < endIndex) {
        //         //within a single day
        //         for (let i = 0; i < this.props.increments; i++) {
        //             let active = false;
        //             if (startIndex <= i && endIndex > i) {
        //                 active = true
        //             }
        //             segments.push(<TimeBarSegment key={i} active={active}/>)
        //         }
        //     } else if (startIndex > endIndex) {
        //     //    tariff runs over night
        //         for (let i = 0; i < this.props.increments; i++) {
        //             let active = false;
        //             if (i < endIndex ||  i >= startIndex) {
        //                 active = true
        //             }
        //             segments.push(<TimeBarSegment key={i} active={active}/>)
        //         }
        //     } else {
        //     //    start and end are equal, tariff runs all 24 hours
        //         for (let i = 0; i < this.props.increments; i++) {
        //             let active = true;
        //             segments.push(<TimeBarSegment key={i} active={active}/>)
        //         }
        //     }
        // } else {
        //     for (let i = 0; i < this.props.increments; i++) {
        //         let active = true;
        //         segments.push(<TimeBarSegment key={i}/>)
        //     }
        // }


        return (
            <div className={'container'}>
                {segments}
            </div>
        )
    }
}

export default TimeBar;