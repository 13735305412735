import React from "react";
import GridDuck from "gridduck";
import {Button, Card, CardBody, CardHeader} from "gd-react";
import ManageApplication from "../../modals/ManageApplication/ManageApplication";
import AddApplicationModal from "../../modals/AddApplication/AddApplicationModal";
import GenericLoader from "../../components/GenericLoader";
import './DeveloperPage.scss'

class DeveloperPage extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        this.state = {
            loaded: false
        };
        GridDuck.getApplications({getAll: true})
            .then(function (applicationList) {
                self.list = applicationList;
                self.list.on('updated', function () {
                    self.setState({applications: applicationList.list});
                });
                self.setState({applications: applicationList.list, loaded: true});
            });
        this.createApplication = this.createApplication.bind(this);
        this.getSecret = this.getSecret.bind(this);
    }

    createApplication() {
        this.setState({createApplication: true});
    }

    getSecret(application) {
        this.setState({viewSecret: application});
    }

    render() {
        let applications = [];
        let self = this;
        if (this.state.applications) {
            this.state.applications.forEach(function (application, i) {
                let grants = application.allowedGrants.map(
                    g => (<span key={g + 'g' + application.id}>{g}</span>)
                );
                let scopes = application.scopes.map(
                    g => (<span key={g + 'g2' + application.id}>{g}</span>)
                );
                applications.push(
                    <div className={'column'} key={i + 'c'}>
                        <div className={'detail-content'} key={i + 'dc'}>
                            <Card>
                                <CardHeader title={application.clientName}>
                                    <div style={{display: 'inline-block', float: 'right'}}>
                                        <Button color={'gd-grey'}
                                                label={"Manage"}
                                                onClick={() => self.getSecret(application)}
                                        />
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    {grants && grants.length ? <div className={"row"}>
                                        <p className={'app-info'}>{grants}</p>
                                    </div> : ''}
                                    <div className={"row"}>
                                        <p className={'app-info-title'}>Scope{scopes.length > 1 ? 's' : ''}</p>
                                    </div>
                                    <div className={"row"}>
                                        <p className={'app-info'}> {scopes}</p>
                                    </div>
                                    <div className={"row"}>
                                        <p className={'app-info-title'}>Client ID</p>
                                    </div>
                                    <div className={"column"}>
                                        <p className={'app-info'}>{application.id}</p>
                                    </div>

                                </CardBody>
                            </Card>
                        </div>
                    </div>
                )
            })
        }
        return (
            <div className={"page grey not-flex narrow developer"}>
                <p className={"notice"}>
                    Applications allow your systems complete access to the <a href={"https://v2.api.gridduck.com/"}
                                                                              target={"_blank"}>GridDuck API</a> using
                    OAuth2 Authorization.<br/>
                    Refer to the <a href={"https://gridduck.com/developer"} target={"_blank"}>Getting Started</a> guide
                    for help.
                </p>

                <div className={'row reverse'} style={{alignItems: 'center', justifyContent: 'center', marginBottom: '30px'}}>
                    <Button
                        label={"Create Application"}
                        onClick={() => self.setState({createApplication: true})}
                    />
                </div>
                {this.state.loaded ? applications : <GenericLoader/>}
                {this.state.loaded && !applications.length ? <div className={'column'}>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader title={'You have no applications yet'}/>
                            <CardBody>
                                <div className={"row"}>
                                    <Button
                                        label={"Create Application"}
                                        onClick={() => self.setState({createApplication: true})}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div> : ''}

                {this.state.viewSecret ?
                    <ManageApplication open={!!(this.state.viewSecret)} application={this.state.viewSecret}
                                       onClose={() => this.setState({viewSecret: null})}/> : ''}
                {this.state.createApplication ? <AddApplicationModal open={!!(this.state.createApplication)}
                                                                     onClose={() => this.setState({createApplication: false})}/> : ''}


            </div>
        );
    }

}

export default DeveloperPage;
