import React from "react";
import "./AssetOverviewPage.scss";
import {
    Button,
    Card,
    CardBody,
    CardHeader, DemandListCell,
    Dropdown,
    Input,
    Loader,
    List,
    StateOptions,
    Icon,
    StandardListCell,
    StateListCell,
    StateSelectMenu, SensorDataListCell
} from "gd-react";
import getFormat from "../../services/formatter";
import GridDuck from "gridduck";
import history from "../../meta/history";
import RulePageTimeswitch from "../rule/RulePageTimeswitch";
import RulePageAlert from "../rule/RulePageAlert";
import RulePageThermostat from "../rule/RulePageThermostat";
import AssetOverviewPage from "./AssetOverviewPage";
import RulePageAdvanced from "../rule/RulePageAdvanced";
import VIRTUAL_PARENT from "../../images/products/VIRTUAL-PARENT.png"
import IssuePopover from "../../components/IssuePopover/IssuePopover";

const toCamel = (s) => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
};

const lineDevices = [
    'SEN-POW-OWON',
    'SEN-POW-OWON-OLD',
]

const channelDevices = [
    'BLE_PULSE_DELIVERED',
    'BLE_PULSE_DELIVERED_GAS',
    'BLE_PULSE_DELIVERED_WATER',
    'BLE_PULSE_DELIVERED_WATER_HOT',
    'NB-IOT-PULSE_DELIVERED',
    'NB-IOT-PULSE_DELIVERED_GAS',
    'NB-IOT-PULSE_DELIVERED_WATER',
    'NB-IOT-PULSE_DELIVERED_WATER_HOT'
]

function skuDetails(sku) {
    let type = StateOptions.find(sO => sO.type === sku);
    if (type) {
        return {
            name: type.label,
            image: '/images/products/' + sku + '.png'
        }
    } else {
        return {
            name: 'Unknown',
            image: '/images/products/unknown.png'
        }
    }
}

class DesktopAssetOverviewPage extends AssetOverviewPage {
    render() {
        let summary;
        let realTime;
        let phaseBreakdown;
        let rules;
        let ruleComponents = [];
        let family;
        let self = this;
        let isPulse = channelDevices.indexOf(this.state.asset.sku) !== -1
        let isLine = lineDevices.indexOf(this.state.asset.sku) !== -1
        let isGeneration = this.state.asset.generation;
        let realTimeData = [];
        let states = StateOptions.find(s => s.type === this.state.asset.sku);
        if (!states) {
            states = StateOptions.find(s => s.type === 'default');
        }
        let dataTypes = states.dataTypes;
        if (this.state.asset.lastContacted) {
            realTimeData.push(
                <p className={"asset-data"}><span
                    className={"heading"}>Last contacted:&nbsp;</span>{getFormat('lastContacted')(self.state.asset.lastContacted)}
                </p>
            )
        }
        if (this.state.asset.serialNo) {
            realTimeData.push(
                <p className={"asset-data"}><span
                    className={"heading"}>Serial number:&nbsp;</span>{self.state.asset.serialNo}
                </p>
            )
        }
        dataTypes.forEach(function (dataType) {
            if (self.state.asset[toCamel(dataType.type.toLowerCase())] !== undefined) {
                if (dataType.type === 'NB_IOT_ECL') {
                    let signalIcon = (
                        <Icon icon='MdSignalCellular1Bar' size={'14'}/>
                    );
                    if (self.state.asset[toCamel(dataType.type.toLowerCase())] === 1) {
                        signalIcon = (<Icon icon='MdSignalCellular2Bar' size={'14'}/>)
                    }
                    if (self.state.asset[toCamel(dataType.type.toLowerCase())] === 0) {
                        signalIcon = (<Icon icon='MdSignalCellular4Bar' size={'14'}/>)
                    }
                    realTimeData.push(
                        <p className={"asset-data"}>
                            <span className={"heading"}>{dataType.label}:&nbsp;</span>
                            {signalIcon}
                        </p>
                    )
                } else if (dataType.type === 'ASSET_BATTERY_ALARM') {
                    if (self.state.asset[toCamel(dataType.type.toLowerCase())]) {
                        realTimeData.push(
                            <p className={"asset-data"}><span class={"warning"}>Warning! Low Battery</span>
                            </p>
                        )
                    }
                } else {
                    realTimeData.push(
                        <p className={"asset-data"}><span
                            className={"heading"}>{dataType.label}:&nbsp;</span>{getFormat(toCamel(dataType.type.toLowerCase()), false, states.connection)(self.state.asset[toCamel(dataType.type.toLowerCase())])}
                        </p>
                    )
                }
            }
        })
        if (this.state.asset.sku === 'SEN-POW-OWON' || this.state.asset.sku === 'SEN-POW-OWON-OLD') {
            phaseBreakdown = (
                <div className={'column'}>
                    <div className={'detail-content'}>
                        <Card className={"real-time"}>
                            <CardHeader title={"Phase breakdown"}>
                            </CardHeader>
                            <CardBody>
                                <div className={"phase-button"}>
                                    <Button color={'gd-blue'} label={"Get Current Phase Breakdown"}
                                            onClick={this.getPhaseBreakdown} progressRes/>
                                </div>
                                {!self.state.gettingPhaseOne && self.state.asset.demandPhaseOne !== undefined ?
                                    <p className={"asset-data"}><span
                                        className={"heading"}>L1 Demand:&nbsp;</span>{getFormat('consumption')(self.state.asset.demandPhaseOne)}
                                    </p> : ''}
                                {!self.state.gettingPfOne && self.state.asset.pfPhaseOne !== undefined ?
                                    <p className={"asset-data"}><span
                                        className={"heading"}>L1 Power Factor:&nbsp;</span>{self.state.asset.pfPhaseOne}
                                    </p> : ''}
                                {!self.state.gettingPhaseTwo && self.state.asset.demandPhaseTwo !== undefined ?
                                    <p className={"asset-data"}><span
                                        className={"heading"}>L2 Demand:&nbsp;</span>{getFormat('consumption')(self.state.asset.demandPhaseTwo)}
                                    </p> : ''}
                                {!self.state.gettingPfTwo && self.state.asset.pfPhaseTwo !== undefined ?
                                    <p className={"asset-data"}><span
                                        className={"heading"}>L2 Power Factor:&nbsp;</span>{self.state.asset.pfPhaseTwo}
                                    </p> : ''}
                                {!self.state.gettingPhaseThree && self.state.asset.demandPhaseThree !== undefined ?
                                    <p className={"asset-data"}><span
                                        className={"heading"}>L3 Demand:&nbsp;</span>{getFormat('consumption')(self.state.asset.demandPhaseThree)}
                                    </p> : ''}
                                {!self.state.gettingPfThree && self.state.asset.pfPhaseThree !== undefined ?
                                    <p className={"asset-data"}><span
                                        className={"heading"}>L3 Power Factor:&nbsp;</span>{self.state.asset.pfPhaseThree}
                                    </p> : ''}
                            </CardBody>
                        </Card>
                    </div>
                </div>
            )
        }
        if (this.state.asset) {
            let imageStyle = {
                backgroundImage: `url(${skuDetails(this.state.asset.sku).image})`
            }
            summary = (<div className={"top-summary"}>
                {this.state.asset.sku === 'INITIALISING_SKU' ? <div className={"left-image"} style={{paddingLeft: '20px'}}><Loader type={'circular'}/></div> : <div className={"left-image"} style={imageStyle}>
                </div>}
                <div className={"right-summary"}>
                    <p className={"asset-data"}><span
                        className={"heading"}>Device/circuit:&nbsp;</span>{this.state.asset.name}</p>
                    <p className={"asset-data"}><span
                        className={"heading"}>Device:&nbsp;</span>{skuDetails(this.state.asset.sku).name}</p>
                    {this.state.asset.code ? <p className={"asset-data"}><span
                        className={"heading"}>Code:&nbsp;</span>{this.state.asset.code}</p> : ''}
                    {this.state.asset.applianceType ? <p className={"asset-data"}><span
                        className={"heading"}>Device Label:&nbsp;</span>{this.state.asset.applianceType}</p> : ''}
                    {this.state.asset.modbusId ? <p className={"asset-data"}><span
                        className={"heading"}>Modbus Model:&nbsp;</span>{this.state.modbusData?.displayName}</p> : ''}
                    {isPulse ? <p className={"asset-data"}><span
                        className={"heading"}>Channel:&nbsp;</span>{this.state.asset.assetIndex + 1}</p> : ''}
                    {isLine ? <p className={"asset-data"}><span
                        className={"heading"}>Line:&nbsp;</span>{this.state.asset.assetIndex ? this.state.asset.assetIndex : '3-Phase Circuit'}</p> : ''}
                    {this.state.asset.gatewayId ?
                        <p className={"asset-data"}><span className={"heading"}>Hub:&nbsp;</span><span
                            style={{'text-decoration': 'underline', 'cursor': 'pointer'}}
                            onClick={() => history.push("/hub/" + self.state.asset.gatewayId)}>{this.state.asset.gatewayName}</span>
                        </p> : ''}
                    <p className={"asset-data"}><span
                        className={"heading"}>ID:&nbsp;</span>{this.state.asset.id}</p>
                </div>
            </div>);
            if (this.state.asset.sku !== 'VIRTUAL-PARENT') realTime = (
                <Card className={"real-time"}>
                    <CardHeader title={"Real-time info"}>
                        {!this.state.asset.ble ? <div className={'identify-button-container'}>
                            <Button
                                color={'gd-blue'}
                                label={"Identify"}
                                progressRes
                                onClick={this.identify}/>
                        </div> : null}
                    </CardHeader>
                    <CardBody>
                        <div className={'row'} style={{
                            height: '42px',
                            alignItems: 'center',
                            marginBottom: '20px'
                        }}>
                            <StateSelectMenu permission={this.state.orgPermission}
                                             object={this.state.asset}
                                             showLabel={true}
                                             showButton={true}
                                             endAdornment={() => <IssuePopover
                                                 updateDisconnectedDrawer={this.props.updateFunctions.updateDisconnectedDrawer}
                                                 item={this.state.asset}/>}
                                             disableRightClick={true}/>
                        </div>
                        {realTimeData}
                    </CardBody>
                </Card>
            )
            if (this.state.rules) {
                this.state.rules.forEach(function (rule) {
                    let Component;
                    switch (rule.template) {
                        case 'timeswitch':
                            Component = RulePageTimeswitch;
                            break;
                        case 'alert':
                            Component = RulePageAlert;
                            break;
                        case 'thermostat':
                            Component = RulePageThermostat;
                            break;
                        default:
                            Component = RulePageAdvanced;
                    }
                    if (Component) {
                        ruleComponents.push(
                            <Component strippedback={'true'} rule={rule}/>
                        )
                    }
                })
            }
            rules = (ruleComponents.length ?
                    <div className={'column'}>

                        <div className={'detail-content'}>
                            <Card>
                                <CardHeader title={"Rules"}/>
                                <CardBody>
                                    <div style={{maxHeight: '200px', overflow: 'auto'}}>
                                        {ruleComponents}
                                    </div>
                                </CardBody>
                            </Card></div>
                    </div> : ''
            )
            let parent;
            if (this.state.parentName) {
                parent = (<Input top label={'Parent'} disabled value={this.state.parentName}/>
                );
            } else {
                parent = (<p className={"psuedo-label"}>No parent</p>);
            }
            let children;
            if (this.state.asset.children && this.state.asset.children.length > 0) {
                children = (
                    <div className={"children-container"}>
                        <p className={"psuedo-label"}>{this.state.asset.children.length} children</p>
                        <List
                            columns={[
                                {component: StandardListCell, bold: true, sortable: true, title: 'Name', field: 'name'},
                                {
                                    component: StateListCell,
                                    sortable: false,
                                    title: 'Status',
                                    field: 'currentState',
                                    showLabel: true,
                                    permission: this.state.orgPermission
                                },
                                {
                                    component: SensorDataListCell,
                                    title: 'Data',
                                    field: 'temperature',
                                    maxWidth: 'none'
                                },
                            ]}
                            rowOnClick={this.goToAsset}
                            getlist={this.getList}
                            parentField={"parentAsset"}
                            childrenField={"children"}
                            expanded={true}
                            filters={this.state.childrenFilters}/>
                    </div>);
            }
            family = (
                <Card className={"family"}>
                    <CardHeader title={"Family"}>
                        <div style={{display: 'inline-block', float: 'right'}}>
                            <Button label={"Edit"} color={'gd-grey'}
                                    onClick={() => history.push("/site/" + this.state.asset.siteId + "/settings/family")}/>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {parent}
                        {children}
                    </CardBody>
                </Card>
            )
        }

        return (
            <div className={"overview-asset page narrow not-flex grey"}>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        {summary}
                    </div>
                </div>
                <div className={'column'}>

                    <div className={'detail-content'}>
                        {realTime}
                    </div>
                </div>
                {phaseBreakdown}
                {rules}

                <div className={'column'}>

                    <div className={'detail-content'}>
                        {family}
                    </div>
                </div>
            </div>
        )
    }
}

export default DesktopAssetOverviewPage;
