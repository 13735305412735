import React from "react";
import "./AnomalyDetection.scss";
import Anomaly from "./Anomaly";
import {Grid} from "@material-ui/core";
import history from "../../meta/history";
import AnomalyPanel from "./AnomalyPanel";
import GridDuck from "gridduck";
import GenericLoader from "../../components/GenericLoader";
import InfiniteScroll from 'react-infinite-scroller';
import LegendRange from "../../components/LegendRange";
import {Button, DateTimeRangePicker, Dropdown, Icon, Input, Tooltip} from "gd-react";
import FilterMenuV2 from "../../components/FilterMenuV2/FilterMenuV2";
import moment from "moment";
import {debounce} from 'lodash';

class Anomalies extends React.Component {
    constructor(props) {
        super(props);
        this.timezone = moment.tz.guess();
        this.state = {
            anomalies: [],
            selectedAnomaly: null,
            updated: 0,
            statusFilter: 'all',
            searchText: '',
            filterOptions: [
                {
                    id: 'status',
                    name: 'Status',
                    justHeader: true,
                    children: [{
                        id: 'ongoing',
                        name: 'Ongoing',
                        parent: 'status',
                        checked: true
                    }, {
                        id: 'new',
                        name: 'New',
                        parent: 'status',
                        checked: true
                    }, {
                        id: 'flagged',
                        name: 'Flagged',
                        parent: 'status',
                        checked: true
                    }, {
                        id: 'resolved',
                        name: 'Resolved',
                        parent: 'status',
                        checked: false
                    }]
                }
            ]
        };
        this.updatedExternally = this.updatedExternally.bind(this);
        this.scrollToAnomaly = this.scrollToAnomaly.bind(this);
        this.loadAnomalies = this.loadAnomalies.bind(this);
        this.getStatusesArray = this.getStatusesArray.bind(this);
    }

    async getAnomalies(limit, offset) {
        console.log('Get Anomalies: ' + limit + ' ' + offset);
        return GridDuck.getAnomalys({
            items: limit,
            offset: offset,
            filters: [
                {
                    field: 'search',
                    value: this.state.searchText
                }, {
                    field: 'userStatus',
                    value: this.getStatusesArray()
                }
            ]

        });
    }

    async loadAnomalies(config) {
        console.log('Load Anomalies');
        console.log(this.state.anomalies, ' : this.state.anomalies');
        console.log(this.state.count, ' : this.state.count');
        let anomalies, self = this;

        if (this.loading || this.state.anomalies.length >= this.state.count) {
            console.log('immediately resolve');
            console.log(this.loading, ' : this.loading');
            this.loading = false;
            return Promise.resolve();
        }
        this.loading = true;
        anomalies = await this.getAnomalies(12, this.state.anomalies.length);

        if (!this.state.loaded && !this.state.anomalies.length) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const anomalyId = urlParams.get('anomaly')
            let anomaly;
            if (anomalyId) {
                anomaly = await GridDuck.getAnomaly({id: anomalyId});
            }
            this.setState({
                anomalies: anomalies.list,
                selectedAnomaly: anomaly,
                loaded: true,
                count: anomalies.total
            }, () => {
                self.loading = false;
                if (anomaly) {
                    this.scrollToAnomaly('anomaly-' + anomaly.id);
                }
            });
        } else {
            console.log('Load more anomalies');
            console.log(this.state.anomalies, ' : this.state.anomalies');
            console.log(anomalies.list, ' : anomalies.list');
            this.setState({anomalies: this.state.anomalies.concat(anomalies.list), count: anomalies.total}, () => {
                self.loading = false;
            });
        }

        return Promise.resolve();
    }

    updatedExternally(cb) {
        let statuses = this.getStatusesArray();
        if (this.state.anomalies) {
            this.state.anomalies = this.state.anomalies.filter(a => statuses.find(s => s === a.userStatus))
        }
        console.log(this.state.anomalies, ' : anomalies');
        this.setState({updated: this.state.updated++}, cb);
    }

    scrollToAnomaly(anomaly_id) {
        window.setTimeout(() => {
            let anomalyElem = document.getElementById(anomaly_id);
            if (anomalyElem) {
                let offset = anomalyElem.offsetTop - 114;
                document.getElementById('anomalies-list').scrollTo({
                    top: offset,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }, 300);
    }

    getStatusesArray() {
        let statuses = this.state.filterOptions[0].children.filter(fo => fo.checked).map(fo => fo.id);
        if (!statuses.length) {
            statuses = ['none']
        }
        return statuses;
    }

    render() {
        return <div className={'anomalies-wrapper'}>
            <div className={'board-header'} style={{justifyContent: 'space-between'}}>
                <div className={'search-input-wrapper'}><
                    Input InputProps={{
                    startAdornment: (
                        <div className={'input-icon no-pointer'}>
                            <Icon color={'grey'} size={'10'} icon={'FaSearch'}/>
                        </div>),
                    endAdornment: this.state.searchText.length ? (
                        <div onClick={() => this.setState({searchText: ''})} className={'input-icon end'}>
                            <Icon color={'gd-red'} size={'10'} icon={'FaTimes'}/>
                        </div>) : ''
                }} value={this.state.searchText} placeholder={'Search'}
                          onKeyPress={(event) => {
                              if (event.key === 'Enter') this.setState({
                                  anomalies: [],
                                  count: undefined,
                                  loaded: false
                              });
                          }}
                          onChange={(e) => {
                              this.setState({searchText: e.target.value});
                          }}/>
                </div>

                <FilterMenuV2 onUpdate={(val, item) => {
                    let newFilterOptions = this.state.filterOptions.map((fd) => {
                        if (fd.id === item.id) fd.checked = val;
                        fd.children.forEach((fdc) => {
                            if (fdc.id === item.id || fdc.parent === item.id) fdc.checked = val;
                        });
                        return fd;
                    });
                    this.setState({filterOptions: newFilterOptions, anomalies: [], count: undefined, loaded: false});
                }} disabled={!this.state.loaded}
                              filterItems={this.state.filterOptions}>
                    <div className={'button-wrapper grey larger with-text'}
                         style={{marginLeft: '10px'}}>
                        <Icon
                            size={12}
                            color={'darkgrey'}
                            icon={'FaFilter'}/>
                        <label>Filter</label>
                    </div>
                </FilterMenuV2>

            </div>
            <div className={'anomalies-wrapper row'}>
                <div className={'anomalies'} id={'anomalies-list'}>
                    {this.state.loaded && this.state.anomalies.length === 0 ?
                        <p className={'no-results'}>No Anomalies</p> : ''}
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadAnomalies}
                        hasMore={!this.state.loaded || this.state.anomalies.length < this.state.count}
                        loader={<div key={'i-load'} style={{
                            display: 'flex',
                            flex: '1 1',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <GenericLoader text={'Fetching...'} textLineTwo={'Anomalies'}/></div>}
                        useWindow={false}>
                        <Grid container spacing={2} key={'anomalies-wrapper'}>
                            {this.state.anomalies.length ? this.state.anomalies.map((a) => {
                                    return <Anomaly showingAnomaly={this.state.selectedAnomaly}
                                                    onClick={(e, id) => {
                                                        history.push('/anomaly-detection/anomalies?anomaly=' + a.id);
                                                        this.setState({selectedAnomaly: a});
                                                        this.scrollToAnomaly(id);
                                                    }} key={a.id} anomaly={a}/>
                                }) :
                                null}
                        </Grid>
                    </InfiniteScroll>
                </div>

                {this.state.selectedAnomaly ? <AnomalyPanel onUpdate={this.updatedExternally} onClose={() => {
                    history.push('/anomaly-detection/anomalies');
                    this.setState({selectedAnomaly: null})
                }} anomaly={this.state.selectedAnomaly}/> : null}
            </div>
        </div>

    }
}

export default Anomalies;