import React from "react";
import GridDuck from "gridduck";
import {
    Button,
    Card, GdAutocomplete,
    Input
} from "gd-react";
import {StateOptions} from "gd-react";
import Toggle from "../../components/Toggle/Toggle";

class CloneSite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            site: null,
            sites: [],
            newName: '',
            loaded: false,
            removeCharacters: 0
        }
        this.errors = {}
        this.getSites = this.getSites.bind(this)
        this.selectSite = this.selectSite.bind(this);
        this.cloneSite = this.cloneSite.bind(this);
    }

    getSites(searchTerm) {
        return GridDuck.getSites({
            filters: [{
                field: 'search',
                value: searchTerm
            }]
        }).then(function (siteList) {
            return Promise.resolve(
                siteList.list
                    .filter(s => s._permission === 'admin')
                    .map(
                        s => ({
                            id: s.id,
                            title: s.name
                        })
                    )
            )
        })
    }

    generateRandomHex(length) {
        let id = '';
        let characters = 'abcdef0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            id += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return id;
    }

    async cloneSite() {
        let self = this
        let currentSite = this.state.site;
        if (!self.state.site) {
            console.log('no site selected')
            return Promise.reject(false)
        }
        let newSite = {
            name: self.state.newName || currentSite.title + ' (Copy)',
        }
        let newSiteRes = await GridDuck.createSite(newSite)
        let id = newSiteRes.id;
        console.log(newSiteRes, ' new site res')
        let filters = [
            {
                field: 'siteId',
                value: this.state.site.id
            }
        ]
        let assetsRes = await GridDuck.getAssets({filters: filters})
        let newAssetsList = assetsRes.list.map((a) => {
            console.log('processing ', a.name)
            let type = StateOptions.find((o) => o.type === a.sku)
            if (!type || !a.gatewayId) {
                console.log('no type found for ', a.sku)
                return null
            }
            let types = type.dataTypes;
            let virtualArray = []
            types.forEach((t) => {
                virtualArray.push({
                    from: t.type,
                    to: t.type
                })
            })
            let virtualLink = {
                asset_id: a.id,
                data: virtualArray
            }
            let name = a.name.slice(self.state.removeCharacters)
            let asset = {
                name: name,
                code: this.generateRandomHex(a.code.length),
                applianceType: a.applianceType || 'Other',
                sku: a.sku,
                dataType: a.dataType,
                gatewayId: 'CLONED',
                groupIds: [id],
                virtualLink: virtualLink
            }
            console.log(asset, ' asset')
            return asset;
        });
        console.log(newAssetsList, ' assets to be created')
        let promises = []
        newAssetsList.forEach((a) => {
            if (a) promises.push(GridDuck.createAsset(a))
        })
        let createAllRes = await Promise.all(promises);
        console.log(createAllRes, ' create all res')
    }

    componentDidMount() {
        let self = this;
    }

    selectSite(site, siteObj) {
        console.log('select site triggered')
        if (!site || !siteObj) return
        this.setState({
            site: siteObj,
        });
    }

    render() {
        let self = this;
        return (
            <div className='page narrow Account not-flex grey'>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        <Card>
                            <div className={'row'} style={{marginTop: '20px'}}>
                                <GdAutocomplete
                                    async
                                    name={'site'}
                                    getOptionSelected={function (option, value) {
                                        if (option && value) return option.id === value.id;
                                    }}
                                    value={this.state.site}
                                    placeholder={'Select a site'}
                                    getList={self.getSites}
                                    onChange={this.selectSite}
                                    label="Site"/>
                            </div>
                            <div className={'row'} style={{marginTop: '20px'}}>
                                <Input label={'Optional Name '}
                                       onChange={(e) => this.setState({newName: e.target.value})}
                                       value={this.state.newName}/>
                            </div>
                            <div className={'row'} style={{marginTop: '20px'}}>
                                <Input
                                    // inputProps={{step: 1, min: 0, max: 100}}
                                       onChange={(e) => this.setState({removeCharacters: e.target.value})}
                                       type={'number'}
                                       name={'Remove X Characters from Device Names'}
                                       value={this.state.removeCharacters}
                                       label={'Remove X Characters from Device Names'}
                                />
                            </div>
                            <div className={'row reverse'}>
                                <Button progressRes style={{marginTop: '30px', marginBottom: '10px'}}
                                        additionalclasses={'sm'}
                                        color={'gd-green'}
                                        onClick={self.cloneSite}
                                        label={'Clone Site'}/>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default CloneSite;