import getFormat from "./formatter";

const colors = {
    info: '#49b9c4',
    update: '#49b9c4',
    delete: '#49b9c4',
    create: '#49b9c4',
    error: '#fc1e1e',
    warning: '#f89d41',
}

const generateNotificationMetaData = function (notification) {

    let icon = {}
    let url;
    let noClick;

    switch (notification.objectType) {
        case 'site':
            icon.name = 'FaBuilding';
            url = '/site/' + notification.objectId;
            break;
        case 'device':
            icon.name = 'FaPlug';
            url = '/device/' + notification.objectId + '/details';
            break;
        case 'hub':
            icon.name = 'MdRouter';
            url = '/hub/' + notification.objectId;
            break;
        case 'group':
            icon.name = 'FaTasks';
            url = '/deviceGroup/' + notification.objectId;
            break;
        case 'alert':
            icon.name = 'FaBell';
            url = '/alerts/' + notification.objectId;
            break;
        case 'report':
            icon.name = 'FaFileAlt';
            url = '/reports/' + notification.objectId;
            break;
        case 'rule':
            icon.name = 'FaTasks';
            url = '/automations/' + notification.objectId;
            break;
        case 'site_shared':
            icon.name = 'FaShare';
            url = '/site/' + notification.objectId;
            break;
    }
    switch (notification.category) {
        case 'delete':
            icon.color = "red";
            url = null;
            noClick = true;
            break;
    }

    return {
        id: notification.id,
        name: notification.message,
        subtitle: notification.description,
        date: getFormat('lastContacted')(notification.created),
        icon: icon,
        color: colors[notification.category],
        url: url,
        noClick: noClick
    };
}

export default generateNotificationMetaData