import "../Rules.scss";
import React from "react";
import GridDuck from "gridduck";
import {Button} from "gd-react";
import {ScheduleTimeline} from "gd-react";
import RulePageAdvanced from "./RulePageAdvanced";
import RulePageTimeswitch from "./RulePageTimeswitch";
import RulePageAlert from "./RulePageAlert";
import history from "../../meta/history";
import RulePageThermostat from "./RulePageThermostat";
import GenericLoader from "../../components/GenericLoader";
import DeleteModal from "../../modals/Delete/DeleteModal";

class RulePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rule: this.props.rule,
			loaded: true
		};

		// let {ruleId} = this.props.match.params;
		// let self = this;
		// GridDuck.getRule({
		// 	id: ruleId
		// }).then(function (rule) {
		// 	self.setState({
		// 		rule: rule,
		// 		loaded: true
		// 	});
		// 	rule.on('updated', self.updated);
		// });
		this.updated = this.updated.bind(this);
		this.showDeleteModal = this.showDeleteModal.bind(this)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let newRuleId = this.props.rule.id;
		let oldRuleId = prevProps.rule.id;
		if (newRuleId && oldRuleId && newRuleId !== oldRuleId) {
			this.updated();
		}
	}

	updated(field, val) {
		let {ruleId} = this.props.match.params;
		let self = this;
		GridDuck.getRule({
			id: ruleId
		}).then(function (rule) {
			self.setState({rule: rule});
		})
	}

	componentWillUnmount() {
		if (this.state.rule && this.state.rule.off) {
			this.state.rule.off('updated', this.updated)
		}
	}

	showDeleteModal() {
		this.setState({showDeleteModal: true})
	}

	render() {
		let Component = RulePageAdvanced;
		if (this.state.rule) {
			switch (this.state.rule.template) {
				case 'timeswitch':
					Component = RulePageTimeswitch;
					break;
				case 'alert':
					Component = RulePageAlert;
					break;
				case 'thermostat':
					Component = RulePageThermostat;
					break;
			}
		}
		return (
			<div className={"rule"}>
				{this.state.rule ? <div className={'inner-rule'}>

					<Component history={history} rule={this.state.rule}/>
					<DeleteModal
						itemType={'rule'}
						item={this.state.rule}
						open={this.state.showDeleteModal}
						onClose={() => this.setState({showDeleteModal: false})}/>
				</div> : ''}
				{!this.state.loaded ? <GenericLoader/> : ''}
			</div>
		)
	}
}

export default RulePage;
