import React, {Component} from "react";
import "./AddGroupModal.scss";
import '../../styles/_layout.scss';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Dropdown, EditableList,
    GdAutocomplete,
    Icon,
    Input,
    StandardListCell,
    Tabs
} from "gd-react";
import GridDuck from "gridduck";
import moment from "moment-timezone";
import SaveBar from "../../components/SaveBar/SaveBar";
import history from "../../meta/history";
import {Grid} from "@material-ui/core";
import GdModal from "../../components/GdModal/GdModal";

class AddGroupModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.onSiteChange = this.onSiteChange.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.getActiveList = this.getActiveList.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.devicesChanged = this.devicesChanged.bind(this);
        this.moveToTab = this.moveToTab.bind(this);
        this.errors = {};
        this.changed = false;

        this.state = {
            wide: false,
            group: {
                name: '',
                assetIds: []
            },
            tabs: [[
                {
                    id: 1,
                    title: 'Details',
                    selected: true,
                    onTabClick: this.onTabClick

                },
                {
                    id: 2,
                    title: 'Devices',
                    onTabClick: this.onTabClick,
                    disabled: true

                }
            ]]
        }

        this.modalClosed = false;
    }

    componentDidMount() {
        this.checkErrors();
    }

    onTabClick(ev, tab) {
        this.state.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    onFormChange(val) {
        this.state.group[val.target.name] = val.target.value;
        this.setState({group: this.state.group});
    }

    onSiteChange(val, newValue) {
        this.state.site = newValue;
        this.setState(this.state);
    }

    saveChanges() {
        let self = this;
        console.log(this.errors, ' : this.errors');
        if (Object.keys(this.errors).length > 0) return Promise.reject();
        return GridDuck.createGroup(this.state.group).then(function (res) {
            history.push('/device-groups/' + res.id);
            self.closeModal();
        })
    }

    checkErrors() {
        let errors = {};
        if (!this.state.group || !this.state.group.name) errors['name'] = true;
        if (!this.state.group.assetIds || !this.state.group.assetIds.length) errors['assetIds'] = true;
        this.state.tabs[0][1].disabled = errors['name'];

        this.errors = errors;
    }

    getActiveList(retrievalParams) {
        let promises = this.state.group.assetIds.map(a => GridDuck.getAsset({id: a}))
        return Promise.all(promises).then(function (results) {
            return Promise.resolve({list: results, total: results.length});
        })
    }

    moveToTab() {
        this.setState({triedToNext: true})
        if (this.errors['name']) return;
        this.onTabClick(null, {id: 2});
    }

    devicesChanged(newList) {
        console.log(newList, ' : changed');
        this.state.group.assetIds = newList.map(function (asset) {
            return asset.id;
        })
        this.setState({group: this.state.group});
    }

    getAvailableList(params) {
        return GridDuck.getAssets(params);
    }

    render() {
        this.checkErrors();

        let footer;
        if (this.state.tabs[0][0].selected) footer = <Button label={'Next'} onClick={() => this.moveToTab(1)}/>
        if (this.state.tabs[0][1].selected) footer =
            <Button progressRes additionalclasses={'sm'} label={'Add group'} color={'gd-green'}
                    onClick={this.saveChanges}/>

        return (
            <GdModal
                title={'Create Device Group'}
                open={this.props.open}
                wide={this.state.tabs[0][1].selected}
                contentLabel={'Add Site'}
                footer={footer}
                onClose={this.closeModal}>

                {this.state.tabs ? <Tabs tabData={this.state.tabs}/> : null}
                {this.state.tabs[0][0].selected ?
                    <CardBody>
                        <div className={'row'}>
                            <Input required error={this.errors['name'] && this.state.triedToNext} top='true'
                                   onChange={this.onFormChange}
                                   name={'name'} value={this.state.group.name} label={'Name'}/>
                        </div>
                    </CardBody> : ''}
                {this.state.tabs[0][1].selected ?

                    <CardBody additionalclasses={'no-padding'}> <EditableList
                        isEditing={true}
                        key={1}
                        onChange={this.devicesChanged}
                        noCancel={true}
                        noSave
                        editingColumns={[
                            {component: StandardListCell, title: 'Site Name', field: 'siteName'},
                        ]}
                        getActiveList={this.getActiveList}
                        getAvailableList={this.getAvailableList}
                    /></CardBody> : ''}
            </GdModal>
        )
    }
}

export default AddGroupModal;
