import React from 'react';
import './MobileBottomNav.scss';
import {Icon} from "gd-react";
import history from "../meta/history";
import AddManualDeviceModal from "../modals/AddDevice/AddManualDeviceModal";

class MobileBottomNav extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			openAddManualDeviceModal: false
		}
	}

	goPath(path) {
		history.push(path);
	}


	render() {
		return (
			<div id="mobile_bottom_nav">
				{this.state.openAddManualDeviceModal ? <AddManualDeviceModal isMobile={false} open={this.state.openAddManualDeviceModal}
																 onClose={() => this.setState({openAddManualDeviceModal: false})}/> : ''}
				<div className={"button"} onClick={()=>this.goPath('/')}>
					<Icon size={'15'} icon={'FaLayerGroup'} color={'gd-grey'}/>
					<p className={"label"}>Portfolio</p>
				</div>
				<div className={"button"} onClick={()=>this.goPath('/reports')}>
					<Icon size={'15'} icon={'FaChartArea'} color={'gd-grey'}/>
					<p className={"label"}>Reports</p>
				</div>
				<div className={"button"} onClick={() => this.setState({openAddManualDeviceModal: true})}>
					<Icon size={'15'} icon={'FiPlus'} color={'gd-grey'}/>
					<p className={"label"}>Add Device</p>
				</div>
			</div>
		);
	}
}

export default MobileBottomNav;
