import React from 'react';
import './DesktopSiteGroupSites.scss';
import {Button, DemandListCell, EditableList, List, SiteGroup, StandardListCell} from "gd-react";
import SiteGroupSites from "./SiteGroupSites";
import GenericLoader from "../../components/GenericLoader";
import IssuePopover from "../../components/IssuePopover/IssuePopover";

class DesktopSiteGroupSites extends SiteGroupSites {

    render() {
        let self = this;
        let sites;
        let {itemId} = this.props.match.params;
		let listComp = EditableList;
		if (itemId.substr(0, 3) === 'all') {
			listComp = List;
		}
		console.log(this.props.listFilters, ' : this.props.listFilters')

        if (this.props.listFilters && this.props.loaded) {
			if (itemId.substr(0, 3) === 'all' || this.props.filterType === 'org_partner_id') {
				sites = (
					<List
						searchable
						columns={[
							{
								component: StandardListCell,
								bold: true,
								title: 'Name',
								field: 'name',
								sortable: true,
								defaultSort: 'asc'
							},
							{component: StandardListCell, title: 'Devices', field: 'appliances', maxWidth: '150px', custom: (object) => {
								return object.appliances || '0'
						}},
							{
								component: StandardListCell,
								title: 'Issues',
								field: 'issues',
								maxWidth: '150px',
								custom: function (object) {
									return <IssuePopover updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} item={object}/>
								}
							}
						]}
						getlist={this.getAvailableList}
						filters={this.props.listFilters}
						rowOnClick={this.goToSite}/>
				)
			} else {
				sites = (
					<EditableList
						searchable
						activeColumns={[
							{
								component: StandardListCell,
								bold: true,
								title: 'Name',
								field: 'name',
								sortable: true,
								defaultSort: 'asc'
							},
							{component: StandardListCell, title: 'Devices', field: 'appliances', maxWidth: '150px',},
							{
								component: StandardListCell,
								title: 'Issues',
								field: 'issues',
								maxWidth: '150px',
								custom: function (object) {
									return <IssuePopover updateDisconnectedDrawer={self.props.updateFunctions.updateDisconnectedDrawer} item={object}/>
								}
							},
							{component: StandardListCell, title: 'Access level', field: '_permission', maxWidth: 'none'}
						]}
						getActiveList={this.getList}
						getAvailableList={this.getAvailableList}
						rowOnClick={this.goToSite}
						save={this.save}
						disabled={itemId.substr(0, 3) === 'all' || !this.props.editable}
						filters={this.props.listFilters}/>
				)
			}
        }
        return (
            <div className='page'>
                {sites}
				{!this.props.loaded ? <GenericLoader/> : null}
            </div>
        );
    }
}

export default DesktopSiteGroupSites;