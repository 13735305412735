import React from 'react';
import {Redirect} from 'react-router-dom';
import {Button} from 'gd-react';
import GridDuck from 'gridduck';
import cookie from 'react-cookies'

class Logout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loggedOut: false};
        this.logout = this.logout.bind(this);
    }

    logout() {
        let self = this;
        GridDuck.config.accessToken = null;
        cookie.remove('accessToken', { path: '/' });
        self.setState({loggedOut: true});
    }

    render() {
        if (this.state.loggedOut === true) {
            return (<Redirect to='/'/>)
        }
        return (
            <Button
                color="gd-brand"
                disabled={false}
                label="Logout"
                onClick={this.logout}
                outline={false}
            />
        );
    }
}

export default Logout;
