import React from "react";
import "../../pages/savingsCalculator/savingsCalculator.scss";
import getFormat from "../../services/formatter";

class SavingBreakdown extends React.Component {
    render() {
        return (
            <div className={'row'} style={{justifyContent: 'space-evenly', margin: '15px 0'}}>
                {!this.props.hidePrice ? <div style={{padding: '0 10px', textAlign: 'center'}}>
                    <p className={'stat'} style={{fontSize: '30px'}}>£{getFormat('comma')(this.props.processedSavingsData.pounds)}*</p>
                    <p> </p>
                </div> : null}
                <div style={{padding: '0 10px', textAlign: 'center'}}>
                    <p className={'stat'}>{getFormat('comma')(this.props.processedSavingsData.totalKwhSaved)}</p>
                    <p>kWh</p>
                </div>
                <div style={{padding: '0 10px', textAlign: 'center'}}>
                    <p className={'stat'}>{getFormat('comma')(this.props.processedSavingsData.totalCarbonSaved)}</p>
                    <p>kg of Carbon</p>
                </div>
                <div style={{padding: '0 10px', textAlign: 'center'}}>
                    {this.props.hidePrice ? <p className={'stat'}>{Math.round(this.props.includePercentage)}%</p> : <p className={'stat'}>{(Math.round((this.props.processedSavingsData.totalKwhSaved / this.props.totalKwh) * 100))}%</p>}
                    {this.props.hidePrice ? <p>of your spend</p> : <p>of total saving</p>}
                </div>
            </div>
        )
    }
}

export default SavingBreakdown;
