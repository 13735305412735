import React, {Component} from "react";
import fa from 'react-icons/fa/index';
import io from 'react-icons/io/index';
import md from 'react-icons/md/index';
import ti from 'react-icons/ti/index';
import go from 'react-icons/go/index';
import fi from 'react-icons/fi/index';
import gi from 'react-icons/gi/index';
import di from 'react-icons/di/index';
import wi from 'react-icons/wi/index';
import ai from 'react-icons/ai/index';

//https://react-icons.netlify.com/#/icons/io

import "./Icon.css";
import Badge from "@material-ui/core/Badge";

const Icon = function (props) {
	let lib = props.icon.slice(0, 2).toLowerCase();
	let status;
	switch (lib) {
		case 'fa':
			status = {LoadedIcon: fa[props.icon]};
			break;
		case 'io':
			status = {LoadedIcon: io[props.icon]};
			break;
		case 'md':
			status = {LoadedIcon: md[props.icon]};
			break;
		case 'ti':
			status = {LoadedIcon: ti[props.icon]};
			break;
		case 'go':
			status = {LoadedIcon: go[props.icon]};
			break;
		case 'fi':
			status = {LoadedIcon: fi[props.icon]};
			break;
		case 'gi':
			status = {LoadedIcon: gi[props.icon]};
			break;
		case 'wi':
			status = {LoadedIcon: wi[props.icon]};
			break;
		case 'di':
			status = {LoadedIcon: di[props.icon]};
			break;
		case 'ai':
			status = {LoadedIcon: ai[props.icon]};
			break;
		default:

	}

	let color;
	switch (props.color) {
		case 'gd-green':
			color = '#49c570';
			break;
		case 'gd-red':
			color = '#e43536';
			break;
		case 'gd-blue':
			color = '#03a9f4';
			break;
		case 'gd-brand':
			color = '#49b9c4';
			break;
		case 'gd-grey':
			color = '#a7a7a7';
			break;
		case 'black':
			color = '#000000';
			break;
		case 'white':
			color = '#ffffff';
			break;
		default:
			color = props.color;
			break;

	}

//	Define Notification Colour
	let notificationsColor;
	if (props.notifications) {
		switch (props.notifications.color) {
			case 'gd-green':
				notificationsColor = '#49c570';
				break;
			case 'gd-red':
				notificationsColor = '#e43536';
				break;
			case 'gd-blue':
				notificationsColor = '#03a9f4';
				break;
			case 'gd-grey':
				notificationsColor = '#a7a7a7';
				break;
			case 'black':
				notificationsColor = '#000000';
				break;
			case 'white':
				notificationsColor = '#ffffff';
				break;
			default:
				notificationsColor = props.notifications.color;
				break;

		}
	}
	let sizeInPx = props.size ? props.size + 'px' : '';
	let LhSizeInPx = props.size ? props.size - 2 + 'px' : '';
	let offset = props.notifications && props.notifications.offset ? props.notifications.offset + 'px' : '';
	let notificationNumber = props.notifications ? props.notifications.count : 0

	return (
		<div style={{
			display: 'inline-block',
			position: 'relative',
			pointerEvents: props.notouch ? 'none' : 'auto',
			fontSize: sizeInPx,
			lineHeight: props.lineHeight ? props.lineHeight : LhSizeInPx
		}} className={'Icon ' + (props.onIconClick ? 'clickable ' : '') + (props.spinning ? 'spinning' : '')}>
			<Badge overlap={"rectangular"} badgeContent={notificationNumber}>
				<status.LoadedIcon
					onClick={props.onIconClick ? props.onIconClick : null}
					style={{
						lineHeight: props.lineHeight ? props.lineHeight : LhSizeInPx,
						color: color,
						fontSize: props.size ? props.size + 'px' : 'auto'
					}}/>
			</Badge>
		</div>
	)
}

Icon.defaultProps = {
	color: 'inherit',
	size: 'inherit'
};

export default Icon;
