import React from "react";
import "./FamilyTree.scss";
import GridDuck from "gridduck";
import SortableTree from 'react-sortable-tree';
import {find} from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import {Button, Toast} from "gd-react";
import {Prompt} from "react-router-dom";
import GenericLoader from "../GenericLoader";
import SaveBar from "../SaveBar/SaveBar";
import NoItems from "../noItems";
import AddHubModal from "../../modals/AddHub/AddHubModal";
import AddManualDeviceModal from "../../modals/AddDevice/AddManualDeviceModal";

class FamilyTree extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        this.state = {
            assets: [],
            showToast: false
        };

        this.processAssets = this.processAssets.bind(this);
        this.getDifferences = this.getDifferences.bind(this);
        this.updatedList = this.updatedList.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);

        this.assetListeners = {};

        Promise.all([GridDuck.getAssets({
            getAll: true,
            filters: [
                {
                    field: 'siteId',
                    value: props.siteId
                }
            ]
        }), GridDuck.getGateways({
            getAll: true,
            filters: [
                {
                    field: 'siteId',
                    value: props.siteId
                }
            ]
        })]).then(function (assets) {
            console.log(assets[0], ' : asset list');
            assets[0].on('updated', self.updatedList)
            self.setState({loaded: true, hubs: assets[1].list})
            self.assetList = assets[0];
            self.processAssets(assets[0].list);
        })
    }

    cancel() {
        this.processAssets(this.assetList.list);
    }

    updatedList(field, val) {
        if (field === 'list' && this.getDifferences().length === 0) {
            this.processAssets(val);
        }
    }

    componentWillUnmount() {
        let self = this;
        if (this.assetList) {
            this.assetList.list.forEach(function (asset) {
                if (self.assetListeners[asset.id]) {
                    asset.off('updated', self.assetListeners[asset.id]);
                }
            })
            this.assetList.off('updated', this.updatedList);
        }
    }

    save() {
        let self = this;
        let changes = this.getDifferences();
        let promises = [];
        changes.forEach(function (changedAsset) {
            let thisAsset = self.assetList.list.find(a => a.id === changedAsset.id);
            promises.push(thisAsset.setParentAsset(changedAsset.parent));
        })
        return Promise.all(promises).then(function () {
            self.setState({showToast: 'Save Successful'})
            self.processAssets(self.assetList.list);
        });
    }

    processAssets(assets) {
        let self = this;
        assets.forEach(function (asset) {
            if (!self.assetListeners[asset.id]) {
                self.assetListeners[asset.id] = function (field, val) {
                    if (field === 'parentAsset' || field === 'name') {
                        if (self.getDifferences().length === 0) {
                            self.processAssets(self.assetList.list);
                        }
                    }
                }
            }
            asset.on('updated', self.assetListeners[asset.id]);
        })


        const getChildren = function (asset) {
            return assets.filter(a => a.parentAsset === asset.id).map(a => ({
                id: a.id,
                title: a.name,
                expanded: true,
                children: getChildren(a)
            }));
        }
        let topLevelAssets = assets.filter(a => !a.parentAsset).map(a => ({
            id: a.id,
            title: a.name,
            expanded: true,
            children: getChildren(a)
        }));
        this.originalState = assets.map(a => ({id: a.id, parent: a.parentAsset}));
        this.setState({
            assets: topLevelAssets,
        });
    }

    getDifferences() {
        let self = this;
        let currentAssets = [];
        const propagateAssets = function (assetList, parent) {
            assetList.forEach(function (asset) {
                currentAssets.push({
                    id: asset.id,
                    parent: parent
                })
                if (asset.children.length > 0) {
                    propagateAssets(asset.children, asset.id)
                }
            })
        }
        if (this.state.assets) {
            propagateAssets(this.state.assets, null);
        }
        let changes = [];
        currentAssets.forEach(function (currentAsset) {
            let originalAsset = self.originalState.find(a => a.id === currentAsset.id);
            if (originalAsset.parent !== currentAsset.parent) {
                changes.push({
                    id: currentAsset.id,
                    parent: currentAsset.parent
                });
            }
        });
        return changes;
    }


    render() {
        let changes = this.getDifferences();
        let assets = [];
        let prompted = changes.length > 0;

        return (
            <div className={'column'} style={{height: '100%'}}>
                <Toast onClose={() => this.setState({showToast: null})} message={this.state.showToast}
                       open={!!(this.state.showToast)}
                       severity="success"
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                       }}
                />
                <p className={'how-to'}>
                    To reorder the family tree grab the <span className={'menu-icon'}/> icon and drag
                </p>
                <div className={'detail-content'} style={{height: '100%'}}>
                    <div className={"dragging"}>
                        {this.state.loaded && this.state.assets.length ? <SortableTree
                            getNodeKey={({node}) => node.id}
                            treeData={this.state.assets}
                            onChange={treeData => this.setState({assets: treeData})}
                        /> : ''}
                        {!this.state.loaded ? <GenericLoader text={"Fetching"} textLineTwo={"Family Tree..."}/> : ''}
                    </div>
                </div>
                {prompted ?

                    <div className={'detail-content'}>
                        <SaveBar onSaveClick={this.save} onCancelClick={this.cancel}/></div>
                    : ''}
            </div>

        )
    }

}

export default FamilyTree;
