import React, {Component} from "react";
import "./PasswordChecker.scss";
import "../../styles/_layout.scss"
import {Input, Icon} from "gd-react";

class PasswordChecker extends Component {

    constructor(props) {
        super(props);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onConfirmChange = this.onConfirmChange.bind(this);
        this.passed = false;
        this.matching = false;
        this.pwAttrs = {
            eightChars: false,
            lowercase: false,
            uppercase: false,
            number: false
        }
        this.state = {
            password: '',
            confirmPassword: ''
        };
    }

    passedCheck(obj) {
        for (let o in obj)
            if (!obj[o]) return false;

        return true;
    }

    onPasswordChange(val) {
        let pw = val.target.value;
        this.pwAttrs = {
            eightChars: (pw.length >= 8),
            lowercase: (/[a-z]/.test(pw)),
            uppercase: (/[A-Z]/.test(pw)),
            number: (/[0-9]/.test(pw))
        }
        this.setState({
            password: pw
        })
        this.passed = this.passedCheck(this.pwAttrs);
    }

    onConfirmChange(val) {
        this.setState({
            confirmPassword: val.target.value
        })

        this.matching = (val.target.value === this.state.password);

        this.props.onChange((this.passed && this.matching), val.target.value);
    }

    render() {

        return (
            <div className={'password-reset'}>
                <div className={'row'}>
                    <Input autoComplete={'new-password'} type={'password'} error={!!(!this.passed && this.state.password.length)} value={this.state.password}
                           onChange={this.onPasswordChange} label={this.props.label ? this.props.label : 'New Password'} required/>
                </div>
                <div className="pw-contains row">
                    <div className="column">
                        <div className="row pw-attr">
                            <Icon size={'12'} icon={this.pwAttrs.eightChars ? 'FaCheck' : 'FaTimes'}/>
                            <span>At least 8 characters</span>
                        </div>
                        <div className="row pw-attr">
                            <Icon size={'12'} icon={this.pwAttrs.lowercase ? 'FaCheck' : 'FaTimes'}/>
                            <span>One lowercase</span>
                        </div>
                    </div>
                    <div className="column">
                        <div className="row pw-attr">
                            <Icon size={'12'} icon={this.pwAttrs.uppercase ? 'FaCheck' : 'FaTimes'}/>
                            <span>One uppercase</span>
                        </div>
                        <div className="row pw-attr">
                            <Icon size={'12'} icon={this.pwAttrs.number ? 'FaCheck' : 'FaTimes'}/>
                            <span>One number</span>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <Input autoComplete={'new-password'} type={'password'} error={!!(!this.matching && this.state.confirmPassword.length)}
                           value={this.state.confirmPassword}
                           onChange={this.onConfirmChange} label={this.props.label ? "Confirm " + this.props.label : 'Confirm New Password'} required/>
                </div>
                {!!(!this.matching && this.state.confirmPassword.length) ?
                    <p className={'error-text'}>The passwords do not match</p> : ''}
            </div>
        )
    }
}


export default PasswordChecker;
