import React from "react";
import FamilyTree from "../../components/FamilyTree/FamilyTree";
import '../../components/FamilyTree/FamilyTree.scss'
import GenericLoader from "../../components/GenericLoader";

class SiteFamilyTree extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {itemId} = this.props.match.params;
        return (
            <div className={'page narrow not-flex grey family-tree'}>
                {this.props.loaded ? <FamilyTree siteId={itemId}/> : <GenericLoader/>}
            </div>
        );
    }
}

export default SiteFamilyTree;
