import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    DateTimeRangePicker,
    Dropdown,
    Input,
    Loader,
    StateOptions,
    Toast
} from "gd-react";
import "./reset.scss";
import GridDuck from "gridduck";
import getFormat from "../../services/formatter";
import GDGraphs from "gd-graphs";
import moment from "moment-timezone";

class AssetAdmin extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        this.state = {
            updateRef: 0,
            dateSelection: 'week_so_far',
            selectedReset: 0,
            mode: 'resets',
            start: moment().startOf('isoWeek').unix(),
            end: moment().unix(),
            granularity: 60 * 60 * 24
        };
        GDGraphs.config({
            gridduckJS: GridDuck
        });
        this.getResets = this.getResets.bind(this);
        this.renderGraph = this.renderGraph.bind(this);
        this.test = this.test.bind(this);
        this.commit = this.commit.bind(this);
        let thisItem = StateOptions.find(o => o.type === this.props.item.sku) || StateOptions.find(o => o.type === 'default');
        let dataType = thisItem.dataTypes.find(dT => dT.graphType === 'bar');
        this.dataType = dataType.type;
        this.pageBack = this.pageBack.bind(this);
        this.pageForward = this.pageForward.bind(this);
        this.setDateRange = this.setDateRange.bind(this);
    }

    componentWillUnmount() {
        if (this.graph) {
            this.graph.remove();
            this.graph = null;
        }
    }

    setDateRange(rangeStr) {
        let start;
        let end;
        let granularity;
        switch (rangeStr) {
            case 'last_15_mins':
                start = moment().subtract(15, 'minutes').unix();
                end = moment().unix();
                granularity = 30;
                break;
            case 'today_so_far':
                start = moment().startOf('day').unix();
                end = moment().unix();
                granularity = 60 * 30;
                break;
            case 'yesterday':
                start = moment().subtract(1, 'day').startOf('day').unix()
                end = moment().startOf('day').unix();
                granularity = 60 * 30;
                break;
            case 'week_so_far':
                start = moment().startOf('isoWeek').unix();
                end = moment().unix();
                granularity = 60 * 60 * 24;
                break;
            case 'last_week':
                start = moment().subtract(7, 'day').startOf('isoWeek').unix();
                end = moment().startOf('isoWeek').unix();
                granularity = 60 * 60 * 24;
                break;
            case 'month_so_far':
                start = moment().startOf('month').unix();
                end = moment().unix();
                granularity = 60 * 60 * 24;
                break;
            case 'last_month':
                start = moment().subtract(1, 'month').startOf('month').unix();
                end = moment().startOf('month').unix();
                granularity = 60 * 60 * 24;
                break;
            case 'year_so_far':
                start = moment().startOf('year').unix();
                end = moment().unix();
                granularity = 'monthly';
                break;
            case 'last_year':
                start = moment().subtract(1, 'year').startOf('year').unix();
                end = moment().startOf('year').unix();
                granularity = 'monthly';
                break;
        }
        this.setState({start: start, end: end, granularity: granularity, dateSelection: rangeStr});
    }

    pageBack() {
        if (this.graph) {
            this.graph.remove();
            this.graph = null;
        }
        console.log("Go back", {testReset: null, selectedReset: Math.max(0, this.state.selectedReset - 1)});
        this.setState({testReset: null, selectedReset: Math.max(0, this.state.selectedReset - 1)},
            this.renderGraph);
    }

    pageForward() {
        if (this.graph) {
            this.graph.remove();
            this.graph = null;
        }
        console.log("Go forward", {
            testReset: null,
            selectedReset: Math.min(this.state.rawDatas.length - 1, this.state.selectedReset + 1)
        });
        this.setState({
                testReset: null,
                selectedReset: Math.min(this.state.rawDatas.length - 1, this.state.selectedReset + 1)
            },
            this.renderGraph
        );
    }

    test() {
        if (this.graph) {
            this.graph.remove();
            this.graph = null;
        }
        let dataPoint = this.state.rawDatas[this.state.selectedReset];
        this.setState({
            testReset: {
                time: dataPoint.time,
                value: dataPoint.value
            }
        }, this.renderGraph);
    }


    commit() {
        let self = this;
        console.log("Commit");

        GridDuck.createAssetReset({
            assetId: this.props.item.id,
            timestamp: this.state.rawDatas[this.state.selectedReset].time,
            value: this.state.rawDatas[this.state.selectedReset].value
        })
        //FIXME: this create isn't returning when works... not sure why, just timed out it for now, low prioprity
        setTimeout(function () {
            if (this.graph && this.graph.remove) {
                this.graph.remove();
                this.graph = null;
            }
            self.setState({testReset: null, selectedReset: 0, rawDatas: null}, self.getResets);
            return Promise.resolve();
        }, 1000);
    }

    getResets() {
        let self = this;
        if (this.graph) {
            this.graph.remove();
            this.graph = null;
        }
        return GridDuck.getRawAggregateDatas({
            filters: [
                {
                    field: 'assetId',
                    value: self.props.item.id
                },
                {
                    field: 'dataType',
                    value: self.dataType
                },
                {
                    field: 'start',
                    value: this.state.start
                },
                {
                    field: 'end',
                    value: this.state.end
                }
            ]
        })
            .then(function (rawDatas) {
                console.log("Got raw datas", rawDatas);
                self.setState({testReset: null, selectedReset: 0, rawDatas: rawDatas.list}, self.renderGraph);
            })
    }

    async renderGraph() {
        let self = this;
        let start = this.state.start;
        let end = this.state.end;
        let data = await GridDuck.getAssetConsumptionBatchs({
            filters: [
                {
                    field: 'assetId',
                    value: this.props.item.id
                },
                {
                    field: 'dataType',
                    value: self.dataType
                },
                {
                    field: 'start',
                    value: start
                },
                {
                    field: 'end',
                    value: end
                },
                {
                    field: 'granularity',
                    value: this.state.granularity
                }
            ]
        });
        data = JSON.parse(JSON.stringify(data.list.map(r => ({list: r.telemetry}))));
        console.log("Got data", data);
        let fixedData = [];
        let usedTest = false;
        console.log(self.state.testReset);
        data[0].list.reverse().forEach(function (result) {
            if (self.state.testReset && !usedTest) {
                if (result.time * 1000 < self.state.testReset.time) {
                    result.consumption = result.consumption + self.state.testReset.value;
                    usedTest = true;
                }
            }
            fixedData.push(result);
        });
        fixedData = [{list: fixedData.reverse()}];
        console.log("Got fixed data", fixedData);
        this.graph = GDGraphs.barChart(
            {
                assets: [
                    {
                        id: this.props.item.id,
                        name: 'Test'
                    }
                ],
                data: fixedData,
                widget: true,
                dataTypes: [self.dataType],
                element: '#reset-graph',
                tooltipOverride: () => {

                },
                start: start,
                end: end,
                granularity: this.state.granularity
            }
        )
    }


    render() {
        let self = this;
        let dateSelectRange = [
            {
                value: 'today_so_far',
                title: 'Today so far'
            },
            {
                value: 'yesterday',
                title: 'Yesterday'
            },
            {
                value: 'week_so_far',
                title: 'Week So Far'
            },
            {
                value: 'last_week',
                title: 'Last Week'
            },
            {
                value: 'month_so_far',
                title: 'Month So Far'
            },
            {
                value: 'last_month',
                title: 'Last Month'
            },
            {
                value: 'year_so_far',
                title: 'Year So Far'
            },
            {
                value: 'last_year',
                title: 'Last Year'
            }
        ];

        let rawData;
        let resetSummary;
        let operations;
        if (this.state.rawDatas) {
            if (this.state.rawDatas.length > 0) {
                resetSummary = (
                    <p className={"controls"}>
                        <span onClick={this.pageBack}>{'<'}</span>&nbsp;
                        Potential Reset {this.state.selectedReset + 1} of {this.state.rawDatas.length}&nbsp;
                        <span onClick={this.pageForward}>{'>'}</span>
                    </p>
                )
                let dataPoint = this.state.rawDatas[this.state.selectedReset];
                let rawDatas = [];
                let addedReset = false;
                dataPoint.records.forEach(function (record) {
                    if (record.time > dataPoint.time && !addedReset) {
                        rawDatas.push(
                            <tr className={'reset-row'}>
                                <td>{getFormat('date')(dataPoint.time / 1000)}</td>
                                <td>{dataPoint.value}</td>
                            </tr>
                        )
                        addedReset = true;
                    }
                    rawDatas.push(
                        <tr className={'record-row'}>
                            <td>{getFormat('date')(record.time / 1000)}</td>
                            <td>{record.value}</td>
                        </tr>
                    )
                });
                rawData = (
                    <table className={'result-table'}>
                        <tr className={'results-header'}>
                            <th>Timestamp</th>
                            <th>Value</th>
                        </tr>
                        {rawDatas}
                    </table>
                )
                operations = [
                    <Button label={'Test'} onClick={this.test}/>,
                    <Button progressRes label={'Commit'} onClick={this.commit}/>
                ]
            } else {
                resetSummary = (
                    <p>No Potential Resets Found</p>
                )
            }
        }

        return (
            <div className={"page narrow not-flex grey reset-page"}>
                <div className={'column top'}>
                    <div className={'detail-content row'}>
                        <div className={'date-pick'}>
                            <Dropdown key={"dropdown-date"} nolabel='true'
                                      onChange={(e) => this.setDateRange(e.target.value)}
                                      fixeditems={dateSelectRange} value={this.state.dateSelection}/>
                        </div>
                        <Button label={'Scan For Resets'} onClick={this.getResets} progressRes/>
                    </div>
                    <div className={'resets'}>
                        {resetSummary}
                        <div className={"data-summary"}>
                            <div className={"left-table"}>
                                {rawData}
                            </div>
                            <div className={"right-graph"} id={"reset-graph"}>
                            </div>
                        </div>
                        <div className={"operations"}>
                            {operations}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AssetAdmin;
