import React, {Component} from 'react';
import {Input} from "gd-react";

class MetricForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            symbol: this.props.unit && this.props.unit.metaData ? this.props.unit.metaData.symbol : ''
        };
        this.errors = {};
        let self = this;
        ['checkErrors', 'formUpdate'].forEach((func) => {
           self[func] = self[func].bind(self);
        });
    }

    componentDidMount() {
        this.formUpdate({updateFunc: (cb) => cb()});
    }

    checkErrors() {
        this.errors['symbol'] = !this.state.symbol || !this.state.symbol.length;
    }

    formUpdate(formData) {
        let self = this;
        formData.updateFunc(() => {
            self.checkErrors();
            self.props.onChange({metaData: self.state, errors: self.errors});
        });
    }

    render() {
        return (
            <div className={'row'}>
                <Input required
                       onChange={(val) => this.formUpdate({updateFunc: (cb) => this.setState({symbol: val.target.value}, cb)})}
                       value={this.state.symbol}
                       error={this.errors['symbol'] && this.props.triedToSave}
                       label={'Symbol'}/>
            </div>
        );
    }
}

export default MetricForm;