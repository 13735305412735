import React from "react";
import GridDuck from 'gridduck';
import getFormat from "../../services/formatter";
import {
    Button,
    CollapsibleTable,
    Icon, Toast,
} from "gd-react";
import history from "../../meta/history";
import GenericLoader from "../../components/GenericLoader";
import NoItems from "../../components/noItems";

class IgnoredAlertsPage extends React.Component {
    constructor() {
        super();
        this.state = {};
        let self = this;
        this.processIncidents = this.processIncidents.bind(this);
        this.unIgnoreIncident = this.unIgnoreIncident.bind(this);
        this.unignoreAll = this.unignoreAll.bind(this);
        Promise.all([
                GridDuck.getIssues({
                    getAll: true,
                    items: 200
                }),
                GridDuck.getAccount({id: GridDuck.userId})
            ]
        )
            .then(function (results) {
                let incidents = results[0];
                let account = results[1];
                self.setState({
                    incidents: incidents,
                    ignoredAssets: account.ignoredAssets,
                    ignoredSites: account.ignoredSites,
                    loaded: true
                });
                incidents.on('updated', self.processIncidents);
                // account.on('updated', self.processIncidents);
                self.account = account;
                self.incidents = incidents;
                self.processIncidents();
            });
    }

    componentDidMount() {
        let deviceToIgnore = new URLSearchParams(this.props.location.search).get("ignore_device");
        let gatewayToIgnore = new URLSearchParams(this.props.location.search).get("ignore_hub")
        if (deviceToIgnore) {
            GridDuck.getAccount({id: GridDuck.userId})
                .then(function(account) {
                    let ignoredAssets = account.ignoredAssets;
                    if (ignoredAssets.indexOf(deviceToIgnore) === -1) {
                        ignoredAssets.push(deviceToIgnore);
                    }
                    account.setIgnoredAssets(ignoredAssets);
                });
        }
        if (gatewayToIgnore) {
            GridDuck.getAccount({id: GridDuck.userId})
                .then(function(account) {
                    let ignoredAssets = account.ignoredAssets;
                    if (ignoredAssets.indexOf(gatewayToIgnore) === -1) {
                        ignoredAssets.push(gatewayToIgnore);
                    }
                    account.setIgnoredAssets(ignoredAssets);
                });
        }
        if (deviceToIgnore || gatewayToIgnore) {
            this.setState({
                showAddedDeviceIgnore: deviceToIgnore !== null,
                showAddedGatewayIgnore: gatewayToIgnore !== null
            });
        }
    }

    componentWillUnmount() {
        let self = this;
        if (self.incidents) {
            self.incidents.off('updated', self.processIncidents);
        }

        if (self.account) {
            self.account.off('updated', self.processIncidents);
        }

    }

    unignoreAll() {
        return this.account.setIgnoredAssets([]);
    }

    unIgnoreIncident(objectId, event) {
        event.stopPropagation();
        event.preventDefault();
        let ignoredAssets = this.account.ignoredAssets;
        if (ignoredAssets.indexOf(objectId) !== -1) {
            ignoredAssets.splice(ignoredAssets.indexOf(objectId), 1);
        }
        return this.account.setIgnoredAssets(ignoredAssets);
    }

    processIncidents() {
        let siteIncidents = [];
        console.log(this.state.incidents, ' : incidents')

        this.state.incidents.list
            .filter(i => !i.timeEnd)
            .filter(i => this.account.ignoredSites.indexOf(i.siteId) !== -1 || this.account.ignoredAssets.indexOf(i.objectId) !== -1)
            .forEach(function (incident) {
                let thisSiteIncident = siteIncidents.find(i => i.siteId === incident.siteId);
                if (!thisSiteIncident) {
                	thisSiteIncident = {
						siteId: incident.siteId,
						siteName: incident.siteName,
						incidents: [incident]
					};
                    siteIncidents.push(thisSiteIncident);
                } else {
                    thisSiteIncident.incidents.push(incident);
                }
				if (incident.inferredAssetIds) {
					incident.inferredAssetIds.forEach(function(inferredAssetId, inferredIndex) {
						thisSiteIncident.incidents.push({
							id: incident.id,
							objectId: inferredAssetId,
							objectName: incident.inferredAssetNames[inferredIndex],
							siteId: incident.siteId,
							siteName: incident.siteName,
							timeStart: incident.timeStart,
							type: "inferredAsset"
						});
					})
				}
            });
        if (!siteIncidents.length) {
			this.setState({siteIncidents: null});
            return;
        }
        this.setState({siteIncidents: siteIncidents});
    }

    render() {
        let self = this;
        let toasts = [(<Toast severity="success"
                              key={"dev-add"}
                             onClose={() => this.setState({showAddedDeviceIgnore: false})}
                             message={"Device added to ignored list"}
                             anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                             open={this.state.showAddedDeviceIgnore}/>),
            (<Toast severity="success"
                    key={"gw-add"}
                    onClose={() => this.setState({showAddedGatewayIgnore: false})}
                    message={"Hub added to ignored list"}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={this.state.showAddedGatewayIgnore}/>)
        ]
        return (
            <div className={"page"}>
                {toasts}
                <div style={{
                    marginTop: '10px',
                    marginRight: '20px',
                    marginBottom: '-30px',
                    flexDirection: 'row-reverse',
                    display: 'flex',
                    zIndex: 10
                }}>
                    <Button style={{'width': 'auto'}} progressRes label={"Un-ignore all issues"} onClick={this.unignoreAll}/>
                </div>
                {this.state.loaded && this.state.siteIncidents ? <CollapsibleTable columns={{
                    outerColumns: [{title: 'Site'}, {title: '# of Alerts'}],
                    innerColumns: [{title: 'Type'}, {title: 'Device'}, {title: 'Status'}, {title: 'Date/Time'}, {title: 'Un-ignore'}]
                }}
                                                                                   tableData={
                    this.state.siteIncidents.map(function (incidentsO) {
                        return {
                            id: incidentsO.siteId,
                            data: [{
                                value: <span className={'list-hover'}
                                             onClick={() => incidentsO.siteId ? history.push('/site/' + incidentsO.siteId + '/devices') : history.push('/')}>{incidentsO.siteName}</span>
                            }, {value: incidentsO.incidents.length}],
                            innerData: incidentsO.incidents.map(function (incidentsI) {
                                return [
									{
										value: incidentsI.type === 'gateway' ? 'Hub' : incidentsI.type === 'inferredAsset' ? 'Device (Due to Disconnected Hub)' : 'Device'
									},
                                	{
                                    value: <span className={'list-hover'}
                                                 onClick={() => history.push('/device/' + incidentsI.objectId)}>{incidentsI.objectName}</span>
                                }, {
                                    value: <span style={{color: 'red'}}>Disconnected</span>
                                }, {value: getFormat('lastContacted')(incidentsI.timeStart)}, {
                                        value: incidentsI.type !== 'inferredAsset' ? (
                                            <Icon onIconClick={(e) => self.unIgnoreIncident(incidentsI.objectId, e)}
                                                 color={'gd-blue'} size={'20'} icon={"MdAlarm"}/>) : ''
                                }]
                            })
                        }
                    })
                }/> : ''}
                {!this.state.loaded ? <GenericLoader text={"Fetching"} textLineTwo={"Ignored Alerts..."}/> : ''}
                {this.state.loaded && !this.state.siteIncidents ?
                    <NoItems disabled text={'You have no ignored issues'}/> : ''}
            </div>
        );
    }
}

export default IgnoredAlertsPage;
